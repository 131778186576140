import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";

/**
 * import Core
 */
import numeral from "@utils/numeral";

import { Button } from "@components";

import Card from "./_card";

const CardOffer = ({ data, handleSubmit, loading, decreaseStep, price }) => {
  const totalWeight = get(data, "show.totalWeight", " - ");
  const list = [
    { label: "Your final offer", value: numeral(price).format() },
    { label: "Quantity", value: totalWeight },
  ];

  return (
    <Card title="Confirm your Final Offer">
      <p className="text-sm text-blue-gray-600">
        You’re about to submit your Final Offer to the Seller.
      </p>
      <ul className="divide-y divide-blue-gray-200">
        {map(list, (item, index) => {
          return (
            <li className="flex items-center justify-between py-4" key={index}>
              <div className=" text-blue-gray-500"> {item.label} </div>
              <div className=" text-blue-gray-900 font-bold">{item.value} </div>
            </li>
          );
        })}
      </ul>
      <ul className="grid grid-cols-1 md:grid-cols-3 md:gap-4 space-y-4 md:space-y-0">
        <li className="col-span-1">
          <Button
            size="xl"
            color="white"
            textColor="gray-900"
            className="bg-white border-blue-gray-300 hover:border-blue-gray-400 focus:outline-none focus:border-blue-gray-400"
            full
            outline
            onClick={() => {
              decreaseStep();
            }}
          >
            Back
          </Button>
        </li>
        <li className="col-span-2">
          <Button
            size="xl"
            color="gray-900"
            textColor="white"
            className="bg-gray-900 flex-1 justify-center whitespace-no-wrap px-0"
            disabled={loading}
            loading={loading}
            onClick={() => {
              handleSubmit({ price });
            }}
            full
          >
            Confirm
          </Button>
        </li>
      </ul>
    </Card>
  );
};

CardOffer.propTypes = {};

export default CardOffer;

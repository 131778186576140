import {
  VERIFY_EMAIL_CONFIRMATION_CODE_SUCCESS,
  VERIFY_EMAIL_CONFIRMATION_CODE_LOADING,
  VERIFY_EMAIL_CONFIRMATION_CODE_FAILED,
  VERIFY_EMAIL_CONFIRMATION_CODE_REQUEST
} from './action'

function verifyEmailConfirmationCodeStatus(state = {}, action = {}) {
  switch (action.type) {
    case VERIFY_EMAIL_CONFIRMATION_CODE_LOADING:
      return { loading: true, data: null, error: null }
    case VERIFY_EMAIL_CONFIRMATION_CODE_SUCCESS:
      return { loading: false, data: action.data, error: null }
    case VERIFY_EMAIL_CONFIRMATION_CODE_FAILED:
      return { loading: false, data: null, error: action.error }
    default:
      return state
  }
}

export { verifyEmailConfirmationCodeStatus }
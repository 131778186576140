import {
  CHANGE_PASSWORD_RESET,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from "./action";

function changePasswordStatus(state = {}, action = {}) {
  switch (action.type) {
    case CHANGE_PASSWORD_LOADING:
      return { loading: true, data: null, error: null };
    case CHANGE_PASSWORD_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case CHANGE_PASSWORD_FAILED:
      return { loading: false, data: null, error: action.error };
    case CHANGE_PASSWORD_RESET:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

export { changePasswordStatus };


export const INVOICES_CREATE_POST = "invoices/create_post";
export const INVOICES_CREATE_LOADING = "invoices/create_loading";
export const INVOICES_CREATE_SUCCESS = "invoices/create_success";
export const INVOICES_CREATE_FAILED = "invoices/create_failed";
export const INVOICES_CREATE_RECEIVED = "invoices/create_received";

export function invoiceCreatePost(orderItemId, payload = {}) {
  return {
    type: INVOICES_CREATE_POST,
    id: orderItemId,
    payload
  };
}

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  PRICEABLES_HEADER_FETCH_REQUEST,
  PRICEABLES_HEADER_FETCH_LOADING,
  PRICEABLES_HEADER_FETCH_SUCCESS,
  PRICEABLES_HEADER_FETCH_FAILED,
} from "./headerAction";

import {
  priceablesCategoryAPI,
} from "@config/api";


//fetch priceables header data
export function* watchPriceablesHeaderFetch() {
  yield takeEvery(PRICEABLES_HEADER_FETCH_REQUEST, priceablesHeaderFetch);
}

export function* priceablesHeaderFetch(action) {
  yield put({ type: PRICEABLES_HEADER_FETCH_LOADING });
  const { err, res } = yield call(priceablesHeaderFetchApi, action.payload.category_id, action.payload.params);

  if (!res) {
    yield put({
      type: PRICEABLES_HEADER_FETCH_FAILED,
      error: "Fetch priceables header data with category failed."
    });
    return;
  }
  yield put({ type: PRICEABLES_HEADER_FETCH_SUCCESS, data: res });
}

export function priceablesHeaderFetchApi(id, params) {
  return axios
    .get(priceablesCategoryAPI(id, 'header', params))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

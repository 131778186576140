export const FISHERY_FOLLOWING_AUCTIONS = "fishery/following_auctions";
export const FISHERY_FOLLOWING_AUCTIONS_REQUEST = `${FISHERY_FOLLOWING_AUCTIONS}_request`;
export const FISHERY_FOLLOWING_AUCTIONS_SUCCESS = `${FISHERY_FOLLOWING_AUCTIONS}_success`;
export const FISHERY_FOLLOWING_AUCTIONS_FAILURE = `${FISHERY_FOLLOWING_AUCTIONS}_failure`;

export const fisheryFollowingAuctionsAction = {
  request: (payload) => {
    return {
      type: FISHERY_FOLLOWING_AUCTIONS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_FOLLOWING_AUCTIONS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_FOLLOWING_AUCTIONS_FAILURE,
      error,
    }
  },
};

import React, { useRef } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import ReactTooltip from "react-tooltip";

/**
 * import Core
 */
import numeral from "@utils/numeral";

/**
 * import lib tools
 */
import { reduxForm, Field } from "redux-form";

import { useResponsive } from "@hooks";

/**
 * import local component
 */
import { renderCurrencyInput } from "@components/reduxform";
import { Button } from "@components";

import Slider from "./_slider";

const FinalBidForm = ({
  data,
  handleSubmit,
  price,
  setInitalValues,
  addStep,
  invalid,
  averagePrice,
}) => {
  const currentUserAuctionPriceStr = get(data, "show.currentUserAuctionPrice.str", " - ");
  const reservePriceStr = get(data, "show.reservePrice.str", " - ");
  const currentUserAuctionPrice = get(data, "show.currentUserAuctionPrice.value", 0);
  const reservePrice = get(data, "show.reservePrice.value", 0);
  const weightUnit = get(data, "show.weightUnit", "");
  const { isMobile } = useResponsive();
  const inputEl = isMobile ? "input-el-mob" : "input-el-pc";
  return (
    <div className="mt-6">
      <form onSubmit={handleSubmit}>
        {isMobile && (
          <Field
            name="price"
            data-for="price"
            data-tip="price-tip"
            id={inputEl}
            className="w-full bg-white text-fish-primary-700 form-field-sm text-center text-lg font-semibold px-2 py-2 rounded-lg border border-white focus:border-fish-primary-700"
            component={renderCurrencyInput}
            prefix="$"
          />
        )}
        <div className="flex items-center justify-between py-4 md:py-0">
          <div className="font-medium">{currentUserAuctionPriceStr}</div>
          {!isMobile && (
            <div className="max-w-xxs mb-2">
              <Field
                name="price"
                data-for="price"
                data-tip="price-tip"
                id={inputEl}
                className="bg-white text-fish-primary-700 form-field-sm text-center text-lg font-semibold px-2 py-2 w-32 rounded-lg border border-white focus:border-fish-primary-700"
                component={renderCurrencyInput}
                prefix="$"
              />
            </div>
          )}
          <div className="font-medium">{reservePriceStr}</div>
        </div>
        <Slider
          min={currentUserAuctionPrice}
          max={reservePrice}
          value={averagePrice}
          onChange={(value) => {
            setInitalValues({ price: value });
          }}
          onBeforeChange={() => {
            const el = document.getElementById(inputEl);
            typeof ReactTooltip.show === "function" && ReactTooltip.show(el);
          }}
          onAfterChange={() => {
            const el = document.getElementById(inputEl);
            typeof ReactTooltip.show === "function" && ReactTooltip.hide(el);
          }}
          step={0.1}
        />
        <div className="flex justify-between items-center pt-2 pb-4">
          <div className="text-sm text-red-500">Lower chance</div>
          <div className="text-sm text-green-500">Higher chance</div>
        </div>
      </form>
      <Button
        size="xl"
        color="white"
        textColor="gray-900"
        className="bg-white border-blue-gray-200 hover:border-blue-gray-300 focus:outline-none focus:border-blue-gray-300"
        full
        outline
        onClick={() => {
          if (invalid) {
            return;
          }
          addStep();
        }}
      >
        <span className="text-sm">
          {price
            ? `Submit Final Offer of ${numeral(price).format()}/${weightUnit}`
            : "Submit Final Offer"}
        </span>
      </Button>
      <ReactTooltip
        id="price"
        className="max-w-xs opacity-100 shadow-xs"
        textColor="#64748B"
        backgroundColor="white"
        arrowColor="white"
        place="top"
        event="focus"
        eventOff="blur"
      >
        Or enter exact amount
      </ReactTooltip>
    </div>
  );
};

FinalBidForm.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default reduxForm({
  form: "AuctionFinalBidForm",
})(FinalBidForm);

import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import cx from "classnames";
import { Moment } from "@core";

/** Hooks */
import {
  usePaymentsProofs,
  useBuyerPay,
  useReceiveFund,
  usePaymentsProofsList,
  useQuotaTransfer,
  useQuotaTransferList,
} from "@hooks";

/** Common Compoennts */
import {
  Action,
  Button,
  Loading,
  Modal,
  ModalButton,
  useModal,
} from "@components";

const Actions = ({ id, order, history, fetchOrderShow }) => {
  //hooks

  //currentModalAction ['buyer_paid', 'transfer_quota', 'fund_received']
  const [currentModalAction, setCurrentModalAction] = useState();
  //buyer paid submit
  const { onSubmit: buyerPaySubmit, loading: buyerPayLoading } = useBuyerPay(
    id,
    fetchOrderShow
  );
  //receive fund submit
  const {
    onSubmit: receiveFundSubmit,
    loading: receiveFundLoading,
  } = useReceiveFund(id, fetchOrderShow);
  // proofs list
  const {
    data: proofs,
    fetchList,
    loading: proofsLoading,
  } = usePaymentsProofsList(id);
  //upload payment proofs
  const { onUpload, loading: uploading } = usePaymentsProofs(id, fetchList);

  //quota transfer list
  const {
    data: quotas,
    loading: quotasLoading,
    fetchList: quotasFetchList,
  } = useQuotaTransferList(id);
  //upload transfer quota
  const {
    onUpload: onQuotaTransferUpload,
    onRemove: onQuotaTransferRemove,
    uploadRequest: quotaTransferUploadRequest,
    files: quotasPreviews,
    loading: quotaTransferLoading,
  } = useQuotaTransfer(id, () => {
    typeof fetchOrderShow === "function" && fetchOrderShow();
    typeof quotasFetchList === "function" && quotasFetchList();
  });
  //status
  const isBuyer = get(order, "buyer.is_me", false);
  const isSeller = get(order, "seller.is_me", false);
  const { pending, buyerPaid, fundsReceived, completed } =
    get(order, "order.status", {}) || {};
  const proofsEditable = isBuyer && (pending || buyerPaid);
  const buyerPaidAllowed = isBuyer && pending;
  const quotaTransferEditable = isSeller && (fundsReceived || completed);
  const quotaTransferTime = get(order, "order.stateStep.completed.time", "");
  const isPermanent = get(order, "order.market.isPermanent", false);

  //icons
  const iconWarning = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="w-5 h-5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 13V15H9V13H11ZM11 11V5H9V11H11Z"
        fill="#0694A2"
      />
    </svg>
  );
  //render
  const renderProofsList = (proofs = [], loading) => {
    if (loading) {
      return <Loading />;
    }
    return map(proofs, (item, index) => (
      <li
        key={index}
        className="py-3 flex items-center border-blue-gray-300 space-x-5 lg:space-x-10 text-left border-t last:pb-0"
      >
        <div className="font-medium flex-1 flex-shrink-0 text-sm">
          {get(item, "name", "")}
        </div>
        <a
          href={get(item, "file_url", "")}
          className="text-fish-primary-700"
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </a>
        {/* {proofsEditable && (
          <div
            className="cursor-pointer"
            onClick={() => {
              if (confirm("Are you sure?")) {
                onDelete(get(item, "id", ""));
              }
            }}
          >
            <svg
              className="w-4 h-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
        )} */}
      </li>
    ));
  };

  const renderQuotaList = (quotas = [], loading) => {
    if (loading) {
      return <Loading />;
    }
    return map(quotas, (item, index) => (
      <li
        key={index}
        className="py-3 flex items-center border-blue-gray-300 space-x-5 lg:space-x-10 text-left border-t last:pb-0"
      >
        <div className="font-medium flex-1 flex-shrink-0 text-sm">
          {get(item, "name", "")}
        </div>
        <a
          href={get(item, "file_url", "")}
          className="text-fish-primary-700"
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </a>
      </li>
    ));
  };

  const renderOptions = (currentModalAction) => {
    let defaultOptions = {
      content: <div />,
      buttonAlign: "right",
      showClose: true,
      size: "xl",
      buttons: [],
    };
    if (currentModalAction === "buyer_paid") {
      return Object.assign({}, defaultOptions, {
        content: (
          <div className="py-5 w-full text-left">
            <div className="text-lg leading-6 font-bold">
              Confirm payment sent
            </div>
            <div className="mt-2 max-w-xl text-sm">
              <p>
                Let the seller know you’ve sent payment. Upload proof of your
                payment to fast track the process.
              </p>
            </div>
            {proofsEditable && (
              <>
                <label
                  htmlFor="uploadFiles"
                  className={cx(
                    "transition ease-in-out duration-150 relative border border-blue-gray-900 font-semibold text-sm leading-5 px-4-tw py-2-tw rounded-md inline-flex items-center mt-4",
                    {
                      "cursor-pointer text-blue-gray-900": !uploading,
                      "cursor-not-allowed text-blue-gray-600 bg-gray-50": uploading,
                    }
                  )}
                >
                  {uploading
                    ? "Uploading ..."
                    : "Upload proof of payment (optional)"}
                </label>
                <input
                  type="file"
                  id="uploadFiles"
                  className="hidden"
                  onChange={onUpload}
                />
              </>
            )}
            {!isEmpty(proofs) && (
              <ul className="py-4">
                {renderProofsList(proofs, proofsLoading)}
              </ul>
            )}
          </div>
        ),
        buttons: buyerPaidAllowed
          ? [
              <ModalButton
                label={
                  buyerPayLoading ? "Submitting ..." : "Confirm payment sent"
                }
                key={`modal-button-2`}
                isStylePrimary
                disabled={buyerPayLoading}
                loading={buyerPayLoading}
                onClick={buyerPaySubmit}
              />,
            ]
          : [],
      });
    }

    if (currentModalAction === "fund_received") {
      return Object.assign({}, defaultOptions, {
        content: (
          <div className="py-5 w-full">
            <h3>
              Confirm funds received.
            </h3>
            <div className="mt-2 max-w-xl text-sm">
              <p>
                An email will be sent to the buyer to notify them that funds
                have been received.
              </p>
            </div>
          </div>
        ),
        buttons: [
          <ModalButton
            label={receiveFundLoading ? "Submitting ..." : "Confirm"}
            key={`modal-button-1`}
            isStylePrimary
            disabled={receiveFundLoading}
            loading={receiveFundLoading}
            onClick={receiveFundSubmit}
          />,
        ],
      });
    }

    if (currentModalAction === "transfer_quota") {
      return Object.assign({}, defaultOptions, {
        content: (
          <div className="py-5 w-full text-left">
            <div className="text-lg leading-6 font-bold">Transfer quota</div>
            <div className="mt-2 max-w-xl text-sm">
              <p>
                Upload a copy of the transfer receipt or transfer form must be
                uploaded
              </p>
            </div>
            {quotaTransferEditable && (
              <>
                <label
                  htmlFor="uploadQuotaFiles"
                  className={cx(
                    "transition ease-in-out duration-150 relative border border-blue-gray-900 font-semibold text-sm leading-5 px-4-tw py-2-tw rounded-md inline-flex items-center mt-4",
                    {
                      "cursor-pointer text-blue-gray-900": !uploading,
                      "cursor-not-allowed text-blue-gray-600 bg-gray-50": uploading,
                    }
                  )}
                >
                  Upload
                </label>
                <input
                  type="file"
                  id="uploadQuotaFiles"
                  className="hidden"
                  onChange={onQuotaTransferUpload}
                />
              </>
            )}
            {!isEmpty(quotasPreviews) && (
              <ul className="py-4">
                {map(quotasPreviews, (item, index) => (
                  <li
                    key={index}
                    className="py-3 flex items-center border-blue-gray-300 space-x-5 lg:space-x-10 text-left border-b"
                  >
                    <div className="font-medium flex-1 flex-shrink-0 text-sm">
                      {get(item, "name", "")}
                    </div>
                    <a
                      href={URL.createObjectURL(item)}
                      className="text-fish-primary-700"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (confirm("Are you sure?")) {
                          onQuotaTransferRemove(index);
                        }
                      }}
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ),
        buttons: quotasPreviews
          ? [
              <ModalButton
                label={quotaTransferLoading ? "Submitting ..." : "Confirm"}
                key={`modal-button-2`}
                isStylePrimary
                disabled={quotaTransferLoading}
                loading={quotaTransferLoading}
                onClick={quotaTransferUploadRequest}
              />,
            ]
          : [],
      });
    }

    return defaultOptions;
  };

  const [modalProps, toggleModal] = useModal(renderOptions(currentModalAction));

  const getBuyerActionProps = (order) => {
    if (pending) {
      return {
        icon: iconWarning,
        title: "Pay for your order",
        subTitle: `You need to pay for your order. Include invoice number ${order.serialNumber} in your payment description`,
        action: (
          <div className="flex space-x-4">
            <Button
              color="white"
              textColor="gray-900"
              onClick={() => {
                history && history.push(order.invoiceUrl);
              }}
            >
              Invoice
            </Button>
            <Button
              color="teal-500"
              textColor="white"
              onClick={() => {
                setCurrentModalAction("buyer_paid");
                toggleModal(true);
              }}
            >
              Confirm payment
            </Button>
          </div>
        ),
        theme: "teal",
      };
    }
  };

  const getSellerActionProps = () => {
    if (pending || buyerPaid) {
      return {
        icon: iconWarning,
        title: "Confirm funds received",
        subTitle:
          "Buyer paid successfully, please confirm you have received the funds.",
        action: (
          <Button
            color="teal-500"
            textColor="white"
            onClick={() => {
              setCurrentModalAction("fund_received");
              toggleModal(true);
            }}
          >
            Confirm
          </Button>
        ),
        theme: "teal",
      };
    }

    if (fundsReceived) {
      return {
        icon: iconWarning,
        title: "Transfer quota",
        subTitle:
          "A copy of the transfer receipt or transfer form must be uploaded",
        action: (
          <Button
            color="teal-500"
            textColor="white"
            loading={receiveFundLoading}
            disabled={receiveFundLoading}
            onClick={() => {
              setCurrentModalAction("transfer_quota");
              toggleModal(true);
            }}
          >
            Upload
          </Button>
        ),
        theme: "teal",
      };
    }
    return {};
  };

  const actionProps = isSeller
    ? getSellerActionProps(order)
    : isBuyer
    ? getBuyerActionProps(order)
    : {};

  const proofsActionProps = {
    title: "Payment confirmed",
    subTitle: "Payment sent by buyer",
    action: proofsEditable ? (
      <>
        <label
          htmlFor="uploadFiles"
          className={cx(
            "transition ease-in-out duration-150 relative border border-blue-gray-900 font-semibold text-sm leading-5 px-4-tw py-2-tw rounded-md inline-flex items-center",
            {
              "cursor-pointer text-blue-gray-900": !uploading,
              "cursor-not-allowed text-blue-gray-600 bg-gray-50": uploading,
            }
          )}
        >
          {uploading ? "Uploading ..." : "Upload proof"}
        </label>
        <input
          type="file"
          id="uploadFiles"
          className="hidden"
          onChange={onUpload}
        />
      </>
    ) : null,
    list: renderProofsList(proofs, proofsLoading),
  };

  const transferQuotaProps = {
    title: "Quota Transferred",
    subTitle: `Seller transferred quota on ${Moment.getFormated(
      quotaTransferTime
    )}`,
    action:
      isSeller && !isEmpty(quotas) ? (
        <Button
          color="gray-900"
          textColor="white"
          onClick={() => {
            setCurrentModalAction("transfer_quota");
            toggleModal(true);
          }}
        >
          Upload
        </Button>
      ) : null,
    list: renderQuotaList(quotas, quotasLoading),
  };

  if (!get(order, "isAuctionOrder", false)) {
    return null;
  }

  if (isPermanent) {
    return null;
  }

  return (
    <>
      {!isEmpty(actionProps) && (
        <div className="mt-4 md:mt-6 mx-5 md:mx-0">
          <Action {...actionProps} />
        </div>
      )}
      <div className="space-y-4 md:space-y-6">
        {buyerPaid || fundsReceived || completed ? (
          <div className="mt-4 md:mt-6 mx-5 md:mx-0">
            <Action {...proofsActionProps} />
          </div>
        ) : null}
        {completed && (
          <div className="mt-4 md:mt-6 mx-5 md:mx-0">
            <Action {...transferQuotaProps} />
          </div>
        )}
      </div>
      <Modal {...modalProps} />
    </>
  );
};

Actions.propTypes = {
  id: PropTypes.string,
  order: PropTypes.object,
  history: PropTypes.object,
  fetchOrderShow: PropTypes.func,
};

export default Actions;

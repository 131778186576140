export const EXCHANGE_LIST_FETCH_REQUEST = "exchange/fetch_list_request";
export const EXCHANGE_LIST_FETCH_LOADING = "exchange/fetch_list_loading";
export const EXCHANGE_LIST_FETCH_SUCCESS = "exchange/fetch_list_success";
export const EXCHANGE_LIST_FETCH_FAILED = "exchange/fetch_list_failed";
export const EXCHANGE_LIST_REFRESH = "exchange/refresh_list";



export function fetchExchangeList() {
  return {
    type: EXCHANGE_LIST_FETCH_REQUEST
  };
}

export function refreshExchangeList(data) {
  return {
    type: EXCHANGE_LIST_REFRESH,
    data,
  };
}

import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_BIDS_REQUEST, accountBidsAction } from "./action";

// fetch account bids list
export function* watchAccountBidsFetch() {
  yield takeLatest(ACCOUNT_BIDS_REQUEST, accountBidsFetch);
}

function* accountBidsFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "bids"], action.payload);
    yield put(accountBidsAction.success(res.data));
  } catch (err) {
    yield put(accountBidsAction.failure(err));
  }
}

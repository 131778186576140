import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

import { Dropdown, Link, Mobile, DesktopAndTablet } from "@components";
import { links } from "@config/staticLink";

/**
 * [Component] Layout DashboardNav
 */
const DashboardNav = ({ match }) => {

  const currentPath = get(match, "path", "");

  const getCurrentClass = (name) =>
    cx(
      { "text-blue-gray-900 border-blue-gray-900": currentPath === name }
    );

    const getCurrentOrderMenu = (name) =>
    cx(
      { "text-blue-gray-900 border-blue-gray-900": window.location.search === name }
    );
  
  return (
    <>
      <div className="px-4 md:px-8 -mt-1 md:mt-0 border-b md:border-0 border-blue-gray-200 bg-gray-50 overflow-x-auto md:overflow-visible">
        <nav
          className="flex items-center justify-between text-blue-gray-500 md:justify-center overflow-y-visible"
          aria-label="Global"
        >
          <div className="flex space-x-6 md:space-x-10 overflow-y-visible" style={{ minWidth:"445px" }}>
            <Link
              to={links.dashboard}
              className={cx("text-sm md:text-base font-medium md:font-semibold hover:text-blue-gray-900 border-b-2 border-transparent pb-4",getCurrentClass("/dashboard"))}
            >
              Marketplace
            </Link>
            <Mobile>
              <Dropdown
                className="static-important"
                button={
                  <div className={cx("text-sm font-medium md:font-semibold hover:text-blue-gray-900 cursor-pointer border-b-2 border-transparent pb-4",getCurrentClass("/listings"),getCurrentOrderMenu("?type=sales"))}>
                    Sales
                    <svg className="inline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.00006 9.99997L5.17139 7.1713L6.11472 6.22864L8.00006 8.11464L9.88539 6.22864L10.8287 7.1713L8.00006 9.99997Z" fill="currentColor"/>
                    </svg>
                  </div>
                }
                fixed
              >
                <div className="md:mt-2 w-full md:w-56 md:rounded-md shadow-lg bg-white text-blue-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-blue-gray-200 focus:outline-none z-50">
                  <div className="py-3">
                    <Link
                      to={links.listings_order}
                      className={cx("block px-4 py-2 md:py-3 text-sm hover:bg-gray-50 hover:text-blue-gray-900",getCurrentClass("/listings"))}
                    >
                      My Listings
                    </Link>
                    <Link
                      to={links.sales_order}
                      className={cx("block px-4 py-2 md:py-3 text-sm hover:bg-gray-50 hover:text-blue-gray-900",getCurrentOrderMenu("?type=sales"))}
                    >
                      Sales Orders
                    </Link>
                  </div>
                </div>
              </Dropdown>
              <Dropdown
                className="static-important"
                button={
                  <div className={cx("text-sm font-medium md:font-semibold hover:text-blue-gray-900 cursor-pointer border-b-2 border-transparent pb-4",getCurrentClass("/bids"),getCurrentOrderMenu("?type=purchase"))}>
                    Purchases
                    <svg className="inline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.00006 9.99997L5.17139 7.1713L6.11472 6.22864L8.00006 8.11464L9.88539 6.22864L10.8287 7.1713L8.00006 9.99997Z" fill="currentColor"/>
                    </svg>
                  </div>
                }
                fixed
              >
                <div className="md:mt-2 w-full md:w-56 md:rounded-md shadow-lg bg-white text-blue-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-blue-gray-200 focus:outline-none z-50">
                  <div className="py-3">
                    <Link
                      to={links.bids_order}
                      className={cx("block px-4 py-2 md:py-3 text-sm hover:bg-gray-50 hover:text-blue-gray-900",getCurrentClass("/bids"))}
                    >
                      My Bids
                    </Link>
                    <Link
                      to={links.purchase_order}
                      className={cx("block px-4 py-2 md:py-3 text-sm hover:bg-gray-50 hover:text-blue-gray-900",getCurrentOrderMenu("?type=purchase"))}
                    >
                      Purchase Orders
                    </Link>
                  </div>
                </div>
              </Dropdown>
            </Mobile>
            <Link
              to="/wallet"
              className={cx("text-sm md:text-base font-medium md:font-semibold hover:text-blue-gray-900 border-b-2 border-transparent pb-4",getCurrentClass("/wallet"))}
            >
              My Wallet
            </Link>
            <Link
              to="/statements"
              className={cx("text-sm md:text-base font-medium md:font-semibold hover:text-blue-gray-900 hidden border-b-2 border-transparent pb-4",getCurrentClass("/statements"))}
            >
              Tax Statements
            </Link>
            <Link
              to={links.profile_settings}
              className={cx("text-sm md:text-base font-medium md:font-semibold hover:text-blue-gray-900 border-b-2 border-transparent pb-4",getCurrentClass("/profile/settings"))}
            >
              Settings
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
};

DashboardNav.propTypes = {
};

DashboardNav.defaultProps = {
};

export default DashboardNav;

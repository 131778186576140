export const OFFER_MORE_TOGGLE = "quota_show/offer_more_toggle";
export const MODAL_ORDER_SET_DATA = "quota_show/modal_order_set_data";
export const MODAL_ORDER_STASTUS_CHANGE = "quota_show/modal_order_status_change";
export const MODAL_ORDER_RESULT_CHANGE = "quota_show/modal_order_result_change";
export const OFFER_FORM_STASTUS_CHANGE = "quota_show/offer_form_status_change";
export const OFFER_FORM_INPUT = "quota_show/offer_form_input";
export const SET_MESSAGE_MOUNT = "quota_show/set_message_mount";
export const SET_MESSAGE_SHOW = "quota_show/set_message_show";


/**
 * 报价详情页 报价列表显示更多切换
 * @param  {Boolean} status
 * @return {Object}
 * {type: OFFER_MORE_TOGGLE, payload: { isOpen: true|false }}
 */
export function quotaShowOfferMoreToggle(status) {
  return {
    type: OFFER_MORE_TOGGLE,
    payload: {
      isOpen: status,
    }
  };
}

/**
 * 报价详情页 订单dialog 显示切换
 * @param  {Boolean} status
 * @return {Object}
 * {type: MODAL_ORDER_STASTUS_CHANGE, payload: { isOpen: true|false }}
 */
export function quotaShowModalOrderStatusChange(status) {
  return {
    type: MODAL_ORDER_STASTUS_CHANGE,
    payload: {
      isOpen: status,
    }
  };
}
export function quotaShowModalOrderSetData(order) {
  return {
    type: MODAL_ORDER_SET_DATA,
    payload: {
      order
    }
  };
}
/**
 * 报价详情页 订单dialog中，是否显示最后结果切换
 * @param  {Boolean} status
 * @return {Object}
 * {type: }
 */
export function quotaShowModalOrderResultChange(status) {
  return {
    type: MODAL_ORDER_RESULT_CHANGE,
    payload: {
      resultShow: status,
    }
  };
}


/**
 * 报价详情页底部 Make Offer Price表单的显示切换 (mobile)
 * @param  {Boolean} status true|false
 * @return {Object}
 * {type: OFFER_FORM_STASTUS_CHANGE, payload: {isOpen: true|false}}
 */
export function quotaShowOfferFormStatusChange(status) {
  return {
    type: OFFER_FORM_STASTUS_CHANGE,
    payload: {
      isOpen: status,
    }
  };
}


/**
 * 报价详情页底部表单内的输入框输入事件
 * @param  {Object} payload 输入并且处理后的数据
 * @return {Object}
 * {
 *   type: OFFER_FORM_INPUT,
 *   payload: {
 *.    price: 666.00,
 *   }
 * }
 */
export function quotaShowOfferFormInput(payload) {
  return {
    type: OFFER_FORM_INPUT,
    payload
  };
}


/**
 * 报价详情页 Message 挂载时间
 * @param  {Object} time
 * @return {Object}
 * {
 *   type: SET_MESSAGE_MOUNT,
 *   time: Time(Object)
 * }
 */
export function quotaShowSetMessageMount(time) {
  return {
    type: SET_MESSAGE_MOUNT,
    time,
  };
}

/**
 * 报价详情页 Message 是否显示（用于手机端）
 * @param  {Boolean} status true|false
 * @return {Object}
 * {
 *   type: SET_MESSAGE_SHOW,
 *   show: true|false
 * }
 */
export function quotaShowSetMessageShow(status) {
  return {
    type: SET_MESSAGE_SHOW,
    show: status,
  };
}


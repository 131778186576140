import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";


import {
  walletTopupAPI,
} from "@config/api";


import {
  WALLET_TOPUP_REQUEST,
  walletTopupFailed,
  walletTopupSuccess,
  walletTopupLoading,
} from "./topupAction";

export function* watchWalletTopupPost() {
  yield takeLatest(WALLET_TOPUP_REQUEST, walletTopupPost);
}

export function* walletTopupPost(action) {

  yield put(walletTopupLoading());

  const { res, err } = yield call(walletTopupApi, action.payMethod, action.payload);

  if (err) {
    yield put(walletTopupFailed(err));
    return;
  }
  yield put(walletTopupSuccess(res));
}

export function walletTopupApi(payMethod, payload) {
  return axios
    .post(walletTopupAPI(payMethod), { ...payload } )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import cx from "classnames";


import { Link, Loading, TimeCountDown } from "@components";

const NotificatList = ({ notificationList, notificationLoading }) => {

  const renderTimeCreate = (time) =>{
    let createTime = Date.parse(time);
    let nowTime = window.moment.now();
    let resultTime = nowTime - createTime;
    if((resultTime / 1000 / 3600 / 24) > 1){
      return `${Math.floor(resultTime / 1000 / 3600 / 24)} Days ago`
    } else if((resultTime / 1000 / 3600 / 24) == 1){
      return '1 Day ago'
    } else if((resultTime / 1000 / 3600) > 1){
      return `${Math.floor(resultTime / 1000 / 3600)} Hours ago`
    } else if((resultTime / 1000 / 3600) == 1){
      return '1 Hour ago'
    } else if((resultTime / 1000 / 60) > 1){
      return `${Math.floor(resultTime / 1000 / 60)} Mins ago`
    } else if((resultTime / 1000 / 60) == 1){
      return '1 Min ago'
    }
  }

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6 border-b border-blue-gray-200">
        <h2 className="text-base text-blue-gray-800 leading-normal font-medium">
          Notifications
        </h2>
        <span className="text-xxs text-blue-gray-400">All your latest alerts</span>
      </div>
      { notificationLoading ? (
        <div>
          <Loading />
        </div>
      ): (
        <ul className="wacthlist-input watchlist-height px-6 pb-2 divide-y divide-blue-gray-200">
          {
            (isEmpty(notificationList) && (
              <li className="pb-4 pt-6 text-blue-gray-500 text-center">No data</li>
            ))
          }
          {map(notificationList, (item, index) => {
            return (
              <li
                key={index}
                className="py-4"
              >
                <div className="text-sm">
                  <div className="flex items-center space-x-2">
                    <div className="py-px px-2 rounded-md bg-purple-100 text-purple-600">{item.no}</div>
                    <div className="flex-1 truncate text-blue-gray-700">{item.title}</div>
                  </div>
                  <div className="mt-2 text-blue-gray-700 font-medium">{item.message}</div>
                  <div className="mt-1 text-blue-gray-500">
                    <span>{renderTimeCreate(item.created_at)}</span>
                    <div className="flex items-center">
                      <span>( </span> &nbsp;
                      <TimeCountDown
                            date={get(item, "expired_at", "")}
                            prefixLabel="Expires: "
                            themeComplete="text-sm"
                            now={() => {
                              return window.moment.now();
                            }}
                            onComplete={() => {
                              window.location.reload();
                            }}
                          />
                      <span>&nbsp;)</span>
                    </div>
                  </div>
                  <Link to={item.url} className="mt-2 block rounded-md border border-blue-gray-200 shadow-t-sm bg-white py-1 text-blue-gray-700 text-xxs font-medium text-center">
                    View
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      )}

    </div>
  );
};

NotificatList.propTypes = {
};

NotificatList.defaultProps = {};

export default NotificatList;
import { PERMANENT_ACCEPT_BID_SUCCESS, PERMANENT_ACCEPT_BID_FAILURE, PERMANENT_ACCEPT_BID_RESET } from "./action";

export const permanentAcceptBidStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PERMANENT_ACCEPT_BID_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case PERMANENT_ACCEPT_BID_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case PERMANENT_ACCEPT_BID_RESET:
      return initStatus

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

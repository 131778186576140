import axios from "axios";
import { takeLatest, takeEvery, call, put, select } from "redux-saga/effects";

import get from "lodash/get";
import result from "lodash/result";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import {
  AUCTIONS_CATEGORIES_FETCH_REQUEST,
  AUCTIONS_CATEGORIES_FETCH_LOADING,
  AUCTIONS_CATEGORIES_FETCH_SUCCESS,
  AUCTIONS_CATEGORIES_FETCH_FAILED,
  AUCTIONS_CATEGORIES_CHANGE_REQUEST
} from "./catesAction";

import { productsAPI } from "@config/api";

//watch fetch auction categories
export function* watchAuctionCatesFetch() {
  yield takeEvery(AUCTIONS_CATEGORIES_FETCH_REQUEST, auctionsCatesFetch);
}

export function* auctionsCatesFetch(action) {
  yield put({ type: AUCTIONS_CATEGORIES_FETCH_LOADING });
  const { err, res } = yield call(auctionsCatesFetchApi, action.payload);
  if (!res) {
    yield put({
      type: AUCTIONS_CATEGORIES_FETCH_FAILED,
      error: "Get categories failed."
    });
    return;
  }
  yield put({
    type: AUCTIONS_CATEGORIES_FETCH_SUCCESS,
    data: getData(res, action.payload)
  });
}

export function auctionsCatesFetchApi(payload) {
  return axios
    .get(productsAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

//watch auction categories change
export function* watchAuctionCatesChange() {
  yield takeEvery(AUCTIONS_CATEGORIES_CHANGE_REQUEST, auctionsCatesChange);
}

function* auctionsCatesChange(action) {
  const res = yield select(state =>
    get(state, "auctionsCatesStatus.data.res", [])
  );
  const data = getData(res, action.payload);
  yield put({
    type: AUCTIONS_CATEGORIES_FETCH_SUCCESS,
    data
  });
}

//Get and update categories data
const getData = (res, { sell_type, category_id, state }) => {
  //initial data
  let data = {
    res,
    categories: [{ label: "Select a Catagory", value: "" }],
    states: [{ label: "Select a State", value: "" }],
    product_grades: [{ label: "Select a Zone", value: "" }]
  };
  let categories,
    states,
    product_grades = [];

  //return empty unless sell_type

  if (!sell_type) {
    return data;
  }

  //get categories data
  categories = getCategories(res, sell_type);
  data.categories = [
    ...data.categories,
    ...categories.map(item => ({
      label: item.name,
      value: item.id
    }))
  ];

  //return data unless category_id
  if (!category_id) {
    return data;
  }

  //get states data
  states = getStates(categories, Number(category_id));
  data.states = [
    ...data.states,
    ...states.map(item => ({
      label: item.state,
      value: item.state
    }))
  ];

  //return data unless state_name
  if (!state) {
    return data;
  }

  //get product grades data
  product_grades = getProductGrades(states, state);
  data.product_grades = [...data.product_grades, ...product_grades];

  //save params for router;
  data.link = `/l/${getCategoryName(
    categories,
    Number(category_id)
  )}/${getProductId(states, state)}`;
  return data;
};

const getCategories = (res, sell_type) => {
  return getDataByKey(
    res,
    { key: "sell_type", value: sell_type },
    "categories"
  );
};

const getStates = (categories, category_id) => {
  return getDataByKey(
    categories,
    { key: "id", value: category_id },
    "products"
  );
};

const getCategoryName = (categories, category_id) => {
  return getDataByKey(categories, { key: "id", value: category_id }, "name");
};

const getProductId = (states, state) => {
  return getDataByKey(states, { key: "state", value: state }, "id");
};

const getProductGrades = (states, state) => {
  const product_name = getDataByKey(
    states,
    { key: "state", value: state },
    "name"
  );
  const product_grades = getDataByKey(
    states,
    { key: "state", value: state },
    "product_grades"
  );
  return product_grades.map(item => ({
    value: item.id,
    label: !item.name || item.name === "N/A" ? product_name : item.name,
    unit_weight: item.unit_weight,
    unit_suffix: item.unit_suffix
  }));
};

const getDataByKey = (data, { key, value }, path) => {
  return result(find(data, item => get(item, key) === value), path) || [];
};

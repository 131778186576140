import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * Core Method
 */
import { LoadingSelector } from "@core";

/**
 * import local tools & redux & usecase
 */
import {
  statesListAction,
  STATES_LIST,
} from "@redux/modules_v2/quotaAttr/states/action";

/**
 * Fetch list hooks
 * @param {*} param
 */
export const useStatesFetchList = (param = {}) => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(statesListAction.request(param));
  };

  const statesListStatus = useSelector(state => {
    const selector = new LoadingSelector(STATES_LIST, state);
    return Object.assign(
      {},
      get(state, "quotaAttrStatus.statesListStatus", {}),
      {
        loading: selector.loading,
      }
    );
  });

  const { data, loading } = statesListStatus;

  /**
   * did mount fetch list
   */
  useEffect(() => {
    if (param.country_id) fetchList();
  }, [param.country_id]);

  return [data, loading];
};

import { takeLatest, call, put } from "redux-saga/effects";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_MARKET_GROUPS_REQUEST, permanentMarketGroupsAction } from "./action";

// fetch permanent list
export function* watchPermanentMarketGroupsFetch() {
  yield takeLatest(PERMANENT_MARKET_GROUPS_REQUEST, permanentMarketGroupsFetch);
}

function* permanentMarketGroupsFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "marketGroups"], action.payload);
    yield put(permanentMarketGroupsAction.success(res.data));
  } catch (err) {
    yield put(permanentMarketGroupsAction.failure(err));
  }
}

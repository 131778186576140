import { takeLatest, call, put } from "redux-saga/effects";
// import get from "lodash/get";

import errorString from "@utils/errorString";
import { P2PApiUsecase } from "@core";
import { P2P_NEW_REQUEST, p2pNewAction } from "./action";

export function* watchp2pNewRequest() {
  yield takeLatest(P2P_NEW_REQUEST, p2pNewFetch);
}

function* p2pNewFetch(action) {
  try {
    const usecase = new P2PApiUsecase();
    const res = yield call([usecase, "new"], action.payload);
    yield put(p2pNewAction.success(res.data));
  } catch (err) {
    // const errors = get(err, "response.data.errors", {});
    yield put(p2pNewAction.failure(errorString(err)));
  }
}
 
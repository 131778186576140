export const QUOTUM_INVENTORIES = "quotum_inventories";
export const QUOTUM_INVENTORIES_REQUEST = `${QUOTUM_INVENTORIES}_request`;
export const QUOTUM_INVENTORIES_SUCCESS = `${QUOTUM_INVENTORIES}_success`;
export const QUOTUM_INVENTORIES_FAILURE = `${QUOTUM_INVENTORIES}_failure`;
export const QUOTUM_INVENTORIES_UPDATE = `${QUOTUM_INVENTORIES}_update`;

export const quotumInventoriesAction = {
  request: (payload) => {
    return {
      type: QUOTUM_INVENTORIES_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: QUOTUM_INVENTORIES_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: QUOTUM_INVENTORIES_FAILURE,
      error,
    };
  },
  update: (payload) => {
    return {
      type: QUOTUM_INVENTORIES_UPDATE,
      payload,
    };
  },
};

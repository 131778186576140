import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { ModalWrapper } from "@views/common";
import { Button } from "@components";

const Index = ({
  isOpen,
  place,
  setPlace,
  toggleModal,
  submit,
  loading,
  title,
  price,
  unit,
  total,
}) => {
  return (
    <ModalWrapper
      title="Confirm purchase"
      isOpen={isOpen}
      toggleModal={() => {
        typeof setPlace === "function" && setPlace({});
        typeof toggleModal === "function" && toggleModal(false);
      }}
      size="lg"
      contentAlign="left"
    >
      <div className="">
        <div className="py-4 text-sm text-blue-gray-700">
          You are about to instantly purchase the following items. This will end
          the listing and declare you as the buyer. By continuing, you will be
          liable to complete purchase for this listing.
        </div>
        <div className="font-medium space-y-4">
          <div>
            <div className="text-xxs text-blue-gray-500 uppercase mb-1">
              Purchase information
            </div>
            <div className="text-sm text-blue-gray-700 capitalize">{title}</div>
          </div>
          <div>
            <div className="text-xxs text-blue-gray-500 uppercase mb-1">
              Buy-it-now price
            </div>
            <div className="text-sm text-blue-gray-700">{price}</div>
          </div>
        </div>
        <div className="my-6 h-px bg-gray-100 w-full" />
        <ul className="w-full mb-6">
          <li className="flex justify-between items-center space-y-2 text-blue-gray-700">
            <span className="font-medium text-xxs">Subtotal</span>
            <span className="font-bold text-xxs">
              {get(total, "amount", "")}
            </span>
          </li>
          <li className="flex justify-between items-center space-y-2 text-blue-gray-700">
            <span className="font-medium text-xxs">GST</span>
            <span className="font-bold text-xxs">{get(total, "GST", "")}</span>
          </li>
          <li className="flex justify-between items-center space-y-2 text-blue-gray-700">
            <span className="font-medium text-xxs">Total</span>
            <span className="font-bold">{get(total, "totalWithGST", "")}</span>
          </li>
        </ul>

        <Button
          size="xl"
          color="gray-900"
          textColor="white"
          className="bg-gray-900"
          full
          type="submit"
          onClick={() => {
            typeof submit === "function" && submit();
          }}
          loading={loading}
          disabled={loading}
        >
          Confirm
        </Button>
      </div>
    </ModalWrapper>
  );
};

Index.propTypes = {};

export default Index;

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { MarketUsecase, LoadingSelector } from "@core";
import {
  marketShowAction,
  MARKET_SHOW,
} from "@redux/modules_v2/markets/show/action";

/**
 * [hooks] market show by id
 * @returns { usecase: "a usecase by market data", loading: true|false }
 */
export const useMarketShow = (id) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchMarketShow = (marketId) => {
    dispatch(marketShowAction.request({ id: marketId }));
  };

  const marketShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(MARKET_SHOW, state);
    return Object.assign({}, get(state, "marketShowStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    if (!marketShowStatus.loading) {
      if (!isEmpty(marketShowStatus.data)) {
        const usecase = new MarketUsecase(marketShowStatus.data);
        const isActive = get(usecase, "single.isActive", false);
        if (!isActive) {
          history && history.push("/");
        }
      }
    }
  }, [marketShowStatus.loading]);

  const loading = marketShowStatus.loading;
  const usecase = new MarketUsecase(marketShowStatus.data);

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    if (id) {
      fetchMarketShow(id);
    }
  }, [id]);

  return { usecase, loading };
};

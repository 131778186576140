import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { STATMENT_LIST_REQUEST, statementListAction } from "./action";

// fetch statements list
export function* watchStatementListFetch() {
  yield takeLatest(STATMENT_LIST_REQUEST, statementListFetch);
}

function* statementListFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "statementListing"], action.payload);
    yield put(statementListAction.success(res.data));
  } catch (err) {
    yield put(statementListAction.failure(err));
  }
}

import { get, map } from "lodash";
import { Moment, UrlUtils } from "@core/utils";
import { PriceBaseEntity } from "./baseEntity";
import { QuotumEntity } from "./quotumEntity";
import urlGenerator from "@utils/urlGenerator";
import numeral from "@utils/numeral";
import pluralize from "pluralize";

/**
 * [ ENTITY ] Permanent entity
 * @data
 * {
 *   id: 7
 *   title: "species_1"
 *   description: "asdfasdfd"
 *   state: "approved"（废弃）
 *   created_at: "2020-03-12T10:52:13.332+11:00"
 *   updated_at: "2020-03-12T11:14:34.264+11:00"
 *   cognito_id: "3afe3585-bf6a-4ae5-a4d7-f056a82d7526"
 *   total_price: {price: "748.00", price_with_symbol: "$748.00"}
 *   permanent_listing_type: "sell",
 *   approved: true
 * }
 */
export class PermanentEntity {
  constructor(props) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id", "");
  }

  get description() {
    return get(this.data, "description", "");
  }

  get state() {
    return get(this.data, "state", "");
  }

  get cognitoId() {
    return get(this.data, "cognito_id", "");
  }

  get fisheryId() {
    return get(this.data, "fishery.id", "");
  }

  get title() {
    return get(this.data, "title", "");
  }

  get titleWithStateAbbr() {
    return get(this.data, "title", "");
  }

  get url() {
    const url = UrlUtils.format(`/au/p/${this.title}/${this.id}`);
    return urlGenerator(url);
  }

  get urlEdit() {
    return `/permanents/${this.id}/edit`;
  }

  get quotumId() {
    return get(this.markets, "[0].quotum.id", "");
  }

  get urlQuotaUnit() { 
    return `/permanents/${this.id}?fishery_id=${this.fisheryId}&quotum_id=${this.quotumId}`;
  }

  get expiredAt() {
    const date = get(this.data, "expired_at", "");
    return Moment.getFormated(date);
  }

  get expiredDays() {
    const date = get(this.data, "expired_at", "");
    return Moment.getDaysFromNow(date);
  }

  get isExpired() {
    const date = new Moment(get(this.data, "expired_at"));
    return date.isExpired;
  }

  price(type) {
    const usecase = new PriceBaseEntity(get(this.data, "total_price", {}));
    return usecase.price(type);
  }

  get markets() {
    return map(get(this.data, "markets", []), (market) => {
      return new PermanentMarketEntity(market);
    });
  }

  get enquired() {
    return get(this.data, "enquired", false);
  }

  get isApproved() {
    return get(this.data, "approved", false);
  }

  get listingType() {
    return get(this.data, "permanent_listing_type"); // sell | buy
  }

  get listingTypeTag() {
    if (this.listingType === "sell") {
      return "Sell";
    }
    if (this.listingType === "buy") {
      return "Wanted";
    }
    return "";
  }

  get status() {
    return get(this.data, "status", "");
  }

  get isUnderContract() {
    return "Under Contract" === this.status;
  }

  get isUnderOffer() {
    return "Under Offer" === this.status;
  }
}

/**
 * [ ENTITY ] PermanentMarketEntity
 * @data
 * {
 *   id: 7
 *   quantity: 34
 *   quotum_id: 1
 *   created_at: "2020-03-12T10:52:13.335+11:00"
 *   updated_at: "2020-03-12T10:52:13.335+11:00"
 *   price: {price: "22.00", price_with_symbol: "$22.00"}
 *   "quotum":{
      "id":158086018,
      "unit_name":"units",
      "category":{
          "id":804506587,
          "name":"Greenlip Abalone"
      },
      "country":{
          "id":1052642834,
          "abbr":"AU",
          "name":"Australia"
      },
      "species":{
          "id":906617150,
          "name":"greenlip abalone"
      },
      "state":{
          "id":818524958,
          "abbr":"NSW",
          "name":"New South Wales"
      },
      "zone":{
          "id":956409797,
          "abbr":"Z1",
          "name":"zone 1"
      }
 * }
 *
 * }
 */
export class PermanentMarketEntity {
  constructor(props) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id", "");
  }

  get quantity() {
    return get(this.data, "quantity", "");
  }

  get updatedAt() {
    return get(this.data, "updated_at", "");
  }

  get price() {
    const usecase = new PriceBaseEntity(get(this.data, "price", {}));
    return usecase.price("price_with_symbol");
  }

  get priceOrigin() {
    return get(this.data, "price.price", "");
  }

  get priceValue() {
    return numeral(this.priceOrigin).value();
  }

  get quotum() {
    return new QuotumEntity(get(this.data, "quotum", {}));
  }

  get name() {
    return this.quotum.name;
  }

  get nameNoState() {
    return this.quotum.nameNoState;
  }

  get nameStateAbbr() {
    return this.quotum.nameStateAbbr;
  }

  get unitOfSale() {
    return get(this.data, "unit_of_sale", "");
  }

  get unitName() {
    return this.quotum.unitNameCondition(this.unitOfSale, 1);
  }

  get amount() {
    return `${this.quantity}${this.unitName === "kg" ? "" : " "}${pluralize(
      this.unitName,
      this.unitName === "kg" ? 1 : this.quantity
    )}`;
  }

  get amountWithPrice() {
    return `${this.quantity}${this.unitName === "kg" ? "" : " "}${pluralize(
      this.unitName,
      this.unitName === "kg" ? 1 : this.quantity
    )} · ${this.price} per ${pluralize(this.unitName, 1)}`;
  }

  get fishOn() {
    return get(this.data, "fish_on", false);
  }
}

/**
 * [ ENTITY ] PermanentBidEntity
 * @data
 * {
 *   id: 7
 *   settlement_days: 7
 *   description: xxxxx
 *   created_at: "2020-03-12T10:52:13.335+11:00"
 *   updated_at: "2020-03-12T10:52:13.335+11:00"
 *   user: {
 *      email: "geekerzp@gmail.com"
 *   }
 *   price: {price: "22.00", price_with_symbol: "$22.00"}
 * }
 */

export class PermanentBidEntity {
  constructor(props) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id", "");
  }

  get description() {
    return get(this.data, "description", "");
  }

  get email() {
    return get(this.data, "user.email", "");
  }

  get settlementDays() {
    return get(this.data, "settlement_days", "");
  }

  get date() {
    return Moment.getFormated(get(this.data, "updated_at", ""));
  }

  price(type) {
    const usecase = new PriceBaseEntity(get(this.data, "price", {}));
    return usecase.price(type);
  }
}

/**
 * [ ENTITY ] PermanentStatisticEntity
 * @data
  {
    "lowest_permanent_market_price":
    {
      "price": "0.00",
      "price_with_symbol": "$0.00"
    },
    "quota":
    {
      "id": 3,
      "unit_name": "shares",
      "category":
      {
        "id": 4,
        "name": "Abalone"
      },
      "species":
      {
        "id": 1,
        "name": "Blacklip Abalone"
      },
      "country":
      {
        "id": 1,
        "name": "Australia",
        "abbr": "AU"
      },
      "state":
      {
        "id": 2,
        "name": "Tasmania",
        "abbr": "TAS"
      },
      "zone":
      {
        "id": 3,
        "name": "Easten",
        "abbr": "E"
      }
    }
  }
 */

export class PermanentStatisticEntity {
  constructor(props) {
    this.data = props;
  }

  get lowestPrice() {
    const price = get(this.data, "lowest_permanent_market_price.price");
    return numeral(price).value();
  }

  get lowestPriceStr() {
    return get(
      this.data,
      "lowest_permanent_market_price.price_with_symbol",
      ""
    );
  }

  // lowestPrice(type) {
  //   const price = get(this.data, "lowest_permanent_market_price", {});
  //   const usecase = new PriceBaseEntity(price);
  //   return usecase.price(type);
  // }

  // highestPrice(type) {
  //   const price = get(this.data, "highest_permanent_market_price", {});
  //   const usecase = new PriceBaseEntity(price);
  //   return usecase.price(type);
  // }

  get quota() {
    return new QuotumEntity(get(this.data, "quota", {}));
  }
}

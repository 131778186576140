export const LISTING_MESSAGE_POST = "listing/leave_message_post";
export const LISTING_MESSAGE_LOADING = "listing/leave_message_loading";
export const LISTING_MESSAGE_SUCCESS = "listing/leave_message_success";
export const LISTING_MESSAGE_FAILED = "listing/leave_message_failed";
export const LISTING_MESSAGE_RESET = "listing/leave_message_reset";

export function listingMessagePost(id, payload = {}) {
  return {
    type: LISTING_MESSAGE_POST,
    id,
    payload
  };
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Header = ({ title, sub, className }) => {
  return (
    <div className={cx("", className)}>
      <div className="text-xl md:text-2xl font-medium md:leading-tight text-blue-gray-900">
        {title}
      </div>
      <div className="mt-2 text-xs md:text-base font-medium text-blue-gray-500">
        {sub}
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  sub: PropTypes.string,
  className: PropTypes.string,
};

export default Header;

import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { PERMANENT_DELETE_REQUEST, permanentDeleteAction } from "./action";

// destroy permanent
export function* watchAccountPermanentDelete() {
  yield takeLatest(PERMANENT_DELETE_REQUEST, permanentDelete);
}

function* permanentDelete(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "permanentDestroy"], action.payload);
    yield put(permanentDeleteAction.success(res.data));
  } catch (err) {
    yield put(permanentDeleteAction.failure(err));
  }
}

import { takeLatest, call, put } from "redux-saga/effects";

import { TransferApplyApiUsecase } from "@core";
import { TRANSFER_APPLY_CREATE_REQUEST, transferApplyCreateAction } from "./action";

// create quota transfer apply with market(lease)
export function* watchTransferApplyCreatePost() {
  yield takeLatest(TRANSFER_APPLY_CREATE_REQUEST, transferApplyCreatePost);
}

function* transferApplyCreatePost(action) {
  try {
    const usecase = new TransferApplyApiUsecase();
    const res = yield call([usecase, "create"], action.payload);
    yield put(transferApplyCreateAction.success(res.data));
  } catch (err) {
    yield put(transferApplyCreateAction.failure(err));
  }
}

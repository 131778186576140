import React from "react";

import toLower from "lodash/toLower";
import { reduxForm, Field } from "redux-form";
import {
  renderTextField,
  required,
  email,
} from "@components/reduxform";

const EmailForm = ({ handleSubmit }) => {
  return (
    <div className="account-form">
      <form onSubmit={handleSubmit}>
        <Field
          id="email_current"
          name="email_current"
          label="Current Email"
          type="text"
          validate={[required, email]}
          component={renderTextField}
          align="left"
          readOnly={true}
        />
        <Field
          id="email"
          name="email"
          label="New Email"
          type="text"
          placeholder="Enter"
          validate={[required, email]}
          component={renderTextField}
          align="left"
          parse={(value) => toLower(value)}
        />
      </form>
    </div>
  );
};

const EmailFormWithReduxForm = reduxForm({
  form: "EmailForm",
})(EmailForm);

export default EmailFormWithReduxForm;

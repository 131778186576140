
export const PROFILE_FETCH_REQUEST = "profile/fetch_request";
export const PROFILE_FETCH_LOADING = "profile/fetch_loading";
export const PROFILE_FETCH_SUCCESS = "profile/fetch_success";
export const PROFILE_FETCH_FAILED = "profile/fetch_failed";
export const PROFILE_REFRESH = "profile/refresh";

export function fetchProfile(payload = {}, params = {}) {
  return {
    type: PROFILE_FETCH_REQUEST,
    payload,
    params,
  };
}

export function refreshProfileList(data) {
  return {
    type: PROFILE_REFRESH,
    data,
  };
}

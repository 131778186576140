import { WithdrawApiRepo } from "@core";

export class WithdrawApiUsecase {

  constructor() {
    this.repo = new WithdrawApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }

  show(payload) {
    return this.repo.show(payload);
  }

  create(payload) {
    return this.repo.create(payload);
  }



}
import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_OPEN_ORDERS_REQUEST, accountOpenOrdersAction } from "./action";

// fetch account open orders list
export function* watchAccountOpenOrdersFetch() {
  yield takeLatest(ACCOUNT_OPEN_ORDERS_REQUEST, accountOpenOrdersFetch);
}

function* accountOpenOrdersFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "openOrders"], action.payload);
    yield put(accountOpenOrdersAction.success(res.data));
  } catch (err) {
    yield put(accountOpenOrdersAction.failure(err));
  }
}

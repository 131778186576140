import { useState, useEffect } from "react";

/**
 * import lib tools
 */
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, PaymentUsecase, PayTypeMap } from "@core";
import { paymentMethodMap, paymentValueMap, stripePayMin } from "@config/enum";
import Notice from "@utils/noticeNew";
import objectToParams, { getParamsByStr } from "@utils/objectToParams";

import {
  ORDER_PAYMENT,
  orderPaymentAction,
} from "@redux/modules_v2/orders/payment/action";
 
/**
 * [hooks] order 支付
 * @param {String|Number} id 订单id
 * @param {String|Number} balance 个人账户余额
 * @param {Instance<OrderUsecase>} usecaseOrder
 */
export const useOrderPayment = (id, balance, usecaseOrder) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(paymentMethodMap.stripe);
  const order = usecaseOrder.single;

  const [payType, setPayType] = useState(""); // "instant"全款，默认，提交时可为空|"hold_fee"|"deposit"押金|"balance"尾款

  useEffect(() => {
    const defaultPayType = order.depositPaid
      ? PayTypeMap.balance
      : PayTypeMap.instant;
    setPayType(defaultPayType);
  }, [order.depositPaid]);

  const orderPaymentStatus = useSelector((state) => {
    const selector = new LoadingSelector(ORDER_PAYMENT, state);
    return Object.assign({}, get(state, "orderPaymentStatusV2", {}), {
      loading: selector.loading,
    });
  });

  /**
   * 计算支付金额
   */
  const generateMoney = () => {
    const usecasePayment = new PaymentUsecase({});
    const payment = usecasePayment.calculation(order, paymentMethod, payType);
    return payment;
  };

  /**
   * watch order payment status
   */
  useEffect(() => {
    if (orderPaymentStatus.justFinished) {
      if (orderPaymentStatus.error) {
        paymentCallbackFaild();
      } else {
        paymentCallbackSuccessful();
      }
      dispatch(orderPaymentAction.received());
    }
  }, [orderPaymentStatus.justFinished]);

  const paymentCallbackFaild = () => {
    // const msg = get(staticData, "messages.error_order_pay", "Order pay error");
    const msg = get(orderPaymentStatus, "error", "Order pay error");
    Notice.failure(msg);
  };

  const paymentCallbackSuccessful = () => {
    const params = {};
    params.created_by = "order";
    if (order.howMuchLeft != 0) {
      params.no_licence = true;
    }

    if (paymentMethod === paymentMethodMap.poli) {
      const redirectUrl = get(orderPaymentStatus, "data.poli_navigate_url");
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
    
    if (paymentMethod === paymentMethodMap.stripe) {
      let redirectUrl = get(orderPaymentStatus, "data.stripe_navigate_url");
      if (redirectUrl) {
        const p1 = getParamsByStr(redirectUrl);
        const p2 = Object.assign({}, p1, params);
        const str = objectToParams(p2);
        const url = redirectUrl.split("?")[0] || "";
        history && history.push(`${url}${str ? "?" + str : ""}`);
      }
    }

    if (paymentMethod === paymentMethodMap.wallet) {
      const orderId = get(orderPaymentStatus, "data.order_id");
      if (orderId) {
        const p2 = Object.assign({}, {
          payMethod: "wallet",
          order_id: orderId,
        }, params);
        const str = objectToParams(p2);
        const redirectUrl = `/payment/successful?${str}`;
        history && history.push(redirectUrl);
      }
    }

    if (paymentMethod === paymentMethodMap.offline) {
      const orderId = get(orderPaymentStatus, "data.order_id");
      if (orderId) {
        const redirectUrl = `/payment/successful?payMethod=offline&order_id=${orderId}`;
        history && history.push(redirectUrl);
      }
    }
  };
 
  /**
   * 提交 payment
   */
  const submit = () => {
    if (paymentMethod) {
      const money = generateMoney();
      if(order.isFinished) {
        Notice.failure(
          "The order has ended, please do not repeat the payment"
        );
        return;
      }
      if (
        paymentMethod === paymentMethodMap.wallet &&
        balance < money.paymentTotal
      ) {
        Notice.failure(
          "Your balance is insufficient. Please top up your wallet."
        );
        return;
      }
      if (paymentMethod === paymentMethodMap.stripe) {
        if (money.paymentTotal < stripePayMin) {
          Notice.failure(
            `Minimum amount above $${stripePayMin} is required for Credit Card payment`
          );
          return;
        }
      }
      dispatch(orderPaymentAction.request({ id, paymentMethod, payType, history }));
    } else {
      Notice.failure("Please select your billing method.");
    }
  };

  return {
    money: {
      ...generateMoney(),
    },
    paymentMethod,
    setPaymentMethod,
    payType,
    setPayType,
    submit,
    loading: orderPaymentStatus.loading,
  };
};

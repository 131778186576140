import React from "react";
import { links } from "@config/staticLink";

/**
 * import lib components
 */
import { Button } from "@components";

const NoMatch = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="max-w-md w-full pb-12 px-6">
        <div className="text-center pb-6">
          <h3 className="text-fish-primary">Ooops!!</h3>
          <div className="pt-3">That page doesn't exist or is unavailable.</div>
          <Button href={links.home} className="mt-6">
            Go Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;

import toString from "lodash/toString";

/**
 * parse and format
 */


//保留数字
export const formatNumber = (value) => {
  return value.replace(/[^\d]/g, "");
};

//保留小数点后2位数字
export const formatFixedNumber = (val) => {
  let str = toString(val);
  str = str.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  // str = str.replace(/^\./g, ""); //验证第一个字符是数字而不是小数点
  str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  //只能输入一个小数点
  str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
  return str;
};

//保留首位不能为零的整数
export const formatInt = (value) => {
  if (value.length === 1) {
    return value.replace(/[^1-9]/g, "");
  }
  return value.replace(/\D/g, "");
};
import { useEffect, useState, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty, remove } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  ordersQuotaTransfersNewAction,
  ORDERS_QUOTA_TRANSFERS_NEW,
} from "@redux/modules_v2/orders/actions/quotaTransfers/new/action";

import Notice from "@utils/noticeNew";

export const useQuotaTransfer = (id, cb) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const onUpload = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file && beforeUpload(file)) {
        setFiles([file, ...files]);
      }
    },
    [id, files]
  );

  const onRemove = useCallback(
    (idx) => {
      const updatedFiles = remove(files, (_, index) => idx != index);
      setFiles(updatedFiles);
    },
    [files]
  );

  const beforeUpload = (file) => {
    const images = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
    const isImage = images.includes(file.type);
    if (!isImage) {
      Notice.failure("You can only upload image or pdf file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      Notice.failure("File must smaller than 5MB!");
    }
    return isImage && isLt5M;
  };

  const uploadRequest = useCallback(() => {
    if(isEmpty(files)) {
      Notice.failure("Please upload a copy.");
      return 
    }
    dispatch(ordersQuotaTransfersNewAction.request({ id, files }));
  }, [id, files]);

  const ordersQuotaTransfersNewStatus = useSelector((state) => {
    const selector = new LoadingSelector(ORDERS_QUOTA_TRANSFERS_NEW, state);
    return Object.assign({}, get(state, "ordersQuotaTransfersNewStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    return () => {
      dispatch(ordersQuotaTransfersNewAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!ordersQuotaTransfersNewStatus.loading) {
      if (!isEmpty(ordersQuotaTransfersNewStatus.data)) {
        Notice.success("Submit successfully!");
        dispatch(ordersQuotaTransfersNewAction.reset());
        typeof cb === "function" && cb();
      }
      if (ordersQuotaTransfersNewStatus.error) {
        Notice.failure(ordersQuotaTransfersNewStatus.error);
      }
    }
  }, [ordersQuotaTransfersNewStatus.loading]);

  const loading = ordersQuotaTransfersNewStatus.loading;
  const data = ordersQuotaTransfersNewStatus.data || {};

  return { onUpload, onRemove, uploadRequest, files, loading, data };
};

import moment from "moment-timezone";
// moment.updateLocale("en", {
//   relativeTime: {
//     future: "in %s",
//     past: "%s ago",
//     s: "a few seconds",
//     ss: "%d seconds",
//     m: "1 minute",
//     mm: "%d minutes",
//     h: "1 hour",
//     hh: "%d hours",
//     d: "1 day",
//     dd: "%d days",
//     M: "1 month",
//     MM: "%d months",
//     y: "1 year",
//     yy: "%d years",
//   },
// });
/**
 * Monment
 *
 * @example
 *
 * const str = "2020-03-02T18:31:38.148+11:00";
 * const dt = moment() | moment("2020-03-02T18:31:38.148+11:00");
 * const t1 = new Moment(str) | new Moment(dt);
 * t1.dateTime          // => a moment obj
 * t1.format            // => "02 Mar 2020 18:31PM"
 * t1.formatWithAgo     // => "5 mins/secs ago" | "Today" | "02 Mar 2020 18:31PM"
 * t1.formatDate        // => "02 Mar 2020"
 * t1.formatTime        // => "18:31PM"
 * t1.formatTimestamp   // => 时间戳 "1583208226856"
 * t1.diffTimestamp     // => 毫秒时间差
 * t1.isToday           // true|false 是不是今天
 * t1.isYesterday       // true|false 是不是昨天
 * t1.isDaysAfter(3)    // true|false 是不是3天后
 * t1.isExpired         // true|false 是不是过期了
 *
 * t1.setFormat("YYYY-MM-DD HH:mm") // 设置格式化格式
 *
 * t1.now()                // 待解释
 * t1.getCurrentTimeInMs() // 待解释
 *
 * Moment.getFormated(str|dt)      // => "02 Mar 2020 18:31PM"
 * Moment.getFormatedDate(str|dt)  // => "02 Mar 2020"
 * Moment.getFormatedTime(str|dt)  // => "18:31PM"
 */
export class Moment {
  constructor(data, formatStr = "DD MMM YYYY HH:mmA") {
    this.data = data;
    this.formatStr = formatStr;
  }

  /** 实例方法 **/

  getCurrentTimeInMs() {
    return window &&
      window.performance &&
      window.performance.now &&
      window.performance.timing &&
      window.performance.timing.navigationStart
      ? window.performance.now() + window.performance.timing.navigationStart
      : Date.now();
  }

  isInHour(h = 1) {
    const target = this.dateTime.add(h, "h");
    return moment().isBefore(target);
  }

  isDaysAfter(day = 0) {
    const afterDay = moment().add(day, "day").startOf("day");
    return this.dateTime > afterDay;
  }

  now({
    receive_timestamp,
    response_timestamp,
    request_timestamp,
    callback_timestamp,
  }) {
    const offset =
      (receive_timestamp -
        request_timestamp +
        (response_timestamp - callback_timestamp)) /
      2;
    return +new Date(this.getCurrentTimeInMs() + offset);
  }

  /** getter & setter **/

  set setFormat(formatStr) {
    this.formatStr = formatStr;
  }

  get dateTime() {
    // return moment.isMoment(this.data) ? this.data : moment(this.data);
    return moment.isMoment(this.data) ? this.data.tz("Australia/Sydney") : moment(this.data).tz("Australia/Sydney");
  }

  get format() {
    return this.dateTime.format(this.formatStr);
  }

  get formatDate() {
    return this.dateTime.format("DD MMM YYYY");
  }

  get formatTime() {
    return this.dateTime.format("HH:mm A");
  }

  get formatTimestamp() {
    return this.dateTime.format("x");
  }

  get formatWithAgo() {
    if (this.isInHour()) {
      return this.dateTime.fromNow();
    }
    if (this.isToday) {
      return "Today";
    }
    return this.format;
  }

  get isToday() {
    return this.dateTime.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD");
  }

  get isYesterday() {
    return (
      this.dateTime.format("YYYY-MM-DD") ==
      moment().subtract(1, "d").format("YYYY-MM-DD")
    );
  }

  get isExpired() {
    return moment().isAfter(this.dateTime);
  }

  get diffTimestamp() {
    if (Number(this.formatTimestamp) <= this.getCurrentTimeInMs()) {
      return 0;
    }
    return Number(this.formatTimestamp) - this.getCurrentTimeInMs();
  }

  get fromNow() {
    if (this.isExpired) {
      return false;
    }
    return moment(this.data).fromNow(true);
  }

  get toISOString() {
    return moment(this.data).toISOString();
  }

  /** 类方法 **/

  static getFormated(date) {
    const obj = new Moment(date);
    return obj.format;
  }

  static getFormatedDate(date) {
    const obj = new Moment(date);
    return obj.formatDate;
  }

  static getFormatedTime(date) {
    const obj = new Moment(date);
    return obj.formatTime;
  }

  static getDaysFromNow(date) {
    const obj = new Moment(date);
    return obj.fromNow;
  }

  static getISOString(date) {
    const obj = new Moment(date);
    return obj.toISOString;
  }
}

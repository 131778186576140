import {CognitoUser} from "amazon-cognito-identity-js";
import {userPool} from './config'
import {currentAuthenticatedUser} from './session'

/**
 * Change a password for an authenticated user
 * @param {Object} user - The CognitoUser object
 * @param {String} oldPassword - the current password
 * @param {String} newPassword - the requested new password
 * @return {Promise<any>} - A promise resolves if success
 */
function changePassword(user, oldPassword, newPassword) {
  return new Promise((resolve, reject) => {
    user.changePassword(oldPassword, newPassword, (err, data) => {
      if (err) {
        // console.log(`cognito change password failed: ${JSON.stringify(err)}`)
        return reject(err)
      }
      // console.log(`cognito change password success: ${JSON.stringify(err)}`)
      resolve(data)
    })
  })
}

/**
 * Change a password for the current user
 * @param {String} oldPassword - the current password
 * @param {String} newPassword - the requested new password
 * @return {Promise} - A promise resolves if success
 */
function changeCurrentUserPassword(oldPassword, newPassword) {
  return currentAuthenticatedUser()
    .then(user => changePassword(user, oldPassword, newPassword))
}

function forgotPassword(email) {
  let userData = {
    Username: email,
    Pool: userPool
  }
  let cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        // console.log(`cognito send forgot password verification code success: ${JSON.stringify(result)}`)
        resolve(result)
      },
      onFailure: (err) => {
        // console.log(`cognito send forgot password verification code failed: ${JSON.stringify(err)}`)
        reject(err)
      }
    })
  })
}

function confirmPassword(email, code, newPassword) {
  let userData = {
    Username: email,
    Pool: userPool
  }
  let cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: (result) => {
        // console.log(`cognito confirm password success: ${JSON.stringify(result)}`)
        resolve(result)
      },
      onFailure: (err) => {
        // console.log(`cognito confirm password failed: ${JSON.stringify(err)}`)
        reject(err)
      }
    })
  })
}

export {
  changePassword,
  changeCurrentUserPassword,
  forgotPassword,
  confirmPassword
}


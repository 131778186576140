export const GRADE_CHANGE = "product_show/grade_change";
export const ROLE_CHANGE = "product_show/role_change";
export const CHART_TIME_CHANGE = "product_show/chart_time_change";
// export const TABLE_ROLE_CHANGE = "product_show/table_role_change";
export const PRICE_SHOW_ALL = "product_show/price_show_all";
export const TRADE_SHOW_ALL = "product_show/trade_show_all";
// export const MODAL_ORDER_STATUS_CHANGE = "product_show/modal_order_status_change";
export const ORDER_TAB_CHANGE = "product_show/order_tab_change";
export const ORDER_FORM_CHANGE = "product_show/order_form_change";
export const MODAL_ORDER_CONFIRM_STATUS_CHANGE = "product_show/modal_order_confirm_status_change";
export const MODAL_ORDER_CONFIRM_RESULT_CHANGE = "product_show/modal_order_confirm_result_change";
export const ORDER_FORM_INPUT = "product_show/order_form_input";
export const RESET_ORDER_FORM_DATA = "product_show/reset_order_form";
export const MODAL_CART_STATUS_CHANGE = "product_show/modal_cart_status_change";
export const MODAL_CART_TAB_CHANGE = "product_show/modal_cart_tab_change";
export const MODAL_CART_AVAILABLE_CHANGE = "product_show/modal_cart_available_change";
export const CART_NEW_ITEM_TIPS_STATUS_CHANGE = "cart/new_item_tips_change";
export const MODAL_SET_PRICE_STATUS_CHANGE = "product_show/modal_set_price_status_change";

/**
 * 产品详情页规格切换
 * @param  {Object} payload 规格数据
 * @return {Object}
 * {type: GRADE_CHANGE, payload: {id: xxx, size: "West blacklip"}} //暂时是这样
 */
export function productShowGradeChange(payload) {
  return {
    type: GRADE_CHANGE,
    payload
  };
}

/**
 * 产品详情页角色切换
 * @param  {Object} payload 角色数据 {role: "buy|sell"}
 * @return {Object}
 * {type: ROLE_CHANGE, payload: {role: "buy"}}
 * {type: ROLE_CHANGE, payload: {role: "sell"}}
 */
export function productShowRoleChange(payload) {
  return {
    type: ROLE_CHANGE,
    payload
  };
}

/**
 * 产品详情页图表时间选择切换
 * @param  {Object} time  {time: "day|week|month|year"}
 * @return {Object}
 * {type: CHART_TIME_CHANGE, payload: {role: "buy"}}
 * {type: CHART_TIME_CHANGE, payload: {role: "sell"}}
 */
export function productShowChartTimeChange(time) {
  return {
    type: CHART_TIME_CHANGE,
    payload: {
      time,
    },
  };
}

/**
 * 产品详情页表格角色切换（用于mobile）
 * @param  {Object} payload 角色数据 {role: "buy|sell"}
 * @return {Object}
 * {type: TABLE_ROLE_CHANGE, payload: {role: "buy"}}
 * {type: TABLE_ROLE_CHANGE, payload: {role: "sell"}}
 */
// export function productShowTableRoleChange(payload) {
//   return {
//     type: TABLE_ROLE_CHANGE,
//     payload
//   };
// }


/**
 * 产品详情页 Price Table 显示全部切换
 * @param  {Object} payload 角色数据 {isShowAll: true|false}
 * @return {Object}
 * {type: PRICE_SHOW_ALL, payload: {isShowAll: true}}
 * {type: PRICE_SHOW_ALL, payload: {isShowAll: false}}
 */
export function productShowAllPriceChange(status) {
  return {
    type: PRICE_SHOW_ALL,
    payload: {
      isShowAll: status,
    },
  };
}

/**
 * 产品详情页 Trade Table 显示全部切换
 * @param  {Object} payload 角色数据 {isShowAll: true|false}
 * @return {Object}
 * {type: TRADE_SHOW_ALL, payload: {isShowAll: true}}
 * {type: TRADE_SHOW_ALL, payload: {isShowAll: false}}
 */
export function productShowAllTradeChange(status) {
  return {
    type: TRADE_SHOW_ALL,
    payload: {
      isShowAll: status,
    },
  };
}

/**
 * 产品详情页订单弹框开启／关闭切换
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: MODAL_ORDER_STATUS_CHANGE, payload: {isOpen: true}}
 * {type: MODAL_ORDER_STATUS_CHANGE, payload: {isOpen: false}}
 */
// export function productShowModalOrderStatusChange(payload) {
//   return {
//     type: MODAL_ORDER_STATUS_CHANGE,
//     payload
//   };
// }

/**
 * 产品详情页订单弹框里的 tab 切换
 * @param  {Object} payload tab数据
 * @return {Object}
 * {type: MODAL_ORDER_TAB_CHANGE, payload: {tabId: "tab1"}}
 * {type: MODAL_ORDER_TAB_CHANGE, payload: {tabId: "其他 tab id"}}
 */
export function productShowOrderTabChange(payload) {
  return {
    type: ORDER_TAB_CHANGE,
    payload
  };
}

/**
 * 产品详情页订单弹框里订单表单弹出／隐藏切换 （现用于mobile模式）
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: ORDER_FORM_CHANGE, payload: {isOpen: true}}
 * {type: ORDER_FORM_CHANGE, payload: {isOpen: false}}
 */
export function productShowModalOrderFormChange(payload) {
  return {
    type: ORDER_FORM_CHANGE,
    payload
  };
}

/**
 * 产品详情页订单确认弹框开启／关闭切换
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: MODAL_ORDER_CONFIRM_STATUS_CHANGE, payload: {isOpen: true}}
 * {type: MODAL_ORDER_CONFIRM_STATUS_CHANGE, payload: {isOpen: false}}
 */
export function productShowModalOrderConfirmStatusChange(payload) {
  return {
    type: MODAL_ORDER_CONFIRM_STATUS_CHANGE,
    payload
  };
}


/**
 * 产品详情页订单确认弹框中，切换显示订单结果
 * @param  {Object} payload {showResult: true|false}
 * @return {Object}
 * {type: MODAL_ORDER_CONFIRM_RESULT_CHANGE, payload: {showResult: true}}
 * {type: MODAL_ORDER_CONFIRM_RESULT_CHANGE, payload: {showResult: false}}
 */
export function productShowModalOrderConfirmResultChange(payload) {
  return {
    type: MODAL_ORDER_CONFIRM_RESULT_CHANGE,
    payload
  };
}

/**
 * 产品详情页订单表单内的输入框输入事件
 * @param  {Object} payload 输入并且处理后的数据
 * @return {Object}
 * {
 *   type: MODAL_ORDER_FORM_INPUT,
 *   payload: {
 *     quantity: Number,
 *     orderItems: [],
 *     total_boxes: Number,
 *     total_volume: Number,
 *     total_money: Float,
 *     price: Float,
 *   }
 * }
 */
export function productShowOrderFormQuantityInput(payload) {
  return {
    type: ORDER_FORM_INPUT,
    payload
  };
}

/**
 * 产品详情页重置表单数据
 * @return {Object}
 */
export function productShowResetState() {
  return {
    type: RESET_ORDER_FORM_DATA,
  };
}

/**
 * 产品购物车弹框开启／关闭切换
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: MODAL_CART_STATUS_CHANGE, payload: {isOpen: true}}
 * {type: MODAL_CART_STATUS_CHANGE, payload: {isOpen: false}}
 */
export function productShowModalCartStatusChange(payload) {
  return {
    type: MODAL_CART_STATUS_CHANGE,
    payload
  };
}

/**
 * 产品购物车弹框里的 tab 切换
 * @param  {Object} payload tab数据
 * @return {Object}
 * {type: MODAL_CART_TAB_CHANGE, payload: {tabId: "tab1"}}
 * {type: MODAL_CART_TAB_CHANGE, payload: {tabId: "其他 tab id"}}
 */
export function productShowModalCartTabChange(payload) {
  return {
    type: MODAL_CART_TAB_CHANGE,
    payload
  };
}

/**
 * 产品购物车PC端弹框开启／关闭切换条件，只在confirm弹出框关闭只有触发
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: MODAL_CART_STATUS_CHANGE, payload: {isAvailable: true}}
 * {type: MODAL_CART_STATUS_CHANGE, payload: {isAvailable: false}}
 */
export function productShowModalCartAvailableChange(payload) {
  return {
    type: MODAL_CART_AVAILABLE_CHANGE,
    payload
  };
}

/**
 * 添加新报价提示框开启／关闭切换
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: CART_NEW_ITEM_TIPS_STATUS_CHANGE, payload: {isOpen: true}}
 * {type: CART_NEW_ITEM_TIPS_STATUS_CHANGE, payload: {isOpen: false}}
 */
export function cartNewItemStatusChange(payload) {
  return {
    type: CART_NEW_ITEM_TIPS_STATUS_CHANGE,
    payload
  };
}

/**
 * 产品详情页设置加个确认弹框开启／关闭切换
 * @param  {Object} payload {isOpen: true|false}
 * @return {Object}
 * {type: MODAL_SET_PRICE_STATUS_CHANGE, payload: {isOpen: true}}
 * {type: MODAL_SET_PRICE_STATUS_CHANGE, payload: {isOpen: false}}
 */
export function productShowModalSetPriceStatusChange(payload) {
  return {
    type: MODAL_SET_PRICE_STATUS_CHANGE,
    payload
  };
}

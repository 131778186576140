import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, first } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { WithdrawUsecase, LoadingSelector } from "@core";
import {
  withdrawListAction,
  WITHDRAW_LIST,
} from "@redux/modules_v2/withdraws/list/action";

/**
 * [hooks] withdraw list
 * @returns { usecase: "a usecase by withdraw data", loading: true|false }
 */
export const useWithdrawList = () => {
  const dispatch = useDispatch();

  const fetchWithdrawList = () => {
    dispatch(withdrawListAction.request({}));
  };

  const withdrawListStatus = useSelector(state => {
    const selector = new LoadingSelector(WITHDRAW_LIST, state);
    return Object.assign({}, get(state, "withdrawListStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = withdrawListStatus.loading;
  const usecase = new WithdrawUsecase(
    get(withdrawListStatus, "data.array", [])
  );
  const lastWithdraw = first(usecase.list) || {};

  /**
   * did mount
   */
  useEffect(() => {
    fetchWithdrawList();
  }, []);

  return { usecase, lastWithdraw, loading };
};

import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class UserApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "users";
  }

  /**
   * User Api instance profile
   */
  profile(payload) {
    this.setApi(`${API}/${this.model}/profile`);
    return this.get(payload);
  }
}

/**
 * Component Select field
 *
 * @example
 * options:[{ label: "", value:""}]
 */

import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";

export default class SelectField extends Component {
  constructor(props) {
    super(props);
  }

  renderSingleOption = (option = {}, index) => {
    const { value, label, ...rest } = option;
    return (
      <option key={index} value={value} {...rest}>
        {label}
      </option>
    );
  };

  contentMarkRight = () => (
    <div className="gs-form-group-input-mark-right flex flex-col items-center justify-center">
      <span className="oi oi-caret-bottom color-info-dark" />
    </div>
  );

  render() {
    const {
      bsSize,
      label,
      id,
      name,
      disabled,
      error,
      onChange,
      onBlur,
      options,
      defaultValue,
      align,
      placeholder,
      value,
      className,
    } = this.props;
    return (
      <div className="form-element mb-4 gs-form-group-input">
        {label && (
          <label className="mb-2 label block text-sm font-bold leading-5">
            {label}
          </label>
        )}
        <select
          id={id || name}
          name={name}
          invalid={`${!!error}`}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          className={cx(
            "block w-full p-3 md:text-sm md:leading-5 border border-blue-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md form-control",
            className
          )}
        >
          {map(options, this.renderSingleOption)}
        </select>
        {error && <div className="mt-2 error">{error}</div>}
      </div>
    );
  }
}

SelectField.propTypes = {
  bsSize: PropTypes.string, //lg,sm
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  align: PropTypes.string,
};

SelectField.defaultProps = {
  bsSize: "lg",
  label: "",
  id: "",
  name: "",
  disabled: false,
  error: "",
  value: "",
  onChange: () => {},
  onBlur: () => {},
  options: [],
  align: "left",
};

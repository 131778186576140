import { takeEvery, all, call, put } from "redux-saga/effects";
import { change } from "redux-form";
import {
  UPLOAD_AVATAR_REQUEST,
  uploadAvatarLoading,
  uploadAvatarSuccess,
} from "./action";
import * as s3 from "@utils/s3";
import { updateAttributeFailed } from "../updateAttribute/action";

function* watchUploadAvatarRequest() {
  yield takeEvery(UPLOAD_AVATAR_REQUEST, uploadAvatar);
}

function* uploadAvatar(action) {
  yield put(uploadAvatarLoading());

  try {
    const res = yield call(s3.addAvatar, action.payload.file);
    yield all([
      put(change("InfoForm", "picture", res.Location)),
      put(uploadAvatarSuccess(res)),
    ]);
  } catch (err) {
    yield put(updateAttributeFailed(err));
  }
}

export { watchUploadAvatarRequest };

export const BID_CREATE = "bids/create";
export const BID_CREATE_REQUEST = `${BID_CREATE}_request`;
export const BID_CREATE_SUCCESS = `${BID_CREATE}_success`;
export const BID_CREATE_FAILURE = `${BID_CREATE}_failure`;
export const BID_CREATE_RECEIVED = `${BID_CREATE}_received`;

export const bidCreateAction = {
  request: (payload) => {
    return {
      type: BID_CREATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: BID_CREATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: BID_CREATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: BID_CREATE_RECEIVED
    }
  },
};

import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";

const Header = ({ logo, data, auctionId, children }) => {
  return (
    <div className="flex items-start lg:items-center">
      <div className="mr-4 lg:mr-6 w-16 h-16 flex items-center rounded-xl bg-white overflow-hidden flex-shrink-0">
        <img
          className="w-full h-full object-cover"
          src={logo}
          alt={get(data, "show.title", "")}
        />
      </div>
      <div>
        <div className="text-lg font-medium leading-tight text-blue-gray-900 flex-1 capitalize">
          {`Auction #${auctionId} ${get(data, "show.title", "")}`}
        </div>
        {children}
      </div>
    </div>
  );
};

Header.propTypes = {};

export default Header;

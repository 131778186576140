import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import cx from "classnames";
import get from "lodash/get";
import map from "lodash/map";

/**
 * [COMPONENT] Drop down
 * @param {Object}
 * @example
 * 1 默认toggle按钮 <Dropdown buttonName="button" buttonClassName="" className=""  open={false | true}  options={[{name: "", action: () => {}}]} />
 * 2 自定义按钮 <Dropdown key={`dropdown-${index}`} {...item}><div className="">custom button</div></Dropdown>
 * @ref https://tailwindui.com/components/application-ui/elements/dropdowns
 */

//[HOOKS] dropdown打开关闭状态hook
const useDropdownOpen = (open) => {
  //初始化状态
  const [isOpen, toggleOpen] = useState(open || false);
  const ref = useRef(null);

  //按esc键关闭dropdown
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      toggleOpen(false);
    }
  };

  //点击区域外关闭dropdown
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleOpen(false);
    }
  };

  //添加动作监听
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  return { ref, isOpen, toggleOpen };
};

/**
 * [COMPONENT] Overlay 打开关闭容器
 * @param {Object}
 * @example
 * <Overlay isOpen={isOpen} position={position}>...children</Overlay>
 */
const Overlay = ({ isOpen, full, fixed, position, children }) => {
  return (
    <CSSTransition
      in={isOpen}
      classNames="tw-dropdown"
      className={
        cx(
        {
          "absolute left-0 right-0": fixed,
          "absolute": !fixed,
        },
        "transform mt-2 z-10", {
        "left-0": position === "left",
        "origin-top-right right-0": position === "right",
        "max-w-md": !full,
        "w-full": full,
      })}
      timeout={300}
      unmountOnExit
    >
      <div className="rounded-lg shadow-lg">
        <div className="rounded-lg shadow-lg overflow-hidden bg-white">
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
Overlay.propTypes = {
  isOpen: PropTypes.bool,
  position: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  full: PropTypes.bool,
  fixed: PropTypes.bool,
};

Overlay.defaultProps = {
  children: "",
  full: false,
  isOpen: false,
  position: "left",
  fixed: false,
};

/**
 * [COMPONENT] Dropdown button 控制打开关闭dropdown menu 的按钮
 * @param {Object}
 * @example
 * <Overlay isOpen={isOpen} position={position}>...children</Overlay>
 */
const DropdownButton = ({
  buttonClassName,
  caret,
  children,
  currentItem,
  isOpen,
  renderButtonName,
  toggleOpen,
}) => {
  const childrenProps = get(children, "props", {});
  const childClassName = childrenProps.className;
  const DefaultButton = (
    <button
      type="button"
      className={cx("flex item-center", buttonClassName)}
      onClick={() => {
        toggleOpen(!isOpen);
      }}
    >
      <span>
        {typeof renderButtonName === "function"
          ? renderButtonName(currentItem.label)
          : currentItem.label}
      </span>
      {caret && (
        <svg className="h-5 w-5 ml-2" viewBox="0 0 18 18" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  );
  return children ? (
    typeof children === "string" ? (
      <div
        className={buttonClassName}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
      >
        {children}
      </div>
    ) : (
      React.cloneElement(children, {
        className: cx(childClassName, { open: isOpen }),
        onClick: () => {
          toggleOpen(!isOpen);
        },
      })
    )
  ) : (
    DefaultButton
  );
};

DropdownButton.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  currentItem: PropTypes.object,
  toggleOpen: PropTypes.func,
  buttonClassName: PropTypes.string,
  caret: PropTypes.bool,
};

DropdownButton.defaultProps = {
  isOpen: false,
  children: "",
  currentItem: {},
  toggleOpen: () => {},
  buttonClassName: "",
  caret: false,
};

/**
 * [COMPONENT] Dropdown menu menu
 * @param {Object}
 * @example
 * <Menu>{children}</Menu>
 */
const DropdownMenu = ({
  children,
  menuClassName,
  onChange,
  optionClassName,
  options,
  setCurrentItem,
  toggleOpen,
}) => {
  const DefaultMenu = (
    <div className={cx("py-4", menuClassName)}>
      {map(options, (option, index) => (
        <div
          key={`dropdown-option-${index}`}
          onClick={() => {
            toggleOpen(false);
            setCurrentItem(option);
            typeof onChange === "function" && onChange(option);
          }}
          className={cx(
            "block px-4 md:px-6 py-2-tw text-sm leading-5 text-blue-gray-700 hover:bg-gray-50 hover:text-blue-gray-900 focus:outline-none focus:bg-gray-50 focus:text-blue-gray-900 cursor-pointer",
            optionClassName
          )}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
  return children ? (
    <div className={menuClassName}>{children}</div>
  ) : (
    DefaultMenu
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  toggleOpen: PropTypes.func,
  menuClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  setCurrentItem: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

DropdownMenu.defaultProps = {
  children: "",
  toggleOpen: () => {},
  menuClassName: "",
  buttonClassName: "",
  setCurrentItem: () => {},
  onChange: () => {},
  options: [],
};

const Dropdown = ({
  button,
  buttonClassName,
  caret,
  children,
  className,
  full,
  isOpen,
  menuClassName,
  onChange,
  optionClassName,
  options,
  position,
  renderButtonName,
  fixed,
}) => {
  const { ref, isOpen: _isOpen, toggleOpen } = useDropdownOpen(isOpen);
  const location = useLocation();
  const [currentItem, setCurrentItem] = useState(get(options, "0", {}));
  useEffect(() => {
    toggleOpen(false);
  }, [location.pathname, location.search]);
  return (
    <div ref={ref} className={cx("relative", className)}>
      <DropdownButton
        buttonClassName={buttonClassName}
        caret={caret}
        currentItem={currentItem}
        isOpen={_isOpen}
        renderButtonName={renderButtonName}
        toggleOpen={toggleOpen}
      >
        {button}
      </DropdownButton>
      <Overlay isOpen={_isOpen} position={position} full={full} fixed={fixed}>
        <DropdownMenu
          menuClassName={menuClassName}
          onChange={onChange}
          optionClassName={optionClassName}
          options={options}
          setCurrentItem={setCurrentItem}
          toggleOpen={toggleOpen}
        >
          {children}
        </DropdownMenu>
      </Overlay>
    </div>
  );
};

Dropdown.propTypes = {
  button: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  buttonClassName: PropTypes.string,
  caret: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  menuClassName: PropTypes.string,
  onChange: PropTypes.func,
  optionClassName: PropTypes.string,
  options: PropTypes.array,
  position: PropTypes.string,
  full: PropTypes.bool,
  fixed: PropTypes.bool,
};

Dropdown.defaultProps = {
  className: "", //最外层 wrapper className,
  buttonClassName: "", //按钮 classname
  menuClassName: "",
  optionClassName: "",
  caret: false,
  button: "",
  isOpen: false, //默认状态dropdown是否打开
  position: "left",
  onChange: () => {},
  options: [],
  full: false,
  fixed: false,
};

export { useDropdownOpen, Dropdown };

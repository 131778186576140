import React from "react";
import PropTypes from "prop-types";

import { Button } from "@components";
import Transition from "./_transition";

const SubmitBtn = ({ loading, step, values }) => {
  const color = step === 2 ? "fish-dark" : "fish-primary";
  const className =
    step === 2
      ? "bg-fish-dark hover:bg-gray-900"
      : "bg-fish-primary hover:bg-fish-primary-700";
  const getValues = (step, values) => {
    const {
      email,
      first_name,
      fishery_id,
      interested,
      last_name,
      phone_number,
      selling,
      state,
    } = values;
    switch (step) {
      case 0:
        return {
          email,
          first_name,
          last_name,
          phone_number,
          state,
        };
      case 1:
        return {
          fishery_id,
          interested,
          selling,
        };
      default:
        return {};
    }
  };

  return (
    <Transition values={getValues(step, values)}>
      <Button
        type="submit"
        color={color}
        textColor="white"
        disabled={loading}
        loading={loading}
        full
        className={className}
      >
        Continue
      </Button>
    </Transition>
  );
};

SubmitBtn.propTypes = {
  loading: PropTypes.bool,
};

export default SubmitBtn;

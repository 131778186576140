import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useFormikContext, getIn } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import WithLabel from "./with_label";

/**
 * [Formik Component Input]
 * @param {Object} props
 * @example
 * <Input
 *   name='textFieldExample1'
 *   label='Input field label'
 *   hint='This is a hint'
 * />
 * @refs https://tailwindui.com/components/application-ui/forms/input-groups
 */
export const Input = ({
  className,
  disabled,
  errorMsg,
  id,
  name,
  label,
  onBlur,
  onFocus,
  placeholder,
  type,
  leftSymbol,
  rightSymbol,
  leftSymbolClass,
  rightSymbolClass,
  labelFontWeight,
  cols,
  rows,
  parse,
  onChange,
  ...rest
}) => {
  const { values, handleChange, handleBlur, touched } = useFormikContext();
  const touch = getIn(touched, name);
  const change = (e) => {
    const value = e.target.value;
    if (typeof parse === "function") {
      e.target.value = parse(value);
    }
    if( typeof onChange === "function") {
      onChange(e)
    }
    handleChange(e);
  };

  const blur =  (e) => {
    if( typeof onBlur === "function") {
      onBlur(e)
    }
    handleBlur(e);
  };

  const classNameWrapper = cx("flex rounded-md shadow-sm relative", {
    "mt-1": label,
  });
  const classNameInput = cx(
    "form-input block w-full flex-1 text-sm leading-5",
    className,
    {
      "border-red-300 text-fish-red placeholder-red-300 focus:border-red-300 focus:shadow-outline-red": errorMsg,
      "rounded-l-none ": leftSymbol,
      "rounded-r-none ": rightSymbol,
    }
  );

  return (
    <div className={classNameWrapper}>
      {leftSymbol && (
        <div className={cx("inline-flex items-center px-3 rounded-l-md border border-r-0 border-blue-gray-300 bg-gray-50 text-blue-gray-500 text-sm",
          leftSymbolClass
        )}>
          {leftSymbol}
        </div>
      )}
      {type == "textarea" ? (
        <TextareaAutosize
          minRows={3}
          maxRows={50}
          onChange={change}
          onFocus={onFocus}
          onBlur={blur}
          {...rest}
          name={name}
          cols={cols}
          rows={rows}
          className={classNameInput}
          id={id || name}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
        />
      ) : (
        <input
          value={getIn(values, name)}
          onChange={change}
          onFocus={onFocus}
          onBlur={blur}
          {...rest}
          id={id || name}
          name={name}
          className={classNameInput}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
        />
      )}
      {rightSymbol ? (
        <div
          className={cx(
            "inline-flex items-center px-3 rounded-r-md border border-l-0 border-blue-gray-300 bg-gray-50 text-blue-gray-500 text-sm",
            rightSymbolClass
          )}
        >
          {rightSymbol}
        </div>
      ) : (
        errorMsg && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-fish-red"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )
      )}
    </div>
  );
};

Input.propTypes = {
  errorMsg: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "color",
    "email",
    "hidden",
    "image",
    "number",
    "password",
    "range",
    "search",
    "tel",
    "url",
  ]),
  cols: PropTypes.string,
  rows: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  leftSymbol: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  rightSymbol: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  parse: PropTypes.func,
  labelFontWeight: PropTypes.string,
};

Input.defaultProps = {
  className: null,
  disabled: false,
  errorMsg: null,
  id: null,
  label: null,
  onBlur: null,
  onFocus: null,
  placeholder: null,
  required: false,
  type: "text",
  cols: "20",
  rows: "2",
  leftSymbol: null,
  rightSymbol: null,
  labelFontWeight: "medium",
};

export default WithLabel("input")(Input);

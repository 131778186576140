import axios from "axios";
import { takeLatest, all, takeEvery, call, put, select } from "redux-saga/effects";
import get from "lodash/get";

import {
  CART_ASKING_PRICES_FETCH_REQUEST,
  CART_ASKING_PRICES_FETCH_SUCCESS,
  CART_ASKING_PRICES_FETCH_LOADING,
  CART_ASKING_PRICES_FETCH_FAILED,
  CART_PRICES_UPDATE_REQUEST,
  CART_PRICES_UPDATE_LOADING,
  CART_PRICES_UPDATE_SUCCESS,
  CART_PRICES_UPDATE_FAILED,
  CART_PRICES_DELETE_REQUEST,
  CART_PRICES_DELETE_LOADING,
  CART_PRICES_DELETE_SUCCESS,
  CART_PRICES_DELETE_FAILED
} from "./askingPricesAuction";

import { CART_OFFER_PRICES_FETCH_REQUEST } from "./offerPricesAuction";

import { pendingAskingPricesAPI, cartPricesAPI } from "@config/api";

import { quoteType, cartResponse } from "@config/enum";

import { fetchOfferPriceList } from "../offerPrices/listAction";
import { fetchAskingPriceList } from "../askingPrices/listAction";

import format from "./_format";

export function* watchCartAskingPricesFetch() {
  yield takeEvery(CART_ASKING_PRICES_FETCH_REQUEST, cartAskingPricesFetch);
}

export function* cartAskingPricesFetch(action) {
  yield put({ type: CART_ASKING_PRICES_FETCH_LOADING });
  const { err, res } = yield call(cartAskingPricesFetchApi, action.payload);
  if (!res) {
    yield put({
      type: CART_ASKING_PRICES_FETCH_FAILED,
      error: "Get asking prices list failed."
    });
    return;
  }
  yield put({ type: CART_ASKING_PRICES_FETCH_SUCCESS, data: format(res) });
}

export function cartAskingPricesFetchApi(payload) {
  return axios
    .get(pendingAskingPricesAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

// watch cart prices update

export function* watchCartPricesUpdate() {
  yield takeEvery(CART_PRICES_UPDATE_REQUEST, cartPricesUpdate);
}

export function* cartPricesUpdate(action) {
  yield put({ type: CART_PRICES_UPDATE_LOADING });
  const { err, res } = yield call(cartPricesUpdateApi, action.payload);
  const type = get(action, "payload.type");
  const pageStates = yield select(state => state.pageStates);
  const currentGradeId = get(pageStates, "productShowState.grade.id");
  if (get(err, "response.status") === 409) {
    yield put({
      type: CART_PRICES_UPDATE_FAILED,
      error: cartResponse.conflict
    });
    return;
  }
  if (get(err, "response.data.errors") || !res) {
    yield all([
      put({
        type: CART_PRICES_UPDATE_FAILED,
        error: get(err, "response.data.errors")
      })
    ]);
    return;
  }
  yield all([
    put({ type: CART_PRICES_UPDATE_SUCCESS, data: res }),
    fetchCartPricesList(type),
    updateProductPrice(type, currentGradeId)
  ]);
}

function cartPricesUpdateApi({
  type,
  price_id,
  price,
  boxes_count,
  base_boxes_count
}) {
  return axios
    .patch(cartPricesAPI(type, price_id), {
      price,
      boxes_count,
      base_boxes_count
    })
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

// watch cart prices delete
export function* watchCartPricesDelete() {
  yield takeEvery(CART_PRICES_DELETE_REQUEST, cartPricesDelete);
}

export function* cartPricesDelete(action) {
  yield put({ type: CART_PRICES_DELETE_LOADING });
  const { res, err } = yield call(cartPricesDeleteApi, action.payload);
  const type = get(action, "payload.type");
  const pageStates = yield select(state => state.pageStates);
  const currentGradeId = get(pageStates, "productShowState.grade.id");
  if (get(res, "status") === 204) {
    yield all([
      put({
        type: CART_PRICES_DELETE_SUCCESS,
        data: "Delete price success"
      }),
      fetchCartPricesList(type),
      updateProductPrice(type, currentGradeId)
    ]);
    return;
  }
  if (get(err, "response.status") === 409) {
    yield put({
      type: CART_PRICES_DELETE_FAILED,
      error: cartResponse.conflict
    });
    return;
  }
  yield put({
    type: CART_PRICES_DELETE_FAILED,
    error: "Delete price failed"
  });
}
function cartPricesDeleteApi({ type, price_id, base_boxes_count }) {
  return axios
    .delete(cartPricesAPI(type, price_id), { params: { base_boxes_count } })
    .then(res => ({
      res: res
    }))
    .catch(err => ({
      err
    }));
}

export function fetchCartPricesList(type) {
  switch (type) {
    case quoteType.buy:
      return put({ type: CART_OFFER_PRICES_FETCH_REQUEST });
    case quoteType.sell:
      return put({ type: CART_ASKING_PRICES_FETCH_REQUEST });
    default:
      return "";
  }
}

export function updateProductPrice(type, currentGradeId) {
  switch (type) {
    case quoteType.buy:
      return put(fetchOfferPriceList({ productGradeId: currentGradeId }));
    case quoteType.sell:
      return put(fetchAskingPriceList({ productGradeId: currentGradeId }));
    default:
      return "";
  }
}

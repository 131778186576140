import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  ORDERS_CREATE_POST,
  ORDERS_CREATE_LOADING,
  ORDERS_CREATE_SUCCESS,
  ORDERS_CREATE_FAILED
} from "./createAction";

import { ordersGradeAPI } from "@config/api";

//post order for create
export function* watchOrderPost() {
  yield takeLatest(ORDERS_CREATE_POST, orderPost);
}

export function* orderPost(action) {
  yield put({ type: ORDERS_CREATE_LOADING });
  const { err, res } = yield call(
    orderPostApi,
    action.productId,
    action.payload
  );

  if (!res) {
    yield put({
      type: ORDERS_CREATE_FAILED,
      error: "Post order failed."
    });
    return;
  }
  yield put({ type: ORDERS_CREATE_SUCCESS, data: res });
}

export function orderPostApi(productId, payload) {
  return axios
    .post(ordersGradeAPI(productId), payload)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import {
  FISHERY_FOLLOW_SUCCESS,
  FISHERY_FOLLOW_FAILURE,
  FISHERY_FOLLOW_RESET
} from "./action";

export const fisheryFollowStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case FISHERY_FOLLOW_SUCCESS:
      return {
        data: action.data,
        error: null
      };

    case FISHERY_FOLLOW_FAILURE:
      return {
        data: null,
        error: action.error
      };
      
    case FISHERY_FOLLOW_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null
};

import { takeLatest, call, put } from "redux-saga/effects";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_SHOW_REQUEST, auctionShowAction } from "./action";

//Fetch auction show
export function* watchAuctionShowFetch() {
  yield takeLatest(AUCTION_SHOW_REQUEST, auctionShowFetch);
}

function* auctionShowFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(auctionShowAction.success(res.data));
  } catch (err) {
    yield put(auctionShowAction.failure(err));
  }
}

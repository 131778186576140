export const QUOTA_INDEX_GRADE_CHANGE = "quota_index/grade_change";
export const QUOTA_INDEX_GRADE_DROPDOWN_CHANGE = "quota_index/grade_dropdown_change";
export const QUOTA_INDEX_LIST_TAB_CHANGE = "quota_index/list_tab_change";

/**
 * 报价列表页 grade 切换
 * @param  {Object} grade
 * @return {Object}
 * {type: QUOTA_INDEX_GRADE_CHANGE, grade: {id: xxx, name: xxx, ...}}
 */
export function quotaIndexGradeChange(grade) {
  return {
    type: QUOTA_INDEX_GRADE_CHANGE,
    grade
  };
}

/**
 * 报价列表页 grade下拉 状态切换
 * @param  {Object} status
 * @return {Object}
 * {type: QUOTA_INDEX_GRADE_DROPDOWN_CHANGE, payload: {isOpen: true|false }}
 */
export function quotaIndexGradeDropdownChange(status) {
  return {
    type: QUOTA_INDEX_GRADE_DROPDOWN_CHANGE,
    payload: {
      isOpen: status,
    },
  };
}


/**
 * 报价列表页 Tab 切换
 * @param  {String} tabId
 * @return {Object}
 * {type: QUOTA_INDEX_LIST_TAB_CHANGE, payload: { tabId: "tab1|tab2" }}
 */
export function quotaIndexListTabChange(tabId) {
  return {
    type: QUOTA_INDEX_LIST_TAB_CHANGE,
    payload: {
      tabId,
    },
  };
}
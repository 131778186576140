import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  PRICEABLES_PRODUCTS_FETCH_REQUEST,
  PRICEABLES_PRODUCTS_FETCH_LOADING,
  PRICEABLES_PRODUCTS_FETCH_SUCCESS,
  PRICEABLES_PRODUCTS_FETCH_FAILED,
} from "./productsAction";

import {
  priceablesAPI,
} from "@config/api";


//fetch priceables products list
export function* watchPriceablesProductsFetch() {
  yield takeEvery(PRICEABLES_PRODUCTS_FETCH_REQUEST, priceablesProductsFetch);
}

export function* priceablesProductsFetch(action) {
  yield put({ type: PRICEABLES_PRODUCTS_FETCH_LOADING });
  const { err, res } = yield call(priceablesProductsFetchApi);

  if (!res) {
    yield put({
      type: PRICEABLES_PRODUCTS_FETCH_FAILED,
      error: "Fetch priceables products list failed."
    });
    return;
  }
  yield put({ type: PRICEABLES_PRODUCTS_FETCH_SUCCESS, data: res });
}

export function priceablesProductsFetchApi() {
  return axios
    .get(priceablesAPI('products'))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

export const DASHBOARD_ORDER_STATUS_CHANGE = "dashboard/order_status_change";
export const DASHBOARD_ORDER_KIND_CHANGE = "dashboard/order_kind_change";

/**
 * 个人中心页订单状态tab切换
 * @param  {Object} status 订单类型 "not_done|complete"
 * @return {Object}
 * {type: ORDER_KIND_CHANGE, payload: {orderKind: "not_done"}}
 * {type: ORDER_KIND_CHANGE, payload: {orderKind: "complete"}}
 */
export function dashboardOrderStatusChange(status) {
  return {
    type: DASHBOARD_ORDER_STATUS_CHANGE,
    payload: {
      status
    },
  };
}

/**
 * 个人中心页订单种类切换
 * @param  {Object} kind 订单类型 "all|purchased|sold|outdated"
 * @return {Object}
 */
export function dashboardOrderKindChange(kind) {
  return {
    type: DASHBOARD_ORDER_KIND_CHANGE,
    payload: {
      orderKind: kind,
    },
  };
}

import { takeEvery, all, call, put } from "redux-saga/effects";
import { signIn } from "@utils/cognitoAuth";
import setAuthorizationToken from "@utils/setAuthorizationToken";
import get from "lodash/get";

import {
  LOGIN_REQUEST,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from "./action";

import { setCurrentUser } from "../auth/action";

function* watchLoginRequest() {
  yield takeEvery(LOGIN_REQUEST, loginRequest);
}

function* loginRequest(action) {
  yield put({ type: LOGIN_LOADING });
  let { res, err } = yield call(loginRequestCall, action.payload);

  if (err) {
    yield put({
      type: LOGIN_FAILED,
      error: err,
    });
    return;
  }

  let userData = get(res, "idToken.payload", {});
  userData.token = get(res, "idToken.jwtToken");
  setAuthorizationToken();

  yield all([
    put({ type: LOGIN_SUCCESS, data: res }),
    put(setCurrentUser(userData)),
  ]);
}

function loginRequestCall({ email, password }) {
  return signIn(email, password)
    .then((res) => {
      return { res: res };
    })
    .catch((err) => {
      return { err: err };
    });
}

export { watchLoginRequest };

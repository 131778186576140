import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Link } from "react-router-dom";

const MySales = ({ auctionList, instantList, pageSoldData }) => {
  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Link
          to="/listings?type=auction"
          >
          <div className="py-3 px-4 bg-white shadow-sm md:shadow-t-xs rounded-lg transition duration-300 ease-in-out border border-transparent hover:border-fish-primary flex justify-between items-center">
            <div className="flex-1 flex items-center">
              <div className="mr-5">
                <svg className="w-8" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.8" d="M27.8304 23.6558L18.0455 13.9522C17.8205 13.7222 17.4467 13.7222 17.2148 13.9522L17.1075 14.0586L15.3354 12.2977L20.638 6.94526C20.8629 6.72214 20.8629 6.35144 20.638 6.12146L15.6088 1.13748C15.3838 0.914373 15.01 0.914373 14.7781 1.13748L2.71861 13.4422C2.49363 13.6653 2.48671 14.036 2.71861 14.266L7.74433 19.25C7.97623 19.4731 8.34312 19.4731 8.57502 19.25L13.8332 13.7909L15.6088 15.5517L15.5015 15.6581C15.2765 15.8812 15.2765 16.252 15.5015 16.4819L25.2864 26.1856C25.5183 26.4156 25.8852 26.4087 26.1171 26.1856L27.8374 24.4796C28.0554 24.2531 28.0554 23.8824 27.8304 23.6558Z" fill="#4DC8EC"/>
                  <g opacity="0.6">
                  <path d="M11.4761 3.35955L18.4885 10.3138C18.7204 10.5437 19.0908 10.5437 19.3262 10.3172L21.7144 7.94879C21.9394 7.71881 21.9394 7.3481 21.7144 7.12499L14.695 0.167334C14.4631 -0.0557779 14.0893 -0.0557779 13.8643 0.167334L11.4761 2.53575C11.2511 2.76573 11.2511 3.13644 11.4761 3.35955Z" fill="#302DE4"/>
                  <path d="M1.59772 13.1593L8.61018 20.1135C8.84208 20.3401 9.20897 20.3401 9.44088 20.1101L11.8014 17.7691C12.0264 17.5392 12.0264 17.1684 11.8014 16.9453L4.78898 9.99455C4.55708 9.77144 4.19019 9.77144 3.95828 9.99455L1.59772 12.3355C1.37274 12.5655 1.37274 12.9293 1.59772 13.1593Z" fill="#302DE4"/>
                  </g>
                  <path d="M0.657634 24.9622H16.3509C16.7143 24.9622 17.012 25.254 17.005 25.6144V27.3478C17.005 27.7048 16.7108 28 16.3474 28H0.657634C0.297666 28 0 27.7082 0 27.3478V25.6144C0 25.2574 0.294205 24.9622 0.657634 24.9622Z" fill="#6ED1EE"/>
                </svg>
              </div>
              <div>
                <div className="text-sm font-medium text-blue-gray-800">My Auction Listings</div>
                <div className="mt-px text-xxs text-blue-gray-500">{auctionList.length} active auctions</div>
              </div>
            </div>
            <div className="ml-8">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 4.16669L13.3333 10L7.5 15.8334" stroke="#CDD5E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </Link>
        <Link
          to="/listings?type=exchange"
          >
          <div className="py-3 px-4 bg-white shadow-sm md:shadow-t-xs rounded-lg transition duration-300 ease-in-out border border-transparent hover:border-fish-primary flex justify-between items-center">
            <div className="flex-1 flex items-center">
              <div className="mr-5">
                <svg className="w-8" width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M26.3698 5.84645L13.7209 11.1111L1.04287 5.84642L13.6912 0.848511L26.3698 5.84645ZM0.000977062 8.03539L12.6207 13.4185V27.9982C11.6177 27.5445 10.5757 27.0714 9.49502 26.5785C8.56866 26.1647 7.52185 25.7013 6.35412 25.1883C5.18643 24.6754 4.01404 24.1627 2.83715 23.6504C2.41231 23.4529 2.02632 23.1917 1.67934 22.8668C1.33221 22.5419 1.03302 22.1869 0.781706 21.8021C0.530424 21.4172 0.337503 21.0081 0.202799 20.5746C0.068275 20.1412 0.000578911 19.7173 0 19.3027V8.03539H0.000977062ZM27.1515 8.03539V19.244C27.1515 19.757 27.0548 20.2547 26.8616 20.7374C26.6683 21.2201 26.4222 21.6737 26.1234 22.0984C25.8245 22.5229 25.4917 22.8977 25.1249 23.2226C24.7581 23.5475 24.4011 23.7989 24.0541 23.9768C23.0316 24.4109 21.9606 24.8647 20.841 25.3379C19.7214 25.8109 18.6889 26.2447 17.7435 26.6391C16.6438 27.1123 15.5632 27.5659 14.5017 28V13.4204L27.1515 8.03539Z" fill="#71D3F0"/>
                  <path d="M13.7208 11.1111L26.3698 5.84645L13.6912 0.848511L1.04285 5.84642L13.7208 11.1111Z" fill="#71D3F0"/>
                  <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M24.1818 11.0303C27.2277 11.0303 29.697 8.56109 29.697 5.51515C29.697 2.46922 27.2277 0 24.1818 0C21.1359 0 18.6667 2.46922 18.6667 5.51515C18.6667 8.56109 21.1359 11.0303 24.1818 11.0303ZM23.5739 5.96095L22.2234 4.22967L21.2121 4.94134L23.3143 7.63636L24.3256 6.9247L24.3231 6.92153L28 4.35761L27.2551 3.39394L23.5739 5.96095Z" fill="#302DE4"/>
                </svg>
              </div>
              <div>
                <div className="text-sm font-medium text-blue-gray-800">My Instant Listings</div>
                <div className="mt-px text-xxs text-blue-gray-500">{instantList.length} active consignments</div>
              </div>
            </div>
            <div className="ml-8">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 4.16669L13.3333 10L7.5 15.8334" stroke="#CDD5E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </Link> 
        <Link
          to="/orders?type=sales"
          >
          <div className="py-3 px-4 bg-white shadow-sm md:shadow-t-xs rounded-lg transition duration-300 ease-in-out border border-transparent hover:border-fish-primary flex justify-between items-center">
            <div className="flex-1 flex items-center">
              <div className="mr-5">
                <svg className="w-8" width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M19.7356 17.9187C24.6837 17.9187 28.6949 13.9074 28.6949 8.95933C28.6949 4.01123 24.6837 0 19.7356 0C14.7875 0 10.7762 4.01123 10.7762 8.95933C10.7762 13.9074 14.7875 17.9187 19.7356 17.9187ZM20.3145 13.1449V14.5589H19.0693V13.2577C18.2286 13.2143 17.4117 12.9541 16.9358 12.6678L17.3086 11.063C17.8321 11.3753 18.5776 11.6702 19.3945 11.6702C20.1241 11.6702 20.608 11.3579 20.608 10.8201C20.608 10.2996 20.2035 9.97 19.2676 9.62298C17.9273 9.12847 17.0072 8.44316 17.0072 7.10728C17.0072 5.88412 17.7845 4.93856 19.1249 4.66097V3.35975H20.3543V4.56555C21.1791 4.59157 21.7502 4.79977 22.1864 5.01661L21.8136 6.56939C21.8038 6.56428 21.7937 6.55903 21.7833 6.55364C21.4597 6.38498 20.8814 6.08365 20.0053 6.08365C19.1884 6.08365 18.9266 6.48269 18.9266 6.86437C18.9266 7.30677 19.3628 7.61041 20.4495 8.02681C21.9405 8.60805 22.5354 9.36274 22.5354 10.6119C22.5354 11.8351 21.7581 12.8848 20.3147 13.145L20.3145 13.1449Z" fill="#302DE4"/>
                  <path opacity="0.8" d="M14.7986 21.139C14.8612 21.2267 14.9239 21.3144 14.9865 21.3896C15.0617 21.4773 15.2121 21.5525 15.3123 21.5525C17.8685 21.565 20.4248 21.565 22.981 21.5525C23.1063 21.5525 23.2817 21.4272 23.3694 21.3019C23.8832 20.5501 24.3719 19.7857 24.8856 19.0464C24.9608 18.9336 25.1488 18.8209 25.2741 18.8209C26.9281 18.8083 28.5696 18.8083 30.2236 18.8083C30.3489 18.8083 30.4492 18.9086 30.4492 19.0339C30.4241 19.6228 30.4241 20.2243 30.3364 20.8007C30.2362 21.4648 30.0733 22.1289 29.8227 22.7554C29.0332 24.7979 27.6173 26.264 25.65 27.2038C24.5974 27.705 23.4697 27.9807 22.2918 27.9807C18.7833 27.9807 15.2747 28.0433 11.7662 27.9431C9.48562 27.8804 7.59351 26.8028 6.08984 25.0861C6.01466 24.9984 5.97707 24.8606 5.97707 24.7478C5.97707 21.5776 5.97707 18.3948 5.96454 15.2246C5.96454 14.9239 6.11491 14.9113 6.32792 14.9113C9.67358 14.9113 13.0318 14.9113 16.3774 14.9113C17.7307 14.9113 19.0965 14.8863 20.4498 14.9364C21.9284 14.9865 23.1564 16.4776 23.0436 17.9562C22.9309 19.4599 21.7405 20.6754 20.0489 20.6754C18.3698 20.6879 16.6907 20.6754 15.0116 20.6754C14.7986 20.6879 14.6607 20.951 14.7986 21.139Z" fill="#4DC8EC"/>
                  <path opacity="0.8" d="M0 14.1477C0 14.0235 0.0902855 13.9185 0.207657 13.9185C1.42651 13.9185 2.64536 13.9185 3.86422 13.9089C4.0809 13.9089 4.13507 13.9853 4.13507 14.2051C4.12605 18.3034 4.12605 22.4017 4.13507 26.5001C4.13507 26.7389 4.0809 26.8154 3.84616 26.8154C2.63634 26.8058 1.42651 26.8058 0.216685 26.8058H0.207657C0.0902855 26.8249 0 26.7198 0 26.5956V14.1477Z" fill="#4DC8EC"/>
                </svg>
              </div>
              <div>
                <div className="text-sm font-medium text-blue-gray-800">My Sales Orders</div>
                <div className="mt-px text-xxs text-blue-gray-500">{get(pageSoldData, "total", "")} recent orders</div>
              </div>
            </div>
            <div className="ml-8">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 4.16669L13.3333 10L7.5 15.8334" stroke="#CDD5E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </Link> 
      </div>
    </div>
  );
};

MySales.propTypes = {
};

MySales.defaultProps = {
};

export default MySales;

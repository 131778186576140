export const FISHERY_FOLLOW_LIST = "fishery/follow_list";
export const FISHERY_FOLLOW_LIST_REQUEST = `${FISHERY_FOLLOW_LIST}_request`;
export const FISHERY_FOLLOW_LIST_SUCCESS = `${FISHERY_FOLLOW_LIST}_success`;
export const FISHERY_FOLLOW_LIST_FAILURE = `${FISHERY_FOLLOW_LIST}_failure`;

export const fisheryFollowListAction = {
  request: (payload) => {
    return {
      type: FISHERY_FOLLOW_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_FOLLOW_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_FOLLOW_LIST_FAILURE,
      error,
    }
  },
};

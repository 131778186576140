export const LISTING_OFFER_NEW = "listing/create_offer";
export const LISTING_OFFER_NEW_REQUEST = `${LISTING_OFFER_NEW}_request`;
export const LISTING_OFFER_NEW_SUCCESS = `${LISTING_OFFER_NEW}_success`;
export const LISTING_OFFER_NEW_FAILURE = `${LISTING_OFFER_NEW}_failure`;
export const LISTING_OFFER_NEW_RESET = `${LISTING_OFFER_NEW}_reset`;

export const listingOfferNewAction = {
  request: (payload) => {
    return {
      type: LISTING_OFFER_NEW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: LISTING_OFFER_NEW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: LISTING_OFFER_NEW_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: LISTING_OFFER_NEW_RESET
    }
  },
};

import { UserApiRepo } from "@core";

export class UserApiUsecase {
  constructor() {
    this.repo = new UserApiRepo();
  }

  profile(payload) {
    return this.repo.profile(payload);
  }
}

import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import Link from "./link";

/**
 * Breadcrumb
 * @param {Object} props
 * @example <Breadcrumb data={[{name:"fishery name", link: "/fisheries/1"}]} />
 */

const Breadcrumb = ({ className, data, loading, back, backIcon }) => {
  const onBack = () => {
    if (window.history.length >= 1 && window.history.length <= 2) {
      window.location.href = "/"
      return;
    }
    window.history.back();
  };
  const renderBackIcon = () => {
    return (
      <div className={className}>
        <button onClick={onBack}>&times;</button>
      </div>
    );
  };

  const renderBack = () => {
    return (
      <button
        onClick={onBack}
        className="flex items-center text-xxs leading-5 text-blue-gray-500 hover:text-blue-gray-700 transition duration-150 ease-in-out"
      >
        <svg
          className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-blue-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
        Back
      </button>
    );
  };

  if (back) {
    return (
      <div className={className}>
        <nav> {renderBack()}</nav>
      </div>
    );
  }

  if (backIcon) {
    return renderBackIcon();
  }

  return (
    <div className={className}>
      <nav className="md:hidden">{renderBack()}</nav>
      <nav className="hidden md:flex items-center text-xxs leading-5">
        <Link
          to="/"
          className="text-blue-gray-500 hover:text-blue-gray-900 transition duration-150 ease-in-out"
        >
          Home
        </Link>
        {!loading &&
          map(data, (item = {}, index) => (
            <React.Fragment key={index}>
              <svg
                className="flex-shrink-0 mx-2 h-5 w-5 text-blue-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <Link
                to={item.link}
                className="transition duration-150 ease-in-out text-blue-gray-500 hover:text-blue-gray-900"
              >
                {item.name}
              </Link>
            </React.Fragment>
          ))}
      </nav>
    </div>
  );
};

Breadcrumb.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  back: PropTypes.bool,
  backIcon: PropTypes.bool,
  loading: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  data: [],
  className: "",
  back: false,
  backIcon: false,
  loading: false,
};

export default Breadcrumb;

import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  currentUserAttributes,
  updateCurrentUserAttributes,
} from "@utils/cognitoAuth";
/**
 * import lib tools
 */
import getQueryString from "@utils/getUrlQuery";
import { licenceNumberEventsRequest } from "@redux/modules/licenceNumberEvents/action";

export const useUpdateLicenceHook = () => {
  const [res, setRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jsutFinished, setJustFinished] = useState(false);
  const [err, setErr] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(attributes => {
    const licence_number = attributes["custom:licence_number"];
    const orderId = getQueryString("order_id");
    setLoading(true);
    updateCurrentUserAttributes(attributes)
      .then(res => {
        setRes(res);
        setLoading(false);
        setJustFinished(true);
        dispatch(licenceNumberEventsRequest({ licence_number, orderId }));
      })
      .catch(err => {
        setLoading(false);
        setJustFinished(true);
        setErr(err);
      });
  }, []);

  return [handleSubmit, setJustFinished, jsutFinished, loading, res, err];
};

export const useFetchLicenceHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState({});

  useEffect(() => {
    setLoading(true);
    currentUserAttributes()
      .then(attrs => {
        setLoading(false);
        setRes({ "custom:licence_number": attrs["custom:licence_number"] });
      })
      .catch(err => {
        setLoading(false);
        setError({ err });
      });
  }, []);

  return [res, loading, error];
};

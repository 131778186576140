export const COUNTRIES_LIST = "countries/list";
export const COUNTRIES_LIST_REQUEST = `${COUNTRIES_LIST}_request`;
export const COUNTRIES_LIST_SUCCESS = `${COUNTRIES_LIST}_success`;
export const COUNTRIES_LIST_FAILURE = `${COUNTRIES_LIST}_failure`;

export const countriesListAction = {
  request: (payload) => {
    return {
      type: COUNTRIES_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: COUNTRIES_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: COUNTRIES_LIST_FAILURE,
      error,
    }
  },
};

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

const Line = ({ data, className, vertical }) => {
  const bgColor = get(data, "show.color.bg", "");
  const isSeller = get(data, "show.seller", false);

  if (isSeller) {
    return null;
  }

  if (vertical) {
    return (
      <div className={cx("w-1 h-full", className, bgColor ? bgColor : "")} />
    );
  }
  return (
    <div
      className={cx(
        "h-1 w-full rounded-t-lg",
        className,
        bgColor ? bgColor : ""
      )}
    />
  );
};

Line.propTypes = {};

export default Line;

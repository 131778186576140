
export const ORDERS_PAYMENT_POST = "orders/payment_post";
export const ORDERS_PAYMENT_LOADING = "orders/payment_loading";
export const ORDERS_PAYMENT_SUCCESS = "orders/payment_success";
export const ORDERS_PAYMENT_FAILED = "orders/payment_failed";
export const ORDERS_PAYMENT_RECEIVED = "orders/payment_received";

/**
 * 订单支付
 * @param  {Number} orderId 订单ID
 * @param  {String} kind    支付方式 "stripe|poli|wallet"
 * @return {Object}
 * {
 *   type: ORDERS_PAYMENT_POST,
 *   orderId: 1,
 *   payload: { kind: "stripe|wallet" }
 * }
 */
export function orderPaymentPost(orderId, kind) {
  return {
    type: ORDERS_PAYMENT_POST,
    orderId,
    payload: {
      kind,
    }
  };
}

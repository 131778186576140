import React from "react";
import { get, take } from "lodash";
import { ChartFull } from "@views/common";

const ShowChart = (props) => {
  const { data: statistics } = props;
  const chartData = get(statistics, "latest_transcation") || [];

  return (
    <div className="pt-4">
      <ChartFull data={chartData} />
    </div>
  );
};

export default ShowChart;

import {
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  REGISTER_RESET,
} from "./action";

function registerStatus(state = {}, action = {}) {
  switch (action.type) {
    case REGISTER_LOADING:
      return {
        loading: true,
        data: null,
        error: null,
      };
    case REGISTER_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    case REGISTER_FAILED:
      return {
        loading: false,
        data: null,
        error: action.error,
      };
    case REGISTER_RESET:
      return {
        loading: false,
        data: null,
        error: null,
      };
    default:
      return state;
  }
}

export { registerStatus };

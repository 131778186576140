import React, { useState, useEffect } from "react";
import get from "lodash/get";
import map from "lodash/map";

/**
 * import local components
 */
import { Breadcrumb } from "@components";
import Layout from "@views/layout";
import Collapse from "./_collapse";
import Profile from "./profile";
import Bank from "./bank";
import Password from "./reset_password";
import Email from "./_email";
import Sidebar from "@views/account/sidebar";

/**
 * Import Hooks
 */
import { useUserProfile, useResponsive } from "@hooks";

/**
 * [Page] Account setting 个人设置页面
 */
const Setting = () => {
  const { isMobile } = useResponsive();

  const { data: profile } = useUserProfile();
  const email = get(profile, "user.email", "");

  const [isOpen, setOpen] = useState(true);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    isMobile && setOpen(false);
  }, [isMobile]);

  const list = [
    {
      icon: (
        <svg viewBox="0 0 20 20" className="w-5 h-5" fill="currentColor">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33366 10.834C4.57116 10.834 2.33366 8.59648 2.33366 5.83398C2.33366 3.07148 4.57116 0.833984 7.33366 0.833984C10.0962 0.833984 12.3337 3.07148 12.3337 5.83398C12.3337 8.59648 10.0962 10.834 7.33366 10.834ZM0.666992 18.334C0.666992 14.6521 3.65176 11.6673 7.33366 11.6673C11.0156 11.6673 14.0003 14.6521 14.0003 18.334H0.666992ZM19.0003 14.1673H15.667V15.834H19.0003V14.1673ZM13.167 10.0007H19.0003V11.6673H13.167V10.0007ZM19.0003 5.83398H14.8337V7.50065H19.0003V5.83398Z"
          />
        </svg>
      ),
      title: "Profile",
      children: <Profile />,
    },
    {
      icon: (
        <svg viewBox="0 0 18 16" width="18" height="16" fill="currentColor">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3333 4.66667H0.666585V1.33333C0.666585 0.873096 1.03968 0.5 1.49992 0.5H16.4999C16.9602 0.5 17.3333 0.873096 17.3333 1.33333V4.66667ZM17.3332 6.33333V14.6667C17.3332 15.1269 16.9601 15.5 16.4998 15.5H1.49984C1.0396 15.5 0.666504 15.1269 0.666504 14.6667V6.33333H17.3332ZM11.4998 13V11.3333H14.8332V13H11.4998Z"
          />
        </svg>
      ),
      title: "Bank",
      children: <Bank />,
    },
    {
      icon: (
        <svg viewBox="0 0 16 19" width="16" height="19" fill="currentColor">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 6.66732H14.6667C15.1269 6.66732 15.5 7.04041 15.5 7.50065V17.5007C15.5 17.9609 15.1269 18.334 14.6667 18.334H1.33333C0.873096 18.334 0.5 17.9609 0.5 17.5007V7.50065C0.5 7.04041 0.873096 6.66732 1.33333 6.66732H3V5.83398C3 3.07256 5.23858 0.833984 8 0.833984C10.7614 0.833984 13 3.07256 13 5.83398V6.66732ZM11.3333 6.6674V5.83407C11.3333 3.99312 9.84095 2.50073 8 2.50073C6.15905 2.50073 4.66667 3.99312 4.66667 5.83407V6.6674H11.3333ZM7.16667 13.3341V11.6674H8.83333V13.3341H7.16667ZM3.83333 11.6674V13.3341H5.5V11.6674H3.83333ZM10.5 13.3341V11.6674H12.1667V13.3341H10.5Z"
          />
        </svg>
      ),
      title: "Reset Password",
      children: <Password />,
    },
    {
      icon: (
        <svg viewBox="0 0 19 18" width="19" height="18" fill="currentColor">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1287 10.295C13.4917 8.93206 15.5167 8.47392 17.3337 9.1175V1.33333C17.3337 0.873096 16.9606 0.5 16.5003 0.5H1.50033C1.04009 0.5 0.666992 0.873096 0.666992 1.33333V14.6667C0.666992 15.1269 1.04009 15.5 1.50033 15.5H10.9512C10.3076 13.6831 10.7657 11.658 12.1287 10.295ZM3.70693 3.19836L9.05027 7.73586H9.0511L14.2886 3.20336L15.3786 4.4642L9.0611 9.93087L2.62777 4.46836L3.70693 3.19836ZM13.2085 14.29C13.1527 13.9881 13.1527 13.6786 13.2085 13.3767L12.3635 12.8883L13.1968 11.445L14.0418 11.9333C14.2735 11.735 14.541 11.5792 14.8335 11.475V10.5H16.5002V11.475C16.7927 11.5792 17.0602 11.735 17.2918 11.9333L18.1368 11.445L18.9701 12.8883L18.1252 13.3767C18.1809 13.6786 18.1809 13.9881 18.1252 14.29L18.9701 14.7783L18.1368 16.2217L17.2918 15.7333C17.0584 15.9335 16.79 16.0889 16.5002 16.1917V17.1667H14.8335V16.1917C14.5436 16.0889 14.2752 15.9335 14.0418 15.7333L13.1968 16.2217L12.3635 14.7783L13.2085 14.29ZM16.5002 13.8333C16.5002 14.2936 16.1271 14.6667 15.6669 14.6667C15.2067 14.6667 14.8336 14.2936 14.8336 13.8333C14.8336 13.3731 15.2067 13 15.6669 13C16.1271 13 16.5002 13.3731 16.5002 13.8333Z"
          />
        </svg>
      ),
      title: "Change Email",
      children: <Email email={email} />,
    },
  ];
  return (
    <Layout name="account">
      <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar className="hidden" current="setting" />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 mt-6 lg:mt-0 mb-8 lg:mb-16">
            <Breadcrumb className="px-4 md:px-0 mb-6 md:mb-8" back />
            <h2 className="px-4 md:px-0">
              Settings
            </h2>
            <div className="px-2 md:px-0 mt-8 md:mt-10">
              {map(list, (item, index) => (
                <Collapse
                  key={index}
                  isOpen={isOpen && current === index}
                  icon={item.icon}
                  title={item.title}
                  onClick={() => {
                    if (current === index) {
                      setOpen(!isOpen);
                      return;
                    }
                    setOpen(true);
                    setCurrent(index);
                  }}
                  className="mb-4 md:mb-6"
                >
                  {item.children}
                </Collapse>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Setting;

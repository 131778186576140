
export const OFFER_PRICES_ATTEND_FETCH_REQUEST = "offer_prices/fetch_attend_list_request";
export const OFFER_PRICES_ATTEND_FETCH_LOADING = "offer_prices/fetch_attend_list_loading";
export const OFFER_PRICES_ATTEND_FETCH_SUCCESS = "offer_prices/fetch_attend_list_success";
export const OFFER_PRICES_ATTEND_FETCH_FAILED = "offer_prices/fetch_attend_list_failed";

export function fetchAttendOfferPriceList(payload) {
  return {
    type: OFFER_PRICES_ATTEND_FETCH_REQUEST,
    payload
  };
}

import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import get from "lodash/get";
import PropTypes from "prop-types";
import cx from "classnames";
import { ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";

import { Link } from "@components";

const Alert = ({ data, className }) => {
  const [isOpen, setOpen] = useState(false);
  const hasDeposited = get(data, "status.hasDeposited", false);

  useEffect(() => {
    if (!hasDeposited) {
      setOpen(true);
    }
  }, [hasDeposited]);
  
  return (
    <CSSTransition in={isOpen} classNames="alert" timeout={300} unmountOnExit>
      <div className={cx("px-2 md:px-0 mb-4 md:mb-6", className)}>
        <div className="p-6 rounded-lg bg-white shadow sm:p-3">
          <div className="md:flex items-center justify-between flex-wrap">
            <div className="flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-gray-100">
                <ExclamationCircleIcon
                  className="h-6 w-6 text-blue-gray-800"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-medium text-blue-gray-800 truncate">
                <span>A refundable deposit is needed before you can bid.</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/deposit"
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900"
              >
                Pay Deposit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

Alert.propTypes = {};

export default Alert;

import { WalletApiRepo } from "@core";

export class WalletApiUsecase {

  constructor() {
    this.repo = new WalletApiRepo();
  }

  entries(payload) {
    return this.repo.entries(payload);
  }

  balance(payload) {
    return this.repo.balance(payload);
  }

  topup(payload) {
    return this.repo.topup(payload);
  }

}
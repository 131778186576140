import React, { useRef, useState } from "react";
import Swiper,{SwipeRef} from 'react-tiga-swiper';

import { Mobile, DesktopAndTablet } from "@components";

const Introduce = () => {

  const swiperRef = useRef(null);
  const swiperData = [
    (
      <div className="flex flex-col justify-center items-center p-4 md:p-6">
        <div className="text-xs md:text-sm font-medium text-gray-500 text-center">Genuine buyers and sellers</div>
        <div className="md:mt-1 text-sm md:text-base font-medium text-gray-900 text-center">All listings and bids are binding</div>
      </div>
    ),
    (
      <div className="flex flex-col justify-center items-center p-4 md:p-6">
        <div className="text-xs md:text-sm font-medium text-gray-500 text-center">Fair market prices</div>
        <div className="md:mt-1 text-sm md:text-base font-medium text-gray-900 text-center">Trade with 1000+ verified members</div>
      </div>
    ),
    (
      <div className="flex flex-col justify-center items-center p-4 md:p-6">
        <div className="text-xs md:text-sm font-medium text-gray-500 text-center">Trade protection</div>
        <div className="md:mt-1 text-sm md:text-base font-medium text-gray-900 text-center">100% buyer and seller protection</div>
      </div>
    )
  ];

  return (
    <div>
      <Mobile>
        <Swiper
          className="demo-swiper-container h-20 border-b border-gray-200"
          autoPlay={3000}
          selectedIndex={0}
          direction="vertical"
          showIndicators={false}
          indicator={null}
          showDots={false}
          dots={null}
          ref={swiperRef}
        >
          {swiperData.map((item, key) => (
            <div className="h-20" key={key}>{item}</div>
          ))}
        </Swiper>
      </Mobile>
      <DesktopAndTablet>
        <div className="bg-gray-50 border-b border-gray-200">
          <div className="max-w-5xl w-full mx-auto">
            <ul className="grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x divide-gray-200">
              <li className="flex flex-col justify-center items-center p-4 md:p-6">
                <div className="text-xs md:text-sm font-medium text-gray-500 text-center">Genuine buyers and sellers</div>
                <div className="md:mt-1 text-sm md:text-base font-medium text-gray-900 text-center">All listings and bids are binding</div>
              </li>
              <li className="flex flex-col justify-center items-center p-4 md:p-6">
                <div className="text-xs md:text-sm font-medium text-gray-500 text-center">Fair market prices</div>
                <div className="md:mt-1 text-sm md:text-base font-medium text-gray-900 text-center">Trade with 1000+ verified members</div>
              </li>
              <li className="flex flex-col justify-center items-center p-4 md:p-6">
                <div className="text-xs md:text-sm font-medium text-gray-500 text-center">Trade protection</div>
                <div className="md:mt-1 text-sm md:text-base font-medium text-gray-900 text-center">100% buyer and seller protection</div>
              </li>
            </ul>
          </div>
        </div>
      </DesktopAndTablet>
    </div>
    
  );
};

export default Introduce;

export const OFFER_PRICES_FETCH_REQUEST = "offer_prices/fetch_list_request";
export const OFFER_PRICES_FETCH_LOADING = "offer_prices/fetch_list_loading";
export const OFFER_PRICES_FETCH_SUCCESS = "offer_prices/fetch_list_success";
export const OFFER_PRICES_FETCH_FAILED = "offer_prices/fetch_list_failed";

export const OFFER_PRICES_ANIMATE_START = "offer_prices/animate_start";
export const OFFER_PRICES_ANIMATE_END = "offer_prices/animate_end";

export function fetchOfferPriceList(payload) {
  return {
    type: OFFER_PRICES_FETCH_REQUEST,
    payload
  };
}

export const CATEGORY_SHOW_TRANSACTION_FORM_STATUS_TOGGLE = "category_show/transaction_form_status_toggle";

export const CATEGORY_SHOW_MODAL_ORDER_STATUS_TOGGLE = "category_show/modal_order_status_toggle";
export const CATEGORY_SHOW_MODAL_ORDER_PAY_TOGGLE = "category_show/modal_order_pay_toggle";
export const CATEGORY_SHOW_MODAL_ORDER_RESULT_TOGGLE = "category_show/modal_order_result_toggle";
export const CATEGORY_SHOW_MODAL_ORDER_SET_PAYMETHOD = "category_show/modal_order_set_paymethod";
export const CATEGORY_SHOW_ORDER_SET_DATA = "category_show/order_set_data";

export const CATEGORY_SHOW_MODAL_OFFER_STATUS_TOGGLE = "category_show/modal_offer_status_toggle";
export const CATEGORY_SHOW_MODAL_OFFER_CONFIRM_TOGGLE = "category_show/modal_offer_confirm_toggle";
export const CATEGORY_SHOW_MODAL_OFFER_RESULT_TOGGLE = "category_show/modal_offer_result_toggle";
export const CATEGORY_SHOW_OFFER_SET_DATA = "category_show/offer_set_data";

export const CATEGORY_SHOW_MODAL_EMAIL_STATUS_TOGGLE = "category_show/modal_email_status_toggle";

/**
 * 分类页 交易表单的状态切换（手机模式）
 * @param  {Boolean} status 状态 true|false
 * @return {Object}
 * {type: CATEGORY_SHOW_TRANSACTION_FORM_STATUS_TOGGLE, payload: {isOpen: true}}
 * {type: CATEGORY_SHOW_TRANSACTION_FORM_STATUS_TOGGLE, payload: {isOpen: false}}
 */
export function categoryShowTransactionFormStatusToggle(status) {
  return {
    type: CATEGORY_SHOW_TRANSACTION_FORM_STATUS_TOGGLE,
    payload: {
      isOpen: status
    },
  };
}


/**
 * 分类页 买/卖订单 moral 状态切换
 * @param  {Boolean} status 状态 true|false
 * @return {Object}
 * {type: CATEGORY_SHOW_MODAL_ORDER_STATUS_TOGGLE, payload: {isOpen: true}}
 * {type: CATEGORY_SHOW_MODAL_ORDER_STATUS_TOGGLE, payload: {isOpen: false}}
 */
export function categoryShowModalOrderStatusToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_ORDER_STATUS_TOGGLE,
    payload: {
      isOpen: status
    },
  };
}

/**
 * 分类页 买/卖订单 moral 支付页面切换
 * @param  {Boolean} status 是否显示 true|false
 * @return {Object}
 * {type: CATEGORY_SHOW_MODAL_ORDER_PAY_TOGGLE, payload: {showPay: true}}
 * {type: CATEGORY_SHOW_MODAL_ORDER_PAY_TOGGLE, payload: {showPay: false}}
 */
export function categoryShowModalOrderPayToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_ORDER_PAY_TOGGLE,
    payload: {
      showPay: status
    },
  };
}


/**
 * 分类页 买/卖订单 moral 结果页面切换
 * @param  {Boolean} status 是否显示 true|false
 * @return {Object}
 * {type: CATEGORY_SHOW_MODAL_ORDER_RESULT_TOGGLE, payload: {showResult: true}}
 * {type: CATEGORY_SHOW_MODAL_ORDER_RESULT_TOGGLE, payload: {showResult: false}}
 */
export function categoryShowModalOrderResultToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_ORDER_RESULT_TOGGLE,
    payload: {
      showResult: status
    },
  };
}

/**
 * 分类页 买/卖订单 moral 支付步骤中，设置支付方式
 * @param  {String} payMethod 支付方式："offline"
 * @return {Object}
 * {type: CATEGORY_SHOW_MODAL_ORDER_SET_PAYMETHOD, payload: {payMethod: "offline"}}
 */
export function categoryShowModalOrderSetPayMethod(payMethod) {
  return {
    type: CATEGORY_SHOW_MODAL_ORDER_SET_PAYMETHOD,
    payload: {
      payMethod
    },
  };
}

/**
 * 分类页 买/卖订单数量输入
 * @param  {Number} value
 * @return {Object}
 * {type: CATEGORY_SHOW_ORDER_SET_DATA, payload: {quantity: 12.5, orderItems: [], totalPrice:1, totalQuantity:1, averagePrice:1}}
 */
export function categoryShowOrderSetData(payload) {
  return {
    type: CATEGORY_SHOW_ORDER_SET_DATA,
    payload
  };
}


/**
 * 分类页 发布 offer 报价 moral 状态切换
 * @param  {Object} status 状态 true|false
 * @return {Object}
 * {type: CATEGORY_SHOW_MODAL_OFFER_STATUS_TOGGLE, payload: {isOpen: true}}
 * {type: CATEGORY_SHOW_MODAL_OFFER_STATUS_TOGGLE, payload: {isOpen: false}}
 */
export function categoryShowModalOfferStatusToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_OFFER_STATUS_TOGGLE,
    payload: {
      isOpen: status
    },
  };
}


/**
 * 分类页 发布 offer 数据
 * @param  {String} attr 字段名称 "quantity|price"
 * @param  {String} value
 * @return {Object}
 * {type: CATEGORY_SHOW_OFFER_SET_DATA, payload: {quantity: 12.5}}
 */
export function categoryShowOfferSetData(attr='', value) {
  let payload = {};
  payload[attr] = value;
  return {
    type: CATEGORY_SHOW_OFFER_SET_DATA,
    payload,
  };
}

/**
 * 分类页 发布 offer 数据，（modal）切换显示确认信息
 * @param  {Boolean} status 是否显示 "true|false"
 * @return {Object}
 * {type: CATEGORY_SHOW_OFFER_CONFIRM_TOGGLE, payload: {status: true|false}}
 */

export function categoryShowModalOfferConfirmToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_OFFER_CONFIRM_TOGGLE,
    payload: {
      showConfirm: status
    }
  };
}


/**
 * 分类页 发布 offer 数据，（modal）切换显示报价结果
 * @param  {Boolean} status 是否显示 "true|false"
 * @return {Object}
 * {type: CATEGORY_SHOW_OFFER_RESULT_TOGGLE, payload: {status: true|false}}
 */

export function categoryShowModalOfferResultToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_OFFER_RESULT_TOGGLE,
    payload: {
      showResult: status
    }
  };
}


/**
 * 分类页 发布 offer 数据时，（modal）切换显示联系管理员
 * @param  {Boolean} status 是否显示 "true|false"
 * @return {Object}
 * {type: CATEGORY_SHOW_MODAL_EMAIL_STATUS_TOGGLE, payload: {isOpen: true|false}}
 */

export function categoryShowModalEmailStatusToggle(status) {
  return {
    type: CATEGORY_SHOW_MODAL_EMAIL_STATUS_TOGGLE,
    payload: {
      isOpen: status
    }
  };
}
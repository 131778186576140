export const PERMANENT_ENQUIRE = "permanent/enquire";
export const PERMANENT_ENQUIRE_REQUEST = `${PERMANENT_ENQUIRE}_request`;
export const PERMANENT_ENQUIRE_SUCCESS = `${PERMANENT_ENQUIRE}_success`;
export const PERMANENT_ENQUIRE_FAILURE = `${PERMANENT_ENQUIRE}_failure`;
export const PERMANENT_ENQUIRE_RESET = `${PERMANENT_ENQUIRE}_reset`;

export const permanentEnquireAction = {
  request: (payload) => {
    return {
      type: PERMANENT_ENQUIRE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_ENQUIRE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_ENQUIRE_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: PERMANENT_ENQUIRE_RESET
    }
  }
};

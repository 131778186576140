import { takeLatest, call, put } from "redux-saga/effects";

import { MarketApiUsecase } from "@core";
import { MARKET_LIST_REQUEST, marketListAction } from "./action";

// fetch market(lease) list
export function* watchMarketListFetch() {
  yield takeLatest(MARKET_LIST_REQUEST, marketListFetch);
}

function* marketListFetch(action) {
  try {
    const usecase = new MarketApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(marketListAction.success(res.data));
  } catch (err) {
    yield put(marketListAction.failure(err));
  }
}

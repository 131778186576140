
export const ORDERS_CREATE_POST = "orders/create_post";
export const ORDERS_CREATE_LOADING = "orders/create_loading";
export const ORDERS_CREATE_SUCCESS = "orders/create_success";
export const ORDERS_CREATE_FAILED = "orders/create_failed";
export const ORDERS_CREATE_RECEIVED = "orders/create_received";
export const ORDERS_CREATE_RESET = "orders/create_reset_data";

export function orderCreatePost(productId, payload) {
  return {
    type: ORDERS_CREATE_POST,
    productId,
    payload
  };
}

export function orderCreateReset() {
  return {
    type: ORDERS_CREATE_RESET,
  }
}

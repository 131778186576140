export const GLOBAL_STATIC = "global/static";
export const GLOBAL_STATIC_REQUEST = `${GLOBAL_STATIC}_request`;
export const GLOBAL_STATIC_SUCCESS = `${GLOBAL_STATIC}_success`;
export const GLOBAL_STATIC_FAILURE = `${GLOBAL_STATIC}_failure`;

export const globalStaticAction = {
  request: (payload) => {
    return {
      type: GLOBAL_STATIC_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: GLOBAL_STATIC_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: GLOBAL_STATIC_FAILURE,
      error,
    }
  },
};

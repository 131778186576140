import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { Loading } from "@components";

/**
 * Lazy load image
 * @param {*} param0
 * @ref https://github.com/twobin/react-lazyload
 */
const Image = ({ height, src, alt, className, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <LazyLoad height={height} {...rest}>
      {loaded ? null : (
        <div className={cx("flex items-center justify-center", className)} style={{height: height}}>
          <Loading />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        className={cx("transition ease-out duration-300", className, {
          hidden: !loaded,
          block: loaded,
        })}
      />
    </LazyLoad>
  );
};

Image.propTypes = {
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {
  once: true,
};

export default Image;

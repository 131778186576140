import { useState, useCallback } from "react";
import get from "lodash/get";
import compact from "lodash/compact";
import capitalize from "lodash/capitalize";
import numeral from "@utils/numeral";

/**
 * import hooks
 */
import { useDeepEffect } from "@hooks";

export const useMarketUnitName = (market = {}, quantity = 1) => {
  const [amount, setAmount] = useState({});
  const title = get(market, "title", "");
  const marketType = get(market, "marketType", "");
  const unitOfPrice = get(market, "unitOfPrice", "");
  const unitNameCondition = get(market, "unitNameCondition", "");
  const isSameUnitName = unitOfPrice === unitNameCondition;
  const getKgPerUnit = useCallback(
    (amount = {}) => {
      if (isSameUnitName || !amount.kgPerUnit) {
        return null;
      }
      return `1${amount.unitName} = ${amount.kgPerUnit}`;
    },
    [isSameUnitName]
  );
  const getAmount = useCallback(
    (amount = {}) => {
      if (isSameUnitName || !amount.kgPerUnit) {
        return null;
      }
      return `${quantity || 1}${amount.unitName} = ${amount.amountStr}`;
    },
    [quantity, isSameUnitName]
  );

  /**
   * Get unit equivalent kg amount
   */
  const getEquivalentAmount = useCallback(
    (amount = {}) => {
      if (isSameUnitName || !amount.kgPerUnit || !quantity) {
        return null;
      }
      return `Equivalent to ${amount.amountStr}`;
    },
    [isSameUnitName, quantity]
  );

  /**
   * get amount unit name
   */
  const getAmountStr = useCallback(
    (amount = {}) => {
      if (!quantity) {
        return null;
      }
      return compact([
        amount.amountStr,
        isSameUnitName ? "" : `(${quantity}${amount.unitName})`,
      ]).join(" ");
    },
    [quantity, isSameUnitName]
  );

  /**
   * Auto generate auction title
   */
  const generateAuctionTitle = useCallback(
    (amount = {}) => {
      return compact([
        amount.amountStr,
        isSameUnitName ? "" : `(${quantity}${amount.unitName})`,
        title,
        capitalize(marketType),
      ]).join(" ");
    },
    [title, marketType, quantity, isSameUnitName]
  );

  /**
   * Get total price, price * total amount
   */
  const getTotalPrice = useCallback(
    (amount) =>
      (price = 0) => {
        const total = get(amount, "amount", 0) || 0;
        if (!total || !price) {
          return null;
        }
        return `${numeral(price)
          .multiply(total)
          .format()} subtotal excluding GST`;
      },
    []
  );

  useDeepEffect(() => {
    if (typeof market.generateData === "function") {
      setAmount(market.generateData(quantity || 1));
    }
  }, [market, quantity]);

  return {
    amount: amount.amount,
    amountStr: getAmountStr(amount),
    auctionTitle: generateAuctionTitle(amount),
    dynaUnitName: amount.unitName,
    kgAmount: getAmount(amount),
    equivalentAmount: getEquivalentAmount(amount),
    kgPerUnit: getKgPerUnit(amount),
    totalPrice: getTotalPrice(amount),
    unitOfPrice,
  };
};

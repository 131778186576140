
export const ACCOUNT_BANKS_UPDATE = "account/banks_update";
export const ACCOUNT_BANKS_UPDATE_REQUEST = `${ACCOUNT_BANKS_UPDATE}_request`;
export const ACCOUNT_BANKS_UPDATE_SUCCESS = `${ACCOUNT_BANKS_UPDATE}_success`;
export const ACCOUNT_BANKS_UPDATE_FAILURE = `${ACCOUNT_BANKS_UPDATE}_failure`;
export const ACCOUNT_BANKS_UPDATE_RESET = `${ACCOUNT_BANKS_UPDATE}_reset`;

export const accountBankUpdateAction = {
  request: payload => {
    return {
      type: ACCOUNT_BANKS_UPDATE_REQUEST,
      payload,
    };
  },
  success: data => {
    return {
      type: ACCOUNT_BANKS_UPDATE_SUCCESS,
      data,
    };
  },
  failure: error => {
    return {
      type: ACCOUNT_BANKS_UPDATE_FAILURE,
      error,
    };
  },
  reset: () => {
    return {
      type: ACCOUNT_BANKS_UPDATE_RESET,
    };
  },
};

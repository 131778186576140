import {
  WALLET_OFFLINES_LOADING,
  WALLET_OFFLINES_SUCCESS,
  WALLET_OFFLINES_FAILED,
} from "./offlinesListAction";

export function walletOfflinesStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_OFFLINES_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_OFFLINES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
      });

    case WALLET_OFFLINES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: [],
        error: action.error,
      });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: [],
  error: null,
};

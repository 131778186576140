import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import get from "lodash/get";
import StepButton from "./share/_buttons";
import FirstStep from "./share/_step_1";
import ExchangeThirdStep from "./exchange/_step_3";
import ExchangeEndStep from "./exchange/_step_end";
import AuctionThirdStep from "./auction/_step_3";
import AuctionFourthStep from "./auction/_step_4";
import P2PThirdStep from "./p2p/_step_3";
import P2PFourthStep from "./p2p/_step_4";

import Title from "./_title";
import Header from "./_header";

const renderStep = ({
  quotaMarketLoading,
  setAuctionTitle,
  setFieldValue,
  step,
  values,
  listingOfferNewStatus,
}) => {
  const sellType = get(values, "sell_type", "");
  switch (step) {
    case 1:
      return <FirstStep values={values} setFieldValue={setFieldValue} />;
    case 2:
      if (sellType === "auction") {
        return (
          <AuctionThirdStep
            values={values}
            setAuctionTitle={setAuctionTitle}
            loading={quotaMarketLoading}
          />
        );
      }
      if (sellType === "exchange") {
        return (
          <ExchangeThirdStep values={values} loading={quotaMarketLoading} />
        );
      }
      return (
        <P2PThirdStep
          values={values}
          setAuctionTitle={setAuctionTitle}
          loading={quotaMarketLoading}
        />
      );
    case 3:
      if (sellType === "auction") {
        return <AuctionFourthStep values={values} />;
      }
      if (sellType === "exchange") {
        return (
          <ExchangeEndStep
            values={values}
            listingOfferNewStatus={listingOfferNewStatus}
          />
        );
      }
      return <P2PFourthStep values={values} />;
    default:
      return <div />;
  }
};

renderStep.propTypes = {
  quotaMarketLoading: PropTypes.bool,
  setAuctionTitle: PropTypes.func,
  setFieldValue: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};

renderStep.defaultProps = {
  setAuctionTitle: () => {},
  setFieldValue: () => {},
  setHighestBuyerBids: () => {},
  step: "",
  values: {},
};

const Steps = ({
  auctionTitle,
  formikRef,
  initialValues,
  loading,
  onSubmit,
  quotaMarketLoading,
  schema,
  sellType,
  setAuctionTitle,
  setStep,
  step,
  listingOfferNewStatus,
}) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Header step={step} maxStep={3} />
              <div className="shadow sm:rounded-2xl bg-white">
                <div className="p-4 md:p-10 space-y-6">
                  <Title
                    step={step}
                    sellType={sellType}
                    auctionTitle={auctionTitle}
                  />
                  <div className="mb-6 space-y-6">
                    {renderStep({
                      quotaMarketLoading,
                      setAuctionTitle,
                      setFieldValue,
                      step,
                      values,
                      listingOfferNewStatus,
                    })}
                  </div>
                </div>
                <div className="px-4 sm:px-6 md:px-10 pb-4 sm:pb-6 md:pb-10">
                  <StepButton
                    step={step}
                    maxStep={3}
                    setStep={setStep}
                    values={values}
                    loading={loading}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

Steps.propTypes = {
  formikRef: PropTypes.object,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  quotaMarketLoading: PropTypes.bool,
  schema: PropTypes.object,
  setAuctionTitle: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Steps.defaultProps = {
  formikRef: {},
  initialValues: {},
  loading: false,
  onSubmit: () => {},
  onTransferLaterSubmit: () => {},
  quotaMarketLoading: false,
  schema: {},
  setAuctionTitle: () => {},
  setStep: () => {},
  step: "",
};

export default Steps;

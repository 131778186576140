import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class P2PApiRepo extends BaseApiRepo {
  constructor() {
    super();
  }

  /**
   * P2P Api Show
   * @param {object} payload {}
   * GET /api/v1/p2p_offers/:id
   */
  show(payload) {
    this.setApi(`${API}/p2p_offers/${payload.id}`);
    return this.get();
  }

  /**
   * P2P new
   * @param {object} payload {}
   * POST /api/v1/p2p_offers
   */
  new(payload) {
    this.setApi(`${API}/p2p_offers`);
    return this.post(payload);
  }

  /**
   * P2P list
   * GET /api/v1/p2p_offers
   */
  list(payload) {
    this.setApi(`${API}/p2p_offers`);
    return this.get(payload);
  }

  /**
   * P2P create new order
   * POST /api/v1/p2p_orders
   * { p2p_offer_id: 1, quantity: 1 }
   */
  createOrder(payload) {
    this.setApi(`${API}/p2p_orders`);
    return this.post(payload);
  }

  /**
   * P2P sales histories
   * GET /api/v1/p2p_sale_histories?p2p_offer_id=xxx
   */
  salesHistories(payload) {
    this.setApi(`${API}/p2p_sale_histories`);
    return this.get(payload);
  }

  /**
   * P2P inquiry
   * POST /api/v1/p2p_inquires
   * { p2p_offer_id: xxx, quantity: xxx, price: xxx }
   */
  createInquire(payload) {
    this.setApi(`${API}/p2p_inquires`);
    return this.post(payload);
  }

  /**
   * P2P inquiry accept
   * POST /api/v1/p2p_inquires/xxx/accept
   */
  acceptInquire(payload) {
    this.setApi(`${API}/p2p_inquires/${payload.p2p_inquiry_id}/accept`);
    return this.post();
  }

  /**
   * P2P inquiry list
   * GET /api/v1/p2p_bargains
   */
  inquireList(payload) {
    this.setApi(`${API}/p2p_bargains`);
    return this.get(payload);
  }
}
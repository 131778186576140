import React from "react";

/**
 * Components
 */
import Form from "./form";

/**
 * [Page] Account bank 银行页面
 */
const Bank = () => {
  return (
    <div>
      <Form />
    </div>
  );
};

export default Bank;

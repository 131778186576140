import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { OfferApiUsecase } from "@core";
import { OFFERS_APPLY_REQUEST, offersApplyAction } from "./action";

export function* watchOffersApply() {
  yield takeLatest(OFFERS_APPLY_REQUEST, offersApply);
}

function* offersApply(action) {
  try {
    const usecase = new OfferApiUsecase();
    const res = yield call([usecase, "apply"], action.payload);
    const data = get(res, "data", {});
    yield put(offersApplyAction.success(data));
  } catch (err) {
    yield put(offersApplyAction.failure(errorString(err)));
  }
}

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Formik } from "formik";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import capitalize from "lodash/capitalize";

/**
 * import hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * import components
 */
import { Form, Input, formatInt } from "@components/formik";
import { Button } from "@components";

const Trade = ({
  statistics,
  usecaseMarket,
  onSubmit,
  data,
  onAmountChange,
  offers,
}) => {
  const { amount, isMe, maxQuantity } = data;
  const lowestOffer = isEmpty(get(statistics, "lowest_seller_offer"))
    ? ""
    : get(statistics, "lowest_seller_offer");
  const { equivalentAmount, dynaUnitName } = useMarketUnitName(
    usecaseMarket,
    amount
  );
  const { amountStr: maxAmountStr } = useMarketUnitName(
    usecaseMarket,
    maxQuantity
  );

  const title = compact([
    capitalize(get(usecaseMarket, "marketType", "")),
    get(usecaseMarket, "specName", ""),
    get(usecaseMarket, "season.years", ""),
  ]).join(" ");

  const validate = useCallback(
    (values) => {
      const errors = {};
      if (values.amount) {
        if (isEmpty(offers)) {
          errors.amount =
            "You can’t complete the lease in right now because there are currently no seller asks in the market.";
        } else if (isMe) {
          errors.amount =
            "Sorry, at this quantity you will lease-in your own quota. Users can not transact on their own quota.";
        } else if (values.amount > maxQuantity) {
          errors.amount = `The maximum amount you can put in is ${maxAmountStr}`;
        }
      }

      return errors;
    },
    [isMe, maxAmountStr, maxQuantity, offers]
  );

  const initialValues = {
    amount: "",
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div
        className="h-1 w-full"
        style={{
          background: "linear-gradient(90deg, #6A2BAA 27.21%, #BD00FF 97.98%)",
        }}
      ></div>
      <div className="px-4 md:px-6 pt-0 pb-4 md:pb-6">
        {lowestOffer ? (
          <div className="flex md:flex-col items-center justify-between py-3 md:py-6">
            <div className="text-sm leading-snug text-fish-dark capitalize">
              {title}
            </div>
            <div className="text-lg md:text-3xl font-bold text-fish-primary mt-1">
              {lowestOffer}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center py-6">
            <div className="text-sm leading-snug text-blue-gray-500 text-center">
              There's no quota available yet
            </div>
          </div>
        )}
        <Formik
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
        >
          <MyForm
            data={data}
            dynaUnitName={dynaUnitName}
            equivalentAmount={equivalentAmount}
            onAmountChange={onAmountChange}
          />
        </Formik>
      </div>
    </div>
  );
};

const MyForm = ({ data, dynaUnitName, equivalentAmount, onAmountChange }) => {
  const { amount, maxQuantity } = data;
  return (
    <Form>
      <Input
        name="amount"
        placeholder="Enter quantity"
        className=""
        classNameWrapper="mt-0"
        rightSymbol={dynaUnitName}
        parse={formatInt}
        onChange={(e) => {
          let value = e.target.value;
          if (value > maxQuantity) {
            value = maxQuantity;
          }
          onAmountChange(value);
        }}
        autoComplete="off"
      />
      {equivalentAmount ? (
        <div className="mt-4 text-center text-xs font-medium text-blue-gray-500">
          {equivalentAmount}
        </div>
      ) : null}

      {!isEmpty(data.items) ? (
        <ul className="trade-items text-sm mt-4">
          {data.items.map((item, index) => {
            const liClass = cx("flex justify-between pb-3", {
              "text-fish-red": item.isMe,
            });
            return (
              <li className={liClass} key={index}>
                <div className={item.isMe ? "text-fish-red" : "text-blue-gray-600"}>
                  {item.amount} × {item.price}
                </div>
                <div className="font-semibold">{item.itemTotal}</div>
              </li>
            );
          })}
        </ul>
      ) : null}

      {amount && amount > 0 ? (
        <div>
          <hr className="border-blue-gray-200 mb-2" />
          <div className="trade-subtotal flex justify-between pt-3-tw">
            <div>Subtotal</div>
            <div className="font-semibold">{data.subTotal || ""}</div>
          </div>
        </div>
      ) : null}
      <div className="mt-5">
        <Button
          type="submit"
          size="lg"
          full
          color="gray-800"
          textColor="white"
          className="flex items-center justify-center text-sm font-bold"
        >
          <svg
            className="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29996 0.045986C8.50296 0.109828 8.68031 0.236767 8.8062 0.408342C8.93209 0.579916 8.99997 0.787179 8.99996 0.999986V5.99999H13C13.1829 5.9999 13.3623 6.05 13.5187 6.14481C13.6752 6.23962 13.8026 6.37553 13.8871 6.53773C13.9717 6.69993 14.0101 6.88222 13.9983 7.06475C13.9865 7.24729 13.9248 7.42307 13.82 7.57299L6.81996 17.573C6.69813 17.7475 6.52379 17.8786 6.3223 17.9472C6.12081 18.0159 5.90268 18.0184 5.69965 17.9545C5.49663 17.8905 5.31929 17.7635 5.19345 17.5918C5.06761 17.4202 4.99982 17.2128 4.99996 17V12H0.999957C0.817038 12.0001 0.637602 11.95 0.481174 11.8552C0.324747 11.7603 0.197322 11.6244 0.112766 11.4622C0.0282109 11.3 -0.0102353 11.1178 0.00161155 10.9352C0.0134584 10.7527 0.0751445 10.5769 0.179957 10.427L7.17996 0.426986C7.30197 0.252773 7.47635 0.121998 7.67776 0.0536622C7.87917 -0.0146742 8.09713 -0.0170128 8.29996 0.0469861V0.045986Z"
              fill="white"
            />
          </svg>
          Lease Instantly
        </Button>
      </div>
    </Form>
  );
};

Trade.propTypes = {
  usecaseMarket: PropTypes.object,
};

export default Trade;

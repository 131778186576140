
export const CHANNEL_MESSAGES_FETCH_REQUEST = "channel_messages/fetch_list_request";
export const CHANNEL_MESSAGES_FETCH_LOADING = "channel_messages/fetch_list_loading";
export const CHANNEL_MESSAGES_FETCH_SUCCESS = "channel_messages/fetch_list_success";
export const CHANNEL_MESSAGES_FETCH_FAILED = "channel_messages/fetch_list_failed";
export const CHANNEL_MESSAGES_FETCH_RECEIVED = "channel_messages/fetch_list_received";

export const CHANNEL_MESSAGES_PUSH = "channel_messages/push";
export const CHANNEL_MESSAGES_SLICE = "channel_messages/slice";


export function fetchChannelMessagesList(channelId) {
  return {
    type: CHANNEL_MESSAGES_FETCH_REQUEST,
    channelId
  };
}


export function pushChannelMessagesList(message) {
  return {
    type: CHANNEL_MESSAGES_PUSH,
    message,
  };
}

export function sliceChannelMessagesList(messageId) {

}

import { useSelector } from "react-redux";
import get from "lodash/get";


/**
 * [HOOK] Current User Status
 * @example
 * const status = useCurrentUser();
 * const cognitoId = get(status, "user.user_id", "")
 */
export const useCurrentUser = () => {
  const currentUserStatus = useSelector(state =>
    get(state, "currentUserStatus")
  );

  return currentUserStatus;
};

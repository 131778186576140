import { map, isArray, findIndex, cloneDeep } from "lodash";
import { AuctionBidShowUsecase } from "./bidShow";

export class AuctionBidListUsecase {
  /**
   * 构造函数
   * @param {Object} props
   * @param {Object} user currentUserStatus
   */
  constructor(props, user) {
    this.data = props;
    this.userData = user;
  }

  get list() {
    if (!isArray(this.collisionBidlistData)) {
      return [];
    }
    return map(this.collisionBidlistData, (data) => {
      const usecase = new AuctionBidShowUsecase(data, this.userData);
      return usecase.show;
    });
  }

  get collisionBidlistData() {
    let res = cloneDeep(this.data);
    const index = findIndex(this.data, (o) => o.collision_cognito_id);
    if (index > -1) {
      const o = this.data[index] || {};
      const collisionBid = Object.assign({}, o, {
        alias_username: o.collision_alias_username,
        cognito_id: o.collision_cognito_id,
      });
      res.splice(index + 1, 0, collisionBid);
    }
    return res;
  }
}

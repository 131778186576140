import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  OFFER_PRICES_ATTEND_FETCH_REQUEST,
  OFFER_PRICES_ATTEND_FETCH_SUCCESS,
  OFFER_PRICES_ATTEND_FETCH_LOADING,
  OFFER_PRICES_ATTEND_FETCH_FAILED,
} from "./attendListAction";

import {
  offerPricesAPI,
  offerPricesWithoutProductAPI,
} from "@config/api";


//fetch attend offer prices list
export function* watchAttendOfferPricesFetch() {
  yield takeEvery(OFFER_PRICES_ATTEND_FETCH_REQUEST, offerPricesAttendFetch);
}

export function* offerPricesAttendFetch(action) {
  yield put({ type: OFFER_PRICES_ATTEND_FETCH_LOADING });
  const { err, res } = yield call(offerPricesAttendFetchApi, action.payload);
  if (!res) {
    yield put({
      type: OFFER_PRICES_ATTEND_FETCH_FAILED,
      error: "Get attend offer prices list failed."
    });
    return;
  }
  yield put({ type: OFFER_PRICES_ATTEND_FETCH_SUCCESS, data: res });

}

export function offerPricesAttendFetchApi(payload) {
  let api = payload.productGradeId ?
  offerPricesAPI(payload.productGradeId) :
  offerPricesWithoutProductAPI("attended");
  return axios
    .get(api)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

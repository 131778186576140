import get from "lodash/get";

export default (data = []) => {
  let result = [];
  data.map(item => {
    const category_name = get(item, "category.name");
    const quota_name = get(item, "quota_name");
    const state = item.state;
    const id = item.id;
    (item.product_grades || []).map(subItem => {
      const asking_prices = subItem.asking_prices;
      const offer_prices = subItem.offer_prices;
      const lowest_asking_price = subItem.lowest_asking_price;
      const highest_offer_price = subItem.highest_offer_price;
      const product_type = subItem.product_type;
      const zone = subItem.name;
      const o = {
        header: {
          id,
          name: category_name,
          quota_name,
          zone,
          state,
          product_type
        },
        prices: asking_prices || offer_prices,
        limit: lowest_asking_price || highest_offer_price
      };
      result.push(o);
    });
  });
  return result;
};

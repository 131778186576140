import { PERMANENT_STATISTIC_SUCCESS, PERMANENT_STATISTIC_FAILURE } from "./action";

export const permanentStatisticStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PERMANENT_STATISTIC_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case PERMANENT_STATISTIC_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: {},
  error: null,
};

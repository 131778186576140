import isEmpty from "lodash/isEmpty";
/**
 * import lib tools
 */
import { useDispatch } from "react-redux";
/**
 * import local tools & redux & usecase
 */

import { auctionShowAction } from "@redux/modules_v2/auctions/show/action";
import { auctionBidListAction } from "@redux/modules_v2/auctions/bidList/action";

/**
 * Hooks
 */
import { useDeepEffect } from "@hooks";

/**
 * Fetch auction wait ruling hooks
 * @param {*} param
 */
export const useAuctionWaitRuling = ({
  id,
  auctionWaitRuling,
  setAuctionWaitRuling,
  setAuctionLastChance,
}) => {
  let timer;
  const dispatch = useDispatch();

  const fetchAuctionShow = (id) => {
    dispatch(auctionShowAction.request({ id }));
  };

  const fetchBidList = (id) => {
    dispatch(auctionBidListAction.request({ auction_id: id, unique: true }));
  };

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    if (auctionWaitRuling) {
      if(!isEmpty(timer)) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setAuctionWaitRuling(false);
        setAuctionLastChance(false);
        fetchAuctionShow(id);
        fetchBidList(id);
      }, 10 * 1000);
    }
  }, [auctionWaitRuling,id]);
};

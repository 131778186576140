import { takeLatest, call, put } from "redux-saga/effects";

import { MarketApiUsecase } from "@core";
import { MARKET_SHOW_REQUEST, marketShowAction } from "./action";

// fetch market(lease) show
export function* watchMarketShowFetch() {
  yield takeLatest(MARKET_SHOW_REQUEST, marketShowFetch);
}

function* marketShowFetch(action) {
  yield put(marketShowAction.reset());
  try {
    const usecase = new MarketApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(marketShowAction.success(res.data));
  } catch (err) {
    yield put(marketShowAction.failure(err));
  }
}

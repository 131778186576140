import { useEffect, useCallback, useState } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { OrderUsecase, LoadingSelector } from "@core";
import {
  orderShowAction,
  ORDER_SHOW,
} from "@redux/modules_v2/orders/show/action";
import {useCurrentUser} from "@hooks";

/**
 * [hooks] order show by id
 * @returns { usecase: "a usecase by order data", loading: true|false }
 */
export const useOrderShow = (id) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const fetchOrderShow = useCallback(() => {
    dispatch(orderShowAction.request({ id }));
  }, [id]);

  const checkOrderShow = useCallback(
    (onSuccess, onError) => {
      dispatch(orderShowAction.check({ id, onSuccess, onError }));
    },
    [id]
  );

  const orderShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(ORDER_SHOW, state);
    return Object.assign({}, get(state, "orderShowStatusV2", {}), {
      loading: selector.loading,
    });
  });

  const loading = orderShowStatus.loading;
  const usecase = new OrderUsecase(orderShowStatus.data, get(currentUser, "user.user_id", ""));

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    if (id) {
      fetchOrderShow(id);
    }
  }, [id]);

  return { usecase, loading, fetchOrderShow, checkOrderShow };
};

import { takeLatest, call, put } from "redux-saga/effects";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_SIMILAR_LIST_REQUEST, auctionSimilarListAction } from "./action";

//Fetch similar auction list
export function* watchAuctionSimilarListFetch() {
  yield takeLatest(AUCTION_SIMILAR_LIST_REQUEST, auctionListFetch);
}

function* auctionListFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(auctionSimilarListAction.success(res.data));
  } catch (err) {
    yield put(auctionSimilarListAction.failure(err));
  }
}
 
import { get } from "lodash";
import { MarketMap } from "@core";
import { BidOfferEntity, generateMarket } from "./bidOfferEntity";
/**
 * 新版订单
 */
export class OrderNewEntity {
  constructor(data) {
    this.entity = data;
    this.market = generateMarket(data);
  }

  get items() {
    return get(this.entity, "items", []);
  }

  get itemsFormated() {
    const list = [].concat(this.items).map((item) => {
      const obj = {
        price_cents: get(item, "price_cents.humanized_money", 0) * 100,
        item_total: get(item, "item_total.humanized_money", 0),
        remain_quantity: get(item, "units", 0),
        marketable_type: get(this.entity, "marketable_type"),
      };
      return new BidOfferEntity(obj, this.market);
    });
    return list;
  }

  get boxesCount() {
    return get(this.entity, "boxes_count", 0);
  }

  get units() {
    if (this.isLease) {
      return this.market.generateData(this.boxesCount).units || 0;
    }
    if (this.isPermanent) {
      return this.market.units || 0;
    }
  }
  get unitsStr() {
    if (this.isLease) {
      return this.market.generateData(this.boxesCount).unitsStr || "";
    }
    if (this.isPermanent) {
      return this.market.unitsStr;
    }
  }

  get amount() {
    if (this.isLease) {
      return this.market.generateData(this.boxesCount).amount || 0;
    }
    if (this.isPermanent) {
      return this.market.amount;
    }
  }
  get amountStr() {
    if (this.isLease) {
      return this.market.generateData(this.boxesCount).amountStr || "";
    }
    if (this.isPermanent) {
      return this.market.amountStr;
    }
  }

  get isLease() {
    return this.marketType === MarketMap.lease;
  }

  get isPermanent() {
    return this.marketType === MarketMap.permanent;
  }

  get marketId() {
    return get(this.entity, "marketable_id", "");
  }

  get marketType() {
    return get(this.entity, "marketable_type", ""); // QuotaMarket | PermanentListing
  }

  get title() {
    return get(this.market, "titleWithStateAbbr", "");
  }

  get titleUrl() {
    return get(this.market, "url", "");
  }

  get originId() {
    return get(this.entity, "origin_id");
  }
}

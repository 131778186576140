import React, { useState } from "react";
import { Formik, Form } from "formik";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import get from "lodash/get";

import {
  injectStripe,
  CardElement,
  // PostalCodeElement,
} from "react-stripe-elements";

import { Checkbox, Radio } from "@views/components/formik";

import { Button, Loading, DesktopAndTablet } from "@components";
import {
  useStripePaymentHook,
  usePaymentMethodsHook,
  usePaymentMethodsDeleteHook,
  useDeepEffect,
} from "@hooks";

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
        width: "100%",
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

const StripeCardForm = (props) => {
  const {
    paymentId,
    stripe,
    elements,
    orderId,
    loading: paymentLoading,
  } = props;
  const { onSubmit, initialValues, loading, elementFontSize, createdBy } =
    useStripePaymentHook({ paymentId, stripe, elements, orderId });
  const { res, loading: methodsLoading, getList } = usePaymentMethodsHook();
  const { onSubmit: onDelete, loading: deleteLoading } =
    usePaymentMethodsDeleteHook(getList);
  if (methodsLoading || paymentLoading) {
    return <Loading />;
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => {
        return (
          <MyForm
            createdBy={createdBy}
            deleteLoading={deleteLoading}
            elementFontSize={elementFontSize}
            loading={loading}
            onDelete={onDelete}
            res={res}
            setFieldValue={setFieldValue}
          />
        );
      }}
    </Formik>
  );
};

const MyForm = ({
  createdBy,
  deleteLoading,
  elementFontSize,
  loading,
  onDelete,
  res,
  setFieldValue,
}) => {
  const [newCard, setNewCard] = useState(false);
  useDeepEffect(() => {
    if (!isEmpty(res)) {
      const id = get(last(res), "id");
      setFieldValue("payment_method_id", id);
    }
  }, [res]);
  if (createdBy === "order") {
    return (
      <Form>
        {!isEmpty(res) && (
          <Radio
            name="payment_method_id"
            className="lg:grid lg:grid-cols-1 lg:gap-4 space-y-4 lg:space-y-0 space-y-4 mb-4"
            radioClassName="items-center rounded-md border border-blue-gray-300 bg-white p-4"
            options={map(res, (item = {}) => ({
              label: (
                <div className="flex items-center flex-1">
                  <div className="flex-1 text-sm font-bold capitalize">
                    {`${item.brand} **** **** **** ${item.last4}`}
                  </div>
                  <button
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        typeof onDelete === "function" &&
                        deleteLoading === false
                      ) {
                        onDelete(item.id);
                      }
                    }}
                  >
                    Delete <DesktopAndTablet>card</DesktopAndTablet>
                  </button>
                </div>
              ),
              value: item.id,
            }))}
          />
        )}

        <Button
          color="gray-300"
          textColor="gray-900"
          size="xl"
          outline
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setNewCard(true);
          }}
          full
          className="hover:border-fish-primary hover:bg-fish-primary hover:text-white focus:text-white focus:outline-none focus:border-fish-primary focus:bg-fish-primary active:bg-fish-primary mb-4"
        >
          Add new credit card
        </Button>
        {newCard && (
          <>
            <div className="grid grid-cols-6 gap-x-4">
              <div className="col-span-6">
                <div className="gs-form-group">
                  <label className="label block text-sm font-medium leading-5 text-blue-gray-700 mb-2">
                    Card number
                  </label>
                  <CardElement
                    onFocus={() => {
                      setFieldValue("payment_method_id", "");
                    }}
                    {...createOptions(elementFontSize, 20)}
                  />
                </div>
              </div>
            </div>
            <Checkbox
              name="saving_card"
              content="Save card for future payments"
              className="text-blue-gray-900 font-medium"
              classNameWrapper="mt-0"
            />
          </>
        )}
        <Button
          color="fish-primary"
          textColor="white"
          size="xl"
          className="mt-4 hover:bg-fish-primary-700"
          full
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Pay
        </Button>
      </Form>
    );
  }
  return (
    <Form>
      <div className="grid grid-cols-6 gap-x-4">
        <div className="col-span-6">
          <div className="gs-form-group">
            <label className="label block text-sm font-medium leading-5 text-blue-gray-700 mb-2">
              Card number
            </label>
            <CardElement
              onFocus={() => {
                setFieldValue("payment_method_id", "");
              }}
              {...createOptions(elementFontSize, 20)}
            />
          </div>
        </div>
      </div>
      <Button
        color="fish-primary"
        textColor="white"
        size="xl"
        className="mt-4 hover:bg-fish-primary-700"
        full
        type="submit"
        disabled={loading}
        loading={loading}
      >
        Pay
      </Button>
    </Form>
  );
};
export default injectStripe(StripeCardForm);

import React from "react";

/**
 * import lib tools
 */
import { get } from "lodash";
import { reduxForm, Field } from "redux-form";
import { renderTextField, required } from "@components/reduxform";

const LicenceNumberForm = props => {
  const { handleSubmit, staticData, loading } = props;
  return (
    <div className="account-form">
      <form onSubmit={handleSubmit}>
        <Field
          id="licence_number"
          name="custom:licence_number"
          label={get(staticData, "licence_number.label")}
          placeholder={get(staticData, "licence_number.placeholder")}
          type="text"
          validate={required}
          component={renderTextField}
          align="left"
        />
      </form>
    </div>
  );
};

const LicenceNumberFormWithReduxForm = reduxForm({
  form: "LicenceNumberForm"
})(LicenceNumberForm);

export default LicenceNumberFormWithReduxForm;

import {
  WALLET_WITHDRAWAL_CANCEL_LOADING,
  WALLET_WITHDRAWAL_CANCEL_SUCCESS,
  WALLET_WITHDRAWAL_CANCEL_FAILED,
  WALLET_WITHDRAWAL_CANCEL_RECEIVED,
} from "./withdrawalCancelAction";

export function walletWithdrawalCancelStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_WITHDRAWAL_CANCEL_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_WITHDRAWAL_CANCEL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      });

    case WALLET_WITHDRAWAL_CANCEL_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: {},
        error: action.error,
        justFinished: true,
      });

    case WALLET_WITHDRAWAL_CANCEL_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: {},
  error: null,
  justFinished: false,
};

import { useEffect, useCallback } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionShowUsecase } from "@core/index";

import {
  auctionShowAction,
  AUCTION_SHOW,
} from "@redux/modules_v2/auctions/show/action";
import { auctionBidListAction } from "@redux/modules_v2/auctions/bidList/action";

//Hooks
import { useCurrentUser } from "@hooks";

/**
 * Fetch auction show hooks
 * @param {*} param
 */
export const useFetchAuctionShow = (id, finalBidPrice) => {
  const dispatch = useDispatch();

  const fetchAuctionShow = useCallback(() => {
    dispatch(auctionShowAction.request({ id }));
  }, [id]);

  const onAuctionReceived = useCallback((obj = {}) => {
    if (!isEmpty(obj.bidsList)) {
      dispatch(auctionBidListAction.success(obj.bidsList));
    }

    //last chance 刷新倒计时
    if (!isEmpty(obj.auction)) {
      dispatch(auctionShowAction.success(obj.auction));
    }
  }, []);

  const auctionShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_SHOW, state);
    return Object.assign({}, get(state, "auctionShowStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = auctionShowStatus;
  const currentUser = useCurrentUser();
  const usecase = new AuctionShowUsecase(
    data,
    get(currentUser, "user", {}),
    finalBidPrice
  );

  /**
   * did mount fetch show
   */
  useEffect(() => {
    id && fetchAuctionShow();
  }, [id]);

  return { usecase, loading, onAuctionReceived, fetchAuctionShow };
};


export const AUCTIONS_CATEGORIES_FETCH_REQUEST = "auctions/categories_fetch_request";
export const AUCTIONS_CATEGORIES_FETCH_LOADING = "auctions/categories_fetch_loading";
export const AUCTIONS_CATEGORIES_FETCH_SUCCESS = "auctions/categories_fetch_success";
export const AUCTIONS_CATEGORIES_FETCH_FAILED = "auctions/categories_fetch_failed";

export function fetchAuctionsCategories(payload = {}) {
  return {
    type: AUCTIONS_CATEGORIES_FETCH_REQUEST,
    payload
  };
}


export const AUCTIONS_CATEGORIES_CHANGE_REQUEST = "auctions/categories_change_request";
export const AUCTIONS_CATEGORIES_CHANGE_LOADING = "auctions/categories_change_loading";
export const AUCTIONS_CATEGORIES_CHANGE_SUCCESS = "auctions/categories_change_success";
export const AUCTIONS_CATEGORIES_CHANGE_FAILED = "auctions/categories_change_failed";

export function onAuctionsCategoriesChange(payload={}) {
  return {
    type: AUCTIONS_CATEGORIES_CHANGE_REQUEST,
    payload
  };
}
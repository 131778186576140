import React, { Component } from "react";

/**
 * import lib tools
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import Notice from "@utils/noticeNew";
import { Button } from "@components";

/**
 * action
 */
import { resendEmailConfirmationCodeRequest } from "@redux/modules/resendEmailCode/action";

class ResetCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestCodeDisabled: false
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      this.props.codeRequestStatus.loading &&
      nextProps.codeRequestStatus.error
    ) {
      // code request failed
      Notice.failure(nextProps.codeRequestStatus.error.message);
      return;
    }
    if (
      this.props.codeRequestStatus.loading &&
      nextProps.codeRequestStatus.data
    ) {
      // code request success
      Notice.success("Verification code sent successfully", "alert");
    }
  }

  handleRequestCode = values => {
    this.setState({ requestCodeDisabled: true });
    this.handleRequestCodeAllow();
    this.resendEmailConfirmationCodeRequest();
  };

  handleRequestCodeAllow = () => {
    setTimeout(() => {
      this.setState({ requestCodeDisabled: false });
    }, 60 * 1000);
  };

  resendEmailConfirmationCodeRequest = () => {
    this.props.resendEmailConfirmationCodeRequest();
  };

  render() {
    const { requestCodeDisabled } = this.state;
    const { hideText } = this.props;
    return (
      <div>
        {hideText ? null : (
          <div className="fs-14 lh-14 mb-3">
            A verification email has been sent to your email address. Please
            check and verify your email.{" "}
            <strong>{get(this.props, "user.email", "")}</strong>
          </div>
        )}
        <Button
          onClick={this.handleRequestCode}
          disabled={requestCodeDisabled}
          className="capitalize mb-4"
          size="lg"
          color="fish-primary"
        >
          {requestCodeDisabled ? "Please check your email" : "Resend Code"}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: get(state, "currentUserStatus.user", ""),
    codeRequestStatus: state.resendEmailConfirmationCodeStatus
  };
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    {},
    bindActionCreators(
      {
        resendEmailConfirmationCodeRequest
      },
      dispatch
    ),
    {
      dispatch
    }
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetCode);
import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";

import {
 categoriesListAction,
 CATEGORIES_LIST
} from "@redux/modules_v2/quotaAttr/categories/action";

/**
 * Fetch list hooks
 * @param {*} param
 */
export const useCategoriesFetchList = () => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(categoriesListAction.request());
  };

  const status = useSelector(state => {
    const selector = new LoadingSelector(CATEGORIES_LIST, state);
    return Object.assign({}, get(state, "quotaAttrStatus.categoriesListStatus", {}), {
      loading: selector.loading
    });
  });

  const { data, loading } = status;
  /**
   * did mount fetch list
   */
  useEffect(() => {
    fetchList();
  }, []);

  return [data, loading];
};

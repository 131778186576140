
export const PRICEABLES_HEADER_FETCH_REQUEST = "priceables/fetch_header_request";
export const PRICEABLES_HEADER_FETCH_LOADING = "priceables/fetch_header_loading";
export const PRICEABLES_HEADER_FETCH_SUCCESS = "priceables/fetch_header_success";
export const PRICEABLES_HEADER_FETCH_FAILED = "priceables/fetch_header_failed";


export function fetchPriceablesHeader(category_id, params) {
  return {
    type: PRICEABLES_HEADER_FETCH_REQUEST,
    payload: {
      category_id,
      params,
    },
  };
}

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { SET_CURRENT_USER } from "./action";

const initializeStates = {
  isAuthenticated: false,
  isLoading: true,
  user: {},
};

export const currentUserStatus = (state = initializeStates, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      const sub = get(action, "payload.sub", "");
      return {
        isAuthenticated: !isEmpty(action.payload),
        isLoading: false,
        user: Object.assign({}, action.payload, {
          user_id: sub,
        }),
      };

    default:
      return state;
  }
};

import {
  AUTH_LOGOUT_LOADING,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED,
  AUTH_LOGOUT_RECEIVED,
} from "./action";
export const logoutStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case AUTH_LOGOUT_LOADING:
      return {
        justFinished: false,
        loading: true,
        data: null,
        error: null,
      };
    case AUTH_LOGOUT_SUCCESS:
      return {
        justFinished: true,
        loading: false,
        data: action.data,
        error: null,
      };
    case AUTH_LOGOUT_FAILED:
      return {
        justFinished: true,
        loading: false,
        data: null,
        error: action.error,
      };
    case AUTH_LOGOUT_RECEIVED:
      return {
        justFinished: false,
        loading: false,
        data: null,
        error: null,
      };
    default:
      return state;
  }
};

import {
  REFRESH_CURRENT_USER_LOADING,
  REFRESH_CURRENT_USER_FAILED,
  REFRESH_CURRENT_USER_SUCCESS,
  REFRESH_CURRENT_USER_RESET,
} from "./action";

export const currentUserRefreshStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case REFRESH_CURRENT_USER_LOADING:
      return {
        loading: true,
        data: null,
        error: null,
      };
    case REFRESH_CURRENT_USER_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    case REFRESH_CURRENT_USER_FAILED:
      return {
        loading: false,
        data: null,
        error: action.error,
      };
    case REFRESH_CURRENT_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null,
      };
    default:
      return state;
  }
};

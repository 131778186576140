/**
 * Set current user
 * @param {Object} setCurrentUser
 */

export const SET_CURRENT_USER = "auth/set_current_user";

export function setCurrentUser(payload) {
  return {
    type: SET_CURRENT_USER,
    payload
  };
}

/**
 * Log out
 * @param {Object} payload {redirect_url:""}
 */

export const AUTH_LOGOUT_REQUEST = "auth/logout_request";
export const AUTH_LOGOUT_LOADING = "auth/logout_loading";
export const AUTH_LOGOUT_SUCCESS = "auth/logout_success";
export const AUTH_LOGOUT_FAILED = "auth/logout_failed";
export const AUTH_LOGOUT_RECEIVED = "auth/logout_received";

export function logout(payload) {
  return {
    type: AUTH_LOGOUT_REQUEST,
    payload
  };
}
export function logoutReceived() {
  return {
    type: AUTH_LOGOUT_RECEIVED
  };
}

/**
 * Refresh current user
 * @param {Object} refreshCurrentUser
 */

export const REFRESH_CURRENT_USER_REQUEST = "auth/refresh_current_user_request";
export const REFRESH_CURRENT_USER_LOADING = "auth/refresh_current_user_loading";
export const REFRESH_CURRENT_USER_FAILED = "auth/refresh_current_user_failed";
export const REFRESH_CURRENT_USER_SUCCESS = "auth/refresh_current_user_success";
export const REFRESH_CURRENT_USER_RESET = "auth/refresh_current_user_reset";

export function refreshCurrentUser() {
  return {
    type: REFRESH_CURRENT_USER_REQUEST
  };
}

export function refreshCurrentUserReset() {
  return {
    type: REFRESH_CURRENT_USER_RESET
  }
}



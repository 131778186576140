import { useState, useEffect, useCallback } from "react";
import {
  submit,
  reset,
  untouch,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import toNumber from "lodash/toNumber";

//Core Method
import { LoadingSelector } from "@core";
import Notice from "@utils/noticeNew";
import {
  auctionBidCreateAction,
  AUCTION_BID_CREATE,
} from "@redux/modules_v2/auctions/bidCreate/action";

//Hooks
import { useActionCableConnect } from "@hooks";
/**
 * [hooks] create new auction bid
 */
export const useAuctionCreateBid = ({ auctionId, seller, login, success }) => {
  const dispatch = useDispatch();
  const { status: isConnected } = useActionCableConnect();

  //Auction bid create status
  const auctionBidCreateStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_BID_CREATE, state);
    return Object.assign({}, get(state, "auctionBidCreateStatus", {}), {
      loading: selector.loading,
    });
  });

  // Declare your memoized mapState function
  const selector = formValueSelector("AuctionBidForm");
  const mapState = useCallback(
    (state) => ({
      price: selector(state, "price"),
    }),
    [selector]
  );
  const { price } = useSelector(mapState);

  /**
   * 初始化表单
   */
  const initialState = {
    price: "",
  };
  const [initialValues, setInitalValues] = useState(initialState);

  /**
   * 提交表单
   */
  const startSubmit = () => {
    dispatch(submit("AuctionBidForm"));
  };

  /**
   * 提交表单callback
   */
  const handleSubmit = useCallback(
    (values = {}) => {
      dispatch(untouch("AuctionBidForm", "price"));
      if (!isConnected) {
        throw new SubmissionError({ price: "Unstable connection." });
      }
      if (!login) {
        throw new SubmissionError({ price: "Please login." });
      }
      if (seller) {
        throw new SubmissionError({
          price: "The lister can not bid on your own auction.",
        });
      }
      const data = { auction_id: auctionId, price: toNumber(values.price) };
      dispatch(auctionBidCreateAction.request(data));
    },
    [auctionId, seller, isConnected, login]
  );

  /**
   * 创建permanent 后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues(initialState);
      dispatch(auctionBidCreateAction.reset());
    };
  }, []);
  useEffect(() => {
    if (!auctionBidCreateStatus.loading) {
      if (auctionBidCreateStatus.data) {
        typeof success === "function" && success(auctionBidCreateStatus.data);
        Notice.success("You have successfully posted a new bid.");
      }
      if (auctionBidCreateStatus.error) {
        Notice.failure(auctionBidCreateStatus.error);
      }
      dispatch(reset("AuctionBidForm"));
    }
  }, [auctionBidCreateStatus.loading]);

  return {
    handleSubmit,
    initialValues,
    loading: auctionBidCreateStatus.loading,
    price,
    setInitalValues,
    startSubmit,
  };
};

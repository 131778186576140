export const CREATED_AUCTIONS = "created_auctions";
export const CREATED_AUCTIONS_REQUEST = `${CREATED_AUCTIONS}_request`;
export const CREATED_AUCTIONS_SUCCESS = `${CREATED_AUCTIONS}_success`;
export const CREATED_AUCTIONS_FAILURE = `${CREATED_AUCTIONS}_failure`;
export const CREATED_AUCTIONS_UPDATE = `${CREATED_AUCTIONS}_update`;

export const createdAuctionsAction = {
  request: (payload) => {
    return {
      type: CREATED_AUCTIONS_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: CREATED_AUCTIONS_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: CREATED_AUCTIONS_FAILURE,
      error,
    };
  },
  update: (payload) => {
    return {
      type: CREATED_AUCTIONS_UPDATE,
      payload,
    };
  },
};

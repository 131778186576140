import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, FisheryUsecase } from "@core/index";

import {
  fisheryShowAction,
  FISHERY_SHOW,
} from "@redux/modules_v2/fishery/show/action";

/**
 * Fetch fishery show hooks
 * @param {*} param
 */
export const useFisheryShow = (id, callBack) => {
  const dispatch = useDispatch();

  const fetchShow = useCallback(() => {
    dispatch(fisheryShowAction.request({ id }));
  }, [id]);

  const updateShow = useCallback((values) => {
    values && dispatch(fisheryShowAction.update(values));
  }, []);

  const fisheryShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERY_SHOW, state);
    return Object.assign({}, get(state, "fisheryShowStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = fisheryShowStatus;
  const usecase = new FisheryUsecase(data);

  /**
   * did mount fetch list
   */
  useEffect(() => {
    id && fetchShow();
  }, [id]);

  useEffect(() => {
    if (!fisheryShowStatus.loading) {
      if (fisheryShowStatus.data) {
        const cagegoryId = get(fisheryShowStatus, "data.category.id", "");
        if (!cagegoryId) {
          return;
        }
        if (!callBack) {
          return;
        }
        callBack({
          category_id: cagegoryId,
        });
      }
    }
  }, [fisheryShowStatus.loading]);

  return { data: usecase.single, loading, updateShow };
};

import { takeLatest, call, put } from "redux-saga/effects";

import { UserApiUsecase } from "@core";
import { USER_PROFILE_REQUEST, userProfileAction } from "./action";

// fetch user profile
export function* watchUserProfileFetch() {
  yield takeLatest(USER_PROFILE_REQUEST, userProfileFetch);
}

function* userProfileFetch(action) {
  try {
    const usecase = new UserApiUsecase();
    const res = yield call([usecase, "profile"], action.payload);
    yield put(userProfileAction.success(res.data));
  } catch (err) {
    yield put(userProfileAction.failure(err));
  }
}

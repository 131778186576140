import {
  TRANSFER_APPLY_CREATE_SUCCESS,
  TRANSFER_APPLY_CREATE_FAILURE,
  TRANSFER_APPLY_CREATE_RECEIVED,
} from "./action";

export const transferApplyCreateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case TRANSFER_APPLY_CREATE_SUCCESS:
      return {
        justFinished: true,
        data: action.data,
        error: null,
      };

    case TRANSFER_APPLY_CREATE_FAILURE:
      return {
        justFinished: true,
        data: null,
        error: action.error,
      };

    case TRANSFER_APPLY_CREATE_RECEIVED:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  justFinished: false,
  data: null,
  error: null,
};

import { useMediaQuery } from "react-responsive";

/**
 * [hooks] React Responsive
 */
export const useResponsive = () => {
  const isLargeDesktop = useMediaQuery({ minWidth: 1280 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1279 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktopAndLargeDesktop = useMediaQuery({ minWidth: 992 });
  const isDesktopAndTablet = useMediaQuery({ minWidth: 768 });
  const isMobileAndTablet = useMediaQuery({ maxWidth: 991 });
  return {
    isLargeDesktop,
    isDesktop,
    isTablet,
    isMobile,
    isDesktopAndLargeDesktop,
    isDesktopAndTablet,
    isMobileAndTablet,
  };
};

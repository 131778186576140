import { useState, useEffect, useCallback } from "react";
import { submit, formValueSelector, arrayPush, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import {
  find,
  findIndex,
  map,
  get,
  filter,
  capitalize,
  isEmpty,
  sumBy,
  compact,
} from "lodash";

//Core Method
import { LoadingSelector, unitOfSaleMap } from "@core";
import Notice from "@utils/noticeNew";
import numeral from "@utils/numeral";
import {
  permanentUpdateAction,
  PERMANENT_UPDATE,
} from "@redux/modules_v2/account/permanentUpdate/action";

//Hooks
import { useFisheryList, useDeepEffect, useFisheryQuotaList } from "@hooks";

/**
 * [hooks] create new permanents listing
 */
export const useUpdatePermanent = ({ permanent }, success) => {
  const dispatch = useDispatch();
  const selector = formValueSelector("PermannentNewForm");
  const type = permanent.listingType;
  const [quotaParams, setQuotaParams] = useState("");
  const { options, data } = useFisheryList({});
  const [quotaList, quotaListLoading] = useFisheryQuotaList(quotaParams);
  const [isLicence, setLicence] = useState(false);
  // Declare your memoized mapState function
  const mapState = useCallback(
    (state) => {
      return {
        title: selector(state, "title"),
        markets_attributes: selector(state, "markets_attributes"),
        fishery_id: selector(state, "fishery_id"),
      };
    },
    [selector]
  );
  const { markets_attributes, title, fishery_id } = useSelector(mapState);

  // Permanent udpate status
  const permanentUpdateStatus = useSelector((state) => {
    const selector = new LoadingSelector(PERMANENT_UPDATE, state);
    return Object.assign({}, get(state, "accountPermanentUpdateStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    title: "",
    description: "",
    expiry_days: "7",
    fishery_id: "",
    markets_attributes: [],
  });
  const [totalPrice, setTotalPrice] = useState();
  /**
   * 获取未被表单选择的quota列表
   * @param {array} fields market_attr 列表
   * @param {number|string} currentQuotumId 当前market_attr的id
   */
  const getQuotaOptions = useCallback(
    (fields, currentQuotumId) => {
      let calculatedQuotas = [];

      const fieldsQuotaIds = fields.map((_, idx) =>
        get(fields.get(idx), "quotum_id", "")
      );

      quotaList.forEach((quota) => {
        const index = findIndex(fieldsQuotaIds, (id) => id == quota.id);
        if (index === -1 || currentQuotumId == quota.id) {
          calculatedQuotas.push(quota);
        }
      });

      const options = map(calculatedQuotas, (o) => {
        let label = [
          get(o, "state.name", ""),
          get(o, "zone.name", ""),
          get(o, "species.name", ""),
        ];
        if (!isLicence) {
          label = [...label, "Quota", capitalize(get(o, "unit_name", ""))];
        }
        return {
          label: compact(label).join(" "),
          value: o.id,
        };
      });
      return options;
    },
    [quotaList, markets_attributes, isLicence]
  );

  /**
   * 提交表单
   */
  const startSubmit = () => {
    dispatch(submit("PermannentNewForm"));
  };

  /**
   * 提交表单callback
   */
  const handleSubmit = useCallback(
    (values) => {
      const data = Object.assign({}, values, {
        expiry_days: values.expiry_days > 0 ? Number(values.expiry_days) : "",
        permanent_listing_type: type,
        markets_attributes: map(values.markets_attributes, (item) => {
          return Object.assign({}, item, {
            price: Number(item.price),
            quantity: Number(item.quantity),
          });
        }),
      });
      dispatch(permanentUpdateAction.request(data));
    },
    [type]
  );

  /**
   * 添加默认initalValues
   * @param {array} fields market_attr 列表
   */
  const addNewMarketsAttribute = useCallback(
    (fields) => {
      const options = getQuotaOptions(fields);
      if (isEmpty(options)) {
        Notice.failure("Not quota available");
        return;
      }
      dispatch(
        arrayPush("PermannentNewForm", "markets_attributes", {
          price: "",
          quantity: "",
          quotum_id: get(options, "[0][value]", ""),
          unit_of_sale: unitOfSaleMap.unit,
        })
      );
    },
    [quotaList]
  );

  /**
   * 删除已有的market， 添加删除标记
   */
  const moveMarket = (id) => {
    const list = [];
    initialValues.markets_attributes.forEach((item) => {
      if (item.id == id) {
        item._destroy = true;
      }
      list.push(item);
    });
    setInitalValues(
      Object.assign({}, initialValues, {
        markets_attributes: list,
      })
    );
    parseTotal(list);
  };

  const parseTotal = (list) => {
    const sumPrice = sumBy(list, (attr) => {
      if (attr._destroy) {
        return 0;
      }
      return Number(attr.price) * Number(attr.quantity);
    });
    setTotalPrice(numeral(sumPrice).format());
  };

  /**
   * 从quota options 中根据id获取unit name
   */
  const getUnitNameByQuotumId = useCallback(
    (currentQuotumId) => {
      const index = findIndex(
        quotaList,
        (quota) => currentQuotumId == quota.id
      );
      return get(quotaList, `[${index}].unit_name`, "");
    },
    [quotaList]
  );

  /**
   * 判断 unitOfSale 返回 unit
   */
  const unitNameCondition = useCallback(
    (currentQuotumId, unitOfSale) => {
      const isSaleByUnit = unitOfSale === unitOfSaleMap.unit;
      if (isSaleByUnit) {
        return getUnitNameByQuotumId(currentQuotumId);
      }
      return "kg";
    },
    [quotaList]
  );

  /**
   * 判断 fishery fishery type 是否是 licence
   */
  const getCurrentLicence = useCallback(
    (fisheryId) => {
      const currentFishery = find(data, (o) => fisheryId == o.id);
      const currentFisheryType = get(currentFishery, "fisheryType", "");
      return currentFisheryType === "licence";
    },
    [data]
  );

  /**
   * 初始化增加一个默认market_attribute
   */
  useDeepEffect(() => {
    if (permanent && permanent.id) {
      setInitalValues(
        Object.assign({}, initialValues, {
          id: permanent.id,
          fishery_id: get(permanent, "fisheryId", ""),
          title: get(permanent, "title", ""),
          description: get(permanent, "description", ""),
          expiry_days: "",
          expiredAt: get(permanent, "expiredAt", ""),
          markets_attributes: map(permanent.markets, (item) => {
            return {
              id: item.id,
              quotum_id: get(item, "quotum.id", ""),
              unitName: get(item, "unitName", ""),
              unit_of_sale: get(item, "unitOfSale", ""),
              quantity: get(item, "quantity", ""),
              price: get(item, "priceValue", ""),
              fish_on: item.fishOn ? "on" : "off",
            };
          }),
        })
      );
    }
  }, [permanent]);

  useEffect(() => {
    return () => {
      setInitalValues({});
    };
  }, []);

  /**
   * 根据market_attribute自动生成title
   */
  useDeepEffect(() => {
    if (!isEmpty(markets_attributes)) {
      const quotas = filter(
        quotaList,
        (quota) =>
          findIndex(markets_attributes, (attr) => attr.quotum_id == quota.id) >
          -1
      );
      const names = map(quotas, (quota) => {
        let label = [
          get(quota, "state.name", ""),
          get(quota, "zone.name", ""),
          get(quota, "species.name", ""),
        ];
        if (!isLicence) {
          label = [...label, "Quota", capitalize(get(quota, "unit_name", ""))];
        }

        return compact(label).join(" ");
      });
      dispatch(change("PermannentNewForm", "title", names.join(" And ")));
    }
  }, [quotaList, markets_attributes, isLicence]);

  /**
   * 根据market_attribute自动计算总价
   */
  useDeepEffect(() => {
    parseTotal(markets_attributes);
  }, [markets_attributes]);

  /**
   * 创建 permanent 后的 effect
   */
  useEffect(() => {
    if (permanentUpdateStatus.justFinished) {
      if (permanentUpdateStatus.data) {
        Notice.success("You have successfully update a permanent listing.");
        typeof success === "function" && success();
      }
      if (permanentUpdateStatus.error) {
        Notice.failure(permanentUpdateStatus.error);
      }
      dispatch(permanentUpdateAction.received());
      setInitalValues({});
    }
  }, [permanentUpdateStatus]);

  /**
   * 根据fishery改变获取当前的fisherytype是否为licence
   */
  useEffect(() => {
    setLicence(getCurrentLicence(fishery_id));
  }, [data, fishery_id]);

  /**
   * 根据fishery改变获取quota list
   */
  useEffect(() => {
    fishery_id && setQuotaParams(fishery_id);
  }, [fishery_id]);

  return {
    addNewMarketsAttribute,
    getQuotaOptions,
    getUnitNameByQuotumId,
    unitNameCondition,
    handleSubmit,
    initialValues,
    setInitalValues,
    startSubmit,
    moveMarket,
    title,
    totalPrice,
    fisheryOptions: options,
    loading: permanentUpdateStatus.loading,
    quotaListLoading,
    isLicence,
  };
};

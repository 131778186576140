const VERIFY_REQUEST = 'verify/request'
const VERIFY_LOADING = 'verify/loading'
const VERIFY_SUCCESS = 'verify/success'
const VERIFY_FAILED = 'verify/failed'

function verifyRequest(payload) {
  return {
    type: VERIFY_REQUEST,
    payload
  }
}

export { verifyRequest, VERIFY_REQUEST, VERIFY_FAILED, VERIFY_LOADING, VERIFY_SUCCESS }
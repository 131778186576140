import { all, fork } from "redux-saga/effects";

import { watchAskingPricesFetch } from "./modules/askingPrices/listSaga";
import { watchAskingPriceShowFetch } from "./modules/askingPrices/showSaga";
import { watchAskingPricePost } from "./modules/askingPrices/createSaga";
import { watchAskingPriceUpdate } from "./modules/askingPrices/updateSaga";
import { watchAskingPriceDelete } from "./modules/askingPrices/deleteSaga";
import { watchAttendAskingPricesFetch } from "./modules/askingPrices/attendListSaga";
import { watchAskingPricesFetchQuotaBalance } from "./modules/askingPrices/quotaBalanceSaga";

import { watchOfferPricesFetch } from "./modules/offerPrices/listSaga";
import { watchOfferPriceShowFetch } from "./modules/offerPrices/showSaga";
import { watchOfferPricePost } from "./modules/offerPrices/createSaga";
import { watchOfferPriceUpdate } from "./modules/offerPrices/updateSaga";
import { watchOfferPriceDelete } from "./modules/offerPrices/deleteSaga";
import { watchAttendOfferPricesFetch } from "./modules/offerPrices/attendListSaga";

import { watchExchangesFetch } from "./modules/exchanges/listSaga";

import { watchListingsFetch } from "./modules/listings/listSaga";
import { watchListingPost } from "./modules/listings/createSaga";
import { watchListingShowFetch } from "./modules/listings/showSaga";
import { watchListingMessagePost } from "./modules/listings/messageSaga";

import { watchChannelsFetch } from "./modules/channels/listSaga";
import { watchChannelPost } from "./modules/channels/createSaga";
import { watchChannelMessagesFetch } from "./modules/channels/messageListSaga";
import { watchChannelMessagePost } from "./modules/channels/messageCreateSaga";

import { watchPriceablesFetch } from "./modules/priceables/listSaga";
import { watchAuctionsPriceablesFetch } from "./modules/priceables/listSaga";
import { watchPriceablesProductsFetch } from "./modules/priceables/productsSaga";
import { watchPriceablesCategoryFetch } from "./modules/priceables/categorySaga";
import { watchPriceablesHeaderFetch } from "./modules/priceables/headerSaga";

import { watchApplyPost } from "./modules/applies/createSaga";
import { watchApplyDelete } from "./modules/applies/deleteSaga";

import { watchProductsFetch } from "./modules/products/listSaga";
import { watchProductShowFetch } from "./modules/products/showSaga";
import { watchProductNewRequest } from "./modules/products/newSaga";
import { watchProductRecentTradeFetch } from "./modules/products/recentTradeSaga";
import { watchProductPricesFetch } from "./modules/products/pricesSaga";

import { watchOrdersFetch } from "./modules/orders/listSaga";
import { watchOrderShowFetch } from "./modules/orders/showSaga";
import { watchOrderPost } from "./modules/orders/createSaga";
import { watchOrderPaymentPost } from "./modules/orders/paymentSaga";
import { watchOrdersDashboardFetch } from "./modules/orders/dashboardSaga";
import { watchOrdersDashboardSideFetch } from "./modules/orders/dashboardSideSaga";
import { watchCloseOrder } from "./modules/orders/closeSaga";

import { watchInvoicePost } from "./modules/invoices/createSaga";
import { watchInvoiceShowFetch } from "./modules/invoices/showSaga";

import {
  watchCartAskingPricesFetch,
  watchCartPricesUpdate,
  watchCartPricesDelete,
} from "./modules/cart/askingPricesSaga";
import { watchCartOfferPricesFetch } from "./modules/cart/offerPricesSaga";

import {
  watchAuctonsListFetch,
  watchAuctonsOwnListFetch,
  watchAuctonsInvolvedListFetch,
} from "./modules/auctions/listSaga";
import { watchAuctionCreate } from "./modules/auctions/newSaga";
import { watchAuctionEdit } from "./modules/auctions/editSaga";
import {
  watchAuctionsShowFetch,
  watchAuctionsFinished,
} from "./modules/auctions/showSaga";
import { watchAuctionCatesFetch } from "./modules/auctions/catesSaga";
import { watchAuctionCatesChange } from "./modules/auctions/catesSaga";

import { watchCategoriesFetch } from "./modules/categories/listSaga";
import { watchCategoryProductGradesFetch } from "./modules/categories/gradeSaga";

import { watchChartsLastSoldFetch } from "./modules/charts/lastSoldSaga";

import { watchLogout, watchCurrentUserRefresh } from "./modules/auth/saga";

import { watchBankUpdate } from "./modules/banks/editSaga";
import { watchBankShow } from "./modules/banks/showSaga";
import { watchBankUpdateForOrder } from "./modules/banks/OrderEditSaga";

import { watchStaticDataFetch } from "./modules/static/saga";
import { watchLiveStatesFetch } from "./modules/static/liveStatesSaga";

import { watchProfileFetch } from "./modules/profile/saga";

import { watchBidNew } from "./modules/bids/newSaga";
import { watchBidListFetch, watchBidListUpdate } from "./modules/bids/listSaga";

import { watchsubscribe } from "./modules/subscribe/saga";

import { watchLoginRequest } from "./modules/login/saga";

import { watchRegisterRequest } from "./modules/register/saga";

import { watchVerifyRequest } from "./modules/verify/saga";

import { watchResendCodeRequest } from "./modules/resendCode/saga";

import { watchChangeEmailRequest } from "./modules/changeEmail/saga";

import { watchResendEmailConfirmationCodeRequest } from "./modules/resendEmailCode/saga";

import { watchVerifyEmailConfirmationCodeRequest } from "./modules/verifyEmail/saga";

import { watchChangePasswordRequest } from "./modules/changePassword/saga";

import { watchUpdateAttributeRequest } from "./modules/updateAttribute/saga";

import { watchInitForgotPasswordRequest } from "./modules/forgotPassword/initSaga";

import { watchConfirmForgotPasswordRequest } from "./modules/forgotPassword/confirmSaga";

import { watchRefreshCognitoUserRequest } from "./modules/refreshCognitoUser/saga";

import { watchTimestampFetch } from "./modules/timeStamp/saga";

import { watchRechargeDepositRequest } from "./modules/rechargeDeposit/saga";

import { watchUploadAvatarRequest } from "./modules/uploadAvatar/saga";

import { watchChangeEmailEventRequest } from "./modules/changeEmailEvent/saga";

import { watchChangePasswordEventRequest } from "./modules/changePasswordEvent/saga";

import { watchUpdateProfileEventRequest } from "./modules/updateProfileEvent/saga";

import { watchACCreateRequest } from "./modules/actioncable/saga";

import {
  watchPointsFetch,
  watchPointsRewardFetch,
  watchPointsConsumeRequest,
} from "./modules/points/saga";

import { watchLicenceNumberEventsRequest } from "./modules/licenceNumberEvents/saga";

import { watchWalletRequest } from "./modules/wallet/listSaga";
import { watchWalletOfflinesRequest } from "./modules/wallet/offlinesListSaga";
import { watchWalletTopupPost } from "./modules/wallet/topupSaga";
import { watchWalletWithdrawalShowRequest } from "./modules/wallet/withdrawalShowSaga";
import { watchWalletWithdrawalPost } from "./modules/wallet/withdrawalSaga";
import { watchWalletWithdrawalCancelPut } from "./modules/wallet/withdrawalCancelSaga";
import { watchWalletBalanceRequest } from "./modules/wallet/balanceSaga";

// ----------- v2 --------------
// global
import { watchGlobalStaticFetch } from "./modules_v2/global/static/saga";

// market
import { watchMarketListFetch } from "./modules_v2/markets/list/saga";
import { watchMarketShowFetch } from "./modules_v2/markets/show/saga";
import { watchMarketStatisticsFetch } from "./modules_v2/markets/statistics/saga";
import { watchMarketBalanceFetch } from "./modules_v2/markets/balance/saga";
// offer
import { watchMarketOfferListFetch } from "./modules_v2/offers/list/saga";
import { watchMarketOfferCreatePost } from "./modules_v2/offers/create/saga";
import { watchMarketOfferUpdatePut } from "./modules_v2/offers/update/saga";
import { watchMarketOfferDelete } from "./modules_v2/offers/delete/saga";
import { watchMarketOfferOrderPost } from "./modules_v2/offers/order/saga";
import { watchOffersApply } from "./modules_v2/offers/apply/saga";
import { watchOffersApplyConfirm } from "./modules_v2/offers/applyConfirm/saga";
// bid
import { watchMarketBidListFetch } from "./modules_v2/bids/list/saga";
import { watchMarketBidCreatePost } from "./modules_v2/bids/create/saga";
import { watchMarketBidUpdatePut } from "./modules_v2/bids/update/saga";
import { watchMarketBidDelete } from "./modules_v2/bids/delete/saga";
import { watchMarketBidOrderPost } from "./modules_v2/bids/order/saga";
// quota transfer apply
import { watchTransferApplyCreatePost } from "./modules_v2/quotaTransferApplies/create/saga";
// order
import { watchOrderListFetch } from "./modules_v2/orders/list/saga";
import { watchOrderShowFetchV2, watchOrderShowCheck } from "./modules_v2/orders/show/saga";
import { watchOrderPaymentPostV2 } from "./modules_v2/orders/payment/saga";
import { watchOrderPurchasedFetch } from "./modules_v2/orders/purchased/saga";
import { watchOrderSoldFetch } from "./modules_v2/orders/sold/saga";
import { watchOrdersBuyerPay } from "./modules_v2/orders/actions/buyerPay/saga";
import { watchOrderPaymentProofsListFetch } from "./modules_v2/orders/actions/paymentProofs/list/saga";
import { watchOrdersPaymentProofsNew } from "./modules_v2/orders/actions/paymentProofs/new/saga";
import { watchOrdersPaymentProofsDelete } from "./modules_v2/orders/actions/paymentProofs/delete/saga";
import { watchOrdersReceiveFund } from "./modules_v2/orders/actions/receiveFund/saga";
import { watchOrdersQuotaTransfersNew } from "./modules_v2/orders/actions/quotaTransfers/new/saga";
import { watchQuotaTransfersListFetch } from "./modules_v2/orders/actions/quotaTransfers/list/saga";

// invoice
import { watchInvoiceShowFetchV2 } from "./modules_v2/invoices/show/saga";
import { watchInvoiceCreatePostV2 } from "./modules_v2/invoices/create/saga";

// wallet
import { watchWalletBalanceFetch } from "./modules_v2/wallets/balance/saga";
import { watchWalletEntriesFetch } from "./modules_v2/wallets/entries/saga";
import { watchWalletTopupPostV2 } from "./modules_v2/wallets/topup/saga";
// withdraw (wallet)
import { watchWithdrawListFetch } from "./modules_v2/withdraws/list/saga";
import { watchWithdrawShowFetch } from "./modules_v2/withdraws/show/saga";
import { watchWithdrawCreatePost } from "./modules_v2/withdraws/create/saga";
// quota
import { watchQuotaListFetch } from "./modules_v2/quotas/list/saga";
// permanent
import { watchPermanentAcceptBidFetch } from "./modules_v2/permanent/acceptBid/saga";
import { watchPermanentBidFetch } from "./modules_v2/permanent/bid/saga";
import { watchPermanentBidListFetch } from "./modules_v2/permanent/bidList/saga";
import { watchpermanentListFetch } from "./modules_v2/permanent/list/saga";
import { watchPermanentNewFetch } from "./modules_v2/permanent/new/saga";
import { watchPermanentPurchaseFetch } from "./modules_v2/permanent/purchase/saga";
import { watchPermanentShowFetch } from "./modules_v2/permanent/show/saga";
import { watchPermanentDeleteFetch } from "./modules_v2/permanent/bidDelete/saga";
import { watchPermanentMarketsFetch } from "./modules_v2/permanent/markets/saga";
import { watchPermanentMarketGroupsFetch } from "./modules_v2/permanent/marketGroups/saga";
import { watchPermanentStatisticFetch } from "./modules_v2/permanent/statistic/saga";
import { watchPermanentEnquireFetch } from "./modules_v2/permanent/enquire/saga";

// quota attr
import { watchcountriesListFetch } from "./modules_v2/quotaAttr/countries/saga";
import { watchstatesListFetch } from "./modules_v2/quotaAttr/states/saga";
import { watchcategoriesListFetch } from "./modules_v2/quotaAttr/categories/saga";
// user
import { watchUserProfileFetch } from "./modules_v2/users/profile/saga";
// account
import { watchAccountBidsFetch } from "./modules_v2/account/bids/saga";
import { watchAccountOffersFetch } from "./modules_v2/account/offers/saga";
import { watchAccountRecendFetch } from "./modules_v2/account/recend/saga";
import { watchAccountOpenOrdersFetch } from "./modules_v2/account/openOrders/saga";
import { watchAccountProfileUpdateRequest } from "./modules_v2/account/profile/saga";
import { watchAccountPermanentListFetch } from "./modules_v2/account/permanentListing/saga";
import { watchAccountPermanentShow } from "./modules_v2/account/permanentShow/saga";
import { watchAccountPermanentUpdate } from "./modules_v2/account/permanentUpdate/saga";
import { watchAccountPermanentDelete } from "./modules_v2/account/permanentDelete/saga";
import { watchAccountBankShowFetch } from "./modules_v2/account/banks/show/saga";
import { watchAccountBankUpdate } from "./modules_v2/account/banks/edit/saga";
import { watchStatementListFetch } from "./modules_v2/account/statementListing/saga";
import { watchQuotumBalancesFetch } from "./modules_v2/account/quotumBalances/saga";
import { watchQuotumInventoriesFetch } from "./modules_v2/account/quotumInventories/saga";
import { watchCreatedAuctionsFetch } from "./modules_v2/account/createdAuctions/saga";
import { watchInvolvedAuctionsFetch } from "./modules_v2/account/involvedAuctions/saga";
import { watchAccountProfileRegisterFetch } from "./modules_v2/account/profile/register/saga";
import { watchNotificationListFetch } from "./modules_v2/account/notification/saga";

// popluar lists
import { watchPopularListFetch } from "./modules_v2/popularLists/list/saga";

// fishery
import { watchFisheriesListFetch } from "./modules_v2/fishery/list/saga";
import { watchFisheryStickListFetch } from "./modules_v2/fishery/stickList/saga";
import { watchfisheryShowFetch } from "./modules_v2/fishery/show/saga";
import { watchfisheryQuotaMarketsFetch } from "./modules_v2/fishery/quotaMarkets/saga";
import { watchfisheryActivityFetch } from "./modules_v2/fishery/activity/saga";
import { watchfisheryExistenceFetch } from "./modules_v2/fishery/existence/saga";
import { watchfisheryFollowFetch } from "./modules_v2/fishery/follow/saga";
import { watchfisheryFollowListFetch } from "./modules_v2/fishery/followList/saga";
import { watchFisheryFollowingQuotaMarketsFetch } from "./modules_v2/fishery/followingQuotaMarkets/saga";
import { watchFisheryQuotaListFetch } from "./modules_v2/fishery/quotaList/saga";
import { watchFisheryFollowingAuctionsFetch } from "./modules_v2/fishery/followingAuctions/saga";

// licence
import { watchLicenceUpdatePut } from "./modules_v2/licences/update/saga";

// auction for new edition
import { watchAuctionListFetch } from "./modules_v2/auctions/list/saga";
import { watchAuctionShowFetch } from "./modules_v2/auctions/show/saga";
import { watchAuctionBidCreate } from "./modules_v2/auctions/bidCreate/saga";
import { watchAuctionBidListFetch } from "./modules_v2/auctions/bidList/saga";
import { watchAuctionBidAllListFetch } from "./modules_v2/auctions/bidAllList/saga";
import { watchAuctionNewRequest } from "./modules_v2/auctions/new/saga";
import { watchAuctionFinalBidShowFetch } from "./modules_v2/auctions/finalBid/saga";
import { watchAuctionFinalBidCreate } from "./modules_v2/auctions/finalBidCreate/saga";
import { watchAuctionInstantBuy } from "./modules_v2/auctions/instantBuy/saga";
import { watchAuctionFinalChancesListFetch } from "./modules_v2/auctions/finalChancesList/saga";
import { watchAuctionSimilarListFetch } from "./modules_v2/auctions/similarList/saga";

//listing
import { watchListingOfferNew } from "./modules_v2/listing/new/saga";
import { watchListingQuotaTransfersListFetch } from "./modules_v2/listing/quotaTransfers/list/saga";
import { watchListingQuotaTransfersNew } from "./modules_v2/listing/quotaTransfers/new/saga";
import { watchListingOfferEnquiry } from "./modules_v2/listing/enquiry/saga";

import { watchp2pNewRequest } from "./modules_v2/p2p/new/saga";

export default function* rootSaga() {
  yield all([
    // offer price & asking price
    fork(watchOfferPricesFetch),
    fork(watchOfferPriceShowFetch),
    fork(watchOfferPricePost),
    fork(watchOfferPriceUpdate),
    fork(watchOfferPriceDelete),
    fork(watchAttendOfferPricesFetch),
    fork(watchAskingPricesFetch),
    fork(watchAskingPriceShowFetch),
    fork(watchAskingPricePost),
    fork(watchAskingPriceUpdate),
    fork(watchAskingPriceDelete),
    fork(watchAttendAskingPricesFetch),
    fork(watchAskingPricesFetchQuotaBalance),

    // exchange
    fork(watchExchangesFetch),

    // listing
    fork(watchListingShowFetch),
    fork(watchListingsFetch),
    fork(watchListingPost),
    fork(watchListingMessagePost),

    // priceables
    fork(watchPriceablesFetch),
    fork(watchAuctionsPriceablesFetch),
    fork(watchPriceablesProductsFetch),
    fork(watchPriceablesCategoryFetch),
    fork(watchPriceablesHeaderFetch),

    // applies
    fork(watchApplyPost),
    fork(watchApplyDelete),

    // channels
    fork(watchChannelsFetch),
    fork(watchChannelPost),
    fork(watchChannelMessagesFetch),
    fork(watchChannelMessagePost),

    // product
    fork(watchProductsFetch),
    fork(watchProductShowFetch),
    fork(watchProductNewRequest),
    fork(watchProductRecentTradeFetch),
    fork(watchProductPricesFetch),

    // order
    fork(watchOrdersFetch),
    fork(watchOrderShowFetch),
    fork(watchOrderPost),
    fork(watchOrderPaymentPost),
    fork(watchOrdersDashboardFetch),
    fork(watchOrdersDashboardSideFetch),
    fork(watchCloseOrder),

    // invoice
    fork(watchInvoicePost),
    fork(watchInvoiceShowFetch),

    // cart
    fork(watchCartAskingPricesFetch),
    fork(watchCartOfferPricesFetch),
    fork(watchCartPricesUpdate),
    fork(watchCartPricesDelete),

    //auctions
    fork(watchAuctonsListFetch),
    fork(watchAuctonsOwnListFetch),
    fork(watchAuctonsInvolvedListFetch),
    fork(watchAuctionCreate),
    fork(watchAuctionEdit),
    fork(watchAuctionsShowFetch),
    fork(watchAuctionCatesFetch),
    fork(watchAuctionCatesChange),
    fork(watchAuctionsFinished),

    //categories,
    fork(watchCategoriesFetch),
    fork(watchCategoryProductGradesFetch),

    // charts
    fork(watchChartsLastSoldFetch),

    //auth
    fork(watchLogout),
    fork(watchCurrentUserRefresh),

    // banks
    fork(watchBankUpdate),
    fork(watchBankShow),
    fork(watchBankUpdateForOrder),

    //static

    fork(watchStaticDataFetch),
    fork(watchLiveStatesFetch),

    // login
    fork(watchLoginRequest),

    // register
    fork(watchRegisterRequest),

    // veirfy
    fork(watchVerifyRequest),

    // resend code
    fork(watchResendCodeRequest),

    // change email
    fork(watchChangeEmailRequest),

    // change email code request
    fork(watchResendEmailConfirmationCodeRequest),

    // change email code verify
    fork(watchVerifyEmailConfirmationCodeRequest),

    // change password
    fork(watchChangePasswordRequest),

    // update attribute
    fork(watchUpdateAttributeRequest),

    // init forgot password
    fork(watchInitForgotPasswordRequest),

    // confirm forgot password
    fork(watchConfirmForgotPasswordRequest),

    // (user)account wallet
    fork(watchWalletRequest),
    fork(watchWalletOfflinesRequest),
    fork(watchWalletBalanceRequest),
    fork(watchWalletTopupPost),
    fork(watchWalletWithdrawalShowRequest),
    fork(watchWalletWithdrawalPost),
    fork(watchWalletWithdrawalCancelPut),

    //bids
    fork(watchBidNew),
    fork(watchBidListFetch),
    fork(watchBidListUpdate),

    //timestamp
    fork(watchTimestampFetch),

    //subscribe
    fork(watchsubscribe),

    // refresh cognito user
    fork(watchRefreshCognitoUserRequest),

    // recharge deposit
    fork(watchRechargeDepositRequest),

    // upload avatar
    fork(watchUploadAvatarRequest),

    // change email event
    fork(watchChangeEmailEventRequest),

    // change password event
    fork(watchChangePasswordEventRequest),

    // update profile event
    fork(watchProfileFetch),
    fork(watchUpdateProfileEventRequest),

    // actioncable
    fork(watchACCreateRequest),

    // points fetch
    fork(watchPointsFetch),
    fork(watchPointsRewardFetch),
    fork(watchPointsConsumeRequest),

    // Licence number events
    fork(watchLicenceNumberEventsRequest),

    // ------------------ v2 ---------------
    // global
    fork(watchGlobalStaticFetch),
    // market(lease)
    fork(watchMarketListFetch),
    fork(watchMarketShowFetch),
    fork(watchMarketStatisticsFetch),
    fork(watchMarketBalanceFetch),
    // offer
    fork(watchMarketOfferListFetch),
    fork(watchMarketOfferCreatePost),
    fork(watchMarketOfferUpdatePut),
    fork(watchMarketOfferDelete),
    fork(watchMarketOfferOrderPost),
    fork(watchOffersApply),
    fork(watchOffersApplyConfirm),
    // bid
    fork(watchMarketBidListFetch),
    fork(watchMarketBidCreatePost),
    fork(watchMarketBidUpdatePut),
    fork(watchMarketBidDelete),
    fork(watchMarketBidOrderPost),
    // quota transfer apply
    fork(watchTransferApplyCreatePost),
    // order
    fork(watchOrderListFetch),
    fork(watchOrderShowFetchV2),
    fork(watchOrderShowCheck),
    fork(watchOrderPaymentPostV2),
    fork(watchOrderPurchasedFetch),
    fork(watchOrderSoldFetch),
    fork(watchOrdersBuyerPay),
    fork(watchOrderPaymentProofsListFetch),
    fork(watchOrdersPaymentProofsNew),
    fork(watchOrdersPaymentProofsDelete),
    fork(watchOrdersReceiveFund),
    fork(watchOrdersQuotaTransfersNew),
    fork(watchQuotaTransfersListFetch),

    // invoice
    fork(watchInvoiceShowFetchV2),
    fork(watchInvoiceCreatePostV2),
    // wallet
    fork(watchWalletBalanceFetch),
    fork(watchWalletEntriesFetch),
    fork(watchWalletTopupPostV2),
    // withdrawl(wallet)
    fork(watchWithdrawListFetch),
    fork(watchWithdrawShowFetch),
    fork(watchWithdrawCreatePost),
    // quota
    fork(watchQuotaListFetch),
    // permanent
    fork(watchPermanentAcceptBidFetch),
    fork(watchPermanentBidFetch),
    fork(watchPermanentBidListFetch),
    fork(watchPermanentNewFetch),
    fork(watchpermanentListFetch),
    fork(watchPermanentPurchaseFetch),
    fork(watchPermanentShowFetch),
    fork(watchPermanentDeleteFetch),
    fork(watchPermanentMarketsFetch),
    fork(watchPermanentMarketGroupsFetch),
    fork(watchPermanentStatisticFetch),
    fork(watchPermanentEnquireFetch),
    // quota attr
    fork(watchcountriesListFetch),
    fork(watchstatesListFetch),
    fork(watchcategoriesListFetch),
    // user
    fork(watchUserProfileFetch),
    // account
    fork(watchAccountBidsFetch),
    fork(watchAccountOffersFetch),
    fork(watchAccountRecendFetch),
    fork(watchAccountOpenOrdersFetch),
    fork(watchAccountProfileUpdateRequest),
    fork(watchAccountPermanentListFetch),
    fork(watchAccountPermanentShow),
    fork(watchAccountPermanentUpdate),
    fork(watchAccountPermanentDelete),
    fork(watchAccountBankShowFetch),
    fork(watchAccountBankUpdate),
    fork(watchStatementListFetch),
    fork(watchQuotumBalancesFetch),
    fork(watchQuotumInventoriesFetch),
    fork(watchCreatedAuctionsFetch),
    fork(watchInvolvedAuctionsFetch),
    fork(watchAccountProfileRegisterFetch),
    fork(watchNotificationListFetch),
    // popular lists
    fork(watchPopularListFetch),
    // fishery
    fork(watchFisheriesListFetch),
    fork(watchFisheryStickListFetch),
    fork(watchfisheryShowFetch),
    fork(watchfisheryQuotaMarketsFetch),
    fork(watchfisheryActivityFetch),
    fork(watchfisheryExistenceFetch),
    fork(watchfisheryFollowFetch),
    fork(watchfisheryFollowListFetch),
    fork(watchFisheryFollowingQuotaMarketsFetch),
    fork(watchFisheryQuotaListFetch),
    fork(watchFisheryFollowingAuctionsFetch),
    // licence
    fork(watchLicenceUpdatePut),
    // auction for new edition
    fork(watchAuctionListFetch),
    fork(watchAuctionShowFetch),
    fork(watchAuctionBidCreate),
    fork(watchAuctionBidListFetch),
    fork(watchAuctionBidAllListFetch),
    fork(watchAuctionNewRequest),
    fork(watchAuctionFinalBidShowFetch),
    fork(watchAuctionFinalBidCreate),
    fork(watchAuctionInstantBuy),
    fork(watchAuctionFinalChancesListFetch),
    fork(watchAuctionSimilarListFetch),
    //listing
    fork(watchListingOfferNew),
    fork(watchListingQuotaTransfersListFetch),
    fork(watchListingQuotaTransfersNew),
    fork(watchListingOfferEnquiry),

    fork(watchp2pNewRequest),
  ]);
}

export const AUCTION_INSTANT_BUY = "auction/instant_buy";
export const AUCTION_INSTANT_BUY_REQUEST = `${AUCTION_INSTANT_BUY}_request`;
export const AUCTION_INSTANT_BUY_SUCCESS = `${AUCTION_INSTANT_BUY}_success`;
export const AUCTION_INSTANT_BUY_FAILURE = `${AUCTION_INSTANT_BUY}_failure`;
export const AUCTION_INSTANT_BUY_RESET = `${AUCTION_INSTANT_BUY}_reset`;

export const auctionInstantBuyAction = {
  request: (payload) => {
    return {
      type: AUCTION_INSTANT_BUY_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: AUCTION_INSTANT_BUY_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: AUCTION_INSTANT_BUY_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: AUCTION_INSTANT_BUY_RESET
    }
  },
};

export const ORDERS_PAYMENT_PROOFS_DELETE = "orders/payment_proofs_delete";
export const ORDERS_PAYMENT_PROOFS_DELETE_REQUEST = `${ORDERS_PAYMENT_PROOFS_DELETE}_request`;
export const ORDERS_PAYMENT_PROOFS_DELETE_SUCCESS = `${ORDERS_PAYMENT_PROOFS_DELETE}_success`;
export const ORDERS_PAYMENT_PROOFS_DELETE_FAILURE = `${ORDERS_PAYMENT_PROOFS_DELETE}_failure`;
export const ORDERS_PAYMENT_PROOFS_DELETE_RESET = `${ORDERS_PAYMENT_PROOFS_DELETE}_reset`;

export const ordersPaymentProofsDeleteAction = {
  request: (payload) => {
    return {
      type: ORDERS_PAYMENT_PROOFS_DELETE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDERS_PAYMENT_PROOFS_DELETE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDERS_PAYMENT_PROOFS_DELETE_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: ORDERS_PAYMENT_PROOFS_DELETE_RESET
    }
  },
};

import {
  ASKING_PRICES_DELETE_LOADING,
  ASKING_PRICES_DELETE_SUCCESS,
  ASKING_PRICES_DELETE_FAILED,
  ASKING_PRICES_DELETE_RECEIVED
} from "./deleteAction";

export const askingPriceDeleteStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ASKING_PRICES_DELETE_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
        justFinished: false,
      };
      break;
    case ASKING_PRICES_DELETE_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      };
      break;
    case ASKING_PRICES_DELETE_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
        justFinished: true,
      };
      break;

    case ASKING_PRICES_DELETE_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
  justFinished: false,
};
export const ACCOUNT_OPEN_ORDERS = "account/open_orders";
export const ACCOUNT_OPEN_ORDERS_REQUEST = `${ACCOUNT_OPEN_ORDERS}_request`;
export const ACCOUNT_OPEN_ORDERS_SUCCESS = `${ACCOUNT_OPEN_ORDERS}_success`;
export const ACCOUNT_OPEN_ORDERS_FAILURE = `${ACCOUNT_OPEN_ORDERS}_failure`;

export const accountOpenOrdersAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_OPEN_ORDERS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ACCOUNT_OPEN_ORDERS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ACCOUNT_OPEN_ORDERS_FAILURE,
      error,
    }
  },
};

import { LISTING_QUOTA_TRANSFERS_LIST_SUCCESS, LISTING_QUOTA_TRANSFERS_LIST_FAILURE } from "./action";

export const listingQuotaTransfersListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LISTING_QUOTA_TRANSFERS_LIST_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case LISTING_QUOTA_TRANSFERS_LIST_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

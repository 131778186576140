export const TRANSFER_APPLY_CREATE = "quota_transfer_applies/create";
export const TRANSFER_APPLY_CREATE_REQUEST = `${TRANSFER_APPLY_CREATE}_request`;
export const TRANSFER_APPLY_CREATE_SUCCESS = `${TRANSFER_APPLY_CREATE}_success`;
export const TRANSFER_APPLY_CREATE_FAILURE = `${TRANSFER_APPLY_CREATE}_failure`;
export const TRANSFER_APPLY_CREATE_RECEIVED = `${TRANSFER_APPLY_CREATE}_received`;

export const transferApplyCreateAction = {
  request: (payload) => {
    return {
      type: TRANSFER_APPLY_CREATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: TRANSFER_APPLY_CREATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: TRANSFER_APPLY_CREATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: TRANSFER_APPLY_CREATE_RECEIVED
    }
  },
};

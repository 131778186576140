import { InvoiceApiRepo } from "@core";

export class InvoiceApiUsecase {
  constructor() {
    this.repo = new InvoiceApiRepo();
  }

  show(payload) {
    return this.repo.show(payload);
  }

  create(payload) {
    return this.repo.create(payload);
  }
}

import { useState } from "react";

/**
 * [hooks] Modal status
 */
export const useModal = (defaultStatus = false) => {
  const [status, setStatus] = useState(defaultStatus);

  const toggleModal = () => {
    setStatus(!status);
  };

  return {
    isOpen: status,
    toggleModal,
  };
};

import { createSelector } from "reselect";
import get from "lodash/get";
import map from "lodash/map";
import findIndex from "lodash/findIndex";
import {
  AUCTION_LIST_SUCCESS,
  AUCTION_LIST_FAILURE,
  AUCTION_LIST_UPDATE,
} from "./action";

export const auctionListStatus = (state = initStatus, action = {}) => {
  const updateSelector = createSelector(
    (state) => state.data,
    (_, payload) => payload,
    (data, payload) => {
      return map(data, (obj) => {
        const index = findIndex(payload, ({ id }) => get(obj, "id") === id);
        if (index > -1) {
          return payload[index];
        }
        return obj;
      });
    }
  );

  switch (action.type) {
    case AUCTION_LIST_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case AUCTION_LIST_UPDATE:
      return {
        data: updateSelector(state, action.data),
        error: null,
      };

    case AUCTION_LIST_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null,
};


export const ASKING_PRICES_FETCH_QUOTA_BALANCE_REQUEST = "asking_prices/fetch_quota_balance_request";
export const ASKING_PRICES_FETCH_QUOTA_BALANCE_LOADING = "asking_prices/fetch_quota_balance_loading";
export const ASKING_PRICES_FETCH_QUOTA_BALANCE_SUCCESS = "asking_prices/fetch_quota_balance_success";
export const ASKING_PRICES_FETCH_QUOTA_BALANCE_FAILED = "asking_prices/fetch_quota_balance_failed";
export const ASKING_PRICES_REFRESH_QUOTA_BALANCE = "asking_prices/refresh_quota_balance";

export function fetchAskingPriceQuotaBalance(payload, params = {}) {
  return {
    type: ASKING_PRICES_FETCH_QUOTA_BALANCE_REQUEST,
    payload,
    params,
  };
}

export function refreshAskingPriceQuotaBalance(data) {
  return {
    type: ASKING_PRICES_REFRESH_QUOTA_BALANCE,
    data,
  };
}

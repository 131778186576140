import { takeLatest, call, put } from "redux-saga/effects";

import { PermanentApiUsecase } from "@core";
import { PERMANMENT_SHOW_REQUEST, permanentShowAction } from "./action";

export function* watchPermanentShowFetch() {
  yield takeLatest(PERMANMENT_SHOW_REQUEST, permanentShowFetch);
}

function* permanentShowFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(permanentShowAction.success(res.data));
  } catch (err) {
    yield put(permanentShowAction.failure(err));
  }
}

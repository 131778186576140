import React from "react";

/**
 * import local tools & config & hooks
 */
import {
  useCurrentUser,
  useFisheryList,
  useFisheryStickList,
  useAuctionList,
  useMarketList,
  useParams,
  useAccountCreatedAuctions,
  useAccountQuotumBalances,
  useOrderSold,
  useAccountInvolvedAuctions,
  useAccountBids,
  useOrderPurchased,
  useAuctionFinalChancesList,
} from "@hooks";

/**
 * import local components
 */
import Layout from "@views/layout";
import FeaturedListings from "./_featured_listings";
import MySales from "./_my_sales";
import MyPurchases from "./_my_purchases";
import BrowseFisheries from "./_browse_fisheries";
import Notice from "./_notice";
import Header from "./_header";
import Filter from "./_filter";
import Introduce from "./_introduce";

/**
 * [Page] Account dashboard 个人中心首页页面
 */
const Dashboard = (props) => {
  const currentUser = useCurrentUser();
  const [{ redirect_to, ...params }] = useParams("all");

  const {
    activeList,
    fetchList: fetchAuctionList,
    loading,
  } = useAuctionList({ expired: false });
  const {
    data: marketList,
    fetchMarketList,
    loading: marketLoading,
  } = useMarketList({
    only_stick: true,
  });
  const { data: fisheryList } = useFisheryList(params);
  const {
    data: fisheryStickList,
    loading: stickListLoading,
  } = useFisheryStickList();
  const {data: finalChancesList} = useAuctionFinalChancesList();
  const { data: auctionList } = useAccountCreatedAuctions();
  const { data: instantList } = useAccountQuotumBalances();
  const { data: pageSoldData } = useOrderSold();
  const { data: auctionBidsList } = useAccountInvolvedAuctions();
  const { data: exchangeBidsList } = useAccountBids();
  const { data: pagePurchasedData } = useOrderPurchased();

  return (
    <Layout name="account">
      <div className="bg-gray-50 min-h-screen">
        <Introduce />
        <div className="max-w-5xl w-full mx-auto">
          <Header currentUser={currentUser} />
          <div className="mt-6 lg:mt-0 pb-6 mb-8 mx-4 md:mx-0  grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-4 lg:gap-y-10 border-b border-gray-200">
            <div className="">
              <Filter
                fetchAuctionList={fetchAuctionList}
                fetchMarketList={fetchMarketList}
              />
            </div>
            <div className="lg:col-span-3">
              <Notice />
              <div className="mb-4 md:mb-6">
                <FeaturedListings
                  loading={loading}
                  auctionList={[...finalChancesList, ...activeList]}
                  marketLoading={marketLoading}
                  marketList={marketList}
                  fisheryList={fisheryList}
                />
              </div>
            </div>
          </div>
          <div className="px-4 md:px-0">
            <div className="mb-12">
              <h2 className="text-lg md:text-xl font-semibold md:font-medium text-blue-gray-700 mb-6">
                My Sales
              </h2>
              <MySales
                auctionList={auctionList}
                instantList={instantList}
                pageSoldData={pageSoldData}
              />
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-semibold md:font-medium text-blue-gray-700 mb-6">
                My Purchases
              </h2>
              <MyPurchases
                auctionBidsList={auctionBidsList}
                exchangeBidsList={exchangeBidsList}
                pagePurchasedData={pagePurchasedData}
              />
            </div>
            <div className="mb-8 md:mb-24">
              <h2 className="text-lg md:text-xl font-semibold md:font-medium text-blue-gray-700 mb-6">
                Browse Fisheries
              </h2>
              <BrowseFisheries
                listLoading={stickListLoading}
                fisheryList={fisheryStickList}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;

import get from "lodash/get";
import map from "lodash/map";
import lowerCase from "lodash/lowerCase";
import includes from "lodash/includes";
import split from "lodash/split";
import last from "lodash/last";
import { Moment, UrlUtils } from "@core/utils";
import { locationAbbre } from "@config/enum";
import numeral from "@utils/numeral";
import { QuotaAttrBaseEntity, PriceBaseEntity } from "./baseEntity";
import { SeasonEntity } from "./seasonEntity";

/**
 * [ ENTITY ] Fishery entity
 * @data
 * {
 *  “id”: 415929031,
 *	“description”: “Fishery 1",
 *	“licenses_number”: 10,
 *	“quota_units_number”: 10,
 *	“season_start”: “2020-05-12",
 *	“season_end”: “2020-05-13",
 *	“species”: {
 *		“id”: 906617150,
 *		“name”: “greenlip abalone”
 *	},
 *	“state”: {
 *		“id”: 818524958,
 *		“name”: “New South Wales”
 *	}
 * }
 */
export class FisheryEntity {
  
  static slugToId(slug = "") {
    return last(split(slug, "-"));
  }

  constructor(props) {
    this.data = props;
    this.getAttr = QuotaAttrBaseEntity.getAttr(this.data);
  }

  get id() {
    return get(this.data, "id", "");
  }

  get description() {
    return get(this.data, "description", "");
  }

  get state() {
    return this.getAttr("state", "name");
  }

  get stateAbbr() {
    return locationAbbre(this.state);
  }

  get species() {
    return this.getAttr("species", "name");
  }

  get category() {
    return this.getAttr("category", "name");
  }

  get title() {
    return `${this.state} ${this.category}`;
  }

  get logoImage() {
    return get(this.data, "logo_image", "");
  }

  get coverImage() {
    return get(this.data, "cover_image", "");
  }

  get image() {
    return `categories/${lowerCase(this.category)}`.replace(/\s/g, "_");
  }

  get seasonStart() {
    const date = get(this.data, "season_start", "");
    return date ? Moment.getFormatedDate(date) : "";
  }

  get seasonEnd() {
    const date = get(this.data, "season_end", "");
    return date ? Moment.getFormatedDate(date) : "";
  }

  get season() {
    if (!this.seasonStart || !this.seasonEnd) {
      return "";
    }
    return `${this.seasonStart} to ${this.seasonEnd}`;
  }

  get licensesNumber() {
    return get(this.data, "licenses_number", "");
  }

  get quotaUnitsNumber() {
    const number = get(this.data, "quota_units_number", "");
    if (!number) {
      return 0;
    }
    return numeral(number).format("0,0");
  }

  get following() {
    return get(this.data, "following", false);
  }

  get url() {
    return this.getUrl();
  }

  get fisheryType() {
    return get(this.data, "fishery_type", false);
  }

  get saleTypes() {
    const types = get(this.data, "sale_types", []) || [];
    if (includes(types, "exchange") && includes(types, "auction")) {
      return "both";
    }
    if (includes(types, "exchange")) {
      return "exchange";
    }
    if (includes(types, "auction")) {
      return "auction";
    }
    return "";
  }

  getUrl(params) {
    const stateName = this.state.trim().split(" ").join("-");
    const categoryName = this.category.trim().split(" ").join("-");
    const name = `${stateName.toLowerCase()}-${categoryName.toLowerCase()}`;
    const url = `/fisheries/${name}-${this.id}`;
    const queryStr = UrlUtils.paramsToQuery(params);
    if (!queryStr) {
      return url;
    }
    return `${url}?${queryStr}`;
  }
}

/**
 * [ ENTITY ] Fishery entity
 * @data
 * {
 *  quota_market_activities: [
 *    {
 *      species: { id: 1, name: "Abalone Two" },
 *      state: { id: 2, name: "Tasmania" },
 *      last_traded_price: { price: "0.00", price_with_symbol: "$0.00" },
 *    },
 *    {
 *      species: { id: 1, name: "Abalone Two" },
 *      state: { id: 2, name: "Tasmania" },
 *      last_traded_price: { price: "0.00", price_with_symbol: "$0.00" },
 *    },
 *  ],
 * };
 */

export class FisheryActivitiesEntity {
  constructor(props) {
    this.data = props;
  }

  get quotaMarket() {
    const activities = get(this.data, "quota_market_activities", []);
    return map(activities, (item) => new FisheryActivityEntity(item));
  }

  get auctions() {
    return get(this.data, "auction_activities", []);
  }
}

export class FisheryActivityEntity {
  constructor(props) {
    this.data = props;
    this.getAttr = QuotaAttrBaseEntity.getAttr(this.data);
  }

  get id() {
    return get(this.data, "quota_market_id", "");
  }

  get param() {
    return { leaseMarketId: this.id };
  }

  get zone() {
    return this.getAttr("zone", "name");
  }

  get state() {
    return this.getAttr("state", "name");
  }

  get species() {
    return this.getAttr("species", "name");
  }

  get category() {
    return this.getAttr("category", "name");
  }

  get name() {
    if (get(this.data, "title", "")) {
      return get(this.data, "title", "");
    }
    const result = [];
    result.push(this.getAttr("state", "abbr"));
    this.zone && result.push(`${this.zone} Zone`);
    result.push(this.species);
    result.push("Lease");
    return result.join(" ");
  }

  get unitOfSale() {
    return get(this.data, "unit_of_sale", ""); // kg | blocks(即unit)
  }

  get price() {
    const usecase = new PriceBaseEntity(
      get(this.data, "lowest_seller_offer_price", {})
    );
    return usecase.price("value")
      ? usecase.price("price_with_symbol")
      : "Lease";
  }

  get season() {
    return new SeasonEntity(get(this.data, "fishing_season") || {});
  }
}

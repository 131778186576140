import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  orderPaymentProofsListAction,
  ORDER_PAYMENT_PROOFS_LIST,
} from "@redux/modules_v2/orders/actions/paymentProofs/list/action";


export const usePaymentsProofsList = (id) => {
  const dispatch = useDispatch();

  const fetchList = useCallback(() => {
    dispatch(orderPaymentProofsListAction.request({ id }));
  }, [id]);

  const orderPaymentProofsListStatus = useSelector((state) => {
    const selector = new LoadingSelector(ORDER_PAYMENT_PROOFS_LIST, state);
    return Object.assign({}, get(state, "orderPaymentProofsListStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = orderPaymentProofsListStatus.loading;
  const data = orderPaymentProofsListStatus.data;

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    if (id) {
      fetchList(id);
    }
  }, [id]);

  return { data, loading, fetchList };
};

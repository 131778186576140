import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";

import errorString from "@utils/errorString";
import { OrderApiUsecase } from "@core";
import { paymentMethodMap } from "@config/enum";
import { ORDER_PAYMENT_REQUEST, orderPaymentAction } from "./action";
import { updateOrderShow } from "../show/saga";

// order payment
export function* watchOrderPaymentPostV2() {
  yield takeLatest(ORDER_PAYMENT_REQUEST, orderPaymentPost);
}

function* orderPaymentPost(action) {
  try {
    const { isFinished, paymentConfirmUrl } = yield call(
      updateOrderShow,
      action
    );
    if (isFinished) {
      yield put(
        orderPaymentAction.failure(
          "The order has finished, please do not repeat the payment"
        )
      );
      return;
    }
    if (
      paymentConfirmUrl &&
      get(action, "payload.paymentMethod", "") === paymentMethodMap.stripe
    ) {
      action.payload.history && action.payload.history.push(paymentConfirmUrl);
      yield put(orderPaymentAction.success("success"));
      return;
    }
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "payment"], action.payload);
    yield put(orderPaymentAction.success(res.data));
  } catch (err) {
    yield put(orderPaymentAction.failure(errorString(err)));
  }
}

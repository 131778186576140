import { takeLatest, call, put } from "redux-saga/effects";

import { WithdrawApiUsecase } from "@core";
import { WITHDRAW_CREATE_REQUEST, withdrawCreateAction } from "./action";

// create withdraw
export function* watchWithdrawCreatePost() {
  yield takeLatest(WITHDRAW_CREATE_REQUEST, withdrawCreatePost);
}

function* withdrawCreatePost(action) {
  try {
    const usecase = new WithdrawApiUsecase();
    const res = yield call([usecase, "create"], action.payload);
    yield put(withdrawCreateAction.success(res.data));
  } catch (err) {
    yield put(withdrawCreateAction.failure(err));
  }
}

import React from "react";
import PropTypes from "prop-types";

import flatten from "lodash/flatten";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import toNumber from "lodash/toNumber";
import findIndex from "lodash/findIndex";

import { useDeepEffect } from "@hooks";
import { Action, Button } from "@components";

const Actions = ({ data, params, toggleModal }) => {
  const noProofPrices = flatten(
    map(data, (item) =>
      filter(
        get(item, "bid_offer_prices", []),
        (entity) =>
          !get(entity, "entity.quotum_transfer_apply.status", "") ||
          get(entity, "entity.quotum_transfer_apply.status", "") === "pending"
      )
    )
  );
  const renderAction = (item, index) => {
    const amountStr = get(item, "amountStr", "");
    const apply = get(item, "entity.quotum_transfer_apply", {}) || {};
    const actionProps = {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          className="w-5 h-5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 13V15H9V13H11ZM11 11V5H9V11H11Z"
            fill="#0694A2"
          />
        </svg>
      ),
      title: "Waiting for quota consignment",
      subTitle: `You need to transfer quota for listing ID #${get(
        item,
        "id",
        ""
      )} within 60 minutes.`,
      action: (
        <div className="flex space-x-4">
          <Button
            color="teal-500"
            textColor="white"
            onClick={(evt) => {
              evt.stopPropagation();
              const data = {
                offerId: item.id,
                quotum_transfer_apply: apply,
                amountStr: amountStr,
              };
              toggleModal && toggleModal(data, "apply");
            }}
          >
            Consign quota now
          </Button>
        </div>
      ),
      theme: "teal",
    };
    return (
      <div className="mb-4 lg:mb-6 space-y-4 lg:space-y-6" key={index}>
        <Action key={index} {...actionProps} />
      </div>
    );
  };

  useDeepEffect(()=>{
    const toggle =get(params, "toggle", "");
    const type =get(params, "type", "");
    const offerId = toNumber(get(params, "offer_id", ""));
    const index = findIndex(noProofPrices, item => item.id === offerId);
    if(toggle === "open" && type === "apply" && index > -1) {
      const data = {
        offerId: offerId,
        quotum_transfer_apply: get(noProofPrices[index], "entity.quotum_transfer_apply", {}) || {},
      };
      toggleModal && toggleModal(data, "apply");
    }
  }, [params, noProofPrices]);

  return map(noProofPrices, renderAction);
};

Actions.propTypes = {
  data: PropTypes.array,
  params: PropTypes.object,
  toggleModal: PropTypes.func,
};

Actions.defaultProps = {
  data: [],
  params: {},
  toggleModal: () => {},
};

export default Actions;

export const NOTIFICATION_LIST = "notification_list";
export const NOTIFICATION_LIST_REQUEST = `${NOTIFICATION_LIST}_request`;
export const NOTIFICATION_LIST_SUCCESS = `${NOTIFICATION_LIST}_success`;
export const NOTIFICATION_LIST_FAILURE = `${NOTIFICATION_LIST}_failure`;

export const notificationListAction = {
  request: (payload) => {
    return {
      type: NOTIFICATION_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: NOTIFICATION_LIST_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: NOTIFICATION_LIST_FAILURE,
      error,
    };
  },
};
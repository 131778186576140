import { useEffect, useState, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

/**
 * import local tools & redux & usecase
 */
import {
  resendCodeRequest,
  resendCodeReset,
} from "@redux/modules/resendCode/action";

import Notice from "@utils/noticeNew";

/**
 * Reset code hooks
 * @param {*} param
 */
export const useResendCode = () => {
  const dispatch = useDispatch();

  /**
   * 发送按钮默认状态
   */
  const [disabled, setDisabled] = useState(false);

  /**
   * 提交后状态
   */
  const resendCodeStatus = useSelector((state) =>
    get(state, "resendCodeStatus", {})
  );

  /**
   * [Callback] 发起请求
   */
  const onResendCode = useCallback((email) => {
    email && dispatch(resendCodeRequest({ email }));
  }, []);

  /**
   * [Callback] reset状态
   */
  const resetStatus = useCallback(() => {
    dispatch(resendCodeReset());
  }, []);
  /**
   * 设置一定时间后可以再次发送
   */
  const allowSendCode = useCallback(() => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 60 * 1000);
  }, []);

  /**
   * 提交后的effect
   */
  useEffect(() => {
    return () => {
      resetStatus();
    };
  }, []);

  useEffect(() => {
    if (!resendCodeStatus.loading) {
      if (!isEmpty(resendCodeStatus.data)) {
        Notice.success("Verification code sent successfully");
        allowSendCode();
      }
      if (resendCodeStatus.error) {
        Notice.failure(
          get(resendCodeStatus, "error.message", "Resend Code Failed")
        );
      }
    }
  }, [resendCodeStatus.loading]);

  return {
    disabled: disabled || resendCodeStatus.loading,
    onResendCode,
    resetStatus,
  };
};

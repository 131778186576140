import { ORDER_PAYMENT_SUCCESS, ORDER_PAYMENT_FAILURE, ORDER_PAYMENT_RECEIVED } from "./action";

export const orderPaymentStatusV2 = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ORDER_PAYMENT_SUCCESS:
      return {
        data: action.data,
        error: null,
        justFinished: true,
      };

    case ORDER_PAYMENT_FAILURE:
      return {
        data: null,
        error: action.error,
        justFinished: true,
      };

    case ORDER_PAYMENT_RECEIVED:
      return {
        data: state.data,
        error: state.error,
        justFinished: false,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
  justFinished: false,
};

import React, { useState, useEffect } from "react";

/**
 * import lib tools
 */
import get from "lodash/get";
import { links } from "@config/staticLink";

/**
 * import local tools & components
 */

import { Image, ModalButton, Modal, useModal, Button, SEO, Link, Mobile, DesktopAndTablet } from "@components";
import RegisterForm from "./form";

//Hooks
import { useRegister, useResendCode, useGlobalStatic } from "@hooks";

const Register = () => {
  const [currentEmail, setEmail] = useState("");
  const { data: staticData } = useGlobalStatic(true);
  const { disabled, onResendCode, resetStatus } = useResendCode();
  const [modalProps, toggleModal] = useModal({
    icon: (
      <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <svg
          fill="none"
          className="h-6 w-6 text-red-600"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
        </svg>
      </div>
    ),
    title: get(staticData, "register_code.title", "Verify your email"),
    content: (
      <div>
        {`${get(staticData, "register_code.content_before", "")} `}
        <strong>{currentEmail}</strong>.
        {` ${get(staticData, "register_code.content_after", "")}`}
      </div>
    ),
    buttons: [
      <ModalButton
        label="Re-send verification code"
        key={`modal-button-2`}
        isStyleDestructive
        disabled={disabled}
        onClick={() => onResendCode(currentEmail)}
      />,
      <ModalButton
        label="Cancel"
        isStyleCancel
        key={`modal-button-1`}
        onClick={() => {
          toggleModal();
          resetStatus();
        }}
      />,
    ],
    buttonAlign: "right",
    isVertical: true,
  });
  const { email, ...registerProps } = useRegister({ toggleModal });

  useEffect(() => {
    email && setEmail(email);
  }, [email]);

  return (
    <>
    <SEO title="Sign up - BeachPrice" />
    <div className="min-h-screen bg-gray-50 relative">
      <div className="hidden md:block absolute top-5 left-8">
        <Link to="/dashboard">
          <Image source="logo" className="h-8 w-32" />
        </Link>
      </div>
      <div className="min-h-screen md:grid grid-cols-1 md:grid-cols-2 px-4 md:px-0 pb-12 md:pb-0">
        <div className="w-full h-full md:bg-white md:flex flex-col justify-center">
          <div className="max-w-sm mx-auto py-12 md:py-0 md:transform md:translate-x-1/5">
            <Mobile>
              <div className="flex justify-center">
                <Link to={links.home}>
                  <Image source="logo" className="h-8 w-32" />
                </Link>
              </div>
            </Mobile>
            <DesktopAndTablet>
              <h2 className="leading-tight text-center md:text-left">
                Join BeachPrice <br />
                for free
              </h2>
              <div className="mt-6 md:mt-8 px-6 md:px-0 text-sm md:text-base text-blue-gray-600 text-center md:text-left">
                Join over 1000 fishers, wholesalers, and license owners who use BeachPrice to trade fishing quota.
              </div>
              <ul className="mt-8">
                <li className="flex items-center mt-4">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66668 10.1147L12.7947 3.98599L13.738 4.92866L6.66668 12L2.42401 7.75733L3.36668 6.81466L6.66668 10.1147Z" fill="#5D5AF3"/>
                  </svg>
                  <div className="ml-4 text-blue-gray-600">Buy and sell in minutes</div>
                </li>
                <li className="flex items-center mt-4">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66668 10.1147L12.7947 3.98599L13.738 4.92866L6.66668 12L2.42401 7.75733L3.36668 6.81466L6.66668 10.1147Z" fill="#5D5AF3"/>
                  </svg>
                  <div className="ml-4 text-blue-gray-600">Get the market price every time</div>
                </li>
                <li className="flex items-center mt-4">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66668 10.1147L12.7947 3.98599L13.738 4.92866L6.66668 12L2.42401 7.75733L3.36668 6.81466L6.66668 10.1147Z" fill="#5D5AF3"/>
                  </svg>
                  <div className="ml-4 text-blue-gray-600">Secured with 100% buyer guarantee</div>
                </li>
                <li className="flex items-center mt-4">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66668 10.1147L12.7947 3.98599L13.738 4.92866L6.66668 12L2.42401 7.75733L3.36668 6.81466L6.66668 10.1147Z" fill="#5D5AF3"/>
                  </svg>
                  <div className="ml-4 text-blue-gray-600">Free to sign up and start trading</div>
                </li>
              </ul>
            </DesktopAndTablet>
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-center">
          <div className="max-w-sm w-full mx-auto">
            <div className="hidden md:flex">
              <Link
                to={links.home}
                className="flex items-center mb-6"
              >
                <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.21872 8.00001L10.5187 11.3L9.57606 12.2427L5.33339 8.00001L9.57606 3.75734L10.5187 4.70001L7.21872 8.00001Z" fill="#677489"/>
                </svg>
                <span className="text-sm text-blue-gray-500">Back to homepage</span>
              </Link>
            </div>
            <div className="rounded-lg bg-gray-200 grid grid-cols-2" style={{padding:6}}>
              <Link
                to="/register"
                className="bg-white rounded-lg shadow-t-xs py-3 text-center text-blue-gray-800 font-medium text-sm"
              >
                Create account
              </Link>
              <Link
                to={links.sign_in}
                className="py-3 text-center text-blue-gray-600 font-medium text-sm"
              >
                Log in
              </Link>
            </div>
            <div className="mt-6 bg-white rounded-lg shadow-t-card">
              <div className="p-6 md:p-10 md:pb-12">
                <h4 className="text-base">Sign up for free</h4>
                <div className="mt-6">
                  <RegisterForm {...registerProps} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal {...modalProps} />
    </div>
    </>
  );
};

export default Register;

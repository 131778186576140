import merge from "lodash/merge";
import isInteger from "lodash/isInteger";

import {
  CATEGORY_SHOW_TRANSACTION_FORM_STATUS_TOGGLE,

  CATEGORY_SHOW_MODAL_ORDER_STATUS_TOGGLE,
  CATEGORY_SHOW_MODAL_ORDER_PAY_TOGGLE,
  CATEGORY_SHOW_MODAL_ORDER_RESULT_TOGGLE,
  CATEGORY_SHOW_MODAL_ORDER_SET_PAYMETHOD,
  CATEGORY_SHOW_ORDER_SET_DATA,

  CATEGORY_SHOW_MODAL_OFFER_STATUS_TOGGLE,
  CATEGORY_SHOW_MODAL_OFFER_CONFIRM_TOGGLE,
  CATEGORY_SHOW_MODAL_OFFER_RESULT_TOGGLE,
  CATEGORY_SHOW_OFFER_SET_DATA,

  CATEGORY_SHOW_MODAL_EMAIL_STATUS_TOGGLE,
} from "./categoryShowAction";

export default function categoryShowState(state = initStatus, action = {}) {

  switch (action.type) {

    // About Order
    case CATEGORY_SHOW_TRANSACTION_FORM_STATUS_TOGGLE:
      return Object.assign({}, state, {
        transactionFormShow: action.payload.isOpen
      });
      break;

    case CATEGORY_SHOW_MODAL_ORDER_STATUS_TOGGLE:
      return merge({}, state, {
        modalOrder: {
          isOpen: action.payload.isOpen
        }
      });

    case CATEGORY_SHOW_MODAL_ORDER_PAY_TOGGLE:
      return merge({}, state, {
        modalOrder: {
          showPay: action.payload.showPay
        }
      });

    case CATEGORY_SHOW_MODAL_ORDER_SET_PAYMETHOD:
      return merge({}, state, {
        modalOrder: {
          payMethod: action.payload.payMethod
        }
      });

    case CATEGORY_SHOW_MODAL_ORDER_RESULT_TOGGLE:
      return merge({}, state, {
        modalOrder: {
          showResult: action.payload.showResult
        }
      });

    case CATEGORY_SHOW_ORDER_SET_DATA:
      return Object.assign({}, state, {
        transactionFormData: action.payload,
      });

    // About Order end

    // About Offer
    case CATEGORY_SHOW_MODAL_OFFER_STATUS_TOGGLE:
      return merge({}, state, {
        modalOffer: {
          isOpen: action.payload.isOpen
        }
      });

    case CATEGORY_SHOW_MODAL_OFFER_CONFIRM_TOGGLE:
      return merge({}, state, {
        modalOffer: {
          showConfirm: action.payload.showConfirm,
        }
      });

    case CATEGORY_SHOW_MODAL_OFFER_RESULT_TOGGLE:
      return merge({}, state, {
        modalOffer: {
          showResult: action.payload.showResult,
        }
      });

    case CATEGORY_SHOW_OFFER_SET_DATA:
      return merge({}, state, {
        offerFormData: action.payload
      });
    // About Offer end

    case CATEGORY_SHOW_MODAL_EMAIL_STATUS_TOGGLE:
      return merge({}, state, {
        modalEmail: {
          isOpen: action.payload.isOpen
        }
      });

    default:
      return state;
  }
}

const initStatus = {
  // 用于交易（only手机）
  transactionFormShow: false,
  // 用于交易的数据
  transactionFormData: {
    quantityError: "",
    quantity: "",
    orderItems: [],
    totalPrice: 0,
    totalQuantity: 0,
    totalMoney: 0,
    gst: 0,
    averagePrice: 0,
  },
  // 用于发布offer的数据
  offerFormData: {
    quantity: "",
    price: "",
  },
  // 用于交易的弹框
  modalOrder: {
    isOpen: false,
    showPay: false,
    showResult: false,
    payMethod: "", // ""|offline
  },
  // 用于发布offer的弹框
  modalOffer: {
    isOpen: false,
    showConfirm: false,
    showResult: false,
  },
  // 用于联系管理员的弹框
  modalEmail: {
    isOpen: false,
  },
};

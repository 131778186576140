import {
    BIDS_NEW_REQUEST,
    BIDS_NEW_SUCCESS,
    BIDS_NEW_FAILED,
  
  } from "./newAction";
  
  export const bidsNewStatus = (state = initStatus, action = {}) => {
    switch (action.type) {
      case BIDS_NEW_REQUEST:
        return {
          loading: true,
          data: null,
          error: null,
        };
  
      case BIDS_NEW_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null,
        };
  
      case BIDS_NEW_FAILED:
        return {
          loading: false,
          data: null,
          error: action.error,
        };
  
      default:
        return state;
    }
  };
  
  const initStatus = {
    loading: false,
    data: null,
    error: null,
  };
export const OFFER_PRICES_UPDATE_PATCH = "offer_prices/update_patch";
export const OFFER_PRICES_UPDATE_LOADING = "offer_prices/update_loading";
export const OFFER_PRICES_UPDATE_SUCCESS = "offer_prices/update_success";
export const OFFER_PRICES_UPDATE_FAILED = "offer_prices/update_failed";
export const OFFER_PRICES_UPDATE_RECEIVED = "offer_prices/update_received";

export function offerPriceUpdatePatch(id, payload = {}) {
  return {
    type: OFFER_PRICES_UPDATE_PATCH,
    id,
    payload
  };
}
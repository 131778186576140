import React, { useState, useEffect } from "react";
import { TrixEditor } from "react-trix";
import { useFormikContext, getIn } from "formik";
import PropTypes from "prop-types";
import cx from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";

import WithLabel from "./with_label";

export const Editor = ({ onChange, name, placeholder, className }) => {
  const [trixEditor, setTrixEditor] = useState({});
  const [currentAttributes, setCurrentAttributes] = useState({});
  const { values, setFieldValue } = useFormikContext();

  const onChangeEditor = (html, content) => {
    if (isEmpty(trim(content))) {
      setFieldValue(name, "");
      return;
    }
    const values = '<div class="rich-editor-content">' + html + "</div>";
    setFieldValue(name, values);
    onChange && onChange(values);
  };

  const onToggle = (name) => {
    onToggleAttribute(name);
    updateCurrentAttributes();
    focusEditor();
  };

  const onToggleAttribute = (name) => {
    if (attributeIsActive(name)) {
      trixEditor && trixEditor.deactivateAttribute(name);
      return;
    }
    trixEditor && trixEditor.activateAttribute(name);
  };

  const updateCurrentAttributes = () => {
    setCurrentAttributes(get(trixEditor, "composition.currentAttributes", {}));
  };

  const attributeIsActive = (name) => {
    return trixEditor && trixEditor.attributeIsActive(name);
  };

  const focusEditor = () => {
    const trixEl = get(trixEditor, "element", "");
    setTimeout(() => {
      trixEl && trixEl.focus();
    }, 100);
  };

  useEffect(() => {
    updateCurrentAttributes();
    focusEditor();
  }, []);

  return (
    <div className="RichEditor-root editor-wrapper">
      <div id="trix-editor" className="flex">
        <InlineStyleControls
          currentAttributes={currentAttributes}
          onToggle={onToggle}
        />
        <div className="RichEditor-styleButton-split" />
        <BlockStyleControls
          currentAttributes={currentAttributes}
          onToggle={onToggle}
        />
      </div>
      <TrixEditor
        onChange={(html, content) => {
          onChangeEditor && onChangeEditor(html, content);
          updateCurrentAttributes();
        }}
        onEditorReady={(editor) => {
          setTrixEditor(editor);
        }}
        toolbar="trix-editor"
        className={cx("RichEditor-editor outline-none py-3", className)}
        value={getIn(values, name)}
        placeholder={placeholder}
      />
    </div>
  );
};

Editor.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

Editor.defaultProps = {
  onChange: () => {},
  name: "",
  placeholder: "",
  className: "",
};

const StyleButton = ({ style, active, onToggle, name, label }) => {
  let className = cx("RichEditor-styleButton", style, {
    "RichEditor-activeButton": active,
  });

  return (
    <span
      className={className}
      onMouseDown={() => {
        onToggle(name);
      }}
    >
      {label}
    </span>
  );
};

StyleButton.propTypes = {
  style: PropTypes.string,
  active: PropTypes.bool,
  onToggle: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
};

StyleButton.defaultProps = {
  style: "",
  active: false,
  onToggle: () => {},
  name: "",
  label: "",
};

const BLOCK_TYPES = [
  { label: "H1", name: "heading1", style: "header-one" },
  { label: "H2", name: "heading2", style: "header-two" },
  { label: "H3", name: "heading3", style: "header-three" },
  { label: "H4", name: "heading4", style: "header-four" },
  { label: "H5", name: "heading5", style: "header-five" },
  { label: "H6", name: "heading6", style: "header-six" },
  { label: "UL", name: "bullet", style: "unordered-list-item" },
  { label: "OL", name: "number", style: "ordered-list-item" },
];
const BlockStyleControls = ({ currentAttributes, onToggle }) => {
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentAttributes[type.name]}
          onToggle={onToggle}
          {...type}
        />
      ))}
    </div>
  );
};
BlockStyleControls.propTypes = {
  currentAttributes: PropTypes.object,
  onToggle: PropTypes.func,
};

BlockStyleControls.defaultProps = {
  currentAttributes: {},
  onToggle: () => {},
};

var INLINE_STYLES = [
  { label: "B", name: "bold", style: "BOLD" },
  { label: "I", name: "italic", style: "ITALIC" },
  { label: "U", name: "underline", style: "UNDERLINE" },
];

const InlineStyleControls = ({ currentAttributes, onToggle }) => {
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentAttributes[type.name]}
          onToggle={onToggle}
          {...type}
        />
      ))}
    </div>
  );
};

InlineStyleControls.propTypes = {
  currentAttributes: PropTypes.object,
  onToggle: PropTypes.func,
};

InlineStyleControls.defaultProps = {
  currentAttributes: {},
  onToggle: () => {},
};

export default WithLabel("trix-editor")(Editor);

import { useState, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import numeral from "@utils/numeral";
import Notice from "@utils/noticeNew";
import {
  offerDeleteAction,
  OFFER_DELETE,
} from "@redux/modules_v2/offers/delete/action";
import {
  bidDeleteAction,
  BID_DELETE,
} from "@redux/modules_v2/bids/delete/action";

/**
 * [hooks] place bid/ask delete
 *
 * @param {Function} success 成功后的回调函数
 *
 * @example
 * 用法1:
 * const { deleteOffer, deleteBid } = useBidOfferDelete(() => { some success code })
 * onClick={() => {
 *   deleteOffer(1, 2) 或者
 *   deleteBid(1, 2)
 * }}
 *
 * 用法2:
 * const { deleteByData } = useBidOfferDelete(() => { some success code })
 * onClick={() => {
 *   const data = { bidOfferType: "bid|offer", marketId: 1, id: 2 }
 *   deleteByData(data)
 * }}
 */
export const useBidOfferDelete = (success) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * 删除 seller 报价(ask)
   */
  const deleteOffer = (marketId, id) => {
    const deleteData = {
      marketId,
      offerId: id,
    };
    dispatch(offerDeleteAction.request(deleteData));
  };

  /**
   * 删除 buy 报价(bid)
   */
  const deleteBid = (marketId, id) => {
    const deleteData = {
      marketId,
      bidId: id,
    };
    dispatch(bidDeleteAction.request(deleteData));
  };

  /**
   * 根据某数据，删除报价，需判断
   */
  const deleteByData = (data) => {
    if (data.bidOfferType && data.marketId && data.id) {
      switch (data.bidOfferType) {
        case "bid":
          deleteBid(data.marketId, data.id);
          return;
          break;

        case "offer":
          deleteOffer(data.marketId, data.id);
          return;
          break;

        default:
          break;
      }
    }
  };

  /**
   * 监听报价删除
   * offerDeleteStatus 卖家报价删除
   * bidDeleteStatus   买家报价删除
   */
  const offerDeleteStatus = useSelector((state) => {
    const selector = new LoadingSelector(OFFER_DELETE, state);
    return Object.assign({}, get(state, "offerDeleteStatus", {}), {
      loading: selector.loading,
    });
  });
  const bidDeleteStatus = useSelector((state) => {
    const selector = new LoadingSelector(BID_DELETE, state);
    return Object.assign({}, get(state, "bidDeleteStatus", {}), {
      loading: selector.loading,
    });
  });

  const loadingDeleteOffer = offerDeleteStatus.loading;
  const loadingDeleteBid = bidDeleteStatus.loading;

  useEffect(() => {
    if (offerDeleteStatus.justFinished) {
      if (!offerDeleteStatus.loading && !offerDeleteStatus.error) {
        Notice.success("You have successfully delete an ask!");
        success && success();
      }
      if (!offerDeleteStatus.loading && offerDeleteStatus.error) {
        Notice.failure(offerDeleteStatus.error || "Delete Failed");
      }
      dispatch(offerDeleteAction.received());
    }
  }, [offerDeleteStatus.justFinished]);

  useEffect(() => {
    if (bidDeleteStatus.justFinished) {
      if (!bidDeleteStatus.loading && !bidDeleteStatus.error) {
        Notice.success("You have successfully delete a bid!");
        success && success();
      }
      if (!bidDeleteStatus.loading && bidDeleteStatus.error) {
        Notice.failure(bidDeleteStatus.error || "Delete Failed");
      }
      dispatch(bidDeleteAction.received());
    }
  }, [bidDeleteStatus.justFinished]);

  return {
    deleteOffer,
    deleteBid,
    deleteByData,
    loadingDeleteOffer,
    loadingDeleteBid,
  };
};

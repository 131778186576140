import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";
import { PayTypeTitleMap } from "@core";
import { Button, Collapse, Helper, Table } from "@components";
import { simpleTimer } from "@utils/timer";
import { paymentValueMap } from "@config/enum";
import { useMarketUnitName } from "@hooks";

const AmountStr = ({ data = {} }) => {
  const units = get(data, "units", 0);
  const market = get(data, "market", {});
  const { amountStr } = useMarketUnitName(market, units);
  return amountStr;
};

const Items = (props) => {
  const { order, history } = props;
  const isPermanent = get(order, "order.market.marketType", "") === "permanent";
  return (
    <div className="border-blue-gray-200 border block rounded-lg mb-4 bg-white">
      <div className="px-4 md:px-8 pt-4 md:pt-8 text-lg font-bold leading-tight">
        Order summary
      </div>
      <div className="py-4 md:py-8 px-4 md:px-8 ">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            const url = get(order, "titleUrl");
            history && history.push(url || "/");
          }}
        >
          <h3>{order.title}</h3>
          <svg
            className="ml-2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
          </svg>
        </div>
        <div className="flex justify-between items-center mt-2">
          {!isPermanent && (
            <p className="text-sm text-blue-gray-500">
              {get(order, "order.market.seasonStr", "")}
            </p>
          )}
          <div
            className="hidden cursor-pointer text-sm font-medium text-fish-primary hover:text-fish-primary-700"
            onClick={() => {
              const url = get(order, "order.market.fisheryUrl");
              // history && history.push(url || "/");
              window.location.href = url;
            }}
          >
            Go to the market
          </div>
        </div>
        <div className="mt-5">
          <SectionSummary order={order} />
        </div>
        <div className="mt-5">
          <SectionQuota order={order} history={history} />
        </div>
        <div className="mt-5">
          <SectionServiceFee order={order} history={history} />
        </div>
        <div className="mt-5">
          <SectionCardProcessFee order={order} />
        </div>
      </div>
    </div>
  );
};

const SectionSummary = (props) => {
  const { order } = props;

  const getMethodStr = () => {
    const m = order.holdingFeePaymentMethod;
    switch (m) {
      case paymentValueMap.stripe:
        return "credit card";
        break;

      case paymentValueMap.wallet:
        return "wallet";
        break;

      case paymentValueMap.poli:
        return "poli";
        break;
        
      default:
        return "";
        break;
    }
  };

  const isStripe = () => {
    return order.holdingFeePaymentMethod === paymentValueMap.stripe;
  };

  const renderTotal = () => {
    return (
      <div className="flex-1">
        <div className="text-sm font-medium text-blue-gray-500">
          <span className="mr-2">
            {order.isIn ? "Total Payment" : "Total Income"}
          </span>
          <Helper
            content={
              order.isFinished && order.isIn
                ? "Included Card Processing Fee and Service Fee"
                : "Included Service Fee"
            }
          />
        </div>
        <div className="font-bold text-xl">
          {order.isFinished
            ? order.totalPayStr
            : numeral(order.subTotalWithGst)
                .add(order.serviceFeeWithGst)
                .format()}
        </div>
      </div>
    );
  };

  const renderBalance = () => {
    if (order.isAuctionOrder) {
      return null;
    }
    if (order.isOut) {
      return null;
    }
    if (order.isFinished) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="border-l border-blue-gray-300 hidden md:block mx-4 md:my-2"></div>
        <div className="flex-1 mt-4 md:mt-0">
          <div className="text-sm font-medium text-blue-gray-500">Total Paid</div>
          <div>
            <span className="font-bold text-xl">{order.howMuchPaidStr}</span>
          </div>
        </div>
        <div className="border-l border-blue-gray-300 hidden md:block mx-4 md:my-2"></div>
        <div className="flex-1 mt-4 md:mt-0">
          <div className="text-sm font-medium text-blue-gray-500">
            Outstanding Balance
          </div>
          <div>
            <span
              className={classnames("font-bold text-xl", {
                "text-red-600": order.howMuchLeft > 0,
              })}
            >
              {order.howMuchLeftStr}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderHoldFee = () => {
    const tip = getMethodStr();
    return (
      <React.Fragment>
        <div className="border-l border-blue-gray-300 hidden md:block mx-8 md:my-2"></div>
        <div className="flex-1 mt-4 md:mt-0">
          <div className="text-sm font-medium text-blue-gray-500">
            <span className="mr-2">Holding Fee</span>
            <Helper
              content={`The holding fee of ${
                order.holdingWithProcessingFeeStr
              } you paid ${
                isStripe()
                  ? `(including ${order.holdingCardProceessingFeeStr} card processing fee)`
                  : ""
              } has been secured and will be automatically returned to your ${tip} after the full payment is made.`}
            />
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1 w-4 h-4 text-blue-gray-500"
              viewBox="0 0 512 512"
            >
              <path
                d="M336 208v-95a80 80 0 00-160 0v95"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
              />
              <rect
                x="96"
                y="208"
                width="320"
                height="272"
                rx="48"
                ry="48"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
              />
            </svg>
            <span className="font-bold text-xl">
              {order.isCompleted ? "$0.00" : order.holdingWithProcessingFeeStr}
            </span>
          </div>
        </div>
        {order.isFinished ? (
          <div className="text-xxs ml-10" style={{ maxWidth: 180 }}>
            {order.isClosed
              ? `Your holding fee has been forfeited because you have not completed the full payment within ${simpleTimer(
                  order.holdingExpirationDur
                )}.`
              : `The ${order.holdingFeePercentageStr} holding fee has been refunded to
            your ${tip}`}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div className="rounded shadow border border-blue-gray-300 bg-gray-50 md:flex p-4 md:px-8">
      {renderTotal()}
      {order.useHoldingFee ? renderHoldFee() : renderBalance()}
    </div>
  );
};

const SectionQuota = (props) => {
  const { order, history } = props;
  return (
    <Collapse
      title={
        <div className="flex items-center justify-between cursor-pointer">
          <div className="font-bold">Quota Price</div>
          <div className="flex items-center">
            <span className="font-bold">{order.subTotalWithGstStr}</span>
          </div>
        </div>
      }
      titleClassName="border border-blue-gray-200 bg-gray-50 p-4 md:px-8"
      content={
        <div className="rounded-b bg-white border border-t-0 border-blue-gray-200 ">
          <div className="p-4 md:py-6 md:px-8">
            <Table className="table normal-table no-padding-table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th className="text-right">Price</th>
                  <th className="text-right w-1/3">Item Total</th>
                </tr>
              </thead>
              <tbody className="text-sm whitespace-no-wrap">
                {(order.itemsFormated || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <AmountStr data={item} />
                      </td>
                      <td className="text-right">{`${item.priceStr}/${get(
                        item,
                        "market.unitOfPrice"
                      )}`}</td>
                      <td className="text-right">{item.totalStr}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="border-t border-blue-gray-200 py-2 text-sm font-medium">
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">Subtotal</div>
                <div className="text-right w-1/3">{order.subTotalStr}</div>
              </div>
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">GST</div>
                <div className="text-right w-1/3">{order.gstStr}</div>
              </div>
              <hr className="w-2/3 md:w-1/2 ml-auto my-2" />
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">Total</div>
                <div className="text-right w-1/3 text-base font-bold">
                  {order.subTotalWithGstStr}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      className="rounded overflow-hidden"
      open
    />
  );
};

const SectionServiceFee = (props) => {
  const { order, history } = props;
  return (
    <Collapse
      title={
        <div className="flex items-center justify-between cursor-pointer">
          <div className="font-bold">Service Fee</div>
          <span className="font-bold">{order.serviceFeeWithGstStr}</span>
        </div>
      }
      titleClassName="border border-blue-gray-200 bg-gray-50 p-4 md:px-8"
      content={
        <div className="rounded-b bg-white border border-t-0 border-blue-gray-200 ">
          <div className="p-4 md:py-6 md:px-8">
            <Table className="table normal-table no-padding-table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th className="text-right">Price</th>
                  <th className="text-right w-1/3">Item Total</th>
                </tr>
              </thead>
              <tbody className="text-sm whitespace-no-wrap">
                <tr>
                  <td>
                    <AmountStr data={order} />
                  </td>
                  <td className="text-right">{order.serviceFeeTitle}</td>
                  <td className="text-right">{order.serviceFeeAbsStr}</td>
                </tr>
              </tbody>
            </Table>
            <div className="border-t border-blue-gray-200 py-2 text-sm font-medium">
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">Subtotal</div>
                <div className="text-right w-1/3">{order.serviceFeeAbsStr}</div>
              </div>
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">GST</div>
                <div className="text-right w-1/3">
                  {order.serviceFeeGstAbsStr}
                </div>
              </div>
              <hr className="w-2/3 md:w-1/2 ml-auto my-2" />
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">Total</div>
                <div className="text-right w-1/3 text-base font-bold">
                  {order.serviceFeeWithGstAbsStr}
                </div>
              </div>
            </div>
            {order.serviceFeeWithGstAbs > 0 && !order.isClosed ? (
              <div className="flex justify-end mt-4">
                <Button
                  color="fish-primary"
                  hoverColor="gray-100"
                  outline
                  onClick={() => {
                    history && history.push(order.invoiceFeeUrl);
                  }}
                >
                  Service Fee Invoice
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      }
      className="rounded overflow-hidden"
    />
  );
};

const SectionCardProcessFee = (props) => {
  const { order } = props;
  if(order.isSeller) {
    return null;
  }
  if (order.isAuctionOrder) {
    return null;
  }

  if (isEmpty(order.completedNotWaletPayments)) {
    return null;
  }
  return (
    <Collapse
      title={
        <div className="flex items-center justify-between cursor-pointer">
          <div className="font-bold">Card Processing Fee</div>
          <span className="font-bold">{order.cardProcessFeeSumStr}</span>
        </div>
      }
      titleClassName="border border-blue-gray-200 bg-gray-50 p-4 md:px-8"
      content={
        <div className="rounded-b bg-white border border-t-0 border-blue-gray-200 ">
          <div className="p-4 md:py-6 md:px-8">
            <Table className="table normal-table no-padding-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th className="text-right">Payment</th>
                  <th className="text-right w-1/3">Card Processing Fee</th>
                </tr>
              </thead>
              <tbody className="text-sm whitespace-no-wrap">
                {order.completedNotWaletPayments.map((payment, index) => {
                  return (
                    <tr key={index}>
                      <td className="capitalize">
                        {get(PayTypeTitleMap, `${payment.term}`, "")}
                      </td>
                      <td className="text-right">
                        {payment.transactionAmountStr}
                      </td>
                      <td className="text-right">
                        {payment.transactionFeesStr}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="border-t border-blue-gray-200 py-2 text-sm font-medium">
              <div className="flex justify-end py-2">
                <div className="text-right pr-3">Total</div>
                <div className="text-right w-1/3 text-base font-bold">
                  {order.cardProcessFeeSumStr}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      className="rounded overflow-hidden"
    />
  );
};

export default Items;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Select, Radio } from "@views/components/formik";
import { useQuotamarketOptions, useDeepEffect } from "@hooks";
import { SellParamsContext } from "@contexts";
import SecondStep from "./_step_2";

/**
 * [Component] Step 1
 */
const FirstStep = ({ values, setFieldValue }) => {
  const fisheryId = get(values, "fishery_id", "");
  const marketType = get(values, "market_type", "");
  const params = useContext(SellParamsContext);
  const originMarketType = get(params, "market_type", "");
  const quotaMarketId = get(values, "quota_market_id", "");

  // const { data, options, auctionOptions, loading } = useFisheryList();
  const {
    getCurrentMarket,
    exchangeOptions: marketOptions,
    loading: marketLoading,
  } = useQuotamarketOptions({
    fisheryId,
    marketType,
  });
  // const fisheryOptions = marketType === "permanent" ? auctionOptions : options;

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      maxHeight: 400,
    }),
  };

  const onMarketTypeChange = () => {
    setFieldValue("fishery_id", "");
    setFieldValue("quota_market_id", "");
    setFieldValue("market", {});
  };

  // const onFisheryChange = (value) => {
  //   const id = get(value, "value", "");
  //   const fishery = find(data, (o) => get(o, "id") == id);
  //   setFieldValue("sales_types", get(fishery, "saleTypes", ""));
  //   setFieldValue("quota_market_id", "");
  //   setFieldValue("market", {});
  // };

  const onMarketChange = (value) => {
    const id = get(value, "value");
    const market = getCurrentMarket(id);
    setFieldValue("market", market);
    setFieldValue("sales_types", get(market, "market.saleTypes", ""));
  };

  useDeepEffect(() => {
    if (marketOptions.length === 1) {
      const id = get(marketOptions, "[0].value", "");
      const market = getCurrentMarket(id);
      setFieldValue("quota_market_id", id);
      setFieldValue("market", market);
      setFieldValue("sales_types", get(market, "market.saleTypes", ""));
    }
  }, [marketOptions]);

  return (
    <>
      {!originMarketType && (
        <Radio
          name="market_type"
          className="lg:grid lg:grid-cols-2 lg:gap-4 space-y-4 lg:space-y-0"
          radioClassName="fish-primary rounded-md border border-blue-gray-200 bg-white p-3 items-center"
          onChange={onMarketTypeChange}
          options={[
            {
              label: (
                <div className="block text-sm font-medium">
                  Lease seasonal quota
                </div>
              ),
              value: "lease",
            },
            {
              label: (
                <div className="block text-sm font-medium">
                  Sell permanent quota
                </div>
              ),
              value: "permanent",
            },
          ]}
        />
      )}
      {marketType && (
        <Select
          label="What are you selling?"
          name="quota_market_id"
          options={marketOptions}
          placeholder="Select"
          className="form-select-lg"
          isLoading={marketLoading}
          onChange={onMarketChange}
          styles={customStyles}
        />
      )}
      {/* {quotaMarketId && (
        <SecondStep values={values} setFieldValue={setFieldValue} />
      )} */}
    </>
  );
};

FirstStep.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

FirstStep.defaultProps = {
  values: {},
  setFieldValue: () => {},
};

export default FirstStep;

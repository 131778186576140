import {
  AUCTION_FINAL_BID_SHOW_SUCCESS,
  AUCTION_FINAL_BID_SHOW_FAILURE,
} from "./action";

export const auctionFinalBidShowStatus = (state = initStatus, action = {}) => {

  switch (action.type) {
    case AUCTION_FINAL_BID_SHOW_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case AUCTION_FINAL_BID_SHOW_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: {},
  error: null,
};

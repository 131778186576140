export const WITHDRAW_LIST = "withdraws/list";
export const WITHDRAW_LIST_REQUEST = `${WITHDRAW_LIST}_request`;
export const WITHDRAW_LIST_SUCCESS = `${WITHDRAW_LIST}_success`;
export const WITHDRAW_LIST_FAILURE = `${WITHDRAW_LIST}_failure`;

export const withdrawListAction = {
  request: (payload) => {
    return {
      type: WITHDRAW_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: WITHDRAW_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: WITHDRAW_LIST_FAILURE,
      error,
    }
  },
};


export const CATEGORIES_FETCH_REQUEST = "categories/fetch_list_request";
export const CATEGORIES_FETCH_LOADING = "categories/fetch_list_loading";
export const CATEGORIES_FETCH_SUCCESS = "categories/fetch_list_success";
export const CATEGORIES_FETCH_FAILED = "categories/fetch_list_failed";

export function fetchCategoryList(payload) {
  return {
    type: CATEGORIES_FETCH_REQUEST,
    payload
  };
}

import { MarketApiRepo } from "@core";

export class MarketApiUsecase {

  constructor() {
    this.repo = new MarketApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }

  show(payload) {
    return this.repo.show(payload);
  }

  // create(payload) {
  //   return this.repo.create(payload);
  // }

  // update(payload) {
  //   return this.repo.update(payload);
  // }

  // destroy(payload) {
  //   return this.repo.destroy(payload);
  // }

  statistics(payload) {
    return this.repo.statistics(payload);
  }

  balance(payload) {
    return this.repo.balance(payload);
  }

}
const CHANGE_EMAIL_REQUEST = "change_email/request"
const CHANGE_EMAIL_LOADING = "change_email/loading"
const CHANGE_EMAIL_SUCCESS = "change_email/success"
const CHANGE_EMAIL_FAILED = "change_email/failed"

function changeEmailRequest(payload) {
  return {
    type: CHANGE_EMAIL_REQUEST,
    payload
  }
}

export {
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_LOADING,
  CHANGE_EMAIL_SUCCESS,

  changeEmailRequest
}
import React, { Component,useEffect } from 'react';
import { withRouter } from 'react-router-dom';

export const scrollTop = () => {
  window.scrollTo(0, 0);
};

const ScrollToTop = props=> {
  const {location} = props;
  useEffect(()=>{
    scrollTop();
  },[location])

  return props.children;
}

export default withRouter(ScrollToTop);

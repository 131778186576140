import { takeLatest, call, put } from "redux-saga/effects";

import { LicenceApiUsecase } from "@core";
import { LICENCE_UPDATE_REQUEST, licenceUpdateAction } from "./action";

export function* watchLicenceUpdatePut() {
  yield takeLatest(LICENCE_UPDATE_REQUEST, licenceUpdatePut)
}

function* licenceUpdatePut(action) {
  try {
    const usecase = new LicenceApiUsecase();
    const res = yield call([usecase, "update"], action.payload)
    yield put(licenceUpdateAction.success(res.data))
  } catch (err) {
    yield put(licenceUpdateAction.failure(err))
  }
}

export const BIDS_NEW_REQUEST = "bids/new_request";
export const BIDS_NEW_LOADING = "bids/new_loading";
export const BIDS_NEW_SUCCESS = "bids/new_success";
export const BIDS_NEW_FAILED = "bids/new_failed";

export function newBidRequest(payload) {
  return {
    type: BIDS_NEW_REQUEST,
    payload
  };
}

import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import get from "lodash/get";
import { useHistory } from "react-router-dom";

import Notice from "@utils/noticeNew";
import getUrlQuery from "@utils/getUrlQuery";
import errorString from "@utils/errorString";

import {
  paymentStripesAPI,
  paymentAmountAPI,
  paymentMethodsAPI,
} from "@config/api";
import { paymentUrl } from "@config/enum";

/**
 * stripe支付请求
 * @param {Object}
 */
export const useStripePaymentHook = ({
  paymentId,
  stripe,
  elements,
  orderId,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [elementFontSize, setElementFontSize] = useState("");
  const createdBy = getUrlQuery("created_by");

  /**
   * 默认值
   */
  const initialValues = {
    payment_method_id: "",
    saving_card: true,
  };
  /**
   * 请求付款接口
   */
  const pay = useCallback(
    (orderData) => {
      return new Promise((resolve, reject) => {
        axios
          .post(paymentStripesAPI(paymentId), orderData)
          .then((res = {}) => {
            resolve(res.data);
            return res;
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [paymentId]
  );

  /**
   * 付款成功后回调
   */
  const onSuccess = useCallback(
    (res = {}) => {
      Notice.success("Payment success!");
      // setLoading(false);
      const orderId = res.order_id;
      const no_licence = getUrlQuery("no_licence");
      let url = paymentUrl.successful;
      if (orderId) {
        const str = no_licence ? `&no_licence=true` : "";
        url += `?order_id=${orderId}${str}`;
      } else {
        url += `?page=wallet`;
      }
      setTimeout(() => {
        history && history.push(url);
      }, 300);
    },
    [history]
  );

  /**
   * 付款失败后回调
   */
  const onError = useCallback(
    (err) => {
      Notice.failure(get(err, "message", "") || "Payment failed");
      setLoading(false);
      setTimeout(() => {
        if (history) {
          history && history.goBack();
          // if (orderId) {
          //   history.push(`/orders/${orderId}/payment`);
          // } else {
          //   history.push("/account/topup");
          // }
        }
      }, 300);
    },
    [orderId]
  );

  /**
   * 保存银行卡请求
   */
  const handleAction = useCallback(
    (clientSecret) => {
      // Show the authentication modal if the PaymentIntent has a status of "requires_action"
      if (stripe) {
        stripe
          .handleCardAction(clientSecret)
          .then(function (data) {
            if (data.error) {
              onError(data.error);
            } else if (data.paymentIntent.status === "requires_confirmation") {
              // Card was properly authenticated, we can attempt to confirm the payment again with the same PaymentIntent
              let orderData = {};
              orderData.payment_intent_id = data.paymentIntent.id;
              if (pay) {
                pay(orderData)
                  .then((res = {}) => {
                    onSuccess(res);
                  })
                  .catch((err) => {
                    onError(err);
                  });
              }
            }
          })
          .catch(function (err) {
            onError(err);
          });
      }
    },
    [pay, stripe, orderId]
  );

  /**
   * 订单支付
   */
  const orderPay = useCallback(
    (values = {}) => {
      const { payment_method_id, saving_card } = values;

      // 选择现有银行卡来支付
      if (payment_method_id) {
        let orderData = {};
        orderData.payment_method_id = payment_method_id;
        orderData.reuse_card = true;
        pay(orderData)
          .then((paymentData = {}) => {
            if (paymentData.requires_action) {
              // Request authentication
              handleAction(paymentData.client_secret);
            } else {
              onSuccess(paymentData);
            }
          })
          .catch((err) => {
            onError(err);
          });
        return;
      }

      // 选择新卡支付
      // Use Elements to get a reference to the Card Element mounted somewhere
      // in your <Elements> tree. Elements will know how to find your Card Element
      // because only one is allowed.
      // See our getElement documentation for more:
      // https://stripe.com/docs/stripe-js/reference#elements-get-element
      const cardElement = elements.getElement("card");

      // From here we can call createPaymentMethod to create a PaymentMethod
      // See our createPaymentMethod documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
      if (stripe) {
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
          })
          .then((result = {}) => {
            if (result.error) {
              onError(result.error);
            } else {
              let orderData = {};
              orderData.payment_method_id = result.paymentMethod.id;
              orderData.saving_card = saving_card;
              return pay && pay(orderData);
            }
          })
          .then((paymentData = {}) => {
            if (paymentData.requires_action) {
              // Request authentication
              handleAction(paymentData.client_secret);
            } else {
              onSuccess(paymentData);
            }
          })
          .catch((err) => {
            onError(err);
          });
      } else {
        setLoading(false);
      }
    },
    [handleAction, pay, stripe, elements, orderId]
  );

  /**
   * 钱包充值
   */
  const walletPay = useCallback(() => {
    if (stripe) {
      stripe
        .createToken()
        .then((payload) => {
          let orderData = {};
          orderData.token = get(payload, "token.id");
          return pay(orderData);
        })
        .then((res) => {
          onSuccess(res);
        })
        .catch((err) => {
          onError(err);
        });
    } else {
      this.setState({ loading: false });
    }
  }, [stripe, pay, orderId]);

  /**
   * 点击按钮后提交请求
   */
  const onSubmit = useCallback(
    (values = {}) => {
      setLoading(true);
      if (createdBy === "order") {
        return orderPay(values);
      }
      walletPay();
    },
    [createdBy, orderId]
  );

  useEffect(() => {
    setElementFontSize(window.innerWidth < 450 ? "14px" : "18px");
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 450 && elementFontSize !== "14px") {
        setElementFontSize("14px");
      } else if (window.innerWidth >= 450 && elementFontSize !== "18px") {
        setElementFontSize("18px");
      }
    });
  }, [elementFontSize]);

  return { onSubmit, initialValues, loading, elementFontSize, createdBy };
};

export const usePaymentAmountHook = (paymentId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(paymentAmountAPI(paymentId))
      .then((res) => {
        setLoading(false);
        setRes(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  }, [paymentId]);

  return [res, loading, error];
};

export const usePaymentMethodsHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);

  const getList = useCallback(() => {
    setLoading(true);
    axios
      .get(paymentMethodsAPI())
      .then((res) => {
        setLoading(false);
        setRes(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    getList();
  }, []);

  return { res, getList, loading, error };
};

export const usePaymentMethodsDeleteHook = (cb) => {
  const [loading, setLoading] = useState(false);

  /**
   * 请求删除银行卡接口
   */
  const del = useCallback((id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(paymentMethodsAPI(id))
        .then((res = {}) => {
          resolve(res.data);
          return res;
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  /**
   * 提交请求
   */
  const onSubmit = useCallback(
    (id) => {
      if (window.confirm("Are you sure?")) {
        setLoading(true);
        del(id)
          .then(() => {
            setLoading(false);
            Notice.success("Delete card success!");
            typeof cb === "function" && cb();
          })
          .catch((err) => {
            Notice.failure(errorString(err) || "Delete card failed");
            setLoading(false);
          });
      }
    },
    [cb]
  );

  return { onSubmit, loading };
};

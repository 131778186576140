import { OrderApiRepo } from "@core";

export class OrderApiUsecase {
  constructor() {
    this.repo = new OrderApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }

  sold(payload) {
    return this.repo.sold(payload);
  }

  purchased(payload) {
    return this.repo.purchased(payload);
  }

  show(payload) {
    return this.repo.show(payload);
  }

  // create(payload) {
  //   return this.repo.create(payload);
  // }

  // update(payload) {
  //   return this.repo.update(payload);
  // }

  payment(payload) {
    return this.repo.payment(payload);
  }

  buyerPay(payload) {
    return this.repo.buyerPay(payload);
  }

  paymentsProofs(payload) {
    return this.repo.paymentsProofs(payload);
  }

  getPaymentsProofs(payload) {
    return this.repo.getPaymentsProofs(payload);
  }

  deletePaymentsProofs(payload) {
    return this.repo.deletePaymentsProofs(payload);
  }

  receiveFund(payload) {
    return this.repo.receiveFund(payload);
  }

  quotaTransfers(payload) {
    return this.repo.quotaTransfers(payload);
  }

  getQuotaTransfers(payload) {
    return this.repo.getQuotaTransfers(payload);
  }
}

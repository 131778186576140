import React from "react";
import { Formik } from "formik";

import { useAccountBankForm } from "@hooks/account/bank";
import { Form, Input } from "@components/formik";
import { LoadingWrapper, Button } from "@components";
import Header from "@views/account/setting/_header";

const BankForm = () => {
  const {
    initialValues,
    loading,
    updateLoading,
    onSubmit,
    schema,
  } = useAccountBankForm();
  return (
    <LoadingWrapper loading={loading}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Header
            title="Your bank details"
            sub="So that funds can be transferred to you for leasing-out and selling quota. It won't be shared publicly."
            className="mb-6"
          />
          <Input classNameWrapper="mt-4-tw" name="name" label="Bank Name" />
          <Input classNameWrapper="mt-4-tw" name="bsb" label="BSB Number" />
          <Input
            classNameWrapper="mt-4-tw"
            name="account_name"
            label="Account Name"
          />
          <Input classNameWrapper="mt-4-tw" name="acc" label="ACC Number" />
          <div className="py-8">
            <Button
              type="submit"
              size="xl"
              color="fish-primary"
              className="hover:border-fish-primary-700 hover:bg-fish-primary-700 focus:border-fish-primary-700 focus:bg-fish-primary-700 active:bg-fish-primary-700"
              disabled={updateLoading}
              loading={updateLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </LoadingWrapper>
  );
};

export default BankForm;

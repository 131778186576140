
export const TIMESTAMP_FETCH_REQUEST = "timestamp/fetch_request";
export const TIMESTAMP_FETCH_LOADING = "timestamp/fetch_loading";
export const TIMESTAMP_FETCH_SUCCESS = "timestamp/fetch_success";
export const TIMESTAMP_FETCH_FAILED = "timestamp/fetch_failed";

export function timestampFetch(payload) {
  return {
    type: TIMESTAMP_FETCH_REQUEST,
    payload
  };
}

import { get, some, isArray } from "lodash";

export class LoadingSelector {

  constructor(keys = "", state = {}) {
    this.keys = keys;
    this.state = state;
  }

  get loading() {
    if (isArray(this.keys)) {
      return some(this.keys, key =>
        get(this.state, `loadingStatus.${key}`, false)
      );
    }
    return get(this.state, `loadingStatus.${this.keys}`, false);
  }
}

import React, { useEffect } from "react";
import Cohere from "cohere-js";

/**
 * import lib tools & components
 */
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import * as FullStory from "@fullstory/browser";
import get from "lodash/get";
import indexOf from "lodash/indexOf";
/**
 * import local hooks & tools & components
 */
import { Mobile, DesktopAndTablet } from "@components";
import {
  useCurrentUser,
  useUserLogout,
  useResponsive,
  useGlobalStatic,
  useFisheryExistence,
  useActionCableConnect,
  useFisheryList,
  useFisheryFollow,
  useWalletBalance,
  usePaymentMethodsHook,
  useParams,
  useNotificationList,
} from "@hooks";
import { fullStoryConfig } from "@config/fullStory";
import Top from "./_top";
import Footer from "./_footer";
import DashboardNav from "./_dashboardNav";

/**
 * [Component] Layout
 * @example
 *
  <Layout>
    Your content here
  </Layout>
 *
 */
const Layout = (props) => {
  const { match, children, hideHeader } = props;
  const { status: connectStatus } = useActionCableConnect();
  const currentUserStatus = useCurrentUser();
  const { logout } = useUserLogout();
  const { isMobile } = useResponsive();
  const { data: staticData } = useGlobalStatic(true);
  const { fetch: fisheryExistFetch } = useFisheryExistence();
  const { balance, balanceStr, loading: loadingBalance } = useWalletBalance();
  const { res, loading: methodsLoading, getList } = usePaymentMethodsHook();

  /**
  * watchlist
  */
  const [{ redirect_to, ...params }] = useParams("all");
  const {
    data,
    hasFollowing,
    loading: listLoading,
    updateList,
  } = useFisheryList(params);
  const { onSubmit, loading: followLoading } = useFisheryFollow(updateList);

  const {
    data: notificationList,
    loading: notificationLoading,
  } = useNotificationList();
  

  const isAuthenticated = get(currentUserStatus, "isAuthenticated", false);
  const isProfileFilled = get(
    currentUserStatus,
    "user.custom:is_filled",
    false
  );
  const isHome = get(match, "path", "") === "/";
  const currentPath = get(match, "path", "");
  const isDashboard = indexOf(['/dashboard','/listings','/orders','/bids','/wallet','/statements','/setting'], currentPath);

  useEffect(() => {
    if (isProfileFilled && isAuthenticated) {
      fisheryExistFetch();
    }
  }, [isAuthenticated, isProfileFilled]);

  //identify fullstory
  useEffect(() => {
    const fullStory = fullStoryConfig();

    if (!fullStory.org_code) {
      return;
    }

    const fullStoryId = get(currentUserStatus, "user.cognito:username");

    if (fullStoryId) {
      const fullStoryDisplayName = get(
        currentUserStatus,
        "user.custom:company_name"
      );
      const fullStoryEmail = get(currentUserStatus, "user.email");
      FullStory.identify(fullStoryId, {
        displayName: fullStoryDisplayName || "",
        email: fullStoryEmail || "",
      });
    }
  }, [currentUserStatus]);

  //identify Cohere
  useEffect(() => {
    Cohere.init("FlAy3ESk9dbyT6Ig3VBVRhOl")

    const fullStory = fullStoryConfig();
    if (!fullStory.org_code) {
      return;
    }
    
    const fullCohereId = get(currentUserStatus, "user.cognito:username");
    if (fullCohereId) {
      const cohereDisplayName = get(
        currentUserStatus,
        "user.custom:company_name"
      );
      const cohereEmail = get(currentUserStatus, "user.email");
      Cohere.identify(
        fullCohereId,
        {
          displayName: cohereDisplayName || "",
          email: cohereEmail || "",
        }
      );
    }
  }, [currentUserStatus]);

  return (
    <StickyContainer>
      <div className="layout-v2 min-h-screen flex flex-col bg-gray-50">
        {!hideHeader && (
          <div>
            <Top
              logout={logout}
              currentUserStatus={currentUserStatus}
              connectStatus={connectStatus}
              match={match}
              data={data}
              listLoading={listLoading}
              onSubmit={onSubmit}
              followLoading={followLoading}
              balanceStr={balanceStr}
              res={res}
              notificationList={notificationList}
              notificationLoading={notificationLoading}
            />
            <Mobile>
              {isDashboard != '-1' && (
                <DashboardNav
                  match={match}
                />
              )}
            </Mobile>
          </div>
        )}
        <div className="layout-v2-main flex-1 flex flex-col">{children}</div>
        {isMobile && !isHome ? null : <Footer staticData={staticData} />}
      </div>
    </StickyContainer>
  );
};

Layout.propTypes = {
  hideHeader: PropTypes.bool,
};

Layout.defaultProps = {
  hideHeader: false,
};

export default withRouter(Layout);

export const PERMANMENT_SHOW = "permanent/show";
export const PERMANMENT_SHOW_REQUEST = `${PERMANMENT_SHOW}_request`;
export const PERMANMENT_SHOW_SUCCESS = `${PERMANMENT_SHOW}_success`;
export const PERMANMENT_SHOW_FAILURE = `${PERMANMENT_SHOW}_failure`;

export const permanentShowAction = {
  request: (payload) => {
    return {
      type: PERMANMENT_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANMENT_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANMENT_SHOW_FAILURE,
      error,
    }
  },
};

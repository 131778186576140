import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash/get";

/**
 * import lib tools
 */
import { LoadingSelector, Yup } from "@core";
import getUrlQuery from "@utils/getUrlQuery";

/**
 * import local tools & redux & usecase
 */
import Notice from "@utils/noticeNew";
import { AccountProfileUsecase } from "@core/index";
import {
  accountProfileUpdateAction,
  ACCOUNT_PROFILE_UPDATE,
} from "@redux/modules_v2/account/profile/action";

/**
 * hooks
 */
import { useGlobalStatic } from "@hooks";

/**
 * Profile form hooks
 * @param {*} param
 */
export const useAccountProfileSettingForm = (callback) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();
  const toggleModal = get(callback, "toggleModal", "");
  const callbackSubmit = get(callback, "submit", "");

  /**
   * Account profile usecase;
   */
  const profileUsecase = new AccountProfileUsecase();

  /**
   * contentful 静态数据
   */
  const { data: globalStaticData } = useGlobalStatic(true);
  const staticData = get(globalStaticData, "my_profile", {});
  const staticMsg = get(globalStaticData, "messages", {});
  /**
   * 编辑提交后状态
   */
  const accountProfileUpdateStatus = useSelector((state) => {
    const selector = new LoadingSelector(ACCOUNT_PROFILE_UPDATE, state);
    return Object.assign({}, get(state, "accountProfileUpdateStatus", {}), {
      loading: selector.loading,
    });
  });


  /**
   * 获取redirect url
   */
  const redirectTo = useCallback(() => {
    const redirect = getUrlQuery("redirect_to");
    return redirect;
  }, [])();

  /**
   * 初始化更新表单数据loading
   */
  const [loading, setLoading] = useState(false);

  /**
   * 初始化countries 和 states列表
   */
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  /**
   * 初始化表单
   * //   email: "",
    //   picture: "",
    //   user_name: "",
    //   first_name: "",
    //   last_name: "",
    //   user_type: [],
    //   address: "",
    //   state: "",
    //   country: "",
    //   post_code: "",
    //   phone_country_code: "",
    //   mobile_number: "",
    //   company_name: "",
    //   industry: [],
    //   abn: "",
    //   licence_number: "",
    //   need_gst: false
   */
  const [initialValues, setInitalValues] = useState({
    email: "",
    user_name: "",
    first_name: "",
    last_name: "",
    user_type: [],
    address: "",
    state: "",
    country: "",
    post_code: "",
    phone_country_code: "",
    phone_number: "",
    company_name: "",
    industry: [],
    abn: "",
    licence_number: "",
  });

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback((values) => {
    const data = profileUsecase.getEditData(values);
    // dispatch(updateAttributeRequest(data));
    dispatch(accountProfileUpdateAction.request(data));
  }, []);

  /**
   * [Callback] 触发提交表单
   */
  const handleSubmit = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  /**
   * [Callback] 检查abn是否填写
   */
  const checkProfileAbn = useCallback(() => {
    const hasAbn = !!get(initialValues, "abn", "");
    if (hasAbn) {
      return false;
    }
    // typeof toggleModal === "function" && toggleModal(true);
    return true;
  }, [initialValues, toggleModal]);

  /**
   * 获取profile信息
   */
  const fetchProfile = useCallback(() => {
    profileUsecase.getData().then((data) => {
      setLoading(false);
      setInitalValues(data);
    });
  }, []);

  /**
   * Schema
   */
  const schema = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    phone_number: Yup.string().phone(
      "AU", true, "Please enter a valid Phone Number"
    ).required("This field is required"),
    company_name: Yup.string().required("This field is required"),
    abn: Yup.string()
      .min(11, "Must be exactly 11 digits")
      .max(11, "Must be exactly 11 digits"),
  });

  /**
   * 更新表单数据
   */
  useEffect(() => {
    setLoading(true);
    setCountries(profileUsecase.countries);
    setStates(profileUsecase.states);
    fetchProfile();
  }, []);

  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(accountProfileUpdateAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!accountProfileUpdateStatus.loading) {
      if (accountProfileUpdateStatus.data) {
        Notice.success("Submit successfully!");
        fetchProfile();
        typeof toggleModal === "function" && toggleModal();
        if (typeof callbackSubmit === "function") {
          callbackSubmit();
          return;
        }
        redirectTo && history.push(redirectTo);
      }
      if (accountProfileUpdateStatus.error) {
        Notice.failure("Update Profile Failed");
      }
    }
  }, [accountProfileUpdateStatus.loading]);

  return {
    checkProfileAbn,
    countries,
    fetchloading: loading,
    formRef,
    handleSubmit,
    initialValues,
    onSubmit,
    schema,
    states,
    staticData,
    updateLoading: accountProfileUpdateStatus.loading,
  };
};

import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { OrderApiUsecase } from "@core";
import {
  ORDERS_PAYMENT_PROOFS_DELETE_REQUEST,
  ordersPaymentProofsDeleteAction,
} from "./action";

export function* watchOrdersPaymentProofsDelete() {
  yield takeLatest(
    ORDERS_PAYMENT_PROOFS_DELETE_REQUEST,
    ordersPaymentProofsDelete
  );
}

function* ordersPaymentProofsDelete(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "deletePaymentsProofs"], action.payload);
    yield put(ordersPaymentProofsDeleteAction.success("success"));
  } catch (err) {
    yield put(ordersPaymentProofsDeleteAction.failure(errorString(err)));
  }
}

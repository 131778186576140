export const AUCTION_BID_LIST = "auction/bid_list";
export const AUCTION_BID_LIST_REQUEST = `${AUCTION_BID_LIST}_request`;
export const AUCTION_BID_LIST_SUCCESS = `${AUCTION_BID_LIST}_success`;
export const AUCTION_BID_LIST_FAILURE = `${AUCTION_BID_LIST}_failure`;
export const AUCTION_BID_LIST_RESET = `${AUCTION_BID_LIST}_reset`;

export const auctionBidListAction = {
  request: (payload) => {
    return {
      type: AUCTION_BID_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: AUCTION_BID_LIST_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: AUCTION_BID_LIST_FAILURE,
      error,
    };
  },
  reset: () => {
    return {
      type: AUCTION_BID_LIST_RESET,
    };
  },
};

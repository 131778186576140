import {
  AuthenticationDetails,
  CognitoUser
} from "amazon-cognito-identity-js";

import { userPool, region, poolData } from './config'

/**
 * Sign in with email and password
 * @param email
 * @param password
 * @return {Promise<any>}
 */
function signIn(email, password) {
  let authenticationData = {
    Username: email,
    Password: password
  }
  let authenticationDetails = new AuthenticationDetails(authenticationData)
  let userData = {
    Username: email,
    Pool: userPool
  }
  let cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // console.log(`cognito auth user success: ${result}`)
        resolve(result)
      },

      onFailure: function (err) {
        // console.log(`cognito auth user failed: ${err}`)
        reject(err)
      },

      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user.
        // Required attributes according to schema, which don’t have any values yet, will have blank values.
        // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.


        // Get these details and call
        // newPassword: password that user has given
        // attributesData: object with key as attribute name and value that the user has given.
        // cognitoUser.completeNewPasswordChallenge(newPassword, attributesData, this)
      }
    })
  })
}

export {signIn}
import {
  WS_CONNECTING_STATUS_ADD,
  WS_CONNECTING_STATUS_DEL,
  WS_CONNECTING_STATUS_SET
} from "./action";

export function wsConnectingState(state = initStatus, action = {}) {
  let channels = Object.assign({}, state.channels);
  switch (action.type) {
    case WS_CONNECTING_STATUS_ADD:
      channels = addChannel(state.channels, action.payload);
      return {
        channels,
        status: getConnectingStatus(channels)
      };

    case WS_CONNECTING_STATUS_DEL:
      channels = delChannel(state.channels, action.payload);
      return {
        channels,
        status: getConnectingStatus(channels)
      };

    case WS_CONNECTING_STATUS_SET:
      channels = setChannel(state.channels, action.payload);
      return {
        channels,
        status: getConnectingStatus(channels)
      };

    default:
      return state;
  }
}

const initStatus = {
  channels: {},
  status: true
};

const addChannel = (channels, { channel }) => {
  return Object.assign({}, channels, { [channel]: true });
};

const delChannel = (channels, { channel }) => {
  let result = Object.assign({}, channels);
  delete result[channel];
  return result;
};

const setChannel = (channels, { channel, status }) => {
  let result = Object.assign({}, channels);
  result[channel] = status;
  return result;
};

const getConnectingStatus = channels => {
  const values = Object.values(channels);
  let result = true;
  values.map(v => {
    if (!v) {
      result = false;
      return;
    }
  });
  return result;
};

import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  ordersReceiveFundAction,
  ORDERS_RECEIVE_FUND
} from "@redux/modules_v2/orders/actions/receiveFund/action";

import Notice from "@utils/noticeNew";

export const useReceiveFund = (id, cb) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(() => {
    request({ id });
  }, [id]);

  const request = (payload) => {
    dispatch(ordersReceiveFundAction.request(payload));
  };

  const ordersReceiveFundStatus = useSelector((state) => {
    const selector = new LoadingSelector(ORDERS_RECEIVE_FUND, state);
    return Object.assign({}, get(state, "ordersReceiveFundStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    return () => {
      dispatch(ordersReceiveFundAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!ordersReceiveFundStatus.loading) {
      if (!isEmpty(ordersReceiveFundStatus.data)) {
        Notice.success("Submit successfully!");
        dispatch(ordersReceiveFundAction.reset());
        typeof cb === "function" && cb();
      }
      if (ordersReceiveFundStatus.error) {
        Notice.failure(ordersReceiveFundStatus.error);
      }
    }
  }, [ordersReceiveFundStatus.loading]);

  const loading = ordersReceiveFundStatus.loading;
  const data = ordersReceiveFundStatus.data || {};

  return { onSubmit, loading, data };
};

import { createSelector } from "reselect";
import map from "lodash/map";
import {
  STATMENT_LIST_SUCCESS,
  STATMENT_LIST_FAILURE,
  STATMENT_LIST_UPDATE,
} from "./action";

export const accountStatementListStatus = (state = initStatus, action = {}) => {
  const updateSelector = createSelector(
    (state) => state.data,
    (_, payload) => payload,
    (data, { id, ...rest }) => {
      return map(data, (item) => {
        if (item.id == id) {
          return Object.assign({}, item, rest);
        }
        return item;
      });
    }
  );

  switch (action.type) {
    case STATMENT_LIST_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case STATMENT_LIST_FAILURE:
      return {
        data: [],
        error: action.error,
      };

    case STATMENT_LIST_UPDATE:
      return {
        data: updateSelector(state, action.payload),
        error: null,
      };
    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null,
};

import { QuotaApiRepo } from "@core";

export class QuotaApiUsecase {
  constructor() {
    this.repo = new QuotaApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }
}

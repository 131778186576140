import axios from "axios";
import { actionChannel, call, put, take } from "redux-saga/effects";
import { buffers } from 'redux-saga'
import { getCurrentTimeInMs, adjustTime } from "@utils/timeAdjust";
import {
  TIMESTAMP_FETCH_REQUEST,
  TIMESTAMP_FETCH_LOADING,
  TIMESTAMP_FETCH_SUCCESS,
  TIMESTAMP_FETCH_FAILED
} from "./action";

import { timestampAPI } from "@config/api";

export function* watchTimestampFetch() {
  const timeStampChan = yield actionChannel(TIMESTAMP_FETCH_REQUEST, buffers.none());

  while(true) {
    const action = yield take(timeStampChan);
    yield call(timestampFetch, action)
  }
}
export function* timestampFetch(action) {
  yield put({ type: TIMESTAMP_FETCH_LOADING });
  const { err, res } = yield call(timestampFetchApi, action.payload);
  if (!res) {
    yield put({
      type: TIMESTAMP_FETCH_FAILED,
      error: "Get timestamp failed."
    });
    return;
  }
  yield put({
    type: TIMESTAMP_FETCH_SUCCESS,
    data: res
  });
  adjustTime(res);
}

export function timestampFetchApi(payload) {
  const request_timestamp = getCurrentTimeInMs();
  return axios
    .get(timestampAPI())
    .then(res => {
      const callback_timestamp = getCurrentTimeInMs();
      return {
        res: Object.assign({}, res.data, {
          request_timestamp,
          callback_timestamp
        })
      };
    })
    .catch(err => ({
      err
    }));
}

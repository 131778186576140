export const FISHERY_FOLLOWING_QUOTA_MARKETS = "fishery/following_quota_markets";
export const FISHERY_FOLLOWING_QUOTA_MARKETS_REQUEST = `${FISHERY_FOLLOWING_QUOTA_MARKETS}_request`;
export const FISHERY_FOLLOWING_QUOTA_MARKETS_SUCCESS = `${FISHERY_FOLLOWING_QUOTA_MARKETS}_success`;
export const FISHERY_FOLLOWING_QUOTA_MARKETS_FAILURE = `${FISHERY_FOLLOWING_QUOTA_MARKETS}_failure`;

export const fisheryFollowingQuotaMarketsAction = {
  request: (payload) => {
    return {
      type: FISHERY_FOLLOWING_QUOTA_MARKETS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_FOLLOWING_QUOTA_MARKETS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_FOLLOWING_QUOTA_MARKETS_FAILURE,
      error,
    }
  },
};

import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_STICK_LIST_REQUEST, fisheryStickListAction } from "./action";

// fetch fishery list
export function* watchFisheryStickListFetch() {
  yield takeLatest(FISHERY_STICK_LIST_REQUEST, fisheryStickListFetch);
}

function* fisheryStickListFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "stickList"], action.payload);
    yield put(fisheryStickListAction.success(res.data));
  } catch (err) {
    yield put(fisheryStickListAction.failure(err));
  }
}

import get from "lodash/get";
import { FisheryApiRepo } from "@core";

export class FisheryApiUsecase {
  constructor() {
    this.repo = new FisheryApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }

  stickList() {
    return this.repo.stickList();
  }
  
  show(payload) {
    return this.repo.show(payload);
  }

  quotaMarkets(payload) {
    return this.repo.quotaMarkets(payload);
  }

  activity(payload) {
    return this.repo.activity(payload);
  }

  existence() {
    return this.repo.existence();
  }

  toggleFollow(payload) {
    const following = get(payload, "following", false);
    if (following) {
      return this.repo.unfollow(payload);
    }
    return this.repo.follow(payload);
  }

  followingList() {
    return this.repo.followingList();
  }

  followingQuotaMarkets() {
    return this.repo.followingQuotaMarkets();
  }

  quota(payload) {
    return this.repo.quota(payload);
  }

  followingAuctions() {
    return this.repo.followingAuctions();
  }
}

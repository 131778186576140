const RESEND_CODE_REQUEST = "resend_code/request";
const RESEND_CODE_LOADING = "resend_code/loading";
const RESEND_CODE_SUCCESS = "resend_code/success";
const RESEND_CODE_FAILED = "resend_code/failed";
const RESEND_CODE_RESET = "resend_code/reset";

function resendCodeRequest(payload) {
  return {
    type: RESEND_CODE_REQUEST,
    payload,
  };
}

function resendCodeReset() {
  return {
    type: RESEND_CODE_RESET,
  };
}

export {
  resendCodeRequest,
  resendCodeReset,
  RESEND_CODE_REQUEST,
  RESEND_CODE_FAILED,
  RESEND_CODE_LOADING,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_RESET,
};

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const Header = ({ currentUser }) => {
  const userName = get(currentUser, "user.given_name", "");
  return (
    <div className="relative pt-8 md:pt-16 pb-4 md:pb-6 border-b border-gray-200 mb-4 md:mb-8 mx-4 md:mx-0">
      <h1 className=" text-2xl font-bold tracking-tight text-blue-gray-900">
        Welcome back, {userName}
      </h1>
    </div>
  );
};

Header.propTypes = {};

export default Header;

import {takeEvery, call, put} from 'redux-saga/effects'
import {confirmRegistration} from '@utils/cognitoAuth'

import {
  VERIFY_FAILED,
  VERIFY_SUCCESS,
  VERIFY_LOADING,
  VERIFY_REQUEST
} from './action'

function* watchVerifyRequest() {
  yield takeEvery(VERIFY_REQUEST, verifyRequest)
}

function* verifyRequest(action) {
  yield put({type: VERIFY_LOADING})
  let {res, err} = yield call(verifyCall, action.payload)

  if (err) {
    yield put({type: VERIFY_FAILED, error: err})
    return
  }
  yield put({type: VERIFY_SUCCESS, data: res})
}

function verifyCall({email, code}) {
  return confirmRegistration(email, code)
    .then(res => ({res: res}))
    .catch(err => ({err: err}))
}

export {watchVerifyRequest}
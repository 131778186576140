import {
  OFFER_NEW_COLLAPSE_STATUS_TOGGLE,
  OFFER_NEW_MODAL_STATUS_TOGGLE,
  OFFER_NEW_SET_SELL_TYPE,
  OFFER_SET_EDIT,
  AUCTION_SET_EDIT,
} from "./layoutAction";

export default function layoutState(state = initStatus, action = {}) {
  let offerNewModal = Object.assign({}, state.offerNewModal);
  let pendingOrderModal = Object.assign({}, state.pendingOrderModal);

  switch (action.type) {
    case OFFER_NEW_COLLAPSE_STATUS_TOGGLE:
      return Object.assign({}, state, {
        offerNewCollapseIsOpen: action.payload.isOpen
      });

    case OFFER_NEW_MODAL_STATUS_TOGGLE:
      offerNewModal.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        offerNewModal
      });

    case OFFER_NEW_SET_SELL_TYPE:
      return Object.assign({}, state, {
        offerNewSellType: action.payload.kind
      });

    case OFFER_SET_EDIT:
      return Object.assign({}, state, {
        offerModalIsEdit: action.payload.isEdit,
        offerData: action.payload.isEdit ? action.payload.data : null
      });

    case AUCTION_SET_EDIT:
      return Object.assign({}, state, {
        auctionModalIsEdit: action.payload.isEdit,
        auctionData: action.payload.isEdit ? action.payload.data : null
      });

    default:
      return state;
  }
}

const initStatus = {
  offerNewCollapseIsOpen: false,
  offerNewSellType: "", // AskingPrice|OfferPrice|Auction
  offerNewModal: {
    isOpen: false
  },
  offerModalIsEdit: false,
  offerData: null // null|{id: 111, ...}
};
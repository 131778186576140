import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Notice from "@utils/noticeNew";

/**
 * import local tools & hooks
 */
import { usePermanentEnquire } from "@hooks";

/**
 * import common component
 */
import Layout from "@views/layout";
import { WithConfirmModal } from "@views/common";
import { Breadcrumb, DesktopAndTablet, Mobile } from "@components";

/**
 * import local component
 */
import Form from "./form";
// const getUrl = (props) => {
//   const fisheryId = get(props, "match.params.fishery_id", "");
//   const search = get(props, "history.location.search", "");
//   return `/au/fishery/${fisheryId}${search}`;
// };
/**
 * [Page] Quota show
 */
const EnquireQuotaUnits = ({ match, toggleConfirmModal }) => {
  const quotaUnitId = get(match, "params.id");
  const permanentEnquireProps = usePermanentEnquire({
    id: quotaUnitId,
    toggleModal: toggleConfirmModal,
  });
  const renderContent = () => {
    return (
      <div className="px-2 md:px-0">
        <Breadcrumb className="mt-4 px-2 md:px-0" back />
        <h2 className="px-2 md:px-0 mt-6">
          {" "}
          Enquire about this listing
        </h2>
        <div className="mt-6 md:mt-10 px-4 py-6 md:px-6 md:py-8 rounded-lg border border-blue-gray-100 shadow bg-white">
          <Form {...permanentEnquireProps} />
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="gs-page pt-0 pb-0 bg-gray-50">
        <DesktopAndTablet>
          <div className="mx-auto mb-10" style={{ width: 640, marginTop: 60 }}>
            {renderContent()}
          </div>
        </DesktopAndTablet>
        <Mobile>
          <div className="mb-6">{renderContent()}</div>
        </Mobile>
      </div>
    </Layout>
  );
};

EnquireQuotaUnits.propTypes = {
  history: PropTypes.object,
  toggleConfirmModal: PropTypes.func,
  match: PropTypes.object,
};

EnquireQuotaUnits.defaultProps = {
  toggleConfirmModal: () => {},
};

export default WithConfirmModal({
  url: "/", //confirm按钮点击后跳转的地址
  title: "Thanks!", //modal的标题
  text:
    "We’ve received your Enquiry. Our team will be in touch with you as soon as possible. You can also contact us via online chat and email at support@beachprice.com", //modal的内容
  button: "Confirm",
  callback: () => {
    Notice.success("Submitted successfully!");
  },
})(EnquireQuotaUnits);

import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import cx from "classnames";
import { LoadingWrapper, Empty } from "@components";

const Table = ({ data, loading, emptyTitle, emptyContent }) => {
  return (
    <LoadingWrapper loading={loading}>
      <Empty data={data} title={emptyTitle} content={emptyContent}>
        <div className="overflow-hidden">
          <table className="mb-2 lg:mb-0 min-w-full lg:divide-y divide-blue-gray-200">
            <thead className="hidden lg:table-header-group bg-gray-50">
              <tr>
                <th className="pl-4 lg:pl-6 pr-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Date
                </th>
                <th className="px-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Action
                </th>
                <th className="pr-4 lg:pr-6 pl-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody className="bg-white lg:divide-y divide-blue-gray-200" x-max="1">
              {map(data, (item, index) => (
                <tr
                  className={cx(
                    "hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer",
                    { "lg:bg-gray-50": index % 2 != 0 }
                  )}
                  key={index}
                >
                  <td className="px-0 lg:pl-4 lg:pr-2 py-0 lg:py-5 lg:max-w-xxs">
                    <div
                      className={cx(
                        "border lg:border-none border-blue-gray-200 rounded-lg overflow-hidden mx-2",
                        {
                          "mt-2": index > 0,
                        }
                      )}
                    >
                      <div className="px-4 lg:px-0 py-4 lg:py-0 flex items-center justify-between lg:block">
                        <div>
                          <div className="text-sm font-medium leading-5 mb-1 lg:hidden">
                            {get(item, "action", "")}
                          </div>
                          <div className="text-xxs leading-5 text-blue-gray-400 lg:text-sm lg:text-blue-gray-600 lg:whitespace-no-wrap">
                            {get(item, "createdAtStr", "")}
                          </div>
                        </div>
                        <div className="text-sm leading-5 text-blue-gray-600 font-medium lg:hidden">
                          {get(item, "str.quantity", "")}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="hidden lg:table-cell px-2 py-4 lg:whitespace-no-wrap">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "action", "")}
                    </div>
                  </td>
                  <td className="hidden lg:table-cell pl-2 pr-4 lg:pr-6 py-4 lg:whitespace-no-wrap text-right">
                    <div className="text-sm leading-5 text-blue-gray-600">
                    {get(item, "str.quantity", "")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Empty>
    </LoadingWrapper>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyContent: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  loading: false,
  emptyTitle: "",
  emptyContent: "",
};

export default Table;

const LOGIN_REQUEST = "login/request";
const LOGIN_LOADING = "login/loading";
const LOGIN_SUCCESS = "login/success";
const LOGIN_FAILED = "login/failed";
const LOGIN_RESET = "login/reset";

function loginRequest(payload) {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
}

function loginReset() {
  return {
    type: LOGIN_RESET,
  };
}

export {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGIN_FAILED,
  LOGIN_RESET,
  loginRequest,
  loginReset,
};

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";


export class QuotaApiRepo extends BaseApiRepo {
  constructor() {
    super(`${API}/quota`);
  }

  /**
   * quota list
   */
  list(payload) {
    return this.get(payload);
  }
}

import { takeLatest, call, put } from "redux-saga/effects";

import { OfferApiUsecase } from "@core";
import { OFFER_UPDATE_REQUEST, offerUpdateAction } from "./action";

// update offer with market(lease)
export function* watchMarketOfferUpdatePut() {
  yield takeLatest(OFFER_UPDATE_REQUEST, offerUpdatePut);
}

function* offerUpdatePut(action) {
  try {
    const usecase = new OfferApiUsecase();
    const res = yield call([usecase, "update"], action.payload);
    yield put(offerUpdateAction.success(res.data));
  } catch (err) {
    yield put(offerUpdateAction.failure(err));
  }
}

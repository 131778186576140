import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";


import {
  PROFILE_FETCH_REQUEST,
  PROFILE_FETCH_LOADING,
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_FAILED,
} from "./action";

import {
  userProfileAPI,
} from "@config/api";


//fetch profile
export function* watchProfileFetch() {
  yield takeEvery(PROFILE_FETCH_REQUEST, profileFetch);
}

export function* profileFetch(action) {
  yield put({ type: PROFILE_FETCH_LOADING });
  const { err, res } = yield call(profileFetchApi, action.payload, action.params);

  if (!res) {
    yield put({
      type: PROFILE_FETCH_FAILED,
      error: "Fetch profile failed."
    });
    return;
  }
  yield put({ type: PROFILE_FETCH_SUCCESS, data: res });
}

export function profileFetchApi(payload, params) {
  return axios
    .get(userProfileAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import { OFFERS_APPLY_SUCCESS, OFFERS_APPLY_FAILURE, OFFERS_APPLY_RESET } from "./action";

export const offersApplyStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case OFFERS_APPLY_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case OFFERS_APPLY_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case OFFERS_APPLY_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

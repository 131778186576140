import * as AWS from "aws-sdk";
import {currentUserCredentials} from "../cognitoAuth/session";

/**
 * AWS S3 config data
 */
function bucketData() {
  switch (process.env.RAILS_ENV) {
    case "staging":
      return {
        bucketName: "gfresh-staging"
      };
    case "production":
      return {
        bucketName: "gfresh-production"
      };
    default:
      return {
        bucketName: "gfresh-dev"
      }
  }
}

export {
  bucketData
}
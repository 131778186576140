import {
  LISTING_MESSAGE_LOADING,
  LISTING_MESSAGE_SUCCESS,
  LISTING_MESSAGE_FAILED,
  LISTING_MESSAGE_RESET
} from "./messageAction";

export const listingMessageStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LISTING_MESSAGE_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;
    case LISTING_MESSAGE_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case LISTING_MESSAGE_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;

    case LISTING_MESSAGE_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};
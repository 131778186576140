import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

/**
 * import hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * import components
 */
import { Form, Input, formatFixedNumber, formatInt } from "@components/formik";
import { Button } from "@components";

/**
 * [Component] PlaceForm for "edit ask" "edit bid",
 * 一般与一个 ModalWrapper 配合使用
 *
 * @example
  <PlaceForm
    dataOrigin={{
      id:1,
      price: 12.5,
      quantity: 50,
      unitName: "pots"
    }}
    data={{
      price: 12.5,
      quantity: 50,
      unitName: "pots"
    }}
    setPlaceData={() => {

    }}
    loading={true|false}
    onSubmit={() => {
      ... ...
    }}
    loadingDelete={true|false}
    onDelete={() => {
      ...
    }}
  />
 */
const PlaceForm = ({
  dataOrigin,
  data,
  setPlaceData,
  loading,
  onSubmit,
  onDelete,
  loadingDelete,
  usecaseMarket,
}) => {
  const { quantity } = data;
  const { unitOfPrice, dynaUnitName } = useMarketUnitName(
    usecaseMarket,
    quantity
  );
  const validate = useCallback((values) => {
    const errors = {};
    return errors;
  }, []);

  const initialValues = {
    price: dataOrigin.price,
    quantity: dataOrigin.quantity,
  };

  return (
    <div>
      <div className="mb-4">
        <div className="txt text-xl font-medium leading-tight">
          {dataOrigin.title}
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize={true}
      >
        <MyForm
          data={data}
          dynaUnitName={dynaUnitName}
          unitOfPrice={unitOfPrice}
          setPlaceData={setPlaceData}
          onDelete={onDelete}
          loading={loading}
          loadingDelete={loadingDelete}
        />
      </Formik>
    </div>
  );
};

const MyForm = ({
  data,
  dynaUnitName,
  unitOfPrice,
  setPlaceData,
  onDelete,
  loading,
  loadingDelete,
}) => {
  return (
    <Form className="space-y-4">
      <Input
        label="Price"
        name="price"
        placeholder="Enter price"
        className="form-input-lg"
        leftSymbol="$"
        rightSymbol={`per ${unitOfPrice}`}
        parse={formatFixedNumber}
        onChange={(e) => {
          const value = e.target.value || "";
          setPlaceData({
            ...data,
            price: value,
          });
        }}
      />
      <Input
        label="Amount"
        name="quantity"
        placeholder="Quota quantity"
        className="form-input-lg"
        rightSymbol={dynaUnitName}
        parse={formatInt}
        onChange={(evt) => {
          const value = evt.target.value || "";
          setPlaceData({
            ...data,
            quantity: value,
          });
        }}
      />
      <div className="space-y-2 pt-2">
        <Button
          type="submit"
          size="xl"
          color="fish-primary"
          textColor="white"
          className="hover:bg-fish-primary-700"
          full
          loading={loading}
          disabled={loading}
        >
          Update
        </Button>
        {onDelete && (
          <Button
            size="xl"
            color="fish-primary"
            hoverColor="gray-100"
            outline
            full
            loading={loadingDelete}
            disabled={loadingDelete}
            onClick={() => {
              onDelete();
            }}
          >
            Delete
          </Button>
        )}
      </div>
    </Form>
  );
};

PlaceForm.propTypes = {
  usecaseMarket: PropTypes.object,
};

export default PlaceForm;

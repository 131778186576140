import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

//Core Method
import { LoadingSelector } from "@core";
import Notice from "@utils/noticeNew";

//Redux
import {
  fisheryFollowAction,
  FISHERY_FOLLOW,
} from "@redux/modules_v2/fishery/follow/action";

export const useFisheryFollow = (callBack) => {
  const dispatch = useDispatch();

  const fisheryFollowStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERY_FOLLOW, state);
    return Object.assign({}, get(state, "fisheryFollowStatus", {}), {
      loading: selector.loading,
    });
  });

  const onSubmit = useCallback(({ id, following }) => {
    if (!id) {
      return;
    }
    dispatch(fisheryFollowAction.request({ id, following }));
  }, []);

  /**
   * 提交后的effect
   */
  useEffect(() => {
    return () => {
      dispatch(fisheryFollowAction.reset());
    };
  }, []);
  useEffect(() => {
    if (!fisheryFollowStatus.loading) {
      if (fisheryFollowStatus.data) {
        callBack &&
          callBack({
            id: get(fisheryFollowStatus, "data.id", ""),
            following: get(fisheryFollowStatus, "data.following", false),
          });
      }
      if (fisheryFollowStatus.error) {
        Notice.failure(fisheryFollowStatus.error);
      }
    }
  }, [fisheryFollowStatus.loading]);

  return { onSubmit, loading: fisheryFollowStatus.loading };
};

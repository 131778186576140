import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  CHANNEL_MESSAGES_FETCH_REQUEST,
  CHANNEL_MESSAGES_FETCH_LOADING,
  CHANNEL_MESSAGES_FETCH_SUCCESS,
  CHANNEL_MESSAGES_FETCH_FAILED,
} from "./messageListAction";

import {
  channelMessagesAPI,
} from "@config/api";


//fetch channelMessages list
export function* watchChannelMessagesFetch() {
  yield takeEvery(CHANNEL_MESSAGES_FETCH_REQUEST, channelMessagesFetch);
}

export function* channelMessagesFetch(action) {
  yield put({ type: CHANNEL_MESSAGES_FETCH_LOADING });
  const { err, res } = yield call(channelMessagesFetchApi, action.channelId);
  yield call(delay, 1000);
  if (!res) {
    yield put({
      type: CHANNEL_MESSAGES_FETCH_FAILED,
      error: "Fetch channelMessages list failed."
    });
    return;
  }
  yield put({ type: CHANNEL_MESSAGES_FETCH_SUCCESS, data: res });
}

export function channelMessagesFetchApi(channelId) {
  return axios
    .get(channelMessagesAPI(channelId))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

const WALLET_WITHDRAWAL_REQUEST = "account_wallet_withdrawal/request";
const WALLET_WITHDRAWAL_LOADING = "account_wallet_withdrawal/loading";
const WALLET_WITHDRAWAL_SUCCESS = "account_wallet_withdrawal/success";
const WALLET_WITHDRAWAL_FAILED = "account_wallet_withdrawal/failed";
const WALLET_WITHDRAWAL_RECEIVED = "account_wallet_withdrawal/received";

function walletWithdrawalRequest(payload) {
  return {
    type: WALLET_WITHDRAWAL_REQUEST,
    payload,
  };
}

function walletWithdrawalLoading() {
  return {
    type: WALLET_WITHDRAWAL_LOADING,
  };
}

function walletWithdrawalSuccess(data) {
  return {
    type: WALLET_WITHDRAWAL_SUCCESS,
    data,
  };
}

function walletWithdrawalFailed(error) {
  return {
    type: WALLET_WITHDRAWAL_FAILED,
    error,
  };
}

function walletWithdrawalReceived() {
  return {
    type: WALLET_WITHDRAWAL_RECEIVED,
  };
}

export {
  WALLET_WITHDRAWAL_REQUEST,
  WALLET_WITHDRAWAL_LOADING,
  WALLET_WITHDRAWAL_SUCCESS,
  WALLET_WITHDRAWAL_FAILED,
  WALLET_WITHDRAWAL_RECEIVED,
  walletWithdrawalRequest,
  walletWithdrawalLoading,
  walletWithdrawalSuccess,
  walletWithdrawalFailed,
  walletWithdrawalReceived,
};

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class FisheryApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "fisheries";
  }

  /**
   * Fishery Api List
   * @param {object} payload {}
   * GET /api/v1/fisheries
   */
  list(payload) {
    this.setApi(`${API}/${this.model}`);
    return this.get(payload);
  }

  /**
   * Fishery Api stickList
   * @param {object} payload {}
   * GET /api/v1/fisheries?stick=true
   */
  stickList() {
    this.setApi(`${API}/${this.model}?stick=true`);
    return this.get();
  }

  /**
   * Fishery Api Show
   * @param {object} payload {}
   * GET /api/v1/fisheries/:id
   */
  show(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}`);
    return this.get();
  }

  /**
   * Fishery Api Quota Markets
   * @param {object} payload {}
   * GET /api/v1/fisheries/:fishery_id/quota_markets
   */
  quotaMarkets(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/quota_markets`);
    return this.get();
  }

  /**
   * Fishery activity
   * @param {object} payload {}
   * GET /api/v1/fisheries/:id/activity
   */
  activity(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/activity`);
    return this.get();
  }

  /**
   * Fishery existence
   * @param {object} payload {}
   * GET /api/v1/fisheries/existence
   * @return {"fishery_exists": true | false}
   */
  existence() {
    this.setApi(`${API}/${this.model}/existence`);
    return this.get();
  }

  /**
   * Fishery follow
   * @param {object} payload {}
   * POST /api/v1/fisheries/:fishery_id/followings
   */
  follow(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/followings`);
    return this.post();
  }

  /**
   * Fishery unfollow
   * @param {object} payload {}
   * POST /api/v1/fisheries/:fishery_id/unfollowings
   */
  unfollow(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/unfollowings`);
    return this.post();
  }

  /**
   * Fishery following list
   */
  followingList() {
    this.setApi(`${API}/${this.model}/followings`);
    return this.get();
  }

  /**
   * Fishery following quota markets
   */
  followingQuotaMarkets() {
    this.setApi(`${API}/quota_markets/followings`);
    return this.get();
  }

  /**
   * Fishery quota
   * /api/v1/fisheries/:id/quota
   */
  quota(payload = {}) {
    this.setApi(`${API}/${this.model}/${payload.id}/quota`);
    return this.get();
  }

  /**
   * Fishery following auctions
   * /api/v1/auctions/followings
   */
  followingAuctions() {
    this.setApi(`${API}/auctions/followings`);
    return this.get();
  }
}

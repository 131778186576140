export const LISTING_CREATE_POST = "listing/create_post";
export const LISTING_CREATE_LOADING = "listing/create_loading";
export const LISTING_CREATE_SUCCESS = "listing/create_success";
export const LISTING_CREATE_FAILED = "listing/create_failed";
export const LISTING_CREATE_RESET = "listing/create_reset";

export function listingCreatePost(payload = {}) {
  return {
    type: LISTING_CREATE_POST,
    payload
  };
}
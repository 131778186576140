export const MARKET_STATISTICS = "markets/statistics";
export const MARKET_STATISTICS_REQUEST = `${MARKET_STATISTICS}_request`;
export const MARKET_STATISTICS_SUCCESS = `${MARKET_STATISTICS}_success`;
export const MARKET_STATISTICS_FAILURE = `${MARKET_STATISTICS}_failure`;

export const marketStatisticsAction = {
  request: (payload) => {
    return {
      type: MARKET_STATISTICS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: MARKET_STATISTICS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: MARKET_STATISTICS_FAILURE,
      error,
    }
  },
};

import { takeLatest, call, put } from "redux-saga/effects";
import errorString from "@utils/errorString";
import { OrderApiUsecase } from "@core";
import { ORDERS_RECEIVE_FUND_REQUEST, ordersReceiveFundAction } from "./action";

export function* watchOrdersReceiveFund() {
  yield takeLatest(ORDERS_RECEIVE_FUND_REQUEST, ordersReceiveFund);
}

function* ordersReceiveFund(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "receiveFund"], action.payload);
    yield put(ordersReceiveFundAction.success("success"));
  } catch (err) {
    yield put(ordersReceiveFundAction.failure(errorString(err)));
  }
}

import { takeEvery, call, put } from "redux-saga/effects";
import get from "lodash/get";

import errorString from "@utils/errorString";
import { FisheryApiUsecase } from "@core";
import { FISHERY_FOLLOW_REQUEST, fisheryFollowAction } from "./action";

export function* watchfisheryFollowFetch() {
  yield takeEvery(FISHERY_FOLLOW_REQUEST, fisheryFollowFetch);
}

function* fisheryFollowFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    yield call([usecase, "toggleFollow"], action.payload);
    yield put(
      fisheryFollowAction.success({
        id: get(action, "payload.id"),
        following: !get(action, "payload.following", false),
      })
    );
  } catch (err) {
    const errors = get(err, "response.data.errors", {});
    yield put(fisheryFollowAction.failure(errorString(errors)));
  }
}

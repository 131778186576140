import { forEach } from 'lodash'
import { userPool } from './config'
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { userSession, currentUser, currentAuthenticatedUser } from './session'

/**
 * Get attributes of CognitoUser
 * @param user
 * @return {Promise}
 */
function userAttributes(user) {
  return userSession(user)
    .then(session => {
      return new Promise((resolve, reject) => {
        user.getUserAttributes((err, result) => {
          if (err) {
            // console.log(`cognito get attributes failed: ${err}`)
            reject(err)
          } else {
            // console.log(`cognito get attributes success: ${JSON.stringify(attributesToObject(result))}`)
            resolve(attributesToObject(result))
          }
        })
      })
    })
}

/**
 * Get attributes of current user
 * @return {Promise}
 */
function currentUserAttributes() {
  let cognitoUser = currentUser()
  return userAttributes(cognitoUser)
}

/**
 * Update attributes for the current user
 * @param attrs
 * @return {Promise<any>}
 */
function updateCurrentUserAttributes(attrs) {
  return currentAuthenticatedUser().then(user => updateUserAttributes(user, attrs))
}

/**
 * Update attributes for the user
 * @param user
 * @param attrs
 * @return {Promise<any>}
 */
function updateUserAttributes(user, attrs) {
  return new Promise((resolve, reject) => {
    let attributeList = []
    forEach(attrs, (item, key) => {
      let attribute = {
        Name: key,
        Value: item
      }
      attribute = new CognitoUserAttribute(attribute)
      attributeList.push(attribute)
    })

    user.updateAttributes(attributeList, (err, result) => {
      if (err) {
        // console.log(`cognito update attributes failed: ${JSON.stringify(err)}`)
        return reject(err)
      }
      // console.log(`cognito update attributes successful: ${JSON.stringify(result)}`)
      resolve(result)
    })
  })
}

function attributesToObject(attributes) {
  const obj = {}
  attributes.map(attr => {
    obj[attr.Name] = (attr.Value === 'false')? false : attr.Value
  })
  return obj
}

export {
  userAttributes,
  currentUserAttributes,
  updateUserAttributes,
  updateCurrentUserAttributes
}

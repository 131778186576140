export const LISTING_SHOW_FETCH_REQUEST = 'listing/fetch_show_request';
export const LISTING_SHOW_FETCH_LOADING = 'listing/fetch_show_loading';
export const LISTING_SHOW_FETCH_SUCCESS = 'listing/fetch_show_success';
export const LISTING_SHOW_FETCH_FAILED = 'listing/fetch_show_failed';
export const LISTING_SHOW_FETCH_RECEIVED = 'listing/fetch_show_received';
export const LISTING_SHOW_REFRESH = 'listing/refresh_show';

export function fetchListingShow(id) {
  return {
    type: LISTING_SHOW_FETCH_REQUEST,
    payload: {
      id
    }
  };
}

export function refreshListingShow(newData) {
  return {
    type: LISTING_SHOW_REFRESH,
    data: newData
  };
}

export const PERMANENT_DELETE = "account/permanent_delete";
export const PERMANENT_DELETE_REQUEST = `${PERMANENT_DELETE}_request`;
export const PERMANENT_DELETE_SUCCESS = `${PERMANENT_DELETE}_success`;
export const PERMANENT_DELETE_FAILURE = `${PERMANENT_DELETE}_failure`;
export const PERMANENT_DELETE_RECEIVED = `${PERMANENT_DELETE}_received`;

export const permanentDeleteAction = {
  request: (payload) => {
    return {
      type: PERMANENT_DELETE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_DELETE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_DELETE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: PERMANENT_DELETE_RECEIVED
    }
  },
};

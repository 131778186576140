import {
    CART_ASKING_PRICES_FETCH_REQUEST,
    CART_ASKING_PRICES_FETCH_SUCCESS,
    CART_ASKING_PRICES_FETCH_FAILED,
    CART_PRICES_UPDATE_LOADING,
    CART_PRICES_UPDATE_SUCCESS,
    CART_PRICES_UPDATE_FAILED,
    CART_PRICES_DELETE_LOADING,
    CART_PRICES_DELETE_SUCCESS,
    CART_PRICES_DELETE_FAILED
  } from "./askingPricesAuction";
  export const cartAskingPricesStatus = (state = initStatus, action = {}) => {
    switch (action.type) {
      case CART_ASKING_PRICES_FETCH_REQUEST:
        return {
          loading: true,
          data: [],
          error: null,
        };
        break;
  
      case CART_ASKING_PRICES_FETCH_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null,
        };
        break;
  
      case CART_ASKING_PRICES_FETCH_FAILED:
        return {
          loading: false,
          data: [],
          error: action.error,
        };
        break;
  
      default:
        return state;
    }
  };
  
  const initStatus = {
    loading: false,
    data: [],
    error: null,
  };

  export const cartUpdatePricesStatus = (state = {}, action = {}) => {
    switch (action.type) {
      case CART_PRICES_UPDATE_LOADING:
        return {
          loading: true,
          data: null,
          error: null
        };
      case CART_PRICES_UPDATE_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null
        };
      case CART_PRICES_UPDATE_FAILED:
        return {
          loading: false,
          data: null,
          error: action.error
        };
      default:
        return state;
    }
  };

  export const cartDeletePricesStatus = (state = {}, action = {}) => {
    switch (action.type) {
      case CART_PRICES_DELETE_LOADING:
        return {
          loading: true,
          data: null,
          error: null
        };
      case CART_PRICES_DELETE_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null
        };
      case CART_PRICES_DELETE_FAILED:
        return {
          loading: false,
          data: null,
          error: action.error
        };
      default:
        return state;
    }
  };
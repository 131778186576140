import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import capitalize from "lodash/capitalize";

import {
  pricesAPI,
} from "@config/api";

import {
  PRODUCTS_PRICES_FETCH_REQUEST,
  PRODUCTS_PRICES_FETCH_LOADING,
  PRODUCTS_PRICES_FETCH_SUCCESS,
  PRODUCTS_PRICES_FETCH_FAILED
} from "./pricesAction";


//fetch product prices
export function* watchProductPricesFetch() {
  yield takeEvery(PRODUCTS_PRICES_FETCH_REQUEST, productPricesFetch);
}

export function* productPricesFetch(action) {
  yield put({ type: PRODUCTS_PRICES_FETCH_LOADING });

  const { res, err } = yield call(productPricesFetchApi, action.payload);
  if (!res) {
    yield put({
      type: PRODUCTS_PRICES_FETCH_FAILED,
      error: "Get product prices list failed."
    });
    return;
  }
  yield put({ type: PRODUCTS_PRICES_FETCH_SUCCESS, data: res });
}

function productPricesFetchApi(payload) {
  return axios
    .get(pricesAPI(payload))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import React from "react";
import get from "lodash/get";

const InvoiceLink = (props) => {
  const { order, history } = props;
  const isClosed = order.isClosed;
  const isP2POrder = order.isP2POrder;
  const invoiceActive = get(order, "order.market.invoiceActive", false);
  const text = isP2POrder
    ? "Please wait for the invoice to be sent to you later"
    : "View tax invoice";
  if (isClosed || !invoiceActive) {
    return null;
  }
  return (
    <div
      className="cursor-pointer bg-white border-1 border-blue-gray-200 px-4 md:px-6 py-4 md:py-6 rounded-lg flex items-center"
      onClick={() => {
        if (isP2POrder) {
          return;
        }
        history && history.push(order.invoiceUrl);
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0"
      >
        <path
          d="M17 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V13H20V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20ZM16 15V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V15H16ZM4 5V7H12V5H4ZM4 9V11H12V9H4ZM4 13V15H9V13H4Z"
          fill="#A0A6B1"
        />
      </svg>
      <span className="ml-2 font-bold">{text}</span>
    </div>
  );
};

export default InvoiceLink;

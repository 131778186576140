import { takeLatest, call, put } from "redux-saga/effects";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_BID_LIST_REQUEST, permanentBidListAction } from "./action";

// fetch quota list
export function* watchPermanentBidListFetch() {
  yield takeLatest(PERMANENT_BID_LIST_REQUEST, permanentBidListFetch);
}

function* permanentBidListFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "bidList"], action.payload);
    yield put(permanentBidListAction.success(res.data));
  } catch (err) {
    yield put(permanentBidListAction.failure(err));
  } 
}

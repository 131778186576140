import {
  RESEND_CODE_LOADING,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAILED,
  RESEND_CODE_RESET,
} from "./action";

function resendCodeStatus(state = {}, action = {}) {
  switch (action.type) {
    case RESEND_CODE_LOADING:
      return { loading: true, data: null, error: null };
    case RESEND_CODE_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case RESEND_CODE_FAILED:
      return { loading: false, data: null, error: action.error };
    case RESEND_CODE_RESET:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

export { resendCodeStatus };

import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { AuctionsApiUsecase } from "@core";
import { AUCTION_BID_CREATE_REQUEST, auctionBidCreateAction } from "./action";

// Auction create bid
export function* watchAuctionBidCreate() {
  yield takeLatest(AUCTION_BID_CREATE_REQUEST, auctionBidCreate);
}

function* auctionBidCreate(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "createBid"], action.payload);
    const data = get(res, "data", {});
    yield put(auctionBidCreateAction.success(data));
  } catch (err) {
    yield put(auctionBidCreateAction.failure(errorString(err)));
  }
}

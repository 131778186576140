import { QuotaAttrListApiRepo } from "@core/index";

export class QuotaAttrApiUsecase {
  constructor() {
    this.repo = new QuotaAttrListApiRepo();
  }

  /**
   * Categories
   */
  categories() {
    return this.repo.getCategories();
  }

  /**
   * Species
   * @param {Object} payload {category_id: xxx, term: xxx}
   */
  species(payload = {}) {
    return payload.category_id
      ? this.repo.getSpeciesByCategories(payload)
      : this.repo.getSpeciesByTerm(payload);
  }

  /**
   * Countries
   */
  countries() {
    return this.repo.getCountries();
  }

  /**
   * States
   * @param {Object} payload {country_id: XXX}
   */
  states(payload = {}) {
    return this.repo.getStates(payload);
  }

  /**
   * Category including category
   */
  categoryByTerm(payload = {}) {
    return this.repo.getCategoryByTerm(payload)
  }
}

import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isPristine } from "redux-form";

import { get } from "lodash";

/**
 * import local tools & config & redux
 */
import { LoadingSelector } from "@core";
import Notice from "@utils/noticeNew";

import {
  licenceUpdateAction,
  LICENCE_UPDATE,
} from "@redux/modules_v2/licences/update/action";
import { orderShowAction } from "@redux/modules_v2/orders/show/action";

export const useLicenceUpdate = (order, success) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getPristine = isPristine("LicenceNumberForm");
  const pristine = useSelector((state) => getPristine(state));

  const submit = useCallback(
    (values) => {
      if (pristine) {
        success && success();
        return;
      }
      const payload = Object.assign({}, { orderId: order.id }, values);
      dispatch(licenceUpdateAction.request(payload));
    },
    [pristine, order.id, success]
  );

  const licenceUpdateStatus = useSelector((state) => {
    const selector = new LoadingSelector(LICENCE_UPDATE, state);
    return Object.assign({}, get(state, "licenceUpdateStatus", {}), {
      loading: selector.loading,
    });
  });
  const loadingUpdateLicence = licenceUpdateStatus.loading;

  useEffect(() => {
    if (licenceUpdateStatus.justFinished) {
      if (!licenceUpdateStatus.loading && !licenceUpdateStatus.error) {
        Notice.success("Licence updated successfully.");
        dispatch(orderShowAction.request({ id: order.id }));
        success && success();
      }

      if (!licenceUpdateStatus.loading && licenceUpdateStatus.error) {
        Notice.failure(
          get(licenceUpdateStatus, "error", "Licence update error.")
        );
      }

      dispatch(licenceUpdateAction.received());
    }
  }, [licenceUpdateStatus.justFinished]);

  useEffect(() => {
    return () => {
      dispatch(licenceUpdateAction.received());
    };
  }, []);

  return {
    submit,
    loadingUpdateLicence,
  };
};

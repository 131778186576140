import Trix from "trix/dist/trix";
/**
 * Config Trix
 */

const trixConfig = () => {
  /**
   * 添加新的tag，h2,h3,h4,h5,h6,u
   */
  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
  };
  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
  };
  Trix.config.blockAttributes.heading4 = {
    tagName: "h4",
    terminal: true,
    breakOnReturn: true,
    group: false
  };
  Trix.config.blockAttributes.heading5 = {
    tagName: "h5",
    terminal: true,
    breakOnReturn: true,
    group: false
  };
  Trix.config.blockAttributes.heading6 = {
    tagName: "h6",
    terminal: true,
    breakOnReturn: true,
    group: false
  };
  Trix.config.blockAttributes.underline = {
    tagName: "u",
    terminal: true,
    breakOnReturn: true,
    group: false
  };
};

export default trixConfig;

import React from "react";

import { Panel } from "@components";
import { useResponsive } from "@hooks";
import FormPc from "./formPc";
import FormMob from "./formMob";

/**
 * 搜索表单
 * @param {Object} props {className,url, autoSubmit }
 * className: 样式
 * url: 跳转的搜索结果页面的url
 * autoSubmit: 是否自动提交
 */

const SearchForm = (props) => {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return <FormMob {...props} />;
  }

  return (
    <Panel
      classNameWrapper="mt-10"
      className="pr-3-tw pl-5-tw py-1 border-1 border-blue-gray-200"
    >
      <FormPc {...props} />
    </Panel>
  );
};

export default SearchForm;
import { takeEvery, all, call, put } from "redux-saga/effects";
import { verifyCurrentUserEmailVerificationCode } from "@utils/cognitoAuth";

import {
  VERIFY_EMAIL_CONFIRMATION_CODE_FAILED,
  VERIFY_EMAIL_CONFIRMATION_CODE_SUCCESS,
  VERIFY_EMAIL_CONFIRMATION_CODE_LOADING,
  VERIFY_EMAIL_CONFIRMATION_CODE_REQUEST,
  verifyEmailConfirmationCodeSuccess,
  verifyEmailConfirmationCodeFailed,
  verifyEmailConfirmationCodeLoading,
} from "./action";

import { refreshCurrentUser } from "../auth/action";

function* watchVerifyEmailConfirmationCodeRequest() {
  yield takeEvery(
    VERIFY_EMAIL_CONFIRMATION_CODE_REQUEST,
    verifyEmailConfirmationCodeRequest
  );
}

function* verifyEmailConfirmationCodeRequest(action) {
  yield put(verifyEmailConfirmationCodeLoading());
  let { res, err } = yield call(
    verifyEmailConfirmationCodeCall,
    action.payload
  );

  if (err) {
    yield put(verifyEmailConfirmationCodeFailed(err));
    return;
  }
  yield all([
    put(verifyEmailConfirmationCodeSuccess(res)),
    put(refreshCurrentUser()),
  ]);
}

function verifyEmailConfirmationCodeCall({ code }) {
  return verifyCurrentUserEmailVerificationCode(code)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export { watchVerifyEmailConfirmationCodeRequest };

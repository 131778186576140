import React, { useState } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

/**
 * import lib components
 */
import { Button, LoadingWrapper } from "@components";
import { Form, Select, DateSelect } from "@components/formik";
import Table from "./_table";

/**
 * Searching Form Mobile
 */
const StatementForm = ({ onSubmit, data, total, loading, initialValues }) => {
  const [role, setRole] = useState("");

  const customStyle = {
    input: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided) => {
      return {
        ...provided,
        ":active": {
          ...provided[":active"],
          color: "#FFFFFF",
        },
        ":hover": {
          ...provided[":hover"],
          color: "#FFFFFF",
        },
        ":focus": {
          ...provided[":focus"],
          color: "#FFFFFF",
        },
      };
    },
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#3f83f8",
      primary50: "#3f83f8",
      primary: "#3f83f8",
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        const role = get(values, "role", "");
        setRole(role);
        onSubmit(values);
      }}
    >
      {({ values }) => {
        const isEmptyRole = !get(values, "role", "");
        const dateRange = get(values, "date_range", "");
        const startTime = get(values, "start_t", {});
        const endTime = get(values, "end_t", {});
        return (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6 flex-1 mb-4">
              <Select
                name="role"
                label="Order Type"
                placeholder="Select orders"
                styles={customStyle}
                theme={customTheme}
                options={[
                  { label: "Purchases", value: "buyer" },
                  { label: "Sales", value: "seller" },
                ]}
                isSearchable={false}
              />
              <Select
                name="date_range"
                label="Date range"
                placeholder="Date range"
                styles={customStyle}
                theme={customTheme}
                options={[
                  { label: "Most recent", value: 1 },
                  { label: "Last 3 months", value: 3 },
                  { label: "Last 9 months", value: 9 },
                  { label: "Last 12 months", value: 12 },
                  { label: "Custom", value: -1 },
                ]}
                isSearchable={false}
              />
              <div className="md:col-span-2 md:flex items-end space-y-4 md:space-y-0 md:space-x-6">
                {dateRange === -1 && (
                  <>
                    <DateSelect
                      label="Start"
                      name="start_t"
                      placeholder="Start"
                      classNameWrapper="flex-1"
                      isValidDate={(current) => {
                        if (isEmpty(endTime)) {
                          return current.isSameOrBefore(moment());
                        }
                        return current.isBefore(endTime);
                      }}
                      timeFormat={false}
                    />
                    <DateSelect
                      label="End"
                      name="end_t"
                      placeholder="End"
                      classNameWrapper="flex-1"
                      isValidDate={(current) => {
                        if (isEmpty(startTime)) {
                          return current.isSameOrBefore(moment());
                        }
                        return current.isBetween(startTime, moment());
                      }}
                      timeFormat={false}
                    />
                  </>
                )}
                <Button color="fish-primary" type="submit" disabled={isEmptyRole}> 
                  Search
                </Button>
              </div>
            </div>
            <LoadingWrapper loading={loading}>
              <div className="mt-6 md:mt-8">
                <Table
                  data={data}
                  total={total}
                  role={role}
                />
              </div>
            </LoadingWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

StatementForm.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  initialValues: PropTypes.object,
  total: PropTypes.object,
};

StatementForm.defaultProps = {
  onSubmit: () => {},
  data: [],
  total: {},
  loading: false,
  initialValues: {},
};

export default StatementForm;

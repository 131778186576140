import { get } from "lodash";
import { Moment } from "@core";
import numeral from "@utils/numeral";
import { PaymentEntity } from "./paymentEntity";
import { WithdrawalEntity } from "./withdrawalEntity";
import { OrderEntity } from "./orderEntity";

/**
 * [Entity] Wallet
 * data is obj
  1. 充值:
  {
    "id": 408,
    "sheet_title": "user charge",
    "entry_type": "debit",
    "amount": "50000.0",
    "balance_cache": "100000.0",
    "created_at": "2020-03-20T14:09:33+11:00",
    "trackable_type": "Payment",
    "trackable_id": 91,
    "trackable": {
      "id": 91,
      "order_id": null,
      "code": "2003204053_development",
      "state": "completed",
      "payment_method_type": "Payment::Stripe",
      "created_at": "2020-03-20T14:09:04.863+11:00",
      "updated_at": "2020-03-20T14:09:33.922+11:00",
      "paid_amount": "50,935.00",
      "paid_amount_with_symbol": "$50,935.00",
      "transaction_amount": "50,000.00",
      "transaction_amount_with_symbol": "$50,000.00",
      "transaction_fees": "935.00",
      "transaction_fees_with_symbol": "$935.00",
      "transaction_original_fees": "0.00",
      "transaction_origianl_fees_with_symbol": "$0.00",
      "transaction_gst_fees": "0.00",
      "transaction_gst_fees_with_symbol": "$0.00"
    }
  }

  2 提现:
  {
    "id": 413,
    "sheet_title": "user withdraw",
    "entry_type": "credit",
    "amount": "200.0",
    "balance_cache": "99800.0",
    "created_at": "2020-03-20T15:02:34+11:00",
    "trackable_type": "WalletWithdraw",
    "trackable_id": 17,
    "trackable":
    {
      "id": 17,
      "amount":
      {
        "fractional": "20000.0",
        "currency":
        {
          "id": "usd",
          "alternate_symbols": ["US$"],
          "decimal_mark": ".",
          "disambiguate_symbol": "US$",
          "html_entity": "$",
          "iso_code": "USD",
          "iso_numeric": "840",
          "name": "United States Dollar",
          "priority": 1,
          "smallest_denomination": 1,
          "subunit": "Cent",
          "subunit_to_unit": 100,
          "symbol": "$",
          "symbol_first": true,
          "thousands_separator": ","
        },
        "bank":
        {
          "store":
          {
            "index":
            {},
            "options":
            {},
            "mutex":
            {},
            "in_transaction": false
          },
          "rounding_method": null
        }
      },
      "bank_name": null,
      "bank_bsb": "456789456789",
      "account_name": "LucienWang",
      "account_number": "111222333",
      "state": "processing"
    }
  }

  3. 订单 order

  {
    "id": 1318,
    "sheet_title": "user sold",
    "entry_type": "debit",
    "amount": "506.0",
    "balance_cache": "301604.9",
    "created_at": "2020-04-02T15:34:03+11:00",
    "trackable_type": "Order",
    "trackable_id": 3253,
    "trackable":
    {
      "id": 3253,
      "boxes_count": 100.0,
      "created_at": "2020-04-02T15:30:33.322+11:00",
      "updated_at": "2020-04-02T15:34:03.845+11:00",
      "class": "BidOfferPrice",
      "origin_id": 132,
      "sell_type": null,
      "title": null,
      "fishing_season": null,
      "serial_number": "202004020187",
      "point_consumed": 0,
      "unit": null,
      "unit_name": null,
      "unit_weight": null,
      "state": null,
      "paid_state": "completed",
      "close_reason": null,
      "expired_at": "2020-04-02T16:00:33.089+11:00",
      "payment":
      {
        "paid_amount":
        {
          "humanized_money": 3721.98,
          "humanized_money_with_symbol": "$3,721.98"
        },
        "transaction_amount":
        {
          "humanized_money": 3653.65,
          "humanized_money_with_symbol": "$3,653.65"
        },
        "transaction_fees":
        {
          "humanized_money": 68.33,
          "humanized_money_with_symbol": "$68.33"
        },
        "transaction_original_fees":
        {
          "humanized_money": 62.12,
          "humanized_money_with_symbol": "$62.12"
        },
        "transaction_gst_fees":
        {
          "humanized_money": 6.21,
          "humanized_money_with_symbol": "$6.21"
        },
        "payment_method_type": "Payment::Stripe"
      },
      "payments": [],
      "completed_payment":
      {
        "paid_amount":
        {
          "humanized_money": 3653.65,
          "humanized_money_with_symbol": "$3,653.65"
        },
        "transaction_amount":
        {
          "humanized_money": 3653.65,
          "humanized_money_with_symbol": "$3,653.65"
        },
        "transaction_fees":
        {
          "humanized_money": 0,
          "humanized_money_with_symbol": "$0.00"
        },
        "transaction_original_fees":
        {
          "humanized_money": 0,
          "humanized_money_with_symbol": "$0.00"
        },
        "transaction_gst_fees":
        {
          "humanized_money": 0,
          "humanized_money_with_symbol": "$0.00"
        },
        "payment_method_type": "Payment::Wallet"
      },
      "url": null,
      "price":
      {
        "humanized_money": 4.6,
        "humanized_money_with_symbol": "$4.60"
      },
      "invoice": "sent",
      "marketable": null,
      "marketable_type": "QuotaMarket",
      "items": [],
      "buyer":
      {
        "id": 17,
        "email": "vickie+95@gfresh.io",
        "picture": null,
        "name": "Vickie 95",
        "company_name": "vickie95",
        "is_deposit": true,
        "remember_account": false,
        "is_me": false
      },
      "seller":
      {
        "id": 4,
        "email": "vickie+78@gfresh.io",
        "picture": null,
        "name": "vickie 78",
        "company_name": "Vickie78",
        "is_deposit": true,
        "remember_account": true,
        "is_me": true
      },
      "order_type": "sell",
      "sub_total_price":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "sub_total_price_with_gst":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "total_including_gst_price":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "total_price":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "real_price":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "gst":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "transfer_fee":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "fees":
      {
        "title": "Total service fee (Incl. GST)",
        "value": 0,
        "value_with_gst": 0.0
      }
    }
  }
 */
export class WalletEntity {
  constructor(data) {
    this.entity = data;
  }

  trackable() {
    switch (this.trackableType) {
      case "WalletWithdraw":
        return new WithdrawalEntity(get(this.entity, "trackable"));

      case "Payment":
        return new PaymentEntity(get(this.entity, "trackable"));

      case "Order":
        return new OrderEntity(get(this.entity, "trackable"));

      default:
        return null;
    }
  }

  generateData() {
    const obj = this.trackable();
    if (obj) {
      return {
        title: obj.walletTitle(this.sheetTitle, this.entryType),
        orderSerialNumber: obj.orderSerialNumber,
        orderId: obj.orderId,

        showFees: obj.showFees(this.sheetTitle),
        fees: obj.fees,
        tipFees: obj.tipFees,
        tipTotal: obj.tipTotal,

        showCancel: obj.showCancel,
      };
    }
    return {
      title: "",
      orderSerialNumber: "",
      orderId: "",

      showFees: false,
      fees: "",
      tipFees: "",
      tipTotal: "",

      showCancel: false,
    };
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get sheetTitle() {
    return get(this.entity, "sheet_title", "");
  }

  get entryType() {
    return get(this.entity, "entry_type", ""); // "debit" | "credit",
  }

  get amount() {
    return get(this.entity, "amount", "");
  }

  get amountStr() {
    return numeral(this.amount).format();
  }

  get amountWithoutFee() {
    if (this.trackableType === "Payment") {
      return numeral(
        get(this.entity, "trackable.transaction_amount_with_symbol", 0)
      ).value();
    }
    return 0;
  }

  get amountWithoutFeeStr() {
    return numeral(this.amountWithoutFee).format();
  }

  get entrySymbol() {
    return this.entryType === "debit" ? "+" : "-";
  }

  get balanceCache() {
    return get(this.entity, "balance_cache", "");
  }

  get balanceCacheStr() {
    return numeral(this.balanceCache).format();
  }

  get createAt() {
    return new Moment(get(this.entity, "created_at"));
  }

  get createAtStr() {
    return this.createAt.format || "";
  }

  get trackableType() {
    return get(this.entity, "trackable_type", "");
  }
}

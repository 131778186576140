import {
  TIMESTAMP_FETCH_REQUEST,
  TIMESTAMP_FETCH_SUCCESS,
  TIMESTAMP_FETCH_FAILED
} from "./action";

export const timestampStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case TIMESTAMP_FETCH_REQUEST:
      return {
        loading: true,
        data: {},
        error: null
      };

    case TIMESTAMP_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };

    case TIMESTAMP_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error
      };
    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null
};


export const PRICEABLES_CATEGORY_FETCH_REQUEST = "priceables/fetch_category_request";
export const PRICEABLES_CATEGORY_FETCH_LOADING = "priceables/fetch_category_loading";
export const PRICEABLES_CATEGORY_FETCH_SUCCESS = "priceables/fetch_category_success";
export const PRICEABLES_CATEGORY_FETCH_FAILED = "priceables/fetch_category_failed";


export function fetchPriceablesCategoryList(category_id, params) {
  return {
    type: PRICEABLES_CATEGORY_FETCH_REQUEST,
    payload: {
      category_id,
      params,
    },
  };
}

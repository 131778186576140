import moment from "moment";
import get from "lodash/get";
import {
  AUCTIONS_SHOW_LOADING,
  AUCTIONS_SHOW_SUCCESS,
  AUCTIONS_SHOW_FAILED,
  AUCTIONS_SHOW_WAIT_RULING,
  AUCTIONS_SHOW_END
} from "./showAction";

export const auctionsShowStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTIONS_SHOW_LOADING:
      return {
        loading: true,
        data: {},
        error: null
      };

    case AUCTIONS_SHOW_SUCCESS:
      const isEnd = moment().isAfter(get(action, "data.expire_at"), "second");
      return {
        loading: false,
        data: Object.assign({}, action.data, {
          isEnd,
          waitRuling: false
        }),
        error: null
      };

    case AUCTIONS_SHOW_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error
      };

    case AUCTIONS_SHOW_WAIT_RULING:
      return {
        loading: false,
        data: Object.assign({}, state.data, { waitRuling: true, isEnd: false }),
        error: null
      };
   
    case AUCTIONS_SHOW_END:
      return {
        loading: false,
        data: Object.assign({}, state.data, { waitRuling: false, isEnd: true }),
        error: null
      };
    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null
};

export const ASKING_PRICES_SHOW_FETCH_REQUEST = "asking_prices/fetch_show_request";
export const ASKING_PRICES_SHOW_FETCH_LOADING = "asking_prices/fetch_show_loading";
export const ASKING_PRICES_SHOW_FETCH_SUCCESS = "asking_prices/fetch_show_success";
export const ASKING_PRICES_SHOW_FETCH_FAILED = "asking_prices/fetch_show_failed";
export const ASKING_PRICES_SHOW_FETCH_RECEIVED = "asking_prices/fetch_show_received";
export const ASKING_PRICES_SHOW_REFRESH = "asking_prices/show_refresh";


export function fetchAskingPriceShow(payload) {
  return {
    type: ASKING_PRICES_SHOW_FETCH_REQUEST,
    payload
  };
}

export function refreshAskingPriceShow(newData) {
  return {
    type: ASKING_PRICES_SHOW_REFRESH,
    data: newData,
  };
}
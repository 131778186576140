import React from "react";
/**
 * import local component
 */
import { Button } from "@components";

/**
 * [Component] Account wallet balance 个人中心钱包页面：余额部分
 */
const Balance = props => {
  const { profile, data } = props;
  return (
    <div className="rounded-lg-tw bg-white shadow">
      <div className="p-4 md:p-6 pt-6">
        <div className="">
          <div>
            <h3>
              Wallet Balance
            </h3>
            <div className="mt-4 text-3xl font-bold leading-tight md:text-3xl">
              {data.balance}
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
          <Button
            href="/account/withdrawal"
            color="gray-300"
            textColor="gray-900"
            size="xl"
            outline
            full
            className="hover:bg-gray-50 shadow"
          >
            Withdraw
          </Button>
          <Button
            href="/account/topup"
            color="fish-primary"
            size="xl"
            full
            className="hover:bg-fish-primary-700 shadow"
          >
            Deposit Funds
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Balance;

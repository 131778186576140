import { get } from "lodash";
import { Moment } from "@core";
import urlGenerator from "@utils/urlGenerator";

/**
 * [Entity] Category
 * data is obj
  {
    "id": 1,
    "name": "Lobster",
  }
 */
export class CategoryEntity {
  constructor(data) {
    this.entity = data;
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get name() {
    return get(this.entity, "name", "");
  }

  url(country = "", state = "", stateId = "") {
    if (state && stateId) {
      return urlGenerator(`/${country}/c/${this.name}/${state}/${stateId}`); // => "/au/c/Abalone/tas/6" 6 是 state id
    }
    return urlGenerator(`/${country}/c/${this.name}/${this.id}`); // => "/au/c/Abalone/2" 2 是 category id
  }
}
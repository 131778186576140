import React from "react";
import PropTypes from "prop-types";

import { Radio, Select } from "@components/formik";

import { useFisheryList } from "@hooks";

import Transition from "./_transition";

const FirstStep = ({ values }) => {
  const { options } = useFisheryList({});
  const { fishery_id, selling } = values;
  return (
    <div>
      <h2 className="mt-20 mb-12 text-2xl md:text-3xl text-center text-fish-dark font-bold leading-snug">
        What are you looking to sell?
      </h2>
      <div className="md:grid gap-4 grid-cols-1">
        <Radio
          name="selling"
          className="space-y-4"
          radioClassName="fish-primary rounded-md border border-blue-gray-300 bg-white p-3 items-center"
          label="What are you selling?"
          options={[
            {
              label: <div className="block text-sm">Permanent quota units</div>,
              value: "Permanent quota units",
            },
            {
              label: (
                <div className="block text-sm">Temporary seasonal quota</div>
              ),
              value: "Temporary seasonal quota",
            },
            {
              label: (
                <div className="block text-sm">
                  Fishing license or entiltlment
                </div>
              ),
              value: "Fishing license or entiltlment",
            },
          ]}
        />
        <Transition values={{ selling }}>
          <Select
            label="Which fishery does your asset fall under?"
            name="fishery_id"
            options={options}
            className="form-select-lg"
          />
        </Transition>
        <Transition values={{ selling, fishery_id }}>
          <Radio
            name="interested"
            className="space-y-4"
            radioClassName="fish-primary rounded-md border border-blue-gray-300 bg-white p-3 items-center"
            label="What type of sale are you interested in?"
            options={[
              {
                label: (
                  <div className="block text-sm">
                    Public fixed price or auction listing
                  </div>
                ),
                value: "Public fixed price or auction listing",
              },
              {
                label: <div className="block text-sm">Consignment</div>,
                value: "Consignment",
              },
              {
                label: <div className="block text-sm">Private sale</div>,
                value: "Private sale",
              },
            ]}
          />
        </Transition>
      </div>
    </div>
  );
};

FirstStep.propTypes = {
  values: PropTypes.object,
};

export default FirstStep;

import { useState, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import numeral from "@utils/numeral";
import Notice from "@utils/noticeNew";
import {
  offerUpdateAction,
  OFFER_UPDATE,
} from "@redux/modules_v2/offers/update/action";
import {
  bidUpdateAction,
  BID_UPDATE,
} from "@redux/modules_v2/bids/update/action";

/**
 * [hooks] place bid/ask update
 *
 * @param {Function} success 成功后的回调函数
 */
export const useBidOfferEdit = (success) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [placeOrigin, setPlaceOrigin] = useState({});
  const [place, setPlace] = useState({});

  /**
   * 更新 seller 报价(ask)
   */
  const updateOffer = () => {
    const marketId = placeOrigin.marketId;

    const postData = {
      marketId,
      offerId: place.id,
      offer: {
        price_cents: numeral(place.price).multiply(100).value(),
        quantity: numeral(place.quantity).value(),
      },
    };

    dispatch(offerUpdateAction.request(postData));
  };

  /**
   * 更新 buy 报价(bid)
   */
  const updateBid = () => {
    const marketId = placeOrigin.marketId;

    const postData = {
      marketId,
      bidId: place.id,
      bid: {
        price_cents: numeral(place.price).multiply(100).value(),
        quantity: numeral(place.quantity).value(),
      },
    };

    dispatch(bidUpdateAction.request(postData));
  };

  /**
   * 提交 place 表单
   */
  const submit = () => {
    if (placeOrigin && !placeOrigin.id) {
      return;
    }
    if (placeOrigin && !placeOrigin.marketId) {
      return;
    }

    if (place && !place.price) {
      return;
    }
    if (place && place.price <= 0) {
      return;
    }

    switch (placeOrigin.bidOfferType) {
      case "offer":
        updateOffer();
        return;
        break;

      case "bid":
        updateBid();
        return;
        break;

      default:
        break;
    }
  };

  /**
   * 监听报价创建
   * offerUpdateStatus 卖家报价更新
   * bidUpdateStatus   买家报价更新
   */
  const offerUpdateStatus = useSelector((state) => {
    const selector = new LoadingSelector(OFFER_UPDATE, state);
    return Object.assign({}, get(state, "offerUpdateStatus", {}), {
      loading: selector.loading,
    });
  });
  const bidUpdateStatus = useSelector((state) => {
    const selector = new LoadingSelector(BID_UPDATE, state);
    return Object.assign({}, get(state, "bidUpdateStatus", {}), {
      loading: selector.loading,
    });
  });
  const loadingUpdateOffer = offerUpdateStatus.loading;
  const loadingUpdateBid = bidUpdateStatus.loading;

  useEffect(() => {
    if (offerUpdateStatus.justFinished) {
      if (!offerUpdateStatus.loading && !offerUpdateStatus.error) {
        Notice.success("You have successfully updated an ask!");
        success && success();
      }
      if (!offerUpdateStatus.loading && offerUpdateStatus.error) {
        Notice.failure(offerUpdateStatus.error || "Update Failed");
      }
      dispatch(offerUpdateAction.received());
    }
  }, [offerUpdateStatus.justFinished]);
  useEffect(() => {
    if (bidUpdateStatus.justFinished) {
      if (!bidUpdateStatus.loading && !bidUpdateStatus.error) {
        Notice.success("You have successfully updated a bid!");
        success && success();
      }
      if (!bidUpdateStatus.loading && bidUpdateStatus.error) {
        Notice.failure(bidUpdateStatus.error || "Update Failed");
      }
      dispatch(bidUpdateAction.received());
    }
  }, [bidUpdateStatus.justFinished]);

  return {
    place,
    setPlace,
    placeOrigin,
    setPlaceOrigin,
    submit,
    loadingUpdateOffer,
    loadingUpdateBid,
  };
};

import { takeEvery, call, put } from "redux-saga/effects";
import axios from "axios";
import get from "lodash/get";
import errorString from "@utils/errorString";
import {
  RECHARGE_DEPOSIT_REQUEST,
  rechargeDepositFailed,
  rechargeDepositLoading,
  rechargeDepositSuccess,
} from "./action"; 
import { rechargeDepositAPI } from "@config/api";

function* watchRechargeDepositRequest() {
  yield takeEvery(RECHARGE_DEPOSIT_REQUEST, rechargeDeposit);
}

function* rechargeDeposit(action) {
  yield put(rechargeDepositLoading());
  let { res, err } = yield call(rechargeDepositCall, action.payload);

  if (err) {
    const errors = get(err, "response.data.errors", {});
    return yield put(rechargeDepositFailed(errorString(errors)));
  }
  yield put(rechargeDepositSuccess(res));
}

function rechargeDepositCall({ token }) {
  return axios
    .post(rechargeDepositAPI(), { token })
    .then((res) => {
      return { res: res.data };
    })
    .catch((err) => {
      return { err };
    });
}

export { watchRechargeDepositRequest };

export const PERMANENT_NEW = "permanent/new";
export const PERMANENT_NEW_REQUEST = `${PERMANENT_NEW}_request`;
export const PERMANENT_NEW_SUCCESS = `${PERMANENT_NEW}_success`;
export const PERMANENT_NEW_FAILURE = `${PERMANENT_NEW}_failure`;
export const PERMANENT_NEW_RESET = `${PERMANENT_NEW}_reset`;

export const permanentNewAction = {
  request: (payload) => {
    return {
      type: PERMANENT_NEW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_NEW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_NEW_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: PERMANENT_NEW_RESET
    }
  }
};

import React, { useEffect } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";

/**
 * import local tools & hooks & components
 */
import { useGlobalStatic } from "@hooks";
import Notice from "@utils/noticeNew";
import getQueryString from "@utils/getUrlQuery";
import Layout from "@views/layout";
import { Modal, ModalButton, useModal } from "@components";

import Form from "./_form";
/** 
 * import local components
 */
import { useUpdateLicenceHook, useFetchLicenceHook } from "./_hook";

const LicenceNumber = (props) => {
  const { history } = props;
  const dispatch = useDispatch();

  const { data: staticData } = useGlobalStatic();
  const [initialValues] = useFetchLicenceHook();
  const [
    handleSubmit,
    setJustFinished,
    jsutFinished,
    loading,
    res,
    err,
  ] = useUpdateLicenceHook();

  useEffect(() => {
    if (jsutFinished) {
      if (!loading) {
        if (res) {
          Notice.success("Submit successfully!");
          setTimeout(() => {
            const orderId = getQueryString("order_id");
            const url = orderId ? `/orders/${orderId}` : "/dashboard";
            history && history.push(url);
          }, 1000);
        }
        if (err) {
          Notice.failure("Update failed");
        }
      }
      setJustFinished(false);
    }
  }, [jsutFinished]);

  const [modalProps] = useModal(
    {
      content: (
        <div className="text-left">
          <div className="mb-5">
            <h3>
              {get(staticData, "licence_number.title", "")}
            </h3>
          </div>
          <div className="">
            <div>{get(staticData, "licence_number.text", "")}</div>
            <div className="py-6">
              <hr />
            </div>
            <Form
              onSubmit={handleSubmit}
              enableReinitialize={true}
              loading={loading}
              initialValues={initialValues}
              staticData={staticData}
            />
          </div>
        </div>
      ),
      buttonAlign: "right",
      buttons: [
        <ModalButton
          label={loading ? "Submiting ..." : "Submit"}
          key={`modal-button-2`}
          disabled={loading}
          loading={loading}
          isStylePrimary
          onClick={() => {
            dispatch(submit("LicenceNumberForm"));
          }}
        />
      ],
    },
    true
  );

  return (
    <Layout>
      <Modal {...modalProps} />
    </Layout>
  );
};

export default LicenceNumber;

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";

import {
 countriesListAction,
 COUNTRIES_LIST
} from "@redux/modules_v2/quotaAttr/countries/action";

/**
 * Fetch list hooks
 * @param {*} param
 */
export const useCountriesFetchList = () => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(countriesListAction.request());
  };

  const countriesListStatus = useSelector(state => {
    const selector = new LoadingSelector(COUNTRIES_LIST, state);
    return Object.assign({}, get(state, "quotaAttrStatus.countriesListStatus", {}), {
      loading: selector.loading
    });
  });

  const { data, loading } = countriesListStatus;
  /**
   * did mount fetch list
   */
  useEffect(() => {
    fetchList();
  }, []);

  return [data, loading];
};

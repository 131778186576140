import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  CATEGORIES_FETCH_REQUEST,
  CATEGORIES_FETCH_FAILED,
  CATEGORIES_FETCH_LOADING,
  CATEGORIES_FETCH_SUCCESS,
} from "./listAction";

import {
  categoriesAPI,
} from "@config/api";


//fetch categories list
export function* watchCategoriesFetch() {
  yield takeEvery(CATEGORIES_FETCH_REQUEST, categoriesFetch);
}

export function* categoriesFetch(action) {
  yield put({ type: CATEGORIES_FETCH_LOADING });
  const { err, res } = yield call(categoriesFetchApi, action.payload);
  if (!res) {
    yield put({
      type: CATEGORIES_FETCH_FAILED,
      error: "Get category list failed."
    });
    return;
  }
  yield put({ type: CATEGORIES_FETCH_SUCCESS, data: res });
}

export function categoriesFetchApi(params) {
  return axios
    .get(categoriesAPI(null, params))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

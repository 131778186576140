import { takeEvery, all, call, put } from "redux-saga/effects";
import { changeCurrentUserPassword, refreshSession } from "@utils/cognitoAuth";
import { SubmissionError } from "redux-form";

import {
  CHANGE_PASSWORD_REQUEST,
  changePasswordSuccess,
  changePasswordLoading,
  changePasswordFailed,
} from "./action";
import { refreshCurrentUser } from "../auth/action";
import { changePasswordEventRequest } from "../changePasswordEvent/action";

function* watchChangePasswordRequest() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePassword);
}

function* changePassword(action) {
  const {
    payload: { resolve, reject },
  } = action;

  yield put(changePasswordLoading());

  let { res, err } = yield call(changePasswordSubmit, action.payload);

  if (err) {
    let errors = Object.assign({}, err);
    let match;

    // match = err.message.match(
    //   /Value at 'previousPassword' failed to satisfy constraint: Member (.+?)(;|$)/
    // );
    // if (match) {
    //   errors = Object.assign(errors, { password_current: match[1] });
    // }
    // match = err.message.match(
    //   /Value at 'proposedPassword' failed to satisfy constraint: Member (.+?)(;|$)/
    // );
    // if (match) {
    //   errors = Object.assign(errors, { password: match[1] });
    // }
    match = err.message.match(/Incorrect username or password/);
    if (match) {
      errors = Object.assign(errors, {
        message: "Incorrect current password",
      });
    }

    yield put(changePasswordFailed(errors));
    return;
  }
  let { res: refreshRes, err: resfreshErr } = yield call(refreshSessionReq);

  if (resfreshErr) {
    reject(new SubmissionError(resfreshErr));
    yield put(changePasswordFailed(resfreshErr));
    return;
  }

  resolve(res);

  yield all([
    put(changePasswordSuccess("SUCCESS")),
    put(refreshCurrentUser()),
    put(changePasswordEventRequest()),
  ]);
}

function changePasswordSubmit({ oldPassword, newPassword }) {
  return changeCurrentUserPassword(oldPassword, newPassword)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

function refreshSessionReq() {
  return refreshSession()
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}
export { watchChangePasswordRequest };

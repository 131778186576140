import {
  PERMANENT_DELETE_SUCCESS,
  PERMANENT_DELETE_RESET,
  PERMANENT_DELETE_FAILURE
} from "./action";

export const permanentDeleteStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PERMANENT_DELETE_SUCCESS:
      return {
        data: action.data,
        error: null
      };

    case PERMANENT_DELETE_FAILURE:
      return {
        data: null,
        error: action.error
      };

    case PERMANENT_DELETE_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null
};

import {
  LIVE_STATES_FETCH_REQUEST,
  LIVE_STATES_FETCH_SUCCESS,
  LIVE_STATES_FETCH_FAILED,
} from "./liveStatesAction";

export const liveStatesStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LIVE_STATES_FETCH_REQUEST:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;

    case LIVE_STATES_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    case LIVE_STATES_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};

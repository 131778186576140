export const OFFER_ORDER = "offers/order";
export const OFFER_ORDER_REQUEST = `${OFFER_ORDER}_request`;
export const OFFER_ORDER_SUCCESS = `${OFFER_ORDER}_success`;
export const OFFER_ORDER_FAILURE = `${OFFER_ORDER}_failure`;
export const OFFER_ORDER_RECEIVED = `${OFFER_ORDER}_received`;

export const offerOrderAction = {
  request: (payload) => {
    return {
      type: OFFER_ORDER_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: OFFER_ORDER_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: OFFER_ORDER_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: OFFER_ORDER_RECEIVED
    }
  },
};

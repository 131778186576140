
export const ORDERS_DASHBOARD_SIDE_FETCH_REQUEST = "orders/fetch_dashboard_side_request";
export const ORDERS_DASHBOARD_SIDE_FETCH_LOADING = "orders/fetch_dashboard_side_loading";
export const ORDERS_DASHBOARD_SIDE_FETCH_SUCCESS = "orders/fetch_dashboard_side_success";
export const ORDERS_DASHBOARD_SIDE_FETCH_FAILED = "orders/fetch_dashboard_side_failed";


export function fetchOrderDashboardSideList(page=1) {
  return {
    type: ORDERS_DASHBOARD_SIDE_FETCH_REQUEST,
    payload: {
      page,
    },
  };
}

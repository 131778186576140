import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Button } from "@components";

const StepButton = ({ loading, setStep, step, maxStep, values }) => {
  const sellType = get(values, "sell_type", "");
  const onBackToPreStep = () => {
    typeof setStep === "function" && setStep(step - 1);
  };

  const renderBackBtn = (step) => {
    if (step === 1) {
      return null;
    }
    if (step === maxStep && sellType === "exchange") {
      return null;
    }
    return (
      <Button
        onClick={onBackToPreStep}
        size="xl"
        outline
        color="gray-300"
        textColor="gray-900"
        className="hover:border-fish-primary hover:bg-fish-primary hover:text-white focus:outline-none active:bg-fish-primary"
        full
      >
        Back
      </Button>
    );
  };

  const getText = (step, sellType) => {
    if (step < maxStep) {
      return "Next";
    }
    if (sellType === "exchange") {
      return "Close";
    }
    return "Submit";
  };

  const renderSubmitBtn = (step) => {
    return (
      <Button
        type="submit"
        size="xl"
        color="gray-900"
        textColor="white"
        className="bg-gray-900 flex-1 justify-center whitespace-no-wrap px-0"
        full
        disabled={loading}
        loading={loading}
      >
        {getText(step, sellType)}
      </Button>
    );
  };

  return (
    <div className="space-y-4">
      {renderSubmitBtn(step)}
      {renderBackBtn(step)}
    </div>
  );
};

StepButton.propTypes = {
  loading: PropTypes.bool,
  onTransferLaterSubmit: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
  values: PropTypes.object,
};

StepButton.defaultProps = {
  loading: false,
  onTransferLaterSubmit: () => {},
  setStep: () => {},
  step: 1,
  values: {},
};

export default StepButton;

export const PERMANENT_ACCEPT_BID = "permanent/accept_bid";
export const PERMANENT_ACCEPT_BID_REQUEST = `${PERMANENT_ACCEPT_BID}_request`;
export const PERMANENT_ACCEPT_BID_SUCCESS = `${PERMANENT_ACCEPT_BID}_success`;
export const PERMANENT_ACCEPT_BID_FAILURE = `${PERMANENT_ACCEPT_BID}_failure`;
export const PERMANENT_ACCEPT_BID_RESET = `${PERMANENT_ACCEPT_BID}_reset`;

export const permanentAcceptBidAction = {
  request: (payload) => {
    return {
      type: PERMANENT_ACCEPT_BID_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_ACCEPT_BID_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_ACCEPT_BID_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: PERMANENT_ACCEPT_BID_RESET
    }
  }
};

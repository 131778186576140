import { takeLatest, call, put } from "redux-saga/effects";
import errorString from "@utils/errorString";
import { OrderApiUsecase } from "@core";
import { ORDERS_BUYER_PAY_REQUEST, ordersBuyerPayAction } from "./action";

export function* watchOrdersBuyerPay() {
  yield takeLatest(ORDERS_BUYER_PAY_REQUEST, ordersBuyerPay);
}

function* ordersBuyerPay(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "buyerPay"], action.payload);
    yield put(ordersBuyerPayAction.success("success"));
  } catch (err) {
    yield put(ordersBuyerPayAction.failure(errorString(err)));
  }
}

import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { Form } from "@components/formik";

import FirstStep from "./_step_1";
import SecondStep from "./_step_2";
import ThirdStep from "./_step_3";
import SubmitBtn from "./_submit_btn";

const Steps = ({
  loading,
  defaultInitialValues,
  onSubmit,
  schema,
  step,
  setStep,
  states,
}) => {
  const renderStep = (step, states, values) => {
    switch (step) {
      case 0:
        return <FirstStep states={states}/>;
      case 1:
        return <SecondStep values={values} />;
      case 2:
        return <ThirdStep />;
      default:
        return null;
    }
  };
  return (
    <div>
      <Formik
        initialValues={defaultInitialValues}
        validationSchema={schema[step]}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              {renderStep(step, states, values)}
              <div className="mt-10">
                <SubmitBtn step={step} loading={loading} values={values}/>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

Steps.propTypes = {};

export default Steps;

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

/**
 * import core
 */
import { AuctionBidMessage } from "@core";

/**
 * import local utils
 */
import { scrollTop } from "@utils/scrollToTop";

/**
 * import hooks
 */
import { useAuctionCreateBid, useModal } from "@hooks";

/**
 * import local component
 */
import { ModalWrapper } from "@views/common";
import { Button, Mobile, DesktopAndTablet } from "@components";
import Form from "./_form";
import FixPrice from "./_fix_price";
import CurrentPrice from "./_current_price";
import BidMessage from "../detail/_bid_message";

const BidCard = ({ data, state }) => {
  const seller = get(data, "show.seller", false);
  const login = get(data, "user.login", false);
  const isBidder = get(data, "status.bidder", false);
  const message = get(data, "show.bidder.message", "");
  const { isOpen, toggleModal } = useModal();
  const {
    handleSubmit,
    initialValues,
    loading: submitLoading,
    price,
  } = useAuctionCreateBid({
    auctionId: state.auctionId,
    seller,
    login,
    success: () => {
      scrollTop();
      toggleModal(false);
    },
  });

  const renderDesktop = () => {
    return (
      <div className="mt-10">
        <Form
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={initialValues}
          loading={submitLoading}
          data={data}
          price={price}
        />
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="mt-6">
        <Button
          size="xl"
          color="gray-900"
          textColor="white"
          className="flex-1 bg-gray-900 justify-center whitespace-no-wrap px-0 text-sm"
          full
          disabled={seller}
          onClick={() => {
            if (seller) {
              return;
            }
            toggleModal();
          }}
        >
          {isBidder ? "Increase your max bid" : "Place Bid"}
        </Button>
        {seller && (
          <div className="text-sm text-blue-gray-600 mt-2">
            {AuctionBidMessage.seller}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="space-y-2">
        <Mobile>{renderMobile()}</Mobile>
        <DesktopAndTablet>{renderDesktop()}</DesktopAndTablet>
        <FixPrice data={data} />
      </div>
      <Mobile>
        <ModalWrapper
          isOpen={isOpen}
          toggleModal={() => {
            typeof toggleModal === "function" && toggleModal(false);
          }}
          size="md"
          contentAlign="left"
        >
          <div className="">
            <BidMessage data={data} className="absolute inset-x-0 top-0 z-0" />
            <div className={cx({ "mt-20": message })}>
              <CurrentPrice data={data} />
              <div className="mt-6">
                <Form
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  initialValues={initialValues}
                  loading={submitLoading}
                  data={data}
                  price={price}
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      </Mobile>
    </>
  );
};

BidCard.propTypes = {};

export default BidCard;

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, MarketUsecase } from "@core/index";

import {
  marketListAction,
  MARKET_LIST,
} from "@redux/modules_v2/markets/list/action";

//Hooks
import { useDeepEffect } from "../common/deepEffect";

/**
 * [hooks] market list
 * @returns { usecase: "a usecase by market data", loading: true|false }
 */

export const useMarketList = (params) => {
  const dispatch = useDispatch();

  const fetchMarketList = (params) => {
    dispatch(marketListAction.request(params));
  };

  const marketListStatus = useSelector((state) => {
    const selector = new LoadingSelector(MARKET_LIST, state);
    return Object.assign({}, get(state, "marketListStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = marketListStatus.loading;
  const usecase = new MarketUsecase(marketListStatus.data);
  const data = usecase.list;
  const nav = usecase.nav(params);
  const title = usecase.title(params);
  const lowestSellerOffers = usecase.quotaMarkets("lowest_seller_offer");
  const highestBuyerBids = usecase.quotaMarkets("highest_buyer_bids");
  const querySimilar = (query, type = "lowest_seller_offer") => {
    return usecase.similar(query, type);
  };

  const lowestSellerOffersCompacted = usecase.quotaMarkets(
    "lowest_seller_offer",
    true
  );
  const highestBuyerBidsCompacted = usecase.quotaMarkets(
    "highest_buyer_bids",
    true
  );
  /**
   * did mount fetch market list
   */
  useDeepEffect(() => {
    params && fetchMarketList(params);
  }, [params]);

  return {
    data,
    nav,
    title,
    loading,
    lowestSellerOffers,
    highestBuyerBids,
    querySimilar,
    lowestSellerOffersCompacted,
    highestBuyerBidsCompacted,
    fetchMarketList,
  };
};

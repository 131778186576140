export const LISTING_OFFER_ENQUIRY = "listing/enquiry_offer";
export const LISTING_OFFER_ENQUIRY_REQUEST = `${LISTING_OFFER_ENQUIRY}_request`;
export const LISTING_OFFER_ENQUIRY_SUCCESS = `${LISTING_OFFER_ENQUIRY}_success`;
export const LISTING_OFFER_ENQUIRY_FAILURE = `${LISTING_OFFER_ENQUIRY}_failure`;
export const LISTING_OFFER_ENQUIRY_RESET = `${LISTING_OFFER_ENQUIRY}_reset`;

export const listingOfferEnquiryAction = {
  request: (payload) => {
    return {
      type: LISTING_OFFER_ENQUIRY_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: LISTING_OFFER_ENQUIRY_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: LISTING_OFFER_ENQUIRY_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: LISTING_OFFER_ENQUIRY_RESET
    }
  },
};

import { trimEnd } from "lodash";
/**
 * 根据数量显示单位（单复数）
 * @param {Number|String|Null} num null | 0 | 1 | >1
 * @param {String} unit "pots|shares|units ..."
 *
 * @example
   const unitName = toggleUnitTextV2(null, "pots") // => "pot"
   const unitName = toggleUnitTextV2(0, "pots") // => "pot"
   const unitName = toggleUnitTextV2(1, "pots") // => "pot"
   const unitName = toggleUnitTextV2(2, "pots") // => "pots"

   const unitName = toggleUnitTextV2(null, "shares") // => "share"
   const unitName = toggleUnitTextV2(0, "shares") // => "share"
   const unitName = toggleUnitTextV2(1, "shares") // => "share"
   const unitName = toggleUnitTextV2(2, "shares") // => "shares"
 */
export default function toggleUnitTextV2(num, unit = "") {
  if(num && num > 1) {
    return unit;
  }
  return trimEnd(unit, "s");
}
export const APPLIES_DELETE = "applies/delete";
export const APPLIES_DELETE_LOADING = "applies/delete_loading";
export const APPLIES_DELETE_SUCCESS = "applies/delete_success";
export const APPLIES_DELETE_FAILED = "applies/delete_failed";
export const APPLIES_DELETE_RECEIVED = "applies/delete_received";

export function applyDelete(id) {
  return {
    type: APPLIES_DELETE,
    id
  };
}
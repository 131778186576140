import moment from 'moment';

export function isToday(data) {
  const dateTime = moment.isMoment(data) ? data : moment(data);
  return dateTime.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
}

export function isYesterday(data) {
  const dateTime = moment.isMoment(data) ? data : moment(data);
  return (
    dateTime.format('YYYY-MM-DD') ==
    moment()
      .subtract(1, 'd')
      .format('YYYY-MM-DD')
  );
}

export function isDaysAfter(data, day = 0) {
  const dateTime = moment.isMoment(data) ? data : moment(data);
  const afterDay = moment()
    .add(day, 'day')
    .startOf('day');
  // dateTime <
  //   moment()
  //     .add(day, 'day')
  //     .endOf('day') &&
  return dateTime > afterDay;
}

import {CHANGE_EMAIL_EVENT_FAILED, CHANGE_EMAIL_EVENT_LOADING, CHANGE_EMAIL_EVENT_SUCCESS} from "./action";

function changeEmailEvent(state = {}, action = {}) {
  switch (action.type) {
    case CHANGE_EMAIL_EVENT_LOADING:
      return { loading: true, data: null, error: null }
    case CHANGE_EMAIL_EVENT_SUCCESS:
      return { loading: false, data: action.data, error: null }
    case CHANGE_EMAIL_EVENT_FAILED:
      return { loading: false, data: null, error: action.error }
    default:
      return state
  }
}

export { changeEmailEvent }
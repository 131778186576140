import {
  ACCOUNT_BANKS_SHOW_SUCCESS,
  ACCOUNT_BANKS_SHOW_FAILED,
} from "./action";

export const accountBanksShowStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ACCOUNT_BANKS_SHOW_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case ACCOUNT_BANKS_SHOW_FAILED:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: {},
  error: null,
};

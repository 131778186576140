import { takeLatest, call, put } from "redux-saga/effects";

import { WalletApiUsecase } from "@core";
import { WALLET_BALANCE_REQUEST, walletBalanceAction } from "./action";

// wallet balance fetch
export function* watchWalletBalanceFetch() {
  yield takeLatest(WALLET_BALANCE_REQUEST, walletBalanceFetch);
}

function* walletBalanceFetch(action) {
  try {
    const usecase = new WalletApiUsecase();
    const res = yield call([usecase, "balance"], action.payload);
    yield put(walletBalanceAction.success(res.data));
  } catch (err) {
    yield put(walletBalanceAction.failure(err));
  }
}

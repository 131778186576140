import { useCallback, useState } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import indexOf from "lodash/indexOf";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, FisheryUsecase } from "@core";

import {
 FISHERIES_LISTS,
 fisheriesListAction
} from "@redux/modules_v2/fishery/list/action";

/**
 * Hooks
 * @param {*} params
 */
import { useDeepEffect } from "@hooks";

/**
 * Fetch lists hooks
 */
export const useFisheryList = (params, callback) => {
  const dispatch = useDispatch();
  const [followingIds, setFollowingIds] = useState([]);

  const updateFollowingIds = useCallback(
    (id) => {
      const index = indexOf(followingIds, id);
      const ids =
        index > -1
          ? filter(followingIds, (item) => item != id)
          : [...followingIds, id];
      setFollowingIds(ids);
    },
    [followingIds]
  );

  const fetchList = useCallback((params) => {
    dispatch(fisheriesListAction.request(params));
  }, []);

  const updateList = useCallback(
    (values) => {
      if (values) {
        dispatch(fisheriesListAction.update(values));
        updateFollowingIds(get(values, "id", ""));
      }
    },
    [followingIds]
  );

  const fisheriesListStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERIES_LISTS, state);
    return Object.assign({}, get(state, "fisheriesListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = fisheriesListStatus;
  const usecase = new FisheryUsecase(data);

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    params && fetchList(params);
  }, [params]);

  /**
   * effect after fetch
   */
  useDeepEffect(() => {
    if (!fisheriesListStatus.loading) {
      if (fisheriesListStatus.data && isEmpty(params)) {
        const usecase = new FisheryUsecase(data);
        setFollowingIds(usecase.followingIds);
        typeof callback === "function" && callback(usecase);
      }
    }
  }, [fisheriesListStatus.loading, params]);

  return {
    data: usecase.list,
    options: usecase.options,
    auctionOptions: usecase.auctionOptions,
    exchangeOptions: usecase.exchangeOptions,
    hasFollowing: !isEmpty(followingIds),
    loading,
    fetchList,
    updateList,
  };
};

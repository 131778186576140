export const AUCTION_FINAL_BID_SHOW = "auction/final_bid_show";
export const AUCTION_FINAL_BID_SHOW_REQUEST = `${AUCTION_FINAL_BID_SHOW}_request`;
export const AUCTION_FINAL_BID_SHOW_SUCCESS = `${AUCTION_FINAL_BID_SHOW}_success`;
export const AUCTION_FINAL_BID_SHOW_FAILURE = `${AUCTION_FINAL_BID_SHOW}_failure`;

export const auctionFinalBidShowAction = {
  request: (payload) => {
    return {
      type: AUCTION_FINAL_BID_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: AUCTION_FINAL_BID_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: AUCTION_FINAL_BID_SHOW_FAILURE,
      error,
    }
  }
};

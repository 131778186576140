import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_FOLLOWING_AUCTIONS_REQUEST, fisheryFollowingAuctionsAction } from "./action";

// fetch following fishery auctions list
export function* watchFisheryFollowingAuctionsFetch() {
  yield takeLatest(FISHERY_FOLLOWING_AUCTIONS_REQUEST, fisheryFollowingAuctionsFetch);
}

function* fisheryFollowingAuctionsFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "followingAuctions"], action.payload);
    yield put(fisheryFollowingAuctionsAction.success(res.data));
  } catch (err) {
    yield put(fisheryFollowingAuctionsAction.failure(err));
  }
}

/**
 * viewport units on mobile
 */
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit

export default () => {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  // listen to the resize event
  window.addEventListener("resize", () => {
    // update value of --vh
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import capitalize from "lodash/capitalize";

import {
  invoicesAPI,
} from "@config/api";

import {
  INVOICES_SHOW_FETCH_REQUEST,
  INVOICES_SHOW_FETCH_LOADING,
  INVOICES_SHOW_FETCH_SUCCESS,
  INVOICES_SHOW_FETCH_FAILED
} from "./showAction";


//fetch invoice show
export function* watchInvoiceShowFetch() {
  yield takeEvery(INVOICES_SHOW_FETCH_REQUEST, invoiceShowFetch);
}

export function* invoiceShowFetch(action) {
  yield put({ type: INVOICES_SHOW_FETCH_LOADING });

  const { res, err } = yield call(invoiceShowFetchApi, action.payload.id);
  if (!res) {
    yield put({
      type: INVOICES_SHOW_FETCH_FAILED,
      error: "Get invoice data failed."
    });
    return;
  }
  yield put({ type: INVOICES_SHOW_FETCH_SUCCESS, data: res });
}

function invoiceShowFetchApi(id) {
  return axios
    .get(invoicesAPI(id))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

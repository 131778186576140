import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { AuctionsApiUsecase } from "@core";
import { AUCTION_FINAL_BID_CREATE_REQUEST, auctionFinalBidCreateAction } from "./action";

// Auction create bid
export function* watchAuctionFinalBidCreate() {
  yield takeLatest(AUCTION_FINAL_BID_CREATE_REQUEST, auctionFinalBidCreate);
}

function* auctionFinalBidCreate(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "createFinalBid"], action.payload);
    const data = get(res, "data", {});
    yield put(auctionFinalBidCreateAction.success(data));
  } catch (err) {
    yield put(auctionFinalBidCreateAction.failure(errorString(err)));
  }
}

const UPLOAD_AVATAR_REQUEST = "upload_avatar/request"
const UPLOAD_AVATAR_LOADING = "upload_avatar/loading"
const UPLOAD_AVATAR_SUCCESS = "upload_avatar/success"
const UPLOAD_AVATAR_FAILED = "upload_avatar/failed"

function uploadAvatarRequest(payload) {
  return {
    type: UPLOAD_AVATAR_REQUEST,
    payload
  }
}

function uploadAvatarLoading() {
  return {
    type: UPLOAD_AVATAR_LOADING
  }
}

function uploadAvatarSuccess(data) {
  return {
    type: UPLOAD_AVATAR_SUCCESS,
    data
  }
}

function uploadAvatarFailed(error) {
  return {
    type: UPLOAD_AVATAR_FAILED,
    error
  }
}

export {
  UPLOAD_AVATAR_FAILED,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_LOADING,
  UPLOAD_AVATAR_REQUEST,

  uploadAvatarFailed,
  uploadAvatarLoading,
  uploadAvatarRequest,
  uploadAvatarSuccess
}



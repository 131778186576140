import get from "lodash/get";
import compact from "lodash/compact";
import lowerCase from "lodash/lowerCase";
import { unitOfSaleMap } from "@core";
import { QuotaAttrBaseEntity } from "./baseEntity";

/**
 * [ ENTITY ] Quotum entity
 * @data
 * quotum: {
 *   id: 1
 *   unit_name: "units"
 *   category: {id: 4, name: "Greenlip Abalone"}
 *   country: {id: 2, abbr: "AU", name: "Australia"}
 *   species: {id: 1, name: "species_1"}
 *   state: {id: 8, abbr: "NWS", name: "New South Wales"}
 *   zone: {id: 1, abbr: "Z1", name: "zone 1"}
 * }
 */

export class QuotumEntity {
  constructor(props = {}) {
    this.data = props;
    this.getAttr = QuotaAttrBaseEntity.getAttr(this.data);
  }

  get id() {
    return get(this.data, "id", "");
  }

  get unitName() {
    return get(this.data, "unit_name", "");
  }

  get name() {
    return compact([
      this.getAttr("state", "name"),
      this.getAttr("category", "name"),
    ]).join(" ");
  }

  get nameNoState() {
    const key = "name";
    return compact([
      this.getAttr("zone", key),
      this.getAttr("species", key),
    ]).join(" ");
  }

  get nameStateAbbr() {
    return compact([
      this.getAttr("state", "abbr"),
      this.getAttr("zone", "name"),
      this.getAttr("species", "name"),
    ]).join(" ");
  }

  get stateAbbr() {
    return this.getAttr("state", "abbr");
  }

  get imageUrl() {
    const imgName = `categories/${lowerCase(this.getAttr("category", "name"))}`;
    return imgName.replace(/\s/g, "_");
  }

  unitNameCondition(unitOfSale) {
    const isSaleByUnit = unitOfSale === unitOfSaleMap.unit;
    if (isSaleByUnit) {
      return this.unitName;
    }
    return "kg";
  }
}

import React from "react";
import classnames from "classnames";
import { get, isEmpty } from "lodash";
import { Link, Loading, Pagination, Empty, Dropdown } from "@components";
const filterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Deposits",
    value: "topup",
  },
  {
    label: "Withdrawals",
    value: "withdraw",
  },
  {
    label: "Purchases",
    value: "buy_a_quota",
  },
  {
    label: "Sales",
    value: "sell_a_quota",
  },
];

const Entries = (props) => {
  const { data, list, loading, fetchWalletEntries, setFilter, setPage } = props;

  const renderMoney = (item, walletData, index) => {
    const symbolClass = classnames("text-sm font-semibold pr-1", {
      "text-fish-green": item.entrySymbol === "+",
      "text-fish-red": item.entrySymbol !== "+",
    });
    if (walletData.showFees) {
      return (
        <Dropdown
          button={
            <div className="price-popover-bar cursor-pointer flex justify-end items-center">
              <span className={symbolClass}>{item.entrySymbol}</span>
              <span className="text-sm font-semibold leading-snug text-blue-gray-900">
                {item.amountStr}
              </span>
            </div>
          }
          position="right"
          menuClassName="p-4"
          caret
        >
          <div className="flex py-1 items-center">
            <div className="flex-1 text-xs whitespace-no-wrap">{walletData.tipTotal || ""}</div>
            <div className="ml-3 text-sm whitespace-no-wrap">
              <strong>
                {item.amountWithoutFee > 0
                  ? item.amountWithoutFeeStr
                  : item.amountStr}
              </strong>
            </div>
          </div>
          <div className="flex py-1 items-center">
            <div className="flex-1 text-xs whitespace-no-wrap">{walletData.tipFees || ""}</div>
            <div className="ml-3 text-sm whitespace-no-wrap">
              <strong>{walletData.fees}</strong>
            </div>
          </div>
        </Dropdown>
      );
    }
    return (
      <div className="flex justify-end items-center">
        <span className={symbolClass}>{item.entrySymbol}</span>
        <span className="text-sm font-semibold leading-snug text-blue-gray-900">
          {item.amountStr}
        </span>
      </div>
    );
  };

  return (
    <div className="px-4 md:px-6 py-6">
      <div className="flex items-center justify-between mb-8">
        <h3>
          Wallet Transactions
        </h3>
        <div>
          <Dropdown
            options={filterOptions}
            onChange={(item) => {
              setFilter(item.value || "");
              fetchWalletEntries(item.value || "");
            }}
            renderButtonName={(name) => {
              return `Sort by ${name}`;
            }}
            position="right"
            caret
          />
        </div>
      </div>
      <div>
        <div className="bg-white overflow-hidden">
          <div className="">
            {loading && <Loading />}
            {!loading && isEmpty(list) && <Empty />}
            {!loading &&
              list.map((item, index) => {
                const walletData = item.generateData();

                return (
                  <div
                    key={index}
                    className="md:rounded-lg border-b sm-last-border-0 md:border border-blue-gray-100 py-3 md:py-4 md:px-6 md:mt-4 first:mt-0"
                  >
                    <div className="flex justify-between items-center">
                      <div className="text-sm md:text-base font-semibold md:font-medium leading-snug text-blue-gray-900">
                        {walletData.title}{" "}
                        {walletData.orderSerialNumber && walletData.orderId && (
                          <Link
                            to={`/orders/${walletData.orderId}`}
                            className="text-link hover:text-link"
                          >
                            #{walletData.orderSerialNumber}
                          </Link>
                        )}
                      </div>
                      <div className="">
                        {renderMoney(item, walletData, index)}
                      </div>
                    </div>
                    <div className="mt-2 flex justify-between">
                      <div className="text-xs md:text-sm font-normal md:font-medium leading-normal text-blue-gray-500">
                        {item.createAtStr}
                      </div>
                      <div className="text-xs md:text-sm font-normal md:font-medium leading-normal text-blue-gray-500">
                        Balance: {item.balanceCacheStr}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {get(data, "total_pages", 0) > 1 && (
        <Pagination
          className="my-4-tw"
          theme="blue"
          currentPage={get(data, "page", 1)}
          entriesTotal={get(data, "total", 0)}
          pageSize={get(data, "page_size", 20)}
          itemClickHandler={(page) => {
            setPage(page);
            fetchWalletEntries(null, page);
          }}
        />
      )}
    </div>
  );
};

export default Entries;

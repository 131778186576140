import React from "react";
import { Link } from "@components";
import { links } from "@config/staticLink";

/**
 * [Component] Layout Footer
 */
const Footer = (props) => {
  return (
    <footer className="bg-fish-black" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto px-6 py-10 md:px-8">
        <div className="lg:flex lg:items-center lg:justify-between text-sm text-blue-gray-400">
          <div>BeachPrice Pty Ltd</div>
          <nav
            className="md:-mx-4 grid grid-cols-3 md:flex md:flex-wrap"
            aria-label="Footer"
          >
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap">
              <Link to={links.home} className="text-sm hover:text-blue-gray-500">
                Buy
              </Link>
            </div>
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap">
              <Link to="/sell" className="text-sm hover:text-blue-gray-500">
                Sell
              </Link>
            </div>
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap hidden">
              <span
                className="text-sm hover:text-blue-gray-500 cursor-pointer"
              >
                Contact
              </span>
            </div>
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap">
              <Link to={links.sign_in} className="text-sm hover:text-blue-gray-500">
                Log In
              </Link>
            </div>
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap">
              <Link to="/register" className="text-sm hover:text-blue-gray-500">
                Sign Up
              </Link>
            </div>
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap">
              <Link
                to={links.privacyPolicy}
                className="text-sm hover:text-blue-gray-500"
              >
                Privacy Policy
              </Link>
            </div>
            <div className="md:px-4 pt-3 md:pt-0 whitespace-no-wrap">
              <Link
                to={links.termsOfService}
                className="text-sm hover:text-blue-gray-500"
              >
                Terms Of Service
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

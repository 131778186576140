import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  BANKS_UPDATE_PUT,
  BANKS_UPDATE_LOADING,
  BANKS_UPDATE_SUCCESS,
  BANKS_UPDATE_FAILED,
  BANKS_UPDATE_RECEIVED
} from "./editAction";

import {
  banksAPI,
  bpBanksAPI,
} from "@config/api";


//put bank for update
export function* watchBankUpdate() {
  yield takeLatest(BANKS_UPDATE_PUT, bankUpdate);
}

export function* bankUpdate(action) {
  yield put({ type: BANKS_UPDATE_LOADING });
  const { err, res } = yield call(bankUpdateApi, action.payload);

  if (!res) {
    yield put({
      type: BANKS_UPDATE_FAILED,
      error: "Update bank failed."
    });
    return;
  }
  yield put({ type: BANKS_UPDATE_SUCCESS, data: res });
}

export function bankUpdateApi(payload) {
  return axios
    .put(banksAPI(), payload)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

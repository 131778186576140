import axios from "axios";
import {takeEvery, call, put} from 'redux-saga/effects';

import {
  UPDATE_PROFILE_EVENT_REQUEST,

  updateProfileEventLoading,
  updateProfileEventSuccess,
  updateProfileEventFailed
} from "./action";

import {updateProfileEventAPI} from "@config/api";

function* watchUpdateProfileEventRequest() {
  yield takeEvery(UPDATE_PROFILE_EVENT_REQUEST, updateProfileEventRequest)
}

function* updateProfileEventRequest(action) {
  yield put(updateProfileEventLoading())

  try {
    const res = yield call(axios.post, updateProfileEventAPI(action.payload), {})
    yield put(updateProfileEventSuccess("SUCCESS"))
  } catch (err) {
    yield put(updateProfileEventFailed(err))
  }
}

export { watchUpdateProfileEventRequest }
import { useState, useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { get } from "lodash";

/**
 * import local tools & config & redux
 */
import { LoadingSelector, CaculatedFee } from "@core";
import { paymentMethodMap } from "@config/enum";
import numeral from "@utils/numeral";
import Notice from "@utils/noticeNew";
import {
  walletTopupAction,
  WALLET_TOPUP,
} from "@redux/modules_v2/wallets/topup/action";

/**
 * [hooks] wallet topup 充值
 */
export const useWalletTopup = success => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [money, setMoney] = useState("");
  const [payMethod, setPayMethod] = useState(paymentMethodMap.stripe);
  const [processFee, setProcessFee] = useState(0);
  const moneyTotal = numeral(Number(money))
    .add(Number(processFee))
    .value();

  const onMoneyChange = value => {
    setMoney(value);
    const fee =
      payMethod === paymentMethodMap.stripe
        ? CaculatedFee.stripe(value)
        : 0;
    setProcessFee(fee);
  };

  const onMethodChange = value => {
    setPayMethod(value);
    const fee =
      value === paymentMethodMap.stripe
        ? CaculatedFee.stripe(money)
        : 0;
    setProcessFee(fee);
  };

  const submit = () => {
    if (moneyTotal <= 0) {
      Notice.failure("Please enter the topup amount.");
      return;
    }
    if (!payMethod) {
      Notice.failure("Please choose a payment method to continue.");
      return;
    }
    const postData = {
      amount: Number(money),
      fees: Number(processFee),
    };
    dispatch(walletTopupAction.request({ payMethod, ...postData }));
  };

  const walletTopupStatus = useSelector(state => {
    const selector = new LoadingSelector(WALLET_TOPUP, state);
    return Object.assign({}, get(state, "walletTopupStatusV2", {}), {
      loading: selector.loading,
    });
  });

  const loading = walletTopupStatus.loading;

  /**
   * watch topup status ( create payment result status )
   */
  useEffect(() => {
    if (walletTopupStatus.justFinished) {
      if (walletTopupStatus.error) {
        Notice.failure(get(walletTopupStatus, "error", "Topup error"));
      } else {
        if (payMethod === paymentMethodMap.stripe) {
          const redirectUrl = get(
            walletTopupStatus,
            "data.stripe_navigate_url"
          );
          if (redirectUrl) {
            // stripe 在本网站输入 stripe 信息，所以需要使用 history
            history && history.push(redirectUrl);
          }
        }
        if (payMethod === paymentMethodMap.offline) {
          success && success();
        }
      }
      dispatch(walletTopupAction.received());
    }
  }, [walletTopupStatus.justFinished]);

  return {
    money,
    payMethod,
    processFee,
    moneyTotal,

    onMoneyChange,
    onMethodChange,
    submit,
    loading,
  };
};

import { useState, useEffect, useCallback } from "react";
import { submit, reset, formValueSelector } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import toNumber from "lodash/toNumber";

//Core Method
import { LoadingSelector } from "@core";
import Notice from "@utils/noticeNew";
import {
  auctionFinalBidCreateAction,
  AUCTION_FINAL_BID_CREATE,
} from "@redux/modules_v2/auctions/finalBidCreate/action";

/**
 * [hooks] create new auction final bid
 */
export const useAuctionCreateFinalBid = ({
  auctionId,
  price: _price,
  success,
}) => {
  const dispatch = useDispatch();

  //Auction bid create status
  const auctionFinalBidCreateStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_FINAL_BID_CREATE, state);
    return Object.assign({}, get(state, "auctionFinalBidCreateStatus", {}), {
      loading: selector.loading,
    });
  });

  // Declare your memoized mapState function
  const selector = formValueSelector("AuctionFinalBidForm");
  const mapState = useCallback(
    (state) => ({
      price: selector(state, "price"),
      quantity: selector(state, "quantity"),
    }),
    [selector]
  );
  const { price, quantity } = useSelector(mapState);

  /**
   * 初始化表单
   */
  const initialState = {
    price: "",
    quantity: "",
  };
  const [initialValues, setInitalValues] = useState(initialState);

  /**
   * 提交表单
   */
  const startSubmit = () => {
    dispatch(submit("AuctionFinalBidForm"));
  };

  /**
   * 提交表单callback
   */
  const handleSubmit = useCallback(
    (values = {}) => {
      if (!values.price) {
        Notice.failure("Please input your bid!");
        return;
      }
      const price = toNumber(values.price);
      const quantity = toNumber(values.quantity);
      let data = { auction_id: auctionId, price };
      if (quantity) data = Object.assign({}, data, { quantity });
      dispatch(auctionFinalBidCreateAction.request(data));
    },
    [auctionId]
  );

  useEffect(() => {
    _price && setInitalValues({ price: _price });
  }, [_price]);
  /**
   * 创建auction 后的effect
   */
  useEffect(() => {
    if (!auctionFinalBidCreateStatus.loading) {
      if (auctionFinalBidCreateStatus.data) {
        // Notice.success("You have placed a final bid successfully.");
        typeof success === "function" &&
          success(auctionFinalBidCreateStatus.data);
      }
      if (auctionFinalBidCreateStatus.error) {
        Notice.failure(auctionFinalBidCreateStatus.error);
      }
      dispatch(auctionFinalBidCreateAction.reset());
      dispatch(reset("AuctionFinalBidForm"));
    }
  }, [auctionFinalBidCreateStatus.loading]);

  return {
    handleSubmit,
    initialValues,
    loading: auctionFinalBidCreateStatus.loading,
    price,
    quantity,
    setInitalValues,
    startSubmit,
  };
};

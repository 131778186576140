import { WALLET_LOADING, WALLET_SUCCESS, WALLET_FAILED } from "./listAction";

export function walletStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
      });

    case WALLET_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: {},
        error: action.error,
      });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: {},
  error: null,
};


import { useState, useEffect } from "react";
import { confirmRegistration } from "@utils/cognitoAuth";

export const useConfirmRegistration = (params, dependencies) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);

  useEffect(() => {
    setLoading(true);
    confirmRegistration(...params)
      .then(res => {
        setLoading(false);
        setRes(res);
      })
      .catch(err => {
        setLoading(false);
        setError(err.message);
      });
  }, dependencies);

  return [loading, res, error];
};

import { useCallback, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AccountQuotumBalanceUsecase } from "@core";

import {
  quotumBalancesAction,
  QUOTUM_BALANCES,
} from "@redux/modules_v2/account/quotumBalances/action";

/**
 * Fetch lists hooks
 */
export const useAccountQuotumBalances = () => {
  const dispatch = useDispatch();

  const fetchList = useCallback((params) => {
    dispatch(quotumBalancesAction.request(params));
  }, []);

  const accountQuotumBalancesStatus = useSelector((state) => {
    const selector = new LoadingSelector(QUOTUM_BALANCES, state);
    return Object.assign({}, get(state, "accountQuotumBalancesStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = accountQuotumBalancesStatus;
  const usecase = new AccountQuotumBalanceUsecase(data);

  useEffect(() => {
    fetchList();
  }, []);

  return {
    data: usecase.list,
    loading,
    fetchList,
  };
};

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

const Notice = (props) => {
  const {
    title, 
    content, 
    textColor, 
    borderColor, 
    bgColor, 
    isOpen,
    mb,
    className,
    ...restProps
  } = props;
  const colorClass = () => {
    return `text-${textColor ? textColor : "white"} 
      bg-${bgColor ? bgColor : "white"} 
      border-${borderColor ? borderColor : "transparent"}`;
  };
  const marginBottom = () => {
    if(!isOpen)
    return `-mb-${mb}`;
    if(isOpen)
    return `mb-8`;
  };
  return (
    <div className="">
      <div
        className={cx(
          "p-4 shadow border-l-4 transform transition-all duration-500 ease-linear",
          colorClass(),
          marginBottom(),
          {'-translate-y-64': !isOpen},
          {'translate-y-0': isOpen},
          className
        )}
        {...restProps}
      >
        <div className={cx("text-base font-bold leading-normal",
          {'block':title},
          {'hidden':!title},
        )}>
          {title}
        </div>
        <div className="text-base font-medium leading-normal">
          {content}
        </div>
      </div>
    </div>
  );
};
Notice.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  mb: PropTypes.string,
};

Notice.defaultProps = {
  title: "",
  content: "",
  textColor: "",
  borderColor: "",
  bgColor: "",
  isOpen: false,
  className: "",
  mb: "12", // 提示语上滑时，下面内容往上移动距离
};

export default Notice;

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, map } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { AccountQuotumBalanceUsecase, LoadingSelector } from "@core";
import {
  marketBalanceAction,
  MARKET_BALANCE,
} from "@redux/modules_v2/markets/balance/action";

/**
 * import hooks
 */
import { useCurrentUser } from "@hooks";

/**
 * [hooks] market balance by id
 * @param {String|Number} id market id
 * @returns { data: "market balance data obj", loading: true|false }
 */
export const useMarketBalance = (id) => {
  const dispatch = useDispatch();
  const currentUserStatus = useCurrentUser();
  const isAuthenticated = get(currentUserStatus, "isAuthenticated", false);

  const fetchMarketBalance = (marketId) => {
    if (!isAuthenticated) {
      return;
    }
    dispatch(marketBalanceAction.request({ id: marketId }));
  };

  const marketBalanceStatus = useSelector((state) => {
    const selector = new LoadingSelector(MARKET_BALANCE, state);
    return Object.assign({}, get(state, "marketBalanceStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = marketBalanceStatus.loading;
  const balance = marketBalanceStatus.data || {};
  const usecase = new AccountQuotumBalanceUsecase(balance);
  /**
   * did mount and watch id change
   */
  useEffect(() => {
    if (id) {
      fetchMarketBalance(id);
    }
  }, [id]);

  return {
    balance,
    loading,
    show: usecase.show,
    fetchMarketBalance,
  };
};

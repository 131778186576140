import get from "lodash/get";
import map from "lodash/map";
import flatten from "lodash/flatten";
import pluralize from "pluralize";

import {
  PermanentEntity,
  PermanentMarketEntity,
} from "@core/entities/permanentEntity";
import { QuotumEntity } from "@core/entities/quotumEntity";
import { Moment } from "@core";

export class PermanentUsecase extends PermanentEntity {
  /**
   * 构造函数
   * @param {Object} props
   * @param {String} cognitoId
   */
  constructor(props, cognitoId) {
    super(props);
    this.market = get(props, "markets", []);
    this.currentCognitoId = cognitoId;
  }

  /**
   * 根据键值获取states
   * @param {String} key  abbr | name | id
   */
  statesByKey(key) {
    return map(this.market, (item) => {
      const quotum = new QuotumEntity(item.quotum);
      return quotum.getAttr("state", key);
    });
  }

  /**
   * [Class Getter] usecase.amount
   */
  get amount() {
    return map(this.market, (item) => {
      const market = new PermanentMarketEntity(item);
      const quotum = new QuotumEntity(item.quotum);
      return `${market.quantity} x ${quotum.name}  (${market.price(
        "price_with_symbol"
      )} per ${pluralize(quotum.unitName, 1)})`;
    });
  }

  /**
   * [Class Getter] usecase.summary
   */
  get summary() {
    return map(this.market, (item) => {
      const market = new PermanentMarketEntity(item);
      const quotum = new QuotumEntity(item.quotum);
      return {
        name: `${quotum.getAttr("state", "name")} ${quotum.getAttr(
          "species",
          "name"
        )} Quota Unit`,
        quantity: market.quantity,
        value: `${market.price("price_with_symbol")} per ${pluralize(
          quotum.unitName,
          1
        )}`,
      };
    });
  }

  /**
   * [Class Getter] uscease.stateWithUnits
   */
  get stateWithUnits() {
    const result = map(this.market, (item) => {
      const market = new PermanentMarketEntity(item);
      const quotum = new QuotumEntity(item.quotum);
      return [market.unitName, quotum.getAttr("state", "abbr")];
    });
    return flatten(result).join(" · ");
  }

  /**
   * [Class Getter] usecase.isSeller
   */
  get isSeller() {
    return this.cognitoId === this.currentCognitoId;
  }

  /**
   * [Class Getter] usecase.totalPrice
   */
  get totalPrice() {
    return this.price("price_with_symbol");
  }
}

import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { TimeCountDown } from "@components";

const ExpiredAt = ({ data }) => {
  const finalPriceExpiredAt = get(data, "show.finalPriceExpiredAt", "");
  const hasFinalBid = get(data, "status.hasFinalBid", false);
  if (!hasFinalBid) {
    return null;
  }
  return (
    <div
      className="mx-4 md:mx-0 px-6 py-5 rounded-2xl flex flex-col md:flex-row md:justify-between mb-8"
      style={{
        background:
          "linear-gradient(224.94deg, #F56D5B -1.51%, #EA239A 101.89%)",
      }}
    >
      <div className="text-white">Your exclusive Final Offer expires in </div>
      <div className="text-base font-medium text-white">
        <TimeCountDown
          date={finalPriceExpiredAt}
          now={() => {
            return window.moment.now();
          }}
          theme="white"
          themeClosed="white"
          timeClosed={12 * 3600 * 1000}
          onComplete={() => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};

ExpiredAt.propTypes = {};

export default ExpiredAt;

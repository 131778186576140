export const APPLIES_CREATE_POST = "applies/create_post";
export const APPLIES_CREATE_LOADING = "applies/create_loading";
export const APPLIES_CREATE_SUCCESS = "applies/create_success";
export const APPLIES_CREATE_FAILED = "applies/create_failed";
export const APPLIES_CREATE_RECEIVED = "applies/create_received";

export function applyCreatePost(payload = {}) {
  return {
    type: APPLIES_CREATE_POST,
    payload
  };
}
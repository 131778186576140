import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Card = ({ title, subTitle, children, className, isGray }) => {
let wrapper = isGray ? "md:border border-blue-gray-200 bg-gray-50" : "shadow-sm md:shadow bg-white"; 
  return (
    <div className={cx("p-6 md:rounded-2xl", wrapper, className)}>
      <div className="md:flex items-center justify-between space-y-1 md:space-y-0 mb-6">
        <div className="font-medium text-lg md:text-xl">{title}</div>
        {subTitle}
      </div>
      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  isGray: PropTypes.bool
};

Card.defaultProps = {};

export default Card;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Radio } from "@views/components/formik";

/**
 * [Component] Step 2
 */
const SecondStep = ({ values, setFieldValue }) => {
  const marketType = get(values, "market_type", "");
  const salesTypes = get(values, "sales_types", "");
  const getOptions = () => {
    const p2pOpt = {
      label: (
        <div className="-mt-1">
          <div className="block text-sm font-medium mb-1">Fixed price</div>
          <div className="block text-sm text-blue-gray-500">
            Set your fixed price and allow buyers to Buy It Now or make you an
            offer.
          </div>
        </div>
      ),
      value: "p2p",
    };
    const auctionOpt = {
      label: (
        <div className="-mt-1">
          <div className="block text-sm font-medium mb-1">Auction</div>
          <div className="block text-sm text-blue-gray-500">
            Choose a starting price, set your reserve, and let the market
            determine the price.
          </div>
        </div>
      ),
      value: "auction",
    };
    const leaseOpt = {
      label: (
        <div className="-mt-1">
          <div className="block text-sm font-medium mb-1">
            Consign to BeachPrice
          </div>
          <div className="block text-sm text-blue-gray-500">
            Let BeachPrice handle everything for you. Transfer your quota
            upfront to us and we’ll facilitate every sale based on the price you
            set. Buyers get the ability to instantly receive quota they’ve
            purchased which may result in higher prices to you.
          </div>
        </div>
      ),
      value: "exchange",
    };
    if (marketType === "permanent") {
      return [auctionOpt, p2pOpt];
    }
    if (salesTypes === "exchange") {
      return [leaseOpt, p2pOpt];
    }
    if (salesTypes === "auction") {
      return [auctionOpt, p2pOpt];
    }
    return [auctionOpt, leaseOpt, p2pOpt];
  };
  useEffect(() => {
    if (marketType === "permanent") {
      setFieldValue("sell_type", "auction");
      return;
    }
    if (salesTypes === "exchange") {
      setFieldValue("sell_type", "exchange");
      return;
    }
    if (salesTypes === "auction") {
      setFieldValue("sell_type", "auction");
      return;
    }
  }, [marketType, salesTypes]);
  return (
    <Radio
      label="How would you like to sell?"
      name="sell_type"
      className="space-y-4"
      radioClassName="rounded-md border border-blue-gray-200 bg-white p-4"
      options={getOptions()}
    />
  );
};

SecondStep.propTypes = {
  setFieldValue: PropTypes.func,
  setSellType: PropTypes.func,
  values: PropTypes.object,
};

SecondStep.defaultProps = {
  setFieldValue: () => {},
  setSellType: () => {},
  values: {},
};

export default SecondStep;

import React from "react";
import PropTypes from "prop-types";

/**
 * lib tools
 */
import get from "lodash/get";
import keys from "lodash/keys";
import map from "lodash/map";
import replace from "lodash/replace";
import filter from "lodash/filter";

/**
 * core
 */
import { AuctionDescriptionSelectList } from "@core";

/**
 * import lib tools & hooks & components
 */
import { useModal } from "@hooks";
import {
  DateSelect,
  FlatpickrDateTime,
  FlatpickrDate,
  FlatpickrTime,
  Checkbox,
  Radio,
  Editor,
  Select,
} from "@views/components/formik";
import { ModalWrapper } from "@views/common";
import { Button, Helper } from "@components";

const FifthStep = ({ values }) => {
  const { isOpen, toggleModal } = useModal();
  const marketType = get(values, "market_type", "");
  const showTermsAndConditions = get(
    values,
    "market.market.showTermsAndConditions",
    false
  );
  return (
    <>
      {marketType === "lease" && (
        <Radio
          name="fishing_type"
          label="Fishing Type"
          className="lg:grid lg:grid-cols-2 lg:gap-4 space-y-4 lg:space-y-0"
          radioClassName="fish-primary rounded-md border border-blue-gray-300 bg-white p-3 items-center"
          options={[
            {
              label: (
                <>
                  Encumbered{" "}
                  <Helper content="Encumbered quota means there is a fisher tied to the quota and the purchaser cannot assign their own fisher. If the quota is encumbered, the listing will also say who the fisher is upfront." />
                </>
              ),
              value: "Encumbered",
            },
            {
              label: (
                <>
                  Unencumbered{" "}
                  <Helper content="Unencumbered means there is no fisher nominated to the quota. This means you can appoint yourself or another licensed fisher to catch the quota." />
                </>
              ),
              value: "Unencumbered",
            },
          ]}
        />
      )}
      {map(
        filter(keys(AuctionDescriptionSelectList), (key) => {
          const types = get(
            AuctionDescriptionSelectList,
            `${key}.market_type`,
            []
          );
          return types.includes(marketType);
        }),
        (key, index) => {
          if (
            get(AuctionDescriptionSelectList, `${key}.type`, "") === "radio"
          ) {
            return (
              <Radio
                key={`${key}-${index}`}
                label={key}
                name={`additional_information.${replace(key, " ", "_")}`}
                className="lg:grid lg:grid-cols-2 lg:gap-4 space-y-4 lg:space-y-0"
                radioClassName="fish-primary rounded-md border border-blue-gray-300 bg-white p-3 items-center"
                options={get(
                  AuctionDescriptionSelectList,
                  `${key}.options`,
                  []
                )}
              />
            );
          }
          return (
            <Select
              key={`${key}-${index}`}
              label={key}
              name={`additional_information.${replace(key, " ", "_")}`}
              options={get(AuctionDescriptionSelectList, `${key}.options`, [])}
              placeholder="Select"
              className="form-select-lg"
            />
          );
        }
      )}
      <Editor
        name="description"
        label="Description"
        placeholder="Enter any additional information to describe your listing"
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="">
          <FlatpickrDate label="Auction ends date" name="expire_at_date" className="w-full" />
        </div>
        <div className="">
          <FlatpickrTime label="Auction ends time" name="expire_at_time" className="w-full" />
        </div>
      </div>
      {/* <DateSelect
        name="expire_at"
        label="Auction ends"
        className="form-input-lg"
        placeholder="Enter date"
      /> */}
      {showTermsAndConditions && (
        <Checkbox
          name="agree"
          content={
            <div className="flex items-center space-x-2">
              <p>
                I agree with the{" "}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleModal(true);
                  }}
                  className="text-fish-primary underline font-bold"
                >
                  Terms and Conditions
                </button>{" "}
                of the auction.
              </p>
            </div>
          }
        />
      )}
      <ModalWrapper
        title="Terms and Conditions"
        isOpen={isOpen}
        toggleModal={toggleModal}
        size="md"
      >
        <div className="pt-8 space-y-4">
          <p>
            1. The Seller must be legally unrestricted from selling the
            auctioned asset.
          </p>
          <p>
            2. There must be no existing 3rd party interests over any of the
            licenses or quotas associated with the assets being auctioned as of
            the auction listingdate.
          </p>
          <p>
            3. The Seller agrees to abide by the auction rules and understands
            that if they fail to set a reserve, the asset may be legally
            contracted to sale for the highest bid above your opening bid price.
            If you set a reserve and the reserve is met, you are also legally
            bound to sell at the highest bid price above reserve. BeachPrice Pty
            Ltd recommends setting a Reserve for all auctions.
          </p>
          <p>
            4. The Seller must agree to the Buyer being registered as a 3rd
            Party Interest with Queensland Fisheries until Settlement occurs.
          </p>
          <p>
            5. The Seller agrees to Pro Fishing Queensland acting as the Agent
            for purposes of contract subsequent to a successful auction;
            including as deposit holder from the Buyer.
          </p>
          <Button
            color="fish-primary"
            textColor="white"
            className="hover:bg-fish-primary-700"
            full
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleModal();
            }}
          >
            Confirm
          </Button>
        </div>
      </ModalWrapper>
    </>
  );
};

FifthStep.propTypes = {
  values: PropTypes.object,
};

FifthStep.defaultProps = {
  values: {},
};

export default FifthStep;


export const LIVE_STATES_FETCH_REQUEST = "static/live_states_fetch_request";
export const LIVE_STATES_FETCH_LOADING = "static/live_states_fetch_loading";
export const LIVE_STATES_FETCH_SUCCESS = "static/live_states_fetch_success";
export const LIVE_STATES_FETCH_FAILED = "static/live_states_fetch_failed";


export function fetchLiveStatesData(payload) {
  return {
    type: LIVE_STATES_FETCH_REQUEST,
    payload
  };
}
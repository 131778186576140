import React from "react";
import { orderStateMap } from "@config/enum";
import { OrderStatus } from "@core";
import { Button } from "@components";
import get from "lodash/get";

const Header = (props) => {
  const { order, history } = props;

  const renderStatus = () => {
    if (order.isAuctionOrder) {
      if (get(order, "order.market.isPermanent", false)) {
        return null;
      }

      if (!OrderStatus[get(order, "order.paidState", "")]) {
        return null;
      }
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal mt-4 md:mt-0 md:ml-4"
          size="xxs"
          color="green-100"
          textColor="green-500"
        >
          <span className="font-medium text-xxs">
            {OrderStatus[get(order, "order.paidState", "")]}
          </span>
        </Button>
      );
    }

    return (
      <div className="md:ml-10 flex justify-start items-center">
        {(order.paidState === orderStateMap.pending_payment ||
          order.paidState === orderStateMap.pending_confirmation) &&
        !order.paidHoldFee ? (
          <div className="text-xs md:text-lg text-fish-primary font-semibold bg-blue-100 py-2 px-4 mt-3 md:mt-0 rounded-full">
            Waiting for payment
          </div>
        ) : null}

        {(order.paidState === orderStateMap.pending_payment ||
          order.paidState === orderStateMap.pending_confirmation) &&
        order.paidHoldFee ? (
          /* 买家已付 holding fee */
          <div className="text-xs md:text-lg text-fish-primary font-semibold bg-blue-50 py-2 px-4 ml-6 rounded-full">
            Holding fee paid
          </div>
        ) : null}

        {order.paidState === orderStateMap.completed ? (
          /* 买家已付全款 */
          /*
              <div className="text-lg text-fish-primary font-semibold bg-blue-50 py-2 px-4 ml-6 rounded-full">
                Buyer paid - held on escrow
              </div>
              */
          /* 订单完成 */
          <div className="hidden text-xs md:text-lg text-blue-gray-600 font-semibold bg-gray-200 py-2 px-4 mt-2 rounded-full">
            Order completed
          </div>
        ) : null}

        {order.paidState === orderStateMap.closed ? (
          /* 订单因买家没付款，订单关闭 */
          <div className="text-xs md:text-lg text-blue-gray-600 font-semibold bg-gray-200 py-2 px-4 mt-2 rounded-full">
            Order closed
          </div>
        ) : null}

        {/*

            admin已转配额给买家
            <div className="text-lg text-fish-primary font-semibold bg-blue-50 py-2 px-4 ml-6 rounded-full">
              Quota transferred
            </div>

            钱款转账给卖家（这个目前阶段可没有，功能没有开发）
            <div className="text-lg text-fish-primary font-semibold bg-blue-50 py-2 px-4 ml-6 rounded-full">
              Funds released to Seller wallet
            </div>

             */}
      </div>
    );
  };

  return (
    <div className="relative">
      {/* <div
        className="flex items-center mb-4 cursor-pointer"
        onClick={() => {
          if (order.isFinished) {
            const url = get(order, "order.market.fisheryUrl");
            history && history.push(url || "/");
          } else {
            history && history.goBack();
          }
        }}
      >
        <svg
          width="25"
          height="25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.46 18.689l-6-6 6-6"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Back</span>
      </div> */}
      <div className="mt-6 md:mt-10 mb-8 mx-1">
        <div className="md:flex items-center">
          <div>
            <h2 className="mb-2">Order for {get(order, "title", "")}</h2>
            <div className="text-lg md:text-xl">
              Order #{order.serialNumber}
            </div>
          </div>
          {renderStatus()}
        </div>
        <p className="mt-4 md:mt-2 text-sm md:text-base text-blue-gray-500 ">
          {get(order, "order.originId", "")
            ? `${
                order.isAuctionOrder
                  ? "Auction"
                  : order.isP2POrder
                  ? "P2P"
                  : "Exchange"
              } #${get(order, "order.originId", "")} · ${order.createAtStr}`
            : ""}
        </p>
      </div>
    </div>
  );
};

export default Header;


export const ORDERS_FETCH_REQUEST = "orders/fetch_list_request";
export const ORDERS_FETCH_LOADING = "orders/fetch_list_loading";
export const ORDERS_FETCH_SUCCESS = "orders/fetch_list_success";
export const ORDERS_FETCH_FAILED = "orders/fetch_list_failed";


export function fetchOrderList(productGradeId) {
  return {
    type: ORDERS_FETCH_REQUEST,
    id: productGradeId,
  };
}

import React from "react";

import isEmpty from "lodash/isEmpty";
import upperFirst from "lodash/upperFirst";
import moment from "moment";

import TextField from "./text";
import SelectField from "./select";
import RadioField from "./radio";
import CheckBox from "./checkbox";
import CurrencyInput from "./currencyInput";

//validate./dateTime
export const required = (value) => {
  if (value instanceof Object) {
    return isEmpty(value) ? "This field is required" : undefined;
  }
  return value ? undefined : "This field is required";
};

export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minValue = (min, str) => (value) =>
  value && Number(value) < min
    ? `${str ? str : "Someone wants to buy at"} $${min.toFixed(2)}`
    : undefined;

export const maxValue = (max, str) => (value) =>
  value && Number(value) >= max
    ? `${str ? str : "Someone is selling at"} $${Number(max).toFixed(2)}`
    : undefined;

export const minNumber = (min, str, symbol) => (value) =>
  value && Number(value) < min
    ? `${
        str
          ? str.format &&
            str.format(
              `${symbol ? symbol : ""}${symbol ? Number(min).toFixed(2) : min}`
            )
          : "Must be higher than {0}".format(
              `${symbol ? symbol : ""}${symbol ? Number(min).toFixed(2) : min}`
            )
      }`
    : undefined;

export const maxNumber = (max, str, symbol) => (value) =>
  value && Number(value) > max
    ? `${
        str
          ? str.format &&
            str.format(
              `${symbol ? symbol : ""}${symbol ? Number(max).toFixed(2) : max}`
            )
          : "Must be less than {0}".format(
              `${symbol ? symbol : ""}${symbol ? Number(max).toFixed(2) : max}`
            )
      }`
    : undefined;

export const mustAfterDate = (value) =>
  value && moment().isAfter(value)
    ? "Must be a date greater than current date"
    : undefined;
// export const allowString = value => {
//   return value.match(/[^A-Za-z0-9-_() ]/gi) ? "No special characters are allowed." : undefined;
// };

//parse and format
export const formatFixedNumber = (val) => {
  let str = removeDollar(val).toString();
  str = str.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  // str = str.replace(/^\./g, ""); //验证第一个字符是数字而不是小数点
  str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  //只能输入一个小数点
  str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数

  return str;
};

export const formatInt = (value) => {
  if (value.length == 1) {
    return value.replace(/[^1-9]/g, "");
  }
  return value.replace(/\D/g, "");
};

export const formatNumber = (value) => {
  return value.replace(/[^\d.]/g, "");
};

export const trim = (value) => {
  return value.replace(/\s/g, "");
};

export const removeDollar = (value) => {
  return value.replace(/\$/g, "");
};

export const passwordsMustMatch = (value, allValues = {}) =>
  value !== allValues.password ? "Password do not match" : undefined;

export const reservePriceMustMore = (value, allValues = {}) =>
  Number(value) > 0 && Number(value) <= Number(allValues.price)
    ? "Reserve price must be more than starting price"
    : undefined;

//component
export const renderTextField = ({
  input,
  label,
  labelSub,
  value,
  id,
  type,
  disabled,
  readOnly,
  placeholder,
  bsSize,
  symbol,
  rightSymbol,
  align,
  className,
  labelClassName,
  wrapperClassName,
  symbolClass,
  step,
  rows,
  tips,
  meta: { touched, error },
}) => (
  <TextField
    value={value}
    type={type}
    bsSize={bsSize}
    label={label}
    labelSub={labelSub}
    id={id}
    disabled={disabled}
    readOnly={readOnly}
    error={touched && upperFirst(error)}
    placeholder={placeholder}
    symbol={symbol}
    rightSymbol={rightSymbol}
    align={align}
    step={step}
    rows={rows}
    tips={tips}
    className={className}
    labelClassName={labelClassName}
    wrapperClassName={wrapperClassName}
    symbolClass={symbolClass}
    {...input}
  />
);

export const renderSelectField = ({
  input,
  label,
  id,
  options,
  disabled,
  bsSize,
  align,
  defaultValue,
  placeholder,
  className,
  meta: { touched, error },
}) => (
  <SelectField
    label={label}
    id={id}
    options={options}
    disabled={disabled}
    bsSize={bsSize}
    error={touched && upperFirst(error)}
    align={align}
    placeholder={placeholder}
    defaultValue={defaultValue}
    className={className}
    {...input}
  />
);

export const renderRadioField = ({
  input,
  label,
  id,
  options,
  disabled,
  bsSize,
  align,
  inline,
  meta: { touched, error },
}) => (
  <RadioField
    label={label}
    id={id}
    options={options}
    disabled={disabled}
    bsSize={bsSize}
    error={touched && upperFirst(error)}
    align={align}
    inline={inline}
    {...input}
  />
);

export const renderCheckBox = ({
  input,
  label,
  id,
  meta: { touched, error },
}) => (
  <CheckBox
    label={label}
    id={id}
    checked={input.value}
    error={touched && upperFirst(error)}
    {...input}
  />
);

export const renderCurrencyInput = ({
  input,
  meta: { touched, error },
  ...rest
}) => (
  <CurrencyInput
    error={touched && upperFirst(error)}
    {...input}
    {...rest}
  />
);

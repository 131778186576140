import {VERIFY_LOADING, VERIFY_SUCCESS, VERIFY_FAILED, VERIFY_REQUEST} from './action'

function verifyStatus(state = {}, action = {}) {
  switch (action.type) {
    case VERIFY_LOADING:
      return {loading: true, data: null, error: null}
    case VERIFY_SUCCESS:
      return {loading: false, data: action.data, error: null}
    case VERIFY_FAILED:
      return {loading: false, data: null, error: action.error}
    default:
      return state
  }
}

export {verifyStatus}
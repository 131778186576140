import React from "react";

/**
 * import local tools
 */
import Layout from "@views/layout";
import Content from "./_content";

const Successful = props => {
  return (
    <Layout>
      <Content
        title="Payment successful"
        description="You will be redirected in a few seconds"
        type="successful"
        redirect={true}
      />
    </Layout>
  );
};

export default Successful;

import React from "react";

/**
 * import lib tools
 */

import { reduxForm, Field } from "redux-form";

import { renderTextField, renderCheckBox, required } from "@components/reduxform";

const WithdrawalForm = props => {
  const { handleSubmit, onMoneyChange } = props;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Field
          label="Account Name"
          name="account_name"
          type="text"
          // placeholder="Enter your account name"
          validate={required}
          component={renderTextField}
        />

        <Field
          label="Amount to withdraw"
          name="amount"
          type="number"
          // placeholder="Enter the money you want to withdraw"
          validate={required}
          component={renderTextField}
          onChange={onMoneyChange}
        />

        <Field
          label="Bank BSB"
          name="bank_bsb"
          type="text"
          // placeholder="Enter the bank bsb"
          validate={required}
          component={renderTextField}
        />

        <Field
          label="Account Number"
          name="account_number"
          type="text"
          // placeholder="Enter your account number"
          validate={required}
          component={renderTextField}
        />

        <div>
          <Field
            id="RememberAccount"
            name="remember_account"
            label={`Save for next time`}
            component={renderCheckBox}
          />
        </div>
      </form>
    </div>
  );
};

const VerifyFormWithReduxForm = reduxForm({
  form: "WithdrawalForm",
  enableReinitialize: true,
})(WithdrawalForm);

export default VerifyFormWithReduxForm;

export const MARKET_LIST = "markets/list";
export const MARKET_LIST_REQUEST = `${MARKET_LIST}_request`;
export const MARKET_LIST_SUCCESS = `${MARKET_LIST}_success`;
export const MARKET_LIST_FAILURE = `${MARKET_LIST}_failure`;

export const marketListAction = {
  request: (payload) => {
    return {
      type: MARKET_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: MARKET_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: MARKET_LIST_FAILURE,
      error,
    }
  },
};

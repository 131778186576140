import get from "lodash/get";
import toPairs from "lodash/toPairs";
import forEach from "lodash/forEach";

export default function objectToParams(obj) {
  let newObj = Object.assign({}, obj);
  let array = toPairs(newObj);
  return array.map((item) => {
    return `${item[0]}=${item[1]}`
  }).join('&');
}

export function getWindowParams() {
  let result = {};
  let str = get(window, "location.search", "");
  str = str.replace("?", "");
  let strArr = str.split('&');

  forEach(strArr, (item, index) => {
    let [k, v] = item.split("=");
    result[k] = v;
  });

  return result;
}


export function getParamsByStr(str = "") {
  let result = {};
  let str1 = str.split("?");
  let strArr = str1[1] ? str1[1].split('&') : [];

  forEach(strArr, (item, index) => {
    let [k, v] = item.split("=");
    result[k] = v;
  });

  return result;
}

export const OFFER_PRICES_SHOW_FETCH_REQUEST = "offer_prices/fetch_show_request";
export const OFFER_PRICES_SHOW_FETCH_LOADING = "offer_prices/fetch_show_loading";
export const OFFER_PRICES_SHOW_FETCH_SUCCESS = "offer_prices/fetch_show_success";
export const OFFER_PRICES_SHOW_FETCH_FAILED = "offer_prices/fetch_show_failed";
export const OFFER_PRICES_SHOW_FETCH_RECEIVED = "offer_prices/fetch_show_received";
export const OFFER_PRICES_SHOW_REFRESH = "offer_prices/show_refresh";


export function fetchOfferPriceShow(payload) {
  return {
    type: OFFER_PRICES_SHOW_FETCH_REQUEST,
    payload
  };
}


export function refreshOfferPriceShow(newData) {
  return {
    type: OFFER_PRICES_SHOW_REFRESH,
    data: newData,
  };
}
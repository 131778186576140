import {
  RESEND_EAMIL_CONFIRMATION_CODE_LOADING,
  RESEND_EAMIL_CONFIRMATION_CODE_SUCCESS,
  RESEND_EAMIL_CONFIRMATION_CODE_FAILED,
  RESEND_EAMIL_CONFIRMATION_CODE_REQUEST
} from './action'

function resendEmailConfirmationCodeStatus(state = {}, action = {}) {
  switch (action.type) {
    case RESEND_EAMIL_CONFIRMATION_CODE_LOADING:
      return { loading: true, data: null, error: null }
    case RESEND_EAMIL_CONFIRMATION_CODE_SUCCESS:
      return { loading: false, data: action.data, error: null }
    case RESEND_EAMIL_CONFIRMATION_CODE_FAILED:
      return { loading: false, data: null, error: action.error }
    default:
      return state
  }
}

export { resendEmailConfirmationCodeStatus }

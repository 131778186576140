import { takeLatest, call, put } from "redux-saga/effects";
import {get} from "lodash";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_FINAL_BID_SHOW_REQUEST, auctionFinalBidShowAction } from "./action";

//Fetch auction final bid show
export function* watchAuctionFinalBidShowFetch() {
  yield takeLatest(AUCTION_FINAL_BID_SHOW_REQUEST, auctionFinalBidShowFetch);
}

function* auctionFinalBidShowFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "finalBid"], action.payload);
    yield put(auctionFinalBidShowAction.success(get(res, "data", {})));
  } catch (err) {
    yield put(auctionFinalBidShowAction.failure(err));
  }
}

/**
 * Component Helper
 * @ref https://github.com/wwayne/react-tooltip
 * @example
 *
 * <Helper id={xxxx} content="xxxx" />
 * <Helper id={xxxx} content="xxxx" contentClass="xxx" />
 * <Helper id={xxxx} content="xxxx" theme="purple|success" />
 * <Helper id={xxxx} content="xxxx" placement="auto|top|bottom|left|right|... />
 */

import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import uniqid from "uniqid";

export default class Helper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: `helper_${uniqid()}`,
    };
  }

  render() {
    let { content, children, ...rest } = this.props;
    let { id } = this.state;
    let symClass = classnames(
      "help-wrapper",
      this.props.symClass,
      this.props.theme
    );
    return (
      <>
        {children ? (
          React.cloneElement(children, {
            "data-for": id,
            "data-tip": true,
          })
        ) : (
          <svg
            className={symClass}
            data-for={id}
            data-tip
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.5 9C1.5 13.1423 4.85775 16.5 9 16.5C13.1423 16.5 16.5 13.1423 16.5 9C16.5 4.85775 13.1423 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9ZM15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9ZM9.75 11.25V12.75H8.25V11.25H9.75ZM9.75001 10.5001V10.0164C10.9827 9.64896 11.7678 8.4426 11.6045 7.1667C11.4412 5.89079 10.3776 4.92103 9.0921 4.8759C7.80657 4.83078 6.67763 5.72357 6.42526 6.98489L7.89676 7.27964C8.01291 6.69852 8.55995 6.30715 9.14745 6.38485C9.73495 6.46255 10.1614 6.98268 10.1225 7.57402C10.0836 8.16535 9.59263 8.6251 9.00001 8.62514C8.5858 8.62514 8.25001 8.96092 8.25001 9.37514V10.5001H9.75001Z"
            />
          </svg>
        )}
        {content && (
          <ReactTooltip
            id={id}
            className="max-w-xs opacity-100"
            border
            textColor="#64748B"
            backgroundColor="white"
            borderColor="#E2E8F0"
            arrowColor="#E2E8F0"
            place="bottom"
            {...rest}
          >
            {content}
          </ReactTooltip>
        )}
      </>
    );
  }
}

Helper.defaultProps = {
  content: "", // required
};

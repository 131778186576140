export const PERMANENT_STATISTIC = "permanent/statistic";
export const PERMANENT_STATISTIC_REQUEST = `${PERMANENT_STATISTIC}_request`;
export const PERMANENT_STATISTIC_SUCCESS = `${PERMANENT_STATISTIC}_success`;
export const PERMANENT_STATISTIC_FAILURE = `${PERMANENT_STATISTIC}_failure`;

export const permanentStatisticAction = {
  request: (payload) => {
    return {
      type: PERMANENT_STATISTIC_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_STATISTIC_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_STATISTIC_FAILURE,
      error,
    }
  },
};

import {
    CART_OFFER_PRICES_FETCH_REQUEST,
    CART_OFFER_PRICES_FETCH_SUCCESS,
    CART_OFFER_PRICES_FETCH_FAILED,
  
  } from "./offerPricesAuction";
  export const cartOfferPricesStatus = (state = initStatus, action = {}) => {
    switch (action.type) {
      case CART_OFFER_PRICES_FETCH_REQUEST:
        return {
          loading: true,
          data: [],
          error: null,
        };
        break;
  
      case CART_OFFER_PRICES_FETCH_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null,
        };
        break;
  
      case CART_OFFER_PRICES_FETCH_FAILED:
        return {
          loading: false,
          data: [],
          error: action.error,
        };
        break;
  
      default:
        return state;
    }
  };
  
  const initStatus = {
    loading: false,
    data: [],
    error: null,
  };
import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_PROFILE_REGISTER_REQUEST, accountProfileRegisterAction } from "./action";

// update bank info
export function* watchAccountProfileRegisterFetch() {
  yield takeLatest(ACCOUNT_PROFILE_REGISTER_REQUEST, profileRegister);
}

function* profileRegister(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "profileRegister"], action.payload);
    yield put(accountProfileRegisterAction.success(res.data));
  } catch (err) {
    yield put(accountProfileRegisterAction.failure(err));
  }
}

export const HOME_PRODUCT_LIST_TAB_CHANGE = "home/product_list_tab_change";

/**
 * 首页产品列表 tab 切换
 * @param  {Object} payload tab数据
 * @return {Object}
 * {type: HOME_PRODUCT_LIST_TAB_CHANGE, payload: {tabId: "tab1"}}
 * {type: HOME_PRODUCT_LIST_TAB_CHANGE, payload: {tabId: "其他 tab id"}}
 */
export function homeProductListTabChange(payload) {
  return {
    type: HOME_PRODUCT_LIST_TAB_CHANGE,
    payload
  };
}
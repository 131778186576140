export const PRICEABLES_FETCH_REQUEST = "priceables/fetch_list_request";
export const PRICEABLES_FETCH_LOADING = "priceables/fetch_list_loading";
export const PRICEABLES_FETCH_SUCCESS = "priceables/fetch_list_success";
export const PRICEABLES_FETCH_FAILED = "priceables/fetch_list_failed";
export const PRICEABLES_REFRESH = "priceables/refresh";

export const PRICEABLES_AUCTIONS_FETCH_REQUEST = "priceables/auctions_fetch_request";
export const PRICEABLES_AUCTIONS_FETCH_LOADING = "priceables/auctions_fetch_loading";
export const PRICEABLES_AUCTIONS_FETCH_FAILED = "priceables/auctions_fetch_failed";
export const PRICEABLES_SINGLE_AUCTION_FETCH_SUCCESS =
  "priceables/single_auction_fetch_success";
export const PRICEABLES_AUCTIONS_FETCH_SUCCESS =
  "priceables/auctions_fetch_success";

/**
 * Fetch priceables list
 * @param {Object} payload {refetch: true | false}
 * @param {Object} pre 前缀 {categoryId: 1} | {productGradeId: 1}
 */
export function fetchPriceablesList(payload, pre = {}) {
  return {
    type: PRICEABLES_FETCH_REQUEST,
    payload,
    pre,
  };
}

export function fetchPriceableAuctionsList(payload, pre = {}) {
  return {
    type: PRICEABLES_AUCTIONS_FETCH_REQUEST,
    payload,
    pre,
  };
}

export function refreshPriceable(payload) {
  return {
    type: PRICEABLES_REFRESH,
    payload
  };
}

export function refreshPriceableSingleAuction(payload) {
  return {
    type: PRICEABLES_SINGLE_AUCTION_FETCH_SUCCESS,
    payload
  };
}

export function refreshPriceableAuctionsList(payload) {
  return {
    type: PRICEABLES_AUCTIONS_FETCH_SUCCESS,
    data: payload
  };
}
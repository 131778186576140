import map from "lodash/map";
import filter from "lodash/filter";
import isArray from "lodash/isArray";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";

import { AuctionShowUsecase } from "./show";
import numeral from "@utils/numeral";
import { Moment } from "@core";

export class AuctionListUsecase {
  /**
   * 构造函数
   * @param {Object} props
   * @param {Object} user currentUserStatus
   */
  constructor(props, user) {
    this.data = props;
    this.userData = user;
  }

  get list() {
    if (!isArray(this.filterData)) {
      return [];
    }
    return map(
      this.filterData,
      (data) => new AuctionShowUsecase(data, this.userData)
    );
  }

  get filterData() {
    return uniqBy(
      filter(this.data, (item) => !isEmpty(item)),
      "id"
    );
  }

  get sortedList() {
    //按照过期时间来排序
    return sortBy(this.list, (item) => {
      const momentUsecase = new Moment(get(item, "show.expiredAt", ""));
      return numeral(`${momentUsecase.formatTimestamp}`).value();
    });
  }

  get activeList() {
    return filter(
      this.list,
      (item) => !item.status.end && item.status.approved
    );
  }

  get pastList() {
    return this.list;
    // return filter(this.list, (item) => item.status.end);
  }

  get unapprovedList() {
    return filter(this.list, (item) => !item.status.approved);
  }

  get unfinishedList() {
    return filter(this.list, (item) => !item.status.end);
  }
}

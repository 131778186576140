import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import toBoolean from "./toBoolean";
import { links } from "@config/staticLink";

export default function(ComposedComponent) {
  class CheckUserProfile extends React.Component {
    componentWillMount() {
      this.checkUserProfile(this.props);
    }

    componentWillUpdate(nextProps) {
      if (nextProps.user != this.props.user) {
        this.checkUserProfile(nextProps);
      }
    }

    checkUserProfile = (props) => {
      const pathName = window.location.pathname;
      const search = window.location.search;
      const isDashboard = pathName.match(/dashboard/g);
      const href = window.location.href;
      const redirectTo = isDashboard ? "" : `?redirect_to=${pathName}${search}`;

      const { isAuthenticated, user } = props;
      if (!isAuthenticated) {
        window.location.href = `${links.sign_in}?redirect_to=${href}`;
        return
      }
      if (!toBoolean(get(user, "email_verified", false))) {
        return (
          this.props.history && this.props.history.push("/account/email/verify")
        );
      }
      if (toBoolean(get(user, "custom:is_filled", false))) {
        return;
      }
      window.location.href = links.profile_edit;
      // this.props.history && this.props.history.push(`/account/profile?new_register=true&redirect_to=/`);
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: get(state, "currentUserStatus.isAuthenticated", false),
      user: get(state, "currentUserStatus.user", "")
    };
  }

  return withRouter(
    connect(
      mapStateToProps,
      {}
    )(CheckUserProfile)
  );
}

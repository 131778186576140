/**
 * timer
 * @param  {Integer} intDiff [seconds of diff]
 * @return {String}         "1day, 3hours : 03mins" | "03mins : 23secs"
 */
export default function timer(intDiff) {
  let day = 0,
    hour = 0,
    minute = 0,
    second = 0;
  if (intDiff > 0) {
    day = Math.floor(intDiff / (60 * 60 * 24));
    hour = Math.floor(intDiff / (60 * 60)) - day * 24;
    minute = Math.floor(intDiff / 60) - day * 24 * 60 - hour * 60;
    second =
      Math.floor(intDiff) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
  }
  if (hour <= 9) hour = "0" + hour;
  if (minute <= 9) minute = "0" + minute;
  if (second <= 9) second = "0" + second;
  // if (intDiff < 60 * 60) {
  //   return minute + "mins : " + second + "secs";
  // }
  if (day == 0) {
    return hour + " hours, " + minute + " mins, " + second + " secs";
  }
  return day + " days, " + hour + " hours, " + minute + " mins";
}

export function simpleTimer(intDiff) {
  let day = 0,
    hour = 0,
    minute = 0,
    second = 0;
  if (intDiff > 0) {
    day = Math.floor(intDiff / (60 * 60 * 24));
    hour = Math.floor(intDiff / (60 * 60)) - day * 24;
    minute = Math.floor(intDiff / 60) - day * 24 * 60 - hour * 60;
    second =
      Math.floor(intDiff) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
  }
  if (hour <= 9) hour = "0" + hour;
  if (minute <= 9) minute = "0" + minute;
  if (second <= 9) second = "0" + second;

  if (day > 0) {
    if (day == 1) {
      return "1 day";
    }
    return day + " days";
  }

  if (hour > 0) {
    if (hour == 1) {
      return "1 hour";
    }
    return hour + " hours";
  }

  if (minute > 0) {
    if (minute == 1) {
      return "1 min";
    }
    return minute + " mins";
  }

  if (second > 0) {
    if (second == 1) {
      return "1 sec";
    }
    return second + " secs";
  }

  return "";
}

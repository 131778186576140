import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useRouteMatch } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Loading, Link, Mobile, DesktopAndTablet } from "@components";

const BidHistory = ({ data, list, loading, wrapperClass, isLogin }) => {
  const match = useRouteMatch();
  const loginUrl = get(match, "url", "")
    ? `/login?redirect_to=${get(match, "url", "")}`
    : "/login";
  const renderEmpty = () => {
    return (
      <div className="mt-6 p-4 lg:p-6 mb-4 lg:mb-6 rounded-lg bg-gray-50 text-center whitespace-normal">
        No bids at the moment
      </div>
    );
  };

  const renderLogin = () => {
    return (
      <div className="mt-6 p-4 lg:p-6 mb-4 lg:mb-6 rounded-lg bg-gray-50 text-center whitespace-normal">
        <Link to={loginUrl} className="underline hover:text-fish-primary">
          Login to see bids history
        </Link>
      </div>
    );
  };

  const renderListDesktop = () => {
    /** 2c: 955 -> Buy it now used successful */
    if(get(data,"status.isSuccessByFixedPriceSold")){
      return (
        <tr>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50 rounded-l-lg">
            <div className="text-sm font-normal">
              {data.status.isWinner?(
                <span className="text-sm font-bold text-blue-gray-800">
                  Me
                </span>
              ):(
                <span className="text-blue-gray-500 leading-none">
                  Winning Bidder
                </span>
              )}
            </div>
          </td>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50">
            {get(data, "show.createdAtStr", "")}
          </td>
          <td className="px-4 lg:px-5 py-3 bg-gray-50 rounded-r-lg">
            <div className="flex space-x-4 justify-end">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Sold by Buy It Now
              </span>
              <span className="whitespace-no-wrap text-sm font-medium text-blue-gray-900">{get(data, "show.fixedPriceFormat", "")}</span>
            </div>
          </td>
        </tr>
      )
    }
    /** 2d: 966 -> final offer successful */
    if(get(data,"status.isSuccessByFinalChanceAccepted") && get(data, "show.endPrice",0) > 0){
      return(
        <tr>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50 rounded-l-lg">
            <div className="text-sm font-normal">
              {data.bidderStatus.isHighestBidder?(
                <span className="text-sm font-bold text-blue-gray-800">
                  Me
                </span>
              ):(
                <span className="text-blue-gray-500 leading-none">
                  Highest Bidder
                </span>
              )}
            </div>
          </td>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50">
            {get(data, "show.lastBidExpiredAt", "")}
          </td>
          <td className="px-4 lg:px-5 py-3 bg-gray-50 rounded-r-lg">
            <div className="flex space-x-4 justify-end">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Final Offer Accepted
              </span>
              <span className="whitespace-no-wrap text-sm font-medium text-blue-gray-900">{get(data, "show.endPriceFormat", "")}</span>
            </div>
          </td>
        </tr>
      )
    }
    /** 1a: 965 961 -> final offer not successful, reserve not met, filed */
    if(get(data,"status.isFailedByFinalChanceRejected") && get(data,"status.underReserve") && get(data, "show.endPrice",0) > 0){
      return(
        <tr>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50 rounded-l-lg">
            <div className="text-sm font-normal">
              {data.bidderStatus.isHighestBidder?(
                <span className="text-sm font-bold text-blue-gray-800">
                  Me
                </span>
              ):(
                <span className="text-blue-gray-500 leading-none">
                  User {get(data,"show.lastBidAliasName")}
                </span>
              )}
            </div>
          </td>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50">
            {get(data, "show.lastBidExpiredAt", "")}
          </td>
          <td className="px-4 lg:px-5 py-3 bg-gray-50 rounded-r-lg">
            <div className="flex space-x-4 justify-end">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Final Offer
              </span>
              <span className="whitespace-no-wrap text-sm font-medium text-blue-gray-900">{get(data, "show.endPriceFormat", "")}</span>
            </div>
          </td>
        </tr>
      )
    }
    /** 1b: 970 -> no final offer, reserve not met, filed */
    if(!get(data,"status.isFinalChanceSet") && get(data,"status.hasLastBid") && get(data,"status.underReserve") && get(data, "show.endPrice",0) > 0){
      return(
        <tr>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50 rounded-l-lg">
            <div className="text-sm font-normal">
              {data.bidderStatus.isHighestBidder?(
                <span className="text-sm font-bold text-blue-gray-800">
                  Me
                </span>
              ):(
                <span className="text-blue-gray-500 leading-none">
                  Highest Bidder
                </span>
              )}
            </div>
          </td>
          <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50">
            {get(data, "show.lastBidExpiredAt", "")}
          </td>
          <td className="px-4 lg:px-5 py-3 bg-gray-50 rounded-r-lg">
            <div className="flex space-x-4 justify-end">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Max Bid
              </span>
              <span className="whitespace-no-wrap text-sm font-medium text-blue-gray-900">{get(data, "show.endPriceFormat", "")}</span>
            </div>
          </td>
        </tr>
      )
    }

  }
  const renderListMobile = () => {
    /** 2c: 955 -> Buy it now used successful */
    if(get(data,"status.isSuccessByFixedPriceSold")){
      return (
        <div className="">
          <div className="px-4 py-3 bg-gray-50 rounded-lg">
            <div className="flex justify-between items-center mb-1">
              <div className="text-sm">
                {data.status.isWinner?(
                  <span className="text-sm font-bold text-blue-gray-800">
                    Me
                  </span>
                ):(
                  <span className="text-blue-gray-500 leading-none">
                    Winning Bidder
                  </span>
                )}
              </div>
              <div className="text-sm font-normal leading-snug text-blue-gray-500">
                {get(data, "show.createdAtStr", "")}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Sold by Buy It Now
              </span>
              <div className="text-sm font-medium leading-tight text-blue-gray-900 text-right">
                {get(data, "show.fixedPriceFormat", "")}
              </div>
            </div>
          </div>
        </div>
      )
    }
    /** 2d: 966 -> final offer successful */
    if(get(data,"status.isSuccessByFinalChanceAccepted") && get(data, "show.endPrice",0) > 0){
      return(
        <div className="">
          <div className="px-4 py-3 bg-gray-50 rounded-lg">
            <div className="flex justify-between items-center mb-1">
              <div className="text-sm">
                {data.bidderStatus.isHighestBidder?(
                  <span className="text-sm font-bold text-blue-gray-800">
                    Me
                  </span>
                ):(
                  <span className="text-blue-gray-500 leading-none">
                    Highest Bidder
                  </span>
                )}
              </div>
              <div className="text-sm font-normal leading-snug text-blue-gray-500">
                {get(data, "show.lastBidExpiredAt", "")}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Final Offer Accepted
              </span>
              <div className="text-sm font-medium leading-tight text-blue-gray-900 text-right">
                {get(data, "show.endPriceFormat", "")}
              </div>
            </div>
          </div>
        </div>
      )
    }
    /** 1a: 965 961 -> final offer not successful, reserve not met, filed */
    if(get(data,"status.isFailedByFinalChanceRejected") && get(data,"status.reservePrice") && get(data,"status.underReserve") && get(data, "show.endPrice",0) > 0){
      return(
        <div className="">
          <div className="px-4 py-3 bg-gray-50 rounded-lg">
            <div className="flex justify-between items-center mb-1">
              <div className="text-sm">
                {data.bidderStatus.isHighestBidder?(
                  <span className="text-sm font-bold text-blue-gray-800">
                    Me
                  </span>
                ):(
                  <span className="text-blue-gray-500 leading-none">
                    User {get(data,"show.lastBidAliasName")}
                  </span>
                )}
              </div>
              <div className="text-sm font-normal leading-snug text-blue-gray-500">
                {get(data, "show.lastBidExpiredAt", "")}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Final Offer
              </span>
              <div className="text-sm font-medium leading-tight text-blue-gray-900 text-right">
                {get(data, "show.endPriceFormat", "")}
              </div>
            </div>
          </div>
        </div>
      )
    }
    /** 1b: 970 -> no final offer, reserve not met, filed */
    if(!get(data,"status.isFinalChanceSet") && get(data,"status.hasLastBid") && get(data,"status.reservePrice") && get(data,"status.underReserve") && get(data, "show.endPrice",0) > 0){
      return(
        <div className="">
          <div className="px-4 py-3 bg-gray-50 rounded-lg">
            <div className="flex justify-between items-center mb-1">
              <div className="text-sm">
                {data.bidderStatus.isHighestBidder?(
                  <span className="text-sm font-bold text-blue-gray-800">
                    Me
                  </span>
                ):(
                  <span className="text-blue-gray-500 leading-none">
                    User {get(data,"show.lastBidAliasName")}
                  </span>
                )}
              </div>
              <div className="text-sm font-normal leading-snug text-blue-gray-500">
                {get(data, "show.lastBidExpiredAt", "")}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                Max Bid
              </span>
              <div className="text-sm font-medium leading-tight text-blue-gray-900 text-right">
                {get(data, "show.endPriceFormat", "")}
              </div>
            </div>
          </div>
        </div>
      )
    }

  }

  const renderDesktop = () => {
    return (
      <div className={wrapperClass}>
        {loading ? (
          <Loading />
        ) : !isLogin ? (
          renderLogin()
        ) : isEmpty(list) ? (
          renderEmpty()
        ) : (
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto lg:-mx-6 lg:-mx-8">
              <div className="align-middle inline-block min-w-full lg:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table
                    className="min-w-full"
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0 8px",
                    }}
                  >
                    <tbody className="bg-white">
                      {renderListDesktop()}
                      {map(list, (item, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50 rounded-l-lg">
                              <div className="text-sm font-normal">
                                {item.isMe ? (
                                  <span className="text-sm font-bold text-blue-gray-800">
                                    Me
                                  </span>
                                ) : (
                                  <span className="text-blue-gray-500 leading-none">
                                    {`User ${item.name}`}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-4 lg:px-5 py-3 whitespace-no-wrap text-sm text-blue-gray-500 bg-gray-50">
                              {item.createdAtStr}
                            </td>
                            <td className="px-4 lg:px-5 py-3 bg-gray-50 rounded-r-lg">
                              <div className="flex space-x-4 justify-end">
                                {!data.status.isSuccessByFixedPriceSold && !data.status.end && (get(data, "show.endPrice",0)<=0) && (index == 0) && (
                                  <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                                    Highest
                                  </span>
                                )}
                                <span className="whitespace-no-wrap text-sm font-medium text-blue-gray-900">{item.priceStr}</span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div>
        {loading ? (
          <Loading />
        ) : !isLogin ? (
          renderLogin()
        ) : isEmpty(list) ? (
          renderEmpty()
        ) : (
          <div className="space-y-2">
            {renderListMobile()}
            {map(list, (item, index) => {
              return (
                <div key={index} className="">
                  <div className="px-4 py-3 bg-gray-50 rounded-lg">
                    <div className="flex justify-between items-center mb-1">
                      <div className="text-sm">
                        {item.isMe ? (
                          <span className="text-sm font-bold text-blue-gray-800">
                            Me
                          </span>
                        ) : (
                          <span className="text-blue-gray-500 leading-none">
                            {`User ${item.name}`}
                          </span>
                        )}
                      </div>
                      <div className="text-sm font-normal leading-snug text-blue-gray-500">
                        {item.createdAtStr}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="text-sm">
                        {!data.status.isSuccessByFixedPriceSold && !data.status.end && (get(data, "show.endPrice",0)<=0) && (index == 0) && (
                          <span className="badge badge-primary text-xxs font-medium lg:ml-3">
                            Highest
                          </span>
                        )}
                      </div>
                      <div className="text-sm font-medium leading-tight text-blue-gray-900 text-right">
                        {item.priceStr}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <DesktopAndTablet>{renderDesktop()}</DesktopAndTablet>
      <Mobile>{renderMobile()}</Mobile>
    </div>
  );
};

BidHistory.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  hasBottomBorder: PropTypes.bool,
  isLogin: PropTypes.bool,
};

BidHistory.defaultProps = {
  list: [],
  loading: false,
  hasBottomBorder: false,
  isLogin: false,
};

export default BidHistory;

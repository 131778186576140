import { LicenceApiRepo } from "@core"

export class LicenceApiUsecase {
  constructor() {
    this.repo = new LicenceApiRepo()
  }

  update(payload) {
    return this.repo.update(payload)
  }
}
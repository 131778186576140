import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import {userSession, currentAuthenticatedUser, currentUser} from './session'

/**
 * Update email of CognitoUser
 * @param {Object} user - The CognitoUser object
 * @param {String} email - The updated new email
 * @return {Promise}
 */
function updateUserEmail(user, email) {
  return userSession(user)
    .then(session => {
      return new Promise((resolve, reject) => {
        let attribute = {Name: 'email', Value: email}
        attribute = new CognitoUserAttribute(attribute)
        let attributeList = [attribute]

        user.updateAttributes(attributeList, (err, result) => {
          if (err) { reject(err) } else { resolve(result) }
        })
      })
    })
}

/**
 * Update email of current user
 * @param email
 * @return {Promise}
 */
function updateCurrentUserEmail(email) {
  let cognitoUser = currentUser()
  return updateUserEmail(cognitoUser, email)
}

/**
 * Initialize an email confirmation request for the current user
 * @return {Promise} A promise resolves to callback data if success
 */
function resendCurrentUserEmailVerificationCode() {
  return currentAuthenticatedUser()
    .then(user => resendUserEmailVerificationCode(user))
}

/**
 * Initialize an email confirmation request
 * @param {Object} user
 * @return {Promise<any>} A promise resolves to callback data if success
 */
function resendUserEmailVerificationCode(user) {
  return new Promise((resolve, reject) => {
    user.getAttributeVerificationCode('email', {
      onSuccess: (data) => {
        // console.log(`Resend user email verification code success: ${data}`)
        resolve(data)
      },
      onFailure: (err) => {
        // console.log(`Resend user email verification code failed: ${err}`)
        reject(err)
      }
    })
  })
}

/**
 * Confirm an email using a confirmation code
 * @param {Object} user - The CognitoUser
 * @param {Object} code - The confirmation code
 * @return {Promise<any>} A promise resolves to callback data if success
 */
function verifyEmailVerificationCode(user, code) {
  return new Promise((resolve, reject) => {
    user.verifyAttribute('email', code, {
      onSuccess: data => {
        // console.log(`Verify email verification code success: ${data}`)
        resolve(data)
      },
      onFailure: err => {
        // console.log(`Verify email verification code failed: ${err}`)
        reject(err)
      }
    })
  })
}

/**
 * Confirm current user's email using a confirmation code
 * @param {String} code - The confirmation code
 * @return {Promise} - A promise resovles to callback data if success
 */
function verifyCurrentUserEmailVerificationCode(code) {
  return currentAuthenticatedUser()
    .then(user => verifyEmailVerificationCode(user, code))
}

export {
  updateUserEmail,
  updateCurrentUserEmail,
  resendCurrentUserEmailVerificationCode,
  resendUserEmailVerificationCode,
  verifyCurrentUserEmailVerificationCode,
  verifyEmailVerificationCode
}


import {
  ASKING_PRICES_SHOW_FETCH_LOADING,
  ASKING_PRICES_SHOW_FETCH_SUCCESS,
  ASKING_PRICES_SHOW_FETCH_FAILED,
  ASKING_PRICES_SHOW_FETCH_RECEIVED,
  ASKING_PRICES_SHOW_REFRESH,
} from "./showAction";


export const askingPriceShowStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ASKING_PRICES_SHOW_FETCH_LOADING:
      return {
        loading: true,
        justFinished: false,
        data: {},
        error: null,
      };
      break;

    case ASKING_PRICES_SHOW_FETCH_SUCCESS:
      return {
        loading: false,
        justFinished: true,
        data: action.data,
        error: null,
      };
      break;

    case ASKING_PRICES_SHOW_FETCH_FAILED:
      return {
        loading: false,
        justFinished: true,
        data: {},
        error: action.error,
      };
      break;

    case ASKING_PRICES_SHOW_FETCH_RECEIVED:
      return Object.assign({}, state, { justFinished: false });
      break;

    case ASKING_PRICES_SHOW_REFRESH:
      return {
        loading: false,
        justFinished: false,
        data: action.data,
        error: null,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  justFinished: false,
  loading: false,
  data: {},
  error: null,
};
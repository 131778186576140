import {
  INVOICE_MODAL_BANK_STATUS_CHANGE,
} from "./invoiceAction";

export default function invoiceState(state = initStatus, action = {}) {
  let modalBank = state.modalBank;
  switch (action.type) {
    case INVOICE_MODAL_BANK_STATUS_CHANGE:
      modalBank.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        modalBank
      });
      break;

    default:
      return state;
  }
}

const initStatus = {
  modalBank: {
    isOpen: false,
  }
};

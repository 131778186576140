import { takeLatest, call, put } from "redux-saga/effects";

import { QuotaAttrApiUsecase } from "@core";
import { CATEGORIES_LIST_REQUEST, categoriesListAction } from "./action";

// fetch quota list
export function* watchcategoriesListFetch() {
  yield takeLatest(CATEGORIES_LIST_REQUEST, categoriesListFetch);
}

function* categoriesListFetch(action) {
  try {
    const usecase = new QuotaAttrApiUsecase();
    const res = yield call([usecase, "categories"], action.payload);
    yield put(categoriesListAction.success(res.data));
  } catch (err) {
    yield put(categoriesListAction.failure(err));
  }
}

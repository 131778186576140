import axios from "axios";
import { currentIdToken, currentCookieToken } from "@utils/cognitoAuth/session";

// const currentIdToken = () => {
//   return new Promise((resove, reject) => {
//     setTimeout(() => {
//       reject("Catch error");
//     }, 4000);
//   });
// };

export default function setAuthorizationToken() {
  // if (token) {
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // } else {
  //   delete axios.defaults.headers.common['Authorization'];
  // }
  axios.interceptors.request.use(
    config => {
      return currentCookieToken()
        .then(token => {
          config.headers.Authorization = `Bearer ${token}`;
          return Promise.resolve(config);
        })
        .catch(err => {
          // window.Sentry && window.Sentry.captureMessage(err);
          // if (err === "No current user") {
          //   return Promise.resolve(config);
          // }
          return Promise.resolve(config);
        });
    },
    error => {
      // window.Sentry && window.Sentry.captureMessage(error);
      return Promise.reject(error);
    }
  );
}

import React from "react";
import PropTypes from "prop-types";

/**
 * lib tools
 */
 import get from "lodash/get";
 import numeral from "@utils/numeral";

 /**
 * import core
 */
import { fishnetClientNumber } from "@core";

/**
 * Hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * Components
 */
import {Button} from "@components"

const StepEnd = ({values, listingOfferNewStatus}) => {
  const id = get(listingOfferNewStatus, "data.id", "");
  const quantity = get(listingOfferNewStatus, "data.quantity", 0);
  const price = get(listingOfferNewStatus, "data.price.price_with_symbol", 0);

  const market = get(values, "market.market", {}) || {};
  const { amountStr, unitOfPrice } = useMarketUnitName(
    market,
    quantity
  ); 
  return (
    <div className="space-y-4">
      <div className="p-4 rounded-lg" style={{ color: "#04505C", background: "#ECFAFA" }}>
        <div className="font-bold text-sm mb-1">Transfer your quota</div>
        <div className="text-xxs">
          Your listing has been created but won’t be activated until your quota
          has been transferred to us for consignment
        </div>
      </div>
      <div className="bg-gray-50 p-4 rounded-lg space-y-2">
        <div className=" font-bold text-sm mb-3">
          How to transfer quota to us on consignment
        </div>
        <ol className="text-xxs space-y-2 list-decimal list-outside pl-3">
          <li className="">Log into your Fishnet account</li>
          <li>
            Transfer <strong>{amountStr}</strong> of {market.title} seasonal quota to
            BeachPrice’s Fishnet client number <strong>{fishnetClientNumber}</strong>
          </li>
          <li>
            We will check the status of your transfer each day, and will
            activate your listing once your quota arrives in your Fishnet
            account. You will receive an email when this happens.
          </li>
        </ol>
      </div>
      <ul className="bg-gray-50 p-4 rounded-lg space-y-3">
        <li className="flex items-center justify-between">
          <span className="text-blue-gray-500 text-sm">Status</span>
          <Button
            className="rounded-full text-xs font-medium leading-normal"
            size="xxs"
            color="red-100"
            textColor="red-500"
          >
            Quota not consigned yet
          </Button>
        </li>
        <li className="flex items-center justify-between">
          <span className="text-blue-gray-500 text-sm">Listing id</span>
          <span className="text-blue-gray-700 text-sm font-medium">
            #{id}
          </span>
        </li>
        <li className="flex items-center justify-between">
          <span className="text-blue-gray-500 text-sm">Quantity</span>
          <span className="text-blue-gray-700 text-sm font-medium">{amountStr}</span>
        </li>
        <li className="flex items-center justify-between">
          <span className="text-blue-gray-500 text-sm">Listing price</span>
          <span className="text-blue-gray-700 text-sm font-medium">{price}/{unitOfPrice}</span>
        </li>
      </ul>
    </div>
  );
};

StepEnd.propTypes = {};

export default StepEnd;

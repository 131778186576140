import { takeEvery, all,  call, put } from "redux-saga/effects";
import { confirmPassword } from "@utils/cognitoAuth";
import {
  CONFIRM_REQUEST,
  confirmSuccess,
  confirmRequest,
  confirmLoading,
  confirmFailed
} from "./confirmAction";
import { refreshCurrentUser } from "../auth/action";

function* watchConfirmForgotPasswordRequest() {
  yield takeEvery(CONFIRM_REQUEST, confirmForgotPassword);
}

function* confirmForgotPassword(action) {
  yield put(confirmLoading());

  let { res, err } = yield call(confirmForgotPasswordApi, action.payload);
  if (err) {
    return yield put(confirmFailed(err));
  }
  yield all([put(confirmSuccess("SUCCESS")), put(refreshCurrentUser())]);
}

function* confirmForgotPasswordApi({ email, code, newPassword }) {
  try {
    const apiResponse = yield call(confirmPassword, email, code, newPassword);
    return { res: apiResponse, err: null };
  } catch (err) {
    return { res: null, err: err };
  }
}

export { watchConfirmForgotPasswordRequest };

import { takeLatest, call, put } from "redux-saga/effects";

import { OrderApiUsecase } from "@core";
import { ORDER_LIST_REQUEST, orderListAction } from "./action";

// order list
export function* watchOrderListFetch() {
  yield takeLatest(ORDER_LIST_REQUEST, orderListFetch);
}

function* orderListFetch(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(orderListAction.success(res.data));
  } catch (err) {
    yield put(orderListAction.failure(err));
  }
}

import {
  WALLET_WITHDRAWAL_SHOW_LOADING,
  WALLET_WITHDRAWAL_SHOW_SUCCESS,
  WALLET_WITHDRAWAL_SHOW_FAILED,
} from "./withdrawalShowAction";

export function walletWithdrawalShowStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_WITHDRAWAL_SHOW_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_WITHDRAWAL_SHOW_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
      });

    case WALLET_WITHDRAWAL_SHOW_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: {},
        error: action.error,
      });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: {},
  error: null,
};

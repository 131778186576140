import {takeEvery, call, put} from 'redux-saga/effects';
import axios from "axios";

import {
  LICENCE_NUMBER_EVENTS_REQUEST,
  licenceNumberEventsLoading,
  licenceNumberEventsSuccess,
  licenceNumberEventsFailed,
} from "./action";
import {refreshCognitoUserRequest} from "../refreshCognitoUser/action";

import {licenceNumberByOrderIdAPI} from "@config/api";

function* watchLicenceNumberEventsRequest() {
  yield takeEvery(LICENCE_NUMBER_EVENTS_REQUEST, licenceNumberEvents)
}

function* licenceNumberEvents(action) {
  yield put(licenceNumberEventsLoading())
  let {res, err} = yield call(licenceNumberEventsApi, action.payload)
  if (err) {
    return yield put(licenceNumberEventsFailed("FAILED"))
  }
  yield put(licenceNumberEventsSuccess("SUCCESS"));
  yield put(refreshCognitoUserRequest());
}

function licenceNumberEventsApi({orderId, licence_number}) {
  return axios
    .put(licenceNumberByOrderIdAPI(orderId),{licence_number})
    .then(res => ({
      res: res
    }))
    .catch(err => ({
      err
    }));
}


export {watchLicenceNumberEventsRequest}
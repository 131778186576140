import { useCallback, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionListUsecase } from "@core";

import {
  createdAuctionsAction,
  CREATED_AUCTIONS,
} from "@redux/modules_v2/account/createdAuctions/action";

//Hooks
import { useCurrentUser } from "@hooks";

/**
 * Fetch lists hooks
 */
export const useAccountCreatedAuctions = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const fetchList = useCallback((params) => {
    dispatch(createdAuctionsAction.request(params));
  }, []);

  const accountCreatedAuctionsStatus = useSelector((state) => {
    const selector = new LoadingSelector(CREATED_AUCTIONS, state);
    return Object.assign({}, get(state, "accountCreatedAuctionsStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = accountCreatedAuctionsStatus;

  const usecase = new AuctionListUsecase(data, get(currentUser, "user", {}));

  useEffect(() => {
    fetchList();
  }, []);

  return {
    data: usecase.list,
    loading,
    fetchList,
  };
};

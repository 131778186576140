export const BID_ORDER = "bids/order";
export const BID_ORDER_REQUEST = `${BID_ORDER}_request`;
export const BID_ORDER_SUCCESS = `${BID_ORDER}_success`;
export const BID_ORDER_FAILURE = `${BID_ORDER}_failure`;
export const BID_ORDER_RECEIVED = `${BID_ORDER}_received`;

export const bidOrderAction = {
  request: (payload) => {
    return {
      type: BID_ORDER_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: BID_ORDER_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: BID_ORDER_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: BID_ORDER_RECEIVED
    }
  },
};

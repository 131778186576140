export const OFFER_PRICES_CREATE_POST = "offer_prices/create_post";
export const OFFER_PRICES_CREATE_LOADING = "offer_prices/create_loading";
export const OFFER_PRICES_CREATE_SUCCESS = "offer_prices/create_success";
export const OFFER_PRICES_CREATE_FAILED = "offer_prices/create_failed";
export const OFFER_PRICES_CREATE_RECEIVED = "offer_prices/create_received";

export function offerPriceCreatePost(id, payload = {}) {
  return {
    type: OFFER_PRICES_CREATE_POST,
    id,
    payload
  };
}
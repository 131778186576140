import { useState, useEffect } from "react";

/**
 * import lib tools
 */
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get, sumBy, isArray, isEmpty } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { BidOfferUsecase, LoadingSelector } from "@core";
import { takeArrayBySum } from "@utils/array";
import numeral from "@utils/numeral";
import {
  offerOrderAction,
  OFFER_ORDER,
} from "@redux/modules_v2/offers/order/action";
import { bidOrderAction, BID_ORDER } from "@redux/modules_v2/bids/order/action";
import Notice from "@utils/noticeNew";

/**
 * [hooks] market trade core
 *
 * @param {String|Number} id market id
 * @param {String} role 当前角色 "seller"|"buyer"
 * @param {object} currentUserStatus 当登录用户
 * @param {Instance<BidOfferUsecase>} usecase
 * @param {Instance<MarketUsecase>} usecaseMarket
 */
export const useMarketTrade = (
  id,
  role,
  currentUserStatus,
  usecase,
  usecaseMarket
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState("");
  const market = usecaseMarket.single;

  const parseList = () => {
    if (isArray(usecase.entity) && !isEmpty(usecase.entity)) {
      return usecase.entity;
    }
    return [];
  };

  /**
   * 构造表单数据
   */
  const generateFormData = () => {
    const list = parseList();
    const maxQuantity = sumBy(list, (o) => {
      return Number(o.remain_quantity) || 0;
    });
    const dataBidOffer = takeArrayBySum(list, "remain_quantity", amount);
    const usecase = new BidOfferUsecase(dataBidOffer, market);
    return {
      ...usecase.parseTradeFormData(amount),
      maxQuantity,
      isSaleByKg: get(usecase, "market.isSaleByKg", false),
      isSaleByUnit: get(usecase, "market.isSaleByUnit", false),
    };
  };

  /**
   * 构造表单数据
   */
  const generatePostData = () => {
    const data = generateFormData();
    const items = get(data, "items") || [];
    return items.map((item) => {
      const q = get(market, "isSaleByUnit", false)
        ? item.numUnits
        : item.numAmount;
      return {
        quantity: q,
        price: numeral(item.numPrice).multiply(100).value(),
      };
    });
  };

  /**
   * 提交 buyer 订单
   * @param {number|string} amount
   */
  const postBuyOrder = (amount) => {
    if (id && amount) {
      const items = generatePostData();
      const postData = {
        marketId: id,
        quantity: Number(amount),
        items: items,
      };
      dispatch(offerOrderAction.request(postData));
    }
  };

  /**
   * 提交 seller 订单
   * @param {number|string} amount
   */
  const postSellerOrder = (amount) => {
    if (id && amount) {
      const items = generatePostData();
      const postData = {
        marketId: id,
        quantity: Number(amount),
        items: items,
      };
      dispatch(bidOrderAction.request(postData));
    }
  };

  /**
   * 提交 buy/sell now 表单
   */
  const submit = () => {
    if (!currentUserStatus.isAuthenticated) {
      // history && history.push("/login");
      Notice.failure("Please login");
      return;
    }

    switch (role) {
      case "seller":
        postSellerOrder(amount);
        break;

      case "buyer":
        postBuyOrder(amount);
        break;

      default:
        break;
    }
  };

  /**
   * 监听订单创建
   * offerOrderStatus 买家下单
   * bidOrderStatus   卖家下单
   */
  const offerOrderStatus = useSelector((state) => {
    const selector = new LoadingSelector(OFFER_ORDER, state);
    return Object.assign({}, get(state, "offerOrderStatus", {}), {
      loading: selector.loading,
    });
  });
  const bidOrderStatus = useSelector((state) => {
    const selector = new LoadingSelector(BID_ORDER, state);
    return Object.assign({}, get(state, "bidOrderStatus", {}), {
      loading: selector.loading,
    });
  });
  useEffect(() => {
    if (offerOrderStatus.justFinished) {
      if (!offerOrderStatus.loading && offerOrderStatus.data) {
        Notice.success(
          "Your order has been successfully made! Now please complete the payment in 25mins"
        );
        const orderId = get(offerOrderStatus, "data.id");
        const url = orderId ? `/orders/${orderId}` : `/orders?type=purchase`;
        history && history.push(url);
      }
      if (!offerOrderStatus.loading && offerOrderStatus.error) {
        const errorText =
          get(offerOrderStatus, "error.response.status") === 422
            ? "Please refresh your page"
            : offerOrderStatus.error;
        Notice.failure(errorText, "Notice");
      }
      dispatch(offerOrderAction.received());
    }
  }, [offerOrderStatus.justFinished]);
  useEffect(() => {
    if (bidOrderStatus.justFinished) {
      if (!bidOrderStatus.loading && bidOrderStatus.data) {
        Notice.success(
          "You have a new order. The buyer should complete the payment by 24hours, otherwise the order" +
            " will be canceled."
        );
        const url = `/orders?type=sales`;
        history && history.push(url);
      }
      if (!bidOrderStatus.loading && bidOrderStatus.error) {
        const errorText =
          get(bidOrderStatus, "error.response.status") === 422
            ? "Please refresh your page"
            : bidOrderStatus.error;
        Notice.failure(errorText, "Notice");
      }
      dispatch(bidOrderAction.received());
    }
  }, [bidOrderStatus.justFinished]);

  return {
    bidOrderStatus,
    offerOrderStatus,
    trade: {
      price: 0,
      amount,
      ...generateFormData(),
    },
    setAmount,
    submit,
  };
};

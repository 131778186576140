import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { QuotumInventoryEntity } from "@core/index";

import numeral from "@utils/numeral";

export class AccountQuotumInventoryUsecase {
  /**
   * 构造函数
   */
  constructor(props = {}) {
    this.data = props;
  }

  /**
   * 获取 list 方法
   */
  get list() {
    if (!isArray(this.data)) {
      return [];
    }
    return map(this.data, (item) => this.getShow(item));
  }

  /**
   * 获取 show 方法
   */
  get show() {
    if (isArray(this.data) || isEmpty(this.data)) {
      return {};
    }
    return this.getShow(this.data);
  }

  /**
   * 获取 show 方法
   */
  getShow(data = {}) {
    const self = this;
    const usecase = new QuotumInventoryEntity(data);
    return {
      createdAtStr: usecase.createdAtStr,
      action: usecase.action,
      value: {
        quantity: usecase.quantity,
        balanceCache: usecase.balanceCache,
      },
      str: {
        quantity: self.formatValue(usecase.quantity) + "kg",
        balanceCache: self.formatValue(usecase.balanceCache) + "kg",
      },
      url: `/account/quotum_balances/${usecase.quotaMarketId}`
    };
  }

  /**
   * format 数量格式
   */

  formatValue(value = 0) {
    return numeral(value).format("0,0.00");
  }
}

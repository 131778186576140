import { takeLatest, call, put } from "redux-saga/effects";

import { BidApiUsecase } from "@core";
import { BID_DELETE_REQUEST, bidDeleteAction } from "./action";

// destroy bid with market(lease)
export function* watchMarketBidDelete() {
  yield takeLatest(BID_DELETE_REQUEST, bidDelete);
}

function* bidDelete(action) {
  try {
    const usecase = new BidApiUsecase();
    const res = yield call([usecase, "destroy"], action.payload);
    yield put(bidDeleteAction.success(res.data));
  } catch (err) {
    yield put(bidDeleteAction.failure(err));
  }
}

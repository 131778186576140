export const MARKET_SHOW = "markets/show";
export const MARKET_SHOW_REQUEST = `${MARKET_SHOW}_request`;
export const MARKET_SHOW_SUCCESS = `${MARKET_SHOW}_success`;
export const MARKET_SHOW_FAILURE = `${MARKET_SHOW}_failure`;
export const MARKET_SHOW_RESET = `${MARKET_SHOW}_reset`;

export const marketShowAction = {
  request: (payload) => {
    return {
      type: MARKET_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: MARKET_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: MARKET_SHOW_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: MARKET_SHOW_RESET,
    }
  },
};

import {
  LISTING_INDEX_LIST_TAB_CHANGE,
} from "./listingListAction";

export default function listingListState(state = initStatus, action = {}) {

  switch (action.type) {
    case LISTING_INDEX_LIST_TAB_CHANGE:
      return Object.assign({}, state, {
        listTabId: action.payload.tabId,
      });

    default:
      return state;
  }
}

const initStatus = {
  listTabId: "all",
};
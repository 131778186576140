import { useCallback, useReducer } from "react";
/**
 * import lib tools
 */
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { FisheryShowTabs } from "@core";

/**
 * Hooks
 */
import { useParams, useDeepEffect } from "@hooks";
import isEmpty from "lodash/isEmpty";

//Constants
const UPDATE_STATE = "initial_state";
const ACTIVE_TAB_CHANGE = "active_tab_change";
const LEASE_MARKET_ID_CHANGE = "lease_market_id_change";
const TRADE_TYPE_CHANGE = "trade_type_change";
const BUY_FORM_CHANGE = "buy_form_change";
const SELL_FORM_CHANGE = "sell_form_change";
const QUOTA_ID_CHANGE = "quota_id_change";
const QUOTA_UNIT_ID_CHANGE = "quota_unit_id_change";
const SHOW_PERMANENT_LIST = "show_permanent_list";
const AUCTION_ID_CHANGE = "auction_id_change";
const AUCTION_LAST_CHANCE_CHANGE = "auction_last_chance_change";
const AUCTION_WAIT_RULING_CHANGE = "auction_wait_ruling_change";
const FISHERY_ID_CHANGE = "fishery_id_change";

//Action
const updateState = (payload) => ({
  type: UPDATE_STATE,
  payload,
});
const changeActiveTabAction = (payload) => ({
  type: ACTIVE_TAB_CHANGE,
  payload,
});
const changeFisheryIdAction = (payload) => ({
  type: FISHERY_ID_CHANGE,
  payload,
});
const changeLeaseMarketIdAction = (payload) => ({
  type: LEASE_MARKET_ID_CHANGE,
  payload,
});
const changeTradeTypeAction = (payload) => ({
  type: TRADE_TYPE_CHANGE,
  payload,
});
const changeBuyFormOpenAction = (payload) => ({
  type: BUY_FORM_CHANGE,
  payload,
});

const changeSellFormOpenAction = (payload) => ({
  type: SELL_FORM_CHANGE,
  payload,
});

const changeQuotaIdAction = (payload) => ({
  type: QUOTA_ID_CHANGE,
  payload,
});

const changeQuotaUnitIdAction = (payload) => ({
  type: QUOTA_UNIT_ID_CHANGE,
  payload,
});

const showPermanentListAction = (payload) => ({
  type: SHOW_PERMANENT_LIST,
  payload,
});

const changeAuctionIdAction = (payload) => ({
  type: AUCTION_ID_CHANGE,
  payload,
});

const changeAuctionLastChanceAction = (payload) => ({
  type: AUCTION_LAST_CHANCE_CHANGE,
  payload,
});

const changeAuctionWaitRulingChangeAction = (payload) => ({
  type: AUCTION_WAIT_RULING_CHANGE,
  payload,
});

const initialState = {
  activeTab: get(FisheryShowTabs, "[0].key"),
  fisheryId: "",
  leaseMarketId: "",
  tradeType: "sell",
  buyFormOpen: false,
  sellFormOpen: false,
  quotaUnitId: "",
  auctionId: "",
  auctionLastChance: false,
  auctionWaitRuling: false,
  quotaId: "",
  showPermanentList: false,
};

//Reducer
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_STATE:
      return Object.assign({}, state, get(action, "payload"));
    case ACTIVE_TAB_CHANGE:
      return Object.assign({}, state, {
        activeTab: get(action, "payload"),
      });
    case FISHERY_ID_CHANGE:
      return Object.assign({}, state, {
        fisheryId: get(action, "payload"),
      });
    case LEASE_MARKET_ID_CHANGE:
      return Object.assign({}, state, {
        leaseMarketId: get(action, "payload"),
      });
    case TRADE_TYPE_CHANGE:
      return Object.assign({}, state, {
        tradeType: get(action, "payload"),
      });
    case BUY_FORM_CHANGE:
      return Object.assign({}, state, {
        buyFormOpen: get(action, "payload"),
      });
    case SELL_FORM_CHANGE:
      return Object.assign({}, state, {
        sellFormOpen: get(action, "payload"),
      });
    case QUOTA_ID_CHANGE:
      return Object.assign({}, state, {
        quotaId: get(action, "payload"),
      });
    case QUOTA_UNIT_ID_CHANGE:
      return Object.assign({}, state, {
        quotaUnitId: get(action, "payload"),
      });
    case SHOW_PERMANENT_LIST:
      return Object.assign({}, state, {
        showPermanentList: get(action, "payload"),
      });
    case AUCTION_ID_CHANGE:
      return Object.assign({}, state, {
        auctionId: get(action, "payload"),
      });
    case AUCTION_LAST_CHANCE_CHANGE:
      return Object.assign({}, state, {
        auctionLastChance: get(action, "payload"),
      });
    case AUCTION_WAIT_RULING_CHANGE:
      return Object.assign({}, state, {
        auctionWaitRuling: get(action, "payload"),
      });
    default:
      return initialState;
  }
};

/**
 * Fetch fishery show hooks
 * @param {*} param
 */
export const useFisheryShowState = () => {
  const [params] = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setUpdateState = useCallback((payload) => {
    dispatch(updateState(payload));
  }, []);

  const setActiveTab = useCallback((activeTab) => {
    dispatch(changeActiveTabAction(activeTab));
  }, []);

  const setFisheryId = useCallback((id) => {
    dispatch(changeFisheryIdAction(id));
  }, []);

  const setLeaseMarketId = useCallback((id) => {
    dispatch(changeLeaseMarketIdAction(id));
  }, []);

  const setTradeType = useCallback((type) => {
    dispatch(changeTradeTypeAction(type));
  }, []);

  const setBuyFormOpen = useCallback((isOpen) => {
    dispatch(changeBuyFormOpenAction(isOpen));
  }, []);

  const setSellFormOpen = useCallback((isOpen) => {
    dispatch(changeSellFormOpenAction(isOpen));
  }, []);

  const setQuotaId = useCallback((id) => {
    dispatch(changeQuotaIdAction(id));
  }, []);

  const setQuotaUnitId = useCallback((id) => {
    dispatch(changeQuotaUnitIdAction(id));
  }, []);

  const setShowPermanentList = useCallback((type) => {
    dispatch(showPermanentListAction(type));
  }, []);

  const setAuctionId = useCallback((id) => {
    dispatch(changeAuctionIdAction(id));
  }, []);

  const setAuctionLastChance = useCallback((payload) => {
    dispatch(changeAuctionLastChanceAction(payload));
  }, []);

  const setAuctionWaitRuling = useCallback((payload) => {
    dispatch(changeAuctionWaitRulingChangeAction(payload));
  }, []);

  useDeepEffect(() => {
    if (!isEmpty(params)) {
      const state = Object.assign({}, params, {
        buyFormOpen: get(params, "buyFormOpen", "") == "true",
        sellFormOpen: get(params, "sellFormOpen", "") == "true",
        auctionLastChance: get(params, "auctionLastChance", "") == "true",
        auctionWaitRuling: get(params, "auctionWaitRuling", "") == "true",
      });
      setUpdateState(state);
    }
  }, [params]);

  return {
    state,
    setActiveTab,
    setFisheryId,
    setLeaseMarketId,
    setQuotaId,
    setQuotaUnitId,
    setTradeType,
    setBuyFormOpen,
    setSellFormOpen,
    setUpdateState,
    setShowPermanentList,
    setAuctionId,
    setAuctionLastChance,
    setAuctionWaitRuling,
  };
};

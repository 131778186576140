import get from "lodash/get";
import { SET_LOADINGS } from "./action";

export const loadingsStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_LOADINGS:
      const result = Object.assign({}, state, {
        [get(action, "payload.request")]: get(action, "payload.value")
      });
      return result;
    default:
      return state;
  }
};

import { get, isFunction } from "lodash";
import {
  paymentMethodMap,
} from "@config/enum";
import {CaculatedFee} from "@core";
import { paymentMethod } from "@utils/payment";
import numeral, { numberCeil } from "@utils/numeral";
/**
 * [Entity] Payment
 * data is obj
  {
    "id": 91,
    "order_id": null,
    "code": "2003204053_development",
    "state": "completed",
    "payment_method_type": "Payment::Stripe",
    "term": "deposit",
    "created_at": "2020-03-20T14:09:04.863+11:00",
    "updated_at": "2020-03-20T14:09:33.922+11:00",
    "paid_amount": "50,935.00",
    "paid_amount_with_symbol": "$50,935.00",
    "transaction_amount": "50,000.00",
    "transaction_amount_with_symbol": "$50,000.00",
    "transaction_fees": "935.00",
    "transaction_fees_with_symbol": "$935.00",
    "transaction_original_fees": "0.00",
    "transaction_origianl_fees_with_symbol": "$0.00",
    "transaction_gst_fees": "0.00",
    "transaction_gst_fees_with_symbol": "$0.00"
  }
 */
export class PaymentEntity {
  constructor(data) {
    this.entity = data;
  }

  /**
   * [Class Instance Method] payment.generateMoney(orderEntity, payMethod)
   * @param {Entity<Order>} order
   * @param {String} payMethod "stripe|wallet|offline"
   * @param {String} payType "instant"全款，默认，提交时可为空|"hold_fee"|"deposit"押金|"balance"尾款
   */
  generateMoney(order, payMethod, payType) {
    const market = get(order, "order.market") || {};
    const quantity =
      market && isFunction(market.generateData)
        ? market.generateData(order.boxesCount)
        : {
            units: 0,
            amount: 0,
            unitsStr: "",
            amountStr: "",
          };
    const depositPercent = order.depositPercentage || 1;
    const holdingPercent = order.holdingFeePercentage || 1;
    const baseTotal = numeral(order.subTotalWithGst).add(
      order.serviceFeeWithGst
    );

    let instantPaymentTotal = baseTotal.value();
    let depositPaymentTotal = numeral(
      numberCeil(
        numeral(instantPaymentTotal).multiply(depositPercent).value(),
        2
      )
    ).value();
    let holdPaymentTotal = numeral(
      numberCeil(
        numeral(instantPaymentTotal).multiply(holdingPercent).value(),
        2
      )
    ).value();
    let balancePaymentTotal = Number(order.howMuchLeft) || 0;
    let cardProcessingFeeWithGst = 0;
    let currentPaymentTotal = instantPaymentTotal;

    if (payType === "instant") {
      if (payMethod === paymentMethodMap.stripe) {
        cardProcessingFeeWithGst = CaculatedFee.stripe(instantPaymentTotal);
      }
      // instantPaymentTotal = numeral(instantPaymentTotal).add(cardProcessingFeeWithGst).value();
      currentPaymentTotal = instantPaymentTotal;
    }
    if (payType === "deposit") {
      if (payMethod === paymentMethodMap.stripe) {
        cardProcessingFeeWithGst = CaculatedFee.stripe(depositPaymentTotal);
      }
      // depositPaymentTotal = numeral(depositPaymentTotal).add(cardProcessingFeeWithGst).value();
      currentPaymentTotal = depositPaymentTotal;
    }
    if (payType === "hold_fee") {
      if (payMethod === paymentMethodMap.stripe) {
        cardProcessingFeeWithGst = CaculatedFee.stripe(holdPaymentTotal);
      }
      // holdPaymentTotal = numeral(holdPaymentTotal).add(cardProcessingFeeWithGst).value();
      currentPaymentTotal = holdPaymentTotal;
    }
    if (payType === "balance") {
      if (payMethod === paymentMethodMap.stripe) {
        cardProcessingFeeWithGst = CaculatedFee.stripe(balancePaymentTotal);
      }
      // balancePaymentTotal = numeral(balancePaymentTotal).add(cardProcessingFeeWithGst).value();
      currentPaymentTotal = balancePaymentTotal;
    }

    return {
      // 原数据
      unitsTotal: quantity.units,
      amountTotal: quantity.amount,
      averagePrice: numeral(order.subTotal).divide(quantity.amount).value(),
      subTotal: order.subTotal,
      gst: order.gst,
      subTotalWithGst: order.subTotalWithGst,

      instantPaymentTotal: numeral(instantPaymentTotal),
      depositPaymentTotal: numeral(depositPaymentTotal),
      holdPaymentTotal: numeral(holdPaymentTotal),
      balancePaymentTotal: numeral(balancePaymentTotal),
      cardProcessingFeeWithGst: numeral(cardProcessingFeeWithGst),

      currentPaymentTotal: numeral(currentPaymentTotal).add(
        cardProcessingFeeWithGst
      ),
    };
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get state() {
    return get(this.entity, "state", "");
  }

  get payType() {
    return get(this.entity, "payment_method_type", "");
  }

  get payTypeStr() {
    return paymentMethod(this.entity);
  }

  get paidAmount() {
    return numeral(get(this.entity, "paid_amount", 0)).value();
  }

  get paidAmountStr() {
    return get(this.entity, "paid_amount_with_symbol", "");
  }

  get transactionAmount() {
    return numeral(get(this.entity, "transaction_amount", 0)).value();
  }

  get transactionAmountStr() {
    return get(this.entity, "transaction_amount_with_symbol", "");
  }

  get transactionFees() {
    return numeral(get(this.entity, "transaction_fees", 0)).value();
  }

  get transactionFeesStr() {
    return get(this.entity, "transaction_fees_with_symbol", "");
  }

  get transactionOriginalFees() {
    return numeral(get(this.entity, "transaction_original_fees", 0)).value();
  }

  get transactionOriginalFeesStr() {
    return numeral(this.transactionOriginalFees).format();
  }

  get orderSerialNumber() {
    return get(this.entity, "order.serial_number", "");
  }

  get orderId() {
    return get(this.entity, "order.id", "");
  }

  get term() {
    return get(this.entity, "term", "");
  }

  /******************** 钱包相关 *******************/
  get fees() {
    const payTip = this.payTypeStr ? `(${this.payTypeStr})` : "";
    return this.transactionFeesStr + payTip;
  }

  get tipFees() {
    return "Card process fee";
  }

  get tipTotal() {
    return "Topup amount";
  }

  /**
   * 获取钱包标题
   * @param {String} sheetTitle
   * @param {String} type "debit" ｜ "credit"
   */
  walletTitle(sheetTitle, type = "") {
    // 如果 type 是 credit ，则是消费
    //   并且，如果 sheetTitle 是 "user fees" 则是手续费
    // 如果 type 是 debit ，则是充钱
    // 否则，空

    if (this.entity.term === "hold_fee") {
      if (sheetTitle === "user refund") {
        return "Refund holding fee";
      }

      if (sheetTitle === "user pay") {
        return "Holding fee";
      }
    }

    if (type === "credit") {
      if (sheetTitle === "user fees") {
        return "Card Processing Fee";
      }
      return "Quota Bought";
    }
    if (type === "debit") {
      return "Topup to Wallet";
    }

    return "";
  }

  showFees(sheetTitle = "") {
    if (sheetTitle === "user fees") {
      return false;
    }
    return this.transactionFees > 0;
  }
  /******************** 钱包相关 end *******************/
}

import React from "react";

/**
 * import local tools & components
 */
import {
  useWithdrawList,
  useWithdrawCreate,
  useWalletBalance,
  useUserProfile,
  useModal,
} from "@hooks";
import numeral from "@utils/numeral";

import Layout from "@views/layout";
import { Button, Image } from "@components";
import WithdrawalForm from "./_form";
import WithdrawalModal from "./_modal";

/**
 * [Page] Account withdrawal 提现页面
 */
const Withdrawal = (props) => {
  const { history } = props;
  const { data: profile } = useUserProfile();
  const { balance, balanceStr, loading: loadingBalance } = useWalletBalance();
  const { lastWithdraw } = useWithdrawList();

  const {
    money,
    moneyTotal,
    generateFormValues,

    onMoneyChange,
    submit: submitWithdraw,
    loading: loadingWithdraw,
    dispatchSubmit,
  } = useWithdrawCreate(balance, () => {
    toggleModal(true);
  });

  const { isOpen, toggleModal } = useModal();

  const initialFormValues = generateFormValues(lastWithdraw, profile);

  return (
    <Layout>
      <div className="flex-1 flex">
        <div className="flex-1 bg-white"></div>
        <div className="container md:flex">
          <div className="page-main-wrapper page-pb bg-white flex-1">
            <div className="pt-5-tw xl:pt-12">
              <div className="flex items-center mb-4-tw md:mb-12">
                <div
                  className="pr-5-tw cursor-pointer"
                  onClick={() => {
                    history && history.goBack();
                  }}
                >
                  <svg
                    className="-ml-1 mr-1 h-8 w-8 text-blue-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <h2>
                  Withdraw
                </h2>
              </div>
              <div className="text-xl font-bold leading-tight">
                How much would you like to withdraw?
              </div>
              <div className="mt-4-tw md:mt-10">
                <WithdrawalForm
                  onMoneyChange={(evt) => {
                    const value = evt.target.value || "";
                    onMoneyChange(value);
                  }}
                  initialValues={initialFormValues}
                  onSubmit={(values) => {
                    submitWithdraw(values);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="page-sub-wrapper page-pb bg-gray-50">
            <div className="pt-5-tw md:pt-24">
              <div className="bg-white rounded-lg-tw">
                <div className="flex p-5-tw">
                  <Image source="icons/money" className="img w-12" />
                  <div className="pl-6">
                    <h4>Wallet Balance</h4>
                    <div className="mt-1 text-2xl font-semibold">
                      {balanceStr}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="px-5-tw py-4-tw">
                  <div>
                    <div className="flex py-2">
                      <div className="flex-1">Amount to withdraw</div>
                      <div>{numeral(money).format()}</div>
                    </div>
                    <div className="flex py-2">
                      <div className="flex-1">Processing fee</div>
                      <div>$0.00</div>
                    </div>
                    <hr className="my-2" />
                    <div className="flex py-2">
                      <div className="flex-1">Total</div>
                      <div>
                        <strong>{numeral(moneyTotal).format()}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3-tw md:mt-6">
                    <Button
                      size="xl"
                      color="fish-primary"
                      textColor="white"
                      className="hover:bg-fish-primary-700"
                      full
                      loading={loadingWithdraw}
                      disabled={loadingWithdraw}
                      onClick={() => {
                        dispatchSubmit();
                      }}
                    >
                      Withdraw {numeral(moneyTotal).format()}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-gray-50"></div>
      </div>
      <WithdrawalModal
        isOpen={isOpen}
        toggleStatus={toggleModal}
        money={moneyTotal}
        onClosed={() => {
          history && history.push("/wallet");
        }}
      />
    </Layout>
  );
};

export default Withdrawal;

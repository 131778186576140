import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { Button } from "@components";
import { Form, Input, Checkbox } from "@components/formik";

const LoginForm = ({ onSubmit, loading, schema, initialValues }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Form>
        <Input
          name="email"
          type="email"
          label="Email address"
          classNameWrapper="mb-5"
          className="border-blue-gray-200"
          labelFontWeight="normal"
          placeholder="Email address"
          required
        />
        <Input
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          classNameWrapper="-mt-px relative"
          className="border-blue-gray-200"
          labelFontWeight="normal"
          required
        />
        <div className="mt-6 text-blue-gray-500 input-checkbox">
          <Checkbox
            name="agree"
            iconSize="sm"
            className="items-center"
            content={
              <div className="text-xxs">
                <p className="mt-px">
                  I accept the <a href="/privacy_policy" className="font-medium">Platform Rules</a> and <a href="terms_of_service" className="font-medium">Terms of Use</a>
                </p>
              </div>
            }
          />
        </div>
        <div className="mt-5-tw">
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            trailingIcon={
              <svg className="mt-px pt-px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8H2M9.33333 3.33334L14 8L9.33333 3.33334ZM14 8L9.33333 12.6667L14 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            full
            color="gray-800"
            className="hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline h-42"
          >
            Sign up
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  schema: PropTypes.object,
};

LoginForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  schema: {},
};

export default LoginForm;

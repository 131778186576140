import { takeEvery, all, call, put } from "redux-saga/effects";
import { forgotPassword } from "@utils/cognitoAuth";
import {
  INITIALIZE_REQUEST,
  initSuccess,
  initRequest,
  initLoading,
  initFailed,
} from "./initAction";
import { refreshCurrentUser } from "../auth/action";

function* watchInitForgotPasswordRequest() {
  yield takeEvery(INITIALIZE_REQUEST, initForgotPassword);
}

function* initForgotPassword(action) {
  yield put(initLoading());

  let { res, err } = yield call(initForgotPasswordApi, action.payload);
  if (err) {
    return yield put(initFailed(err));
  }
  yield all([put(initSuccess(res)), put(refreshCurrentUser())]);
}

function* initForgotPasswordApi({ email }) {
  try {
    const apiResponse = yield call(forgotPassword, email);
    return { res: apiResponse, err: null };
  } catch (err) {
    return { res: null, err: err };
  }
}

export { watchInitForgotPasswordRequest };

const WALLET_OFFLINES_REQUEST = "account_wallet_offlines/request";
const WALLET_OFFLINES_LOADING = "account_wallet_offlines/loading";
const WALLET_OFFLINES_SUCCESS = "account_wallet_offlines/success";
const WALLET_OFFLINES_FAILED = "account_wallet_offlines/failed";

function walletOfflinesRequest(payload) {
  return {
    type: WALLET_OFFLINES_REQUEST,
    payload,
  };
}

function walletOfflinesLoading() {
  return {
    type: WALLET_OFFLINES_LOADING,
  };
}

function walletOfflinesSuccess(data) {
  return {
    type: WALLET_OFFLINES_SUCCESS,
    data,
  };
}

function walletOfflinesFailed(error) {
  return {
    type: WALLET_OFFLINES_FAILED,
    error,
  };
}

export {
  WALLET_OFFLINES_REQUEST,
  WALLET_OFFLINES_FAILED,
  WALLET_OFFLINES_LOADING,
  WALLET_OFFLINES_SUCCESS,
  walletOfflinesRequest,
  walletOfflinesFailed,
  walletOfflinesSuccess,
  walletOfflinesLoading,
};

import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";

import errorString from "@utils/errorString";
import { PermanentApiUsecase } from "@core";
import { PERMANENT_PURCHASE_REQUEST, permanentPurchaseAction } from "./action";

export function* watchPermanentPurchaseFetch() {
  yield takeLatest(PERMANENT_PURCHASE_REQUEST, permanentPurchaseFetch);
}

function* permanentPurchaseFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "purchase"], action.payload);
    yield put(permanentPurchaseAction.success(res.data));
  } catch (err) {
    const errors = get(err, "response.data.errors", {});
    yield put(permanentPurchaseAction.failure(errorString(errors)));
  }
}

import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_RECEND_REQUEST, accountRecendAction } from "./action";

// fetch account recend list
export function* watchAccountRecendFetch() {
  yield takeLatest(ACCOUNT_RECEND_REQUEST, accountRecendFetch);
}

function* accountRecendFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "recend"], action.payload);
    yield put(accountRecendAction.success(res.data));
  } catch (err) {
    yield put(accountRecendAction.failure(err));
  }
}

export const USER_PROFILE = "users/profile";
export const USER_PROFILE_REQUEST = `${USER_PROFILE}_request`;
export const USER_PROFILE_SUCCESS = `${USER_PROFILE}_success`;
export const USER_PROFILE_FAILURE = `${USER_PROFILE}_failure`;

export const userProfileAction = {
  request: (payload) => {
    return {
      type: USER_PROFILE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: USER_PROFILE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: USER_PROFILE_FAILURE,
      error,
    }
  },
};

import axios from "axios";
import get from "lodash/get";
import { takeEvery, all, call, put } from "redux-saga/effects";

import {
  PRICEABLES_CATEGORY_FETCH_REQUEST,
  PRICEABLES_CATEGORY_FETCH_LOADING,
  PRICEABLES_CATEGORY_FETCH_SUCCESS,
  PRICEABLES_CATEGORY_FETCH_FAILED,
} from "./categoryAction";

import { PRICEABLES_AUCTIONS_FETCH_SUCCESS } from "./listAction";

import { priceablesCategoryAPI } from "@config/api";

import { getAuctions } from "./listSaga";

//fetch priceables category list
export function* watchPriceablesCategoryFetch() {
  yield takeEvery(PRICEABLES_CATEGORY_FETCH_REQUEST, priceablesCategoryFetch);
}

export function* priceablesCategoryFetch(action) {
  yield put({ type: PRICEABLES_CATEGORY_FETCH_LOADING });
  const { err, res } = yield call(
    priceablesCategoryFetchApi,
    action.payload.category_id,
    action.payload.params
  );

  if (!res) {
    yield put({
      type: PRICEABLES_CATEGORY_FETCH_FAILED,
      error: "Fetch priceables list with category failed.",
    });
    return;
  }
  yield all([
    put({ type: PRICEABLES_CATEGORY_FETCH_SUCCESS, data: res }),
    put({
      type: PRICEABLES_AUCTIONS_FETCH_SUCCESS,
      data: getAuctions(get(res, "entries", [])),
    }),
  ]);
}

export function priceablesCategoryFetchApi(id, params) {
  return axios
    .get(priceablesCategoryAPI(id, null, params))
    .then((res) => ({
      res: res.data,
    }))
    .catch((err) => ({
      err,
    }));
}

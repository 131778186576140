import React, { useState, useEffect } from "react";

/**
 * import lib tools
 */
import getQueryString from "@utils/getUrlQuery";
import { DesktopAndTablet, Mobile } from "@components";

const defaultTime = 5;

const Content = (props) => {
  const { title, description, type, redirect } = props;
  let timeoutId = null;
  const [time, setTime] = useState(defaultTime);
  const _description = typeof description === "string" ? description.format(time): description;
  const start = (time) => {
    if (!redirect) {
      return;
    }
    if (time >= 1) {
      timeoutId = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else {
      redirectUrl();
    }
  };

  const stop = () => {
    timeoutId && clearTimeout(timeoutId);
    timeoutId = null;
  };

  const getSuccessUrl = (no_licence, orderId, page) => {
    if (orderId) {
      if (no_licence) {
        return `/orders/${orderId}`;
      }
      return `/account/licence/edit?order_id=${orderId}`;
    }

    if (page && page === "wallet") {
      return `/wallet`;
    }

    return "/dashboard";
  };

  const getUrl = () => {
    const orderId = getQueryString("order_id");
    const no_licence = getQueryString("no_licence");
    const page = getQueryString("page");

    // This is for failed/cancelled
    if (type == "failed" || type == "cancelled") {
      return "/dashboard";
    }
    // This is for successful
    return getSuccessUrl(no_licence, orderId, page);
  };

  const redirectUrl = () => {
    const url = getUrl();
    if (!url) {
      return;
    }
    window.location.href = url;
  };

  /**
   * did mount
   */
  useEffect(() => {
    start(time);

    // will unmount
    return () => {
      stop();
    };
  }, [time]);

  const renderIcon = () => {
    if (type === "successful") {
      return (
        <div className="w-16 h-16 bg-blue-50 rounded-full flex items-center justify-center mb-5">
          <svg
            width="40"
            height="30"
            viewBox="0 0 40 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.222 29.5184L38.9724 6.76861C39.5052 6.23641 39.5052 5.37299 38.9725 4.84079L34.7305 0.599345C34.1988 0.0671455 33.3353 0.0671455 32.8037 0.599345L15.2588 18.1432L6.9679 9.85356C6.43618 9.32136 5.57276 9.32136 5.04112 9.85356L0.79912 14.095C0.267481 14.6272 0.267481 15.4906 0.79912 16.0222L14.2952 29.5184C14.827 30.0511 15.6904 30.0511 16.222 29.5184Z"
              fill="#3F83F8"
            />
          </svg>
        </div>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <DesktopAndTablet>
        <div className="gs-page bg-gray-50">
          <div className="bg-white rounded-lg py-12 px-10 w-1/3 mx-auto flex flex-col justify-center items-center">
            {renderIcon()}
            <h3 className="mb-4">{title}</h3>
            <div className="text-lg text-blue-gray-600 text-center">
              {_description}
            </div>
          </div>
        </div>
      </DesktopAndTablet>
      <Mobile>
        <div className="gs-page bg-gray-50 flex">
          <div className="bg-white rounded-lg py-5 px-5 mx-2 mb-2 flex flex-col justify-center items-center flex-1">
            {renderIcon()}
            <h3 className="mb-4">{title}</h3>
            <div className="text-sm text-blue-gray-600 text-center mb-20">
              {_description}
            </div>
          </div>
        </div>
      </Mobile>
    </React.Fragment>
  );
};

Content.defaultProps = {
  title: "",
  description: "",
  type: "", // "successful|failed|cancelled"
  redirect: false, //是否启动倒计时跳转
};

export default Content;

import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  ASKING_PRICES_FETCH_QUOTA_BALANCE_REQUEST,
  ASKING_PRICES_FETCH_QUOTA_BALANCE_LOADING,
  ASKING_PRICES_FETCH_QUOTA_BALANCE_SUCCESS,
  ASKING_PRICES_FETCH_QUOTA_BALANCE_FAILED,
} from "./quotaBalanceAction";

import {
  askingPricesAPI,
} from "@config/api";


//fetch asking prices quota balance
export function* watchAskingPricesFetchQuotaBalance() {
  yield takeEvery(ASKING_PRICES_FETCH_QUOTA_BALANCE_REQUEST, askingPricesFetchQuotaBalance);
}

export function* askingPricesFetchQuotaBalance(action) {
  yield put({ type: ASKING_PRICES_FETCH_QUOTA_BALANCE_LOADING });
  const { err, res } = yield call(askingPricesFetchQuotaBalanceApi, action.payload, action.params);
  if (!res) {
    yield put({
      type: ASKING_PRICES_FETCH_QUOTA_BALANCE_FAILED,
      error: "Get asking prices quota balance failed."
    });
    return;
  }
  yield put({ type: ASKING_PRICES_FETCH_QUOTA_BALANCE_SUCCESS, data: res });
}

export function askingPricesFetchQuotaBalanceApi(payload, params) {
  let api = askingPricesAPI(payload.productGradeId, payload.id);
  return axios
    .get(api)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

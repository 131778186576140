import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  globalStaticAction,
  GLOBAL_STATIC,
} from "@redux/modules_v2/global/static/action";

/**
 * [hooks] global static
 * @param {Boolean} isFetch 是否调用接口 true|false
 * @returns { data: "a global static data", loading: true|false }
 *
 * @example
 * 1. Layout 或 需要调用接口的地方
 * const { data: staticData } = useGlobalStatic(true);
 *
 * 2. 只是使用数据，不需要调用接口 （因为 Lauout 中已调用）
 * const { data: staticData } = useGlobalStatic();
 */
export const useGlobalStatic = (isFetch) => {
  const dispatch = useDispatch();

  const fetchGlobalStatic = () => {
    dispatch(globalStaticAction.request({}));
  };

  const globalStaticStatus = useSelector(state => {
    const selector = new LoadingSelector(GLOBAL_STATIC, state);
    return Object.assign({}, get(state, "globalStaticStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = globalStaticStatus.loading;
  const data = globalStaticStatus.data || {};

  /**
   * did mount
   */
  useEffect(() => {
    if (isFetch) {
      fetchGlobalStatic();
    }
  }, []);

  return { data, loading };
};

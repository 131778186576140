import {
  AUCTION_BID_LIST_SUCCESS,
  AUCTION_BID_LIST_FAILURE,
  AUCTION_BID_LIST_RESET,
} from "./action";

export const auctionBidListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTION_BID_LIST_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case AUCTION_BID_LIST_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case AUCTION_BID_LIST_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null,
};

import React from "react";
/**
 * import lib tools
 */
import get from "lodash/get";
/**
 * import local tools & components
 */
import { Moment } from "@core";
import {
  useInvoiceShow,
  // useGlobalStatic,
} from "@hooks";
import Layout from "@views/layout";
import { Button, Loading, Table } from "@components";

/**
 * [Page] invoice
 */
const Invoice = (props) => {
  const { match } = props;
  const id = get(match, "params.id");

  // const { data: staticData } = useGlobalStatic();
  const { data: invoice, loading: loadingShow } = useInvoiceShow(id);
  const order = invoice.services || {};

  const renderContent = () => {
    if (loadingShow) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="md:flex justify-between flex-row-reverse md:mb-12 pb-6">
          <div className="invoice-basic py-6 md:py-0 md:w-1/4">
            <div className="md:pt-10">
              <div className="flex justify-between">
                <div className="text-sm text-blue-gray-600">Invoice Number</div>
                <div className="font-medium text-right">
                  {get(invoice, "number")}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm text-blue-gray-600">Invoice Date</div>
                <div className="font-medium text-right">
                  {new Moment(invoice.created_at).formatDate}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm text-blue-gray-600">Due Date</div>
                <div className="font-medium text-right">
                  {new Moment(invoice.due_date).formatDate}
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-users flex-1">
            <div className="text-sm font-medium text-blue-gray-600 flex md:pt-10">
              <div className="flex-1 pr-6 md:pr-8 lg:pr-16">
                <div className="font-semibold">Seller</div>
                <div className="font-semibold text-blue-gray-800">
                  {get(invoice, "buyer.company_name", "")}
                </div>
                <div>
                  <div>
                    {get(invoice, "buyer.postal_address", "")}{" "}
                    {get(invoice, "buyer.state", "")}{" "}
                    {get(invoice, "buyer.post_code", "")}
                  </div>
                  <div>
                    +{get(invoice, "buyer.phone_country_code", "")}{" "}
                    {get(invoice, "buyer.phone_number", "")}
                  </div>
                  <div>ABN: {get(invoice, "buyer.abn", "-")}</div>
                </div>
              </div>
              <div className="flex-1 md:pr-8 lg:pr-16">
                <div className="font-semibold">Buyer</div>
                <div className="font-semibold text-blue-gray-800">
                  {get(invoice, "seller.company_name", "")}
                </div>
                <div>
                  <div>
                    {get(invoice, "seller.postal_address", "")}{" "}
                    {get(invoice, "seller.state", "")}{" "}
                    {get(invoice, "seller.post_code", "")}
                  </div>
                  <div>
                    +{get(invoice, "seller.phone_country_code", "")}{" "}
                    {get(invoice, "seller.phone_number", "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div>
            <Table className="table money-table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Price</th>
                  <th className="text-right">Item Total</th>
                </tr>
              </thead>
              <tbody className="whitespace-no-wrap">
                {(order.items || []).map((item, index) => {
                  const itemPrice = numeral(Math.abs(item.value)).format();
                  return (
                    <tr key={index}>
                      <td>{get(invoice, "order.total_weight", "")} kg</td>
                      <td>{item.title || ""}</td>
                      <td className="text-right">{itemPrice}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div>
              <div className="bg-gray-50 mt-1 py-4 px-3 border-t border-blue-gray-600">
                <div className="md:w-1/2 ml-auto flex ">
                  <div className="flex-1 font-normal md:pl-3">Subtotal</div>
                  <div className="font-bold">
                    {get(
                      order,
                      "sub_total_price.abs_humanized_money_with_symbol",
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 ml-auto">
                <div className="flex items-center justify-between pt-1">
                  <div className="pl-3">GST</div>
                  <div className="font-medium w-32 py-3 px-3 bg-gray-50 text-right">
                    {get(
                      order,
                      "gst_price.abs_humanized_money_with_symbol",
                      ""
                    )}
                  </div>
                </div>
                <hr className="border-blue-gray-600 my-1" />
                <div className="flex items-center justify-between pt-1">
                  <div className="font-semibold pl-3">Total</div>
                  <div className="font-bold w-32 py-3 px-3 text-right text-xl">
                    {get(
                      order,
                      "total_including_gst.abs_humanized_money_with_symbol",
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Layout>
      <div className="bg-gray-50">
        <div className="container page-pb">
          <div className="flex justify-between items-center py-4 md:pt-12 md:pb-10 px-5 md:px-0 hidden-print">
            <div></div>
            <div>
              <Button
                onClick={() => {
                  window.print();
                }}
                size="xl"
                color="white"
                textColor="gray-900"
                className="hover:border-blue-gray-200 hover:bg-gray-200"
              >
                Print
              </Button>
            </div>
          </div>

          <div
            className="bg-white md:rounded-lg p-5 md:px-20 md:pt-12 md:pb-24"
            style={{ minHeight: 1220 }}
          >
            <div>
              <h2>
                Invoice
              </h2>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Invoice;

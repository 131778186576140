import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class AuctionApiRepo extends BaseApiRepo {
  constructor() {
    super();
  }

  /**
   * Auction Api Show
   * @param {object} payload {}
   * GET /api/v1/auctions/:id
   */
  show(payload) {
    this.setApi(`${API}/auctions/${payload.id}`);
    return this.get();
  }

  /**
   * Auction new
   * @param {object} payload {}
   * POST /api/v1/auctions
   */
  new(payload) {
    this.setApi(`${API}/auctions`);
    return this.post(payload);
  }

  /**
   * Auction list
   * GET /api/v1/fisheries/:fishery_id/auctions
   * GET /api/v1/auctions
   */
  list({ fishery_id, owned, similar, ...rest } = {}) {
    const url = fishery_id
      ? `${API}/fisheries/${fishery_id}/auctions`
      : owned
      ? `${API}/account/auctions`
      : similar
      ? `${API}/auctions/similar`
      : `${API}/auctions`;
    this.setApi(url);
    return this.get(rest);
  }

  /**
   * Auction final list
   * GET /api/v1/auctions/final_chances
   */
  finalChancesList() {
    this.setApi(`${API}/auctions/final_chances`);
    return this.get();
  }

  /**
   * Auction create new bid
   *
   */
  createBid({ auction_id, ...rest } = {}) {
    this.setApi(`${API}/auctions/${auction_id}/bids`);
    return this.post({ bid: rest });
  }

  /**
   * Auction bid list
   */
  bids({ auction_id, ...rest } = {}) {
    this.setApi(`${API}/auctions/${auction_id}/bids`);
    return this.get(rest);
  }

  /**
   * Fetch Auction final bid
   */
  finalBid({ auction_id } = {}) {
    this.setApi(`${API}/auctions/${auction_id}/final_price`);
    return this.get();
  }
 
  /**
   * Create Auction final bid
   */
  createFinalBid({ auction_id, ...rest } = {}) {
    this.setApi(`${API}/auctions/${auction_id}/final_price`);
    return this.post(rest);
  }

  /**
   * Create Auction buy-it-now 
   */
  createInstantBuy({id} = {}) {
    this.setApi(`${API}/auctions/${id}/buy`);
    return this.patch()
  }


}

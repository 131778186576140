
export const CHANNELS_CREATE_POST = "channels/create_post";
export const CHANNELS_CREATE_LOADING = "channels/create_loading";
export const CHANNELS_CREATE_SUCCESS = "channels/create_success";
export const CHANNELS_CREATE_FAILED = "channels/create_failed";
export const CHANNELS_CREATE_RECEIVED = "channels/create_received";
/**
 * Channel Create
 * @param  {Number} targetId AskingPriceId | OfferPriceId
 * @param  {String} kind     "AskingPrice" | "OfferPrice"
 * @param  {Object} payload
 * @return {Object}
 *
 * {
 *   type: "channels/create_post",
 *   targetId: 16,
 *   kind: "AskingPrice",
 *   payload: { ... }
 * }
 */
export function channelCreatePost(targetId, kind, payload = {}) {
  return {
    type: CHANNELS_CREATE_POST,
    targetId,
    kind,
    payload
  };
}

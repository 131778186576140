import {
  OFFER_MORE_TOGGLE,
  MODAL_ORDER_SET_DATA,
  MODAL_ORDER_STASTUS_CHANGE,
  MODAL_ORDER_RESULT_CHANGE,
  OFFER_FORM_STASTUS_CHANGE,
  OFFER_FORM_INPUT,
  SET_MESSAGE_MOUNT,
  SET_MESSAGE_SHOW,
} from "./quotaShowAction";

export default function quotaShowState(state = initStatus, action = {}) {
  let modalOrder = Object.assign({}, state.modalOrder);
  let formData = Object.assign({}, state.offerFormData, action.payload);

  switch (action.type) {
    case OFFER_MORE_TOGGLE:
      return Object.assign({}, state, {
        priceListIsOpen: action.payload.isOpen,
      });

    case MODAL_ORDER_SET_DATA:
      modalOrder.formData = action.payload.order;
      return Object.assign({}, state, {
        modalOrder: modalOrder,
      });

    case MODAL_ORDER_STASTUS_CHANGE:
      modalOrder.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        modalOrder: modalOrder,
      });

    case MODAL_ORDER_RESULT_CHANGE:
      modalOrder.resultShow = action.payload.resultShow;
      return Object.assign({}, state, {
        modalOrder: modalOrder,
      });

    case OFFER_FORM_STASTUS_CHANGE:
      return Object.assign({}, state, {
        offerFormShow: action.payload.isOpen,
      });

    case OFFER_FORM_INPUT:
      return Object.assign({}, state, {
        offerFormData: formData
      });

    case SET_MESSAGE_MOUNT:
      return Object.assign({}, state, {
        messageMountAt: action.time,
      });

    case SET_MESSAGE_SHOW:
      return Object.assign({}, state, {
        messageShow: action.show,
      });

    default:
      return state;
  }
}

const initStatus = {
  modalOrder: {
    formData: {},
    isOpen: false,
    resultShow: false,
  },
  offerFormShow: false,
  offerFormData: {
    price: "",
  },
  priceListIsOpen: false,
  messageMountAt: null,
  messageShow: false, // 用于手机端
};
import React from "react";

const Header = (props) => {
  const { order, history } = props;
  return (
    <div>
      <div
        className="flex items-center mb-4 cursor-pointer"
        onClick={() => {
          history && history.goBack();
        }}
      >
        <svg
          width="25"
          height="25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.46 18.689l-6-6 6-6"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Back</span>
      </div>
      <div className="mt-6 pl-1 md:pl-0">
        <h2>Payment</h2>
      </div>
    </div>
  );
};

export default Header;

import { PopularListsApiRepo } from "@core";

export class PopularListsApiUsecase {
  constructor() {
    this.repo = new PopularListsApiRepo();
  }

  list() {
    return this.repo.list();
  }
}

import { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
/**
 * Core
 */
import { UrlUtils } from "@core/index";

/**
 * Search form hooks
 */
export const useSearchForm = ({ toggleOpen, url } = {}) => {
  const history = useHistory();
  const location = useLocation();

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    category_id: "",
    state_id: "",
    market_type: "",
  });

  /**
   * 表单搜索关键词汇总
   */
  const [keywords, setKeywords] = useState({});

  /**
   * 表单其他param
   */
  const [restParams, setRestParams] = useState({});

  /**
   * 设置关键词
   */
  const onChangeKeywords = useCallback(
    (values) => {
      setKeywords(Object.assign({}, keywords, values));
    },
    [keywords]
  );

  /**
   * [Callback] 提交表单,跳转至搜索结果页面
   * @param {Object}
   * @return {String} /:country_name/result
   */
  const onSubmit = useCallback(
    (values, actions) => {
      actions.setSubmitting(false);
      const params = UrlUtils.compactParams(
        Object.assign({}, values, keywords, restParams)
      );
      const query = UrlUtils.paramsToQuery(params);
      history && history.push(`${url}?${query}`);
      toggleOpen && typeof toggleOpen === "function" && toggleOpen(false);
    },
    [keywords, restParams]
  );

  /**
   * 获取params的effect
   * TODO 需要默认loading所有species
   */
  useEffect(() => {
    const {
      category_id,
      state_id,
      category_name,
      state_name,
      ...restParams
    } = UrlUtils.queryToParams(location.search);
    setInitalValues({
      category_id: Number(category_id),
      state_id: Number(state_id),
    });
    setKeywords({
      category_name,
      state_name,
    });
    setRestParams(restParams);
  }, [location.search]);

  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
    };
  }, []);

  return { initialValues, keywords, onChangeKeywords, onSubmit };
};

import { map, isArray } from "lodash";
import { PaymentEntity } from "@core";

export class PaymentUsecase {

  /**
   * 构造函数
   * @param {Array<Payment> | Payment} data 原始数据(Payment)，可能是数组或单例对象
   */
  constructor(data) {
    this.entity = data;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new PaymentEntity(entity);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new PaymentEntity({});
    }
    return new PaymentEntity(this.entity);
  }

  /**
   * [Class Instance Method] usecase.calculation(order entity)
   * @param {Entity<Order>} order
   * @param {String} paymentMethod "poli|stripe|wallet|offline"
   * @param {String} payType "instant"全款，默认，提交时可为空|"deposit"押金|"balance"尾款
   */
  calculation(order, paymentMethod, payType) {
    return this.single.generateMoney(order, paymentMethod, payType);
  }

}

import { useEffect, useState, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import * as Yup from "yup";

/**
 * import lib tools
 */
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

/**
 * import local tools & redux & usecase
 */
import {
  accountBankShowAction,
  ACCOUNT_BANKS_SHOW,
} from "@redux/modules_v2/account/banks/show/action";
import {
  ACCOUNT_BANKS_UPDATE,
  accountBankUpdateAction,
} from "@redux/modules_v2/account/banks/edit/action";

import Notice from "@utils/noticeNew";
import { AccountBankUsecase, LoadingSelector } from "@core/index";

/**
 * Bank form hooks
 * @param {*} param
 */
export const useAccountBankForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * bank information status;
   */
  const bankShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(ACCOUNT_BANKS_SHOW, state);
    return Object.assign({}, get(state, "accountBanksShowStatus", {}), {
      loading: selector.loading,
    });
  });
  /**
   * 编辑提交后状态
   */
  const bankUpdateStatus = useSelector((state) => {
    const selector = new LoadingSelector(ACCOUNT_BANKS_UPDATE, state);
    return Object.assign({}, get(state, "accountBankUpdateStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    name: "",
    bsb: "",
    acc: "",
    account_name: "",
  });

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback((values) => {
    dispatch(accountBankUpdateAction.request(values));
  }, []);

  /**
   * Schema
   */
  const schema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    bsb: Yup.string().required("This field is required"),
    acc: Yup.string().required("This field is required"),
    account_name: Yup.string().required("This field is required"),
  });

  /**
   * 更新表单数据
   */
  useEffect(() => {
    dispatch(accountBankShowAction.request());
  }, []);

  /**
   * 更新表单的effect
   */
  useEffect(() => {
    if (!bankShowStatus.loading) {
      if (!isEmpty(bankShowStatus.data)) {
        const usecase = new AccountBankUsecase(bankShowStatus.data);
        setInitalValues(usecase.initialValues);
      }
    }
  }, [bankShowStatus.loading]);
  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(accountBankUpdateAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!bankUpdateStatus.loading) {
      if (!isEmpty(bankUpdateStatus.data)) {
        Notice.success("Edit Successfully!");
        history && history.push("/setting");
      }
      if (bankUpdateStatus.error) {
        Notice.failure("Edit Failed", "error");
      }
    }
  }, [bankUpdateStatus.loading]);

  return {
    initialValues,
    loading: bankShowStatus.loading,
    updateLoading: bankUpdateStatus.loading,
    schema,
    onSubmit,
  };
};


export const INVOICES_SHOW_FETCH_REQUEST = "invoices/fetch_show_request";
export const INVOICES_SHOW_FETCH_LOADING = "invoices/fetch_show_loading";
export const INVOICES_SHOW_FETCH_SUCCESS = "invoices/fetch_show_success";
export const INVOICES_SHOW_FETCH_FAILED = "invoices/fetch_show_failed";
export const INVOICES_SHOW_FETCH_RECEIVED = "invoices/fetch_show_received";


export function fetchInvoiceShow(payload) {
  return {
    type: INVOICES_SHOW_FETCH_REQUEST,
    payload
  };
}
/**
 * Component DateTime Picker
 *
 * @example
 *
 * base: https://github.com/YouCanBookMe/react-datetime
 */

import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, getIn } from "formik";
import DateTime from "react-datetime";
import WithLabel from "./with_label";
import moment from "moment";
import cx from "classnames";

export const DateSelect = ({
  className,
  error,
  name,
  placeholder,
  onChange,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext();

  //select on change 的 callback
  const onChangeValue = (values) => {
    setFieldValue(name, values);
    onChange && onChange(values);
  };

  const classNameWrapper = cx("react-select-container", {
    invalid: error
  });

  const customInputProps = Object.assign(
    {},
    {
      className: cx("form-input block w-full md:text-sm md:leading-5", className, { "border-red-300 text-fish-red placeholder-red-300 focus:border-red-300 focus:shadow-outline-red": error }),
    },
    { placeholder: placeholder || "" },
    { readOnly: "readonly" }
  );

  return (
    <div className={classNameWrapper}>
      <DateTime
        {...rest}
        inputProps={customInputProps}
        value={getIn(values, name)}
        onChange={onChangeValue}
      />
    </div>
  );
};

DateSelect.propTypes = {
  bsSize: PropTypes.string, //lg,sm
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
};

DateSelect.defaultProps = {
  defaultValue: "",
  viewMode: "days",
  dateFormat: "DD MMM YYYY",
  timeFormat: "hh:mm A",
  input: true,
  utc: false,
  closeOnSelect: false,
  closeOnTab: true,
  label: "",
  error: "",
  inputProps: {
    className: "form-control",
  },
  onChange: () => {},
  isValidDate: (current) => {
    return current.isAfter(moment().subtract(1, "day"));
  },
};

export default WithLabel("date-select")(DateSelect);

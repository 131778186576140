import React from "react";

/**
 * Core Method
 */
import { links } from "@config/staticLink";

/**
 * Hooks
 */
import { useAccountProfileForm } from "@hooks/account/profile";

/**
 * Components
 */
import { Loading, Image, Link, Mobile, DesktopAndTablet } from "@components";
import SimpleForm from "./simpleForm";

/**
 * [Page] Account profile 个人信息页面
 */
const Profile = () => {
  const { fetchloading, ...formProps } = useAccountProfileForm();
  return (
    <div className="min-h-screen bg-gray-50 relative">
      <div className="hidden md:block absolute top-5 left-8">
        <Link to="/dashboard">
          <Image source="logo" className="h-8 w-32" />
        </Link>
      </div>
      <div className="min-h-screen md:grid grid-cols-1 px-4 md:px-0 pb-12 md:pb-0">
        <div className="w-full h-full flex flex-col justify-center">
          <div className="max-w-xl w-full mx-auto">
            <div className="mt-4 md:mt-0 bg-white rounded-lg shadow-t-card">
              <div className="p-6 md:p-10 md:pb-12">
                <h4 className="text-base">
                  Complete my profile
                </h4>
                <div className="mt-6">
                  {fetchloading ? <Loading /> : <SimpleForm {...formProps} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

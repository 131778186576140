import React from "react";
import PropTypes from "prop-types";

import { useEnquriySell } from "@hooks";

import Header from "./_header";
import ProgressBar from "./_progress_bar";
import Steps from "./_steps";

const Enquiry = (props) => {
  const { step, ...rest } = useEnquriySell();
  return (
    <div className="min-h-screen w-full" style={{ background: "white" }}>
      <Header />
      <ProgressBar step={step} />
      <div className="max-w-md w-full pb-12 px-6 mx-auto">
        <Steps step={step} {...rest} />
      </div>
    </div>
  );
};

Enquiry.propTypes = {};

export default Enquiry;

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";
import { omit } from "lodash";

export class PermanentApiRepo extends BaseApiRepo {
  constructor() {
    super(`${API}/permanent_listings`);
  }

  /**
   * permanent listings new
   */
  new(payload = {}) {
    this.setApi(`${API}/fisheries/${payload.fishery_id}/permanent_listings`);
    return this.post(payload);
  }

  /**
   * permanent listings show
   *
   * /api/v1/permanent_listings/11
   * @param {Object} payload { fishery_id: 1, permanent_listing_id: 1 }
   */
  show(payload = {}) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}`
    );
    return this.get();
  }

  /**
   * permanent listings
   * @param {object} payload 如果含有latest = 1，获取最新的列表, 否则获取全部列表
   */
  list(payload = {}) {
    this.setApi(`${API}/fisheries/${payload.fishery_id}/permanent_listings`);
    return this.get(omit(payload, "fishery_id"));
  }

  /**
   * permanent statistic
   * @param {object} payload
   */
  statistic(payload = {}) {
    this.setApi(`${API}/fisheries/${payload.fishery_id}/permanent_statistic`);
    return this.get();
  }

  /**
   * permanent bid
   */
  bid(payload) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}/bids`
    );
    return this.post(payload);
  }

  /**
   * permanent bid update
   * @param {object} payload
   */
  bidEdit(payload) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}/bids/${payload.id}`
    );
    return this.patch(payload);
  }

  /**
   * permanent bid delete
   * @param {object} payload
   */
  bidDelete(payload) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}/bids/${payload.id}`
    );
    return this.delete();
  }

  /**
   * permanent bid listings
   * @param {object} payload 判断如果isSeller = true， 获取所有bids，否则只获取买家提交的bid
   */
  bidList(payload) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}/${
        payload.isSeller ? "bids" : "bids/current"
      }`
    );
    return this.get();
  }

  /**
   * permanent accept bid
   */
  acceptBid(payload) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}/bids/${payload.id}/accept`
    );
    return this.patch();
  }

  /**
   * permanent listing purchase
   */
  purchase(payload) {
    this.setApi(
      `${API}/permanent_listings/${payload.permanent_listing_id}/purchase`
    );
    return this.post(payload);
  }

  /**
   * Permanent markets
   * @param {Object} {quotum_id: 1}
   */
  markets(payload = {}) {
    this.setApi(`${API}/permanent_markets`);
    return this.get(payload);
  }

  /**
   * Permanent market groups
   * @param {Object} {species_id:1, state_id: 1}
   */
  marketGroups(payload = {}) {
    this.setApi(`${API}/permanent_market_groups`);
    return this.get(payload);
  }

  /**
   * Permanent inquire
   * @param {Object} {}
   * POST /api/v1/permanent_listings/:permanent_listing_id/enquire
   */
  enquire(payload = {}) {
    this.setApi(`${API}/permanent_listings/${payload.id}/enquire`);
    return this.post(payload);
  }
}

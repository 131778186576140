import get from "lodash/get";
import { unitOfSaleMap } from "@core";
import numeral from "@utils/numeral";
import pluralize from "pluralize";

/**
 * [ ENTITY ] Quotum Balance entity
 * @data
 * quotumBalance: {
 * available: 0
 * created_at: "2020-05-13T22:44:11.324+10:00"
 * id: 2
 * pending: 0
 * quota_market_id: 1
 * sold: 0
 * total: 0
 * updated_at: "2020-05-13T22:44:11.324+10:00"
 * }
 */

export class QuotumBalanceEntity {
  constructor(props = {}) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id", "");
  }

  get quotaMarketId() {
    return get(this.data, "quota_market_id", "");
  }

  get quotaMarketTitle() {
    return get(this.data, "quota_market_title", "");
  }

  get sold() {
    return get(this.data, "sold", 0);
  }

  get total() {
    return get(this.data, "total", 0);
  }

  get pending() {
    return get(this.data, "pending", 0);
  }

  get available() {
    return get(this.data, "available", 0);
  }

  get totalConsigned() {
    return numeral(this.pending).add(this.available).value();
  }

  get marketUnitName() {
    return get(this.data, "quota_market_unit", "");
  }

  get isSaleByKg() {
    return this.marketUnitName === unitOfSaleMap.kg;
  }

  getAmountStr(amount) {
    let res;
    try {
      const unitName = this.isSaleByKg
        ? pluralize(this.marketUnitName || "", 1)
        : ` ${pluralize(this.marketUnitName || "", Number(amount || 0))}`;
      res = `${numeral(amount).format("0,0")}${unitName}`;
    } catch (err) {
      res = 0;
    } finally {
      return res;
    }
  }
}

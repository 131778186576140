export const ORDER_PURCHASED = "orders/purchased";
export const ORDER_PURCHASED_REQUEST = `${ORDER_PURCHASED}_request`;
export const ORDER_PURCHASED_SUCCESS = `${ORDER_PURCHASED}_success`;
export const ORDER_PURCHASED_FAILURE = `${ORDER_PURCHASED}_failure`;


export const orderPurchasedAction = {
  request: (payload) => {
    return {
      type: ORDER_PURCHASED_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDER_PURCHASED_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDER_PURCHASED_FAILURE,
      error,
    }
  },

};

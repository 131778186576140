import { takeLatest, call, put } from "redux-saga/effects";

import { PopularListsApiUsecase } from "@core";
import { POPULAR_LISTS_REQUEST, popularListAction } from "./action";

// fetch popluar list
export function* watchPopularListFetch() {
  yield takeLatest(POPULAR_LISTS_REQUEST, popularListFetch);
}

function* popularListFetch(action) {
  try {
    const usecase = new PopularListsApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(popularListAction.success(res.data));
  } catch (err) {
    yield put(popularListAction.failure(err));
  }
}

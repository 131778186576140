import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";


import {
  walletBalanceAPI,
} from "@config/api";


import {
  WALLET_BALANCE_REQUEST,
  walletBalanceFailed,
  walletBalanceSuccess,
  walletBalanceLoading,
} from "./balanceAction";

export function* watchWalletBalanceRequest() {
  yield takeLatest(WALLET_BALANCE_REQUEST, walletBalanceRequest);
}

export function* walletBalanceRequest(action) {
  yield put(walletBalanceLoading());

  const { res, err } = yield call(walletBalanceApi, action.payload);

  if (err) {
    yield put(walletBalanceFailed(err));
    return;
  }
  yield put(walletBalanceSuccess(res));
}

export function walletBalanceApi(payload) {
  return axios
    .get(walletBalanceAPI(), { params: payload } )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


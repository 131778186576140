import get from "lodash/get";

/**
 * 获取User的entity
 * @param {*} currentUser
 * @returns {Object}
 */

export class UserEntity {
  constructor(props) {
    this.data = props;
  }

  //获取current user id
  get currentUserId() {
    return get(this.data, "user_id", "");
  }

  //判断状态是否登录
  get login() {
    return !!this.currentUserId;
  }
}

import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";


import {
  walletTopupAPI,
} from "@config/api";


import {
  WALLET_OFFLINES_REQUEST,
  walletOfflinesFailed,
  walletOfflinesSuccess,
  walletOfflinesLoading,
} from "./offlinesListAction";

export function* watchWalletOfflinesRequest() {
  yield takeLatest(WALLET_OFFLINES_REQUEST, walletOfflinesRequest);
}

export function* walletOfflinesRequest(action) {
  yield put(walletOfflinesLoading());

  const { res, err } = yield call(walletOfflinesApi, action.payload);

  if (err) {
    yield put(walletOfflinesFailed(err));
    return;
  }
  yield put(walletOfflinesSuccess(res));
}

export function walletOfflinesApi(payload) {
  return axios
    .get(walletTopupAPI('offlines'), { params: payload } )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


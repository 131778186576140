import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import * as Yup from "yup";

/**
 * import lib tools
 */
import { useDispatch, useSelector } from "react-redux";

/**
 * import local tools & redux & usecase
 */
import {
  changePasswordRequest,
  changePasswordReset,
} from "@redux/modules/changePassword/action";
import Notice from "@utils/noticeNew";
/**
 * hooks
 */

/**
 * Password form hooks
 * @param {*} param
 */
export const useAccountPasswordForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * 编辑提交后状态
   */
  const changePasswordStatus = useSelector((state) =>
    get(state, "changePasswordStatus", {})
  );

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    password_current: "",
    password: "",
    password_confirm: "",
  });

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback(({ password_current, password }) => {
    return new Promise((resolve, reject) => {
      dispatch(
        changePasswordRequest({
          oldPassword: password_current,
          newPassword: password,
          resolve,
          reject,
        })
      );
    });
  }, []);

  /**
   * Schema
   */
  const schema = Yup.object().shape({
    password_current: Yup.string()
      .min(5, "Password length should be greater than or equal to 6")
      .required("This field is required"),
    password: Yup.string()
      .min(5, "Password length should be greater than or equal to 6")
      .required("This field is required"),
    password_confirm: Yup.string()
      .min(5, "Password length should be greater than or equal to 6")
      .required("This field is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(changePasswordReset());
    };
  }, []);

  useEffect(() => {
    if (!changePasswordStatus.loading) {
      if (changePasswordStatus.data) {
        Notice.success("Update password successfully!");
        history && history.push("/setting");
      }
      if (changePasswordStatus.error) {
        Notice.failure(
          get(changePasswordStatus, "error.message", "Update password Failed")
        );
      }
    }
  }, [changePasswordStatus.loading]);

  return {
    initialValues,
    onSubmit,
    schema,
    loading: changePasswordStatus.loading,
  };
};

import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { first } from "lodash";

import { walletWithdrawalAPI } from "@config/api";

import {
  WALLET_WITHDRAWAL_SHOW_REQUEST,
  WALLET_WITHDRAWAL_SHOW_LOADING,
  WALLET_WITHDRAWAL_SHOW_SUCCESS,
  WALLET_WITHDRAWAL_SHOW_FAILED,
} from "./withdrawalShowAction";

export function* watchWalletWithdrawalShowRequest() {
  yield takeLatest(WALLET_WITHDRAWAL_SHOW_REQUEST, walletWithdrawalShow);
}

export function* walletWithdrawalShow(action) {
  yield put({ type: WALLET_WITHDRAWAL_SHOW_LOADING });

  const { res, err } = yield call(walletWithdrawalShowApi);

  if (err) {
    yield put({ type: WALLET_WITHDRAWAL_SHOW_FAILED, error: err });
    return;
  }
  const list = res.array || [];
  const data = first(list) || {};
  yield put({ type: WALLET_WITHDRAWAL_SHOW_SUCCESS, data });
}

export function walletWithdrawalShowApi() {
  return axios
    .get(walletWithdrawalAPI())
    .then(res => ({
      res: res.data,
    }))
    .catch(err => ({
      err,
    }));
}

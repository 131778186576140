import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import {
  ACCOUNT_BANKS_SHOW_REQUEST,
  accountBankShowAction,
} from "./action";

// fetch permanent list
export function* watchAccountBankShowFetch() {
  yield takeLatest(ACCOUNT_BANKS_SHOW_REQUEST, bankShowFetch);
}

function* bankShowFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "bankShow"], action.payload);
    yield put(accountBankShowAction.success(res.data));
  } catch (err) {
    yield put(accountBankShowAction.failure(err));
  }
}

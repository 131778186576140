export const WS_CONNECTING_STATUS_ADD = "ws_connecting/status_add";
export const WS_CONNECTING_STATUS_DEL = "ws_connecting/status_delete";
export const WS_CONNECTING_STATUS_SET = "ws_connecting/status_set";


export function addWsConnectingStatus(channel) {
  return {
    type: WS_CONNECTING_STATUS_ADD,
    payload: {
      channel
    }
  };
}

export function delWsConnectingStatus(channel) {
  return {
    type: WS_CONNECTING_STATUS_DEL,
    payload: {
      channel
    }
  };
}

export function setWsConnectingStatus(channel, status) {
  return {
    type: WS_CONNECTING_STATUS_SET,
    payload: {
      channel,
      status
    }
  };
}

import React from "react";
import { get } from "lodash";

// hooks
import {
  useUpdatePermanent,
  useAccountPermanentShow,
  useAccountPermanentDelete,
} from "@hooks";
// tools
// import getUrlQuery from "@utils/getUrlQuery";
// local components
import Layout from "@views/layout";
import { Button, Breadcrumb, Loading, DesktopAndTablet, Mobile } from "@components";
import Form from "../sell/form";

/**
 * [Page] quota unit edit
 */
const Edit = (props) => {
  const { history, match } = props;
  const id = get(match, "params.id", "");

  const { usecase, loading: loadingShow } = useAccountPermanentShow(id);
  const { deletePermanent, loading: loadingDelete } = useAccountPermanentDelete(
    () => {
      history && history.goBack();
    }
  );
  const permanent = usecase.single;

  const {
    addNewMarketsAttribute,
    getQuotaOptions,
    getUnitNameByQuotumId,
    unitNameCondition,
    handleSubmit,
    startSubmit,
    moveMarket,
    initialValues,
    title,
    totalPrice,
    fisheryOptions,
    loading: loadingUpdate,
    quotaListLoading,
    isLicence,
  } = useUpdatePermanent({ permanent }, () => {
    history && history.goBack();
  });
  const renderContent = () => {
    if (loadingShow) {
      return <Loading />;
    }
    return (
      <div className="px-2 md:px-0">
        <Breadcrumb className="mt-6 px-2 md:px-0" back />
        <h2 className="px-2 md:px-0 mt-6">
          Edit Quota Unit
        </h2>
        <div className="mt-6 md:mt-10 px-4 py-6 md:p-6 rounded-lg border border-blue-gray-100 shadow bg-white">
          <div className="">
            <Form
              addNewMarketsAttribute={addNewMarketsAttribute}
              enableReinitialize={true}
              getQuotaOptions={getQuotaOptions}
              getUnitNameByQuotumId={getUnitNameByQuotumId}
              unitNameCondition={unitNameCondition}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              title={title}
              totalPrice={totalPrice}
              fisheryOptions={fisheryOptions}
              loading={loadingUpdate}
              type={permanent.listingType}
              quotaListLoading={quotaListLoading}
              moveMarket={moveMarket}
              edit={true}
              isLicence={isLicence}
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <Button
            outline
            className="mr-4 hover:bg-fish-primary hover:text-white"
            loading={loadingDelete}
            disabled={loadingDelete}
            onClick={() => {
              if (confirm("Are you sure to delete this listing?")) {
                deletePermanent(id);
              }
            }}
          >
            Delete
          </Button>
          <Button
            className="hover:bg-fish-primary"
            loading={loadingUpdate}
            disabled={loadingUpdate}
            onClick={() => {
              startSubmit();
            }}
          >
            Update
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="bg-gray-50 flex-1">
        <DesktopAndTablet>
          <div className="mx-auto my-8 md:my-10" style={{ width: 640 }}>
            {renderContent()}
          </div>
        </DesktopAndTablet>
        <Mobile>
          <div className="mb-6">{renderContent()}</div>
        </Mobile>
      </div>
    </Layout>
  );
};

export default Edit;

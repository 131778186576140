import { ACCOUNT_BIDS_SUCCESS, ACCOUNT_BIDS_FAILURE } from "./action";

export const acccountBidsStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ACCOUNT_BIDS_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case ACCOUNT_BIDS_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

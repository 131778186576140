import React from "react";
import { Elements } from "react-stripe-elements";
import InjectedStripeCardForm from "./_stripe_card_form";

const Checkout = (props) => {
  return (
    <Elements locale="en">
      <InjectedStripeCardForm {...props} />
    </Elements>
  );
};

export default Checkout;

export const AUCTION_SIMILAR_LIST = "auction/similar_list";
export const AUCTION_SIMILAR_LIST_REQUEST = `${AUCTION_SIMILAR_LIST}_request`;
export const AUCTION_SIMILAR_LIST_SUCCESS = `${AUCTION_SIMILAR_LIST}_success`;
export const AUCTION_SIMILAR_LIST_FAILURE = `${AUCTION_SIMILAR_LIST}_failure`;
export const AUCTION_SIMILAR_LIST_UPDATE = `${AUCTION_SIMILAR_LIST}_update`;

export const auctionSimilarListAction = {
  request: (payload) => {
    return {
      type: AUCTION_SIMILAR_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: AUCTION_SIMILAR_LIST_SUCCESS,
      data,
    };
  },
  update: (data) => {
    return {
      type: AUCTION_SIMILAR_LIST_UPDATE,
      data,
    };
  },
  failure: (error) => {
    return {
      type: AUCTION_SIMILAR_LIST_FAILURE,
      error,
    };
  },
};

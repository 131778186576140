export const OFFERS_APPLY = "offers/apply";
export const OFFERS_APPLY_REQUEST = `${OFFERS_APPLY}_request`;
export const OFFERS_APPLY_SUCCESS = `${OFFERS_APPLY}_success`;
export const OFFERS_APPLY_FAILURE = `${OFFERS_APPLY}_failure`;
export const OFFERS_APPLY_RESET = `${OFFERS_APPLY}_reset`;

export const offersApplyAction = {
  request: (payload) => {
    return {
      type: OFFERS_APPLY_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: OFFERS_APPLY_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: OFFERS_APPLY_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: OFFERS_APPLY_RESET
    }
  },
};

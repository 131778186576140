import { get } from "lodash";
import { Moment } from "@core";
import { numberFormat } from "@utils/numeral";

/**
 * [Entity] Fishing Season
 * data is obj
  {
    "id": 1,
    "name": "2020-2",
    "kg_per_quota": "308.46",
    "start_at": "2020-01-01T00:00:00+11:00",
    "end_at": "2020-12-31T23:59:59+11:00",
    "total_allowable_catch": 1162882,
    "latest_quota_usage": {
      "record_at": "2020-04-28"
      "usage": 636038
    }
  }
 */
export class SeasonEntity {
  constructor(data) {
    this.entity = data;
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get name() {
    return get(this.entity, "name", "");
  }

  get kgPerUnit() {
    return get(this.entity, "kg_per_quota", "");
  }

  get startAt() {
    return new Moment(get(this.entity, "start_at"));
  }

  get startAtStr() {
    return this.startAt.formatDate || "";
  }

  get endAt() {
    return new Moment(get(this.entity, "end_at"));
  }

  get endAtStr() {
    return this.endAt.formatDate || "";
  }

  get isStart() {
    return this.startAt.isExpired;
  }

  get isEnd() {
    return this.endAt.isExpired;
  }

  get isRunning() {
    return this.isStart && !this.isEnd;
  }

  get totalAllowableCatch() {
    return get(this.entity, "total_allowable_catch", 0);
  }

  get usage() {
    return get(this.entity, "latest_quota_usage.usage");
  }

  get usagePercent() {
    if (this.totalAllowableCatch > 0) {
      return this.usage / this.totalAllowableCatch;
    }
    return 0;
  }

  get usagePercentStr() {
    return numeral(this.usagePercent).format("0.00%");
  }

  get remain() {
    if (this.totalAllowableCatch > 0) {
      return this.totalAllowableCatch - (this.usage || 0);
    }
    return 0;
  }

  get remainPercent() {
    return 1 - this.usagePercent;
  }

  get remainPercentStr() {
    return numeral(this.remainPercent).format("0.00%");
  }

  get remainWithKg() {
    return `${numberFormat(this.remain) || 0}kg`;
  }

  get title() {
    return `${this.startAtStr} to ${this.endAtStr}`;
  }

  get years() {
    const startAt = new Moment(get(this.entity, "start_at"));
    const endAt = new Moment(get(this.entity, "end_at"));
    startAt.setFormat = "YYYY"; 
    const startYear = startAt.format;
    endAt.setFormat = "YYYY";
    const endYear = endAt.format;
    if (startYear === endYear) {
      return `${startYear}`;
    }
    return `${startYear}/${endYear}`;
  }
}

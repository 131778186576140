import React from "react";
import PropTypes from "prop-types";

/**
 * lib tools
 */
import get from "lodash/get";
import numeral from "@utils/numeral";

/**
 * import core
 */
import { CalculateAmount } from "@core";

/**
 * Hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * import local tools
 */
import { Loading } from "@components";
import { Input, formatFixedNumber, formatInt } from "@views/components/formik";

const ThirdStep = ({ values, loading }) => {
  const market = get(values, "market.market", {}) || {};
  const price = get(values, "price", "") || 0;
  const quantity = get(values, "quantity", "") || 0;
  const { amount, unitOfPrice, dynaUnitName, kgAmount } = useMarketUnitName(
    market,
    quantity
  );
  const totalAmount = numeral(price)
    .multiply(amount || 0)
    .value();
  const { str } = CalculateAmount.getSalesIncome(totalAmount);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="grid gap-4 grid-cols-1">
      <Input
        label={
          <div className="flex items-center justify-between">
            <span>Quantity to list</span>
            <span>{kgAmount}</span>
          </div>
        }
        name="quantity"
        placeholder="Quota quantity"
        className="form-input-lg"
        rightSymbol={dynaUnitName}
        parse={formatInt}
      />
      <Input
        label="Set fixed asking price"
        name="price"
        placeholder="Enter price"
        className="form-input-lg"
        leftSymbol="$"
        rightSymbol={`per ${unitOfPrice}`}
        parse={formatFixedNumber}
      />
      {price && amount ? (
        <div className="">
          <div className="font-medium text-blue-gray-700 py-4">
            What you’ll receive
          </div>
          <ul className="bg-gray-50 p-4 rounded-lg space-y-3">
            <li className="flex items-center justify-between">
              <span className="text-blue-gray-500 text-sm">Items value</span>
              <span className="text-blue-gray-700 text-sm font-medium">
                {get(str, "amount", "")}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-blue-gray-500 text-sm">
                Minus service fee (5%)
              </span>
              <span className="text-blue-gray-700 text-sm font-medium">{`-${get(
                str,
                "serviceFee",
                ""
              )}`}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-blue-gray-500 text-sm">Subtotal</span>
              <span className="text-blue-gray-700 text-sm font-medium">
                {get(str, "subtotal", "")}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-blue-gray-500 text-sm">GST</span>
              <span className="text-blue-gray-700 text-sm font-medium">
                {get(str, "GST", "")}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-blue-gray-500 text-sm font-bold">
                Total you will receive
              </span>
              <span className="text-blue-gray-700 text-sm font-bold">
                {get(str, "totalWithGST", "")}
              </span>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

ThirdStep.propTypes = {
  values: PropTypes.object,
  loading: PropTypes.bool,
};

ThirdStep.defaultProps = {
  values: {},
  loading: false,
};

export default ThirdStep;

import { ListingApiRepo } from "@core";

export class ListingApiUsecase {
  constructor() {
    this.repo = new ListingApiRepo();
  }

  create(payload) {
    return this.repo.create(payload);
  }

  transferQuota(payload) {
    return this.repo.transferQuota(payload);
  }

  getQuotaTransfers(payload) {
    return this.repo.getQuotaTransfers(payload);
  }

  enquiry(payload) {
    return this.repo.enquiry(payload);
  }
}

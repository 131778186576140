import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";

import {
  currentUserStatus,
  logoutStatus,
  currentUserRefreshStatus,
} from "./modules/auth/reducer";

/**
 * about api query data status
 */

import { offerPriceListStatus } from "./modules/offerPrices/listReducer";
import { offerPriceShowStatus } from "./modules/offerPrices/showReducer";
import { offerPriceCreateStatus } from "./modules/offerPrices/createReducer";
import { offerPriceUpdateStatus } from "./modules/offerPrices/updateReducer";
import { offerPriceDeleteStatus } from "./modules/offerPrices/deleteReducer";
import { offerPriceAttendListStatus } from "./modules/offerPrices/attendListReducer";

import { askingPriceListStatus } from "./modules/askingPrices/listReducer";
import { askingPriceShowStatus } from "./modules/askingPrices/showReducer";
import { askingPriceCreateStatus } from "./modules/askingPrices/createReducer";
import { askingPriceUpdateStatus } from "./modules/askingPrices/updateReducer";
import { askingPriceDeleteStatus } from "./modules/askingPrices/deleteReducer";
import { askingPriceAttendListStatus } from "./modules/askingPrices/attendListReducer";
import { askingPriceQuotaBalanceStatus } from "./modules/askingPrices/quotaBalanceReducer";

import { exchangeListStatus } from "./modules/exchanges/listReducer";

import { listingListStatus } from "./modules/listings/listReducer";
import { listingShowStatus } from "./modules/listings/showReducer";
import { listingCreateStatus } from "./modules/listings/createReducer";
import { listingMessageStatus } from "./modules/listings/messageReducer";

import {
  priceableListStatus,
  priceableAuctionsListStatus,
} from "./modules/priceables/listReducer";
import { priceableCategoryListStatus } from "./modules/priceables/categoryReducer";
import { priceableProductListStatus } from "./modules/priceables/productsReducer";
import { priceableHeaderStatus } from "./modules/priceables/headerReducer";

import { applyCreateStatus } from "./modules/applies/createReducer";
import { applyDeleteStatus } from "./modules/applies/deleteReducer";

import { channelListStatus } from "./modules/channels/listReducer";
import { channelCreateStatus } from "./modules/channels/createReducer";
import { channelMessageListStatus } from "./modules/channels/messageListReducer";
import { channelMessageCreateStatus } from "./modules/channels/messageCreateReducer";

import { productListStatus } from "./modules/products/listReducer";
import { productShowStatus } from "./modules/products/showReducer";
import { productRecentTradeStatus } from "./modules/products/recentTradeReducer";
import { productPricesStatus } from "./modules/products/pricesReducer";

import { orderListStatus } from "./modules/orders/listReducer";
import { orderShowStatus } from "./modules/orders/showReducer";
import { orderCreateStatus } from "./modules/orders/createReducer";
import { orderPaymentStatus } from "./modules/orders/paymentReducer";
import { orderDashboardListStatus } from "./modules/orders/dashboardReducer";
import { orderDashboardSideListStatus } from "./modules/orders/dashboardSideReducer";
import { closeOrderStatus } from "./modules/orders/closeReducer";

import { invoiceCreateStatus } from "./modules/invoices/createReducer";
import { invoiceShowStatus } from "./modules/invoices/showReducer";

import { bankUpdateStatus } from "./modules/banks/editReducer";
import { bankShowStatus } from "./modules/banks/showReducer";
import { bankUpdateForOrderStatus } from "./modules/banks/OrderEditReducer";

/**
 * about pages state
 */
import layoutState from "./modules/pageStates/layoutState";
import productListState from "./modules/pageStates/productListState";
import productShowState from "./modules/pageStates/productShowState";
import dashboardState from "./modules/pageStates/dashboardState";
import invoiceState from "./modules/pageStates/invoiceState";
import quotaListState from "./modules/pageStates/quotaListState";
import quotaShowState from "./modules/pageStates/quotaShowState";
import quotaNewState from "./modules/pageStates/quotaNewState";
import categoryShowState from "./modules/pageStates/categoryShowState";
import listingListState from "./modules/pageStates/listingListState";

/*
 * product new
 */

import productNewStatus from "./modules/products/newReducer";

/**
 * product cart
 */

import {
  cartAskingPricesStatus,
  cartUpdatePricesStatus,
  cartDeletePricesStatus,
} from "./modules/cart/askingPricesReducer";
import { cartOfferPricesStatus } from "./modules/cart/offerPricesReducer";

/**
 * category list
 */

import { categoryListStatus } from "./modules/categories/listReducer";
import { categoryProductGradesStatus } from "./modules/categories/gradeReducer";

import { chartsLastSoldStatus } from "./modules/charts/lastSoldReducer";

/**
 * static data
 */

import { staticDataStatus } from "./modules/static/reducer";
import { liveStatesStatus } from "./modules/static/liveStatesReducer";

/**
 * auctions
 */

import { auctionsListStatus } from "./modules/auctions/listReducer";
import { auctionsListOwnStatus } from "./modules/auctions/listReducer";
import { auctionsListInvolvedStatus } from "./modules/auctions/listReducer";
import { auctionsNewStatus } from "./modules/auctions/newReducer";
import { auctionsEditStatus } from "./modules/auctions/editReducer";
import { auctionsShowStatus } from "./modules/auctions/showReducer";
import { auctionsCatesStatus } from "./modules/auctions/catesReducer.js";

/**
 * bids
 */

import { bidsNewStatus } from "./modules/bids/newReducer";
import { bidsListStatus, bidsAllListStatus } from "./modules/bids/listReducer";
import { bidCurrentStatus } from "./modules/bids/currentReducer";

/**
 * timestamp
 */
import { timestampStatus } from "./modules/timeStamp/reducer";
/**
 * subscribe
 */

import { subscribeStatus } from "./modules/subscribe/reducer";

/**
 * login
 */
import { loginStatus } from "./modules/login/reducer";

/**
 * register
 */
import { registerStatus } from "./modules/register/reducer";

/**
 * verify
 */
import { verifyStatus } from "./modules/verify/reducer";

/**
 * resend code
 */
import { resendCodeStatus } from "./modules/resendCode/reducer";

/**
 * change email
 */
import { changeEmailStatus } from "./modules/changeEmail/reducer";

/**
 * change email code request
 */
import { resendEmailConfirmationCodeStatus } from "./modules/resendEmailCode/reducer";

/**
 * change email code verify
 */
import { verifyEmailConfirmationCodeStatus } from "./modules/verifyEmail/reducer";

/**
 * change password
 */
import { changePasswordStatus } from "./modules/changePassword/reducer";

/**
 * update attribute
 */
import { updateAttributeStatus } from "./modules/updateAttribute/reducer";

/**
 * init forgot password
 */
import { initForgotPassword } from "./modules/forgotPassword/initReducer";

/**
 * confirm forgot password
 */
import { confirmForgotPassword } from "./modules/forgotPassword/confirmReducer";

/**
 * refresh cognito user
 */
import { refreshCognitoUser } from "./modules/refreshCognitoUser/reducer";

/**
 * recharge deposit
 */
import { rechargeDeposit } from "./modules/rechargeDeposit/reducer";

/**
 * upload avatar
 */
import { uploadAvatar } from "./modules/uploadAvatar/reducer";

/**
 * change email event
 */
import { changeEmailEvent } from "./modules/changeEmailEvent/reducer";

/**
 * change password event
 */
import { changePasswordEvent } from "./modules/changePasswordEvent/reducer";

/**
 * update profile event
 */
import { updateProfileEvent } from "./modules/updateProfileEvent/reducer";
import { profileStatus } from "./modules/profile/reducer";

import { currentCable } from "./modules/actioncable/reducer";

import { wsConnectingState } from "./modules/wsConnecting/reducer";

/**
 * fetch points
 */
import {
  pointsStatus,
  pointsRewardStatus,
  pointsConsumeStatus,
} from "./modules/points/reducer";

/**
 * loading status
 */
import { loadingsStatus } from "./modules/loadings/reducer";

import { licenceNumberEventsStatus } from "./modules/licenceNumberEvents/reducer";

import { walletStatus } from "./modules/wallet/listReducer";
import { walletOfflinesStatus } from "./modules/wallet/offlinesListReducer";
import { walletTopupStatus } from "./modules/wallet/topupReducer";
import { walletWithdrawalShowStatus } from "./modules/wallet/withdrawalShowReducer";
import { walletWithdrawalStatus } from "./modules/wallet/withdrawalReducer";
import { walletBalanceStatus } from "./modules/wallet/balanceReducer";
import { walletWithdrawalCancelStatus } from "./modules/wallet/withdrawalCancelReducer";

// ------------------ v2 --------------------------
import { loadingStatus } from "./modules_v2/loadings/reducer";
// global
import { globalStaticStatus } from "./modules_v2/global/static/reducer";
// market
import { marketListStatus } from "./modules_v2/markets/list/reducer";
import { marketShowStatus } from "./modules_v2/markets/show/reducer";
import { marketStatisticsStatus } from "./modules_v2/markets/statistics/reducer";
import { marketBalanceStatus } from "./modules_v2/markets/balance/reducer";
// offer
import { offerListStatus } from "./modules_v2/offers/list/reducer";
import { offerCreateStatus } from "./modules_v2/offers/create/reducer";
import { offerUpdateStatus } from "./modules_v2/offers/update/reducer";
import { offerDeleteStatus } from "./modules_v2/offers/delete/reducer";
import { offerOrderStatus } from "./modules_v2/offers/order/reducer";
import { offersApplyStatus } from "./modules_v2/offers/apply/reducer";
import { offersApplyConfirmStatus } from "./modules_v2/offers/applyConfirm/reducer";
// bid
import { bidListStatus } from "./modules_v2/bids/list/reducer";
import { bidCreateStatus } from "./modules_v2/bids/create/reducer";
import { bidUpdateStatus } from "./modules_v2/bids/update/reducer";
import { bidDeleteStatus } from "./modules_v2/bids/delete/reducer";
import { bidOrderStatus } from "./modules_v2/bids/order/reducer";
// quota transfer apply
import { transferApplyCreateStatus } from "./modules_v2/quotaTransferApplies/create/reducer";
// order
import { orderListStatusV2 } from "./modules_v2/orders/list/reducer";
import { orderShowStatusV2 } from "./modules_v2/orders/show/reducer";
import { orderPaymentStatusV2 } from "./modules_v2/orders/payment/reducer";
import { orderPurchasedStatus } from "./modules_v2/orders/purchased/reducer";
import { orderSoldStatus } from "./modules_v2/orders/sold/reducer";
import { ordersBuyerPayStatus } from "./modules_v2/orders/actions/buyerPay/reducer";
import { orderPaymentProofsListStatus } from "./modules_v2/orders/actions/paymentProofs/list/reducer";
import { ordersPaymentProofsNewStatus } from "./modules_v2/orders/actions/paymentProofs/new/reducer";
import { ordersPaymentProofsDeleteStatus } from "./modules_v2/orders/actions/paymentProofs/delete/reducer";
import { ordersReceiveFundStatus } from "./modules_v2/orders/actions/receiveFund/reducer";
import { ordersQuotaTransfersListStatus } from "./modules_v2/orders/actions/quotaTransfers/list/reducer";
import { ordersQuotaTransfersNewStatus } from "./modules_v2/orders/actions/quotaTransfers/new/reducer";

// invoice
import { invoiceShowStatusV2 } from "./modules_v2/invoices/show/reducer";
import { invoiceCreateStatusV2 } from "./modules_v2/invoices/create/reducer";

// wallet
import { walletBalanceStatusV2 } from "./modules_v2/wallets/balance/reducer";
import { walletEntriesStatus } from "./modules_v2/wallets/entries/reducer";
import { walletTopupStatusV2 } from "./modules_v2/wallets/topup/reducer";
// withdraw (wallet)
import { withdrawListStatus } from "./modules_v2/withdraws/list/reducer";
import { withdrawShowStatus } from "./modules_v2/withdraws/show/reducer";
import { withdrawCreateStatus } from "./modules_v2/withdraws/create/reducer";
// quota
import { quotaListStatus } from "./modules_v2/quotas/list/reducer";
// permanent
import { permanentAcceptBidStatus } from "./modules_v2/permanent/acceptBid/reducer";
import { permanentBidStatus } from "./modules_v2/permanent/bid/reducer";
import { permanentBidListStatus } from "./modules_v2/permanent/bidList/reducer";
import { permanentListStatus } from "./modules_v2/permanent/list/reducer";
import { permanentNewStatus } from "./modules_v2/permanent/new/reducer";
import { permanentPurchaseStatus } from "./modules_v2/permanent/purchase/reducer";
import { permanentShowStatus } from "./modules_v2/permanent/show/reducer";
import { permanentDeleteStatus } from "./modules_v2/permanent/bidDelete/reducer";
import { permanentMarketsStatus } from "./modules_v2/permanent/markets/reducer";
import { permanentMarketGroupsStatus } from "./modules_v2/permanent/marketGroups/reducer";
import { permanentStatisticStatus } from "./modules_v2/permanent/statistic/reducer";
import { permanentEnquireStatus } from "./modules_v2/permanent/enquire/reducer";

// quota attr
import { countriesListStatus } from "./modules_v2/quotaAttr/countries/reducer";
import { statesListStatus } from "./modules_v2/quotaAttr/states/reducer";
import { categoriesListStatus } from "./modules_v2/quotaAttr/categories/reducer";
// user
import { userProfileStatus } from "./modules_v2/users/profile/reducer";
// account
import { acccountBidsStatus } from "./modules_v2/account/bids/reducer";
import { acccountOffersStatus } from "./modules_v2/account/offers/reducer";
import { acccountRecendStatus } from "./modules_v2/account/recend/reducer";
import { acccountOpenOrdersStatus } from "./modules_v2/account/openOrders/reducer";
import { accountProfileUpdateStatus } from "./modules_v2/account/profile/reducer";
import { accountPermanentListStatus } from "./modules_v2/account/permanentListing/reducer";
import { accountPermanentShowStatus } from "./modules_v2/account/permanentShow/reducer";
import { accountPermanentDeleteStatus } from "./modules_v2/account/permanentDelete/reducer";
import { accountPermanentUpdateStatus } from "./modules_v2/account/permanentUpdate/reducer";
import { accountBanksShowStatus } from "./modules_v2/account/banks/show/reducer";
import { accountBankUpdateStatus } from "./modules_v2/account/banks/edit/reducer";
import { accountStatementListStatus } from "./modules_v2/account/statementListing/reducer";
import { accountQuotumBalancesStatus } from "./modules_v2/account/quotumBalances/reducer";
import { accountQuotumInventoriesStatus } from "./modules_v2/account/quotumInventories/reducer";
import { accountCreatedAuctionsStatus } from "./modules_v2/account/createdAuctions/reducer";
import { accountInvolvedAuctionsStatus } from "./modules_v2/account/involvedAuctions/reducer";
import { accountProfileRegisterStatus } from "./modules_v2/account/profile/register/reducer";
import { accountNotificationListStatus } from "./modules_v2/account/notification/reducer";

// popluar lists
import { popularListStatus } from "./modules_v2/popularLists/list/reducer";

// fishery
import { fisheryShowStatus } from "./modules_v2/fishery/show/reducer";
import { fisheryQuotaMarketsStatus } from "./modules_v2/fishery/quotaMarkets/reducer";
import { fisheriesListStatus } from "./modules_v2/fishery/list/reducer";
import { fisheryStickListStatus } from "./modules_v2/fishery/stickList/reducer";
import { fisheryActivityStatus } from "./modules_v2/fishery/activity/reducer";
import { fisheryExistenceStatus } from "./modules_v2/fishery/existence/reducer";
import { fisheryFollowStatus } from "./modules_v2/fishery/follow/reducer";
import { fisheryFollowListStatus } from "./modules_v2/fishery/followList/reducer";
import { fisheryFollowingQuotaMarketsStatus } from "./modules_v2/fishery/followingQuotaMarkets/reducer";
import { fisheryQuotaListStatus } from "./modules_v2/fishery/quotaList/reducer";
import { fisheryFollowingAuctionsStatus } from "./modules_v2/fishery/followingAuctions/reducer";

// licence
import { licenceUpdateStatus } from "./modules_v2/licences/update/reducer";

// auction for new edition
import { auctionListStatus } from "./modules_v2/auctions/list/reducer";
import { auctionShowStatus } from "./modules_v2/auctions/show/reducer";
import { auctionBidCreateStatus } from "./modules_v2/auctions/bidCreate/reducer";
import { auctionBidListStatus } from "./modules_v2/auctions/bidList/reducer";
import { auctionBidAllListStatus } from "./modules_v2/auctions/bidAllList/reducer";
import { auctionNewStatus } from "./modules_v2/auctions/new/reducer";
import { auctionFinalBidShowStatus } from "./modules_v2/auctions/finalBid/reducer";
import { auctionFinalBidCreateStatus } from "./modules_v2/auctions/finalBidCreate/reducer";
import { auctionInstantBuyStatus } from "./modules_v2/auctions/instantBuy/reducer";
import { auctionFinalChancesListStatus } from "./modules_v2/auctions/finalChancesList/reducer";
import { auctionSimilarListStatus } from "./modules_v2/auctions/similarList/reducer";

// listings
import { listingOfferNewStatus } from "./modules_v2/listing/new/reducer";
import { listingQuotaTransfersNewStatus } from "./modules_v2/listing/quotaTransfers/new/reducer";
import { listingQuotaTransfersListStatus } from "./modules_v2/listing/quotaTransfers/list/reducer";
import { listingOfferEnquiryStatus } from "./modules_v2/listing/enquiry/reducer";

import { p2pNewStatus } from "./modules_v2/p2p/new/reducer";

export default combineReducers({
  // redux-form
  form: formReducer,
  // page states
  pageStates: combineReducers({
    layoutState,
    productListState,
    productShowState,
    dashboardState,
    invoiceState,
    quotaListState,
    quotaShowState,
    quotaNewState,
    categoryShowState,
    listingListState,
  }),

  // product
  productListStatus,
  productShowStatus,
  productNewStatus,
  productRecentTradeStatus,
  productPricesStatus,
  // offer & asking price
  offerPriceListStatus,
  offerPriceShowStatus,
  offerPriceCreateStatus,
  offerPriceUpdateStatus,
  offerPriceDeleteStatus,
  offerPriceAttendListStatus,
  askingPriceListStatus,
  askingPriceShowStatus,
  askingPriceCreateStatus,
  askingPriceUpdateStatus,
  askingPriceDeleteStatus,
  askingPriceAttendListStatus,
  askingPriceQuotaBalanceStatus,
  // exchanges
  exchangeListStatus,

  // listing
  listingListStatus,
  listingShowStatus,
  listingCreateStatus,
  listingMessageStatus,

  // priceables
  priceableListStatus,
  priceableAuctionsListStatus,
  priceableCategoryListStatus,
  priceableProductListStatus,
  priceableHeaderStatus,
  // 子报价 applies
  applyCreateStatus,
  applyDeleteStatus,
  // order
  orderCreateStatus,
  orderShowStatus,
  orderPaymentStatus,
  orderListStatus,
  orderDashboardListStatus,
  orderDashboardSideListStatus,
  closeOrderStatus,
  //channels
  channelListStatus,
  channelCreateStatus,
  channelMessageListStatus,
  channelMessageCreateStatus,

  // cart
  cartAskingPricesStatus,
  cartOfferPricesStatus,
  cartUpdatePricesStatus,
  cartDeletePricesStatus,
  //auctions
  auctionsListStatus,
  auctionsListOwnStatus,
  auctionsListInvolvedStatus,
  auctionsNewStatus,
  auctionsEditStatus,
  auctionsShowStatus,
  auctionsCatesStatus,
  //bids,
  bidsNewStatus,
  bidsListStatus,
  bidCurrentStatus,
  bidsAllListStatus,
  //categories
  categoryListStatus,
  categoryProductGradesStatus,
  // invoices
  invoiceCreateStatus,
  invoiceShowStatus,
  // charts
  chartsLastSoldStatus,
  // banks
  bankUpdateStatus,
  bankShowStatus,
  bankUpdateForOrderStatus,
  // static data
  staticDataStatus,
  liveStatesStatus,

  // auth
  currentUserStatus,
  logoutStatus,
  loginStatus,
  currentUserRefreshStatus,
  // register
  registerStatus,
  // verify
  verifyStatus,
  // resend code
  resendCodeStatus,
  // change email
  changeEmailStatus,
  // change email code request
  resendEmailConfirmationCodeStatus,
  // change email code verify
  verifyEmailConfirmationCodeStatus,
  // change password
  changePasswordStatus,
  // update attribute
  updateAttributeStatus,
  // init forgot password
  initForgotPassword,
  // confirm forgot password
  confirmForgotPassword,
  //timestamp
  timestampStatus,
  //subscirbe
  subscribeStatus,
  // refresh cognito user
  refreshCognitoUser,
  // recharge deposit
  rechargeDeposit,
  // upload avatar
  uploadAvatar,
  // change email event
  changeEmailEvent,
  // change password event
  changePasswordEvent,
  // update profile event
  updateProfileEvent,
  // users(account) wallet
  walletStatus,
  walletOfflinesStatus,
  walletBalanceStatus,
  walletTopupStatus,
  walletWithdrawalShowStatus,
  walletWithdrawalStatus,
  walletWithdrawalCancelStatus,
  // users(account) profile
  profileStatus,
  // action cable
  currentCable,
  // ws connecting status
  wsConnectingState,
  // points
  pointsStatus,
  pointsRewardStatus,
  pointsConsumeStatus,
  //loadings status
  loadingsStatus,
  //licence number envents
  licenceNumberEventsStatus,

  // --------------------- v2 ---------------------------
  loadingStatus,
  // global
  globalStaticStatus,
  // market
  marketListStatus,
  marketShowStatus,
  marketStatisticsStatus,
  marketBalanceStatus,
  // offer
  offerListStatus,
  offerCreateStatus,
  offerUpdateStatus,
  offerDeleteStatus,
  offerOrderStatus,
  offersApplyStatus,
  offersApplyConfirmStatus,
  // bid
  bidListStatus,
  bidCreateStatus,
  bidUpdateStatus,
  bidDeleteStatus,
  bidOrderStatus,
  // quota transfer apply
  transferApplyCreateStatus,
  // order
  orderListStatusV2,
  orderShowStatusV2,
  orderPaymentStatusV2,
  orderPurchasedStatus,
  orderSoldStatus,
  ordersBuyerPayStatus,
  orderPaymentProofsListStatus,
  ordersPaymentProofsNewStatus,
  ordersPaymentProofsDeleteStatus,
  ordersReceiveFundStatus,
  ordersQuotaTransfersListStatus,
  ordersQuotaTransfersNewStatus,

  // invoice
  invoiceShowStatusV2,
  invoiceCreateStatusV2,
  // wallet
  walletBalanceStatusV2,
  walletEntriesStatus,
  walletTopupStatusV2,
  // withdraw (wallet)
  withdrawListStatus,
  withdrawShowStatus,
  withdrawCreateStatus,
  // quota
  quotaListStatus,
  // permanent
  permanentAcceptBidStatus,
  permanentBidListStatus,
  permanentBidStatus,
  permanentListStatus,
  permanentNewStatus,
  permanentPurchaseStatus,
  permanentShowStatus,
  permanentDeleteStatus,
  permanentMarketsStatus,
  permanentMarketGroupsStatus,
  permanentStatisticStatus,
  permanentEnquireStatus,

  // quota attr
  quotaAttrStatus: combineReducers({
    categoriesListStatus,
    countriesListStatus,
    statesListStatus,
  }),
  // user
  userProfileStatus,
  // account
  acccountBidsStatus,
  acccountOffersStatus,
  acccountRecendStatus,
  acccountOpenOrdersStatus,
  accountProfileUpdateStatus,
  accountPermanentListStatus,
  accountPermanentShowStatus,
  accountPermanentDeleteStatus,
  accountPermanentUpdateStatus,
  accountBanksShowStatus,
  accountBankUpdateStatus,
  accountStatementListStatus,
  accountQuotumBalancesStatus,
  accountQuotumInventoriesStatus,
  accountCreatedAuctionsStatus,
  accountInvolvedAuctionsStatus,
  accountProfileRegisterStatus,
  accountNotificationListStatus,

  // popular lists
  popularListStatus,

  // fishery
  fisheryShowStatus,
  fisheryQuotaMarketsStatus,
  fisheryActivityStatus,
  fisheriesListStatus,
  fisheryStickListStatus,
  fisheryExistenceStatus,
  fisheryFollowStatus,
  fisheryFollowListStatus,
  fisheryFollowingQuotaMarketsStatus,
  fisheryQuotaListStatus,
  fisheryFollowingAuctionsStatus,

  // licence
  licenceUpdateStatus,

  // auction new edition
  auctionListStatus,
  auctionShowStatus,
  auctionBidCreateStatus,
  auctionBidListStatus,
  auctionBidAllListStatus,
  auctionNewStatus,
  auctionFinalBidShowStatus,
  auctionFinalBidCreateStatus,
  auctionInstantBuyStatus,
  auctionFinalChancesListStatus,
  auctionSimilarListStatus,
  
  // listing
  listingOfferNewStatus,
  listingQuotaTransfersNewStatus,
  listingQuotaTransfersListStatus,
  listingOfferEnquiryStatus,

  p2pNewStatus,
});

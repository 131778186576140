import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { ListingApiUsecase } from "@core";
import { LISTING_OFFER_ENQUIRY_REQUEST, listingOfferEnquiryAction } from "./action";

export function* watchListingOfferEnquiry() {
  yield takeLatest(LISTING_OFFER_ENQUIRY_REQUEST, listingOfferEnquiry);
}

function* listingOfferEnquiry(action) {
  try {
    const usecase = new ListingApiUsecase();
    const res = yield call([usecase, "enquiry"], action.payload);
    const data = get(res, "data", {});
    yield put(listingOfferEnquiryAction.success(data));
  } catch (err) {
    yield put(listingOfferEnquiryAction.failure(errorString(err)));
  }
}

import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { LoadingWrapper, Empty } from "@components";

const Table = ({ data, loading, emptyTitle, emptyContent }) => {
  const history = useHistory();
  return (
    <LoadingWrapper loading={loading}>
      <Empty data={data} title={emptyTitle} content={emptyContent}>
        <div className="overflow-hidden">
          <table className="mb-2 lg:mb-0 min-w-full lg:divide-y divide-blue-gray-200">
            <thead className="hidden lg:table-header-group bg-gray-50">
              <tr>
                <th className="pl-4 lg:pl-6 pr-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Market
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Total Quota Consigned
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Available To List
                </th>
                <th className="pr-4 lg:pr-8 pl-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Listed On Market
                </th>
              </tr>
            </thead>
            <tbody className="bg-white lg:divide-y divide-blue-gray-200" x-max="1">
              {map(data, (item, index) => (
                <tr
                  className={cx(
                    "hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer",
                    { "lg:bg-gray-50": index % 2 != 0 }
                  )}
                  key={index}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    history && history.push(get(item, "url", "/"));
                  }}
                >
                  <td className="px-0 lg:pl-4 lg:pr-2 py-0 lg:py-5 lg:max-w-xxs">
                    <div
                      className={cx(
                        "border lg:border-none border-blue-gray-200 rounded-lg overflow-hidden mx-2",
                        {
                          "mt-2 lg:mt-0": index > 0,
                        }
                      )}
                    >
                      <div className="px-4 lg:px-0 py-4 lg:py-0">
                        <div className="text-sm font-medium leading-5 mb-1 lg:mb-0">
                          {get(item, "quotaMarketTitle", "")}
                        </div>
                        <div className="text-xxs leading-5 font-medium text-blue-gray-500 lg:hidden">
                          <span className="lg:hidden">Total consigned : </span>
                          {get(item, "str.total", "")}
                        </div>
                      </div>
                      <div className="lg:hidden bg-gray-50 flex items-center justify-between py-3 px-4">
                        <div className="text-xxs leading-5 text-blue-gray-400">
                          LISTED {get(item, "str.pending", "")}
                        </div>
                        <span className="text-xxs text-blue-gray-600">
                          AVAILABLE {get(item, "str.available", "")}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="hidden lg:table-cell px-2 py-4 lg:whitespace-no-wrap text-right">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "str.total", "")}
                    </div>
                  </td>
                  <td className="hidden lg:table-cell px-2 py-4 lg:whitespace-no-wrap text-right">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "str.available", "")}
                    </div>
                  </td>
                  <td className="hidden lg:table-cell pl-2 pr-4 py-4 lg:whitespace-no-wrap">
                    <div className="flex items-center justify-end space-x-2">
                      <div className="text-sm leading-5 text-blue-gray-600">
                        {get(item, "str.pending", "")}
                      </div>
                      <svg
                        className="w-3 h-3"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.17201 5.00008L0.343018 2.17208L1.75701 0.75708L6 5.00008L1.75701 9.24308L0.343018 7.82808L3.17201 5.00008Z"
                          fill="#E0E0E0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Empty>
    </LoadingWrapper>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyContent: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  loading: false,
  emptyTitle: "",
  emptyContent: "",
};

export default Table;

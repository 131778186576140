import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";

import {
  ORDERS_PAYMENT_POST,
  ORDERS_PAYMENT_LOADING,
  ORDERS_PAYMENT_SUCCESS,
  ORDERS_PAYMENT_FAILED
} from "./paymentAction";

import { ordersPaymentAPI } from "@config/api";

//post order payment
export function* watchOrderPaymentPost() {
  yield takeLatest(ORDERS_PAYMENT_POST, orderPaymentPost);
}

export function* orderPaymentPost(action) {
  yield put({ type: ORDERS_PAYMENT_LOADING });
  const { err, res } = yield call(
    orderPaymentPostApi,
    action.orderId,
    action.payload
  );

  if (!res) {
    yield put({
      type: ORDERS_PAYMENT_FAILED,
      error: errorString(get(err, "response.data.errors"), "Post order payment failed.")
      // error: "Post order payment failed."
    });
    return;
  }
  yield put({ type: ORDERS_PAYMENT_SUCCESS, data: res });
}

export function orderPaymentPostApi(orderId, payload) {
  return axios
    .post(ordersPaymentAPI(orderId, payload.kind))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import { takeLatest, call, put } from "redux-saga/effects";
import { stopSubmit } from 'redux-form';
import get from "lodash/get";

import errorString from "@utils/errorString";
import { PermanentApiUsecase } from "@core";
import { PERMANENT_BID_REQUEST, permanentBidAction } from "./action";

export function* watchPermanentBidFetch() {
  yield takeLatest(PERMANENT_BID_REQUEST, permanentBidFetch);
}

function* permanentBidFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "bid"], action.payload);
    yield put(permanentBidAction.success(res.data));
  } catch (err) {
    const errors = get(err, "response.data.errors", {});
    yield put(permanentBidAction.failure(errorString(errors)));
    yield put(stopSubmit("PermanentBidForm", errors))
  }
}

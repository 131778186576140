/**
 * errorString 将errors对象格式化为字符串
 *
 * @param  {Object}   errors            报错信息
 * @param {String}    defaultErr        默认报错信息
 * @return {String}                     返回报错字符串
 * @example
 *
 * errorString()
 */

import values from "lodash/values";
import upperFirst from "lodash/upperFirst";
import trim from "lodash/trim";

export default function errorString(errors, defaultErr = "") {
  let str = "";
  function mapError(arr) {
    if (typeof arr === "string") {
      str = str + " " + arr;
    } else if (arr instanceof Array) {
      arr.map((item, i) => {
        mapError(item);
      });
    } else if (arr instanceof Object) {
      values(arr).map(sub => {
        mapError(sub);
      });
    } else {
      return defaultErr;
    }
  }
  mapError(errors);
  return upperFirst(trim(str));
}

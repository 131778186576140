import React from "react";

/**
 * import lib tools
 */
import { reduxForm, Field } from "redux-form";

import { renderTextField, required } from "@components/reduxform";

const LicenceNumberForm = props => {
  const { handleSubmit } = props;
  return (
    <div className="account-form">
      <form onSubmit={handleSubmit}>
        <Field
          id="licence_number"
          name="licence_number"
          label="Licence number"
          placeholder="Enter licence number "
          type="text"
          validate={required}
          component={renderTextField}
          align="left"
        />
      </form>
    </div>
  );
};

const LicenceNumberFormWithReduxForm = reduxForm({
  form: "LicenceNumberForm"
})(LicenceNumberForm);

export default LicenceNumberFormWithReduxForm;

import { useState, useCallback, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import Notice from "@utils/noticeNew";
import {
  auctionInstantBuyAction,
  AUCTION_INSTANT_BUY,
} from "@redux/modules_v2/auctions/instantBuy/action";

/**
 * [hooks] place instant buy
 *
 */
export const useInstantBuy = ({ seller, success } = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [place, setPlace] = useState({});

  /**
   * Callback instant buy request
   */
  const submit = useCallback(() => {
    if (seller) {
      Notice.failure("The lister can not buy your own auction.");
      return;
    }
    if (!isEmpty(place)) {
      dispatch(auctionInstantBuyAction.request(place));
    }
  }, [place]);

  /**
   * 监听instant buy创建
   * auctionInstantBuyStatus
   */
  const auctionInstantBuyStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_INSTANT_BUY, state);
    return Object.assign({}, get(state, "auctionInstantBuyStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    if (!auctionInstantBuyStatus.loading) {
      if (auctionInstantBuyStatus.data) {
        Notice.success("You won this listing");
        typeof success === "function" && success(auctionInstantBuyStatus.data);
        const orderId = get(auctionInstantBuyStatus, "data.order_id", "");
        dispatch(auctionInstantBuyAction.reset());
        history && history.push(`/orders/${orderId}`);
      }
      if (auctionInstantBuyStatus.error) {
        Notice.failure(auctionInstantBuyStatus.error);
      }
    }
  }, [auctionInstantBuyStatus.loading]);

  useEffect(() => {
    return () => {
      dispatch(auctionInstantBuyAction.reset());
    };
  }, []);

  return {
    place,
    setPlace,
    submit,
    loading: auctionInstantBuyStatus.loading,
  };
};

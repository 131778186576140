import { P2PApiRepo } from "@core";

export class P2PApiUsecase {
  constructor() {
    this.repo = new P2PApiRepo();
  }
  
  new(payload) {
    return this.repo.new(payload);
  }
}
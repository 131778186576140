export const ACCOUNT_BANKS_SHOW = "account/banks_show";
export const ACCOUNT_BANKS_SHOW_REQUEST = `${ACCOUNT_BANKS_SHOW}_request`;
export const ACCOUNT_BANKS_SHOW_SUCCESS = `${ACCOUNT_BANKS_SHOW}_success`;
export const ACCOUNT_BANKS_SHOW_FAILED = `${ACCOUNT_BANKS_SHOW}_failure`;

export const accountBankShowAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_BANKS_SHOW_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: ACCOUNT_BANKS_SHOW_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: ACCOUNT_BANKS_SHOW_FAILED,
      error,
    };
  },
};

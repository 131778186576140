import numeral from "@utils/numeral";
import get from "lodash/get";
import isArray from "lodash/isArray";
import max from "lodash/max";
import isEmpty from "lodash/isEmpty";
import sum from "lodash/sum";
import round from "lodash/round";
import includes from "lodash/includes";
import pluralize from "pluralize";

import {
  AuctionStatusEntity,
  AuctionShowEntity,
  UserEntity,
  AuctionBidMessage,
  PriceHidden,
  UnitBaseEntity,
  CalculateAmount,
  BidderStatus,
} from "@core";

/**
 * Auction show usecase
 * @param {Object} props auctionShowStatus
 * @param {Object} user currentUserStatus
 * @returns {Object}
 * {
 *  id: 1,
 *  title: 10,400.00kg (100.00 Units) TAS Rock Lobster Lease,
 *  ...
 * }
 */
export class AuctionShowUsecase {
  constructor(props, user, finalBidPrice) {
    this.data = props;
    this.userData = user;
    this.finalBidPrice = finalBidPrice;
  }

  get show() {
    if (isArray(this.data) || isEmpty(this.data)) {
      return {};
    }
    const usecase = new AuctionShowEntity(this.data);
    const unitUsecase = new UnitBaseEntity(this.data);
    const amount = unitUsecase.amount;
    const status = this.status;
    const user = this.user;
    const price = this.price(usecase, amount.weightUnit);
    const endPriceInfo = this.endPriceInfo(usecase, amount.weightUnit);
    const totalPrice = this.totalPrice(price.value, amount.totalWeightVal);
    const fixedTotalPrice = this.totalPrice(
      usecase.fixedPrice,
      amount.totalWeightVal
    );
    return {
      // base info
      id: usecase.id,
      title: usecase.title
        ? usecase.title
        : `${amount.totalWeight} ${usecase.name} - ${
            usecase.fishingType
          } Quota ${usecase.isPermanent ? "Permanent" : "Lease"}`,
      fishingType: usecase.fishingType,
      fisheryId: usecase.fisheryId,
      description: usecase.description,
      season: get(usecase, "season.years", ""),
      showTermsAndConditions: usecase.showTermsAndConditions,
      additionalInformation: usecase.additionalInformation,
      quotumName: usecase.quotumName,
      isPermanent: usecase.marketType === "permanent",
      marketType: usecase.marketType,
      usecaseName: usecase.name,
      quotaMarketId: usecase.quotaMarketId,
      currentPriceStr: this.getPriceStr(
        usecase.currentPrice,
        amount.weightUnit
      ),
      endPriceStr: this.getPriceStr(
        usecase.endPrice,
        amount.weightUnit
      ),
      endPrice: usecase.endPrice,
      endPriceFormat: numeral(usecase.endPrice).format(),

      //Unit price
      price,

      // 最终显示price相关
      endPriceInfo,

      //Total price
      totalPrice,

      //Bidder
      bidder: {
        message: this.bidderMessage(this.bidderStatus),
        price: this.bidderPrice(usecase, amount.weightUnit, this.bidderStatus),
      },

      reservePrice: {
        label: usecase.reservePrice
          ? status.underReserve
            ? "Reserve Not Met"
            : "Reserve Met"
          : "No Reserve",
        value: usecase.reservePrice,
        str: usecase.reservePriceStr(amount.weightUnit),
      },

      fixedPrice: {
        value: usecase.fixedPrice,
        str: this.getPriceStr(usecase.fixedPrice, amount.weightUnit),
      },
      fixedPriceFormat:numeral(usecase.fixedPrice).format(),
      fixedTotalPrice,
      currentUserAuctionPrice: {
        value: usecase.currentUserAuctionPrice,
        str:  usecase.currentUserAuctionPriceStr(amount.weightUnit) 
      },
      
      //Use form bid form
      lowestAvailablePrice: this.lowestAvailablePrice(usecase),

      //Amount
      weightUnit: amount.weightUnit,
      unit: usecase.unit,
      unitName: usecase.unitName,
      totalWeight: amount.totalWeight,
      totalWeightVal: amount.totalWeightVal,
      totalUnits: amount.units,

      //Time
      createdAtStr: usecase.createdAtStr,
      expiredAt: usecase.expiredAt,
      expiredAtStr: usecase.expiredAtStr,
      finalPriceExpiredAt: usecase.finalPriceExpiredAt,

      //color
      color: this.color(this.bidderStatus),

      //roles
      seller: status.seller(user.currentUserId),
      highestBidder: this.status.highestBidder(this.user.currentUserId),

      //url
      url: usecase.url,
      finalBidUrl: `/auctions/${usecase.id}/final-bid`,
      orderUrl: usecase.orderId ? `/orders/${usecase.orderId}` : "",

      // bid
      bidCount: this.getBidCount(usecase.bidCount),
      bidCountStr: this.getBidCount(usecase.bidCount, true),
      bidStatus: usecase.bidStatus,
      lastBidPriceStr: usecase.lastBid.priceStr(amount.weightUnit),
      lastBidPrice: usecase.lastBid.priceStr(""),
      lastBidAliasName: get(usecase.lastBid, "name", ""),
      lastBidExpiredAt: get(usecase.lastBid, "createdAtStr", ""),
    };
  }

  get status() {
    if (isArray(this.data)) {
      return new AuctionStatusEntity({});
    }
    return new AuctionStatusEntity(this.data);
  }

  get user() {
    return new UserEntity(this.userData);
  }

  get priceHidden() {
    return this.user.login ? PriceHidden["login"] : PriceHidden["unlogin"];
  }

  get bidderStatus() {
    if (isArray(this.data) || isEmpty(this.data)) {
      return "";
    }
    const isWinner = this.status.isWinner;
    const isBidder = this.status.bidder;
    const finalBidEnd = this.status.finalBidEnd;
    const isFailedByReserveNotMet = this.status.isFailedByReserveNotMet;
    const isFinalChanceSet = this.status.isFinalChanceSet;
    const isHighestBidder = this.status.highestBidder(this.user.currentUserId);
    const isCollisionBidder = this.status.collisionBidder(
      this.user.currentUserId
    );
    const isCompleted = this.status.isCompleted;
    const isPending = this.status.isPending;
    const isSuccessByFixedPriceSold = this.status.isSuccessByFixedPriceSold;
    const isSuccessByFinalChanceAccepted =
      this.status.isSuccessByFinalChanceAccepted;
    const isSuccessByAuctionBid = this.status.isSuccessByAuctionBid;
    const isFailedByFinalChanceRejected =
      this.status.isFailedByFinalChanceRejected;
    if (isCompleted) {
      if (isWinner && isSuccessByFixedPriceSold) {
        if (isSuccessByFixedPriceSold) {
          return BidderStatus.won_by_fixed_price;
        }
      } else {
        if (isHighestBidder) {
          if (isSuccessByFinalChanceAccepted) {
            return BidderStatus.won_by_final_chance_accepted;
          }
          if (isSuccessByAuctionBid) {
            return BidderStatus.won_by_highest_bid;
          }
          if (isFailedByFinalChanceRejected) {
            return BidderStatus.failed_by_final_chance_rejected;
          }
          if (isFinalChanceSet) {
            return BidderStatus.pending_wait_for_final_chance_confirm;
          }
          if (finalBidEnd) {
            return BidderStatus.failed_by_final_chance_expired;
          }
          if (isFailedByReserveNotMet) {
            return BidderStatus.pending_wait_for_final_chance_submit;
          }
        }
        //if (isBidder) {// 不显示了
          //return BidderStatus.failed_by_low_bid;
        //}
      }
    }
    if (isPending) {
      if (isHighestBidder) {
        return BidderStatus.pending_highest_bidder;
      }
      if (isCollisionBidder) {
        return BidderStatus.pending_collision_bidder;
      }
      if (isBidder) {
        return BidderStatus.pending_low_bidder;
      }
    }
    return BidderStatus.not_join;
  }

  price(usecase = {}, weightUnit = "") {
    const isSuccessByFixedPriceSold = this.status.isSuccessByFixedPriceSold;
    const isSuccessByFinalChanceAccepted =
      this.status.isSuccessByFinalChanceAccepted;
    const isSuccessByAuctionBid = this.status.isSuccessByAuctionBid;
    const isFailedByFinalChanceRejected = this.status.isFailedByFinalChanceRejected;
    const isFailedByReserveNotMet = this.status.isFailedByReserveNotMet;
    const isFinalChanceSet = this.status.isFinalChanceSet;
    const isFailedByNoOneBid = this.status.isFailedByNoOneBid;
    const isPending = this.status.isPending;
    const bidded = this.status.bidded;

    let res = {
      label: "",
      value: "",
      str: "",
    };
    res.label = "Final Price";

    if (isPending) {
      if (bidded) {
        res.label = "Top bid";
        res.value = get(usecase, "lastBid.price", 0);
      } else {
        res.label = "Start price";
        res.value = get(usecase, "startPrice", 0);
      }
      if (usecase.reservePrice) {
        if (bidded && !this.status.underReserve) {
          res.status = "Reserve met";
        } else {
          res.status = "Reserve not met";
        }
      }
    } else if (isSuccessByFixedPriceSold) {
      res.value = usecase.fixedPrice;
      res.status = "Sold by Buy It Now";
    } else if (isSuccessByFinalChanceAccepted) {
      res.value = usecase.endPrice;
      res.status = "Final Offer Accepted";
    } else if (isSuccessByAuctionBid) {
      res.value = usecase.endPrice;
      res.status = "Auction Successful";
    } else if (isFailedByFinalChanceRejected || isFailedByReserveNotMet || isFinalChanceSet) {
      res.label = "Reserve price";
      res.value = get(usecase, "reservePrice", 0);
      res.status = "Reserve not met";
    } else if (isFailedByNoOneBid && usecase.reservePrice) {
      res.label = "Reserve price";
      res.value = get(usecase, "reservePrice", 0);
      res.status = "Reserve not met";
    } else if (isFailedByNoOneBid && !usecase.reservePrice) {
      res.label = "Final price";
      res.value = usecase.endPrice;
      res.status = "Not complete";
    }
    res.str = this.getPriceStr(res.value, weightUnit);

    return res;
  }

  endPriceInfo(usecase = {}, weightUnit = "") {
    const isSuccessByFixedPriceSold = this.status.isSuccessByFixedPriceSold;
    const isSuccessByFinalChanceAccepted = this.status.isSuccessByFinalChanceAccepted;
    const isFailedByFinalChanceRejected = this.status.isFailedByFinalChanceRejected;
    const isSuccessByAuctionBid = this.status.isSuccessByAuctionBid;
    const isFailedByReserveNotMet = this.status.isFailedByReserveNotMet;
    const isFinalChanceSet = this.status.isFinalChanceSet;
    const isFailedByNoOneBid = this.status.isFailedByNoOneBid;
    const isPending = this.status.isPending;
    const hasLastBid = this.status.hasLastBid;
    let res = {
      label: "",
      value: "",
      valueStr: "",
      status: "",
    };
    /** Buy it now used successful */
    if(isSuccessByFixedPriceSold) {
      res.label = "Final Price";
      res.value = usecase.fixedPrice;
      res.status = "Sold by Buy It Now";
    } 
    /** final offer successful */
    else if(isSuccessByFinalChanceAccepted){
      res.label = "Final Price";
      res.value = usecase.endPrice;
      res.status = "Final Offer Accepted";
    } 
    /** last bid > reserve_price || no reserve, successful */
    else if(isSuccessByAuctionBid && get(usecase, "lastBid.price", 0) > get(usecase, "reservePrice", 0) ){
      res.label = "Final Price";
      res.value = get(usecase, "lastBid.price", 0);
      res.status = "Auction Successful";
    } 
    /** final offer not successful, reserve not met,  || no final offer, reserve not met, || no bid, reserve not met, filed */
    else if((isFailedByFinalChanceRejected || !isFinalChanceSet || isFailedByNoOneBid) && get(usecase, "lastBid.price", 0) < get(usecase, "reservePrice", 0)){
      res.label = "Reserve Price";
      res.value = get(usecase, "reservePrice", 0);
      res.status = "Reserve not met";
    }
    /** no bid, no reserve, filed */
    else if(!hasLastBid && !get(usecase, "reservePrice", 0) && isPending){
      res.label = "Start Price";
      res.value = get(usecase, "startPrice", 0);
      res.status = "Not complete";
    }
    else if(isFailedByNoOneBid && !get(usecase, "reservePrice", 0)){
      res.label = "Final Price";
      res.value = usecase.endPrice;
      res.status = "Not complete";
    }
    res.valueStr = this.getPriceStr(res.value, weightUnit);

    return res;
  }

  bidderPrice(usecase = {}, weightUnit = "", bidderStatus) {
    let res = {
      label: "final price",
      value: "",
      str: "",
    };

    switch (bidderStatus) {
      case BidderStatus.won_by_fixed_price:
        res.value = usecase.fixedPrice;
        break;
      case BidderStatus.won_by_highest_bid:
        res.value = usecase.currentUserLastBid.price;
        break;
      case BidderStatus.won_by_final_chance_accepted:
        res.label = "final offer";
        res.value = this.finalBidPrice;
        break;
      case BidderStatus.not_join:
        res = {};
        break;
      default:
        res.label = "max bid";
        res.value = usecase.currentUserAuctionPrice;
    }

    if (
      includes(
        [
          BidderStatus.failed_by_final_chance_expired,
          BidderStatus.pending_wait_for_final_chance_submit,
        ],
        bidderStatus
      )
    ) {
      res.label = "final price";
      res.value = usecase.currentUserLastBid.price;
    }

    if (
      includes(
        [
          BidderStatus.failed_by_final_chance_rejected,
          BidderStatus.pending_wait_for_final_chance_confirm,
        ],
        bidderStatus
      )
    ) {
      res.label = "final offer";
      res.value = this.finalBidPrice;
    }

    res.str = this.getPriceStr(res.value, weightUnit);

    return res;
  }

  lowestAvailablePrice(usecase) {
    const currentPrice = max([usecase.lastBid.price, usecase.startPrice]);
    return round(sum([currentPrice + usecase.stepPrice]), 2);
  }

  totalPrice(price, quantity) {
    if (!price) {
      return "";
    }
    const total = numeral(price).multiply(quantity).value();
    return CalculateAmount.getPurchasePayment(total);
  }

  bidderMessage(bidderStatus) {
    switch (bidderStatus) {
      case BidderStatus.won_by_fixed_price:
        return AuctionBidMessage.won_by_fixed_price;
      case BidderStatus.won_by_final_chance_accepted:
        return AuctionBidMessage.won_by_final_chance_accepted;
      case BidderStatus.won_by_highest_bid:
        return AuctionBidMessage.won_by_highest_bid;
      case BidderStatus.failed_by_final_chance_rejected:
        return AuctionBidMessage.failed_by_final_chance_rejected;
      case BidderStatus.failed_by_final_chance_expired:
        return AuctionBidMessage.failed_by_final_chance_expired;
      case BidderStatus.failed_by_low_bid:
        return AuctionBidMessage.failed_by_low_bid;
      case BidderStatus.pending_wait_for_final_chance_submit:
        return AuctionBidMessage.pending_wait_for_final_chance_submit;
      case BidderStatus.pending_wait_for_final_chance_confirm:
        return AuctionBidMessage.pending_wait_for_final_chance_confirm;
      case BidderStatus.pending_highest_bidder:
        return AuctionBidMessage.pending_highest_bidder;
      case BidderStatus.pending_collision_bidder:
        return AuctionBidMessage.pending_collision_bidder;
      case BidderStatus.pending_low_bidder:
        return AuctionBidMessage.pending_low_bidder;
      case BidderStatus.not_join:
        return "";
      default:
        return "";
    }
  }

  color(bidderStatus) {
    let color = {};
    if (
      includes(
        [
          BidderStatus.won_by_fixed_price,
          BidderStatus.won_by_final_chance_accepted,
          BidderStatus.won_by_highest_bid,
        ],
        bidderStatus
      )
    ) {
      color = {
        font: "text-white",
        label: "text-fish-primary-100",
        bar: "text-fish-primary-100",
        bg: "bg-fish-primary",
        text: "text-fish-primary",
      };
    } else if (
      includes(
        [
          BidderStatus.failed_by_final_chance_rejected,
          BidderStatus.failed_by_final_chance_expired,
          BidderStatus.failed_by_low_bid,
          BidderStatus.pending_collision_bidder,
          BidderStatus.pending_low_bidder,
        ],
        bidderStatus
      )
    ) {
      color = {
        font: "text-white",
        label: "text-red-200",
        bar: "text-red-400",
        bg: "bg-red-500",
        text: "text-red-500",
      };
    } else if (bidderStatus === BidderStatus.pending_highest_bidder) {
      color = {
        font: "text-white",
        label: "text-green-200",
        bar: "text-green-300",
        bg: "bg-fish-green-300",
        text: "text-green-500",
      };
    } else if (
      includes(
        [
          BidderStatus.pending_wait_for_final_chance_submit,
          BidderStatus.pending_wait_for_final_chance_confirm,
        ],
        bidderStatus
      )
    ) {
      color = {
        font: "text-white",
        label: "text-yellow-200",
        bar: "text-yellow-300",
        bg: "bg-fish-yellow",
        text: "text-fish-yellow",
      };
    }
    return color;
  }

  getPriceStr(value, weightUnit) {
    return value
      ? `${numeral(value).format()}${weightUnit ? `/${weightUnit}` : ""}`
      : "";
  }

  getBidCount(bidCount, isStr) {
    const bidded = this.status.bidded;
    if (!bidded) {
      return "";
    }
    if (!isStr) {
      return bidCount;
    }
    return `${bidCount} ${pluralize("Bid", bidCount)}`;
  }
}

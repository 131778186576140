import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

//Hooks
import { useMarketShow, useFisheryShow, useCurrentUser } from "@hooks";

//Components
import Layout from "@views/layout";
import { SEO, Breadcrumb } from "@components";
import QuotaMarket from "./single";
import Header from "@views/fisheries/show/_header";
//Context
import { CurrentUserContext } from "@contexts";

const QuotaMarkets = (props) => {
  //Lease id
  const id = get(props, "match.params.id");
  //Lease Market Show
  const { usecase: usecaseMarket, loading: loadingMarket } = useMarketShow(id);
  //Set fishery id
  const fisheryId = get(usecaseMarket, "entity.fishery.id", "");
  //Fishery Show Data
  const { data: fisheryShow, loading: fisheryLoading } =
    useFisheryShow(fisheryId);
  const logoImage = get(fisheryShow, "logoImage", "");

  //Current user status
  const currentUser = useCurrentUser();

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <Layout>
        <SEO
          title={`${get(fisheryShow, "stateAbbr", "")} ${get(
            fisheryShow,
            "category",
            ""
          )} Quota Marketplace - BeachPrice`}
        />
        <div className="gs-page pt-0 bg-gray-50 page-pb md:pb-24">
          <div className="container px-2 lg:px-0">
            <Breadcrumb className="mt-6 md:mt-8 px-2 md:px-0" back />
            {!isEmpty(fisheryShow.data) && <Header data={fisheryShow} />}
            <QuotaMarket
              id={id}
              data={{ market: usecaseMarket.single }}
              image={logoImage}
              fisheryId={fisheryId}
              loading={loadingMarket || fisheryLoading}
            />
          </div>
        </div>
      </Layout>
    </CurrentUserContext.Provider>
  );
};

export default QuotaMarkets;

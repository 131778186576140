import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { Loading } from "@components";

import QuotaMarket from "../show/single";

const QuotaMarkets = ({ data, loading, image, fisheryId }) => {
  if (loading) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return null;
  }

  return map(data, (item, index) => {
    return (
      <QuotaMarket
        id={item.id}
        data={item}
        image={image}
        fisheryId={fisheryId}
        key={index}
      />
    );
  });
};

export default QuotaMarkets;

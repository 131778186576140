import { takeLatest, call, put } from "redux-saga/effects";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_BID_LIST_REQUEST, auctionBidListAction } from "./action";

//Fetch auction bid list
export function* watchAuctionBidListFetch() {
  yield takeLatest(AUCTION_BID_LIST_REQUEST, auctionBidListFetch);
}

function* auctionBidListFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "bids"], action.payload);
    yield put(auctionBidListAction.success(res.data));
  } catch (err) {
    yield put(auctionBidListAction.failure(err));
  }
}
 
import React from "react";
import classnames from "classnames";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Moment } from "@core";

const SectionProcess = ({ paidState, stateStep }) => {
  const getTime = (paidState, stateStep) => {
    const time = get(stateStep, `${paidState}.time`, "");
    return time ? Moment.getFormated(time) : "";
  };

  const currentList = [
    {
      state: "pending",
      label: "Order created",
      subLabel: getTime("pending", stateStep),
    },
    {
      state: "buyer_paid",
      label: "Buyer paid",
      subLabel: getTime("buyer_paid", stateStep),
    },
    {
      state: "fund_received",
      label: "Funds received",
      subLabel: getTime("fund_received", stateStep),
    },
    {
      state: "completed",
      label: "Quota transferred",
      subLabel: getTime("completed", stateStep),
    },
  ];
  const current = findIndex(currentList, (o) => o.state === paidState);

  return (
    <div className="border-blue-gray-200 border block rounded-lg bg-white">
      <div className="pt-5 px-4 md:px-8 text-lg font-bold leading-tight">
        Current Status
      </div>
      <div className="py-8 px-6 md:px-8">
        {currentList.map((item, index) => {
          const isLast = index === currentList.length - 1;
          const isCirclePast = index <= current;
          const isLinePast = index <= current - 1;
          const circleClass = classnames("rounded-full w-3 h-3 mr-6", {
            "bg-fish-primary": isCirclePast,
            "bg-gray-400": !isCirclePast,
          });
          const lineClass = classnames("flex-1", {
            "bg-fish-primary": isLinePast,
            "bg-gray-300": !isLinePast,
          });
          const labelClass = classnames("font-semibold", {
            "text-blue-gray-500": !isCirclePast,
            "text-blue-gray-800": isCirclePast,
          });

          return (
            <div className="flex" key={index}>
              <div className="flex flex-col">
                <div className={circleClass}></div>
                {!isLast ? (
                  <div
                    className={lineClass}
                    style={{ width: 2, marginLeft: 5 }}
                  ></div>
                ) : null}
              </div>
              <div
                className="flex-1 pb-6"
                style={{
                  marginTop: -6,
                  minHeight: isLast ? 0 : 50,
                  paddingBottom: isLast ? 0 : null,
                }}
              >
                <div className={labelClass}>{item.label}</div>
                {item.subLabel && (
                  <div className="text-blue-gray-500 text-xxs">
                    {item.subLabel}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

SectionProcess.propTypes = {
  paidState: PropTypes.string,
  stateStep: PropTypes.object,
};

export default SectionProcess;

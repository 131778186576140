import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

import checkUserProfile from "@utils/checkUserProfile";
import checkAuth from "@utils/checkAuth";

/**
 * Home
 */
import Home from "@views/home";

/**
 * Auth
 */
import LoginV2 from "@views/auth/login";
import RegisterV2 from "@views/auth/register";
import RegisterActiveV2 from "@views/auth/register_active";
import PasswordResetV2 from "@views/auth/password_forgot";

/**
 * Account
 */
import DashboardV2 from "@views/account/dashboard";
import InvoiceV2 from "@views/account/invoice";
import InvoiceFeeV2 from "@views/account/invoice_fee";
import WalletV2 from "@views/account/wallet";
import TopupV2 from "@views/account/topup";
import WithdrawalV2 from "@views/account/withdrawal";
import SettingV2 from "@views/account/setting";
import ProfileV2 from "@views/account/setting/profile/simple";
import EmailVerify from "@views/account/setting/email/verify";
import LicenceNumberV2 from "@views/account/licence_number";
import Statements from "@views/account/statements";
import Listings from "@views/account/listings";
import QuotumBalanceShow from "@views/account/quotum_balances/show";
import Bids from "@views/account/bids";
import PastAuction from "@views/account/past_auction"

/**
 * Fisheries
 */
import FisheryList from "@views/fisheries/list";
import FisheryShow from "@views/fisheries/show";
import FisheryFollow from "@views/fisheries/follow";

/**
 * Listings
 */
import Sell from "@views/sell";
import Enquiry from "@views/enquiry";

/**
 * Market {Auction, Lease, Permanent}
 */
import {
  AuctionShow,
  AuctionFinalOffer,
  ExchangeShow,
  PermanentShow,
  PermanentEdit,
  PermanentEnquire,
  PermanentSell,
  PermanentList,
} from "@views/markets";

/**
 * Order
 */
import { OrderShow, OrderPayment, OrderList } from "@views/orders";

/**
 * Payment & Deposit
 */
import DepositV2 from "@views/deposit";
import PaymentSuccessfulV2 from "@views/payment/successful";
import PaymentFailedV2 from "@views/payment/failed";
import PaymentCancelledV2 from "@views/payment/cancelled";
import PaymentStripeV2 from "@views/payment/method/stripe";

/**
 * Public
 */
import NoMatch from "@views/public/no_match";
import NoAuth from "@views/public/no_auth";

/**
 * AppRouter
 */
export const AppRouter = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <Switch>
      <Route path="/" component={Home} exact />
      {/* auth start */}
      <Route path="/login" component={LoginV2} />
      <Route path="/register" component={RegisterV2} exact />
      <Route path="/register/active" component={RegisterActiveV2} exact />
      {/* auth end */}

      {/* account start */}
      <Route
        path="/dashboard"
        component={checkUserProfile(DashboardV2)}
        exact
      />
      <Route path="/dashboard/invoice/:id" component={InvoiceV2} />
      <Route path="/dashboard/fee_invoice/:id" component={InvoiceFeeV2} />
      <Route path="/account/topup" component={TopupV2} exact />
      <Route path="/account/withdrawal" component={WithdrawalV2} exact />
      <Route path="/account/password/forgot" component={PasswordResetV2} />
      <Route path="/account/licence/edit" component={LicenceNumberV2} exact />
      <Route
        path="/account/quotum_balances/:id"
        component={QuotumBalanceShow}
      />
      <Route path="/account/profile" component={ProfileV2} exact />
      <Route path="/wallet" component={WalletV2} exact />
      <Route path="/setting" component={SettingV2} exact />
      <Route path="/account/email/verify" component={EmailVerify} />
      <Route path="/statements" component={Statements} exact />
      <Route path="/listings" component={Listings} exact />
      <Route path="/bids" component={Bids} exact />
      <Route path="/auction/past" component={PastAuction} exact />
      {/* account end */}

      {/* order start */}
      <Route path="/orders" component={OrderList} exact />
      <Route path="/orders/:id" component={OrderShow} exact />
      <Route path="/orders/:id/payment" component={OrderPayment} exact />
      {/* order end */}

      {/* payment start */}
      <Route path="/payment/successful" component={PaymentSuccessfulV2} exact />
      <Route path="/payment/failed" component={PaymentFailedV2} exact />
      <Route path="/payment/cancelled" component={PaymentCancelledV2} exact />
      <Route
        path="/payments/stripes/:payment_id/complete"
        component={PaymentStripeV2}
        exact
      />
      {/* payment end */}

      {/* deposit start */}
      <Route path="/deposit" component={DepositV2} exact />
      {/* deposit end */}

      {/* fishery start */}
      <Route path="/fisheries" component={FisheryList} exact />
      <Route path="/fisheries/follow" component={FisheryFollow} exact />
      <Route path="/fisheries/:slug" component={FisheryShow} exact />
      {/* fishery end */}

      {/* auctions start */}
      <Route
        path="/auctions/:id/final-bid"
        component={checkAuth(AuctionFinalOffer)}
        exact
      />
      <Route
        path="/auctions/:id"
        component={checkUserProfile(AuctionShow)}
        exact
      />
      {/* auctions end */}

      {/* lease start */}
      <Route
        path="/leases/:id"
        component={checkUserProfile(ExchangeShow)}
        exact
      />
      {/* lease end */}

      {/* permanent start */}
      <Route
        path="/fisheries/:fishery_id/quotum/:quotum_id/permanent_listings"
        component={PermanentList}
        exact
      />
      <Route
        path="/permanents/new"
        component={checkAuth(PermanentSell)}
        exact
      />
      <Route
        path="/permanents/:id"
        component={checkAuth(PermanentShow)}
        exact
      />
      <Route
        path="/permanents/:id/enquire"
        component={checkAuth(PermanentEnquire)}
        exact
      />
      <Route
        path="/permanents/:id/edit"
        component={checkAuth(PermanentEdit)}
        exact
      />
      {/* permanent end */}

      {/* listings start */}
      <Route path="/sell" component={checkAuth(Sell)} />
      <Route path="/enquiry" component={checkAuth(Enquiry)} />
      {/* listings end */}

      {/* public start */}
      <Route path="/403" component={NoAuth} />
      <Route component={NoMatch} />
      {/* public end */}
    </Switch>
  );
};

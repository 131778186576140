import React from "react";

/**
 * import lib tools & components
 */
import PropTypes from "prop-types";
import { Button } from "@components";
/**
 * [Component] Button
 *
 * @refs https://stackoverflow.com/questions/61312762/purgecss-cant-recognize-conditional-classes
 *
 * @example
 */
const MyButton = ({ color, children, className, ...rest }) => {
  return (
    <Button color={color} className={className} {...rest}>
      {children}
    </Button>
  );
};

MyButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

MyButton.defaultProps = {
  color: "blue",
  className: "",
  size: "",
};

export default MyButton;

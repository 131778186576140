
export const ASKING_PRICES_ATTEND_FETCH_REQUEST = "asking_prices/fetch_attend_list_request";
export const ASKING_PRICES_ATTEND_FETCH_LOADING = "asking_prices/fetch_attend_list_loading";
export const ASKING_PRICES_ATTEND_FETCH_SUCCESS = "asking_prices/fetch_attend_list_success";
export const ASKING_PRICES_ATTEND_FETCH_FAILED = "asking_prices/fetch_attend_list_failed";


export function fetchAttendAskingPriceList(payload) {
  return {
    type: ASKING_PRICES_ATTEND_FETCH_REQUEST,
    payload
  };
}

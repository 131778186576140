
export const AUCTIONS_NEW_REQUEST = "auctions/new_request";
export const AUCTIONS_NEW_LOADING = "auctions/new_loading";
export const AUCTIONS_NEW_SUCCESS = "auctions/new_success";
export const AUCTIONS_NEW_FAILED = "auctions/new_failed";

export function newAuctionRequest(payload) {
  return {
    type: AUCTIONS_NEW_REQUEST,
    payload
  };
}

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  userProfileAction,
  USER_PROFILE,
} from "@redux/modules_v2/users/profile/action";

/**
 * [hooks] user profile
 * @returns { data: "a user profile data", loading: true|false }
 */
export const useUserProfile = () => {
  const dispatch = useDispatch();

  const fetchUserProfile = () => {
    dispatch(userProfileAction.request({}));
  };

  const userProfileStatus = useSelector(state => {
    const selector = new LoadingSelector(USER_PROFILE, state);
    return Object.assign({}, get(state, "userProfileStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = userProfileStatus.loading;
  const profile = userProfileStatus.data || {};

  /**
   * did mount
   */
  useEffect(() => {
    fetchUserProfile();
  }, []);

  return { data: profile, loading };
};

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import round from "lodash/round";

/**
 * import core
 */
import numeral from "@utils/numeral";

/**
 * import hooks
 */
import {
  useFetchAuctionShow,
  useFetchAuctionFinalBidShow,
  useAuctionCreateFinalBid,
} from "@hooks";

/**
 * import common components
 */
import Layout from "@views/layout";
import { Loading, SEO } from "@components";

/**
 * import local component
 */
import Title from "./_title";
import CardIntro from "./_card_intro";
import CardOption from "./_card_option";
import CardOffer from "./_card_offer";
import CardComplete from "./_card_complete";
import ExpiredAt from "./_expired_at";
import Form from "./_form";
const FinalPricePage = (props) => {
  //history
  const history = get(props, "history", {});
  //Auction id
  const id = get(props, "match.params.id");

  //Auction final bid show
  const {
    price: finalBidPrice,
    fetchShow: fetchFinalBid,
    loading: finalBidLoading,
  } = useFetchAuctionFinalBidShow(id);

  //Auction show
  const {
    usecase: auctionShow,
    fetchAuctionShow,
    loading: auctionShowLoading,
  } = useFetchAuctionShow(id, finalBidPrice);
  const highestBidder = get(auctionShow, "show.highestBidder", false);
  const isFinalChanceSet = get(auctionShow, "status.isFinalChanceSet", false);
  const finalBidEnd = get(auctionShow, "status.finalBidEnd", false);
  const currentUserAuctionPrice = get(auctionShow, "show.currentUserAuctionPrice.value", 0);
  const reservePrice = get(auctionShow, "show.reservePrice.value", 0);
  const averagePrice = round(
    numeral(currentUserAuctionPrice).add(reservePrice).divide(2).value(),
    2
  );
  //Auction final bid create
  const { handleSubmit, initialValues, loading, setInitalValues, price } =
    useAuctionCreateFinalBid({
      auctionId: id,
      price: averagePrice,
      success: () => {
        fetchFinalBid();
        fetchAuctionShow();
        setStep(3);
      },
    });

  //step
  const [step, setStep] = useState(1);
  const addStep = useCallback(() => {
    if (step > 2) {
      return;
    }
    setStep(step + 1);
  }, [step]);

  const decreaseStep = useCallback(() => {
    if (step < 2) {
      return;
    }
    setStep(step - 1);
  }, [step]);

  const renderCard = (step) => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6 md:space-y-8">
            <ExpiredAt data={auctionShow} />
            <CardIntro data={auctionShow} />
            <CardOption
              data={auctionShow}
              addStep={addStep}
              handleSubmit={handleSubmit}
              loading={loading}
            >
              <Form
                enableReinitialize={true}
                initialValues={initialValues}
                data={auctionShow}
                onSubmit={handleSubmit}
                price={price}
                loading={loading}
                setInitalValues={setInitalValues}
                addStep={addStep}
                destroyOnUnmount={false}
                forceUnregisterOnUnmount={true}
                averagePrice={averagePrice}
              />
            </CardOption>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6 md:space-y-8">
            <ExpiredAt data={auctionShow} />
            <CardOffer
              price={price}
              data={auctionShow}
              loading={loading}
              handleSubmit={handleSubmit}
              decreaseStep={decreaseStep}
            />
          </div>
        );
      case 3:
        return (
          <div className="space-y-6 md:space-y-8">
            <CardIntro data={auctionShow} />
            <CardComplete data={auctionShow} />
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!auctionShowLoading) {
      if (!isEmpty(get(auctionShow, "data", {}))) {
        if (!highestBidder) {
          history.push && history.push("/403");
        }
      }
    }
  }, [auctionShowLoading, highestBidder]);

  useEffect(() => {
    if (isFinalChanceSet || finalBidEnd) {
      setStep(3);
    }
  }, [isFinalChanceSet, finalBidEnd]);
  return (
    <Layout>
      <SEO title={`Final Offer - ${get(auctionShow, "show.title", "")}`} />
      <div className="gs-page pt-0 page-pb md:pb-32">
        {auctionShowLoading || finalBidLoading ? (
          <Loading />
        ) : (
          <div className="container max-w-xl">
            <Title step={step} data={auctionShow} />
            {renderCard(step)}
          </div>
        )}
      </div>
    </Layout>
  );
};

FinalPricePage.propTypes = {
  props: PropTypes.object,
};

export default FinalPricePage;

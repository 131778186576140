import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * [COMPONENT] Table
 * @refs https://tailwindui.com/components/application-ui/lists/tables
 */

const Table = ({ children, className, wrapperClassName }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2  sm:px-6 lg:px-8">
          <div className={cx("overflow-hidden", wrapperClassName)}>
            <table className={cx("min-w-full", className)}>{children}</table>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

Table.defaultProps = {
  className: "",
  wrapperClassName: "",
};

export default Table;

import { map, get, isArray, sortBy } from "lodash";
import { Moment } from "@core/index";
import numeral from "@utils/numeral";
import { StatementShowUsecase } from "./show";

export class StatementListUsecase {
  /**
   * 构造函数
   * @param {Object} props
   */
  constructor(props) {
    this.data = props;
  }

  get list() {
    if (!isArray(this.data)) {
      return [];
    }
    //按照过期时间来排序
    return this.sort(this.listUsecase);
  }

  get listUsecase() {
    return map(this.data, (data) => new StatementShowUsecase(data));
  }

  get total() {
    const self = this;
    const subtotal = this.getListByKey("subtotal");
    const subtotalGST = this.getListByKey("subtotalGST");
    const subtotalWithGST = this.getListByKey("subtotalWithGST");
    const serviceFee = this.getListByKey("serviceFee");
    const serviceFeeGST = this.getListByKey("serviceFeeGST");
    const serviceFeeWithGST = this.getListByKey("serviceFeeWithGST");
    const cardProcessingFee = this.getListByKey("cardProcessingFee");
    const cardProcessingFeeGST = this.getListByKey("cardProcessingFeeGST");
    const cardProcessingFeeWithGST = this.getListByKey(
      "cardProcessingFeeWithGST"
    );
    const totalGST = this.getListByKey("totalGST");
    const totalPrice = this.getListByKey("totalPrice");
    const paidAmount = this.getListByKey("paidAmount");
    return {
      subtotal: self.getTotal(subtotal),
      subtotalGST: self.getTotal(subtotalGST),
      subtotalWithGST: self.getTotal(subtotalWithGST),
      serviceFee: self.getTotal(serviceFee),
      serviceFeeGST: self.getTotal(serviceFeeGST),
      serviceFeeWithGST: self.getTotal(serviceFeeWithGST),
      cardProcessingFee: self.getTotal(cardProcessingFee),
      cardProcessingFeeGST: self.getTotal(cardProcessingFeeGST),
      cardProcessingFeeWithGST: self.getTotal(cardProcessingFeeWithGST),
      totalGST: self.getTotal(totalGST),
      totalPrice: self.getTotal(totalPrice),
      paidAmount: self.getTotal(paidAmount),
    };
  }

  sort(data) {
    //按照过期时间来排序
    return sortBy(data, (o) => {
      const momentUsecase = new Moment(get(o, "data.created_at", ""));
      return -numeral(`${momentUsecase.formatTimestamp}`).value();
    });
  }

  getListByKey(key) {
    return map(this.listUsecase, (item) => get(item, `show.${key}`, 0));
  }

  getTotal(data = []) {
    let res = numeral();
    map(data, (item) => res.add(item));
    return res.format();
  }
}

import React, { useState } from "react";
import PropTypes from "prop-types";

import get from "lodash/get";

/**
 * import hooks
 */
import { useInstantBuy } from "@hooks";

/**
 * import local component
 */
import { Button, Mobile, DesktopAndTablet } from "@components";

import InstantBuy from "../../instant_buy";

const FixPrice = ({ data }) => {
  const id = get(data, "show.id", "");
  const seller = get(data, "show.seller", false);
  const hasFixedPrice = get(data, "status.hasFixedPrice", false);
  const fixedPriceStr = get(data, "show.fixedPrice.str", "");
  const fixedTotalPrice = get(data, "show.fixedTotalPrice.str", {});
  const instantBuyProps = useInstantBuy({ seller });
  const [isInstantBuyOpen, toggleInstantBuyModal] = useState(false);

  const renderDesktop = () => {
    return (
      <>
        <div className="relative py-4">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-blue-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm leading-5">
            <span className="text-sm px-4 text-blue-gray-400 bg-white lg:bg-gray-50">Or</span>
          </div>
        </div>
        <div className="flex items-center justify-center mb-4 space-x-3 text-sm text-blue-gray-700">
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-blue-500"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29996 0.0460166C8.50296 0.109859 8.68031 0.236798 8.8062 0.408372C8.93209 0.579947 8.99997 0.787209 8.99996 1.00002V6.00002H13C13.1829 5.99994 13.3623 6.05003 13.5187 6.14484C13.6752 6.23965 13.8026 6.37556 13.8871 6.53776C13.9717 6.69996 14.0101 6.88225 13.9983 7.06478C13.9865 7.24732 13.9248 7.4231 13.82 7.57302L6.81996 17.573C6.69813 17.7476 6.52379 17.8787 6.3223 17.9473C6.12081 18.0159 5.90268 18.0184 5.69965 17.9545C5.49663 17.8906 5.31929 17.7635 5.19345 17.5919C5.06761 17.4202 4.99982 17.2129 4.99996 17V12H0.999957C0.817038 12.0001 0.637602 11.95 0.481174 11.8552C0.324747 11.7604 0.197322 11.6245 0.112766 11.4623C0.0282109 11.3001 -0.0102353 11.1178 0.00161155 10.9353C0.0134584 10.7527 0.0751445 10.5769 0.179957 10.427L7.17996 0.427017C7.30197 0.252803 7.47635 0.122029 7.67776 0.0536927C7.87917 -0.0146436 8.09713 -0.0169822 8.29996 0.0470166V0.0460166Z"
              fill="currentColor"
            />
          </svg>
          <span className="font-medium text-sm">{`Buy-it-now for ${fixedPriceStr}`}</span>
        </div>
        <Button
          size="xl"
          color="white"
          textColor="gray-900"
          className="bg-white border-blue-gray-300 hover:border-blue-gray-400 focus:outline-none focus:border-blue-gray-400"
          full
          outline
          onClick={() => {
            toggleInstantBuyModal(true);
            typeof instantBuyProps.setPlace === "function" &&
              instantBuyProps.setPlace({ id });
          }}
        >
          Buy-it-now
        </Button>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <Button
        size="xl"
        color="gray-300"
        textColor="gray-900"
        className="justify-center hover:border-blue-gray-400 focus:outline-none focus:border-blue-gray-400 whitespace-no-wrap px-0 text-sm"
        full
        outline
        onClick={() => {
          toggleInstantBuyModal(true);
          instantBuyProps.setPlace({ id });
        }}
      >
        {`Or Buy-it-now for ${fixedPriceStr}`}
      </Button>
    );
  };

  if (!hasFixedPrice || seller) {
    return null;
  }
  return (
    <div>
      <Mobile>{renderMobile()}</Mobile>
      <DesktopAndTablet>{renderDesktop()}</DesktopAndTablet>
      <InstantBuy
        isOpen={isInstantBuyOpen}
        toggleModal={toggleInstantBuyModal}
        title={get(data, "show.title", "")}
        price={fixedPriceStr}
        total={fixedTotalPrice}
        {...instantBuyProps}
      />
    </div>
  );
};

FixPrice.propTypes = {};

export default FixPrice;

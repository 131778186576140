import { takeEvery, takeLatest, call, put } from "redux-saga/effects";
import { updateCurrentUserAttributes } from "@utils/cognitoAuth";
import getUrlQuery from "@utils/getUrlQuery";

import {
  ACCOUNT_PROFILE_UPDATE_REQUEST,
  accountProfileUpdateAction,
} from "./action";
import {
  REFRESH_CURRENT_USER_SUCCESS,
  refreshCurrentUser,
} from "@redux/modules/auth/action";
import { AccountApiUsecase } from "@core/index";

/**
 * 监听 Account profile update request
 */
export function* watchAccountProfileUpdateRequest() {
  yield takeEvery(ACCOUNT_PROFILE_UPDATE_REQUEST, accountProfileUpdate);
}

/**
 * 执行动作 Account profile update
 * @param {Object} action 
 */
function* accountProfileUpdate(action) {
  try {
    yield call(updateCurrentUserAttributes, action.payload);
    yield put(refreshCurrentUser());
    yield takeLatest(REFRESH_CURRENT_USER_SUCCESS, accountProfileEventRequest);
  } catch (err) {
    yield put(accountProfileUpdateAction.failure(err));
  }
}

/**
 * 执行动作 account profile event request
 */
function* accountProfileEventRequest() {
  try {
    const newRegister = getUrlQuery("new_register");
    const usecase = new AccountApiUsecase();
    yield call([usecase, "profileEvent"], { newRegister });
    yield put(accountProfileUpdateAction.success("SUCCESS"));
  } catch (err) {
    yield put(accountProfileUpdateAction.failure(err));
  }
}

export const LISTING_QUOTA_TRANSFERS_LIST = "listing/quota_transfers_list";
export const LISTING_QUOTA_TRANSFERS_LIST_REQUEST = `${LISTING_QUOTA_TRANSFERS_LIST}_request`;
export const LISTING_QUOTA_TRANSFERS_LIST_SUCCESS = `${LISTING_QUOTA_TRANSFERS_LIST}_success`;
export const LISTING_QUOTA_TRANSFERS_LIST_FAILURE = `${LISTING_QUOTA_TRANSFERS_LIST}_failure`;


export const listingQuotaTransfersListAction = {
  request: (payload) => {
    return {
      type: LISTING_QUOTA_TRANSFERS_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: LISTING_QUOTA_TRANSFERS_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: LISTING_QUOTA_TRANSFERS_LIST_FAILURE,
      error,
    }
  },
};

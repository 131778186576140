import axios from "axios";
import {takeEvery, call, put} from 'redux-saga/effects';

import {
  CHANGE_EMAIL_EVENT_REQUEST,
  changeEmailEventLoading,
  changeEmailEventSuccess,
  changEmailEventFailed
} from "./action";

import {changeEmailEventAPI} from "@config/api";

function* watchChangeEmailEventRequest() {
  yield takeEvery(CHANGE_EMAIL_EVENT_REQUEST, changeEmailEventRequest)
}

function* changeEmailEventRequest(action) {
  yield put(changeEmailEventLoading())

  try {
    const params = {old_email: action.payload.oldEmail}
    const res = yield call(axios.post, changeEmailEventAPI(), params)
    yield put(changeEmailEventSuccess("SUCCESS"))
  } catch (err) {
    yield put(changEmailEventFailed(err))
  }
}

export { watchChangeEmailEventRequest }
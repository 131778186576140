/**
 * Component Checkbox Field
 *
 * @example
 *
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import cx from "classnames";

export default class CheckboxField extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, label, error, className } = this.props;
    const inputProps = omit(this.props, ["label", "error", "className"]);
    return (
      <div className="form-element mb-4">
        <div className={cx("relative flex items-start cursor-pointer", className)}>
          <div className="flex items-center h-6">
            <input
              type="checkbox"
              className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-blue-gray-200 rounded"
              {...inputProps}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={id}>{label}</label>
          </div>
        </div>
        {error && <div className="mt-2 error">{error}</div>}
      </div>
    );
  }
}

CheckboxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
};

CheckboxField.defaultProps = {
  label: "",
  className: "",
  value: false,
  onChange: () => {},
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

/**
 * import core
 */
import { AuctionBidMessage } from "@core";

/**
 * import lib tools
 */
import { reduxForm, Field } from "redux-form";

/**
 * import local component
 */
import { Button } from "@components";
import {
  renderTextField,
  formatFixedNumber,
  required,
  minValue,
} from "@components/reduxform";
import MaxBidNotice from "../detail/_max_bid_notice";

const BidForm = ({
  handleSubmit,
  loading,
  submitting,
  data,
  price: _price,
}) => {
  const minPrice = get(data, "show.lowestAvailablePrice", 0);
  const [validate, setValidate] = useState([]);
  const [openNotice, setOpenNotice] = useState(false);
  const isBidder = get(data, "status.bidder", false);
  const isSeller = get(data, "show.seller", false);
  useEffect(() => {
    minPrice &&
      setValidate([required, minValue(minPrice, "Place a bid equal or above")]);
  }, [minPrice]);
  const weightUnit = get(data, "show.weightUnit", "");
  const totalWeightVal = get(data, "show.totalWeightVal", 0);
  const getTotalPrice = get(data, "totalPrice", () => {});
  const totalPrice = getTotalPrice(_price, totalWeightVal);
  const totalWithGST = get(totalPrice, "str.totalWithGST", "");
  return (
    <form onSubmit={handleSubmit}>
      <MaxBidNotice open={openNotice} />
      <Field
        symbolClass="size-sm px-3"
        type="text"
        label=""
        name="price"
        placeholder="Enter bid"
        rightSymbol={`per ${weightUnit}`}
        symbol="$"
        validate={validate}
        component={renderTextField}
        fontSize={14}
        parse={(value) => formatFixedNumber(value)}
        onFocus={() => {
          setOpenNotice(true);
        }}
        onBlur={() => {
          setOpenNotice(false);
        }}
      />
      {!!totalWithGST && (
        <div className="text-sm text-center text-blue-gray-600">
          {`${totalWithGST} subtotal including GST`}
        </div>
      )}
      <div className="mt-4">
        <Button
          size="xl"
          color="gray-900"
          textColor="white"
          className="bg-gray-900"
          full
          type="submit"
          disabled={loading || submitting || isSeller}
          loading={loading || submitting}
        >
          {isBidder ? "Increase your max bid" : "Place Bid"}
        </Button>
        {isSeller && (
          <div className="text-sm text-blue-gray-600 mt-2">
            {AuctionBidMessage.seller}
          </div>
        )}
      </div>
    </form>
  );
};

BidForm.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  data: PropTypes.object,
  formError: PropTypes.string,
  price: PropTypes.string,
};

export default reduxForm({
  form: "AuctionBidForm",
})(BidForm);

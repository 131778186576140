import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import sortBy from 'lodash/sortBy';

import {
  AUCTIONS_FETCH_REQUEST,
  AUCTIONS_FETCH_LOADING,
  AUCTIONS_FETCH_SUCCESS,
  AUCTIONS_FETCH_FAILED,
  AUCTIONS_OWN_FETCH_REQUEST,
  AUCTIONS_OWN_FETCH_LOADING,
  AUCTIONS_OWN_FETCH_SUCCESS,
  AUCTIONS_OWN_FETCH_FAILED,
  AUCTIONS_INVOLVED_FETCH_REQUEST,
  AUCTIONS_INVOLVED_FETCH_LOADING,
  AUCTIONS_INVOLVED_FETCH_SUCCESS,
  AUCTIONS_INVOLVED_FETCH_FAILED
} from "./listAction";

import { auctionsAPI } from "@config/api";

//fetch auctions list
export function* watchAuctonsListFetch() {
  yield takeLatest(AUCTIONS_FETCH_REQUEST, auctionsListFetch);
}

export function* auctionsListFetch(action) {
  yield put({ type: AUCTIONS_FETCH_LOADING });
  const { err, res } = yield call(auctionsListFetchApi, action.payload);
  if (!res) {
    yield put({
      type: AUCTIONS_FETCH_FAILED,
      error: "Get auctions list failed."
    });
    return;
  }
  yield put({ type: AUCTIONS_FETCH_SUCCESS, data: res });
}

export function auctionsListFetchApi(payload) {
  return axios
    .get(auctionsAPI(), { params: payload })
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

//fetch auctions list of your own

export function* watchAuctonsOwnListFetch() {
  yield takeEvery(AUCTIONS_OWN_FETCH_REQUEST, auctionsOwnListFetch);
}

export function* auctionsOwnListFetch(action) {
  yield put({ type: AUCTIONS_OWN_FETCH_LOADING });
  const { err, res } = yield call(auctionsOwnListFetchApi, action.payload);
  if (!res) {
    yield put({
      type: AUCTIONS_OWN_FETCH_FAILED,
      error: "Get auctions list failed."
    });
    return;
  }
  yield put({ type: AUCTIONS_OWN_FETCH_SUCCESS, data: formatList(res, 'sell') });
}

export function auctionsOwnListFetchApi(payload) {
  return axios
    .get(auctionsAPI(payload.type))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

//fetch auctions list involved

export function* watchAuctonsInvolvedListFetch() {
  yield takeEvery(AUCTIONS_INVOLVED_FETCH_REQUEST, auctionsInvolvedListFetch);
}

export function* auctionsInvolvedListFetch(action) {
  yield put({ type: AUCTIONS_INVOLVED_FETCH_LOADING });
  const { err, res } = yield call(auctionsOwnListFetchApi, action.payload);
  if (!res) {
    yield put({
      type: AUCTIONS_INVOLVED_FETCH_FAILED,
      error: "Get auctions list failed."
    });
    return;
  }
  yield put({ type: AUCTIONS_INVOLVED_FETCH_SUCCESS, data: formatList(res, 'buy') });
}

const formatList = (list = [], order_type = "sell") => {
  sortBy(list, item => moment(item.expire_at).unix())
  return list.map(item =>
    Object.assign({}, item, {
      approved: !isEmpty(item.approved_at),
      isEnd: moment().isAfter(moment(item.expire_at)),
      order_type
    })
  );
};

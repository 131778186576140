const CONFIRM_REQUEST = 'forgot_password/confirm_request'
const CONFIRM_LOADING = 'forgot_password/confirm_loading'
const CONFIRM_SUCCESS = 'forgot_password/confirm_success'
const CONFIRM_FAILED = 'forgot_password/confirm_failed'

function confirmRequest(payload) {
  return {
    type: CONFIRM_REQUEST,
    payload
  }
}

function confirmLoading() {
  return {
    type: CONFIRM_LOADING
  }
}

function confirmSuccess(data) {
  return {
    type: CONFIRM_SUCCESS,
    data
  }
}

function confirmFailed(error) {
  return {
    type: CONFIRM_FAILED,
    error
  }
}

export {
  CONFIRM_FAILED,
  CONFIRM_SUCCESS,
  CONFIRM_LOADING,
  CONFIRM_REQUEST,

  confirmFailed,
  confirmLoading,
  confirmRequest,
  confirmSuccess
}
const VERIFY_EMAIL_CONFIRMATION_CODE_REQUEST = 'verify_email_confirmation_code/request'
const VERIFY_EMAIL_CONFIRMATION_CODE_LOADING = 'verify_email_confirmation_code/loading'
const VERIFY_EMAIL_CONFIRMATION_CODE_SUCCESS = 'verify_email_confirmation_code/success'
const VERIFY_EMAIL_CONFIRMATION_CODE_FAILED = 'verify_email_confirmation_code/failed'

function verifyEmailConfirmationCodeRequest(payload) {
  return {
    type: VERIFY_EMAIL_CONFIRMATION_CODE_REQUEST,
    payload
  }
}

function verifyEmailConfirmationCodeLoading() {
  return {
    type: VERIFY_EMAIL_CONFIRMATION_CODE_LOADING
  }
}

function verifyEmailConfirmationCodeSuccess(data) {
  return {
    type: VERIFY_EMAIL_CONFIRMATION_CODE_SUCCESS,
    data
  }
}

function verifyEmailConfirmationCodeFailed(error) {
  return {
    type: VERIFY_EMAIL_CONFIRMATION_CODE_FAILED,
    error
  }
}

export {
  VERIFY_EMAIL_CONFIRMATION_CODE_REQUEST,
  VERIFY_EMAIL_CONFIRMATION_CODE_FAILED,
  VERIFY_EMAIL_CONFIRMATION_CODE_LOADING,
  VERIFY_EMAIL_CONFIRMATION_CODE_SUCCESS,

  verifyEmailConfirmationCodeRequest,
  verifyEmailConfirmationCodeFailed,
  verifyEmailConfirmationCodeSuccess,
  verifyEmailConfirmationCodeLoading
}
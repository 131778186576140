import axios from "axios";
import { delay } from "redux-saga";
import { takeLatest, all, takeEvery, call, put } from "redux-saga/effects";

import jwtDecode from "jwt-decode";

import get from "lodash/get";
import {
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_LOADING,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED,
  AUTH_LOGOUT_RESET,
  USRS_PROFILE_REQUEST,
  USRS_PROFILE_LOADING,
  USRS_PROFILE_SUCCESS,
  USRS_PROFILE_FAILED,
  REFRESH_CURRENT_USER_REQUEST,
  REFRESH_CURRENT_USER_LOADING,
  REFRESH_CURRENT_USER_FAILED,
  REFRESH_CURRENT_USER_SUCCESS,
  setCurrentUser,
} from "./action";
import { removeACConsumer } from "../actioncable/action";
import { signOut, refreshSession } from "@utils/cognitoAuth";
import { refreshCognitoUserAPI } from "@config/api";

export function* watchLogout() {
  yield takeEvery(AUTH_LOGOUT_REQUEST, logout);
}

// watch logout
export function* logout(action) {
  yield put({ type: AUTH_LOGOUT_LOADING });
  const { res, err } = yield call(logoutApi, action.payload);
  if (err || !res) {
    yield put({
      type: AUTH_LOGOUT_FAILED,
      error: err,
    });
    return;
  }
  yield put({ type: AUTH_LOGOUT_SUCCESS, data: res });
  yield all([put(setCurrentUser({})), put(removeACConsumer())]);
}

function logoutApi(payload) {
  return signOut()
    .then((res) => ({
      res: "log out success",
    }))
    .catch((err) => ({
      err,
    }));
}

// watch refresh current user

export function* watchCurrentUserRefresh() {
  yield takeEvery(REFRESH_CURRENT_USER_REQUEST, currentUserRefresh);
}

function* currentUserRefresh(action) {
  yield put({ type: REFRESH_CURRENT_USER_LOADING });
  const { err, res } = yield call(currentUserRefreshApi);
  if (!res) {
    yield put({
      type: REFRESH_CURRENT_USER_FAILED,
      error: "Current user refresh failed.",
    });
    return;
  }
  let userData = get(res, "idToken.payload", {});
  userData.token = get(res, "idToken.jwtToken", "");
  userData.user_id = get(res, "idToken.sub", "");
  yield all([
    put({
      type: REFRESH_CURRENT_USER_SUCCESS,
      data: "Current user refresh success",
    }),
    put(setCurrentUser(userData)),
  ]);
}

function currentUserRefreshApi() {
  return refreshSession()
    .then((res) => {
      return refreshCognitoUserApi(res);
    })
    .catch((err) => ({ err }));
}

function refreshCognitoUserApi(res) {
  return axios
    .put(refreshCognitoUserAPI())
    .then(() => ({ res }))
    .catch((err) => ({ err }));
}


export const STATIC_DATA_FETCH_REQUEST = "static/data_fetch_request";
export const STATIC_DATA_FETCH_LOADING = "static/data_fetch_loading";
export const STATIC_DATA_FETCH_SUCCESS = "static/data_fetch_success";
export const STATIC_DATA_FETCH_FAILED = "static/data_fetch_failed";


export function fetchStaticData(payload) {
  return {
    type: STATIC_DATA_FETCH_REQUEST,
    payload
  };
}
import axios from "axios";
import keys from "lodash/keys";
import flatten from "lodash/flatten";
import get from "lodash/get";
import { links } from "@config/staticLink";

import { getHost } from "@config/api";
import getUrlQuery from "@utils/getUrlQuery";

export default function setDefaultContentType() {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const search = window.location.search;
      const pathName = window.location.pathname;
      const href = window.location.href;
      // const isAuctionShow = pathName.match(/^\/auctions\/\d+/);
      // const isLeaseShow = pathName.match(/^\/leases\/\d+/);
      // const isPermanentShow = pathName.match(/^\/permanents\/\d+/);
      const redirect = getUrlQuery("redirect_to");
      if (error.response.status === 401) {
        delete axios.defaults.headers.common["Authorization"];
        if (
          pathName === "/login" ||
          redirect
        ) {
          return;
        }
        setTimeout(function () {
          window.location.href = `${links.sign_in}?redirect_to=${href}`;
        }, 2 * 1000);
      }

      if (error.response.status === 404) {
        window.location.href = `${getHost()}/404`;
        return Promise.reject("Server busy please refresh page");
      }

      if (error.response.status === 412) {
        const errors = get(error, "response.data.errors", []);
        const errorsKey = flatten(
          errors.map((err) => {
            return keys(err);
          })
        );
        const key = "user_not_deposited";
        // 如果未deposit跳转到bp的deposit页面
        if (errorsKey.includes(key)) {
          setTimeout(function () {
            const redirect = getUrlQuery("redirect_to");
            if (!redirect) {
              window.location.href = `${getHost()}/deposit?redirect_to=${pathName}`;
            }
          }, 2 * 1000);
          return Promise.reject(errors);
        }
      }
      if (error.response.status === 422) {
        // logMessage("Response 422:", error);
        const errors = get(error, "response.data.errors") || "Server error";
        return Promise.reject(errors);
      }

      return Promise.reject("Server error");
    }
  );
}


export const ASKING_PRICES_FETCH_REQUEST = "asking_prices/fetch_list_request";
export const ASKING_PRICES_FETCH_LOADING = "asking_prices/fetch_list_loading";
export const ASKING_PRICES_FETCH_SUCCESS = "asking_prices/fetch_list_success";
export const ASKING_PRICES_FETCH_FAILED = "asking_prices/fetch_list_failed";
export const ASKING_PRICES_REFRESH = "asking_prices/refresh_list";


export const ASKING_PRICES_ANIMATE_START = "asking_prices/animate_start";
export const ASKING_PRICES_ANIMATE_END = "asking_prices/animate_end";



export function fetchAskingPriceList(payload, params = {}) {
  return {
    type: ASKING_PRICES_FETCH_REQUEST,
    payload,
    params,
  };
}

export function refreshAskingPriceList(data) {
  return {
    type: ASKING_PRICES_REFRESH,
    data,
  };
}

export const ACCOUNT_RECEND = "orders";
export const ACCOUNT_RECEND_REQUEST = `${ACCOUNT_RECEND}_request`;
export const ACCOUNT_RECEND_SUCCESS = `${ACCOUNT_RECEND}_success`;
export const ACCOUNT_RECEND_FAILURE = `${ACCOUNT_RECEND}_failure`;

export const accountRecendAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_RECEND_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ACCOUNT_RECEND_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ACCOUNT_RECEND_FAILURE,
      error,
    }
  },
};

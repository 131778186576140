import React from "react";
/**
 * import lib tools & components
 */
import get from "lodash/get";
import padStart from "lodash";

/**
 * import local tools & config & hooks
 */
import numeral from "@utils/numeral";
import { paymentMethodMap } from "@config/enum";
import { useWalletTopup, useUserProfile, useModal } from "@hooks";

/**
 * import local components
 */
import Layout from "@views/layout";
import TextField from "@components/reduxform/text";
import { Button, Image, Table } from "@components";
import TopupModal from "./_modal";
import { Discovery } from "aws-sdk";
/**
 * [Page] Account topup 充值页面
 */
const Topup = (props) => {
  const { history } = props;
  const {
    money,
    payMethod,
    processFee,
    moneyTotal,

    onMoneyChange,
    onMethodChange,
    submit: submitPay,
    loading: loadingTopup,
  } = useWalletTopup(() => {
    toggleModal(true);
  });

  const { data: profile } = useUserProfile();
  const { isOpen, toggleModal } = useModal();

  return (
    <Layout>
      <div className="flex-1 flex">
        <div className="flex-1 bg-white"></div>
        <div className="container md:flex">
          <div className="page-main-wrapper page-pb bg-white flex-1">
            <div className="pt-5-tw xl:pt-12">
              <div className="flex items-center mb-4-tw md:mb-12">
                <div
                  className="pr-5-tw cursor-pointer"
                  onClick={() => {
                    history && history.goBack();
                  }}
                >
                  <svg
                    className="-ml-1 mr-1 h-8 w-8 text-blue-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <h2>
                  Topup
                </h2>
              </div>
              <div className="text-xl font-bold leading-tight">
                How much would you like to topup?
              </div>
              <div className="mt-4-tw md:mt-10">
                <TextField
                  name="money"
                  label="Price"
                  type="number"
                  symbol="$"
                  wrapperClassName="mb-0"
                  value={money}
                  onChange={(evt) => {
                    const value = evt.target.value || "";
                    onMoneyChange(value);
                  }}
                />

                <div className="border-b mt-4-tw md:mt-10">
                  <Table className="tw-table">
                    <thead>
                      <tr>
                        <th className="">DESCRIPTION</th>
                        <th className="text-right">PRICE</th>
                      </tr>
                    </thead>
                    <tbody className="whitespace-no-wrap">
                      <tr>
                        <td>Topup</td>
                        <td className="text-right">
                          {numeral(money).format()}
                        </td>
                      </tr>
                      <tr>
                        <td>Card processing fee</td>
                        <td className="text-right">
                          {numeral(processFee).format()}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="w-1/2 ml-auto">
                  <div className="flex items-center justify-between pt-3-tw">
                    <div className="">Total</div>
                    <div className="font-bold text-right pr-3-tw text-xl">
                      {numeral(moneyTotal).format()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-sub-wrapper page-pb bg-gray-50">
            <div className="pt-5-tw md:pt-24">
              <div className="text-lg font-normal mb-6">
                Please choose your billing method
              </div>
              <label
                htmlFor="paymentRadioStripe"
                className="block cursor-pointer bg-white md:rounded-md p-5-tw md:px-8 md:py-6 mb-4-tw"
              >
                <div className="flex justify-between mb-2">
                  <div className="flex items-center">
                    <input
                      id="paymentRadioStripe"
                      type="radio"
                      className="flex-shrink-0 focus:ring-blue-500 h-4 w-4 text-fish-primary border-blue-gray-300"
                      value={paymentMethodMap.stripe}
                      onChange={(evt) => {
                        const value = evt.target.value || "";
                        onMethodChange(value);
                      }}
                      checked={payMethod === paymentMethodMap.stripe}
                    />
                    <div className="ml-3 block text-xl font-bold text-blue-gray-700">
                      Credit Card
                    </div>
                  </div>
                  <svg
                    width="130"
                    height="30"
                    viewBox="0 0 65 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="47.5" cy="7.5" r="7.5" fill="#D82E2B" />
                    <circle cx="56.6072" cy="7.5" r="7.5" fill="#EAA140" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M52.0537 13.5271C53.8449 12.1565 55.0002 9.99671 55.0002 7.56712C55.0002 5.13753 53.8449 2.97776 52.0537 1.60718C50.2626 2.97776 49.1073 5.13753 49.1073 7.56712C49.1073 9.99671 50.2626 12.1565 52.0537 13.5271Z"
                      fill="#EC692C"
                    />
                    <path
                      d="M23.2602 3.40849C22.5157 3.13418 21.7306 2.99383 20.9392 3.00021C18.3805 3.00021 16.5724 4.31757 16.5599 6.20269C16.5443 7.59022 17.8456 8.36852 18.8278 8.83422C19.8381 9.30949 20.176 9.60932 20.1728 10.0367C20.1666 10.6843 19.3658 10.9777 18.6244 10.9777C17.6016 10.9777 17.0416 10.831 16.1846 10.4674L15.8655 10.3174L15.5026 12.4896C16.1251 12.7544 17.245 12.9809 18.4023 13C21.1238 13 22.9036 11.6922 22.9224 9.68268C22.9443 8.57585 22.2436 7.73695 20.7609 7.04797C19.8631 6.59822 19.3032 6.29839 19.3032 5.84225C19.3032 5.44035 19.7818 5.00974 20.7828 5.00974C21.4491 4.99379 22.1091 5.12138 22.7222 5.37974L22.963 5.48819L23.3259 3.38936L23.2602 3.40849ZM29.9042 3.17564H27.9054C27.2829 3.17564 26.8168 3.34789 26.5447 3.97945L22.6972 12.8628H25.4186L25.9629 11.4083L29.2817 11.4115C29.363 11.7528 29.6008 12.8628 29.6008 12.8628H32L29.9042 3.17564ZM12.8813 3.0959H15.4714L13.851 12.7895H11.2579L12.8813 3.0959ZM6.29365 8.4355L6.56266 9.78157L9.09638 3.17564H11.8428L7.76383 12.8501H5.02366L2.78397 4.65568C2.74643 4.52171 2.66197 4.40369 2.54311 4.33351C1.7392 3.90609 0.885239 3.58393 0 3.37341L0.0344086 3.16926H4.21036C4.77654 3.19159 5.23011 3.37341 5.38651 3.98902L6.29677 8.43869V8.4355H6.29365ZM26.7042 9.42432L27.7365 6.71305C27.7239 6.74176 27.9492 6.15166 28.0805 5.78803L28.2588 6.62374L28.8563 9.42113L26.7042 9.42432Z"
                      fill="#2563EB"
                    />
                  </svg>
                </div>
                <div className="text-sm text-blue-gray-600 ml-8 flex items-center">
                  Processing fee of $0.30 + 1.75% plus GST
                </div>
              </label>
              <label
                htmlFor="paymentRadioOffline"
                className="block cursor-pointer bg-white md:rounded-md p-5-tw md:px-8 md:py-6 mb-8"
              >
                <div className="flex justify-between mb-2">
                  <div className="flex items-center">
                    <input
                      id="paymentRadioOffline"
                      type="radio"
                      className="flex-shrink-0 focus:ring-blue-500 h-4 w-4 text-fish-primary border-blue-gray-300"
                      value={paymentMethodMap.offline}
                      onChange={(evt) => {
                        const value = evt.target.value || "";
                        onMethodChange(value);
                      }}
                      checked={payMethod === paymentMethodMap.offline}
                    />
                    <div className="ml-3 block text-xl font-bold text-blue-gray-700">
                      Payment by EFT
                    </div>
                  </div>
                </div>
                <div className="text-sm text-blue-gray-600 ml-8">
                  No proceesing fee
                </div>
                {payMethod === paymentMethodMap.offline ? (
                  <div className="ml-8 mt-3-tw">
                    <div className="bg-orange-100 border-l border-r border-t border-b border-orange-300 rounded-lg-tw p-4-tw">
                      <div className="border-b-1 border-orange-200 pb-2 mb-2 text-center">
                        <div className="text-sm font-normal leading-tight">
                          Please Include the following reference number in your
                          bank payment
                        </div>
                        <div className="text-xl font-bold pt-3-tw">
                          {get(profile, "user.id")
                            ? `BP${padStart(get(profile, "user.id"), 4, "0")}`
                            : null}
                        </div>
                      </div>
                      <div>
                        <p className="text-sm ">Account Name:</p>
                        <div className="text-sm font-semibold leading-tight">
                          BeachPrice Clearing Account
                        </div>
                        <p className="text-sm pt-2">BSB: </p>
                        <div className="text-sm font-semibold leading-tight">
                          087 007
                        </div>
                        <p className="text-sm pt-2">ACC: </p>
                        <div className="text-sm font-semibold leading-tight">
                          90 626 2891
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </label>
              <div className="bg-white md:rounded-md p-5-tw md:px-8 md:py-6">
                <div className="flex mb-8">
                  <div className="flex-1 font-medium text-xl">Total</div>
                  <div className="text-right">
                    <div className="text-xl font-bold leading-tight md:text-3xl">
                      {numeral(moneyTotal).format()}
                    </div>
                    <div className="text-sm font-normal text-blue-gray-600">
                      Inc. GST
                    </div>
                  </div>
                </div>
                <Button
                  size="xl"
                  color="fish-primary"
                  textColor="white"
                  className="hover:bg-fish-primary-700"
                  full
                  onClick={() => {
                    submitPay();
                  }}
                  disabled={loadingTopup}
                  loading={loadingTopup}
                >
                  Continue with payment
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-gray-50"></div>
      </div>
      <TopupModal
        isOpen={isOpen}
        toggleStatus={toggleModal}
        onClosed={() => {
          history && history.push("/wallet");
        }}
        profile={profile}
      />
    </Layout>
  );
};

export default Topup;

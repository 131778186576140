
export const AUCTIONS_FETCH_REQUEST = "auctions/fetch_request";
export const AUCTIONS_FETCH_LOADING = "auctions/fetch_loading";
export const AUCTIONS_FETCH_SUCCESS = "auctions/fetch_success";
export const AUCTIONS_FETCH_FAILED = "auctions/fetch_failed";
export const AUCTIONS_FETCH_UPDATED = "auctions/fetch_update";

export function fetchAuctions(payload) {
  return {
    type: AUCTIONS_FETCH_REQUEST,
    payload
  };
}

/**
 * Auctions of own
 * @param {Object} payload
 * @returns {Object}
 */

export const AUCTIONS_OWN_FETCH_REQUEST = "auctions/own_fetch_request";
export const AUCTIONS_OWN_FETCH_LOADING = "auctions/own_fetch_loading";
export const AUCTIONS_OWN_FETCH_SUCCESS = "auctions/own_fetch_success";
export const AUCTIONS_OWN_FETCH_FAILED = "auctions/own_fetch_failed";

export function fetchAuctionsOwn(payload) {
  return {
    type: AUCTIONS_OWN_FETCH_REQUEST,
    payload
  };
}

/**
 * Auctions of involved
 * @param {Object} payload
 * @returns {Object}
 */

export const AUCTIONS_INVOLVED_FETCH_REQUEST = "auctions/involved_fetch_request";
export const AUCTIONS_INVOLVED_FETCH_LOADING = "auctions/involved_fetch_loading";
export const AUCTIONS_INVOLVED_FETCH_SUCCESS = "auctions/involved_fetch_success";
export const AUCTIONS_INVOLVED_FETCH_FAILED = "auctions/involved_fetch_failed";

export function fetchAuctionsInvolved(payload) {
  return {
    type: AUCTIONS_INVOLVED_FETCH_REQUEST,
    payload
  };
}
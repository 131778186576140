import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  ASKING_PRICES_CREATE_POST,
  ASKING_PRICES_CREATE_LOADING,
  ASKING_PRICES_CREATE_SUCCESS,
  ASKING_PRICES_CREATE_FAILED
} from "./createAction";

import {
  productNewAPI,
} from "@config/api";


//post asking price for create
export function* watchAskingPricePost() {
  yield takeLatest(ASKING_PRICES_CREATE_POST, askingPricePost);
}

export function* askingPricePost(action) {
  yield put({ type: ASKING_PRICES_CREATE_LOADING });
  const { err, res } = yield call(askingPricePostApi, action.id, action.payload);

  // yield call(delay, 5000);
  if (!res) {
    yield put({
      type: ASKING_PRICES_CREATE_FAILED,
      error: "Post asking price failed."
    });
    return;
  }
  yield put({ type: ASKING_PRICES_CREATE_SUCCESS, data: res });
}

export function askingPricePostApi(id, payload) {
  return axios
    .post(productNewAPI(id, 'sell'), { asking_price: payload} )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}
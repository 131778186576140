export const initialState = {
  activeTab: "tab1"
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return Object.assign({}, state, action.value);
    default:
      throw new Error();
  }
};

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Divider = ({ className }) => {
  return <div className={cx("w-full h-px bg-gray-200", className)}></div>;
};

Divider.propTypes = {};

export default Divider;

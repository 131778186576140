export const LICENCE_UPDATE = "licences/update"
export const LICENCE_UPDATE_REQUEST = `${LICENCE_UPDATE}_request`
export const LICENCE_UPDATE_SUCCESS = `${LICENCE_UPDATE}_success`
export const LICENCE_UPDATE_FAILURE = `${LICENCE_UPDATE}_failure`
export const LICENCE_UPDATE_RECEIVED = `${LICENCE_UPDATE}_received`

export const licenceUpdateAction = {
  request: (payload) => {
    return {
      type: LICENCE_UPDATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: LICENCE_UPDATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: LICENCE_UPDATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: LICENCE_UPDATE_RECEIVED
    }
  }
}
import React from "react";
import PropTypes from "prop-types";

//Hooks
import { useStatementList } from "@hooks";

//Local components
import Layout from "@views/layout";
import Form from "./_form";
import Sidebar from "@views/account/sidebar";
import { Breadcrumb } from "@components";

const Statements = () => {
  const initialValues = { date_range: 1 };
  const { data, total, loading, onSubmit } = useStatementList(initialValues);
  return (
    <Layout name="account">
      <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar
          className="hidden"
          current="statements"
        />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 mt-6 lg:mt-0 mb-8 lg:mb-16">
            <Breadcrumb className="px-6 md:px-0 mb-6 md:mb-8" back />
            <h2 className="px-4 md:px-0">
              Tax Statements
            </h2>
            <div className="px-4 mt-8 md:px-0 md:mt-10">
              <Form
                onSubmit={onSubmit}
                data={data}
                total={total}
                loading={loading}
                initialValues={initialValues}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Statements.propTypes = {
  props: PropTypes.object,
};

export default Statements;

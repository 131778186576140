import {
  LISTING_LIST_FETCH_REQUEST,
  LISTING_LIST_FETCH_SUCCESS,
  LISTING_LIST_FETCH_FAILED,
  LISTING_LIST_REFRESH,
} from "./listAction";

export const listingListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LISTING_LIST_FETCH_REQUEST:
      return {
        loading: true,
        data: [],
        error: null,
      };
      break;

    case LISTING_LIST_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    case LISTING_LIST_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    case LISTING_LIST_REFRESH:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: [],
  error: null,
};

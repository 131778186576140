import { useCallback } from "react";
import moment from "moment";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, StatementListUsecase } from "@core";

import {
  statementListAction,
  STATMENT_LIST,
} from "@redux/modules_v2/account/statementListing/action";

/**
 * Hooks
 * @param {*} params
 */
import { useDeepEffect } from "@hooks";

/**
 * Fetch lists hooks
 */
export const useStatementList = (params, callback) => {
  const dispatch = useDispatch();

  const fetchList = useCallback((params) => {
    dispatch(statementListAction.request(params));
  }, []);

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback(({ role, date_range, start_t, end_t } = {}) => {
    let params = { role };
    if (date_range === -1) {
      params = Object.assign({}, params, {
        start_t: moment(start_t).utc().format(),
        end_t: moment(end_t).utc().format(),
      });
    } else if (date_range) {
      params = Object.assign({}, params, {
        start_t: moment().subtract(date_range, "months").utc().format(),
      });
    }
    fetchList(params);
  }, []);

  const accountStatementListStatus = useSelector((state) => {
    const selector = new LoadingSelector(STATMENT_LIST, state);
    return Object.assign({}, get(state, "accountStatementListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = accountStatementListStatus;
  const usecase = new StatementListUsecase(data);

  /**
   * did mount fetch list
   */
  // useDeepEffect(() => {
  //   fetchList(params);
  // }, [params]);

  /**
   * effect after fetch
   */
  useDeepEffect(() => {
    if (!accountStatementListStatus.loading) {
      if (accountStatementListStatus.data && isEmpty(params)) {
        const usecase = new StatementListUsecase(data);
        typeof callback === "function" && callback(usecase);
      }
    }
  }, [accountStatementListStatus.loading, params]);

  return {
    data: usecase.list,
    total: usecase.total,
    loading,
    fetchList,
    onSubmit,
  };
};

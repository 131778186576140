import { BidApiRepo } from "@core";

export class BidApiUsecase {

  constructor() {
    this.repo = new BidApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }

  // show(payload) {
  //   return this.repo.show(payload);
  // }

  create(payload) {
    return this.repo.create(payload);
  }

  update(payload) {
    return this.repo.update(payload);
  }

  destroy(payload) {
    return this.repo.destroy(payload);
  }

  order(payload) {
    return this.repo.order(payload);
  }

}
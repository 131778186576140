import { takeLatest, call, put } from "redux-saga/effects";

import { OrderApiUsecase } from "@core";
import { ORDER_PAYMENT_PROOFS_LIST_REQUEST, orderPaymentProofsListAction } from "./action";

// order list
export function* watchOrderPaymentProofsListFetch() {
  yield takeLatest(ORDER_PAYMENT_PROOFS_LIST_REQUEST, orderPaymentProofsListFetch);
}

function* orderPaymentProofsListFetch(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "getPaymentsProofs"], action.payload);
    yield put(orderPaymentProofsListAction.success(res.data));
  } catch (err) {
    yield put(orderPaymentProofsListAction.failure(err));
  }
}

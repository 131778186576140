/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, PermanentUsecase } from "@core/index";

import {
  permanentShowAction,
  PERMANMENT_SHOW,
} from "@redux/modules_v2/permanent/show/action";

//Hooks
import { useCurrentUser, useDeepEffect } from "@hooks";

/**
 * Fetch permanent show hooks
 * @param {*} param
 */
export const useFetchPermanentShow = (params) => {
  const dispatch = useDispatch();
  const fetchShow = () => {
    dispatch(permanentShowAction.request(params));
  };

  const permanentShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(PERMANMENT_SHOW, state);
    return Object.assign({}, get(state, "permanentShowStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = permanentShowStatus;
  const currentUser = useCurrentUser();
  const usecase = new PermanentUsecase(
    data,
    get(currentUser, "user.user_id", "")
  );

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    if (params.permanent_listing_id) {
      fetchShow();
    }
  }, [params]);

  return [usecase, loading];
};

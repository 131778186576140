import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_DELETE_REQUEST, permanentDeleteAction } from "./action";

export function* watchPermanentDeleteFetch() {
  yield takeLatest(PERMANENT_DELETE_REQUEST, permanentDeleteFetch);
}

function* permanentDeleteFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    yield call([usecase, "bidDelete"], action.payload);
    yield put(permanentDeleteAction.success('delete successful'));
  } catch (err) {
    const errors = get(err, "response.data.errors", {});
    yield put(permanentDeleteAction.failure(errorString(errors)));
  }
}

import { useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionListUsecase } from "@core";

import {
  auctionSimilarListAction,
  AUCTION_SIMILAR_LIST,
} from "@redux/modules_v2/auctions/similarList/action";

//Hooks
import { useCurrentUser, useDeepEffect } from "@hooks";

/**
 * Fetch auction list hooks
 * @param {*} param
 */
export const useAuctionSimilarList = (id) => {
  const dispatch = useDispatch();
  const fetchList = (id) => {
    if (!id) {
      return;
    }
    dispatch(auctionSimilarListAction.request({ similar: true, auction_id:id }));
  };

  const auctionSimilarListStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_SIMILAR_LIST, state);
    return Object.assign({}, get(state, "auctionSimilarListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { loading, data } = auctionSimilarListStatus;

  const currentUser = useCurrentUser();
  const usecase = new AuctionListUsecase(data, get(currentUser, "user", {}));

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    fetchList(id);
  }, [id]);

  return {
    list: usecase.activeList,
    fetchList,
    loading,
  };
};


export const BANKS_SHOW_FETCH_REQUEST = "banks/fetch_show_request";
export const BANKS_SHOW_FETCH_LOADING = "banks/fetch_show_loading";
export const BANKS_SHOW_FETCH_SUCCESS = "banks/fetch_show_success";
export const BANKS_SHOW_FETCH_FAILED = "banks/fetch_show_failed";
export const BANKS_SHOW_FETCH_RECEIVED = "banks/fetch_show_received";

export function bankFetchShow(payload) {
  return {
    type: BANKS_SHOW_FETCH_REQUEST,
    payload
  };
}

export const P2P_NEW = "p2p/new";
export const P2P_NEW_REQUEST = `${P2P_NEW}_request`;
export const P2P_NEW_SUCCESS = `${P2P_NEW}_success`;
export const P2P_NEW_FAILURE = `${P2P_NEW}_failure`;
export const P2P_NEW_RESET = `${P2P_NEW}_reset`;

export const p2pNewAction = {
  request: (payload) => {
    return {
      type: P2P_NEW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: P2P_NEW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: P2P_NEW_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: P2P_NEW_RESET
    }
  }
};

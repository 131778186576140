import React from "react";
import map from "lodash/map";
import PropTypes from "prop-types";

/**
 * Components
 */
import { Loading, Link } from "@components";

const FisheryList = ({ data, listLoading }) => {
  if (listLoading) {
    return <Loading />;
  }
  return (
    <div className="mt-6">
      <ul>
        {map(data, (item, index) => {
          return (
            <Link
              to={item.url}
              key={index}
              className="flex justify-between items-center py-4 border-b-1 border-blue-gray-200"
            >
              <div className="flex items-center space-x-4">
                <img src={item.logoImage} className="flex-shrink-0 h-10 w-10" />
                <div className="block truncate font-bold pr-4 group-hover:text-fish-primary">
                  {item.title}
                </div>
              </div>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

FisheryList.propTypes = {
  data: PropTypes.array,
  listLoading: PropTypes.bool,
};

export default FisheryList;
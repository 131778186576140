import { takeLatest, call, put } from "redux-saga/effects";

import { OrderApiUsecase } from "@core";
import { ORDER_QUOTA_TRANSFERS_LIST_REQUEST, orderQuotaTransfersListAction } from "./action";

// order list
export function* watchQuotaTransfersListFetch() {
  yield takeLatest(ORDER_QUOTA_TRANSFERS_LIST_REQUEST, quotaTransfersListFetch);
}

function* quotaTransfersListFetch(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "getQuotaTransfers"], action.payload);
    yield put(orderQuotaTransfersListAction.success(res.data));
  } catch (err) {
    yield put(orderQuotaTransfersListAction.failure(err));
  }
}

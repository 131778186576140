const countryList = [
  "Australia"
]

function getCountryList() {
  return countryList
}

export {
  getCountryList
}
import omit from "lodash/omit";
import get from "lodash/get";
import map from "lodash/map";
import { API, PayTypeMap } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class OrderApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "orders";
  }

  /**
   * Order Api instance list
   * @param {object} payload { page: 1, state: "closed" ... }
   */
  list(payload) {
    this.setApi(`${API}/${this.model}`);
    return this.get(payload);
  }

  /**
   * Order Api instance sold
   * @param {object} payload { page: 1 }
   */
  sold(payload) {
    this.setApi(`${API}/${this.model}/sold`);
    return this.get(payload);
  }

  /**
   * Order Api instance purchased
   * @param {object} payload { page: 1 }
   */
  purchased(payload) {
    this.setApi(`${API}/${this.model}/purchased`);
    return this.get(payload);
  }

  /**
   * Order Api instance show
   * @param {object} payload { id: 1, ... }
   */
  show(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}`);
    return this.get(omit(payload, "id"));
  }

  /**
   * Order Api instance payment
   * @param {object} payload { id: 1, paymentMethod: "poli|stripe|wallet|offline", payType: "instant|deposit|hold_fee|balance" }
   */
  payment(payload) {
    this.setApi(
      `${API}/${this.model}/${payload.id}/payments/${payload.paymentMethod}`
    );
    const data = {};
    if (
      payload.payType === PayTypeMap.balance ||
      payload.payType === PayTypeMap.deposit ||
      payload.payType === PayTypeMap.holdFee
    ) {
      data.term = payload.payType;
    }
    return this.post(data);
  }

  /**
   * Order Api buyer payment
   * /api/v1/orders/:id/buyer_pay
   */
  buyerPay(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/buyer_pay`);
    return this.post(payload);
  }

  /**
   * Order Api payment proofs upload
   * /api/v1/orders/:id/payment_proofs
   */
  paymentsProofs(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/payment_proofs`);
    let formData = new FormData();
    let file = get(payload, "file", {});
    let name = get(payload, "file.name", "");
    formData.append("file", file);
    formData.append("name", name);
    return this.post(formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  /**
   * Order Api get payment proofs
   */
  getPaymentsProofs(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/payment_proofs`);
    return this.get(payload);
  }

  /**
   * Order Api delete payment proofs
   */
  deletePaymentsProofs(payload) {
    this.setApi(
      `${API}/${this.model}/${payload.id}/payment_proofs/${payload.payment_proofs_id}`
    );
    return this.delete();
  }

  /**
   * Order Api receive fund
   * /api/v1/orders/:id/receive_fund
   */
  receiveFund(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/receive_fund`);
    return this.post(payload);
  }

  /**
   * Order quota transfers
   * POST /api/v1/orders/:id/quota_transfers
   */
  quotaTransfers(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/quota_transfers`);
    let files = get(payload, "files", []) || [];
    let formData = new FormData();
    map(files, (o,idx) => {
      let name = get(o, "name", "");
      formData.append(`quota_transfers[${idx}][name]`, name);
      formData.append(`quota_transfers[${idx}][file]`, o);
    });
    return this.post(formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  /**
   * Order Api get quota transfers
   */
  getQuotaTransfers(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/quota_transfers`);
    return this.get(payload);
  }
}

import {
  GRADE_CHANGE,
  ROLE_CHANGE,
  CHART_TIME_CHANGE,
  // TABLE_ROLE_CHANGE,
  PRICE_SHOW_ALL,
  TRADE_SHOW_ALL,
  // MODAL_ORDER_STATUS_CHANGE,
  ORDER_TAB_CHANGE,
  ORDER_FORM_CHANGE,
  MODAL_ORDER_CONFIRM_STATUS_CHANGE,
  MODAL_ORDER_CONFIRM_RESULT_CHANGE,
  ORDER_FORM_INPUT,
  RESET_ORDER_FORM_DATA,
  MODAL_CART_STATUS_CHANGE,
  MODAL_CART_TAB_CHANGE,
  MODAL_CART_AVAILABLE_CHANGE,
  CART_NEW_ITEM_TIPS_STATUS_CHANGE,
  MODAL_SET_PRICE_STATUS_CHANGE
} from "./productShowAction";

export default function productShowState(state = initStatus, action = {}) {
  // let modalOrder = Object.assign({}, state.modalOrder);
  let modalCart = Object.assign({}, state.modalCart);
  let modalOrderConfirm = Object.assign({}, state.modalOrderConfirm);
  let cartNewItemTip = Object.assign({}, state.cartNewItemTip);
  let modalSetPrice = Object.assign({}, state.modalSetPrice);
  switch (action.type) {
    case GRADE_CHANGE:
      return Object.assign({}, state, {
        grade: action.payload.grade,
        gradeIndex: action.payload.gradeIndex
      });
      break;

    case ROLE_CHANGE:
      return Object.assign({}, state, {
        role: action.payload.role
      });
      break;

    case CHART_TIME_CHANGE:
      return Object.assign({}, state, {
        chartTime: action.payload.time
      });
      break;

    // case TABLE_ROLE_CHANGE:
    //   return Object.assign({}, state, {
    //     tableRole: action.payload.role
    //   });
    //   break;

    case PRICE_SHOW_ALL:
      return Object.assign({}, state, {
        showAllPrice: action.payload.isShowAll
      });
      break;

    case TRADE_SHOW_ALL:
      return Object.assign({}, state, {
        showAllTrade: action.payload.isShowAll
      });
      break;

    // case MODAL_ORDER_STATUS_CHANGE:
    //   modalOrder.isOpen = action.payload.isOpen;
    //   return Object.assign({}, state, {
    //     modalOrder: modalOrder
    //   });
    //   break;

    case ORDER_TAB_CHANGE:
      return Object.assign({}, state, {
        orderTabId: action.payload.tabId
      });
      break;

    case ORDER_FORM_CHANGE:
      return Object.assign({}, state, {
        orderFormShow: action.payload.isOpen
      });
      break;

    case ORDER_FORM_INPUT:
      let formData = Object.assign({}, state.orderFormData, action.payload);
      return Object.assign({}, state, {
        orderFormData: formData
      });
      break;

    case MODAL_ORDER_CONFIRM_STATUS_CHANGE:
      modalOrderConfirm.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        modalOrderConfirm: modalOrderConfirm
      });
      break;

    case MODAL_ORDER_CONFIRM_RESULT_CHANGE:
      modalOrderConfirm.showResult = action.payload.showResult;
      return Object.assign({}, state, {
        modalOrderConfirm: modalOrderConfirm
      });
      break;

    case RESET_ORDER_FORM_DATA:
      let formDataForReset = Object.assign({}, initStatus.orderFormData);

      let orderConfirmForReset = Object.assign(
        {},
        initStatus.modalOrderConfirm
      );
      return Object.assign({}, state, {
        orderFormData: formDataForReset,
        orderTabId: initStatus.orderTabId,
        modalOrderConfirm: orderConfirmForReset
      });
      break;

    case MODAL_CART_STATUS_CHANGE:
      modalCart.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        modalCart: modalCart
      });
      break;

    case MODAL_CART_TAB_CHANGE:
      modalCart.tabId = action.payload.tabId;
      return Object.assign({}, state, {
        modalCart: modalCart
      });
      break;

    case MODAL_CART_AVAILABLE_CHANGE:
      modalCart.isAvailable = action.payload.isAvailable;
      return Object.assign({}, state, {
        modalCart: modalCart
      });
      break;

    case CART_NEW_ITEM_TIPS_STATUS_CHANGE:
      cartNewItemTip.role = action.payload.role;
      cartNewItemTip.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        cartNewItemTip
      });
      break;

    case MODAL_SET_PRICE_STATUS_CHANGE:
      modalSetPrice.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        modalSetPrice
      });
      break;

    default:
      return state;
  }
}

const initStatus = {
  grade: {},
  gradeIndex: 0,
  role: "buy", // buy|sell
  // tableRole: "buy", // buy|sell
  showAllPrice: false,
  showAllTrade: false,
  chartTime: "day", // day|week|month|year
  orderTabId: "tab1",
  orderFormShow: false,
  orderFormData: {
    quantity: "",
    orderItems: []
  },
  // modalOrder: {
  //   isOpen: false,
  //   tabId: "tab1",
  //   orderFormShow: false,
  //   orderFormData: {
  //     quantity: "",
  //     orderItems: []
  //   }
  // },
  modalOrderConfirm: {
    isOpen: false,
    showResult: false
  },
  modalCart: {
    isOpen: false,
    isAvailable: true,
    showResult: false,
    tabId: "tab1"
  },
  cartNewItemTip: {
    role: "",
    isOpen: false
  },
  modalSetPrice: {
    isOpen: false
  }
};

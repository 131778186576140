import { createSelector } from "reselect";
import map from "lodash/map";
import {
  QUOTUM_INVENTORIES_SUCCESS,
  QUOTUM_INVENTORIES_FAILURE,
  QUOTUM_INVENTORIES_UPDATE,
} from "./action";

export const accountQuotumInventoriesStatus = (state = initStatus, action = {}) => {
  const updateSelector = createSelector(
    (state) => state.data,
    (_, payload) => payload,
    (data, { id, ...rest }) => {
      return map(data, (item) => {
        if (item.id == id) {
          return Object.assign({}, item, rest);
        }
        return item;
      });
    }
  );

  switch (action.type) {
    case QUOTUM_INVENTORIES_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case QUOTUM_INVENTORIES_FAILURE:
      return {
        data: [],
        error: action.error,
      };

    case QUOTUM_INVENTORIES_UPDATE:
      return {
        data: updateSelector(state, action.payload),
        error: null,
      };
    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null,
};

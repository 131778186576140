export const OFFER_CREATE = "offers/create";
export const OFFER_CREATE_REQUEST = `${OFFER_CREATE}_request`;
export const OFFER_CREATE_SUCCESS = `${OFFER_CREATE}_success`;
export const OFFER_CREATE_FAILURE = `${OFFER_CREATE}_failure`;
export const OFFER_CREATE_RECEIVED = `${OFFER_CREATE}_received`;

export const offerCreateAction = {
  request: (payload) => {
    return {
      type: OFFER_CREATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: OFFER_CREATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: OFFER_CREATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: OFFER_CREATE_RECEIVED
    }
  },
};

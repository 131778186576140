export const ORDER_SOLD = "orders/sold";
export const ORDER_SOLD_REQUEST = `${ORDER_SOLD}_request`;
export const ORDER_SOLD_SUCCESS = `${ORDER_SOLD}_success`;
export const ORDER_SOLD_FAILURE = `${ORDER_SOLD}_failure`;


export const orderSoldAction = {
  request: (payload) => {
    return {
      type: ORDER_SOLD_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDER_SOLD_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDER_SOLD_FAILURE,
      error,
    }
  },

};

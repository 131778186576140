
export const CHANNELS_FETCH_REQUEST = "channels/fetch_list_request";
export const CHANNELS_FETCH_LOADING = "channels/fetch_list_loading";
export const CHANNELS_FETCH_SUCCESS = "channels/fetch_list_success";
export const CHANNELS_FETCH_FAILED = "channels/fetch_list_failed";
export const CHANNELS_FETCH_RECEIVED = "channels/fetch_list_received";
export const CHANNELS_REFRESH = "channels/refresh";

/**
 * Channel List
 * @param  {Number} targetId AskingPriceId | OfferPriceId
 * @param  {String} kind     "AskingPrice" | "OfferPrice"
 * @return {Object}
 *
 * {
 *   type: "channels/fetch_list_request",
 *   targetId: 16,
 *   kind: "AskingPrice",
 * }
 */
export function fetchChannelList(targetId, kind) {
  return {
    type: CHANNELS_FETCH_REQUEST,
    targetId,
    kind,
  };
}


/**
 * refresh channel list
 * @param  {Array} list      new channel array
 * @return {Object}
 *
 * {
 *   type: "channels/push"
 *   data: [{}, ...]
 * }
 */
export function refreshChannelList(list) {
  return {
    type: CHANNELS_REFRESH,
    data: list,
  };
}

const WALLET_REQUEST = "account_wallet/request";
const WALLET_LOADING = "account_wallet/loading";
const WALLET_SUCCESS = "account_wallet/success";
const WALLET_FAILED = "account_wallet/failed";

function walletRequest(payload) {
  return {
    type: WALLET_REQUEST,
    payload,
  };
}

function walletLoading() {
  return {
    type: WALLET_LOADING,
  };
}

function walletSuccess(data) {
  return {
    type: WALLET_SUCCESS,
    data,
  };
}

function walletFailed(error) {
  return {
    type: WALLET_FAILED,
    error,
  };
}

export {
  WALLET_REQUEST,
  WALLET_FAILED,
  WALLET_LOADING,
  WALLET_SUCCESS,
  walletRequest,
  walletFailed,
  walletSuccess,
  walletLoading,
};

import {
  P2P_NEW_SUCCESS,
  P2P_NEW_RESET,
  P2P_NEW_FAILURE
} from "./action";

export const p2pNewStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case P2P_NEW_SUCCESS:
      return {
        data: action.data,
        error: null
      };

    case P2P_NEW_FAILURE:
      return {
        data: null,
        error: action.error
      };

    case P2P_NEW_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null
};

import isArray from "lodash/isArray";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

/**
 * Url param query 互相转化
 * utils 文件夹中已经存在，转移到这里
 */

export class UrlUtils {
  constructor() {}

  /**
   * Format 格式化url
   * 去除多余的空格；
   * 字母小写
   * 将文字之间空格用下划线替代；
   * @param {String} params
   * @example
   * UrlUtils.format("/White Shark ?category_id=xxxx&species_id=xxxxx");
   * "/white_shark?category_id=xxxx&species_id=xxxxx"
   */
  static format(url = "") {
    let res = "";
    res = url.trim().toLowerCase();
    res = res.replace(/\s+/g, "-");
    return res;
  }

  /**
   * 将格式化后的query转换成带空格的文字, 添加首字母大写
   * @param {String} url
   * @return {String}
   * @example
   * UrlUtils.stringfy("new-south-wales-zone-white-shark");
   * /=> "New South Wales Zone White Shark"
   */
  static stringfy(url = "") {
    let res = "";
    res = startCase(url.trim());
    res = res.replace(/-/g, " ");
    return res;
  }

  /**
   * 给params去空值
   * 去除以key为键名的键/值;
   * 去除值为空的键/值;
   * @param {*} params  { species_id: "", state_id: "", market_type: ""}
   * @param {*} key {market_type}
   * @example
   * compactParams({ species_id: "xxxx", state_id: "", market_type: ""}, "market_type");
   * {species_id: "xxxx"}
   */
  static compactParams(params = {}, key = "") {
    let cloneParams = Object.assign({}, params);
    let res = {};
    if (key) delete cloneParams[key];
    for (let [k, v] of Object.entries(cloneParams)) {
      if (
        (isArray(v) && !isEmpty(compact(v))) ||
        ((isString(v) || isNumber(v)) && v)
      ) {
        res[k] = v;
      }
    }
    return res;
  }

  /**
   * params 转 query string
   * @param {Object} params {category_id: xxxx, species_id: xxxxx}
   * @example paramsToQuery({category_id: xxxx, species_id: xxxxx})
   * category_id=xxxx&species_id=xxxxx
   */
  static paramsToQuery(params = {}) {
    return Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
  }

  /**
   * 根据 query string 查询 params
   * @param {String} query "category_id=xxxx&species_id=xxxxx"
   * @param {String} key "category_id" 可选
   * @returns {Object | String}
   * @example
   * UrlUtils.queryToParams("category_id=11111&species_id=22222")
   * {category_id: 11111, species_id: 22222}
   * UrlUtils.queryToParams("category_id=11111&species_id=22222", category_id)
   * 11111
   * @ref
   * https://developer.mozilla.org/zh-CN/docs/Web/API/URLSearchParams
   */
  static queryToParams(query = "", key = "") {
    const searchParams = new URLSearchParams(query);
    if (key) {
      return searchParams.get(key);
    }
    let res = {};
    for (let [k, v] of searchParams.entries()) {
      res[k] = v;
    }
    return res;
  }

  /**
   * 获取 query string 所有的query
   * @param {String} query "http://localhost:3000/login?redirect_to=/account/quotum_balances/80?toggle=open&type=apply&offer_id=872"
   * @param {String} key "redirect_to"
   * @returns {String} "/account/quotum_balances/80?toggle=open&type=apply&offer_id=872"
   */
  static queryAfterKey(query = "", key = "") {
    try {
      const match = query.substr(query.indexOf(`${key}=`) + key.length + 1);
      return match;
    } catch (error) {
      return "";
    }
  }
}

import { ORDERS_RECEIVE_FUND_SUCCESS, ORDERS_RECEIVE_FUND_FAILURE, ORDERS_RECEIVE_FUND_RESET } from "./action";

export const ordersReceiveFundStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ORDERS_RECEIVE_FUND_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case ORDERS_RECEIVE_FUND_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case ORDERS_RECEIVE_FUND_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

export const FISHERY_FOLLOW = "fishery/follow";
export const FISHERY_FOLLOW_REQUEST = `${FISHERY_FOLLOW}_request`;
export const FISHERY_FOLLOW_SUCCESS = `${FISHERY_FOLLOW}_success`;
export const FISHERY_FOLLOW_FAILURE = `${FISHERY_FOLLOW}_failure`;
export const FISHERY_FOLLOW_RESET = `${FISHERY_FOLLOW}_reset`;

export const fisheryFollowAction = {
  request: payload => {
    return {
      type: FISHERY_FOLLOW_REQUEST,
      payload
    };
  },
  success: data => {
    return {
      type: FISHERY_FOLLOW_SUCCESS,
      data
    };
  },
  failure: error => {
    return {
      type: FISHERY_FOLLOW_FAILURE,
      error
    };
  },
  reset: () => {
    return { type: FISHERY_FOLLOW_RESET };
  }
};
 
import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_OFFERS_REQUEST, accountOffersAction } from "./action";

// fetch account offers list
export function* watchAccountOffersFetch() {
  yield takeLatest(ACCOUNT_OFFERS_REQUEST, accountOffersFetch);
}

function* accountOffersFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "offers"], action.payload);
    yield put(accountOffersAction.success(res.data));
  } catch (err) {
    yield put(accountOffersAction.failure(err));
  }
}

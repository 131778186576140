import { OFFER_UPDATE_SUCCESS, OFFER_UPDATE_FAILURE, OFFER_UPDATE_RECEIVED } from "./action";

export const offerUpdateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case OFFER_UPDATE_SUCCESS:
      return {
        justFinished: true,
        data: action.data,
        error: null,
      };

    case OFFER_UPDATE_FAILURE:
      return {
        justFinished: true,
        data: null,
        error: action.error,
      };

    case OFFER_UPDATE_RECEIVED:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  justFinished: false,
  data: null,
  error: null,
};

import React from "react";

//Core
//Common components
import { Breadcrumb, Mobile, DesktopAndTablet } from "@components";
//Local components
import FisheryList from "./list";
//Hooks
import { useFisheryList } from "@hooks";

/**
 * Components
 */

const List = () => {
  const { data, loading: listLoading } = useFisheryList({});

  const renderDesktop = () => {
    return (
      <div>
        <div
          className="relative mt-6 mx-auto bg-white rounded-lg overflow-x-hidden"
          style={{ width: 620 }}
        >
          <Breadcrumb
            className="text-4xl font-medium absolute right-0 top-4"
            backIcon
          />
          <div className="px-6 pt-10">
            <div className="mb-40">
              <h2 className="mb-6">
                Fisheries
              </h2>
              <FisheryList data={data} listLoading={listLoading} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="relative min-h-screen h-full bg-gray-50 p-2">
        <Breadcrumb
          className="text-2xl z-50 font-medium absolute right-8 top-6"
          backIcon
        />
        <div className="h-full bg-white px-4 pt-6 rounded-lg shadow relative">
          <h2>
            Fisheries
          </h2>
          <FisheryList data={data} listLoading={listLoading} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <DesktopAndTablet>{renderDesktop()}</DesktopAndTablet>
      <Mobile>{renderMobile()}</Mobile>
    </div>
  );
};

export default List;
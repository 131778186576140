import { PermanentApiRepo } from "@core/index";

export class PermanentApiUsecase {
  constructor() {
    this.repo = new PermanentApiRepo();
  }

  /**
   * permanent listings new
   */
  new(payload) {
    return this.repo.new(payload);
  }

  /**
   * permanent listings show
   */
  show(payload) {
    return this.repo.show(payload);
  }

  /**
   * permanent listings
   * @param {object} payload 如果含有latest = 1，获取最新的列表, 否则获取全部列表
   */
  list(payload) {
    return this.repo.list(payload);
  }

  /**
   * permanent statistic
   * @param {object} payload
   */
  statistic(payload) {
    return this.repo.statistic(payload);
  }

  /**
   * permanent bid
   */
  bid(payload) {
    if (payload.id) {
      return this.repo.bidEdit(payload);
    }
    return this.repo.bid(payload);
  }

  /**
   * Permanent bid delete
   * @param {Object} payload
   */
  bidDelete(payload) {
    return this.repo.bidDelete(payload);
  }

  /**
   * permanent bid listings
   * @param {object} payload 判断如果isSeller = true， 获取所有bids，否则只获取买家提交的bid
   */
  bidList(payload) {
    return this.repo.bidList(payload);
  }

  /**
   * permanent accept bid
   */
  acceptBid(payload) {
    return this.repo.acceptBid(payload);
  }

  /**
   * permanent listing purchase
   */
  purchase(payload) {
    return this.repo.purchase(payload);
  }

  /**
   * Permanent markets
   * @param {Object} payload
   */
  markets(payload) {
    return this.repo.markets(payload);
  }

  /**
   * Permanent market groups
   */
  marketGroups(payload) {
    return this.repo.marketGroups(payload);
  }

  /**
   * Permanent enquire
   */
  enquire(payload) {
    return this.repo.enquire(payload);
  }
}

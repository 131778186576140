import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERIES_LISTS_REQUEST, fisheriesListAction } from "./action";

// fetch fishery list
export function* watchFisheriesListFetch() {
  yield takeLatest(FISHERIES_LISTS_REQUEST, fisheriesListFetch);
}

function* fisheriesListFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(fisheriesListAction.success(res.data));
  } catch (err) {
    yield put(fisheriesListAction.failure(err));
  }
}

export const PERMANENT_UPDATE = "account/permanent_update";
export const PERMANENT_UPDATE_REQUEST = `${PERMANENT_UPDATE}_request`;
export const PERMANENT_UPDATE_SUCCESS = `${PERMANENT_UPDATE}_success`;
export const PERMANENT_UPDATE_FAILURE = `${PERMANENT_UPDATE}_failure`;
export const PERMANENT_UPDATE_RECEIVED = `${PERMANENT_UPDATE}_received`;

export const permanentUpdateAction = {
  request: (payload) => {
    return {
      type: PERMANENT_UPDATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_UPDATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_UPDATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: PERMANENT_UPDATE_RECEIVED
    }
  },
};

import {
  FISHERY_EXISTENCE_SUCCESS,
  FISHERY_EXISTENCE_FAILURE,
  FISHERY_EXISTENCE_RESET,
} from "./action";

export const fisheryExistenceStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case FISHERY_EXISTENCE_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case FISHERY_EXISTENCE_FAILURE:
      return {
        data: null,
        error: action.error,
      };
    case FISHERY_EXISTENCE_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: {},
  error: null,
};

import { useEffect, useState, useCallback } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core/index";

import {
  auctionFinalBidShowAction,
  AUCTION_FINAL_BID_SHOW,
} from "@redux/modules_v2/auctions/finalBid/action";

import Notice from "@utils/noticeNew";

/**
 * Fetch auction final bid show hooks
 * @param {*} param
 */
export const useFetchAuctionFinalBidShow = (id) => {
  const [hasBidded, setBid] = useState(false);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState();
  const [hasAccepted, setAccept] = useState(false);

  const dispatch = useDispatch();

  const fetchShow = useCallback(() => {
    dispatch(auctionFinalBidShowAction.request({ auction_id: id }));
  }, [id]);

  const auctionFinalBidShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_FINAL_BID_SHOW, state);
    return Object.assign({}, get(state, "auctionFinalBidShowStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    if (!auctionFinalBidShowStatus.loading) {
      if (!isEmpty(auctionFinalBidShowStatus.data)) {
        setBid(true);
        setAccept(!!get(auctionFinalBidShowStatus, "data.accepted_at", false));
        setPrice(get(auctionFinalBidShowStatus, "data.price", ""));
        setQuantity(get(auctionFinalBidShowStatus, "data.quantity", 0));
      }
      if (auctionFinalBidShowStatus.error) {
        Notice.failure(auctionFinalBidShowStatus.error);
      }
    }
  }, [auctionFinalBidShowStatus.loading]);

  /**
   * did mount fetch list
   */
  useEffect(() => {
    id && fetchShow();
  }, [id]);

  return {
    fetchShow,
    hasBidded,
    price,
    quantity,
    hasAccepted,
    loading: auctionFinalBidShowStatus.loading,
    data: auctionFinalBidShowStatus.data,
  };
};

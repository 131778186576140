import { takeLatest, call, put } from "redux-saga/effects";

import { GlobalApiUsecase } from "@core";
import { GLOBAL_STATIC_REQUEST, globalStaticAction } from "./action";

// fetch globals static data
export function* watchGlobalStaticFetch() {
  yield takeLatest(GLOBAL_STATIC_REQUEST, globalStaticFetch);
}

function* globalStaticFetch(action) {
  try {
    const usecase = new GlobalApiUsecase();
    const res = yield call([usecase, "static"], action.payload);
    yield put(globalStaticAction.success(res.data));
  } catch (err) {
    yield put(globalStaticAction.failure(err));
  }
}

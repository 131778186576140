const CHANGE_PASSWORD_REQUEST = 'change_password/request'
const CHANGE_PASSWORD_LOADING = 'change_password/loading'
const CHANGE_PASSWORD_SUCCESS = 'change_password/success'
const CHANGE_PASSWORD_FAILED = 'change_password/failed'
const CHANGE_PASSWORD_RESET = 'change_password/reset'

function changePasswordRequest(payload) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload
  }
}

function changePasswordLoading() {
  return {
    type: CHANGE_PASSWORD_LOADING
  }
}

function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data
  }
}

function changePasswordFailed(error) {
  return {
    type: CHANGE_PASSWORD_FAILED,
    error
  }
}

function changePasswordReset() {
  return {
    type: CHANGE_PASSWORD_RESET,
  }
}

export {
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_RESET,

  changePasswordFailed,
  changePasswordLoading,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordReset
}

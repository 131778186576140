import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import get from "lodash/get";
import flatten from "lodash/flatten";
import values from "lodash/values";
import compact from "lodash/compact";
import map from "lodash/map";
import { CSSTransition } from "react-transition-group";
import cx from "classnames";

/**
 * Core
 */
import { QuotaAttrUsecase } from "@core/index";

/**
 * Hooks
 */
import { useCountriesFetchList, useStatesFetchList } from "@hooks/quotaAttr";
import { useSearchForm } from "@hooks/search";

/**
 * import lib components
 */
import { Button, useDropdownOpen } from "@components";
import { Form, Select } from "@components/formik";

/**
 * Searching Form Mobile
 */
const SearchFormMob = ({ className, url }) => {
  const [countries] = useCountriesFetchList();
  const [states, statesLoading] = useStatesFetchList({
    country_id: get(countries, "[0][id]"),
  });
  const { ref, isOpen, toggleOpen } = useDropdownOpen(false);
  const { initialValues, keywords, onChangeKeywords, onSubmit } = useSearchForm(
    {
      toggleOpen: toggleOpen,
      url,
    }
  );
  const quotaAttr = new QuotaAttrUsecase(states);
  const customStyle = {
    input: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided) => {
      return {
        ...provided,
        ":active": {
          ...provided[":active"],
          color: "#FFFFFF",
        },
        ":hover": {
          ...provided[":hover"],
          color: "#FFFFFF",
        },
        ":focus": {
          ...provided[":focus"],
          color: "#FFFFFF",
        },
      };
    },
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#3f83f8",
      primary50: "#3f83f8",
      primary: "#3f83f8",
    },
  });

  const formRef = React.useRef();

  const onChange = (values, name) => {
    const labels =
      values instanceof Array
        ? map(values, (val) => val.label)
        : [get(values, "label", "")];
    onChangeKeywords({ [name]: labels });
  };

  return (
    <div ref={ref} className={className}>
      <Button
        size="xl"
        className={cx("pl-12 text-sm border-none shadow-default-tw truncate bg-white", {
          hidden: isOpen,
        })}
        color="gray-700"
        leadingIcon={
          <svg
            className="h-6 w-6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        }
        onClick={() => {
          toggleOpen(!isOpen);
        }}
        outline
        full
      >
        {compact(flatten(values(keywords))).join(", ") ||
          "Search by Species, States, Markets"}
      </Button>
      <CSSTransition
        // in={true}
        in={isOpen}
        classNames="tw-dropdown"
        className="bg-white origin-top-right absolute right-0 mt-2-tw w-56 rounded-md shadow-lg"
        timeout={30}
        unmountOnExit
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          <Form className="search-form-mob rounded-lg shadow-default-tw">
            <div className="relative border-b-2 border-blue-gray-100">
              <svg
                className="z-10 absolute left-2 top-3 h-5 w-5 text-blue-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13.3105 2.46806C14.7051 2.59541 16.8378 3.42027 17.7239 6.09285C17.7587 6.19761 17.7587 6.31081 17.724 6.41557C16.8387 9.08784 14.7053 9.91315 13.3105 10.0404C13.0626 11.3688 11.9118 12.2585 10.648 12.2585H7.90171C7.69437 12.2585 7.50741 12.1336 7.42804 11.942C7.34871 11.7504 7.39257 11.5299 7.5392 11.3832L8.86058 10.0619H7.90119C5.9198 10.0619 4.28078 11.5548 4.04938 13.4748C5.58955 13.7332 6.76694 15.0757 6.76694 16.6881V17.2373C6.76694 17.5205 6.53739 17.75 6.25424 17.75H4.88135C4.34059 17.75 3.85261 17.5208 3.50849 17.1549C3.16433 17.5208 2.67635 17.75 2.13559 17.75H0.762695C0.479551 17.75 0.25 17.5205 0.25 17.2373V16.6881C0.25 15.0658 1.44178 13.7168 2.99579 13.4702V9.54917C2.99579 6.02257 5.5795 3.08852 8.95331 2.53939L7.53916 1.12524C7.39253 0.978609 7.34868 0.758081 7.42801 0.566504C7.50734 0.374927 7.6943 0.25 7.90167 0.25H10.648C11.9154 0.25 13.0634 1.14366 13.3105 2.46806ZM2.13559 16.7246C2.6099 16.7246 2.99579 16.3387 2.99579 15.8644H2.99576V14.5145C2.01077 14.7468 1.27539 15.6332 1.27539 16.6881V16.7246H2.13559ZM12.2506 2.44663C11.9397 1.47746 11.037 1.27539 10.648 1.27539H9.13945L10.3107 2.44663H12.2506ZM5.74155 16.7246V16.6881C5.74155 15.6332 5.00617 14.7468 4.02115 14.5145V15.8644C4.02115 16.3387 4.40704 16.7246 4.88135 16.7246H5.74155ZM4.02115 10.9436C4.91919 9.78422 6.32455 9.03647 7.90116 9.03647H11.7779C10.6993 7.34646 10.6993 5.16203 11.7779 3.47202H10.0983C6.74735 3.47202 4.02115 6.19819 4.02115 9.54917V10.9436ZM9.13945 11.2331H10.648C11.0426 11.2331 11.9397 11.0305 12.2507 10.0618H10.3107L9.13945 11.2331ZM13.0586 9.03087C14.7192 8.94405 16.1638 7.8532 16.6944 6.26149L16.6968 6.25421L16.6946 6.24772C16.1638 4.65528 14.7192 3.5644 13.0586 3.47759C11.639 5.05064 11.639 7.45781 13.0586 9.03087ZM15.0043 5.70514C15.0043 5.98829 14.7748 6.21783 14.4916 6.21783C14.2085 6.21783 13.9789 5.98829 13.9789 5.70514C13.9789 5.42199 14.2085 5.19244 14.4916 5.19244C14.7748 5.19244 15.0043 5.42199 15.0043 5.70514Z" />
              </svg>
              <Select
                name="category_id"
                onChange={(values) => {
                  onChange(values, "category_name");
                }}
                placeholder="All Species"
                loadOptions={quotaAttr.getCategoryByTerm}
                styles={customStyle}
                theme={customTheme}
                // isMulti
                isSearchable={false}
              />
            </div>
            <div className="relative border-b-2 border-blue-gray-100">
              {/* <svg
                className="z-10 absolute left-2 top-4 h-5 w-5 text-blue-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M6.39062 14.3665V15.9909L5.99878 15.6736C5.93823 15.6246 4.49768 14.4522 3.03687 12.7709C1.02173 10.4517 0 8.3698 0 6.58333V6.40627C0 2.87384 2.86682 0 6.39062 0C9.91443 0 12.7812 2.87384 12.7812 6.40627V6.58333C12.7812 6.73336 12.7733 6.88571 12.7589 7.03989L11.5092 5.89697C11.2532 3.29197 9.05542 1.25 6.39062 1.25C3.55444 1.25 1.24695 3.56314 1.24695 6.40627V6.58333C1.24695 9.60218 5.12708 13.2567 6.39062 14.3665ZM10.225 13.4687H12.0953V11.5937H10.225V13.4687ZM6.39062 3.75C7.85168 3.75 9.04041 4.94163 9.04041 6.40627C9.04041 7.8709 7.85168 9.06253 6.39062 9.06253C4.92957 9.06253 3.74084 7.8709 3.74084 6.40627C3.74084 4.94163 4.92957 3.75 6.39062 3.75ZM6.39062 5C5.61707 5 4.98779 5.63081 4.98779 6.40627C4.98779 7.1816 5.61707 7.81253 6.39062 7.81253C7.16406 7.81253 7.79346 7.1816 7.79346 6.40627C7.79346 5.63081 7.16406 5 6.39062 5ZM15.1597 12.6193L14.8091 12.2987V14.3054C14.8091 15.2398 14.0507 16 13.1185 16H9.17065C8.23853 16 7.4801 15.2398 7.4801 14.3054V12.2987L7.12952 12.6193L6.28918 11.6959L11.1445 7.25551L16 11.6959L15.1597 12.6193ZM13.5621 11.1583L11.1445 8.9475L8.72705 11.1583V14.3053C8.72705 14.5505 8.92603 14.75 9.17065 14.75H13.1185C13.3632 14.75 13.5621 14.5505 13.5621 14.3053V11.1583Z" />
              </svg> */}
              <svg
                className="z-10 absolute left-2 top-3 h-5 w-5 text-blue-gray-600"
                viewBox="0 0 25 25"
                fill="none"
                stroke="currentColor"
              >
                <g
                  clipPath="url(#clip0)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21.234 10.424c0 7-9 13-9 13s-9-6-9-13a9 9 0 1118 0z" />
                  <path d="M12.234 13.424a3 3 0 100-6 3 3 0 000 6z" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path fill="#fff" d="M.234.423h24v24h-24z" />
                  </clipPath>
                </defs>
              </svg>
              <Select
                name="state_id"
                onChange={(values) => {
                  onChange(values, "state_name");
                }}
                placeholder="All States"
                options={quotaAttr.states}
                styles={customStyle}
                theme={customTheme}
                // isMulti
                isSearchable={false}
                isLoading={statesLoading}
              />
            </div>
            {/* <div className="relative border-b-2 border-blue-gray-100">
              <svg
                className="z-10 absolute left-2 top-4 h-5 w-5 text-blue-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M0.0238144 15.3837L1.89881 9.7275C1.96238 9.53572 2.14169 9.40625 2.34375 9.40625H4.77225L3.97303 8.40806C3.24481 7.49856 2.84375 6.35481 2.84375 5.1875C2.84375 2.32709 5.15681 0 8 0C10.8432 0 13.1563 2.32709 13.1563 5.1875C13.1563 6.35484 12.7552 7.49856 12.027 8.40806L11.2278 9.40625H13.6563C13.8583 9.40625 14.0376 9.53572 14.1012 9.72753C14.1447 9.85784 15.9382 15.269 15.9762 15.3838C16.0236 15.5267 15.9994 15.6836 15.9113 15.8057C15.8232 15.9277 15.6818 16 15.5313 16H0.468752C0.318221 16 0.176814 15.9277 0.0887206 15.8056C0.000595622 15.6836 -0.0235606 15.5267 0.0238144 15.3837ZM11.9719 14.0938L11.1778 15.0625H14.8821L14.2502 13.1562H13.8459L13.0135 13.9618C12.9261 14.0464 12.8092 14.0938 12.6875 14.0938H11.9719ZM13.9393 12.2188L13.3178 10.3438H10.4771L8.97581 12.2188H9.875C10.1339 12.2188 10.3438 12.4286 10.3438 12.6875C10.3438 12.9464 10.1339 13.1562 9.875 13.1562H6.125C5.86613 13.1562 5.65625 12.9464 5.65625 12.6875C5.65625 12.4286 5.86613 12.2188 6.125 12.2188H7.02419L5.87694 10.7859L4.58144 12.0814C4.49356 12.1693 4.37435 12.2188 4.25 12.2188H3.47541L2.67519 13.0189C2.58731 13.1068 2.4681 13.1562 2.34375 13.1562H1.74991L1.118 15.0625H9.96566L11.3875 13.3278C11.4765 13.2192 11.6096 13.1562 11.75 13.1562H12.4978L13.3303 12.3507C13.4177 12.2661 13.5346 12.2188 13.6563 12.2188H13.9393ZM8 0.9375C5.67378 0.9375 3.78125 2.84403 3.78125 5.1875C3.78125 6.15647 4.10063 7.0675 4.70485 7.82209L8 11.9375L11.2952 7.82209C11.8994 7.0675 12.2188 6.15644 12.2188 5.1875C12.2188 2.84403 10.3262 0.9375 8 0.9375ZM4.05585 11.2812L4.99335 10.3438H2.68219L2.06066 12.2188H2.1496L2.94981 11.4186C3.03769 11.3307 3.15691 11.2812 3.28125 11.2812H4.05585ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z" />
              </svg>
              <svg
                className="z-10 absolute left-2 top-3 h-5 w-5 text-blue-gray-600"
                viewBox="3 2 20 20"
                fill="currentColor"
              >
                <path
                  d="M12 13a1 1 0 100-2 1 1 0 000 2zM12 6a1 1 0 100-2 1 1 0 000 2zM12 20a1 1 0 100-2 1 1 0 000 2z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Select
                name="market_type"
                onChange={(values) => {
                  onChange(values, "market_name");
                }}
                placeholder="Quota Lease"
                options={MarketOptions}
                styles={customStyle}
                theme={customTheme}
                isSearchable={false}
                isMulti
              />
            </div> */}
            <div className="p-2">
              <Button
                color="fish-primary"
                full
                type="submit"
              >
                Search
              </Button>
            </div>
          </Form>
        </Formik>
      </CSSTransition>
    </div>
  );
};

SearchFormMob.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string
};

SearchFormMob.defaultProps = {
  className: "",
  url: ""
};

export default SearchFormMob;
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

const BidMessage = ({ data, className }) => {
  const isSeller = get(data, "show.seller", false);
  const message = get(data, "show.bidder.message", "");
  const fontColor = get(data, "show.color.font", "");
  const labelColor = get(data, "show.color.label", "");
  const barColor = get(data, "show.color.bar", "");
  const bgColor = get(data, "show.color.bg", "");
  const renderMessage = () => {
    if (!message || isSeller) {
      return null;
    }
    return (
      <div
        className={cx(
          "text-sm leading-normal font-medium",
          fontColor ? fontColor : ""
        )}
      >
        {message}
      </div>
    );
  };

  const renderPrice = () => {
    const label = get(data, "show.bidder.price.label", "");
    const str = get(data, "show.bidder.price.str", "");
    const value = get(data, "show.bidder.price.value", "");

    if (!value) {
      return null;
    }
    return (
      <>
        <span
          className={cx(
            "text-blue-gray-200 mx-5 hidden lg:block",
            barColor ? barColor : ""
          )}
        >
          |
        </span>
        <div className="flex items-center flex-shrink-0">
          <span
            className={cx(
              "text-sm leading-normal",
              labelColor ? labelColor : ""
            )}
          >
            {`Your ${label} is`}&nbsp;
          </span>
          <span
            className={cx(
              "text-sm lg:text-sm leading-normal lg:font-bold",
              fontColor ? fontColor : ""
            )}
          >
            {str}
          </span>
        </div>
      </>
    );
  };

  if (!message || isSeller) {
    return null;
  }

  return (
    <div
      className={cx("lg:flex items-center px-6 lg:px-4 py-4 rounded-t-lg lg:rounded-lg", bgColor ? bgColor : "", className)}
    >
      {renderMessage()}
      {renderPrice()}
    </div>
  );
};

BidMessage.propTypes = {
  data: PropTypes.object,
};

BidMessage.defaultProps = {
  data: {},
};

export default BidMessage;

import { useCallback } from "react";
/**
 * import lib tools
 */
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";

/** import hooks */
import { useFisheryMarketList } from "@hooks";

export const useQuotamarketOptions = ({ fisheryId, marketType }) => {
  const [marketList, loading] = useFisheryMarketList(fisheryId);
  /**
   * 获取未market quota options列表
   * @param {object} data quota market list
   */
  const getOptions = useCallback(
    (data = []) => {
      let filterData = [];
      if (marketType) {
        filterData = filter(
          data,
          (o) => get(o, "marketType", "") === marketType
        );
      }
      const options = map(filterData, ({ title, id } = {}) => ({
        label: title,
        value: id,
      }));
      return options;
    },
    [marketType]
  );

  const getExchangeOptions = useCallback((data = []) => {
    const filterData = filter(
      data,
      (o = {}) => get(o, "market.saleTypes", "") === "exchange" || get(o, "market.saleTypes", "") === "both"
    );
    const options = map(filterData, ({ title, id } = {}) => ({
      label: title,
      value: id,
    }));
    return options;
  }, []);

  /**
   * 获取选中market的单位名称和对应的kg
   * @param {string} id current quota market id
   */
  const getCurrentMarket = useCallback(
    (id) => {
      return find(marketList, (o) => get(o, "id") == id);
    },
    [marketList]
  );

  return {
    getCurrentMarket,
    options: getOptions(marketList),
    exchangeOptions: getExchangeOptions(marketList),
    loading: loading,
  };
};

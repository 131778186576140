
export const BIDS_FETCH_REQUEST = "bids/fetch_request";
export const BIDS_FETCH_LOADING = "bids/fetch_loading";
export const BIDS_FETCH_SUCCESS = "bids/fetch_success";
export const BIDS_FETCH_FAILED = "bids/fetch_failed";
export const BIDS_UPDATE_REQUEST = "bids/update_request";
export const BIDS_ALL_FETCH_SUCCESS = "bids/all_fetch_success";

export function fetchBids(payload) {
  return {
    type: BIDS_FETCH_REQUEST,
    payload
  };
}

export function updateBids(payload) {
  return {
    type: BIDS_UPDATE_REQUEST,
    payload
  };
}
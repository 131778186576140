import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
 ORDER_QUOTA_TRANSFERS_LIST,
 orderQuotaTransfersListAction
} from "@redux/modules_v2/orders/actions/quotaTransfers/list/action";


export const useQuotaTransferList = (id) => {
  const dispatch = useDispatch();

  const fetchList = useCallback(() => {
    dispatch(orderQuotaTransfersListAction.request({ id }));
  }, [id]);

  const ordersQuotaTransfersListStatus = useSelector((state) => {
    const selector = new LoadingSelector(ORDER_QUOTA_TRANSFERS_LIST, state);
    return Object.assign({}, get(state, "ordersQuotaTransfersListStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = ordersQuotaTransfersListStatus.loading;
  const data = ordersQuotaTransfersListStatus.data;

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    if (id) {
      fetchList(id);
    }
  }, [id]);

  return { data, loading, fetchList };
};

import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import flatten from "lodash/flatten";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { LoadingWrapper, Empty } from "@components";

const Table = ({ data, loading, emptyTitle, emptyContent, toggleModal }) => {
  const bidOfferPrices = flatten(
    map(data, (item) =>
      map(get(item, "bid_offer_prices", []), (price) => price)
    )
  );
  const history = useHistory();
  const renderOperation = (item) => {
    return (
      <div
        className={cx(
          "text-sm font-medium text-right text-fish-primary cursor-pointer hover:text-fish-primary-700"
        )}
        onClick={(evt) => {
          evt.stopPropagation();
          const data = {
            id: item.id,
            price: item.price,
            quantity: item.quantity,
            bidOfferType: item.bidOfferType,
            marketId: item.marketId,
            quantityUnits: get(item, "market.isSaleByUnit", false)
              ? item.unitsStr
              : item.amountStr,
            title: get(item, "market.title", ""),
            unitName: get(item, "market.unitNameCondition", ""),
          };
          toggleModal && toggleModal(data);
        }}
      >
        Edit
      </div>
    );
  };

  return (
    <LoadingWrapper loading={loading}>
      <Empty data={bidOfferPrices} title={emptyTitle} content={emptyContent}>
        <div className="overflow-hidden">
          <table className="mb-2 lg:mb-0 min-w-full lg:divide-y divide-blue-gray-200">
            <thead className="hidden lg:table-header-group bg-gray-50">
              <tr>
                <th className="pl-4 lg:pl-6 pr-2 py-3 w-32 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Listing ID
                </th>
                <th className="px-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Market
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Quantity
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  unit price
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  total
                </th>
                <th className="pr-4 lg:pr-6 pl-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody className="bg-white lg:divide-y divide-blue-gray-200" x-max="1">
              {map(bidOfferPrices, (item, index) => (
                <tr
                  className={cx(
                    "hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer",
                    { "lg:bg-gray-50": index % 2 != 0 }
                  )}
                  key={index}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    history && history.push(get(item, "market.url", "/"));
                  }}
                >
                  <td className="px-0 lg:pl-4 lg:pr-2 py-0 lg:py-5 lg:max-w-xxs">
                    <div
                      className={cx(
                        "border lg:border-none border-blue-gray-200 rounded-lg overflow-hidden mx-2",
                        {
                          "mt-2 lg:mt-0": index > 0,
                        }
                      )}
                    >
                      <div className="px-4 lg:px-0 py-4 lg:py-0">
                        <div className="flex items-center lg:block mb-1 lg:mb-0 space-x-2">
                          <div className="flex-1">
                            <div className="text-sm font-medium leading-5 flex-1 lg:hidden">
                              {get(item, "market.title", "")}
                            </div>
                            <div className="text-xxs lg:text-sm leading-5 font-medium text-blue-gray-500 lg:text-blue-gray-600 flex-1">
                              <span className="lg:hidden">Listing ID: </span>#
                              {get(item, "id", "")}
                            </div>
                            <div className="text-xxs leading-5 text-blue-gray-500 lg:whitespace-no-wrap lg:hidden">
                              {`${get(item, "amountStr", "")} | ${get(
                                item,
                                "priceWithCondition",
                                ""
                              )} | ${get(item, "totalStr", "")}`}
                            </div>
                          </div>
                          <div className="lg:hidden">{renderOperation(item)}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="hidden lg:table-cell px-2 py-4">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "market.title", "")}
                    </div>
                  </td>
                  <td className="hidden text-right lg:table-cell px-2 py-4 lg:whitespace-no-wrap">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "amountStr", "")}
                    </div>
                  </td>
                  <td className="hidden text-right lg:table-cell px-2 py-4 lg:whitespace-no-wrap">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "priceWithCondition", "")}
                    </div>
                  </td>
                  <td className="hidden text-right lg:table-cell px-2 py-4 lg:whitespace-no-wrap">
                    <div className="text-sm leading-5 text-blue-gray-600">
                      {get(item, "totalStr", "")}
                    </div>
                  </td>
                  <td className="hidden lg:table-cell pl-2 pr-4 lg:pr-6 py-4 lg:whitespace-no-wrap">
                    {renderOperation(item)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Empty>
    </LoadingWrapper>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyContent: PropTypes.string,
  toggleModal: PropTypes.func,
};

Table.defaultProps = {
  data: [],
  loading: false,
  emptyTitle: "",
  emptyContent: "",
  toggleModal: () => {},
};

export default Table;

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const ProgressBar = ({ step }) => {
  const getStyle = (step) => {
    switch (step) {
      case 0:
        return "w-1/3 bg-fish-primary rounded-r-full";
      case 1:
        return "w-2/3 bg-fish-primary rounded-r-full";
      case 2:
        return "w-full bg-fish-green-light";
      default:
        "";
    }
  };

  return (
    <div className="w-full h-2 bg-gray-100">
      <div
        className={cx(
          "transition-all ease-linear duration-300 transform h-full",
          getStyle(step)
        )}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {};

export default ProgressBar;

import React from "react";
/**
 * import lib tools & components
 */
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import { isEmpty } from "lodash";
import { Loading, Table } from "@components";

/**
 * [Component] Price(买/卖报价) list for common
 *
 * @example
 *
  <PriceList
    title="All Buyer Bids / All Seller Asks"
    type="buy|sell"
    market={market}
    loading={true|false}
    list={[
      {
        price: "$33.50",
        unitStr: 2,
        amountStr: "400.60kg",
        isMe: true/false,
      },
      ...
    ]}
  >
 */
const PriceList = (props) => {
  const {
    title,
    list,
    loading,
    type,
    market,
    hasBottomBorder,
    className,
    priceFirst,
  } = props;

  const iconClass = "w-4 h-4 rounded-full relative bg-fish-primary";
  const wrapperClass = classnames("", {
    "border-b border-blue-gray-200": hasBottomBorder,
  });

  const renderEmpty = () => {
    return (
      <div className="p-4 md:p-6 rounded-lg bg-gray-50 text-center whitespace-normal">
        {type === "buy"
          ? "There are no Seller Asks yet so you can’t buy instantly. You can place a bid and sellers will automatically be notified that you’re looking to buy."
          : "There are no Buyer Bids yet so you can’t sell instantly. You can place an ask and buyers will automatically be notified that you’re looking to sell."}
      </div>
    );
  };

  return (
    <div className={classnames(className)}>
      {title && <div className="font-bold mb-4-tw">{title}</div>}
      <div className={wrapperClass}>
        {loading ? (
          <Loading />
        ) : isEmpty(list) ? (
          renderEmpty()
        ) : (
          <Table className="table relative whitespace-no-wrap tw-table-v2 no-border no-padding-table md:padding-table no-head-bg md:head-bg">
            <thead className="">
              <tr>
                {priceFirst ? (
                  <th className="text-left">PRICE</th>
                ) : market && market.isSaleByUnit ? (
                  <th className="text-left">UNIT</th>
                ) : null}
                <th
                  className={
                    (market && market.isSaleByUnit) || priceFirst
                      ? "text-right"
                      : "text-left"
                  }
                >
                  AMOUNT
                </th>
                {!priceFirst ? (
                  <th className="text-right">PRICE</th>
                ) : market && market.isSaleByUnit ? (
                  <th className="text-right">UNIT</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <tr key={index}>
                    {priceFirst ? (
                      <td className="text-left whitespace-no-wrap font-semibold">
                        <span className="text-blue-gray-800">
                          {item.priceStrWithKg}
                        </span>
                      </td>
                    ) : market && market.isSaleByUnit ? (
                      <td className="font-normal text-left text-blue-gray-500">
                        <span className="text-blue-gray-500">
                          {item.unitsStr}
                        </span>
                      </td>
                    ) : null}
                    <td
                      className={`${
                        (market && market.isSaleByUnit) || priceFirst
                          ? "text-right"
                          : "text-left"
                      } whitespace-no-wrap`}
                    >
                      <div
                        className={`flex items-center  ${
                          (market && market.isSaleByUnit) || priceFirst
                            ? "justify-end"
                            : "justify-first"
                        }`}
                      >
                        <span className="font-normal text-blue-gray-500">
                          {item.amountStr.split(" ").join("")}
                        </span>
                        {item.isMe && (
                          <React.Fragment>
                            <div
                              data-for={`icon-me-${index}`}
                              className="ml-3 w-4 h-4 rounded-full relative bg-fish-primary flex items-center justify-center"
                              data-tip
                            >
                              <svg
                                className="text-white w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                fill="currentColor"
                              >
                                <path
                                  d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="32"
                                />
                                <path
                                  d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeMiterlimit="10"
                                  strokeWidth="32"
                                />
                              </svg>
                            </div>
                            <ReactTooltip
                              id={`icon-me-${index}`}
                              className="max-w-xs opacity-100"
                            >
                              You have open and unfilled offers at this price
                            </ReactTooltip>
                          </React.Fragment>
                        )}
                      </div>
                    </td>
                    {!priceFirst ? (
                      <td className="text-right whitespace-no-wrap font-semibold">
                        <span className="text-blue-gray-800">
                          {item.priceStrWithKg}
                        </span>
                      </td>
                    ) : market && market.isSaleByUnit ? (
                      <td className="text-right">
                        <span className="font-normal text-blue-gray-500">
                          {item.unitsStr}
                        </span>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

PriceList.protTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
  market: PropTypes.any,
  list: PropTypes.array,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["buy", "sell"]),
  hasBottomBorder: PropTypes.bool,
  priceFirst: PropTypes.bool, // unit与price列表调换位置
};

PriceList.defaultProps = {
  title: "",
  list: [],
  loading: false,
  market: {},
  type: "buy",
  hasBottomBorder: false,
  priceFirst: false,
};

export default PriceList;

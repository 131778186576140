const CHANGE_PASSWORD_EVENT_REQUEST = "change_password_event/request"
const CHANGE_PASSWORD_EVENT_LOADING = "change_password_event/loading"
const CHANGE_PASSWORD_EVENT_SUCCESS = "change_password_event/success"
const CHANGE_PASSWORD_EVENT_FAILED = "change_password_event/failed"

function changePasswordEventRequest(payload) {
  return {
    type: CHANGE_PASSWORD_EVENT_REQUEST,
    payload
  }
}

function changePasswordEventLoading() {
  return {
    type: CHANGE_PASSWORD_EVENT_LOADING
  }
}

function changePasswordEventSuccess(data) {
  return {
    type: CHANGE_PASSWORD_EVENT_SUCCESS,
    data
  }
}

function changPasswordEventFailed(error) {
  return {
    type: CHANGE_PASSWORD_EVENT_FAILED,
    error
  }
}

export {
  CHANGE_PASSWORD_EVENT_REQUEST,
  CHANGE_PASSWORD_EVENT_FAILED,
  CHANGE_PASSWORD_EVENT_LOADING,
  CHANGE_PASSWORD_EVENT_SUCCESS,

  changePasswordEventRequest,
  changePasswordEventLoading,
  changePasswordEventSuccess,
  changPasswordEventFailed
}
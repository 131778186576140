import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import isEmpty from "lodash/isEmpty";

import { Loading, Link } from "@components";
import ConsignBalance from "./_consignBalance";
import Offers from "./_offers";
import Apply from "@views/account/quotum_balances/apply";
import { PlaceForm, ModalWrapper } from "@views/common";

import {
  useBidOfferEdit,
  useBidOfferDelete,
  useOfferApplyConfirm,
  useOfferApply,
  useResponsive,
} from "@hooks";

const Listings = ({
  balance,
  offers,
  loading,
  fisheryId,
  marketId,
  fetchOfferList,
  fetchMarketBalance,
  usecaseMarket,
}) => {
  const [isOpen, toggleModal] = useState(false);
  const { isMobile } = useResponsive();
  //set current modal apply || ask
  const [currentModal, setCurrenModal] = useState("");
  const {
    place,
    setPlace,
    placeOrigin,
    setPlaceOrigin,
    submit,
    loadingUpdateOffer,
  } = useBidOfferEdit(() => {
    typeof fetchOfferList === "function" && fetchOfferList();
    typeof fetchMarketBalance === "function" && fetchMarketBalance();
    toggleModal(false);
  });
  const { deleteByData } = useBidOfferDelete(() => {
    typeof fetchOfferList === "function" && fetchOfferList();
    typeof fetchMarketBalance === "function" && fetchMarketBalance();
    toggleModal(false);
  });
  const { onSubmit: onOfferApplyConfirmSubmit } = useOfferApplyConfirm(() => {
    typeof fetchOfferList === "function" && fetchOfferList();
  });
  const { onSubmit: onOfferApplySubmit, loading: offerApplyLoading } =
    useOfferApply((offerId) => {
      onOfferApplyConfirmSubmit({ offerId });
      toggleModal(false);
    });
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="bg-white rounded-lg shadow divide-y divide-blue-gray-100">
      <Disclosure defaultOpen={isMobile ? false : true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center justify-between w-full px-4 md:px-6 py-4 text-xxs font-bold capitalize">
              <span> My Listings</span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180" : "rotate-90"
                } w-5 h-5 text-blue-gray-400 transform`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="divide-y divide-blue-gray-100">
              <div className="px-4 md:px-6 py-4">
                <ConsignBalance data={balance} />
              </div>
              {!isEmpty(offers) && (
                <div className="px-4 md:px-6 py-4">
                  <Offers
                    data={offers}
                    toggleModal={(data, currentModal) => {
                      setPlace(data);
                      setPlaceOrigin(data);
                      toggleModal(true);
                      setCurrenModal(currentModal);
                    }}
                    onDelete={(data) => deleteByData(data)}
                  />
                </div>
              )}
              <div className="px-4 md:px-6 py-4">
                <Link
                  to={`/sell?market_type=lease&fishery_id=${fisheryId}&sell_type=exchange&quota_market_id=${marketId}`}
                  className="font-medium text-fish-primary text-xxs"
                >
                  Create a new listing
                </Link>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <ModalWrapper
        title={currentModal === "ask" ? "Edit Ask Price" : "Transfer quota"}
        isOpen={isOpen}
        toggleModal={() => {
          setPlace({});
          setPlaceOrigin({});
          toggleModal(false);
        }}
        size="md"
        contentAlign="left"
      >
        <div className="pt-8">
          {currentModal === "ask" ? (
            <PlaceForm
              dataOrigin={placeOrigin}
              data={place}
              setPlaceData={setPlace}
              loading={loadingUpdateOffer}
              onSubmit={() => submit()}
              usecaseMarket={usecaseMarket}
            />
          ) : (
            <Apply
              data={place}
              onSubmit={onOfferApplySubmit}
              loading={offerApplyLoading}
            />
          )}
        </div>
      </ModalWrapper>
    </div>
  );
};

Listings.propTypes = {};

export default Listings;

import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

const MaxBidNotice = ({ open }) => {
  return (
    <CSSTransition in={open} classNames="collapse" timeout={300} unmountOnExit>
      <div
        className="border-t-4 rounded-lg p-4 border-fish-primary"
        style={{ background: "#D9DFFF" }}
      >
        <div className="text-sm text-fish-primary">
          If the reserve is not met when the auction ends{" "}
          <span className="font-medium">and</span> your max bid is{" "}
          <span className="font-medium">greater than the reserve</span>, you
          will automatically win the auction at the reserve price.
        </div>
      </div>
    </CSSTransition>
  );
};

MaxBidNotice.propTypes = {
  open: PropTypes.bool,
};

MaxBidNotice.defaultProps = {
  open: false,
};

export default MaxBidNotice;

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, notificationListUsecase } from "@core";
import { notificationListAction, NOTIFICATION_LIST } from "@redux/modules_v2/account/notification/action";

/**
 * Fetch auction list hooks
 * @param {*} param
 */
export const useNotificationList = () => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(notificationListAction.request());
  };

  const accountNotificationListStatus = useSelector((state) => {

    const selector = new LoadingSelector( NOTIFICATION_LIST, state);
    return Object.assign({}, get(state, "accountNotificationListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = accountNotificationListStatus;

  /**
   * did mount fetch list
   */
  useEffect(() => {
    fetchList();
  }, []);

  return {
    data: data,
    loading,
  };
}; 
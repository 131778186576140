import axios from "axios";
import get from "lodash/get";
import { takeLatest,all,  takeEvery, call, put, select } from "redux-saga/effects";
import { setSubmitFailed } from "redux-form";
import errorString from "@utils/errorString";
import {
  BIDS_NEW_REQUEST,
  BIDS_NEW_LOADING,
  BIDS_NEW_SUCCESS,
  BIDS_NEW_FAILED
} from "./newAction";

import { updateBids } from "./listAction";

import { bidsAPI } from "@config/api";

// create order
export function* watchBidNew() {
  yield takeEvery(BIDS_NEW_REQUEST, bidNew);
}

export function* bidNew(action) {
  yield put({ type: BIDS_NEW_LOADING });
  const { res, err } = yield call(bidNewApi, action.payload);
  const auction_id = yield select(state =>
    get(state, "auctionsShowStatus.data.id")
  );
  const status = get(res, "status");
  const codes = [200, 204];
  if (codes.includes(status)) {
    yield all([
      put({ type: BIDS_NEW_SUCCESS, data: "Create bid succeed" }),
      put(updateBids(res.data))
    ]);
    return;
  }
  yield all([
    put({
      type: BIDS_NEW_FAILED,
      error: errorString(get(err, "response.data.errors"), "Create bid failed")
    }),
    put(setSubmitFailed("BidForm", "Create bid failed"))
  ]);
}

/**
 * bidNewApi
 * @param  {[type]} payload [description]
 * @return {[type]}         [description]
 *
 * @example
 * payload
 * {
 *   bid: {
 *    price: 10.23
 *   }
 * }
 */

function bidNewApi({ id, values }) {
  return axios
    .post(bidsAPI(id), { bid: values })
    .then(res => ({
      res: res
    }))
    .catch(err => ({
      err
    }));
}

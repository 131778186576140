import concat from "lodash/concat";

import {
  CHANNELS_FETCH_REQUEST,
  CHANNELS_FETCH_SUCCESS,
  CHANNELS_FETCH_FAILED,
  CHANNELS_FETCH_LOADING,
  CHANNELS_FETCH_RECEIVED,

  CHANNELS_REFRESH,
} from "./listAction";

export const channelListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case CHANNELS_FETCH_LOADING:
      return {
        justFinished: false,
        loading: true,
        data: [],
        error: null,
      };
      break;
    case CHANNELS_FETCH_SUCCESS:
      return {
        justFinished: true,
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case CHANNELS_FETCH_FAILED:
      return {
        justFinished: true,
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    case CHANNELS_FETCH_RECEIVED:
      return {
        justFinished: false,
        loading: false,
        data: state.data,
        error: null,
      };
      break;

    case CHANNELS_REFRESH:
      return {
        justFinished: false,
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  justFinished: false,
  loading: false,
  data: [],
  error: null,
};

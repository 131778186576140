import { map, isArray } from "lodash";
import { WithdrawalEntity } from "@core";

export class WithdrawUsecase {

  /**
   * 构造函数
   * @param {Array<Withdraw> | Withdraw} data 原始数据(Withdraw)，可能是数组或单例对象
   */
  constructor(data) {
    this.entity = data;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new WithdrawalEntity(entity);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new WithdrawalEntity({});
    }
    return new WithdrawalEntity(this.entity);
  }

}

import React, { useState } from "react";
import { orderStateMap } from "@config/enum";
import { Button, Alert, TimeCountDown } from "@components";

/**
 * [Component] Order show page notice component
 */
const Notice = (props) => {
  const { order, history, checkOrderShow } = props;
  const [loading, setLoading] = useState(false);

  if (order.isAuctionOrder) {
    return null;
  }

  const renderTimeCountDown = () => {
    return (
      <TimeCountDown
        date={order.expiredAtOrigin}
        theme="danger"
        themeClosed="danger"
        onComplete={() => {
          window && window.location.reload();
        }}
        inline={true}
      />
    );
  };

  if (order.isClosed || (order.isExpired && !order.isFinished)) {
    return (
      <Alert
        theme="danger"
        content="This order has been cancelled as you have not completed the payment with the time specified."
      />
    );
  }

  if (order.isCompleted) {
    return null;
  }

  if (order.isOut) {
    return (
      <Alert
        theme="danger"
        content={
          <div className="">
            <span>Waiting for payment</span> {renderTimeCountDown()}
          </div>
        }
      />
    );
  }

  const content = order.useHoldingFee ? (
    <div className="">
      <span>Make full payment in</span> {renderTimeCountDown()}{" "}
      <span>to complete your order.</span>
    </div>
  ) : (
    <div className="">Your order is not yet complete.</div>
  );

  return (
    <Alert
      theme="danger"
      content={content}
      button={
        order.paidState === orderStateMap.pending_payment ? (
          <Button
            color="red-600"
            textColor="white"
            className="hover:bg-red-700"
            full
            onClick={() => {
              setLoading(true);
              typeof checkOrderShow === "function" &&
                checkOrderShow(
                  () => {
                    history && history.push(order.paymentUrl);
                    setLoading(false);
                  },
                  () => {
                    setLoading(false);
                  }
                );
            }}
            disabled={loading}
          >
            <div className="px-8 py-0 md:py-1 text-base font-semibold">
              Pay now
            </div>
          </Button>
        ) : (
          <Button
            disabled
            color="gray-600"
            textColor="white"
            className="hover:bg-gray-700"
            full
          >
            <div className="px-8 py-0 md:py-1 text-base font-semibold">
              Pay now
            </div>
          </Button>
        )
      }
    />
  );
};

export default Notice;

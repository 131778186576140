export const ORDERS_QUOTA_TRANSFERS_NEW = "orders/quota_transfers";
export const ORDERS_QUOTA_TRANSFERS_NEW_REQUEST = `${ORDERS_QUOTA_TRANSFERS_NEW}_request`;
export const ORDERS_QUOTA_TRANSFERS_NEW_SUCCESS = `${ORDERS_QUOTA_TRANSFERS_NEW}_success`;
export const ORDERS_QUOTA_TRANSFERS_NEW_FAILURE = `${ORDERS_QUOTA_TRANSFERS_NEW}_failure`;
export const ORDERS_QUOTA_TRANSFERS_NEW_RESET = `${ORDERS_QUOTA_TRANSFERS_NEW}_reset`;

export const ordersQuotaTransfersNewAction = {
  request: (payload) => {
    return {
      type: ORDERS_QUOTA_TRANSFERS_NEW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDERS_QUOTA_TRANSFERS_NEW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDERS_QUOTA_TRANSFERS_NEW_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: ORDERS_QUOTA_TRANSFERS_NEW_RESET
    }
  },
};

import React from "react";
import get from "lodash/get";

/**
 * import lib tools & hooks & components
 */
import {
  useAccountBids,
  useAccountInvolvedAuctions,
  useModal,
  useBidOfferEdit,
  useBidOfferDelete,
} from "@hooks";

import Layout from "@views/layout";
import Sidebar from "@views/account/sidebar";

import { PlaceForm, ModalWrapper } from "@views/common";
import { Breadcrumb } from "@components";
import Auctions from "./auctions";
import Bids from "./bids";
import { CloudHSMV2 } from "aws-sdk";

/**
 * [Page] Quotum balance Show
 */
const Show = () => {
  const auctionsProps = useAccountInvolvedAuctions();
  const bidsProps = useAccountBids();
  const usecaseMarket = get(bidsProps, "data[0].quota_market", {});
  const { isOpen, toggleModal } = useModal();
  const {
    place,
    setPlace,
    placeOrigin,
    setPlaceOrigin,
    submit,
    loadingUpdateBid
  } = useBidOfferEdit(() => {
    typeof bidsProps.fetchBidList === "function" && bidsProps.fetchBidList();
    toggleModal();
  });
  const { deleteByData, loadingDeleteBid } = useBidOfferDelete(() => {
    typeof bidsProps.fetchBidList === "function" && bidsProps.fetchBidList();
    toggleModal();
  });
  // render main content with loading
  const renderContent = () => {
    return (
      <div className="">
        <Breadcrumb className="mb-6 md:mb-8" back />
        <h2 className="lg:hidden mb-6">
          My Bids
        </h2>
        <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow mb-4 lg:mb-10">
          <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2">
                <h3>
                  My Auction Bids
                </h3>
              </div>
            </div>
          </div>
          <Auctions
            emptyTitle="You have no auction bids yet"
            emptyContent=""
            {...auctionsProps}
          />
        </div>
        <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow">
          <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2">
                <h3>
                  My Exchange Bids
                </h3>
              </div>
            </div>
          </div>
          <Bids
            toggleModal={(data) => {
              setPlace(data);
              setPlaceOrigin(data);
              toggleModal();
            }}
            emptyTitle="You have no exchange bids yet"
            emptyContent=""
            {...bidsProps}
          />
        </div>
      </div>
    );
  };

  return (
    <Layout name="account">
      <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar
          className="hidden"
          current="bids"
        />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 px-4 lg:px-0 mt-6 lg:mt-0 mb-8 lg:mb-16">
            {renderContent()}
          </div>
        </div>
      </div>
      <ModalWrapper
        title="Edit Bid Price"
        isOpen={isOpen}
        toggleModal={() => {
          setPlace({});
          setPlaceOrigin({});
          toggleModal();
        }}
      >
        <div className="pt-8">
          <PlaceForm
            dataOrigin={placeOrigin}
            data={place}
            setPlaceData={setPlace}
            onSubmit={() => submit()}
            onDelete={() => deleteByData(placeOrigin)}
            loading={loadingUpdateBid}
            loadingDelete={loadingDeleteBid}
            usecaseMarket={usecaseMarket}
          />
        </div>
      </ModalWrapper>
    </Layout>
  );
};

export default Show;

import React, { useState, useEffect } from "react";
import { SlideDown } from "react-slidedown";
import PropTypes from "prop-types";

import { Button } from "@components";

const LayoutTopNotification = ({ connectStatus }) => {
  const [isClose, toggleModal] = useState(true);
  useEffect(() => {
    toggleModal(connectStatus);
  }, [connectStatus]);

  return (
    <SlideDown>
      {!isClose && (
        <div className="layout-notification danger">
          <div className="layout-notification-inner">
            <div className="container flex">
              <div className="flex1 flex items-center">
                <div>
                  Warning! Your connection with the server was lost. Please
                  refresh the page.
                </div>
              </div>
              <div className="hidden md:block">
                <Button
                  outline
                  size="xs"
                  color="white"
                  className="px-2 py-1 hover:text-red-600 ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>
              </div>
            </div>
            <span
              className="layout-notification-close"
              onClick={() => {
                typeof toggleModal === "function" && toggleModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
      )}
    </SlideDown>
  );
};

LayoutTopNotification.propTypes = {
  connectStatus: PropTypes.bool,
};

LayoutTopNotification.defaultProps = {};

export default LayoutTopNotification;

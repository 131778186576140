import get from "lodash/get";
import uniq from "lodash/uniq";
import map from "lodash/map";
import pluralize from "pluralize";
import { Moment, MarketMap } from "@core";
import numeral from "@utils/numeral";

import { MarketEntity } from "./marketEntity";
import { PermanentMarketableEntity } from "./permanentMarketableEntity";

/**
 * [Entity] Bid & Offer
 *
 * data is obj
  {
    "id": 22,
    "is_me": true,
    "bid_offer_type": "offer",
    "price_cents": 1256,
    "price_currency": "AUD",
    "price":
    {
      "fractional": "1256.0",
      "currency":
      {
        "id": "aud",
        "alternate_symbols": ["A$"],
        "decimal_mark": ".",
        "disambiguate_symbol": "A$",
        "html_entity": "$",
        "iso_code": "AUD",
        "iso_numeric": "036",
        "name": "Australian Dollar",
        "priority": 4,
        "smallest_denomination": 5,
        "subunit": "Cent",
        "subunit_to_unit": 100,
        "symbol": "$",
        "symbol_first": true,
        "thousands_separator": ","
      },
      "bank":
      {
        "store":
        {
          "index":
          {},
          "options":
          {},
          "mutex":
          {},
          "in_transaction": false
        },
        "rounding_method": null
      }
    },
    "quantity": 5,
    "remain_quantity": 5,
    "created_at": "2020-03-23T20:29:37.104+11:00",
    "updated_at": "2020-03-23T20:29:37.104+11:00",
    "expired_at": "2020-05-14T18:03:22.388+10:00",
    "marketable_id": 1,
    "marketable_type": "QuotaMarket",
    "user_id": 1,
    "marketable": { market entry data }
  }

  market is a instant of MarketEntity or null
  if market is null, will generateMarket(data) base data.marketable
  generateMarket(data) 会返回一个 MarketEntity 或 PermanentMarketableEntity 实例
 */
export class BidOfferEntity {
  constructor(data, market) {
    this.entity = data;
    this.market = market || generateMarket(data);
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get marketId() {
    return get(this.entity, "marketable_id", "");
  }

  get marketType() {
    return get(this.entity, "marketable_type", ""); // "QuotaMarket|PermanentMarket"
  }

  get price() {
    return numeral(get(this.entity, "price_cents", 0)).divide(100).value();
  }

  get priceStr() {
    return numeral(this.price).format();
  }

  get priceStrWithKg() {
    return `${numeral(this.price).format()}/${this.market.unitOfPrice}`;
  }

  get priceWithCondition() {
    if (this.isLease) {
      return this.priceStrWithKg;
    }
    if (this.isPermanent) {
      return this.priceStr;
    }
    return "";
  }

  get quantity() {
    if (this.isLease) {
      return numeral(get(this.entity, "remain_quantity", 0)).value();
    }
    if (this.isPermanent) {
      return numeral(get(this.entity, "quantity", 0)).value();
    }
    return 0;
  }

  get units() {
    if (this.isLease) {
      return this.market.generateData(this.quantity).units || 0;
    }
    if (this.isPermanent) {
      return this.market.units;
    }
    return 0;
  }

  get unitsStr() {
    if (this.isLease) {
      return this.market.generateData(this.quantity).unitsStr || "";
    }
    if (this.isPermanent) {
      return this.market.unitsStr;
    }
    return "";
  }

  get amount() {
    if (this.isLease) {
      return this.market.generateData(this.quantity).amount || 0;
    }
    if (this.isPermanent) {
      return this.market.amount;
    }
    return 0;
  }

  get amountStr() {
    if (this.isLease) {
      return this.market.generateData(this.quantity).amountStr || "";
    }
    if (this.isPermanent) {
      return this.market.amountStr;
    }
    return "";
  }

  get total() {
    const totalPrice = get(this.entity, "total_price.price", "");
    if (totalPrice) {
      return totalPrice;
    }
    if (this.isLease) {
      return numeral(this.price).multiply(this.amount).value();
    }
    if (this.isPermanent) {
      return numeral(this.price).value();
    }
    return 0;
  }

  get totalStr() {
    return numeral(this.total).format();
  }

  get createAt() {
    return new Moment(get(this.entity, "created_at"));
  }

  get createAtStr() {
    return this.createAt.format || "";
  }

  get createAtWithAgo() {
    return this.createAt.formatWithAgo || "";
  }

  get expiredAtOrigin() {
    return get(this.entity, "expired_at", "");
  }

  get expiredAt() {
    return new Moment(get(this.entity, "expired_at"));
  }

  get expiredAtStr() {
    return this.expiredAt.format || "";
  }

  get isExpired() {
    return this.expiredAt.isExpired;
  }

  get bidOfferType() {
    return get(this.entity, "bid_offer_type", ""); // "bid|offer"
  }

  get isMe() {
    return get(this.entity, "is_me", false);
  }

  get isLease() {
    return this.marketType === MarketMap.lease;
  }

  get isPermanent() {
    return this.marketType === MarketMap.permanent;
  }

  get items() {
    return get(this.entity, "items", []);
  }

  get lessors() {
    const userIds = uniq(map(this.items, (item) => item.user_id)) || [];
    return userIds.length;
  }

  get lessorsStr() {
    if (!this.lessors) {
      return "";
    }
    return `${this.lessors} ${pluralize("lessor", this.lessors)}`;
  }
}

export const generateMarket = (data) => {
  if (data) {
    switch (data.marketable_type) {
      case MarketMap.lease:
        return new MarketEntity(data.marketable);
      case MarketMap.permanent:
        return new PermanentMarketableEntity(data.marketable);
      default:
        return {};
    }
  }
};

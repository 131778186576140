import { omit } from "lodash";

import { BaseApiRepo } from "./baseApiRepo";

export class LicenceApiRepo extends BaseApiRepo {
  constructor() {
    super()
  }

  update(payload) {
    const orderId = payload.orderId
    payload = omit(payload, ["orderId"])
    this.setApi(`/api/v1/orders/${orderId}/licence_number`)
    return this.patch(payload)
  }
}
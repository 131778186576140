import React, { useEffect } from "react";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { links } from "@config/staticLink";

//Hooks
import { useCurrentUser } from "@hooks";
//Common Components
import { Loading } from "@components";

const HomePage = () => {
  const history = useHistory();
  const currentUserStatus = useCurrentUser();
  const isAuthenticated = get(currentUserStatus, "isAuthenticated", false);
  const href = window.location.href;

  useEffect(() => {
    if (isAuthenticated) {
      history.push && history.push("/dashboard");
    } else {
      window.location.href = `${links.sign_in}?redirect_to=${href}`;
    }
  }, [isAuthenticated]);
  return <Loading size="lg" />;
};
export default HomePage; 

import { MARKET_STATISTICS_SUCCESS, MARKET_STATISTICS_FAILURE } from "./action";

export const marketStatisticsStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case MARKET_STATISTICS_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case MARKET_STATISTICS_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TextareaAutosize from "react-textarea-autosize";

export default class TextField extends Component {
  constructor(props) {
    super(props);
  }

  contentMarkLeft = (symbol, symbolClass) => (
    <div className="gs-form-group-input-mark-left">
      <span className={symbolClass}>{symbol}</span>
    </div>
  );

  contentMarkRight = (symbol, symbolClass) => (
    <div className="gs-form-group-input-mark-right">
      <span className={symbolClass}>{symbol}</span>
    </div>
  );

  renderTips = (tips) => {
    if (!tips) {
      return null;
    }
    return <div className="gs-form-group-input-tips">{tips}</div>;
  };

  renderError = (error) => {
    if (!error) {
      return null;
    }
    return <div className="mt-2 error text-left">{error}</div>;
  };

  renderLabelSub = (labelSub) => {
    return labelSub ? <span className="sub-r">{labelSub}</span> : null;
  };

  render() {
    const {
      type,
      bsSize,
      label,
      labelSub,
      id,
      name,
      value,
      disabled,
      readOnly,
      error,
      placeholder,
      onChange,
      onBlur,
      symbol,
      rightSymbol,
      step,
      align,
      rows,
      tips,
      className,
      labelClassName,
      wrapperClassName,
      symbolClass,
      fontSize,
      onFocus,
    } = this.props;

    const wrapperClass = classnames(
      "gs-form-group",
      `${bsSize === "default" ? "" : " gs-form-group-" + bsSize}`,
      wrapperClassName,
      { "gs-form-group-checkbox": type === "checkbox" }
    );

    const inputClass = classnames(`text-${align} text-base`, className);
    const labelClass = classnames(
      "flex items-center justify-between",
      labelClassName
    );

    const classNameWrapper = classnames("flex rounded-md shadow-sm relative", {
      "mt-1": label,
    });
    const classNameInput = classnames(
      "form-input form-input-md block w-full flex-1 text-sm leading-5 focus:shadow-none",
      className,
      {
        "border-red-300 text-fish-red placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
          error,
        "rounded-l-none ": symbol,
        "rounded-r-none ": rightSymbol,
      }
    );
    return (
      <div
        className={classnames("form-element mb-4", wrapperClass)}
        style={{ marginBottom: 16 }}
      >
        {label && (
          <label
            htmlFor={id}
            className={classnames(
              "label block text-sm font-bold leading-5 mb-2",
              labelClass
            )}
          >
            {label}
            {this.renderLabelSub(labelSub)}
          </label>
        )}
        <div className={classNameWrapper}>
          {symbol && (
            <div
              className={classnames(
                "inline-flex items-center px-3 rounded-l-md border border-r-0 border-blue-gray-300 bg-gray-50 text-blue-gray-500 text-sm",
                symbolClass
              )}
            >
              {symbol}
            </div>
          )}

          {type === "textarea" ? (
            <TextareaAutosize
              minRows={3}
              maxRows={50}
              name={name}
              value={value}
              type={type}
              id={id}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
              className={classNameInput}
              rows={rows}
              step={step}
              autoComplete="off"
              readOnly={readOnly}
              style={{ fontSize: fontSize }}
            />
          ) : (
            <input
              name={name}
              value={value}
              type={type}
              id={id}
              placeholder={placeholder}
              invalid={`${!!error}`}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
              className={classNameInput}
              readOnly={readOnly}
              style={{ fontSize: fontSize }}
              autoComplete="off"
            />
          )}
          {rightSymbol && (
            <div
              className={classnames(
                "inline-flex items-center px-3 rounded-r-md border border-l-0 border-blue-gray-300 bg-gray-50 text-blue-gray-500 text-sm",
                symbolClass
              )}
            >
              {rightSymbol}
            </div>
          )}
        </div>
        {this.renderError(error)}
        {this.renderTips(tips)}
      </div>
    );
  }
}

TextField.propTypes = {
  type: PropTypes.string, //type can be things like text,email,password,textarea
  bsSize: PropTypes.string, //lg,sm
  label: PropTypes.string,
  labelSub: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  symbol: PropTypes.string,
  rightSymbol: PropTypes.string,
  align: PropTypes.string,
};

TextField.defaultProps = {
  type: "text",
  bsSize: "lg",
  label: "",
  labelSub: "",
  id: "",
  name: "",
  value: "",
  disabled: false,
  readOnly: false,
  error: "",
  placeholder: "",
  onChange: () => {},
  onBlur: () => {},
  wrapperClassName: "",
  symbol: "",
  rightSymbol: "",
  align: "left",
};

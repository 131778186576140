import React from "react";
import PropTypes from "prop-types";

/**
 * import common components
 */
import { Modal, useModal } from "@components";

const BidHisotryModal = ({ isOpen, toggleModal, body }) => {
  const [modalProps] = useModal(
    {
      title: <div className="">Bids list</div>,
      content: body,
      showClose: true,
      onClosed: () => {
        typeof toggleModal === "function" && toggleModal();
      },
      size: "xl",
      isVertical: true,
      contentAlign: "left",
      bodyNoScroll: true, // modal显示 body不可滚动
      absoluteHead: true, // 头部固定显示
    },
    isOpen,
  );
  return <Modal {...modalProps} />;
};

BidHisotryModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
};

BidHisotryModal.defaultProps = {
  isOpen: false,
  toggleModal: () => {},
  body: <div></div>,
};

export default BidHisotryModal;

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

/**
 * Core
 */
import { BidderStatus } from "@core";
import BidMessage from "./_bid_message";

const MaxBid = ({ data, className, children }) => {
  const isCompleted = get(data, "status.isCompleted", false);
  const bidderStatus = get(data, "bidderStatus", "");
  const notJoin = bidderStatus === BidderStatus.not_join;
  const message = get(data, "show.bidder.message", "");
  const isSeller = get(data, "show.seller", false);

  if (isCompleted && notJoin) {
    return null;
  }

  return (
    <div
      className={cx("rounded-lg shadow relative overflow-auto", className, {
        "lg:hidden": !message || isSeller,
      })}
    >
      <div className="space-y-4 lg:space-y-0">
        <BidMessage data={data} />
        {children ? (
          <div
            className={cx("px-6 pb-6 lg:px-0 lg:pb-0", {
              "pt-6": !message || isSeller,
            })}
          >
            {children}
          </div>
        ) : null}
      </div>
    </div>
  );
};

MaxBid.propTypes = {
  data: PropTypes.object,
};

MaxBid.defaultProps = {
  data: {},
};

export default MaxBid;

import React from "react";
/**
 * import lib tools
 */
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

/**
 * import local tools & components
 */
import { Moment } from "@core";
import { useInvoiceShow } from "@hooks";
import { orderStateMap } from "@config/enum";
import Layout from "@views/layout";
import { Button, Loading, Table } from "@components";

/**
 * [Page] invoice
 */
const Invoice = (props) => {
  const { match } = props;
  const id = get(match, "params.id");

  const { data: invoice, loading: loadingShow } = useInvoiceShow(id);
  const order = invoice.order || {};
  const services = invoice.services || {};
  const isAuction = get(order, "origin_type", "") === "Auction";

  const renderAmount = (item) => {
    const unitsStr = get(item, "quantity.units");
    const amountStr = get(item, "quantity.amount");
    if (!isEmpty(unitsStr) && !isEmpty(amountStr)) {
      return (
        <React.Fragment>
          <div>{amountStr}</div>
          <div className="text-blue-gray-500 text-sm">{unitsStr}</div>
        </React.Fragment>
      );
    }
    if (!isEmpty(unitsStr)) {
      return <div>{unitsStr}</div>;
    }
    if (!isEmpty(amountStr)) {
      return <div>{amountStr}</div>;
    }

    // const arr = get(item, "units", "").split(" ");
    // const num = arr[0] || "";
    // const unitName = arr[1] || "";
    // const unit = toggleUnitTextV2(num, unitName);
    // return `${num} ${unit}`;
  };

  const renderBank = () => {
    // lease由于使用 wallet 付款, 暂时先隐藏， auction 显示
    if (!isAuction) {
      return null;
    }
    const bank = invoice.bank;
    if (!invoice.show_bank) {
      return null;
    }

    return (
      <div>
        <div className="font-bold mb-2">Payment notes</div>
        <div className="font-medium text-sm leading-tight mb-5">
          Bank details below:
        </div>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="text-sm text-blue-gray-600 pr-5 py-1">
                  Account Name
                </div>
              </td>
              <td>
                <div className="font-medium">
                  {get(bank, "account_name", "")}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-sm text-blue-gray-600 pr-5 py-1">BSB</div>
              </td>
              <td>
                <div className="font-medium">{get(bank, "bsb", "")}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-sm text-blue-gray-600 pr-5 py-1">
                  Account Number
                </div>
              </td>
              <td>
                <div className="font-medium">{get(bank, "acc", "")}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderContent = () => {
    if (loadingShow) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <div className="md:flex justify-between flex-row-reverse md:mb-12 pb-6">
          <div className="invoice-basic py-6 md:py-0 md:w-1/4">
            <div className="md:pt-10">
              <div className="flex justify-between">
                <div className="text-sm text-blue-gray-600">Invoice Number</div>
                <div className="font-medium text-right">
                  {get(invoice, "number")}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm text-blue-gray-600">Invoice Date</div>
                <div className="font-medium text-right">
                  {new Moment(invoice.created_at).formatDate}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm text-blue-gray-600">Due Date</div>
                <div className="font-medium text-right">
                  {isAuction
                    ? "2 business days"
                    : new Moment(invoice.due_date).formatDate}
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-users flex-1">
            <div className="text-sm font-medium text-blue-gray-600 flex md:pt-10">
              <div className="flex-1 pr-6 md:pr-8 lg:pr-16">
                <div className="font-semibold">Seller</div>
                <div className="font-semibold text-blue-gray-800">
                  {get(invoice, "seller.company_name", "")}
                </div>
                <div>
                  <div>
                    {compact([
                      get(invoice, "seller.postal_address", ""),
                      get(invoice, "seller.state", ""),
                      get(invoice, "seller.post_code", ""),
                    ]).join(", ")}
                  </div>
                  <div>
                    +{get(invoice, "seller.phone_country_code", "")}{" "}
                    {get(invoice, "seller.phone_number", "")}
                  </div>
                  <div>ABN: {get(invoice, "seller.abn", "-")}</div>
                </div>
              </div>
              <div className="flex-1 md:pr-8 lg:pr-16">
                <div className="font-semibold">Buyer</div>
                <div className="font-semibold text-blue-gray-800">
                  {get(invoice, "buyer.company_name", "")}
                </div>
                <div>
                  <div>
                    {compact([
                      get(invoice, "buyer.postal_address", ""),
                      get(invoice, "buyer.state", ""),
                      get(invoice, "buyer.post_code", ""),
                    ]).join(", ")}
                  </div>
                  <div>
                    +{get(invoice, "buyer.phone_country_code", "")}{" "}
                    {get(invoice, "buyer.phone_number", "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div>
            <Table className="table money-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Amount</th>
                  <th className="text-right">Price</th>
                  <th className="text-right">Item Total</th>
                </tr>
              </thead>
              <tbody className="whitespace-no-wrap">
                {(order.lines || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="font-medium whitespace-normal">
                          {get(item, "items.title", "")}
                        </div>
                        {get(item, "items.fishing_season") ? (
                          <div className="text-sm font-medium text-blue-gray-600">
                            {get(item, "items.fishing_season")}
                          </div>
                        ) : null}
                      </td>
                      <td>{renderAmount(item)}</td>
                      <td className="text-right">{get(item, "price", "")}</td>
                      <td className="text-right">
                        {get(item, "item_total", "")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div>
              <div className="bg-gray-50 mt-1 py-4 px-3 border-t border-blue-gray-600">
                <div className="md:w-1/2 ml-auto flex ">
                  <div className="flex-1 font-normal md:pl-3">Subtotal</div>
                  <div className="font-bold">
                    {get(
                      order,
                      "sub_total_price.humanized_money_with_symbol",
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 ml-auto">
                <div className="flex items-center justify-between pt-1">
                  <div className="pl-3">GST</div>
                  <div className="font-medium w-32 py-3 px-3 bg-gray-50 text-right">
                    {get(
                      order,
                      "gst_price.abs_humanized_money_with_symbol",
                      ""
                    )}
                  </div>
                </div>
                {invoice.user_as === "seller" ? null : (
                  <React.Fragment>
                    <div className="flex items-center justify-between pt-1">
                      <div className="pl-3">Service Fee</div>
                      <div className="font-medium w-32 py-3 px-3 bg-gray-50 text-right">
                        {get(
                          services,
                          "total_including_gst.abs_humanized_money_with_symbol"
                        )}
                      </div>
                    </div>
                    {!isAuction && (
                      <div className="flex items-center justify-between pt-1">
                        <div className="pl-3">
                          Card Processing Fee (Inc. GST)
                        </div>
                        <div className="font-medium w-32 py-3 px-3 bg-gray-50 text-right">
                          {get(
                            order,
                            "card_process_fee.abs_humanized_money_with_symbol",
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                <hr className="border-blue-gray-600 my-1" />

                <div className="flex items-center justify-between pt-1">
                  <div className="font-semibold pl-3">Total</div>
                  <div className="font-bold py-3 px-3 text-right text-xl">
                    {invoice.user_as === "seller"
                      ? get(
                          order,
                          "total_including_gst.abs_humanized_money_with_symbol",
                          ""
                        )
                      : get(
                          order,
                          "net_payout.abs_humanized_money_with_symbol",
                          ""
                        )}
                  </div>
                </div>
                {invoice.user_as === "seller" ? null : (
                  <div className="hidden">
                    <hr className="border-blue-gray-600 my-1" />
                    <div className="flex items-center justify-between pt-3">
                      <div className="font-semibold pl-3">
                        Paid with credit card
                      </div>
                      <div className="font-bold text-right pr-3 text-xl">
                        {get(invoice, "payment.state", "") ===
                        orderStateMap.completed
                          ? "$0.00"
                          : get(
                              order,
                              "net_payout.abs_humanized_money_with_symbol",
                              ""
                            )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-3">
                      <div className="font-semibold pl-3">
                        Outstanding balance
                      </div>
                      <div className="font-bold text-right pr-3 text-xl">
                        {get(invoice, "payment.state", "") ===
                        orderStateMap.completed
                          ? get(
                              order,
                              "net_payout.abs_humanized_money_with_symbol",
                              ""
                            )
                          : "$0.00"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {renderBank()}
      </React.Fragment>
    );
  };

  return (
    <Layout>
      <div className="bg-gray-50">
        <div className="container page-pb">
          <div className="flex justify-between items-center py-4 md:pt-12 md:pb-10 px-5 md:px-0 hidden-print">
            <div></div>
            <div>
              <Button
                onClick={() => {
                  window.print();
                }}
                size="xl"
                color="white"
                textColor="gray-900"
                className="hover:border-blue-gray-200 hover:bg-gray-200"
              >
                Print
              </Button>
            </div>
          </div>

          <div
            className="bg-white md:rounded-lg p-5 md:px-20 md:pt-12 md:pb-24"
            style={{ minHeight: 1220 }}
          >
            <div>
              <h2 className="uppercase">Tax Invoice</h2>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Invoice;

export const PERMANENT_PURCHASE = "permanent/purchase";
export const PERMANENT_PURCHASE_REQUEST = `${PERMANENT_PURCHASE}_request`;
export const PERMANENT_PURCHASE_SUCCESS = `${PERMANENT_PURCHASE}_success`;
export const PERMANENT_PURCHASE_FAILURE = `${PERMANENT_PURCHASE}_failure`;
export const PERMANENT_PURCHASE_RESET = `${PERMANENT_PURCHASE}_reset`;

export const permanentPurchaseAction = {
  request: (payload) => {
    return {
      type: PERMANENT_PURCHASE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_PURCHASE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_PURCHASE_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: PERMANENT_PURCHASE_RESET,
    }
  },
};

import {
  WALLET_TOPUP_LOADING,
  WALLET_TOPUP_SUCCESS,
  WALLET_TOPUP_FAILED,
  WALLET_TOPUP_RECEIVED,
} from "./topupAction";

export function walletTopupStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_TOPUP_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_TOPUP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      });

    case WALLET_TOPUP_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: {},
        error: action.error,
        justFinished: true,
      });

    case WALLET_TOPUP_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: {},
  error: null,
  justFinished: false,
};

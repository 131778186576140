import { takeLatest, call, put } from "redux-saga/effects";

import { InvoiceApiUsecase } from "@core";
import { INVOICE_CREATE_REQUEST, invoiceCreateAction } from "./action";

// invoice create
export function* watchInvoiceCreatePostV2() {
  yield takeLatest(INVOICE_CREATE_REQUEST, invoiceCreatePost);
}

function* invoiceCreatePost(action) {
  try {
    const usecase = new InvoiceApiUsecase();
    const res = yield call([usecase, "create"], action.payload);
    yield put(invoiceCreateAction.success(res.data));
  } catch (err) {
    yield put(invoiceCreateAction.failure(err));
  }
}

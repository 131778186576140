import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionBidAllListUsecase } from "@core";

import {
  auctionBidAllListAction,
  AUCTION_BID_ALL_LIST,
} from "@redux/modules_v2/auctions/bidAllList/action";

//Hooks
import { useCurrentUser } from "@hooks";

/**
 * Fetch auction list hooks
 * @param {*} param
 */
export const useAuctionBidAllList = (id) => {
  const dispatch = useDispatch();

  const fetchBidAllList = (id) => {
    dispatch(auctionBidAllListAction.request({ auction_id: id, limit: 1000 }));
  };

  const auctionBidAllListStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_BID_ALL_LIST, state);
    return Object.assign({}, get(state, "auctionBidAllListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = auctionBidAllListStatus;
  const currentUser = useCurrentUser();
  const usecase = new AuctionBidAllListUsecase(
    get(data, "bids", []),
    get(currentUser, "user", {})
  );

  /**
   * did mount fetch list
   */
  useEffect(() => {
    return () => {
      auctionBidAllListAction.reset();
    };
  }, []);

  useEffect(() => {
    id && fetchBidAllList(id);
  }, [id]);

  return { data: usecase.list, loading, fetchBidAllList };
};

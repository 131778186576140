import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core/index";

import {
  fisheryExistenceAction,
  FISHERY_EXISTENCE,
} from "@redux/modules_v2/fishery/existence/action";

/**
 * Fetch fishery existence hooks
 * @param {*} param
 */
export const useFisheryExistence = (callback) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const fetch = () => {
    dispatch(fisheryExistenceAction.request());
  };

  const fisheryExistenceStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERY_EXISTENCE, state);
    return Object.assign({}, get(state, "fisheryExistenceStatus", {}), {
      loading: selector.loading,
    });
  });

  // /**
  //  * did mount fetch
  //  */
  // useEffect(() => {
  //   isAuthenticated && fetch();
  // }, [isAuthenticated]);

  /**
   *  effect after fetch
   */
  useEffect(() => {
    if (!fisheryExistenceStatus.loading) {
      if (!isEmpty(fisheryExistenceStatus.data)) {
        const fisheryExists = get(
          fisheryExistenceStatus,
          "data.fishery_exists",
          true
        );
        const isFisheryFollowPage = location.pathname === "/fisheries/follow";
        if (!fisheryExists && !isFisheryFollowPage) {
          //history && history.push("/fisheries/follow");
        }
        if (fisheryExists && typeof callback === "function") {
          callback();
        }
        dispatch(fisheryExistenceAction.reset());
      }
    }
  }, [fisheryExistenceStatus.loading]);

  return { fetch, loading: fisheryExistenceStatus.loading };
};

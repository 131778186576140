import forEach from "lodash/forEach";
import find from "lodash/find";
import {
  BIDS_FETCH_LOADING,
  BIDS_FETCH_SUCCESS,
  BIDS_FETCH_FAILED,
  BIDS_ALL_FETCH_SUCCESS
} from "./listAction";

export const bidsListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case BIDS_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;

    case BIDS_FETCH_SUCCESS:
      const list = [];
      forEach(action.data, (item) => {
        let itemOld = find(state.data, (o) => { return o.user_name === item.user_name })
        if(itemOld && item.price != itemOld.price) {
          item.isChange = true;
        }
        list.push(item);
      });
      return {
        loading: false,
        data: list,
        error: null
      };
      break;

    case BIDS_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;

    default:
      return state;
  }
};

export const bidsAllListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case BIDS_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;

    case BIDS_ALL_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case BIDS_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: [],
  error: null
};

export const QUOTA_NEW_SELL_TYPE_CHOOSE = "quota_new/sell_type_choose";
export const QUOTA_NEW_SUPPORT_CHOOSE = "quota_new/support_choose";
export const QUOTA_NEW_PRODUCT_COLLAPSE_TOGGLE = "quota_new/collapse_product_toggle";
export const QUOTA_NEW_SET_CURRENT_PRODUCT = "quota_new/set_current_product";
export const QUOTA_NEW_RESULT_TOGGLE = "quota_new/result_toggle";
export const QUOTA_NEW_RESET_STATE = "quota_new/reset_state";
/**
 * 报价新建页 第一步选择
 * @param {String} type ""|lease|assets
 * @param {String} typeExtend ""|"unencumbered"|"encumbered"
 * @return {Object}
 * {type: QUOTA_NEW_SELL_TYPE_CHOOSE, payload: { sellType: ""|lease|assets }}
 */
export function quotaNewSellTypeChoose(type, typeExtend) {
  return {
    type: QUOTA_NEW_SELL_TYPE_CHOOSE,
    payload: {
      sellType: type,
      sellTypeExtend: typeExtend,
    }
  };
}


/**
 * 报价新建页 第二步选择
 * @param  {String} support ''|onlySelf|withChild|auction
 * @return {Object}
 * {type: QUOTA_NEW_SUPPORT_CHOOSE, payload: { sellType: ''|onlySelf|withChild|auction }}
 */
export function quotaNewSupportChoose(support) {
  return {
    type: QUOTA_NEW_SUPPORT_CHOOSE,
    payload: {
      support: support,
    }
  };
}

/**
 * 报价新建页 第三步，产品 Collapse 状态切换
 * @param {Boolean} status false|true
 * * @return {Object}
 * {type: QUOTA_NEW_PRODUCT_COLLAPSE_TOGGLE, payload: { isOpen: false|true }}
 */
export function quotaNewCollapseProductToggle(status) {
  return {
    type: QUOTA_NEW_PRODUCT_COLLAPSE_TOGGLE,
    payload: {
      isOpen: status,
    }
  };
}

/**
 * 报价新建页 将某一产品存入 store 作为当前产品
 * @param  {Object} product {id: 2, name: "xxxx", product_grades: []}
 * @return {Object}
 */
export function quotaNewSetCurrentProduct(product) {
  return {
    type: QUOTA_NEW_SET_CURRENT_PRODUCT,
    payload: {
      product
    }
  };
}

/**
 * 报价新建页 提交数据后，如果创建成功，显示result提示
 * @param {Boolean} status false|true
 * * @return {Object}
 * {type: QUOTA_NEW_RESULT_TOGGLE, payload: { isOpen: false|true }}
 */
export function quotaNewResultToggle(status) {
  return {
    type: QUOTA_NEW_RESULT_TOGGLE,
    payload: {
      isOpen: status,
    }
  };
}

export function quotaNewResetState() {
  return {
    type: QUOTA_NEW_RESET_STATE,
  };
}

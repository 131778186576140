export const FISHERIES_LISTS = "fisheries";
export const FISHERIES_LISTS_REQUEST = `${FISHERIES_LISTS}_request`;
export const FISHERIES_LISTS_SUCCESS = `${FISHERIES_LISTS}_success`;
export const FISHERIES_LISTS_FAILURE = `${FISHERIES_LISTS}_failure`;
export const FISHERIES_LISTS_UPDATE = `${FISHERIES_LISTS}_update`;

export const fisheriesListAction = {
  request: (payload) => {
    return {
      type: FISHERIES_LISTS_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: FISHERIES_LISTS_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: FISHERIES_LISTS_FAILURE,
      error,
    };
  },
  update: (payload) => {
    return {
      type: FISHERIES_LISTS_UPDATE,
      payload,
    };
  },
};

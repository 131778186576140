const REGISTER_REQUEST = "register/request";
const REGISTER_LOADING = "register/loading";
const REGISTER_SUCCESS = "register/success";
const REGISTER_FAILED = "register/failed";
const REGISTER_RESET = "register/reset";

function registerRequest(payload) {
  return {
    type: REGISTER_REQUEST,
    payload,
  };
}

function registerReset() {
  return {
    type: REGISTER_RESET,
  };
}

export {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_LOADING,
  REGISTER_FAILED,
  REGISTER_RESET,
  registerRequest,
  registerReset,
};

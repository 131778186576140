import React, { Component, useState, useEffect } from "react";
import get from "lodash/get";

/**
 * import lib tools
 */
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

/**
 * import local tools & components
 */
import Notice from "@utils/noticeNew";
import { rechargeDepositRequest } from "@redux/modules/rechargeDeposit/action";
import { Button, Image } from "@components";
import Modal from "./_modal";
import Layout from "@views/layout";

const Deposit = (props) => {
  const [modal, setModal] = useState(false);

  const rechargeDeposit = useSelector((state) =>
    get(state, "rechargeDeposit", {})
  );

  useEffect(() => {
    if (!rechargeDeposit.loading) {
      if (rechargeDeposit.data) {
        Notice.success("Recharge deposit successful.");
        window.location.href = getRedirectTo();
      }
      if (rechargeDeposit.error) {
        Notice.failure(rechargeDeposit.error);
      }
    }
  }, [rechargeDeposit.loading]);

  const getRedirectTo = () => {
    const paramsString = props.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get("redirect_to") || "/dashboard";
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleSubmit = (id) => {
    rechargeDepositRequest({ token: id });
  };

  const rechargeDepositRequest = (payload) => {
    props.rechargeDepositRequest(payload);
  };

  return (
    <Layout>
      <div className="gs-page account-page deposit">
        <div className="container w-md-700">
          <div className="shadow rounded-lg border border-blue-gray-200 overflow-hidden">
            <div className="panel-body px-4 pt-6 pb-10 md:p-10">
              <div className="">
                <h3>
                  Pay your security deposit
                </h3>
                <div className="mt-6 md:mt-10 text-sm font-medium leading-normal text-blue-gray-800">
                  <p>
                    To keep BeachPrice a safe place to buy and sell, all members
                    now need to pay a one-off $250 security deposit before being
                    able to (i) bid on an auction, or (ii) create a new auction.
                  </p>
                  <br />
                  <p>
                    This helps build trust between members and helps ensure that
                    all transactions are genuine.
                  </p>
                  <br />
                  <p>Your deposit will be automatically forfeited if you:</p>
                  <ol>
                    <li>
                      As seller - create an auction but don’t complete a sale
                    </li>
                    <li>
                      As buyer - win an auction but don’t complete payment
                    </li>
                  </ol>
                  <br />
                  <p>
                    You can request a refund of your deposit in full if you do
                    not have any pending transactions. Please check our{" "}
                    <a href="https://help.beachprice.com">Help Center</a> for
                    more information about refunding your deposit.
                  </p>
                </div>
                <div className="mt-8 md:mt-10 clearfix flex">
                  {ImgUrls.map((item, key) => (
                    <div className="money-icon" key={`image-${key}`}>
                      <Image source={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 border-t-1 border-blue-gray-200 py-6 px-4 md:px-10 flex justify-between items-center">
              <div>
                <div className="text-xs font-medium leading-snug text-blue-gray-500">
                  Deposit to be paid
                </div>
                <div className="mt-2 text-xl font-bold leading-tight text-blue-gray-800">
                  $ 250.00
                </div>
              </div>
              <Button
                color="fish-primary"
                size="lg"
                className="hover:bg-fish-primary-700 capitalize"
                onClick={toggleModal}
              >
                Pay My Deposit
              </Button>
            </div>
          </div>
        </div>
        <Modal
          modal={modal}
          toggleModal={toggleModal}
          handleSubmit={handleSubmit}
        />
      </div>
    </Layout>
  );
};

const ImgUrls = ["deposit/visa", "deposit/mc", "deposit/amex"];

function mapStateToProps(state) {
  return {
    rechargeDeposit: state.rechargeDeposit,
  };
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    {},
    bindActionCreators(
      {
        rechargeDepositRequest,
      },
      dispatch
    ),
    {
      dispatch,
    }
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Deposit);

export const OFFER_PRICES_DELETE = "offer_prices/delete";
export const OFFER_PRICES_DELETE_LOADING = "offer_prices/delete_loading";
export const OFFER_PRICES_DELETE_SUCCESS = "offer_prices/delete_success";
export const OFFER_PRICES_DELETE_FAILED = "offer_prices/delete_failed";
export const OFFER_PRICES_DELETE_RECEIVED = "offer_prices/delete_received";

export function offerPriceDelete(id, payload = {}) {
  return {
    type: OFFER_PRICES_DELETE,
    id,
    payload
  };
}
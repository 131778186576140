// xxx
export const CREATE_AC_CONSUMER = "actioncable/create_consumer";
export const REMOVE_AC_CONSUMER = "actioncable/remove_consumer";
export const AC_CONSUMER_CREATED = 'actioncable/consumer_created';

export function createACConsumer(payload) {
  return {
    type: CREATE_AC_CONSUMER,
    payload
  };
}

export function removeACConsumer(payload) {
  return {
    type: REMOVE_AC_CONSUMER,
    payload
  };
}

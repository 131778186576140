/**
 * Component Image
 *
 * @example
 *
 * <Image source="PATH" />
 * <Image source="PATH" className="xxx" />
 */
import React, { Component } from "react";
import classnames from "classnames";
import replace from "lodash/replace";
import startsWith from "lodash/startsWith";

import imgLogo from "@images/logo.svg";
import imgLogoWhite from "@images/logo_white.svg";

//import dashboard images
import imgDashboardEmpty from "@images/dashboard_empty.png";

// Deposit
import imgPayVisa from "@images/deposit/pay_visa.png";
import imgPayMc from "@images/deposit/pay_mc.png";
import imgPayVisaMc from "@images/deposit/pay_visa_mc.png";
import imgPayAmex from "@images/deposit/pay_amex.png";
import imgPayPoli from "@images/deposit/pay_poli.png";
import imgPayStripe from "@images/deposit/pay_stripe.png";

// icons
import imgIconMoney from "@images/icons/money.png";

// empty
import imgEmpty from "@images/empty.png";

// helper
import imgHelperEnitityName from "@images/helper_enitity_name.jpg";

export default class Image extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let className = classnames(this.props.className);
    let { source, imgClassName } = this.props;

    let src;
    if (startsWith(source, "http")) {
      src = source;
    } else {
      src = imgMap[replace(source, " ", "_")];
    }
    return (
      <div className={className}>
        {src ? <img src={src} className={imgClassName || ""} /> : null}
      </div>
    );
  }
}

const imgMap = {
  "dashboard/empty": imgDashboardEmpty,
  "deposit/amex": imgPayAmex,
  "deposit/mc": imgPayMc,
  "deposit/poli": imgPayPoli,
  "deposit/stripe": imgPayStripe,
  "deposit/visa": imgPayVisa,
  "deposit/visa_mc": imgPayVisaMc,
  "helper/enitity_name": imgHelperEnitityName,
  "icons/money": imgIconMoney,
  empty: imgEmpty,
  logo: imgLogo,
  logo_white: imgLogoWhite,
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * lib tools
 */
import get from "lodash/get";
import numeral from "@utils/numeral";

/**
 * import core
 */
import { CalculateAmount } from "@core";

/**
 * Hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * import local tools
 */
import { Loading, Helper } from "@components";
import {
  Input,
  formatFixedNumber,
  formatInt,
  Editor,
  Radio,
} from "@views/components/formik";

const ThirdStep = ({ values, loading, setAuctionTitle }) => {
  const market = get(values, "market.market", {}) || {};
  const price = get(values, "price", "") || 0;
  const quantity = get(values, "quantity", "") || 0;
  const { amount, unitOfPrice, dynaUnitName, kgAmount, auctionTitle } =
    useMarketUnitName(market, quantity);
  useEffect(() => {
    auctionTitle && setAuctionTitle(auctionTitle);
  }, [auctionTitle]);

  const totalAmount = numeral(price)
    .multiply(amount || 0)
    .value();
  const { str } = CalculateAmount.getSalesIncome(totalAmount);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="grid gap-4 grid-cols-1">
      <Radio
        name="fishing_type"
        label="Fishing Type"
        className="lg:grid lg:grid-cols-2 lg:gap-4 space-y-4 lg:space-y-0"
        radioClassName="fish-primary rounded-md border border-blue-gray-300 bg-white p-3 items-center"
        options={[
          {
            label: (
              <>
                Encumbered{" "}
                <Helper content="Encumbered quota means there is a fisher tied to the quota and the purchaser cannot assign their own fisher. If the quota is encumbered, the listing will also say who the fisher is upfront." />
              </>
            ),
            value: "Encumbered",
          },
          {
            label: (
              <>
                Unencumbered{" "}
                <Helper content="Unencumbered means there is no fisher nominated to the quota. This means you can appoint yourself or another licensed fisher to catch the quota." />
              </>
            ),
            value: "Unencumbered",
          },
        ]}
      />
      <Input
        label="Price"
        name="price"
        placeholder="Enter price"
        className="form-input-lg"
        leftSymbol="$"
        rightSymbol={`per ${unitOfPrice}`}
        parse={formatFixedNumber}
      />
      <Input
        label={
          <div className="flex items-center justify-between">
            <span>Unit</span>
            <span>{kgAmount}</span>
          </div>
        }
        name="quantity"
        placeholder="Quota quantity"
        className="form-input-lg"
        rightSymbol={dynaUnitName}
        parse={formatInt}
      />
      <Editor
        name="description"
        label="Description"
        placeholder="Enter any additional information to describe your listing"
      />
    </div>
  );
};

ThirdStep.propTypes = {
  values: PropTypes.object,
  loading: PropTypes.bool,
};

ThirdStep.defaultProps = {
  values: {},
  loading: false,
};

export default ThirdStep;

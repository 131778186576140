import { map, isArray } from "lodash";
import { WalletEntity } from "@core";

export class WalletUsecase {

  /**
   * 构造函数
   * @param {Array<Wallet> | Wallet} data 原始数据(Wallet)，可能是数组或单例对象
   */
  constructor(data) {
    this.entity = data;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new WalletEntity(entity);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new WalletEntity({});
    }
    return new WalletEntity(this.entity);
  }

}

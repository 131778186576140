import {takeEvery, all, call, put} from 'redux-saga/effects'
import {signUpAndSignIn} from '@utils/cognitoAuth'
import setAuthorizationToken from "@utils/setAuthorizationToken";
import get from "lodash/get";

import * as Sentry from '@sentry/browser';

import {
  REGISTER_REQUEST,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILED
} from './action'

import { setCurrentUser } from "../auth/action";

function* watchRegisterRequest() {
  yield takeEvery(REGISTER_REQUEST, registerRequest)
}

function* registerRequest(action) {
  yield put({type: REGISTER_LOADING})
  let {res, err} = yield call(registerCall, action.payload)

  if (err) {
    yield put({type: REGISTER_FAILED, error: err});
    Sentry.captureException(err, { extra: err.message });
    return
  }

  let userData = get(res, "idToken.payload", {});
  userData.token = get(res, "idToken.jwtToken");
  setAuthorizationToken();

  yield all([
    put({type: REGISTER_SUCCESS, data: res}),
    put(setCurrentUser(userData)),
  ]);
}

function registerCall({email, password, invite_code, activity_code, broker_code}) {
  return signUpAndSignIn(email, password, {invite_code, activity_code, broker_code })
    .then(res => { return {res: res} })
    .catch(err => { return {err: err} })
}

export { watchRegisterRequest }
import {
  PERMANENT_PURCHASE_SUCCESS,
  PERMANENT_PURCHASE_FAILURE,
  PERMANENT_PURCHASE_RESET
} from "./action";

export const permanentPurchaseStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PERMANENT_PURCHASE_SUCCESS:
      return {
        data: action.data,
        error: null
      };

    case PERMANENT_PURCHASE_FAILURE:
      return {
        data: null,
        error: action.error
      };
    case PERMANENT_PURCHASE_RESET:
      return initStatus;
      
    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null
};

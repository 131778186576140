import axios from "axios";
import get from "lodash/get";
import { takeLatest, all, takeEvery, call, put } from "redux-saga/effects";
import { setSubmitFailed } from "redux-form";
import {
  AUCTIONS_EDIT_REQUEST,
  AUCTIONS_EDIT_LOADING,
  AUCTIONS_EDIT_SUCCESS,
  AUCTIONS_EDIT_FAILED
} from "./editAction";

import { auctionsAPI } from "@config/api";

// create order
export function* watchAuctionEdit() {
  yield takeEvery(AUCTIONS_EDIT_REQUEST, auctionEdit);
}

export function* auctionEdit(action) {
  yield put({ type: AUCTIONS_EDIT_LOADING });
  const { res, err } = yield call(auctionEditApi, action.payload);
  const status = get(res, "status");
  const codes = [200, 204];
  if (codes.includes(status)) {
    yield put({ type: AUCTIONS_EDIT_SUCCESS, data: "Edit auction succeed" });
    return;
  }
  yield all([
    put({
      type: AUCTIONS_EDIT_FAILED,
      error: "Create auction failed"
    }),
    put(setSubmitFailed("AuctionNewForm", "Create or edit auction failed"))
  ]);
}

/**
 * auctionEditApi
 * @param  {[type]} payload [description]
 * @return {[type]}         [description]
 *
 * @example
 * payload
 * {
 *   auction: {
 *    unit: 2,
 *    category_id: 1,
 *    state: ‘abc’,
 *    price: 13.3,
 *    gov_fee: 13,
 *    expire_at: Time.zone.now.to_s(:iso8601),
 *    fishing_season: ‘2018-2019’,
 *    description: ‘this is description’
 *   }
 * }
 */

function auctionEditApi(payload) {
  return axios
    .patch(auctionsAPI(payload.id), { auction: payload.data })
    .then(res => ({
      res: res
    }))
    .catch(err => ({
      err
    }));
}

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { SellParamsContext } from "@contexts";
import { Switch } from "@headlessui/react";
import cx from "classnames";
import { Input } from "@views/components/formik";

const MySwitch = ({ checked, setCheck }) => {
  return (
    <Switch
      checked={checked}
      onChange={() => {
        setCheck(!checked);
      }}
      className={cx(
        checked ? "bg-fish-primary" : "bg-gray-100",
        "relative inline-flex flex-shrink-0 h-6 switch-w-44 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      )}
    >
      <span
        className={cx(
          checked ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      >
        <span
          className={cx(
            checked
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="bg-white h-3 w-3 text-blue-gray-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={cx(
            checked
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="bg-white h-3 w-3 text-fish-primary"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
};

const CustomTitle = ({ auctionTitle }) => {
  const [checked, setCheck] = useState(false);
  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="text-blue-gray-500 text-sm">
          Your listing will be called:
        </span>
        <div className="flex items-center space-x-2">
          <MySwitch checked={checked} setCheck={setCheck} />
          <span className=" text-sm font-medium">Use custom title</span>
        </div>
      </div>
      <div className="mt-6">
        {checked ? (
          <Input name="title" type="text" />
        ) : (
          <span className="text-xl font-medium">{auctionTitle}</span>
        )}
      </div>
    </div>
  );
};

const Title = ({ step, sellType, auctionTitle }) => {
  const params = useContext(SellParamsContext);
  const originSellType = get(params, "sell_type", "");

  let title = [
    "What are you listing?",
    "Set price and quantity",
    sellType === "exchange"
      ? "Listing successfully created"
      : sellType === "auction"
      ? "Other information"
      : "Confirm your listing",
  ];
  let subTitle = [
    "",
    "",
    sellType === "auction" || sellType === "p2p" ? (
      <CustomTitle auctionTitle={auctionTitle} />
    ) : (
      ""
    ),
  ];

  if (originSellType === "exchange") {
    title = [
      "Create a consigned listing",
      "Set price and quantity",
      "Listing successfully created",
    ];
    subTitle = [
      "You can quickly consign quota to BeachPrice and list them for sale at the price you set.",
      "",
      "",
    ];
  }

  return (
    <div className="pb-3">
      <div className="text-xl font-medium">{title[step - 1]}</div>
      {subTitle[step - 1] ? (
        originSellType === "exchange" ? (
          <div className="text-sm mt-3 text-blue-gray-700">
            {subTitle[step - 1]}
          </div>
        ) : (
          <div className="mt-6 md:mt-10">{subTitle[step - 1]}</div>
        )
      ) : null}
    </div>
  );
};

Title.propTypes = {
  sellType: PropTypes.string,
  step: PropTypes.number,
  auctionTitle: PropTypes.string,
};

Title.defaultProps = {
  sellType: "",
  step: 1,
  auctionTitle: "",
};

export default Title;

import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";


import {
  walletWithdrawalCancelAPI,
} from "@config/api";


import {
  WALLET_WITHDRAWAL_CANCEL_REQUEST,
  walletWithdrawalCancelFailed,
  walletWithdrawalCancelSuccess,
  walletWithdrawalCancelLoading,
} from "./withdrawalCancelAction";

export function* watchWalletWithdrawalCancelPut() {
  yield takeLatest(WALLET_WITHDRAWAL_CANCEL_REQUEST, walletWithdrawalCancelPost);
}

export function* walletWithdrawalCancelPost(action) {

  yield put(walletWithdrawalCancelLoading());

  const { res, err } = yield call(walletWithdrawalCancelApi, action.payload);

  if (err) {
    yield put(walletWithdrawalCancelFailed(err));
    return;
  }
  yield put(walletWithdrawalCancelSuccess(res));
}

export function walletWithdrawalCancelApi(payload) {
  return axios
    .put(walletWithdrawalCancelAPI(payload.id))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


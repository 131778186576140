export const WITHDRAW_SHOW = "withdraws/show";
export const WITHDRAW_SHOW_REQUEST = `${WITHDRAW_SHOW}_request`;
export const WITHDRAW_SHOW_SUCCESS = `${WITHDRAW_SHOW}_success`;
export const WITHDRAW_SHOW_FAILURE = `${WITHDRAW_SHOW}_failure`;

export const withdrawShowAction = {
  request: (payload) => {
    return {
      type: WITHDRAW_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: WITHDRAW_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: WITHDRAW_SHOW_FAILURE,
      error,
    }
  },
};

const CHANGE_EMAIL_EVENT_REQUEST = "change_email_event/request"
const CHANGE_EMAIL_EVENT_LOADING = "change_email_event/loading"
const CHANGE_EMAIL_EVENT_SUCCESS = "change_email_event/success"
const CHANGE_EMAIL_EVENT_FAILED = "change_email_event/failed"

function changeEmailEventRequest(payload) {
  return {
    type: CHANGE_EMAIL_EVENT_REQUEST,
    payload
  }
}

function changeEmailEventLoading() {
  return {
    type: CHANGE_EMAIL_EVENT_LOADING
  }
}

function changeEmailEventSuccess(data) {
  return {
    type: CHANGE_EMAIL_EVENT_SUCCESS,
    data
  }
}

function changEmailEventFailed(error) {
  return {
    type: CHANGE_EMAIL_EVENT_FAILED,
    error
  }
}

export {
  CHANGE_EMAIL_EVENT_REQUEST,
  CHANGE_EMAIL_EVENT_FAILED,
  CHANGE_EMAIL_EVENT_LOADING,
  CHANGE_EMAIL_EVENT_SUCCESS,

  changeEmailEventRequest,
  changeEmailEventLoading,
  changeEmailEventSuccess,
  changEmailEventFailed
}
import {
  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_FETCH_FAILED,
  ORDERS_FETCH_LOADING,
} from "./listAction";

export const orderListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ORDERS_FETCH_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;
    case ORDERS_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case ORDERS_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};

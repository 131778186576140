import {
  OFFER_PRICES_ATTEND_FETCH_REQUEST,
  OFFER_PRICES_ATTEND_FETCH_SUCCESS,
  OFFER_PRICES_ATTEND_FETCH_FAILED,

} from "./attendListAction";
export const offerPriceAttendListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case OFFER_PRICES_ATTEND_FETCH_REQUEST:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;

    case OFFER_PRICES_ATTEND_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    case OFFER_PRICES_ATTEND_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};
import {
  ORDERS_CLOSE_FAILED,
  ORDERS_CLOSE_LOADING,
  ORDERS_CLOSE_SUCCESS,
  ORDERS_CLOSE_RECEIVED,
} from "./closeAction";

const initStatus = {
  loading: false,
  data: {},
  error: null,
  justFinished: false,
};

const closeOrderStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ORDERS_CLOSE_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
        justFinished: false,
      }
    case ORDERS_CLOSE_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      }
    case ORDERS_CLOSE_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
        justFinished: true,
      }
    case ORDERS_CLOSE_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state
  }
}

export { closeOrderStatus }
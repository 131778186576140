import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Link } from "@components";

const Table = ({ data, total, role }) => {
  const isSeller = role === "seller";
  const isBuyer = role === "buyer";

  const renderEmpty = () => {
    return (
      <tr>
        <td colSpan={isSeller ? 12 : isBuyer ? 11 : 9}>
          <div className="p-4 md:p-6 text-center whitespace-normal">
            No statements.
          </div>
        </td>
      </tr>
    );
  };
  // const renderHeader = () => {
  //   return (
  //     <tr>
  //       <td
  //         colSpan={3}
  //         className="px-2 py-4 border border-blue-gray-200 text-center text-sm leading-5 text-white bg-blue-500"
  //       >
  //         Quota
  //       </td>
  //       <td
  //         colSpan={isSeller ? 3 : 2}
  //         className="px-2 py-4 border border-blue-gray-200 text-center text-sm leading-5 text-white bg-blue-500"
  //       >
  //         Quota price
  //       </td>
  //       {isSeller && (
  //         <td
  //           colSpan={3}
  //           className="px-2 py-4 border border-blue-gray-200 text-center text-sm leading-5 text-white bg-blue-500"
  //         >
  //           Service fee
  //         </td>
  //       )}
  //       <td
  //         colSpan={2}
  //         className="px-2 py-4 border border-blue-gray-200 text-center text-sm leading-5 text-white bg-blue-500 whitespace-no-wrap"
  //       >
  //         Final income
  //       </td>
  //     </tr>
  //   );
  // };

  const renderTotal = () => {
    return (
      <tr>
        <td className="px-2 py-4 text-xs border border-blue-gray-200 text-center leading-5 font-medium text-blue-gray-900 bg-gray-50">
          Total
        </td>
        <td className="bg-gray-50" />
        <td className="bg-gray-50" />
        <td className="bg-gray-50" />
        <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
          {get(total, "subtotal", "")}
        </td>
        <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
          {get(total, "subtotalGST", "")}
        </td>
        <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
          {get(total, "subtotalWithGST", "")}
        </td>
        {isSeller && (
          <>
            <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
              {get(total, "serviceFee", "")}
            </td>
            <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
              {get(total, "serviceFeeGST", "")}
            </td>
            <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
              {get(total, "serviceFeeWithGST", "")}
            </td>
          </>
        )}
        {isBuyer && (
          <>
            <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
              {get(total, "cardProcessingFee", "")}
            </td>
            <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
              {get(total, "cardProcessingFeeGST", "")}
            </td>
            {/* <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
              {get(total, "cardProcessingFeeWithGST", "")}
            </td> */}
          </>
        )}
        <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
          {get(total, "totalGST", "")}
        </td>
        <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500 bg-gray-50">
          {get(total, "paidAmount", "")}
        </td>
      </tr>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-8">
          <table className="min-w-full border-collapse border-1 border-blue-gray-200">
            <thead>
              <tr>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  ID
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Quota
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Quantity
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Unit price
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Subtotal price (ex GST)
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  GST
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Total price (incl. GST)
                </th>
                {isSeller && (
                  <>
                    <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                      Service fee (ex GST)
                    </th>
                    <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                      Service fee GST
                    </th>
                    <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                      Total Service fee
                    </th>
                  </>
                )}
                {isBuyer && (
                  <>
                    <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                      Card processing fee (ex GST)
                    </th>
                    <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                      Card processing fee GST
                    </th>
                    {/* <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                      Total Card processing fee (incl GST)
                    </th> */}
                  </>
                )}
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Total GST
                </th>
                <th className="px-2 py-3 bg-gray-50 border border-blue-gray-200 text-center text-xs leading-4 font-medium text-blue-gray-500">
                  Net amount (incl GST)
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-blue-gray-200">
              {isEmpty(data) ? (
                renderEmpty()
              ) : (
                <>
                  {map(data, (item, index) => (
                    <tr key={index}>
                      <td className="px-2 py-4 border border-blue-gray-200 text-xs leading-5 text-blue-gray-500">
                        <Link
                          to={get(item, "show.orderUrl", "")}
                          className="flex items-center text-fish-primary cursor-pointer mb-1"
                        >
                          {`#${get(item, "show.invoiceNumber", "")}`}
                        </Link>
                        <div className="text-blue-gray-500 fs-12">
                          {get(item, "show.createdAt", "")}
                        </div>
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-xs leading-5 text-blue-gray-500">
                        <div className="text-blue-gray-900 mb-1">
                          {get(item, "show.marketName", "")}
                        </div>
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.totalWeight", "")}
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.price", "")}
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.subtotalStr", "")}
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.subtotalGSTStr", "")}
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.subtotalWithGSTStr", "")}
                      </td>
                      {isSeller && (
                        <>
                          <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                            {get(item, "show.serviceFeeStr", "")}
                          </td>
                          <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                            {get(item, "show.serviceFeeGSTStr", "")}
                          </td>
                          <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                            {get(item, "show.serviceFeeWithGSTStr", "")}
                          </td>
                        </>
                      )}
                      {isBuyer && (
                        <>
                          <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                            {get(item, "show.cardProcessingFeeStr", "")}
                          </td>
                          <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                            {get(item, "show.cardProcessingFeeGSTStr", "")}
                          </td>
                          {/* <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                            {get(item, "show.cardProcessingFeeWithGSTStr", "")}
                          </td> */}
                        </>
                      )}
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.totalGSTStr", "")}
                      </td>
                      <td className="px-2 py-4 border border-blue-gray-200 text-right text-xs leading-5 text-blue-gray-500">
                        {get(item, "show.paidAmountStr", "")}
                      </td>
                    </tr>
                  ))}
                  {renderTotal()}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  role: PropTypes.string,
  total: PropTypes.object,
};

export default Table;

export const PERMANENT_BID = "permanent/bid";
export const PERMANENT_BID_REQUEST = `${PERMANENT_BID}_request`;
export const PERMANENT_BID_SUCCESS = `${PERMANENT_BID}_success`;
export const PERMANENT_BID_FAILURE = `${PERMANENT_BID}_failure`;
export const PERMANENT_BID_RESET = `${PERMANENT_BID}_reset`;

export const permanentBidAction = {
  request: payload => {
    return {
      type: PERMANENT_BID_REQUEST,
      payload
    };
  },
  success: data => {
    return {
      type: PERMANENT_BID_SUCCESS,
      data
    };
  },
  failure: error => {
    return {
      type: PERMANENT_BID_FAILURE,
      error
    };
  },
  reset: () => {
    return { type: PERMANENT_BID_RESET };
  }
};

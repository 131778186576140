export const AUCTION_SHOW = "auction/show";
export const AUCTION_SHOW_REQUEST = `${AUCTION_SHOW}_request`;
export const AUCTION_SHOW_SUCCESS = `${AUCTION_SHOW}_success`;
export const AUCTION_SHOW_FAILURE = `${AUCTION_SHOW}_failure`;
export const AUCTION_SHOW_UPDATE = `${AUCTION_SHOW}_update`;

export const auctionShowAction = {
  request: (payload) => {
    return {
      type: AUCTION_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: AUCTION_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: AUCTION_SHOW_FAILURE,
      error,
    }
  },
  update: (payload) => {
    return {
      type: AUCTION_SHOW_UPDATE,
      payload,
    }
  },
};

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, PermanentListUsecase } from "@core";

import {
  permanentListAction,
  PERMANENT_LIST,
} from "@redux/modules_v2/permanent/list/action";

/**
 * Fetch permanent list hooks
 * @param {*} param {fishery_id:1 , quotum_id:2}
 */
export const useFetchPermanentList = (param = {}) => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(permanentListAction.request(param));
  };

  const permanentListStatus = useSelector((state) => {
    const selector = new LoadingSelector(PERMANENT_LIST, state);
    return Object.assign({}, get(state, "permanentListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = permanentListStatus;
  const usecase = new PermanentListUsecase(data);

  /**
   * did mount fetch list
   */
  useEffect(() => {
    param.quotum_id && fetchList();
  }, [param.quotum_id]);

  return [usecase.listByGroup, loading];
};

import React from "react";
import map from "lodash/map";

const Transactions = ({ data, isOpen }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="border-t border-blue-gray-200">
      <table className="table no-padding-table md:padding-table no-head-bg md:head-bg min-w-full">
        <tbody className="">
          {map(data, (item, index) => {
            return (
              <tr key={index}>
                <td className="text-sm space-x-4" style={{ borderBottom: 0 }}>
                  <span className="text-xxs text-blue-gray-500 font-base">
                    {item.createAtWithAgo}
                  </span>
                  <span className="text-xxs text-blue-gray-500 font-base">
                    {item.amountStr.split(" ").join("")}
                  </span>
                </td>
                <td
                  className="whitespace-no-wrap text-right align-middle"
                  style={{ borderBottom: 0 }}
                >
                  <span className="text-xxs font-medium text-fish-dark">
                    {item.priceStrWithKg}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Transactions.propTypes = {};

export default Transactions;

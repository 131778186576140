export const FISHERY_ACTIVITY = "fishery/activity";
export const FISHERY_ACTIVITY_REQUEST = `${FISHERY_ACTIVITY}_request`;
export const FISHERY_ACTIVITY_SUCCESS = `${FISHERY_ACTIVITY}_success`;
export const FISHERY_ACTIVITY_FAILURE = `${FISHERY_ACTIVITY}_failure`;

export const fisheryActivityAction = {
  request: (payload) => {
    return {
      type: FISHERY_ACTIVITY_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_ACTIVITY_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_ACTIVITY_FAILURE,
      error,
    }
  },
};

import { useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionListUsecase } from "@core";

import {
  auctionFinalChancesListAction,
  AUCTION_FINAL_CHANCES_LIST,
} from "@redux/modules_v2/auctions/finalChancesList/action";

//Hooks
import { useCurrentUser, useDeepEffect } from "@hooks";

/**
 * Fetch auction final chance list hooks
 * @param {*} param
 */
export const useAuctionFinalChancesList = (params) => {
  const dispatch = useDispatch();

  const fetchList = (params) => {
    dispatch(auctionFinalChancesListAction.request(params));
  };

  const auctionFinalChancesListStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_FINAL_CHANCES_LIST, state);
    return Object.assign({}, get(state, "auctionFinalChancesListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { loading } = auctionFinalChancesListStatus;
  let data = [];
  if (isArray(get(auctionFinalChancesListStatus, "data.entries"))) {
    data = get(auctionFinalChancesListStatus, "data.entries", []);
  } else {
    data = auctionFinalChancesListStatus.data;
  }

  const currentUser = useCurrentUser();
  const usecase = new AuctionListUsecase(data, get(currentUser, "user", {}));

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    fetchList(params);
  }, [params]);

  return {
    data: usecase.list,
    fetchList,
    loading,
  };
};

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import capitalize from "lodash/capitalize";

import {
  cartPricesAPI,
} from "@config/api";

import {
  OFFER_PRICES_SHOW_FETCH_REQUEST,
  OFFER_PRICES_SHOW_FETCH_LOADING,
  OFFER_PRICES_SHOW_FETCH_SUCCESS,
  OFFER_PRICES_SHOW_FETCH_FAILED
} from "./showAction";


//fetch offer price show
export function* watchOfferPriceShowFetch() {
  yield takeEvery(OFFER_PRICES_SHOW_FETCH_REQUEST, offerPriceShowFetch);
}

export function* offerPriceShowFetch(action) {
  yield put({ type: OFFER_PRICES_SHOW_FETCH_LOADING });

  const { res, err } = yield call(offerPriceShowFetchApi, action.payload);
  if (!res) {
    yield put({
      type: OFFER_PRICES_SHOW_FETCH_FAILED,
      error: "Get offer price data failed."
    });
    return;
  }
  yield put({ type: OFFER_PRICES_SHOW_FETCH_SUCCESS, data: res });
}

function offerPriceShowFetchApi(payload) {
  return axios
    .get(cartPricesAPI('buy', payload.id))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}
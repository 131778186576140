export const BID_LIST = "bids/list";
export const BID_LIST_REQUEST = `${BID_LIST}_request`;
export const BID_LIST_SUCCESS = `${BID_LIST}_success`;
export const BID_LIST_FAILURE = `${BID_LIST}_failure`;

export const bidListAction = {
  request: (payload) => {
    return {
      type: BID_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: BID_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: BID_LIST_FAILURE,
      error,
    }
  },
};

import { takeLatest, call, put } from "redux-saga/effects";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_FINAL_CHANCES_LIST_REQUEST, auctionFinalChancesListAction } from "./action";

//Fetch auction list
export function* watchAuctionFinalChancesListFetch() {
  yield takeLatest(AUCTION_FINAL_CHANCES_LIST_REQUEST, auctionFinalChancesListFetch);
}

function* auctionFinalChancesListFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "finalChancesList"], action.payload);
    yield put(auctionFinalChancesListAction.success(res.data));
  } catch (err) {
    yield put(auctionFinalChancesListAction.failure(err));
  }
}
 
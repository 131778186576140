import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import Card from "./_card";
import Price from "./_price";

const CardIntro = ({ data }) => {
  const reservePriceStr = get(data, "show.reservePrice.str", " - ");
  return (
    <Card title="Your max bid did not meet the reserve" isGray >
      <p className="text-sm text-blue-gray-600">
        Unfortunately your max bid did not meet the seller’s reserve price which
        was set at {reservePriceStr}.
      </p>
      <Price data={data} className="mt-6 md:mt-8" />
    </Card>
  );
};

CardIntro.propTypes = {};

export default CardIntro;

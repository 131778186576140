import React, { Component } from "react";
import PropTypes from "prop-types";

export default class RadioField extends Component {
  constructor(props) {
    super(props);
  }

  renderSingleOption(option = {}, index, props = {}) {
    const { value, label, ...rest } = option;

    let id = `${props.name}-${index}`;

    return (
      <div className="flex items-center cursor-pointer" key={index}>
        <input
          id={id}
          name={props.name}
          type="radio"
          className="flex-shrink-0 focus:ring-blue-500 h-4 w-4 text-fish-primary border-blue-gray-300"
          invalid={!!props.error}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          value={option.value}
          checked={props.value === value}
        />
        <label
          htmlFor={id}
          className="block text-sm font-bold"
        >
          {option.label}
        </label>
      </div>
    );
  }

  render() {
    const {
      bsSize,
      label,
      id,
      name,
      disabled,
      error,
      onChange,
      onBlur,
      options,
      align,
    } = this.props;

    return (
      <div className="form-element mb-4">
        {label && (
          <label className="mb-2 label block text-sm font-medium leading-5 text-blue-gray-700">
            {label}
          </label>
        )}
        <div className={`gs-form-group-input mt-2 ${error ? "invalid" : ""}`}>
          <div className="flex space-x-2 flex-wrap">
            {options.length > 0
              ? options.map((option, index) =>
                  this.renderSingleOption(option, index, this.props)
                )
              : null}
          </div>
          {error && <div className="mt-2 error">{error}</div>}
        </div>
      </div>
    );
  }
}

RadioField.propTypes = {
  bsSize: PropTypes.string, //lg,sm
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  align: PropTypes.string,
};

RadioField.defaultProps = {
  bsSize: "default",
  label: "",
  id: "",
  name: "",
  disabled: false,
  error: "",
  onChange: () => {},
  onBlur: () => {},
  options: [],
  align: "left",
  inline: true,
};

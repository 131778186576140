import { FISHERY_QUOTA_MARKETS_SUCCESS, FISHERY_QUOTA_MARKETS_FAILURE } from "./action";

export const fisheryQuotaMarketsStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case FISHERY_QUOTA_MARKETS_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case FISHERY_QUOTA_MARKETS_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null,
};

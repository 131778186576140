import { map, isArray } from "lodash";
import { OrderEntity } from "@core";

export class OrderUsecase {

  /**
   * 构造函数
   * @param {Array<Order> | Order} data 原始数据(Order)，可能是数组或单例对象
   */
  constructor(data, currentUserId) {
    this.entity = data;
    this.currentUserId = currentUserId;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new OrderEntity(entity, this.currentUserId);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new OrderEntity({});
    }
    return new OrderEntity(this.entity, this.currentUserId);
  }

}

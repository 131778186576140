import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import mapKeys from "lodash/mapKeys";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import PropTypes from "prop-types";

const Attr = ({ data, auctionId }) => {
  const reservePriceValue = get(data, "show.reservePrice.value", "");
  const reservePriceLabel = get(data, "show.reservePrice.label", "");
  const isPermanent = get(data, "show.isPermanent", false);
  const additionalInformation = get(data, "show.additionalInformation", {});

  let attrs = [
    { label: "Auction", value: `#${auctionId}` },
    {
      label: "Reserve",
      value: reservePriceValue ? reservePriceLabel : "No Reserve",
    },
    { label: "Quantity", value: get(data, "show.totalWeight", "") },
    { label: "Ends", value: get(data, "show.expiredAtStr", "") },
  ];

  if (!isPermanent) {
    attrs.splice(1, 0, {
      label: "Type",
      value: get(data, "show.fishingType", ""),
    });
    attrs.push({
      label: "Fishing Season",
      value: get(data, "show.season", ""),
    });
  }
  if (!isEmpty(additionalInformation)) {
    mapKeys(additionalInformation, (value, key) => {
      let label = replace(key, "_", " ");
      attrs.push({
        label,
        value,
      });
    });
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-8 gap-y-3 lg:gap-6">
      {map(attrs, ({ label, value }, index) => (
        <div
          className="col-span-1 lg:col-span-2 flex items-center justify-between lg:block"
          key={index}
        >
          <div className="text-sm font-normal leading-normal text-blue-gray-500">
            {label}
          </div>
          <div className="text-sm leading-normal font-medium text-blue-gray-800 lg:mt-1">
            {value}
          </div>
        </div>
      ))}
    </div>
  );
};

Attr.propTypes = {};

export default Attr;

import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import get from "lodash/get";

/** import core */
import { fishnetClientNumber } from "@core";

/**
 * import local tools
 */
import { Input } from "@views/components/formik";
import { Button } from "@components";

const Apply = ({ data, onSubmit, loading }) => {
  const transferEntityName =
    get(data, "quotum_transfer_apply.transfer_entity_name", "") || "";
  const offerId = get(data, "offerId", "");
  //form
  const initialValues = {
    transfer_entity_name: transferEntityName,
  };

  const schema = Yup.object().shape({
    transfer_entity_name: Yup.string().required("This field is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit({
          offerId,
          transfer_entity_name: values.transfer_entity_name,
        });
      }}
    >
      <div>
        <div className="mb-4 -mt-6">
          Transfer <strong>{get(data, "amountStr", "")}</strong> quota to the
          Beachprice fishing license #{fishnetClientNumber}.
          <a
            href="https://fishnet.fisheries.qld.gov.au/Content/Secure/SignIn.aspx?ReturnUrl=%2fContent%2fSecure%2fHome.aspx"
            target="_blank"
            rel="noopener noreferrer"
            className="text-fish-primary underline pl-1"
          >
            Go to FishNet now
          </a>
        </div>
        <Form className="space-y-6">
          <Input
            name="transfer_entity_name"
            label="My Fishnet client number"
            placeholder="Client name"
            className="form-input-lg"
            classNameWrapper="text-left"
          />
          <Button
            size="xl"
            full
            disabled={loading}
            loading={loading}
            type="submit"
          >
            Confirm quota has been transferred
          </Button>
        </Form>
      </div>
    </Formik>
  );
};

Apply.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
Apply.defaultProps = {};

export default Apply;

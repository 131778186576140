import get from "lodash/get";
import moment from "moment";

import { PriceBaseEntity } from "./baseEntity";

/**
 * [Entity] Statement
 * {
	"id": 225295539,
	"invoice_number": "201909184495",
	"buyer": "dexterdeng@gmail.com",
	"seller": "dexterdeng@yeah.net",
	"units": 30.0,
	"total_weight": "300.0",
	"created_at": "2020-09-30T10:46:54.162+10:00",
	"price": {
		"price": "1.00",
		"price_with_symbol": "$1.00"
	},
	"subtotal_with_gst": {
		"price": "330.00",
		"price_with_symbol": "$330.00"
	},
	"service_fee_with_gst": {
		"price": "0.00",
		"price_with_symbol": "$0.00"
	},
	"total_price": {
		"price": "330.00",
		"price_with_symbol": "$330.00"
	}
}
 */

export class StatementEntity {
  constructor(props) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id", "");
  }

  get orderUrl() {
    return `/orders/${this.id}`;
  }

  get invoiceNumber() {
    return get(this.data, "invoice_number", "");
  }

  get buyer() {
    return get(this.data, "buyer", "");
  }

  get seller() {
    return get(this.data, "seller", "");
  }

  get units() {
    return get(this.data, "total_weight", "");
  }

  get totalWeight() {
    return get(this.data, "total_weight", "");
  }

  get createdAt() {
    return moment(get(this.data, "created_at", "")).format(
      "DD MMM YYYY HH:mmA"
    );
  }

  get marketName() {
    return get(this.data, "market_name", "");
  }

  get subtotalGST() {
    let res = 0;
    const subtotalPrice = this.subtotal("value") || 0;
    const subtotalWithGstPrice = this.subtotalWithGST();
    if (subtotalWithGstPrice.subtract) {
      res = subtotalWithGstPrice.subtract(subtotalPrice).value();
    }
    return res;
  }

  get subtotalGSTStr() {
    let res = 0;
    const subtotalPrice = this.subtotal("value") || 0;
    const subtotalWithGstPrice = this.subtotalWithGST();
    if (subtotalWithGstPrice.subtract) {
      res = subtotalWithGstPrice.subtract(subtotalPrice).format();
    }
    return res;
  }

  get serviceFeeGST() {
    let res = 0;
    const serviceFee = this.serviceFee("value") || 0;
    const serviceFeeWithGST = this.serviceFeeWithGST();
    if (serviceFeeWithGST.subtract) {
      res = serviceFeeWithGST.subtract(serviceFee).value();
    }
    return res;
  }

  get serviceFeeGSTStr() {
    let res = 0;
    const serviceFee = this.serviceFee("value") || 0;
    const serviceFeeWithGST = this.serviceFeeWithGST();
    if (serviceFeeWithGST.subtract) {
      res = serviceFeeWithGST.subtract(serviceFee).format();
    }
    return res;
  }

  get totalGST() {
    let res = 0;
    const subtotalGST = this.subtotalGST || 0;
    const serviceFeeGST = this.serviceFeeGST || 0;
    const cardProcessingFeeGST = this.cardProcessingFeeGST("value") || 0;
    res = numeral(subtotalGST).add(serviceFeeGST).add(cardProcessingFeeGST);
    return res.value();
  }

  get totalGSTStr() {
    let res = 0;
    const subtotalGST = this.subtotalGST || 0;
    const serviceFeeGST = this.serviceFeeGST || 0;
    const cardProcessingFeeGST = this.cardProcessingFeeGST("value") || 0;
    res = numeral(subtotalGST).add(serviceFeeGST).add(cardProcessingFeeGST);
    return res.format();
  }

  price(type) {
    const usecase = new PriceBaseEntity(get(this.data, "price", {}));
    return usecase.price(type);
  }

  subtotal(type) {
    const usecase = new PriceBaseEntity(get(this.data, "subtotal", {}));
    return usecase.price(type);
  }

  subtotalWithGST(type) {
    const usecase = new PriceBaseEntity(
      get(this.data, "subtotal_with_gst", {})
    );
    return usecase.price(type);
  }

  serviceFee(type) {
    const usecase = new PriceBaseEntity(get(this.data, "service_fee", {}));
    return usecase.price(type);
  }

  serviceFeeWithGST(type) {
    const usecase = new PriceBaseEntity(
      get(this.data, "service_fee_with_gst", {})
    );
    return usecase.price(type);
  }

  cardProcessingFee(type) {
    const usecase = new PriceBaseEntity(
      get(this.data, "card_processing_fee", {})
    );
    return usecase.price(type);
  }

  cardProcessingFeeGST(type) {
    const usecase = new PriceBaseEntity(
      get(this.data, "card_processing_fee_gst", {})
    );
    return usecase.price(type);
  }

  cardProcessingFeeWithGST(type) {
    const usecase = new PriceBaseEntity(
      get(this.data, "card_processing_fee_with_gst", {})
    );
    return usecase.price(type);
  }

  totalPrice(type) {
    const usecase = new PriceBaseEntity(get(this.data, "total_price", {}));
    return usecase.price(type);
  }

  paidAmount(type) {
    const usecase = new PriceBaseEntity(get(this.data, "paid_amount", {}));
    return usecase.price(type);
  }
}

import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";


export class MarketApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "quota_markets";
  }

  /**
   * Market Api instance list
   * @param {Ojbect} payload { species_id, state_id, category_id, country_id, zone_id }
   */
  list(payload) {
    this.setApi(`${API}/${this.model}`);
    return this.get(payload);
  }

  /**
   * Market Api instance show
   * @param {object} payload { id: 1, ... }
   */
  show(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}`);
    return this.get(omit(payload, "id"));
  }

  // create(payload) {
  //   this.setApi(`${API}/${this.model}`);
  //   return this.post(payload);
  // }

  // update(payload) {
  //   this.setApi(`${API}/${this.model}/${payload.id}`);
  //   return this.patch(omit(payload, "id"));
  // }

  // destroy(payload) {
  //   this.setApi(`${API}/${this.model}/${payload.id}`);
  //   return this.delete();
  // }

  // others(payload) {
  //   ... ...
  // }

  statistics(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/statistics`);
    return this.get(omit(payload, "id"));
  }

  balance(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}/balance`);
    return this.get(omit(payload, "id"));
  }
}

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Button, TimeCountDown } from "@components";

import Card from "./_card";

const CardComplete = ({ data }) => {
  const finalPriceExpiredAt = get(data, "show.finalPriceExpiredAt", "");
  const finalBidEnd = get(data, "status.finalBidEnd", false);
  const isFinalChanceSet = get(data, "status.isFinalChanceSet", false);
  const isFailedByFinalChanceRejected = get(
    data,
    "status.isFailedByFinalChanceRejected",
    false
  );
  const isSubmitted = !finalBidEnd || isFinalChanceSet;

  return (
    <Card
      title={
        isSubmitted
          ? "Your Final Offer has been submitted"
          : isFailedByFinalChanceRejected
          ? "The Seller did not accept your Final Offer"
          : "Your Final Offer has expired"
      }
    >
      <div className="space-y-6 md:space-y-8">
        <p className="text-sm text-blue-gray-500">
          {isSubmitted ? (
            <div>
              The Seller has been sent your Final Offer, and will now have{" "}
              <TimeCountDown
                date={finalPriceExpiredAt}
                now={() => {
                  return window.moment.now();
                }}
                theme="gray"
                themeClosed="gray"
                timeClosed={12 * 3600 * 1000}
                onComplete={() => {
                  window.location.reload();
                }}
                inline
              />{" "}
              to decide to accept or refuse your offer. You’ll be notified
              by email and SMS when the Seller has responded.
            </div>
          ) : isFailedByFinalChanceRejected ? (
            "The Seller has reviewed your Final Offer bid, but has chosen to not accept it. The auction has now been passed in as a non completed listing."
          ) : (
            "Sorry you did not submit your Final Offer in time. "
          )}
        </p>
      </div>
    </Card>
  )
};

CardComplete.propTypes = {};

export default CardComplete;

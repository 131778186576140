import { AccountApiRepo } from "@core";

export class AccountApiUsecase {
  constructor() {
    this.repo = new AccountApiRepo();
  }

  bids(payload) {
    return this.repo.bids(payload);
  }

  offers(payload) {
    return this.repo.offers(payload);
  }

  recend(payload) {
    return this.repo.recend(payload);
  }

  openOrders(payload) {
    return this.repo.openOrders(payload);
  }

  profileEvent(payload = {}) {
    return this.repo.profileEvent(payload);
  }

  profileRegister(payload = {}) {
    return this.repo.profileRegister(payload);
  }

  permanentListing() {
    return this.repo.permanentListing();
  }

  permanentShow(payload) {
    return this.repo.permanentShow(payload);
  }

  permanentUpdate(payload) {
    return this.repo.permanentUpdate(payload);
  }

  permanentDestroy(payload) {
    return this.repo.permanentDestroy(payload);
  }

  bankShow() {
    return this.repo.bankShow();
  }

  bankEdit(payload) {
    return this.repo.bankEdit(payload);
  }

  statementListing(payload) {
    return this.repo.statementListing(payload);
  }

  quotumBalances() {
    return this.repo.quotumBalances();
  }

  quotumInventories(payload) {
    return this.repo.quotumInventories(payload);
  }

  createdAuctions() {
    return this.repo.createdAuctions();
  }

  involvedAuctions() {
    return this.repo.involvedAuctions();
  }

  notificationList(payload) {
    return this.repo.notificationList(payload);
  }
}

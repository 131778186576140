
export const ORDERS_SHOW_FETCH_REQUEST = "orders/fetch_show_request";
export const ORDERS_SHOW_FETCH_LOADING = "orders/fetch_show_loading";
export const ORDERS_SHOW_FETCH_SUCCESS = "orders/fetch_show_success";
export const ORDERS_SHOW_FETCH_FAILED = "orders/fetch_show_failed";


export function fetchOrderShow(orderId) {
  return {
    type: ORDERS_SHOW_FETCH_REQUEST,
    id: orderId,
  };
}

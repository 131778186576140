import { useEffect, useState, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, MarketUsecase } from "@core/index";

import {
  marketListAction,
  MARKET_LIST
} from "@redux/modules_v2/markets/list/action";
/**
 * Fetch fishery quota market list hooks
 * @param {*} param
 */
export const useFisheryMarketList = (id) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const fetchList = useCallback(() => {
    dispatch(marketListAction.request());
  }, []);

  const marketListStatus = useSelector((state) => {
    const selector = new LoadingSelector(MARKET_LIST, state);
    return Object.assign({}, get(state, "marketListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = marketListStatus;

  useEffect(() => {
    if (!loading) {
      if (data) {
        const usecase = new MarketUsecase(data);
        setList(usecase.fisheryMarket);
      }
    } else {
      setList([]);
    }
  }, [data, loading]);

  /**
   * did mount fetch list
   */
  useEffect(() => {
    fetchList()
  }, []);

  return [list, loading];
};

import { TransferApplyApiRepo } from "@core";

export class TransferApplyApiUsecase {

  constructor() {
    this.repo = new TransferApplyApiRepo();
  }

  // list(payload) {
  //   return this.repo.list(payload);
  // }

  // show(payload) {
  //   return this.repo.show(payload);
  // }

  create(payload) {
    return this.repo.create(payload);
  }

  // update(payload) {
  //   return this.repo.update(payload);
  // }

}
import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Form, Input } from "@components/formik";
import { Button } from "@components";

const InitForgotForm = ({ onSubmit, loading, renderResetCode }) => {
  const schema = Yup.object().shape({
    code: Yup.string().required("Required"),
    password: Yup.string()
      .min(5, "Password length should be greater than or equal to 6")
      .required("Required"),
  });
  return (
    <Formik
      initialValues={{
        code: "",
        password: "",
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Form>
        <input name="fake_code" className="visually-hidden" type="text" />
        <input
          name="fake_password"
          type="password"
          className="visually-hidden" 
        />
        <div className="">
          <label className="label block mb-2 text-sm leading-5 text-fish-dark font-normal">Code</label>
          <div className="flex mb-5">
            <Input
              name="code"
              className="rounded-none rounded-l-md border-blue-gray-200"
              labelFontWeight="normal"
              classNameWrapper="flex-1 mt-0"
              placeholder="Enter the verification code"
              required
            />
            {renderResetCode && renderResetCode()}
          </div>
        </div>
        <Input
          name="password"
          type="password"
          placeholder="Enter new password"
          label="Password" 
          className="relative focus:z-10 border-blue-gray-200" 
          labelFontWeight="normal"
          classNameWrapper="-mt-px relative"
          required
        />
        <div className="mt-6">
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            trailingIcon={
              <svg className="mt-px pt-px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8H2M9.33333 3.33334L14 8L9.33333 3.33334ZM14 8L9.33333 12.6667L14 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            full
            color="gray-800"
            className="hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline h-42"
          >
            Confirm
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

InitForgotForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  renderResetCode: PropTypes.func,
};

InitForgotForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  renderResetCode: () => {},
};

export default InitForgotForm;

export const WALLET_WITHDRAWAL_SHOW_REQUEST = "account_wallet_withdrawal_show/request";
export const WALLET_WITHDRAWAL_SHOW_LOADING = "account_wallet_withdrawal_show/loading";
export const WALLET_WITHDRAWAL_SHOW_SUCCESS = "account_wallet_withdrawal_show/success";
export const WALLET_WITHDRAWAL_SHOW_FAILED = "account_wallet_withdrawal_show/failed";


export function walletWithdrawalShowRequest() {
  return {
    type: WALLET_WITHDRAWAL_SHOW_REQUEST,
  };
}
const INITIALIZE_REQUEST = 'forgot_password/initialize_request'
const INITIALIZE_LOADING = 'forgot_password/initialize_loading'
const INITIALIZE_SUCCESS = 'forgot_password/initialize_success'
const INITIALIZE_FAILED = 'forgot_password/initialize_failed'

function initRequest(payload) {
  return {
    type: INITIALIZE_REQUEST,
    payload
  }
}

function initLoading() {
  return {
    type: INITIALIZE_LOADING
  }
}

function initSuccess(data) {
  return {
    type: INITIALIZE_SUCCESS,
    data
  }
}

function initFailed(error) {
  return {
    type: INITIALIZE_FAILED,
    error
  }
}

export {
  INITIALIZE_FAILED,
  INITIALIZE_SUCCESS,
  INITIALIZE_LOADING,
  INITIALIZE_REQUEST,

  initFailed,
  initLoading,
  initRequest,
  initSuccess
}


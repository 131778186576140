import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

//Hooks
import { useParams } from "@hooks";

//Local components
import { Breadcrumb } from "@components";
import Layout from "@views/layout";
import Sidebar from "@views/account/sidebar";
import Sales from "./_sales";
import Purchase from "./_purchase";

const Orders = () => {
  const [params] = useParams("");
  const type = get(params, "type", "");
  const title =
    type === "sales"
      ? "Sales Orders"
      : type === "purchase"
      ? "Purchase Orders"
      : "";
  return (
    <Layout name="account">
      <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar className="hidden" current={type} />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 px-4 lg:px-0 mt-6 lg:mt-0 mb-8 lg:mb-16">
            <Breadcrumb className="mb-6 md:mb-8" back />
            <div className="text-2xl lg:hidden font-bold leading-tight text-blue-gray-900 mb-6">
              {title}
            </div>
            <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow">
              <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-blue-gray-900">
                      {title}
                    </h3>
                  </div>
                </div>
              </div>
              {type === "sales" ? (
                <Sales />
              ) : type === "purchase" ? (
                <Purchase />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Orders.propTypes = {
  props: PropTypes.object,
};

export default Orders;

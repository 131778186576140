import { FISHERY_QUOTA_LIST_SUCCESS, FISHERY_QUOTA_LIST_FAILURE } from "./action";

export const fisheryQuotaListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case FISHERY_QUOTA_LIST_SUCCESS:
      return {
        data: action.data,
        error: null
      };

    case FISHERY_QUOTA_LIST_FAILURE:
      return {
        data: [],
        error: action.error
      };

    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null
};

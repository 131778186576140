import { takeLatest, call, put } from "redux-saga/effects";

import { ListingApiUsecase } from "@core";
import { LISTING_QUOTA_TRANSFERS_LIST_REQUEST, listingQuotaTransfersListAction } from "./action";

// order list
export function* watchListingQuotaTransfersListFetch() {
  yield takeLatest(LISTING_QUOTA_TRANSFERS_LIST_REQUEST, listingQuotaTransfersListFetch);
}

function* listingQuotaTransfersListFetch(action) {
  try {
    const usecase = new ListingApiUsecase();
    const res = yield call([usecase, "getQuotaTransfers"], action.payload);
    yield put(listingQuotaTransfersListAction.success(res.data));
  } catch (err) {
    yield put(listingQuotaTransfersListAction.failure(err));
  }
}

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

/**
 * import hooks
 */
import { useModal } from "@hooks";

import { Button } from "@components";
import { ModalWrapper } from "@views/common";

import Card from "./_card";

const CardOption = ({ data, children, handleSubmit, loading }) => {
  const reservePrice = get(data, "show.reservePrice.value", 0);
  const reservePriceStr = get(data, "show.reservePrice.str", " - ");
  const hasFinalBid = get(data, "status.hasFinalBid", false);
  const { isOpen, toggleModal } = useModal();
  return (
    <>
      <Card title="Make a Final Offer">
        {hasFinalBid ? (
          <>
            <div className="p-6 bg-blue-gray-100 rounded-lg">
              <p className="text-sm text-blue-gray-600">
                As the highest bidder, you have the exclusive opportunity to
                make a<span className="px-1 font-bold">Final Offer</span> with
                one of the options below.
              </p>
              <div className="space-y-6 flex flex-col mt-6">
                <Button
                  size="xl"
                  textColor="white"
                  className="bg-green-600 flex-1 justify-center px-0"
                  disabled={loading}
                  loading={loading}
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <span className="text-sm hidden md:block">{`Instantly accept reserve price of ${reservePriceStr}`}</span>
                  <span className="text-sm md:hidden">{`Accept price of ${reservePriceStr}`}</span>
                </Button>
              </div>
            </div>
            <div className="relative flex items-center justify-center -mt-4">
              <div className="w-10 h-10 relative flex justify-center items-center text-sm leading-5 rounded-full bg-white">
                <span className="text-sm px-3 text-blue-gray-700">Or</span>
              </div>
            </div>
            <div className="p-6 bg-blue-gray-100 rounded-lg -mt-4">
              <div className="mt-2">
                <p className="text-sm text-blue-gray-600">
                  Submit a price lower than the Reserve and give the Seller an
                  opportunity to consider and accept your price.
                </p>
              </div>
              <div className="mt-2 px-4 py-2 text-xxs text-blue-gray-600 rounded-lg bg-blue-gray-200">
                If you choose not to increase your Final Offer from your winning
                bid, you will still need to resubmit this as a Final Offer
                below.
              </div>

              {children}
            </div>
          </>
        ) : (
          <>
            <p className="text-sm text-blue-gray-600">
              As the highest bidder, you have the exclusive opportunity to make
              a<span className="px-1 font-bold">Final Offer</span> with one of
              the options below.
            </p>
            <div className="mt-6">
              <Button
                size="xl"
                color="white"
                textColor="gray-900"
                className="bg-white border-blue-gray-200 hover:border-blue-gray-300 focus:outline-none focus:border-blue-gray-300"
                full
                outline
                onClick={() => {
                  window.location.reload();
                }}
              >
                <span
                  style={{
                    backgroundImage:
                      "linear-gradient(224.94deg, #F56D5B -1.51%, #EA239A 101.89%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  We’re preparing your Final Offer. Check back in a few mins.
                </span>
              </Button>
            </div>
          </>
        )}
      </Card>
      <ModalWrapper
        title="Accept reserve price"
        isOpen={isOpen}
        toggleModal={() => {
          typeof toggleModal === "function" && toggleModal(false);
        }}
        size="lg"
        contentAlign="left"
      >
        <div className="">
          <div className="py-4 text-sm text-blue-gray-500">
            You can accept the seller's reserve price and instantly win this
            listing.
          </div>
          <div className="grid grid-cols-2 gap-y-3 md:gap-6 mb-6 md:mb-10">
            <div className="col-span-1">
              <div className="text-sm font-normal leading-normal text-blue-gray-500">
                Seller’s reserve price
              </div>
              <div className="text-sm leading-normal font-medium text-blue-gray-800 md:mt-1">
                {reservePriceStr}
              </div>
            </div>
          </div>

          <Button
            size="xl"
            color="gray-900"
            textColor="white"
            className="bg-gray-900"
            full
            type="submit"
            onClick={() => {
              handleSubmit({ price: reservePrice });
            }}
            loading={loading}
            disabled={loading}
          >
            Accept reserve price
          </Button>
        </div>
      </ModalWrapper>
    </>
  );
};

CardOption.propTypes = {};

export default CardOption;

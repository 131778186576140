export const ACCOUNT_OFFERS = "account/offers";
export const ACCOUNT_OFFERS_REQUEST = `${ACCOUNT_OFFERS}_request`;
export const ACCOUNT_OFFERS_SUCCESS = `${ACCOUNT_OFFERS}_success`;
export const ACCOUNT_OFFERS_FAILURE = `${ACCOUNT_OFFERS}_failure`;

export const accountOffersAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_OFFERS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ACCOUNT_OFFERS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ACCOUNT_OFFERS_FAILURE,
      error,
    }
  },
};

export function takeArrayBySum(list, key, sum = 0) {
  const result = [];
  (list || []).forEach(function(item) {
    if (sum >= item[key]) {
      // item.volume = item[key] * 30;
      result.push(item);
    }
    if (sum < item[key] && sum > 0) {
      const obj = Object.assign({}, item);
      obj[key] = sum;
      // obj.volume = sum * 30;
      result.push(obj);
    }
    sum -= item[key];
  });

  return result;
}

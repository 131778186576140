/**
 * Notice
 *
 * @example

  Notice.success("This is content")
  Notice.success("This is content", "Some title")

  Notice.failure("This is content")
  Notice.failure("This is content", "Some title")

 */

import classnames from "classnames";


const showTime = 5;

const Notice = () => {};

Notice.success = (msg = "", title = "") => {
  const id = "Notice_" + new Date().getTime();
  addNotice(msg, title, id, "success");
};

Notice.failure = (msg = "", title = "") => {
  const id = "Notice_" + new Date().getTime();
  addNotice(msg, title, id, "failure");
};

function addNotice(msg = "", title = "", id, kind) {
  const dom = document.createElement("div");
  const domWrapper = document.createElement("div");
  const domTitle = document.createElement("div");
  const domContent = document.createElement("div");

  const wrapperClass = classnames("shadow-lg-tw border-l-4 py-3-tw px-5-tw", {
    "bg-green-100 border-green-500": kind === "success",
    "bg-red-100 border-red-500": kind === "failure",
  });

  const contentClass = classnames("font-medium", {
    "text-green-500": kind === "success",
    "text-red-500": kind === "failure",
  });

  domContent.className = contentClass;
  domContent.innerHTML = msg;
  domWrapper.id = id;
  domWrapper.className = wrapperClass;
  domWrapper.style.textAlign = "left";
  domWrapper.style.maxWidth = "900px";
  domWrapper.style.margin = "0 auto";
  domWrapper.style.transform = "translateY(-100%)";
  domWrapper.style.transition = "all 0.3s ease-in-out";

  if (title) {
    const titleClass = classnames("text-lg font-bold", {
      "text-green-600": kind === "success",
      "text-red-600": kind === "failure",
    });
    domTitle.className = titleClass;
    domTitle.innerHTML = title;
    domWrapper.appendChild(domTitle);
  }
  domWrapper.appendChild(domContent);

  dom.appendChild(domWrapper);
  addClass(dom, "notice-wrapper flex items-center");

  document.body.appendChild(dom);
  setTimeout(() => {
    domWrapper.style.transform = "translateY(0)";
  }, 10);

  setTimeout(function() {
    domWrapper.style.transform = "translateY(-100%)";
    setTimeout(function() {
      removeNotice(dom);
    }, 300);
  }, showTime * 1000);
}

function removeNotice(dom) {
  document.body.removeChild(dom);
}

function hasClass(obj, cls) {
  return obj.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

export function addClass(obj, cls) {
  if (!hasClass(obj, cls)) obj.className += " " + cls;
}

export function removeClass(obj, cls) {
  if (hasClass(obj, cls)) {
    var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    obj.className = obj.className.replace(reg, " ");
  }
}

function toggleClass(obj, cls) {
  if (hasClass(obj, cls)) {
    removeClass(obj, cls);
  } else {
    addClass(obj, cls);
  }
}

export default Notice;

import {
  AUCTION_INSTANT_BUY_SUCCESS,
  AUCTION_INSTANT_BUY_FAILURE,
  AUCTION_INSTANT_BUY_RESET,
} from "./action";

export const auctionInstantBuyStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTION_INSTANT_BUY_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case AUCTION_INSTANT_BUY_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case AUCTION_INSTANT_BUY_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  ASKING_PRICES_FETCH_REQUEST,
  ASKING_PRICES_FETCH_SUCCESS,
  ASKING_PRICES_FETCH_LOADING,
  ASKING_PRICES_FETCH_FAILED,

  ASKING_PRICES_ANIMATE_START,
  ASKING_PRICES_ANIMATE_END,

} from "./listAction";

import {
  askingPricesAPI,
  askingPricesWithoutProductAPI,
} from "@config/api";


//fetch asking prices list
export function* watchAskingPricesFetch() {
  yield takeEvery(ASKING_PRICES_FETCH_REQUEST, askingPricesFetch);
}

export function* askingPricesFetch(action) {
  yield put({ type: ASKING_PRICES_FETCH_LOADING });
  const { err, res } = yield call(askingPricesFetchApi, action.payload, action.params);
  if (!res) {
    yield put({
      type: ASKING_PRICES_FETCH_FAILED,
      error: "Get asking prices list failed."
    });
    return;
  }
  yield put({ type: ASKING_PRICES_FETCH_SUCCESS, data: res });
  // if(action.payload.showAnimate) {
  //   yield call(delay, 600);
  //   yield put({ type: ASKING_PRICES_ANIMATE_START });
  //   yield call(delay, 5 * 1000);
  //   yield put({ type: ASKING_PRICES_ANIMATE_END });
  // }
}

export function askingPricesFetchApi(payload, params) {
  let api = payload.productGradeId ?
    askingPricesAPI(payload.productGradeId, '',params) :
    askingPricesWithoutProductAPI(payload.id);
  return axios
    .get(api)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

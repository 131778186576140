const WALLET_TOPUP_REQUEST = "account_wallet_topup/request";
const WALLET_TOPUP_LOADING = "account_wallet_topup/loading";
const WALLET_TOPUP_SUCCESS = "account_wallet_topup/success";
const WALLET_TOPUP_FAILED = "account_wallet_topup/failed";
const WALLET_TOPUP_RECEIVED = "account_wallet_topup/received";

function walletTopupRequest(payMethod, payload) {
  return {
    type: WALLET_TOPUP_REQUEST,
    payMethod,
    payload,
  };
}

function walletTopupLoading() {
  return {
    type: WALLET_TOPUP_LOADING,
  };
}

function walletTopupSuccess(data) {
  return {
    type: WALLET_TOPUP_SUCCESS,
    data,
  };
}

function walletTopupFailed(error) {
  return {
    type: WALLET_TOPUP_FAILED,
    error,
  };
}

function walletTopupReceived() {
  return {
    type: WALLET_TOPUP_RECEIVED,
  };
}

export {
  WALLET_TOPUP_REQUEST,
  WALLET_TOPUP_LOADING,
  WALLET_TOPUP_SUCCESS,
  WALLET_TOPUP_FAILED,
  WALLET_TOPUP_RECEIVED,
  walletTopupRequest,
  walletTopupLoading,
  walletTopupSuccess,
  walletTopupFailed,
  walletTopupReceived,
};

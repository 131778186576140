import React from "react";

/**
 * import lib tools & components
 */
import { get, padStart } from "lodash";

/**
 * import local utils & components
 */
import { Button } from "@components";
import { ModalWrapper } from "@views/common";

/**
 * [Component] TopupModal
 */
const TopupModal = (props) => {
  const { isOpen, toggleStatus, onClosed, profile } = props;

  const toggle = () => {
    toggleStatus(false);
  };

  const renderResult = () => {
    return (
      <div>
        <h2 className="mb-8">
          Bank Transfer
        </h2>
        <div className="text-center mb-6 max-w-sm mx-auto">
          You’ve requested to deposit your wallet by making offline payment.{" "}
          <br />
          Please complete the payment within 24 hours, or this request would be
          canceled automatically.
        </div>
        <div className="px-2 mb-6 max-w-sm mx-auto">
          <div className="bg-orange-100 border-l border-r border-t border-b border-orange-300 rounded-lg-tw p-4-tw text-center">
            <div className="text-sm font-medium leading-tight">
              Please Include the following reference number in your bank payment
            </div>
            <div className="text-xl font-bold pt-3-tw">
              {get(profile, "user.id")
                ? `BP${padStart(get(profile, "user.id"), 4, "0")}`
                : null}
            </div>
          </div>
        </div>
        <div className="flex justify-center max-w-sm mx-auto">
          <Button
            size="xl"
            color="fish-primary"
            textColor="white"
            className="hover:bg-fish-primary-700"
            onClick={() => {
                typeof toggle ==="function" && toggle(); 
                typeof onClosed === "function" && onClosed();
              }
            }
            full
          >
            <div className="px-5-tw">Confirm</div>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      size="md"
      isOpen={isOpen}
      toggleModal={toggleStatus}
      onClosed={onClosed}
    >
      {renderResult()}
    </ModalWrapper>
  );
};

export default TopupModal;

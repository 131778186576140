import React from "react";

/**
 * Components
 */
import Form from "./form";

/**
 * [Page] Account 修改密码
 */
const ResetPassword = () => {
  return (
    <div>
      <Form />
    </div>
  );
};

export default ResetPassword;

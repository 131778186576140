import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import get from "lodash/get";
import cx from "classnames";

import { Loading, Link } from "@components";

const QuotaOffers = ({ data, loading, fisheryId, marketId }) => {
  if (loading) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className="">
      <div className="flex items-center justify-between mt-6 md:mt-0 pb-0 md:pb-4 pt-4 border-t md:border-t-0 md:border-b border-blue-gray-100">
        <div className="text-sm font-medium">All instant listings</div>
        <Link
          to={`/sell?market_type=lease&fishery_id=${fisheryId}&sell_type=exchange&quota_market_id=${marketId}`}
          className="font-medium text-fish-primary text-xxs"
        >
          Create a new listing
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table
                className="min-w-full mt-4"
                style={{ borderCollapse: "separate", borderSpacing: "0 8px" }}
              >
                <tbody className="bg-white">
                  {map(data, (item, index) => {
                    const market = get(item, "market", {});
                    return (
                      <tr key={index}>
                        {market && market.isSaleByUnit ? (
                          <td className="px-4 md:px-5 py-3 whitespace-no-wrap text-xxs text-blue-gray-500 bg-gray-50 rounded-l-lg">
                            {item.unitsStr}
                          </td>
                        ) : null}
                        <td className={cx("px-4 md:px-5 py-3 whitespace-no-wrap text-xxs text-blue-gray-500 bg-gray-50", {"rounded-l-lg": !market.isSaleByUnit})}>
                          <div className="flex items-center space-x-3">
                            <span>{`${item.amountStr} listed by ${item.lessorsStr}`}</span>
                            {item.isMe && (
                              <span className="text-fish-primary">(Me)</span>
                            )}
                          </div>
                        </td>
                        <td className="px-4 md:px-5 py-3 whitespace-no-wrap text-xxs font-medium flex justify-end text-blue-gray-900 bg-gray-50 rounded-r-lg">
                          {item.priceStrWithKg}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuotaOffers.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default QuotaOffers;

import React from "react";
import get from "lodash/get";
import cx from "classnames";

const Header = ({ data, state, dispatch }) => {
  return (
    <div className="pt-4 md:pt-8 flex items-center">
      <div className="mr-6 w-16 h-16 md:w-20 md:h-20 flex items-center rounded-xl bg-white overflow-hidden shadow-sm p-1">
        <img
          className="w-full h-full object-cover"
          src={get(data, "logoImage", "")}
          alt={get(data, "title", "")}
        />
      </div>
      <h1 className="text-xl md:text-2xl leading-snug text-blue-gray-900 flex-1">
        <span className="font-normal">{get(data, "state", "")}</span>
        <br />
        {get(data, "category", "")}
      </h1>
      <nav className="flex space-x-4 item-items bg-white rounded-lg p-1 shadow flex-shrink-0 hidden">
        <button
          className={cx(
            "md:px-16 py-1 md:py-2 text-xl rounded-lg font-medium flex-1 text-center",
            {
              "bg-gray-800 text-white ": state.activeTab === "tab1",
              "text-blue-gray-700 hover:text-fish-primary":
                state.activeTab !== "tab1",
            }
          )}
          onClick={() => {
            dispatch({ type: "update", value: { activeTab: "tab1" } });
          }}
        >
          Buy
        </button>
        <button
          className={cx(
            "md:px-16 py-1 md:py-2 text-xl rounded-lg font-medium flex-1 text-center",
            {
              "bg-gray-800 text-white ": state.activeTab === "tab2",
              "text-blue-gray-700 hover:text-fish-primary":
                state.activeTab !== "tab2",
            }
          )}
          onClick={() => {
            dispatch({ type: "update", value: { activeTab: "tab2" } });
          }}
        >
          Sell
        </button>
      </nav>
    </div>
  );
};

Header.defaultProps = {
  data: {},
  state: {},
  dispatch: () => {},
};

export default Header;

import { useState, useEffect } from "react";
/**
 * import lib tools
 */
import { submit as submitForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { get } from "lodash";

/**
 * import local tools & config & redux
 */
import { LoadingSelector } from "@core";
import numeral from "@utils/numeral";
import Notice from "@utils/noticeNew";
import {
  withdrawCreateAction,
  WITHDRAW_CREATE,
} from "@redux/modules_v2/withdraws/create/action";

/**
 * [hooks] 钱包提现申请 wallet withdraw
 */
export const useWithdrawCreate = (balance, success) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [money, setMoney] = useState("");
  const moneyTotal = numeral(Number(money)).value();

  const onMoneyChange = value => {
    setMoney(value);
  };

  const dispatchSubmit = () => {
    if (!(moneyTotal > 0)) {
      Notice.failure("Please enter the withdraw amount.");
      return;
    }

    if (moneyTotal > balance) {
      Notice.failure(
        "The withdrawal amount exceeds the amount available in your wallet"
      );
      return;
    }

    dispatch(submitForm("WithdrawalForm"));
  };

  const submit = values => {
    const postData = Object.assign({}, values, {
      amount: moneyTotal,
    });
    dispatch(withdrawCreateAction.request({ ...postData }));
  };

  const generateFormValues = (data, profile) => {
    const rememberAccount = get(profile, "user.remember_account", false);

    return {
      bank_bsb: rememberAccount ? get(data, "bankBsb", "") : "",
      account_number: rememberAccount ? get(data, "accountNumber", "") : "",
      account_name: rememberAccount ? get(data, "accountName", "") : "",
      remember_account: rememberAccount,
    };
  };

  const withdrawCreateStatus = useSelector(state => {
    const selector = new LoadingSelector(WITHDRAW_CREATE, state);
    return Object.assign({}, get(state, "withdrawCreateStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = withdrawCreateStatus.loading;

  /**
   * watch withdraw status
   */
  useEffect(() => {
    if (withdrawCreateStatus.justFinished) {
      if (withdrawCreateStatus.error) {
        Notice.failure(
          get(withdrawCreateStatus, "error", "Withdraw error")
        );
      } else {
        success && success();
      }
      dispatch(withdrawCreateAction.received());
    }
  }, [withdrawCreateStatus.justFinished]);

  return {
    money,
    moneyTotal,
    generateFormValues,

    onMoneyChange,
    submit,
    loading,
    dispatchSubmit,
  };
};

import axios from "axios";

export class BaseApiRepo {
  constructor(api = "", config = {}) {
    this.api = api;
    this.config = config;
  }

  setApi(api) {
    this.api = api;
  }

  /**
   * Api instance get method
   *
   * @param {object|null} payload
   *
   * @example
   * const api = new BaseApiRepo();
   * const payload = {id: 1, first_name: "yourname"}
   * api.setApi("/some_url/users");
   * api.get()        // => GET "/some_url/users"
   * api.get(payload) // => GET "/some_url/users?id=1&first_name=yourname"
   */
  get(payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.api, { params: payload }, this.config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  /**
   * Api instance post method
   *
   * @param {object|null} payload
   *
   * @example
   * const api = new BaseApiRepo();
   * const payload = { first_name: "yourname", last_name: "lastname" }
   * api.setApi("/some_url/users");
   * api.post(payload) // => POST "/some_url/users" body is { first_name: "yourname", last_name: "lastname" }
   */
  post(payload, config) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.api, payload, config || this.config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  /**
   * Api instance patch method
   *
   * @param {object|null} payload
   *
   * @example
   * const api = new BaseApiRepo();
   * const payload = { first_name: "yourname", last_name: "lastname" }
   * api.setApi("/some_url/users/1");
   * api.patch(payload) // => PATCH "/some_url/users/1" body is { first_name: "yourname", last_name: "lastname" }
   */
  patch(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(this.api, payload, this.config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  /**
   * Api instance put method
   *
   * @param {object|null} payload
   *
   * @example
   * const api = new BaseApiRepo();
   * const payload = { first_name: "yourname", last_name: "lastname" }
   * api.setApi("/some_url/users/1");
   * api.put(payload) // => PUT "/some_url/users/1" body is { first_name: "yourname", last_name: "lastname" }
   */
  put(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(this.api, payload, this.config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  /**
   * Api instance delete method
   *
   * @example
   * const api = new BaseApiRepo();
   * api.setApi("/some_url/users/1");
   * api.delete()
   */
  delete() {
    return new Promise((resolve, reject) => {
      axios
        .delete(this.api, this.config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
}

export const ASKING_PRICES_CREATE_POST = "asking_prices/create_post";
export const ASKING_PRICES_CREATE_LOADING = "asking_prices/create_loading";
export const ASKING_PRICES_CREATE_SUCCESS = "asking_prices/create_success";
export const ASKING_PRICES_CREATE_FAILED = "asking_prices/create_failed";
export const ASKING_PRICES_CREATE_RECEIVED = "asking_prices/create_received";

export function askingPriceCreatePost(id, payload = {}) {
  return {
    type: ASKING_PRICES_CREATE_POST,
    id,
    payload
  };
}
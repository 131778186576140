import { useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionListUsecase } from "@core";

import {
  auctionListAction,
  AUCTION_LIST,
} from "@redux/modules_v2/auctions/list/action";

//Hooks
import { useCurrentUser, useDeepEffect } from "@hooks";

/**
 * Fetch auction list hooks
 * @param {*} param
 */
export const useAuctionList = (params) => {
  const dispatch = useDispatch();

  const onAuctionListReceived = useCallback((list = []) => {
    if (!isEmpty(list)) {
      dispatch(auctionListAction.update(list));
    }
  }, []);

  const fetchList = (params) => {
    dispatch(auctionListAction.request(params));
  };

  const auctionListStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_LIST, state);
    return Object.assign({}, get(state, "auctionListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { loading } = auctionListStatus;

  let data = [];
  if(isArray(get(auctionListStatus, "data.entries"))) {
    data = get(auctionListStatus, "data.entries", []);
  } else {
    data = auctionListStatus.data;
  }

  const currentUser = useCurrentUser();
  const usecase = new AuctionListUsecase(data, get(currentUser, "user", {}));

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    fetchList(params);
  }, [params]);

  return {
    activeList: usecase.activeList,
    pastList: usecase.pastList,
    fetchList,
    unapprovedList: usecase.unapprovedList,
    loading,
    onAuctionListReceived,
  };
};

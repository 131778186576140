import { StatementEntity } from "@core";
import { isArray, isEmpty, replace } from "lodash";
import numeral from "@utils/numeral";

/**
 * Statement show usecase
 * @param {Object} props statementShowStatus
 */
export class StatementShowUsecase {
  constructor(props) {
    this.data = props;
  }

  get show() {
    if (isArray(this.data) || isEmpty(this.data)) {
      return {};
    }
    const usecase = new StatementEntity(this.data);
    return {
      id: usecase.id,
      orderUrl: usecase.orderUrl,
      buyer: usecase.buyer,
      createdAt: usecase.createdAt,
      invoiceNumber: usecase.invoiceNumber,
      marketName: usecase.marketName,
      price: usecase.price("price_with_symbol")
        ? `${usecase.price("price_with_symbol")}/kg`
        : "",
      seller: usecase.seller,
      serviceFee: usecase.serviceFee("value"),
      serviceFeeStr: replace(usecase.serviceFee("price_with_symbol"), "-", ""),
      serviceFeeGST: usecase.serviceFeeGST,
      serviceFeeGSTStr: replace(usecase.serviceFeeGSTStr, "-", ""),
      serviceFeeWithGST: usecase.serviceFeeWithGST("value"),
      serviceFeeWithGSTStr: replace(
        usecase.serviceFeeWithGST("price_with_symbol"),
        "-",
        ""
      ),
      cardProcessingFee: usecase.cardProcessingFee("value"),
      cardProcessingFeeStr: usecase.cardProcessingFee("price_with_symbol"),
      cardProcessingFeeGST: usecase.cardProcessingFeeGST("value"),
      cardProcessingFeeGSTStr: usecase.cardProcessingFeeGST(
        "price_with_symbol"
      ),
      cardProcessingFeeWithGST: usecase.cardProcessingFeeWithGST("value"),
      cardProcessingFeeWithGSTStr: usecase.cardProcessingFeeWithGST(
        "price_with_symbol"
      ),
      subtotal: usecase.subtotal("value"),
      subtotalStr: usecase.subtotal("price_with_symbol"),
      subtotalGST: usecase.subtotalGST,
      subtotalGSTStr: usecase.subtotalGSTStr,
      subtotalWithGST: usecase.subtotalWithGST("value"),
      subtotalWithGSTStr: usecase.subtotalWithGST("price_with_symbol"),
      totalGST: usecase.totalGST,
      totalGSTStr: usecase.totalGSTStr,
      totalPrice: usecase.totalPrice("value"),
      totalPriceStr: usecase.totalPrice("price_with_symbol"),
      totalWeight: usecase.totalWeight
        ? `${numeral(usecase.totalWeight).format("0,0.00")}kg`
        : "",
      units: usecase.units,
      paidAmount: usecase.paidAmount("value"),
      paidAmountStr: usecase.paidAmount("price_with_symbol"),
    };
  }
}

import React, {useEffect} from "react";
/**
 * import lib tools & components
 */
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { useHistory } from "react-router-dom";

/**
 * import local tools & config & hooks
 */
import {
  useCurrentUser,
  useAuctionList,
} from "@hooks";

/**
 * import local components
 */
import Layout from "@views/layout";
import { Loading, Link, Pagination, SEO } from "@components";

/**
 * [Page] Account dashboard 个人中心首页页面
 */
const PastAuction = (props) => {
  const currentUser = useCurrentUser();
  const isAuthenticated = get(currentUser, "isAuthenticated", false);
  const history = useHistory();

  const {
    activeList,
    pastList,
    unapprovedList,
    loading: loading,
    onAuctionListReceived,
  } = useAuctionList({expired:true});

  useEffect(() => {
    if(!isAuthenticated) {
      history && history.push("/register");
    }
  }, [isAuthenticated]);

  return (
    <Layout name="account">
      <SEO title="Past Auctions - BeachPrice" />
      <div className="py-10">
        <div className="max-w-screen-xl mx-auto px-4 md:px-6">
          <div className="">
            <h1 className="text-2xl md:text-3xl font-bold leading-tight text-blue-gray-900">
              Past Auctions
            </h1>
            <p className="mt-4 max-w-screen-md text-xs md:text-base text-blue-gray-500">BeachPrice aims to create a transparent and fair platform for quota trading. Our 30-day historic auction results are shown below. Auctions that were passed-in or cancelled are not shown.</p>
          </div>
        </div>
        <div className="max-w-screen-xl mx-auto px-4 md:px-6">
          <div className="py-8">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full max-w-full px-1 sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden rounded-lg">
                    <table className="min-w-full md:divide-y divide-blue-gray-200">
                      <thead className="bg-gray-50">
                        <tr className="hidden md:table-row">
                          <th scope="col" className="px-4 md:px-6 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            Auction ID
                          </th>
                          <th scope="col" className="hidden px-4 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            State + Market Name
                          </th>
                          <th scope="col" className="px-4 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            Auction Title
                          </th>
                          <th scope="col" className="px-4 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            Type
                          </th>
                          <th scope="col" className="px-4 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="px-4 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            Bids
                          </th>
                          <th scope="col" className="px-4 py-3 text-left text-xxs font-medium text-blue-gray-500 uppercase tracking-wider">
                            Final Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-blue-gray-200 md:divide-y-0">
                        {loading ? (
                          <tr>
                            <td colSpan="7">
                              <Loading />
                            </td>
                          </tr>
                          ) : (
                          map(pastList, (item, index) => {
                            return(
                              <tr data-target="auctions-list.item" key={index} className="bg-white even:bg-gray-50 hover-bg-gray-100 cursor-pointer block md:table-row py-2 md:py-0"
                              onClick={(evt) => {
                                evt.stopPropagation();
                                if (get(item, "status.approved", false)) {
                                  history && history.push(get(item, "show.url", "/"));
                                }
                              }}>
                                <td className="px-4 md:px-6 md:py-4 whitespace-nowrap text-sm block md:table-cell font-medium text-fish-primary cursor-pointer">
                                  Auction {get(item, "show.id", "")}
                                </td>
                                <td className="hidden px-4 md:py-4 whitespace-nowrap text-sm text-blue-gray-500">
                                {get(item, "show.usecaseName", "")}
                                </td>
                                <td className="px-4 md:py-4 whitespace-nowrap text-sm block md:table-cell text-blue-gray-800 md:text-blue-gray-500 md:max-w-xs md:truncate">
                                  {get(item, "show.title", "")}
                                </td>
                                <td className="px-4 md:py-4 whitespace-nowrap text-sm block md:table-cell text-blue-gray-500 capitalize">
                                  {get(item, "show.marketType", "")}
                                </td>
                                <td className="px-4 md:py-4 whitespace-nowrap text-sm block md:table-cell text-blue-gray-500">
                                  {get(item, "show.bidStatus", "")}
                                </td>
                                <td className="px-4 md:py-4 whitespace-nowrap text-sm inline md:table-cell text-blue-gray-500">
                                  {get(item, "show.bidCount", "")} bids
                                </td>
                                <td className="px-4 md:py-4 whitespace-nowrap text-sm inline md:table-cell text-blue-gray-900 font-medium float-right md:float-none">
                                  {get(item, "show.endPriceStr", "")}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PastAuction;

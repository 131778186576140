import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";

import { Loading, Link } from "@components";

const BrowseFisheries = ({ listLoading, fisheryList }) => {
  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6">
        {listLoading ? (
          <div className="col-span-4">
            <Loading />
          </div>
        ) : (
          map(fisheryList, (item, index) => {
            return(
              <Link
                to={item.url}
                key={index}
              >
                <div className="h-full p-4 md:p-8 bg-white shadow-sm md:shadow-t-xs md:rounded-lg rounded-lg transition duration-300 ease-in-out border border-transparent hover:border-fish-primary flex md:block items-center">
                  <div className="w-12 h-12 md:w-20 md:h-20 mx-auto">
                    <img src={item.logoImage} className="flex-shrink-0 max-w-full mx-auto rounded text-xxs" alt={item.title} />
                  </div>
                  <div className="flex-1 ml-6 md:ml-0 md:mt-4">
                    <div className="text-sm text-blue-gray-500 md:text-center">{item.state}</div>
                    <div className="md:mt-1 text-sm font-medium text-blue-gray-900 md:text-center">{item.category}</div>
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

BrowseFisheries.propTypes = {
};

BrowseFisheries.defaultProps = {
};

export default BrowseFisheries;

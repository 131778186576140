export const QUOTUM_BALANCES = "quotum_balances";
export const QUOTUM_BALANCES_REQUEST = `${QUOTUM_BALANCES}_request`;
export const QUOTUM_BALANCES_SUCCESS = `${QUOTUM_BALANCES}_success`;
export const QUOTUM_BALANCES_FAILURE = `${QUOTUM_BALANCES}_failure`;
export const QUOTUM_BALANCES_UPDATE = `${QUOTUM_BALANCES}_update`;

export const quotumBalancesAction = {
  request: (payload) => {
    return {
      type: QUOTUM_BALANCES_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: QUOTUM_BALANCES_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: QUOTUM_BALANCES_FAILURE,
      error,
    };
  },
  update: (payload) => {
    return {
      type: QUOTUM_BALANCES_UPDATE,
      payload,
    };
  },
};

export const AUCTION_BID_CREATE = "auction/bid_create";
export const AUCTION_BID_CREATE_REQUEST = `${AUCTION_BID_CREATE}_request`;
export const AUCTION_BID_CREATE_SUCCESS = `${AUCTION_BID_CREATE}_success`;
export const AUCTION_BID_CREATE_FAILURE = `${AUCTION_BID_CREATE}_failure`;
export const AUCTION_BID_CREATE_RESET = `${AUCTION_BID_CREATE}_reset`;

export const auctionBidCreateAction = {
  request: (payload) => {
    return {
      type: AUCTION_BID_CREATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: AUCTION_BID_CREATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: AUCTION_BID_CREATE_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: AUCTION_BID_CREATE_RESET
    }
  },
};

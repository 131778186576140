import { takeLatest, call, put } from "redux-saga/effects";

import { WithdrawApiUsecase } from "@core";
import { WITHDRAW_SHOW_REQUEST, withdrawShowAction } from "./action";

// fetch withdraw show
export function* watchWithdrawShowFetch() {
  yield takeLatest(WITHDRAW_SHOW_REQUEST, withdrawShowFetch);
}

function* withdrawShowFetch(action) {
  try {
    const usecase = new WithdrawApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(withdrawShowAction.success(res.data));
  } catch (err) {
    yield put(withdrawShowAction.failure(err));
  }
}

import { map, isArray, some, filter } from "lodash";
import {
  FisheryEntity,
  FisheryActivitiesEntity,
  FormSelectBaseEntity,
  AuctionListUsecase,
} from "@core";

/**
 * Fishery usecase
 */
export class FisheryUsecase {
  /**
   * 构造函数
   * @param {Array<Fishery> | Fishery} data 原始数据(Fishery)，可能是数组或单例对象
   */
  constructor(props) {
    this.data = props;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.data)) {
      return [];
    }
    return map(this.data, (data) => {
      return new FisheryEntity(data);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.data)) {
      return new FisheryEntity({});
    }
    return new FisheryEntity(this.data);
  }

  /**
   * [Class Getter] usecase.hasFollowing
   */
  get hasFollowing() {
    return some(this.list, (item) => item.following);
  }

  /**
   * [Class Getter] usecase.followIds
   */
  get followingIds() {
    const followingList = filter(this.list, (item) => item.following);
    return map(followingList, (item) => item.id);
  }

  getOptions(data = []) {
    const select = new FormSelectBaseEntity();
    const options = select.options(data, {
      label: "title",
      value: "id",
    });
    return options;
  }

  /**
   * [Class Getter] usecase.options
   */
  get options() {
    return this.getOptions(this.list);
  }

  /**
   * [Class Getter] usecase.exchangeOptions
   */
  get exchangeOptions() {
    const data = filter(
      this.list,
      (o) => o.saleTypes === "exchange" || o.saleTypes === "both"
    );
    return this.getOptions(data);
  }

  /**
   * [Class Getter] usecase.auctionOptions
   */
  get auctionOptions() {
    const data = filter(
      this.list,
      (o) => o.saleTypes === "auction" || o.saleTypes === "both"
    );
    return this.getOptions(data);
  }
}

/**
 * Fishery activity usecase
 */
export class FisheryActivitiesUsecase {
  constructor(props) {
    this.data = props;
    this.entity = new FisheryActivitiesEntity(props);
    const auctionListUsecase = new AuctionListUsecase(this.entity.auctions);
    this.auctionCon = auctionListUsecase.activeList;
  }

  get leaseMarket() {
    return {
      title: "Leases",
      key: "leaseMarket",
      con: this.entity.quotaMarket,
    };
  }

  get auction() {
    return {
      title: "Auctions",
      key: "auctions",
      con: this.auctionCon,
    };
  }

  get list() {
    return {
      leaseMarket: this.leaseMarket,
      auction: this.auction,
    };
  }
}

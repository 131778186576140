import React from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { useFormikContext, getIn } from "formik";
import WithLabel from "./with_label";

const Radio = ({
  className,
  radioClassName,
  radioIconClassName,
  options,
  name,
  onChange,
  onBlur,
  onFocus,
}) => {
  const { values, handleChange } = useFormikContext();
  const value = getIn(values, name);
  const change = (e) => {
    const value = e.target.value;
    if (typeof onChange === "function") {
      onChange(value);
    }
    handleChange(e);
  };
  const renderSingleRadio = (option = {}, idx) => {
    const id = `${name}_${uniqid()}`;
    const { label, value: radioValue } = option;
    return (
      <label
        htmlFor={id}
        className={cx(
          "flex cursor-pointer",
          { "items-center": !radioClassName, active: value === radioValue },
          radioClassName
        )}
        key={idx}
      >
        <input
          id={id}
          name={name}
          type="radio"
          className={cx("flex-shrink-0 h-4 w-4 focus:ring-fish-primary text-fish-primary border-blue-gray-300",radioIconClassName)}
          onChange={change}
          value={radioValue}
          onFocus={onFocus}
          onBlur={onBlur}
          checked={value === radioValue}
        />
        <div className="ml-3 flex-1 block text-sm text-blue-gray-700">
          {label}
        </div>
      </label>
    );
  };
  return (
    <div className={className}>
      {!isEmpty(options) && map(options, renderSingleRadio)}
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  radioClassName: PropTypes.string,
  radioIconClassName: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

Radio.defaultProps = {
  className: "",
  radioClassName: "",
  radioIconClassName: "",
  options: [],
  name: "",
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

export default WithLabel("radio")(Radio);

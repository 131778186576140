export const AUCTIONS_SHOW_REQUEST = "auctions/show_request";
export const AUCTIONS_SHOW_LOADING = "auctions/show_loading";
export const AUCTIONS_SHOW_SUCCESS = "auctions/show_success";
export const AUCTIONS_SHOW_FAILED = "auctions/show_failed";
export const AUCTIONS_SHOW_WAIT_RULING = "auctions/show_wait_ruling";
export const AUCTIONS_SHOW_END = "auctions/show_end";

export function fetchAuction(payload) {
  return {
    type: AUCTIONS_SHOW_REQUEST,
    payload
  };
}

export function updateAuction(payload) {
  return {
    type: AUCTIONS_SHOW_SUCCESS,
    data: payload
  };
}

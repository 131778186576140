import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";


export class GlobalApiRepo extends BaseApiRepo {
  constructor() {
    super();
  }

  /**
   * Glabal Api instance static
   * @param {object} payload {}
   */
  static(payload) {
    this.setApi(`${API}/static`);
    return this.get();
  }

}

import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";

const Title = ({ data }) => {
  const id = get(data, "show.id", "");
  const name = `Auction #${id} for`;

  return (
    <div className="p-6 md:py-10 flex flex-col items-center space-y-6 md:space-y-8 text-center">
      <div className="text-2xl font-medium">Your Final Offer opportunity</div>
      <div className="text-lg md:text-xl font-medium">{name}{" "}<span className="capitalize">{get(data, "show.title", "")}</span></div>
    </div>
  );
};

Title.propTypes = {};

export default Title;

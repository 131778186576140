import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import { OrderStatus } from "@core";
import { orderStateMap, orderClassMap } from "@config/enum";
import { LoadingWrapper, Empty, Button, Pagination } from "@components";

const Table = ({
  data,
  pageData,
  fetchData,
  loading,
  emptyTitle,
  emptyContent,
}) => {
  const history = useHistory();
  const renderPagination = () => {
    if (isEmpty(pageData)) {
      return null;
    }
    if (get(pageData, "total_pages", 0) <= 1) {
      return null;
    }
    return (
      <div className="lg:border-t border-blue-gray-200">
        <Pagination
          className="px-2 lg:px-4 pt-4 lg:pt-6 pb-6"
          theme="blue"
          currentPage={get(pageData, "page", 1)}
          entriesTotal={get(pageData, "total", 0)}
          pageSize={get(pageData, "page_size", 20)}
          itemClickHandler={(page) => {
            fetchData && fetchData(page);
          }}
        />
      </div>
    );
  };

  const renderOperation = (item) => {
    if (item.isAuctionOrder) {
      if (get(item, "order.market.isPermanent", false)) {
        return null;
      }
      if (!OrderStatus[get(item, "order.paidState", "")]) {
        return null;
      }
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="green-100"
          textColor="green-500"
        >
          <span className="font-medium text-xxs">
            {OrderStatus[get(item, "order.paidState", "")]}
          </span>
        </Button>
      );
    }

    if (item.paidState === orderStateMap.completed) {
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="green-100"
          textColor="green-500"
        >
          <span className="font-medium text-xxs">Order Completed</span>
        </Button>
      );
    }

    if (item.paidState === orderStateMap.closed) {
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="gray-100"
          textColor="gray-500"
        >
          Order Closed
        </Button>
      );
    }

    if (item.isExpired) {
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="gray-100"
          textColor="gray-500"
        >
          Order Expired
        </Button>
      );
    }

    if (item.isOut) {
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="green-100"
          textColor="green-500"
        >
          <span className="font-medium text-xxs">Waiting for Payment</span>
        </Button>
      );
    }

    return item.paidState === orderStateMap.pending_payment ? (
      <Button
        className="rounded text-xs font-medium leading-normal"
        size="xxs"
        color="fish-primary"
        textColor="white"
        onClick={(evt) => {
          evt.stopPropagation();
          history && history.push(item.paymentUrl);
        }}
      >
        Pay Now
      </Button>
    ) : (
      <Button
        className="rounded text-xs font-medium leading-normal"
        size="xxs"
        color="gray-100"
        textColor="gray-500"
      >
        Pay Now
      </Button>
    );
  };

  return (
    <LoadingWrapper loading={loading}>
      <Empty data={data} title={emptyTitle} content={emptyContent}>
        <div className="overflow-hidden">
          <table className="mb-2 lg:mb-0 min-w-full lg:divide-y divide-blue-gray-200">
            <thead className="hidden lg:table-header-group bg-gray-50">
              <tr>
                <th className="pl-4 lg:pl-6 pr-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Order
                </th>
                <th className="px-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  item
                </th>
                <th className="px-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase "></th>
                <th className="pl-2 pr-4 lg:pr-10 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Price
                </th>
              </tr>
            </thead>
            <tbody className="bg-white lg:divide-y divide-blue-gray-200" x-max="1">
              {map(data, (item, index) => (
                <tr
                  className={cx(
                    "hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer",
                    { "lg:bg-gray-50": index % 2 != 0 }
                  )}
                  key={index}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    history && history.push(item.url);
                  }}
                >
                  <td className="px-0 lg:pl-4 lg:pr-2 py-0 lg:py-5">
                    <div
                      className={cx(
                        "border lg:border-none border-blue-gray-200 rounded-lg overflow-hidden mx-2",
                        {
                          "mt-2": index > 0,
                        }
                      )}
                    >
                      <div className="px-4 lg:px-0 py-4 lg:py-0">
                        <div className="flex lg:block items-center mb-1">
                          <div className="text-sm leading-5 font-medium text-blue-gray-600 flex-1">
                            #{item.serialNumber}
                          </div>
                          <div className="lg:hidden">
                            {renderOperation(item)}
                          </div>
                        </div>
                        <div className="lg:hidden text-sm font-medium leading-5 mb-1">
                          {item.title}
                        </div>
                        <div className="text-xxs leading-5 text-blue-gray-400 lg:whitespace-no-wrap">
                          {item.createAtStr}{" "}
                          <span className="lg:hidden">
                            {get(item, "order.originId", "")
                              ? ` | ${get(
                                  orderClassMap,
                                  item.originClass,
                                  ""
                                )} #${get(item, "order.originId", "")}`
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="lg:hidden bg-gray-50 flex items-center justify-between py-3 px-4">
                        <span className="text-xxs text-blue-gray-600 uppercase">
                          {item.priceStrWithKg}
                        </span>
                        <span className="text-xxs text-blue-gray-600 uppercase">
                          {item.totalPayStr}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="hidden lg:table-cell px-2 py-4 max-w-xxs">
                    <div className="text-sm leading-5 font-medium text-blue-gray-600 truncate mb-1">
                      {item.title}
                    </div>
                    <div className="text-xs leading-5 text-blue-gray-400 ">
                      {get(item, "order.originId", "")
                        ? `${get(
                            orderClassMap,
                            item.originClass,
                            ""
                          )} #${get(item, "order.originId", "")}`
                        : ""}
                    </div>
                  </td>
                  <td className="hidden lg:table-cell px-2 py-4 lg:whitespace-no-wrap text-right">
                    {renderOperation(item)}
                  </td>
                  <td className="hidden lg:table-cell pl-2 pr-4 lg:pr-6 py-4 lg:whitespace-no-wrap">
                    <div className="flex items-center justify-end space-x-2">
                      <div className="flex flex-col items-end">
                        <div className="text-sm leading-5 text-blue-gray-600 mb-1">
                          {item.priceStrWithKg}
                        </div>
                        <div className="text-xs leading-5 text-blue-gray-400">
                          {item.totalPayStr}
                        </div>
                      </div>
                      <svg
                        className="w-3 h-3"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.17201 5.00008L0.343018 2.17208L1.75701 0.75708L6 5.00008L1.75701 9.24308L0.343018 7.82808L3.17201 5.00008Z"
                          fill="#E0E0E0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {renderPagination()}
        </div>
      </Empty>
    </LoadingWrapper>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  pageData: PropTypes.object,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyContent: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  pageData: {},
  fetchData: () => {},
  loading: false,
  emptyTitle: "",
  emptyContent: "",
};

export default Table;

import get from "lodash/get";
import map from "lodash/map";
import pluralize from "pluralize";

import numeral from "@utils/numeral";

import { PermanentEntity, PermanentMarketEntity } from "./permanentEntity";
import { QuotumEntity } from "./quotumEntity";
import { SeasonEntity } from "./seasonEntity";

/**
 * [ ENTITY ] permanent marketable entity 
 * 
 * @data
 * {
 *  id: 1
 *  title: "Tasmania Wuhan White Shark Quota Units"
 *  description: {
 *    id: null
      name: "description"
      body: null
      record_type: "PermanentListing"
      record_id: 1
      created_at: null
      updated_at: null
    },
    state: "completed"
    created_at: "2020-03-13T02:18:08.442+11:00"
    updated_at: "2020-03-13T02:40:25.542+11:00"
    cognito_id: "3afe3585-bf6a-4ae5-a4d7-f056a82d7526"
    price: "68.00"
    price_with_symbol: "$68.00"
 *  marketables: 
 *  [
 *    { 
 *      market: { 
 *        id: 1
          quantity: 2
          quotum_id: 3
          created_at: "2020-03-13T02:18:08.446+11:00"
          updated_at: "2020-03-13T02:18:08.446+11:00"
          price: "34.00"
          price_with_symbol: "$34.00"
        },
        quotum: {
          id: 3
          unit_name: "units"
          category: {id: 1, name: "Shark"}
          species: {id: 3, name: "White Shark"}
          country: {id: 1, name: "Australia", abbr: "AU"}
          state: {id: 2, name: "Tasmania", abbr: "TAS"}
          zone: {id: 2, name: "Wuhan", abbr: "Wuhan"}
        }
      },
      { ... }
    ]
 *
 */

export class PermanentMarketableEntity extends PermanentEntity {
  constructor(props = {}) {
    super(props);
    this.marketables = get(props, "marketables", []);
  }

  mapMarketables(cb) {
    return map(this.marketables, (item) => {
      const market = new PermanentMarketEntity(get(item, "market", {}));
      const quotum = new QuotumEntity(get(item, "quotum", {}));
      const season = new SeasonEntity(get(item, "season", {}));
      return cb({ market, quotum, season });
    });
  }

  cbUnits({ market }) {
    return numeral(market.quantity).format("0,0");
  }

  cbUnitsStr({ market, quotum }) {
    return `${numeral(market.quantity).format("0,0")} ${pluralize(
      quotum.unitName,
      market.quantity
    )}`;
  }

  cbAmount({ market, season }) {
    return numeral(market.quantity).multiply(season.kgPerUnit).value();
  }

  cbAmountStr({ market, season }) {
    return `${numeral(market.quantity).multiply(season.kgPerUnit).value()} kg`;
  }

  /**
   * [GETTER] units
   * @returns {Array}
   */
  get units() {
    if (this.marketables.length > 1) {
      return [];
    }
    return this.mapMarketables(this.cbUnits);
  }

  /**
   * [GETTER] unitsStr
   * @returns {Array}
   */
  get unitsStr() {
    if (this.marketables.length > 1) {
      return "";
    }
    return this.mapMarketables(this.cbUnitsStr);
  }

  /**
   * [GETTER] amount
   * @returns {Array}
   */

  get amount() {
    if (this.marketables.length > 1) {
      return [];
    }
    return this.mapMarketables(this.cbAmount);
  }

  /**
   * [GETTER] amountStr
   * @returns {Array}
   */
  get amountStr() {
    if (this.marketables.length > 1) {
      return "";
    }
    return this.mapMarketables(this.cbAmountStr);
  }

  /**
   * [GETTER] 包含species和unit的价格
   * @returns {Array}
   * @example ["lobster $2000 per unit", "abalone $500 per unit"]
   */
  get priceWithSpecieUnit() {
    return this.mapMarketables(({ market, quotum }) => {
      return `${quotum.getAttr("species", "name")} ${get(
        market,
        "data.price_with_symbol",
        ""
      )} per ${pluralize(quotum.unitName, 1)}`;
    });
  }

  /**
   * [GETTER] 包含speices和unit的数量
   * @returns {Array}
   * @example ["lobster 5 units", "abalone 3 units"]
   */
  get amountWithSpeicesUnit() {
    return this.mapMarketables(({ market, quotum }) => {
      return `${quotum.getAttr("species", "name")} ${
        market.quantity
      } ${pluralize(quotum.unitName, market.quantity)}`;
    });
  }

  /**
   * [GETTER] 包含acmount 和 price的数组对象
   * @return {Array}
   * @example [{name: "lobster", amount: "5 units", price: "$2000 per unit", total: "$10000" }]
   */
  get marketItems() {
    return this.mapMarketables(({ market, quotum }) => ({
      name: quotum.getAttr("species", "name"),
      amount: `${market.quantity} ${pluralize(
        quotum.unitName,
        market.quantity
      )}`,
      price: `${get(market, "data.price_with_symbol", "")} per ${pluralize(
        quotum.unitName,
        1
      )}`,
      total: `${numeral(get(market, "data.price", ""))
        .multiply(market.quantity)
        .format()}`,
    }));
  }
}


export const CURRENT_BID_FETCH_REQUEST = "bids/current_fetch_request";
export const CURRENT_BID_FETCH_LOADING = "bids/current_fetch_loading";
export const CURRENT_BID_FETCH_SUCCESS = "bids/current_fetch_success";
export const CURRENT_BID_FETCH_FAILED = "bids/current_fetch_failed";

export function fetchCurrentBid(payload) {
  return {
    type: CURRENT_BID_FETCH_REQUEST,
    payload
  };
}

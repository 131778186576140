import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import * as Yup from "yup";

/**
 * import lib tools
 */
import { LoadingSelector } from "@core";

/**
 * import local tools & redux & usecase
 */
import Notice from "@utils/noticeNew";
import {
  permanentEnquireAction,
  PERMANENT_ENQUIRE,
} from "@redux/modules_v2/permanent/enquire/action";

/**
 * Enquire form hooks
 * @param {*} param
 */
export const usePermanentEnquire = (callback) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const toggleModal = get(callback, "toggleModal", "");
  const id = get(callback, "id", "");

  /**
   * 编辑提交后状态
   */
  const permanentEnquireStatus = useSelector((state) => {
    const selector = new LoadingSelector(PERMANENT_ENQUIRE, state);
    return Object.assign({}, get(state, "permanentEnquireStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * 初始化表单
   * //   email: "",
    //   first_name: "",
    //   last_name: "",
    //   mobile_number: "",
    //   message: "",
   */
  const [initialValues, setInitalValues] = useState({
    email: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    message: "",
  });

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback((values) => {
    const data = Object.assign({}, values, {id})
    dispatch(permanentEnquireAction.request(data));
  }, [id]);

  /**
   * [Callback] 触发提交表单
   */
  const handleSubmit = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  /**
   * Schema
   */
  const schema = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    mobile_number: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("The field is not a valid email")
      .required("This field is required"),
    message: Yup.string().required("This field is required"),
  });

  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(permanentEnquireAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!permanentEnquireStatus.loading) {
      if (permanentEnquireStatus.data) {
        // Notice.success();
        typeof toggleModal === "function" && toggleModal();
      }
      if (permanentEnquireStatus.error) {
        Notice.failure("Enquire Failed");
      }
    }
  }, [permanentEnquireStatus.loading]);

  return {
    formRef,
    handleSubmit,
    initialValues,
    onSubmit,
    schema,
    loading: permanentEnquireStatus.loading,
  };
};

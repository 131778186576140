import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { injectStripe, CardElement } from "react-stripe-elements";
import { Button } from "@components";

const handleBlur = () => {
  // console.log("[blur]");
};
const handleChange = (change) => {
  // console.log("[change]", change);
};
const handleClick = () => {
  // console.log("[click]");
};
const handleFocus = () => {
  // console.log("[focus]");
};
const handleReady = () => {
  // console.log("[ready]");
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
        width: "100%",
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class _CardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && nextProps.loading != this.props.loading) {
      // recharge deposit failed
      this.setState({ loading: false });
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ loading: true });
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => {
          const id = get(payload, "token.id");
          if (id) {
            this.props.handleSubmit && this.props.handleSubmit(id);
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
      console.warn("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label className="gs-form-group-label">Card details</label>
        <CardElement
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onReady={handleReady}
          {...createOptions(this.props.fontSize, 20)}
        />
        <Button
          color="fish-primary"
          textColor="white"
          full
          type="submit"
          className="hover:bg-fish-primary-700 mt-3"
          disabled={this.state.loading}
          loading={this.state.loading}
        >
          Pay
        </Button>
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

function mapStateToProps(state) {
  return {
    loading: get(state, "rechargeDeposit.loading", false),
  };
}

export default connect(mapStateToProps, null)(CardForm);

export const WALLET_BALANCE = "wallets/balance";
export const WALLET_BALANCE_REQUEST = `${WALLET_BALANCE}_request`;
export const WALLET_BALANCE_SUCCESS = `${WALLET_BALANCE}_success`;
export const WALLET_BALANCE_FAILURE = `${WALLET_BALANCE}_failure`;


export const walletBalanceAction = {
  request: (payload) => {
    return {
      type: WALLET_BALANCE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: WALLET_BALANCE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: WALLET_BALANCE_FAILURE,
      error,
    }
  },

};


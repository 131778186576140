import { take, call, put, select, actionChannel } from "redux-saga/effects";
import ActionCable from "actioncable";
import {
  CREATE_AC_CONSUMER, AC_CONSUMER_CREATED,
} from "./action";
import { cableAPI } from "@config/api";

export function* watchACCreateRequest() {
  const acConsumerChan = yield actionChannel(CREATE_AC_CONSUMER);
  // 此处用actionChannel 和 yield call保证一次只接受一个CREATE_AC_CONSUMER事件，处理完后再继续下一个，同步执行非异步
  while(true) {
    const action = yield take(acConsumerChan);
    yield call(createActionCableConsumer, action)
  }
}

function* createActionCableConsumer(action) {
  const getCurrentCable = state => state.currentCable;
  const { cable: existCable } = yield select(getCurrentCable);

  if (!existCable) {
    const cable = ActionCable.createConsumer(cableAPI(action.payload));

    yield put({
      type: AC_CONSUMER_CREATED,
      payload: cable,
    });
  }
}
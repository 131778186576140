import isArray from "lodash/isArray";
import {
  EXCHANGE_LIST_FETCH_REQUEST,
  EXCHANGE_LIST_FETCH_SUCCESS,
  EXCHANGE_LIST_FETCH_FAILED,
  EXCHANGE_LIST_REFRESH,
} from "./listAction";

export const exchangeListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case EXCHANGE_LIST_FETCH_REQUEST:
      return {
        loading: true,
        data: [],
        error: null,
      };
      break;

    case EXCHANGE_LIST_FETCH_SUCCESS:
      return {
        loading: false,
        data: isArray(action.data) ? action.data : [action.data],
        error: null,
      };
      break;

    case EXCHANGE_LIST_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    case EXCHANGE_LIST_REFRESH:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: [],
  error: null,
};

import React from "react";

/**
 * import lib tools
 */
import getQueryString from "@utils/getUrlQuery";
import Layout from "@views/layout";

/**
 * hook
 */
import { useConfirmRegistration } from "./hook";

const RegisterActive = props => {
  const email = getQueryString("email");
  const code = getQueryString("code");
  const [loading, res, error] = useConfirmRegistration([email, code], []);
  let content = <p>Your account is being activated.</p>;

  if (!loading && res) {
    content = <p>Your account has been activated successfully.</p>;
    setTimeout(() => {
      props.history && props.history.push("/login");
    }, 2000);
  }

  if (error) {
    if (error === "User cannot be confirm. Current status is CONFIRMED") {
      content = <p>Your account has been activated successfully.</p>;
      setTimeout(() => {
        props.history && props.history.push("/login");
      }, 2000);
    } else {
      content = <p>{error}</p>;
    }
  }
  return (
    <Layout>
      <div className="text-center p-5-tw">{content}</div>
    </Layout>
  );
};

export default RegisterActive;

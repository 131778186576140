import { MARKET_LIST_SUCCESS, MARKET_LIST_FAILURE } from "./action";

export const marketListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case MARKET_LIST_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case MARKET_LIST_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

export const ORDER_PAYMENT = "orders/payment";
export const ORDER_PAYMENT_REQUEST = `${ORDER_PAYMENT}_request`;
export const ORDER_PAYMENT_SUCCESS = `${ORDER_PAYMENT}_success`;
export const ORDER_PAYMENT_FAILURE = `${ORDER_PAYMENT}_failure`;
export const ORDER_PAYMENT_RECEIVED = `${ORDER_PAYMENT}_received`;

export const orderPaymentAction = {
  request: (payload) => {
    return {
      type: ORDER_PAYMENT_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDER_PAYMENT_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDER_PAYMENT_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: ORDER_PAYMENT_RECEIVED,
    }
  },
};

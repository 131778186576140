import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

/**
 * import local component
 */
import { Button } from "@components";
import { Input, Form } from "@components/formik";

const EnquireForm = ({ initialValues, onSubmit, schema, loading }) => {
  const labelClass = "text-sm font-semibold leading-snug text-blue-gray-800";
  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        enableReinitialize={true}
      >
        <Form className="">
          <div className="md:flex justify-between items-center">
            <div className="flex-1 md:mr-6">
              <Input
                name="first_name"
                label="First Name"
                placeholder=""
                labelClass={labelClass}
              />
            </div>
            <div className="mt-4 md:mt-0 flex-1">
              <Input
                name="last_name"
                label="Last Name"
                placeholder=""
                labelClass={labelClass}
              />
            </div>
          </div>
          <div className="mt-4">
            <Input
              name="mobile_number"
              label="Mobile Number"
              placeholder=""
              labelClass={labelClass}
            />
          </div>
          <div className="mt-4">
            <Input
              name="email"
              label="Email"
              placeholder=""
              labelClass={labelClass}
            />
          </div>
          <div className="mt-4">
            <Input
              name="message"
              label="Leave a Message"
              labelClass={labelClass}
              type="textarea"
            />
          </div>
          <div className="mt-6 md:mt-8">
            <Button
              size="xl"
              color="fish-primary"
              textColor="white"
              className="hover:bg-fish-primary-700"
              type="submit"
              disabled={loading}
              loading={loading}
            >
              Send
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

EnquireForm.propTypes = {
  formRef: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  schema: PropTypes.object,
  loading: PropTypes.bool,
};

export default EnquireForm;

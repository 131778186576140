import React from "react";

/**
 * import lib tools & components
 */
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import get from "lodash/get";

/**
 * import local components
 */
import { Button, Modal, useModal, Table } from "@components";

/**
 * [Component] Trade modal for buy/sell now
 *
 * @example
  <TradeModal
    isOpen={true|false}
    loading={true|false}
    theme="success|danger|blue"
    title="This Is a Title For Modal"
    subTitle="This Is a Sub Title For Modal"
    data={{
      price: 12,
      amount: 3,
      amountShow: 3000,
      items: [ { units: "2 pots" price: "$10.00/kg", amount: "20kg", itemTotal: "$200.00"} ],
      serviceFee: "$10.00",
      subTotal: "$10.00",
      gst: "$1.00",
      total: "$11.00",
    }}
    toggle={() => {

    }}
    onSubmit={() => {
      console.log("提交报价");
    }}
  />
 */
const TradeModal = (props) => {
  const { title, subTitle, theme, isOpen, loading, data, toggle, onSubmit } =
    props;
  const buttonClass = classnames({
    "green-600": theme === "success",
    "red-600": theme === "danger",
    "fish-primary": theme === "blue",
  });
  /**
   * 数据展示
   */
  const renderInfos = () => {
    const isSaleByUnit = get(data, "isSaleByUnit", false);
    return (
      <React.Fragment>
        <div>
          <h2>{title}</h2>
          {subTitle && <div className="mt-1 mb-6">{subTitle}</div>}
        </div>
        <div>
          <Table className="table tw-table no-head-bg no-padding-table">
            <thead>
              <tr>
                {isSaleByUnit && <th className="">UNITS</th>}
                <th className={classnames({ "text-right": isSaleByUnit })}>
                  AMOUNT
                </th>
                <th className="text-right">PRICE</th>
                <th className="text-right whitespace-no-wrap">ITEM TOTAL</th>
              </tr>
            </thead>
            <tbody className="whitespace-no-wrap">
              {map(data.items, (item, index) => {
                return (
                  <tr key={index}>
                    {isSaleByUnit && <td className="">{item.units}</td>}
                    <td className={classnames({ "text-right": isSaleByUnit })}>
                      {item.amount}
                    </td>
                    <td className="text-right">{item.price}</td>
                    <td className="text-right">{item.itemTotal}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div>
          <div className="w-2/3 md:w-1/2 ml-auto py-2">
            <div className="flex justify-between py-2">
              <div>Subtotal</div>
              <div className="font-semibold">{data.subTotal}</div>
            </div>
            <div className="flex justify-between py-2">
              <div>GST</div>
              <div className="font-semibold">{data.gst}</div>
            </div>
            <hr className="border-blue-gray-600 border-t-2 my-2" />
            <div className="flex justify-between py-2">
              <div>Total</div>
              <div className="text-xl font-semibold">{data.total}</div>
            </div>
          </div>
        </div>
        <div>
          <Button
            size="xl"
            color={buttonClass}
            textColor="white"
            full
            type="submit"
            onClick={() => {
              onSubmit();
            }}
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const [modalProps] = useModal(
    {
      content: renderInfos(),
      showClose: true,
      onClosed: () => {
        typeof toggle === "function" && toggle();
      },
      isVertical: true,
      contentAlign: "left",
    },
    isOpen
  );

  return <Modal {...modalProps} />;
};

TradeModal.protTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
  theme: PropTypes.string,
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
    serviceFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    gst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    amountShow: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }),
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

TradeModal.defaultProps = {
  isOpen: false,
  loading: false,
  title: "",
  subTitle: "",
  theme: "blue", // success|danger
  data: {
    items: [],
    serviceFee: "",
    subTotal: "",
    gst: "",
    price: "",
    amount: "",
    amountShow: "",
  },

  toggle: () => {},
  onSubmit: () => {},
};

export default TradeModal;

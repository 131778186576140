/**
 * Market type
 */
export const MarketMap = {
  lease: "QuotaMarket",
  permanent: "PermanentListing",
};

export const AuctionSellType = {
  lease: "lease",
  permanent: "permanent",
};

/**
 * Lease market
 * 是以 kg 交易，还是以 unit 交易
 */
export const unitOfSaleMap = {
  kg: "kg",
  unit: "blocks",
};

/**
 * Popular Lists
 * 显示的样式类型, 大图片, 还是小图片
 */
export const PopularListsViewMap = {
  verboseView: "verbose_view",
  compactView: "compact_view",
};

/**
 * Market Type
 */
export const MarketType = {
  all: "all",
  markets: "markets",
  quotaLease: "quota_lease",
  quotaUnits: "quota_units",
};

/**
 * Pay Type
 */
export const PayTypeMap = {
  instant: "instant",
  deposit: "deposit",
  balance: "balance",
  holdFee: "hold_fee",
};

/**
 * Pay Type Title
 */
export const PayTypeTitleMap = {
  full: "Instant Payment",
  deposit: "Deposit",
  balance: "Outstanding Balance",
  hold_fee: "Holding Fee",
};

/**
 * All product option
 */
export const AllMarketsOption = { value: "quota_lease", label: "Quota Lease" };
// export const AllMarketsOption = { label: "Leases & Quota Units", value: "all" };

/**
 * All species option
 */
export const AllSpeciesOption = { label: "All Species", value: "all" };

/**
 * All states option
 */
export const AllStatesOption = { label: "All States", value: "all" };

/**
 * Market搜索类型的options
 */
export const MarketOptions = [
  AllMarketsOption,
  // { value: "quota_units", label: "Quota Units" },
  // { value: "quota_lease", label: "Quota Lease" },
];

/**
 * Colors of states
 */
export const StateColors = {
  tas: "bg-fish-primary",
  via: "bg-teal-500",
  sa: "bg-indigo-600",
  nsw: "bg-purple-600",
  qld: "bg-yellow-600",
  wa: "bg-pink-600",
  afma: "bg-orange-600",
  act: "bg-green-600",
  default: "bg-fish-primary",
};

/**
 * Price Type
 */
export const PriceType = {
  lowest_seller_offer: "Lowest Seller Ask",
  highest_buyer_bids: "Highest Buyer Bid",
};

/**
 * Trending Type
 */
export const TrendingType = {
  lowest_seller_offer: "trending_offer_price",
  highest_buyer_bids: "trending_bid_price",
};

/**
 * Search Type
 */
export const SearchType = {
  Category: "category_id",
  Species: "species_id",
};

/**
 * faqs
 */
export const Faqs = [
  {
    title: "What is BeachPrice?",
    list: [
      { title: "Why use BeachPrice?", content: "Why use BeachPrice?" },
      {
        title: "What quotas can I trade on BeachPrice?",
        content: "Why use BeachPrice?",
      },
      {
        title: "What is a bid? What is an ask?",
        content: "Why use BeachPrice?",
      },
    ],
  },
  {
    title: "Selling and Leasing-out",
    list: [
      {
        title: "How does leasing-out quota work?",
        content: "How does leasing-out quota work?",
      },
      {
        title: "How do I consign my quota to BeachPrice?",
        content: "How does leasing-out quota work?",
      },
      {
        title: "What does it cost to sell or lease-out quota?",
        content: "How does leasing-out quota work?",
      },
      {
        title:
          "How does the transfer process work after an order is generated?",
        content: "How does leasing-out quota work?",
      },
    ],
  },
  {
    title: "Buying and Leasing-in",
    list: [
      {
        title: "How does leasing-in quota work?",
        content: "How does leasing-in quota work?",
      },
      {
        title: "Does it cost anything to buy or lease-in?",
        content: "Does it cost anything to buy or lease-in?",
      },
      {
        title:
          "How does the transfer process work after an order is generated?",
        content: "Does it cost anything to buy or lease-in?",
      },
    ],
  },
  {
    title: "Brokers",
    list: [
      {
        title: "I'm a fishing broker. Can I work with BeachPrice?",
        content: "I'm a fishing broker. Can I work with BeachPrice?",
      },
      {
        title: "Will I still get commission when I work with BeachPrice?",
        content: "I'm a fishing broker. Can I work with BeachPrice?",
      },
      {
        title: "My client wants to use BeachPrice — can we work together?",
        content: "I'm a fishing broker. Can I work with BeachPrice?",
      },
    ],
  },
];

/**
 * Fishery Show Tabs
 */
export const FisheryShowTabs = [
  // {
  //   key: "overview",
  //   label: "Overview",
  // },
  {
    key: "overview",
    label: "Overview",
  },
  {
    key: "auctions",
    label: "Auctions",
  },
  // {
  //   key: "auctions",
  //   label: "Auctions",
  // },
  {
    key: "leaseMarket",
    label: "Leases",
  },
  {
    key: "quotaUnits",
    label: "Permanent Sales",
  },
  // {
  //   key: "news",
  //   label: "News & Discussions",
  // },
  // {
  //   key: "news",
  //   label: "News",
  // },
  // {
  //   key: "fishClub",
  //   label: "FishClub",
  // },
  // {
  //   key: "investors",
  //   label: "Investors",
  // },
];

export const PriceHidden = {
  login: "Price Hidden",
  unlogin: "Login to see price",
};

export const OrderStatus = {
  buyer_paid: "Waiting for seller to receive funds",
  fund_received: "Waiting for seller to transfer quota",
  completed: "Quota transferred",
  pending: "Waiting for buyer payment",
};

export const AuctionStatus = {
  fixed_price_sold: "fixed_price_sold",
  final_chance_accepted: "final_chance_accepted",
  success: "success",
  final_chance_rejected: "final_chance_rejected",
  final_chance_set: "final_chance_set",
  failed: "failed",
  pending: "pending",
};

export const BidderStatus = {
  won_by_fixed_price: "won_by_fixed_price",
  won_by_final_chance_accepted: "won_by_final_chance_accepted",
  won_by_highest_bid: "won_by_highest_bid",
  failed_by_final_chance_rejected: "failed_by_final_chance_rejected",
  failed_by_final_chance_expired: "failed_by_final_chance_expired",
  failed_by_low_bid: "failed_by_low_bid",
  pending_wait_for_final_chance_submit: "pending_wait_for_final_chance_submit",
  pending_wait_for_final_chance_confirm:
    "pending_wait_for_final_chance_confirm",
  pending_highest_bidder: "pending_highest_bidder",
  pending_collision_bidder: "pending_collision_bidder",
  pending_low_bidder: "pending_low_bidder",
  not_join: "not_join",
};

export const AuctionBidMessage = {
  won_by_fixed_price: "You won via buy-it-now",
  won_by_final_chance_accepted: "You won via Final Offer",
  won_by_highest_bid: "You won this auction",
  failed_by_final_chance_rejected: "Your Final Offer was unsuccessful",
  failed_by_final_chance_expired: "Auction was passed in",
  failed_by_low_bid: "Auction was passed in",
  pending_wait_for_final_chance_submit: "You have the exclusive Final Offer option",
  pending_wait_for_final_chance_confirm: "Final Offer submitted",
  pending_highest_bidder: "You're currently the highest bidder",
  pending_collision_bidder: "You've been outbid by someone a bit faster!",
  pending_low_bidder: "You've been outbid!",
  not_join: "Start bidding now!",
  seller: "You cannot bid on your own auction",
};

/**
 * 创建auction description select 下拉列表
 */

export const AuctionDescriptionSelectList = {
  // GST: {
  //   type: "radio",
  //   options: [
  //     { label: "Including GST", value: "Including GST" },
  //     { label: "Excluding GST", value: "Excluding GST" },
  //   ],
  //   market_type: ["lease", "permanent"],
  // },
  // "Fishing fees": {
  //   type: "radio",
  //   options: [
  //     { label: "Including fishing fees", value: "Including fishing fees" },
  //     { label: "Excluding fishing fees", value: "Excluding fishing fees" },
  //   ],
  //   market_type: ["lease"],
  // },
  // "Payment due date": {
  //   type: "select",
  //   options: [
  //     { label: "2 business days", value: "2 business days" },
  //     { label: "3 business days", value: "3 business days" },
  //     { label: "5 business days", value: "5 business days" },
  //   ],
  //   market_type: ["lease", "permanent"],
  // },
};

/**
 *  Beachprice Fishnet client number
 */
export const fishnetClientNumber = "4284";

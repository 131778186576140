import { takeLatest, call, put, delay } from "redux-saga/effects";

import { WalletApiUsecase } from "@core";
import { WALLET_ENTRIES_REQUEST, walletEntriesAction } from "./action";

// wallet entries fetch
export function* watchWalletEntriesFetch() {
  yield takeLatest(WALLET_ENTRIES_REQUEST, walletEntriesFetch);
}

function* walletEntriesFetch(action) {
  try {
    const usecase = new WalletApiUsecase();
    const res = yield call([usecase, "entries"], action.payload);
    // yield delay(3000)
    yield put(walletEntriesAction.success(res.data));
  } catch (err) {
    yield put(walletEntriesAction.failure(err));
  }
}

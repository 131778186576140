import { takeEvery, all, call, put } from "redux-saga/effects";
import axios from "axios";
import { stopSubmit } from "redux-form";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  PRODUCT_NEW_REQUEST,
  PRODUCT_NEW_LOADING,
  PRODUCT_NEW_SUCCESS,
  PRODUCT_NEW_FAILED,
} from "./newAction";
import { fetchCartPricesList } from "../cart/askingPricesSaga";

import { productNewAPI } from "@config/api";

// watch product request
export function* watchProductNewRequest() {
  yield takeEvery(PRODUCT_NEW_REQUEST, productNewRequest);
}

export function* productNewRequest(action) {
  yield put({ type: PRODUCT_NEW_LOADING });
  const { res, err } = yield call(productNewApi, action.payload);
  const type = get(action, "payload.type");
  if (err || isEmpty(res)) {
    yield all([
      put({
        type: PRODUCT_NEW_FAILED,
        error: err,
      }),
      put(stopSubmit("ProductNewForm", formatError(err))),
    ]);
    return;
  }
  yield all([
    put({ type: PRODUCT_NEW_SUCCESS, data: res }),
    fetchCartPricesList(type),
  ]);
}

function productNewApi(payload) {
  const data = Object.keys(payload)
    .filter((key) => ["price", "boxes_count"].includes(key))
    .reduce((obj, key) => {
      obj[key] = payload[key];
      return obj;
    }, {});

  return axios
    .post(productNewAPI(payload.product_id, payload.type), [data])
    .then((res) => {
      return { res: res.data };
    })
    .catch((err) => {
      return { err: get(err, "response.data.error") };
    });
}

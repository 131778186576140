export const ACCOUNT_PERMANENT_LIST = "account/permanent_list";
export const ACCOUNT_PERMANENT_LIST_REQUEST = `${ACCOUNT_PERMANENT_LIST}_request`;
export const ACCOUNT_PERMANENT_LIST_SUCCESS = `${ACCOUNT_PERMANENT_LIST}_success`;
export const ACCOUNT_PERMANENT_LIST_FAILURE = `${ACCOUNT_PERMANENT_LIST}_failure`;

export const accountPermanentListAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_PERMANENT_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ACCOUNT_PERMANENT_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ACCOUNT_PERMANENT_LIST_FAILURE,
      error,
    }
  },
};

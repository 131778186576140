import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_ACTIVITY_REQUEST, fisheryActivityAction } from "./action";

// fetch fishery activity
export function* watchfisheryActivityFetch() {
  yield takeLatest(FISHERY_ACTIVITY_REQUEST, fisheryActivityFetch);
}

function* fisheryActivityFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "activity"], action.payload);
    yield put(fisheryActivityAction.success(res.data));
  } catch (err) {
    yield put(fisheryActivityAction.failure(err));
  }
}

export const BID_DELETE = "bids/delete_v2";
export const BID_DELETE_REQUEST = `${BID_DELETE}_request`;
export const BID_DELETE_SUCCESS = `${BID_DELETE}_success`;
export const BID_DELETE_FAILURE = `${BID_DELETE}_failure`;
export const BID_DELETE_RECEIVED = `${BID_DELETE}_received`;

export const bidDeleteAction = {
  request: (payload) => {
    return {
      type: BID_DELETE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: BID_DELETE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: BID_DELETE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: BID_DELETE_RECEIVED
    }
  },
};

import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";

import errorString from "@utils/errorString";
import { PermanentApiUsecase } from "@core";
import { PERMANENT_ENQUIRE_REQUEST, permanentEnquireAction } from "./action";

export function* watchPermanentEnquireFetch() {
  yield takeLatest(PERMANENT_ENQUIRE_REQUEST, permanentEnquireFetch);
}

function* permanentEnquireFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "enquire"], action.payload);
    yield put(permanentEnquireAction.success(res.data));
  } catch (err) {
    const errors = get(err, "response.data.errors", {});
    yield put(permanentEnquireAction.failure(errorString(errors)));
  }
}
  
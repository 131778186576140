import { get, map, forEach, filter, isArray } from "lodash";
import { BidOfferEntity } from "@core";
import numeral from "@utils/numeral";

export class BidOfferUsecase {
  /**
   * 构造函数
   * @param {Array<BidOffer> | BidOffer} data 原始数据(BidOffer)，可能是数组或单例对象
   * @param {MarketUsecase} market 一个 MarketEntity 的实例
   */
  constructor(data, market) {
    this.entity = data;
    this.market = market;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new BidOfferEntity(entity, this.market);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new BidOfferEntity({}, this.market);
    }
    return new BidOfferEntity(this.entity, this.market);
  }

  /**
   * [Class Getter] usecase.myList  当前用户的报价列表
   */
  get myList() {
    if (!isArray(this.entity)) {
      return [];
    }

    const list = [];
    forEach(this.entity, (group) => {
      forEach(filter(group.items, ["is_me", true]), (obj) => {
        const item = {
          ...obj,
          marketable: group.marketable || {},
        };
        const entity = new BidOfferEntity(item, this.market);
        list.push(entity);
      });
    });

    return list;
  }

  /**
   * [Class Instance Method] usecase.parsePlaceFormData(10, 30)
   */
  parsePlaceFormData(price, amount) {
    const bidOffer = this.single;

    let subTotal = 0;
    let serviceFee = 0;
    const items = [];

    if (price > 0 && amount > 0) {
      items.push({
        price: bidOffer.priceStrWithKg,
        amount: bidOffer.amountStr,
        itemTotal: bidOffer.totalStr,
      });
      subTotal += bidOffer.total;
    }

    const q = this.market.generateData(amount > 0 ? amount : 1);
    const amountShow = !this.market.isSaleByUnit
      ? numeral(q.units).format("0,0")
      : numeral(q.amount).format("0,0.00");

    return {
      amountShow: amountShow,
      subTotal: numeral(subTotal).format(),
      serviceFee: numeral(serviceFee).format(),
      gst: numeral(subTotal).multiply(0.1).format(),
      total: numeral(subTotal).multiply(0.1).add(subTotal).format(),
      items,
    };
  }

  /**
   * [Class Instance Method] usecase.parseTradeFormData(30)
   */
  parseTradeFormData(amount) {
    const bidOfferList = this.list;

    let subTotal = 0;
    let serviceFee = 0;
    let isMe = false;
    const items = [];

    if (amount > 0) {
      bidOfferList.forEach((obj) => {
        items.push({
          numUnits: obj.units,
          numAmount: obj.amount,
          numPrice: obj.price,
          units: obj.unitsStr,
          price: obj.priceStrWithKg,
          amount: obj.amountStr,
          itemTotal: obj.totalStr,
          isMe: obj.isMe,
        });
        subTotal += obj.total;
        isMe = isMe || obj.isMe;
      });
    }
    const q = this.market.generateData(amount > 0 ? amount : 1);
    const amountShow = !this.market.isSaleByUnit
      ? numeral(q.units).format("0,0")
      : numeral(q.amount).format("0,0.00");

    return {
      amountShow: amountShow,
      subTotal: numeral(subTotal).format(),
      serviceFee: numeral(serviceFee).format(),
      gst: numeral(subTotal).multiply(0.1).format(),
      total: numeral(subTotal).multiply(0.1).add(subTotal).format(),
      items,
      isMe,
    };
  }
}

import axios from "axios";
import get from "lodash/get";
import { takeLatest, takeEvery, all, call, put } from "redux-saga/effects";
import { setSubmitFailed } from "redux-form";
import {
  AUCTIONS_NEW_REQUEST,
  AUCTIONS_NEW_LOADING,
  AUCTIONS_NEW_SUCCESS,
  AUCTIONS_NEW_FAILED
} from "./newAction";

import { auctionsAPI } from "@config/api";

// create order
export function* watchAuctionCreate() {
  yield takeEvery(AUCTIONS_NEW_REQUEST, auctionNew);
}

export function* auctionNew(action) {
  yield put({ type: AUCTIONS_NEW_LOADING });
  const { res, err } = yield call(auctionNewApi, action.payload);
  const status = get(res, "status");
  const codes = [200, 204];
  if (codes.includes(status)) {
    yield put({ type: AUCTIONS_NEW_SUCCESS, data: "Create auction succeed" });
    return;
  }
  yield all([
    put({
      type: AUCTIONS_NEW_FAILED,
      error: "Create auction failed"
    }),
    put(setSubmitFailed("AuctionNewForm", "Create auction failed"))
  ]);
}

/**
 * auctionNewApi
 * @param  {[type]} payload [description]
 * @return {[type]}         [description]
 *
 * @example
 * payload
 * {
 *   auction: {
 *    unit: 2,
 *    category_id: 1,
 *    state: ‘abc’,
 *    price: 13.3,
 *    gov_fee: 13,
 *    expire_at: Time.zone.now.to_s(:iso8601),
 *    fishing_season: ‘2018-2019’,
 *    description: ‘this is description’
 *   }
 * }
 */

function auctionNewApi(payload) {
  return axios
    .post(auctionsAPI(), { auction: payload })
    .then(res => ({
      res: res
    }))
    .catch(err => ({
      err
    }));
}

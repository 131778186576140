import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";
import { Link } from "react-router-dom";

import { Loading, TimeCountDown } from "@components";
import { get, isEmpty } from "lodash";

const Auction = ({ data, loading, title }) => {
  if (isEmpty(data)) {
    return null;
  }
  return (
    <div className="">
      <div className="bg-white pt-6 pb-4 md:pb-6 rounded-lg shadow">
        <div className="text-sm font-bold leading-normal text-blue-gray-500 px-4 md:px-6">
          {title}
        </div>
        <div className="mt-2">
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : (
            map(data, (item, index) => {
              const Tag = get(item, "status.approved", false) ? Link : "div";
              const totalUnits = get(item, "show.totalUnits", "");
              const login = get(item, "user.login", false);
              return (
                <Tag
                  to={get(item, "show.url", "")}
                  className="px-4 md:px-6 hover:bg-gray-50 li-hover block"
                  key={index}
                >
                  <div className="py-4 md:py-6 border-b-1 border-blue-gray-200 last-border-0">
                    <div className="flex justify-between items-center">
                      <div className="text-sm font-medium leading-normal">
                        {get(item, "show.expiredAt", "") && (
                          <TimeCountDown
                            date={get(item, "show.expiredAt", "")}
                            now={() => {
                              return window.moment.now();
                            }}
                            theme="blue"
                            themeClosed="danger"
                            timeClosed={12 * 3600 * 1000}
                            onComplete={() => {
                              window.location.reload();
                            }}
                            showDate={true}
                            leadingIcon={
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="currentColor"
                              >
                                <path d="M7.00016 13.6667C5.23205 13.6667 3.53636 12.9643 2.28612 11.7141C1.03588 10.4638 0.333496 8.76812 0.333496 7.00001C0.333496 5.2319 1.03588 3.53621 2.28612 2.28596C3.53636 1.03572 5.23205 0.333344 7.00016 0.333344C8.76827 0.333344 10.464 1.03572 11.7142 2.28596C12.9645 3.53621 13.6668 5.2319 13.6668 7.00001C13.6668 8.76812 12.9645 10.4638 11.7142 11.7141C10.464 12.9643 8.76827 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7714 10.7714 10.7712C11.7716 9.77105 12.3335 8.4145 12.3335 7.00001C12.3335 5.58552 11.7716 4.22897 10.7714 3.22877C9.77121 2.22858 8.41465 1.66668 7.00016 1.66668C5.58567 1.66668 4.22912 2.22858 3.22893 3.22877C2.22873 4.22897 1.66683 5.58552 1.66683 7.00001C1.66683 8.4145 2.22873 9.77105 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM7.66683 6.72668L9.36016 8.41334C9.4857 8.53888 9.55622 8.70914 9.55622 8.88668C9.55622 9.06421 9.4857 9.23447 9.36016 9.36001C9.23463 9.48555 9.06436 9.55607 8.88683 9.55607C8.7093 9.55607 8.53903 9.48555 8.4135 9.36001L6.5335 7.46668C6.40789 7.34359 6.336 7.17586 6.3335 7.00001V4.33334C6.3335 4.15653 6.40373 3.98696 6.52876 3.86194C6.65378 3.73691 6.82335 3.66668 7.00016 3.66668C7.17697 3.66668 7.34654 3.73691 7.47157 3.86194C7.59659 3.98696 7.66683 4.15653 7.66683 4.33334V6.72668Z" />
                              </svg>
                            }
                          />
                        )}
                      </div>
                      {!get(item, "status.approved", false) && (
                        <div className="text-red-500 text-sm font-medium leading-normal">
                          Under review
                        </div>
                      )}
                    </div>
                    <div className="cursor-pointer md:flex justify-between items-center mt-4">
                      <div className="flex-1 text-base font-medium leading-normal text-blue-gray-900 md:mr-3 textcolor-hover">
                        {get(item, "show.title", "")}
                      </div>
                      <div className="flex justify-between items-center mt-4 md:mt-0">
                        <div className="text-base font-medium leading-normal text-blue-gray-900">
                          {`${totalUnits && `${totalUnits} · `}${get(
                            item,
                            "show.totalWeight",
                            ""
                          )}`}
                        </div>
                        <div className="ml-4 md:ml-10">
                          <button
                            className={cx(
                              "text-sm h-10 leading-10 font-semibold text-center rounded-full border border-fish-primary text-fish-primary buttoncolor-hover whitespace-no-wrap",
                              { "w-32": login, "w-40": !login }
                            )}
                          >
                            {get(item, "show.price.value", "")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tag>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

Auction.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

Auction.defaultProps = {
  data: [],
  loading: false,
  title: "",
};

export default Auction;

import React from "react";

/**
 * import lib tools
 */
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEmpty } from "lodash";
/**
 * import local tools
 */

import { unitOfSaleMap } from "@core";
import toggleUnitTextV2 from "@utils/toggleUnitTextV2";

/**
 * import local components
 */
import TextField from "@components/reduxform/text";
import { Button } from "@components";

/**
 * [Component] TradeForm for "place bid", "buy now", "place ask", "sell now"
 *
 * @example
  <TradeForm
    loading={true|false}
    theme="danger"
    buttonText="Buy Now | Place Bid"
    priceShow={true/false}
    unitName="shares"
    unitOfSale="blocks | kg"
    priceError=""|"Some error text"
    amountError=""|"Some error text"
    data={{
      price: 12,
      amount: 3,
      amountShow: 3000,
      items: [ {price: "$10.00/kg", amount: "20kg", itemTotal: "$200.00"} ],
      serviceFee: "$10.00",
      subTotal: "$10.00",
      gst: "$1.00",
      total: "$11.00",
      isMe: true|false
    }}
    onSubmit={() => {
      ... ...
    }}
  />
 */
const TradeForm = (props) => {
  const {
    theme,
    loading,
    buttonText,
    priceShow,
    unitName,
    unitOfSale,
    data,
    priceError,
    amountError,
    onSubmit,
    onPriceChange,
    onAmountChange,
    type,
  } = props;

  const { price, amount, amountShow, isMe } = data;

  const buttonClass = classnames({
    "green-600": theme === "success",
    "red-600": theme === "danger",
    "fish-primary": theme === "blue",
  });

  return (
    <div className="trade-form">
      <div className="trade-fields">
        {priceShow && (
          <div>
            <div className="relative hidden">
              <input
                type="text"
                placeholder="0.00"
                value={price}
                onChange={(evt) => {
                  const value = evt.target.value;
                  onPriceChange && onPriceChange(value);
                }}
                className="text-xl font-bold rounded border outline-none px-4-tw"
                style={{
                  height: 56,
                  lineHeight: "30px",
                }}
              />
              <div
                className="absolute inset-y-0 left-0 text-xl font-bold"
                style={{ lineHeight: "56px" }}
              >
                $
              </div>
              <div
                className="absolute inset-y-0 right-0 font-medium"
                style={{ lineHeight: "56px" }}
              >
                per kg
              </div>
            </div>
            <TextField
              symbolClass="size-sm text-blue-gray-400"
              type="number"
              label=""
              id="TradePrice"
              name="price"
              placeholder="Enter price"
              symbol="$"
              rightSymbol="/KG"
              value={price}
              fontSize={14}
              error={priceError}
              onChange={(evt) => {
                const value = evt.target.value;
                onPriceChange && onPriceChange(value);
              }}
            />
          </div>
        )}
        <TextField
          symbolClass="size-sm text-blue-gray-400"
          wrapperClassName="mb-0 mt-0"
          type="number"
          label=""
          id="TradeAmount"
          name="amount"
          placeholder="Enter quantity"
          value={amount}
          fontSize={14}
          error={amountError}
          rightSymbol={
            unitOfSale === unitOfSaleMap.kg
              ? "KG"
              : toggleUnitTextV2(amount, unitName)
          }
          onChange={(evt) => {
            const value = evt.target.value || "";
            const data =
              unitOfSale === unitOfSaleMap.kg ? value : parseInt(value) || "";
            onAmountChange && onAmountChange(data);
          }}
        />
        {amount && amount > 0 ? (
          <div className="my-4 text-center text-xxs text-blue-gray-600">
            {`Equivalent to ${amountShow} ${
              unitOfSale === unitOfSaleMap.kg
                ? toggleUnitTextV2(amountShow, unitName)
                : "kg"
            }`}
          </div>
        ) : null}
      </div>
      <ul className="trade-items text-sm">
        {!isEmpty(data.items) &&
          data.items.map((item, index) => {
            const liClass = classnames("flex justify-between pb-3-tw", {
              "text-fish-red": item.isMe,
            });
            return (
              <li className={liClass} key={index}>
                <div className={item.isMe ? "text-fish-red" : "text-blue-gray-600"}>
                  {item.amount} × {item.price}
                </div>
                <div className="font-semibold">{item.itemTotal}</div>
              </li>
            );
          })}
      </ul>
      {(price && price > 0) || (amount && amount > 0) ? (
        <div>
          {type === "lease-out" ? (
            <div className="text-sm trade-service-fee flex justify-between pb-3-tw">
              <div className="text-blue-gray-600">
                Service fee applies. It will show in your order and invoice.
              </div>
            </div>
          ) : (
            <div className="text-sm trade-service-fee flex justify-between pb-3-tw">
              <div className="text-blue-gray-600">Service fee</div>
              <div className="font-semibold">{data.serviceFee || ""}</div>
            </div>
          )}
          <hr className="border-blue-gray-200 my-2" />
          <div className="trade-subtotal flex justify-between pt-3-tw">
            <div>Subtotal</div>
            <div className="font-semibold">{data.subTotal || ""}</div>
          </div>
        </div>
      ) : null}
      <div
        className={classnames(
          "trade-button mt-6"
          // {'mt-8' : !price || !amount },
          // {'mt-12' : (price && price > 0) || (amount && amount > 0) },
        )}
      >
        <Button
          size="xl"
          color={buttonClass}
          textColor="white"
          className="bg-fish-primary hover:bg-fish-primary-700 flex items-center justify-center"
          full
          type="submit"
          disabled={loading || isMe || !!priceError}
          loading={loading}
          onClick={() => {
            onSubmit();
          }}
        >
          <svg
            className="text-white mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29996 0.0463217C8.50296 0.110164 8.68031 0.237103 8.8062 0.408677C8.93209 0.580252 8.99997 0.787514 8.99996 1.00032V6.00032H13C13.1829 6.00024 13.3623 6.05033 13.5187 6.14515C13.6752 6.23996 13.8026 6.37586 13.8871 6.53806C13.9717 6.70027 14.0101 6.88255 13.9983 7.06509C13.9865 7.24762 13.9248 7.42341 13.82 7.57332L6.81996 17.5733C6.69813 17.7479 6.52379 17.879 6.3223 17.9476C6.12081 18.0162 5.90268 18.0187 5.69965 17.9548C5.49663 17.8909 5.31929 17.7638 5.19345 17.5922C5.06761 17.4205 4.99982 17.2132 4.99996 17.0003V12.0003H0.999957C0.817038 12.0004 0.637602 11.9503 0.481174 11.8555C0.324747 11.7607 0.197322 11.6248 0.112766 11.4626C0.0282109 11.3004 -0.0102353 11.1181 0.00161155 10.9356C0.0134584 10.753 0.0751445 10.5772 0.179957 10.4273L7.17996 0.427322C7.30197 0.253109 7.47635 0.122334 7.67776 0.0539979C7.87917 -0.0143385 8.09713 -0.0166771 8.29996 0.0473218V0.0463217Z"
              fill="white"
            />
          </svg>

          {buttonText}
        </Button>
      </div>
    </div>
  );
};

TradeForm.propTypes = {
  theme: PropTypes.string,
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
  priceShow: PropTypes.bool,
  unitName: PropTypes.oneOf(["", "shares", "units", "pots", "blocks"]),
  unitOfSale: PropTypes.oneOf(["", "kg", "blocks"]),
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
    serviceFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    gst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    amountShow: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }),
  onPriceChange: PropTypes.func,
  onAmountChange: PropTypes.func,
  onSubmit: PropTypes.func,
  currentUserStatus: PropTypes.object,
  type: PropTypes.string,
};

TradeForm.defaultProps = {
  theme: "blue", // success|danger|blue
  buttonText: "",
  loading: false,
  priceShow: true,
  unitName: "",
  unitOfSale: "",
  data: {
    items: [],
    serviceFee: "",
    subTotal: "",
    gst: "",
    total: "",
    price: "",
    amount: "",
    amountShow: "",
  },
  type: "",
  onPriceChange: () => {},
  onAmountChange: () => {},
  onSubmit: () => {},
};

export default TradeForm;

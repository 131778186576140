
export const SUBSCRIBE_REQUEST = "subscribe/request";
export const SUBSCRIBE_LOADING = "subscribe/loading";
export const SUBSCRIBE_SUCCESS = "subscribe/success";
export const SUBSCRIBE_FAILED = "subscribe/failed";

export function subscribeRequest(payload) {
  return {
    type: SUBSCRIBE_REQUEST,
    payload
  };
}

import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, MarketUsecase } from "@core/index";

import {
  fisheryQuotaMarketsAction,
  FISHERY_QUOTA_MARKETS,
} from "@redux/modules_v2/fishery/quotaMarkets/action";

/**
 * Fetch fishery quota market list hooks
 * @param {*} param
 */
export const useFisheryLeaseMarketList = (id) => {
  const dispatch = useDispatch();

  const fetchList = useCallback(() => {
    dispatch(fisheryQuotaMarketsAction.request({ id }));
  }, [id, dispatch]);

  const fisheryQuotaMarketsStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERY_QUOTA_MARKETS, state);
    return Object.assign({}, get(state, "fisheryQuotaMarketsStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = fisheryQuotaMarketsStatus;

  const usecase = new MarketUsecase(data);

  /**
   * did mount fetch list
   */
  useEffect(() => {
    id && fetchList();
  }, [id, fetchList]);

  return [usecase.fisheryLeaseMarket, loading];
};

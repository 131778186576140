export const ASKING_PRICES_UPDATE_PATCH = "asking_prices/update_patch";
export const ASKING_PRICES_UPDATE_LOADING = "asking_prices/update_loading";
export const ASKING_PRICES_UPDATE_SUCCESS = "asking_prices/update_success";
export const ASKING_PRICES_UPDATE_FAILED = "asking_prices/update_failed";
export const ASKING_PRICES_UPDATE_RECEIVED = "asking_prices/update_received";

export function askingPriceUpdatePatch(id, payload = {}) {
  return {
    type: ASKING_PRICES_UPDATE_PATCH,
    id,
    payload
  };
}
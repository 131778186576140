import {
  QUOTA_NEW_SELL_TYPE_CHOOSE,
  QUOTA_NEW_SUPPORT_CHOOSE,
  QUOTA_NEW_PRODUCT_COLLAPSE_TOGGLE,
  QUOTA_NEW_SET_CURRENT_PRODUCT,
  QUOTA_NEW_RESULT_TOGGLE,
  QUOTA_NEW_RESET_STATE
} from "./quotaNewAction";

export default function quotaNewState(state = initStatus, action = {}) {
  let collapseProduct = Object.assign({}, state.collapseProduct);
  switch (action.type) {
    case QUOTA_NEW_SELL_TYPE_CHOOSE:
      return Object.assign({}, state, {
        sellType: action.payload.sellType,
        sellTypeExtend: action.payload.sellTypeExtend,
        step: action.payload.sellType == "" ? 1 : 2
      });
      break;

    case QUOTA_NEW_SUPPORT_CHOOSE:
      return Object.assign({}, state, {
        support: action.payload.support,
        step: action.payload.support == "" ? 2 : 3
      });
      break;
    case QUOTA_NEW_PRODUCT_COLLAPSE_TOGGLE:
      collapseProduct.isOpen = action.payload.isOpen;
      return Object.assign({}, state, {
        collapseProduct
      });
      break;

    case QUOTA_NEW_SET_CURRENT_PRODUCT:
      return Object.assign({}, state, {
        currentProduct: action.payload.product
      });
      break;

    case QUOTA_NEW_RESULT_TOGGLE:
      return Object.assign({}, state, {
        resultShow: action.payload.isOpen
      });
      break;

    case QUOTA_NEW_RESET_STATE:
      return initStatus;

    default:
      return state;
  }
}

const initStatus = {
  step: 1,
  sellType: "", // ''|lease|assets For step1
  sellTypeExtend: "", // ""|"unencumbered"|"encumbered"
  support: "", // ''|onlySelf|withChild|auction For step2 (if offer kind is AskingPrice (offer kind = AskingPrice|OfferPrice))
  collapseProduct: {
    isOpen: false
  },
  currentProduct: null, // null|Object{id:2, name:"...."}
  resultShow: false
};

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";


import {
    STATIC_DATA_FETCH_REQUEST,
    STATIC_DATA_FETCH_LOADING,
    STATIC_DATA_FETCH_SUCCESS,
    STATIC_DATA_FETCH_FAILED,

} from "./action";

import {
  staticAPI,
} from "@config/api";


//fetch static data
export function* watchStaticDataFetch() {
  yield takeEvery(STATIC_DATA_FETCH_REQUEST, staticDataFetch);
}

export function* staticDataFetch(action) {
  yield put({ type: STATIC_DATA_FETCH_LOADING });
  const { err, res } = yield call(staticDataFetchApi, action.payload);
  if (err && !res) {
    yield put({
      type: STATIC_DATA_FETCH_FAILED,
      error: "Get static data failed."
    });
    return;
  }
  yield put({ type: STATIC_DATA_FETCH_SUCCESS, data: res });
}

export function staticDataFetchApi(payload) {
  return axios
    .get(staticAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

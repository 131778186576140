import React from "react";
import isEmpty from "lodash/isEmpty";

import { Loading } from "@components";
import { List } from "@views/markets/auction/list";

const Auctions = ({ data, loading, image }) => {
  if (loading) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className="mt-4 md:mt-10 md:bg-white md:overflow-hidden rounded-lg md:shadow">
      <div className="md:bg-white mb-4 md:mb-0 px-3 py-2 border-b border-blue-gray-200 md:px-6 flex items-center">
        <svg
          className="w-5 h-5 mr-4"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6808 15.0498H2.90293C2.17493 15.0498 1.58417 15.4902 1.58417 16.033V17.8209H12.9995V16.033C12.9995 15.4902 12.4088 15.0498 11.6808 15.0498Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M18.4798 16.3766L9.35514 7.25194L11.1176 5.49265C11.4082 5.74891 11.855 5.73638 12.13 5.4614C12.4175 5.17081 12.4175 4.70517 12.13 4.41457L8.33019 0.61785C8.04269 0.327253 7.57396 0.327253 7.2865 0.61785C6.9959 0.905355 6.9959 1.37409 7.2865 1.66155L7.25525 1.6303L2.43041 6.45196L2.46166 6.4863C2.17415 6.19571 1.70542 6.19571 1.41796 6.4863C1.12736 6.77381 1.12736 7.24254 1.41796 7.53005L5.21468 11.3299C5.50528 11.6174 5.97401 11.6174 6.26151 11.3299C6.54902 11.0393 6.54902 10.5737 6.26151 10.2831L6.29276 10.3143L8.05518 8.55499L17.1767 17.6765C17.536 18.0359 18.1204 18.0359 18.4798 17.6765C18.8391 17.3172 18.8391 16.7359 18.4798 16.3766Z"
            fill="url(#paint1_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="13.0148"
              y1="14.9698"
              x2="11.7049"
              y2="20.3771"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F5C75B" />
              <stop offset="1" stopColor="#EA3423" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="18.7728"
              y1="-0.1064"
              x2="0.649128"
              y2="18.0588"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F5C75B" />
              <stop offset="1" stopColor="#EA3423" />
            </linearGradient>
          </defs>
        </svg>
        <div className="text-sm md:text-base md:py-3 leading-6 font-bold text-blue-gray-900 capitalize">
          Auctions
        </div>
      </div>
      <List data={data} image={image} />
    </div>
  );
};

export default Auctions;

import {
  AUCTIONS_EDIT_REQUEST,
  AUCTIONS_EDIT_SUCCESS,
  AUCTIONS_EDIT_FAILED,
  AUCTIONS_EDIT_RECEIVED,
} from "./editAction";

export const auctionsEditStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTIONS_EDIT_REQUEST:
      return {
        loading: true,
        data: null,
        error: null,
        justFinished: false,
      };

    case AUCTIONS_EDIT_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      };

    case AUCTIONS_EDIT_FAILED:
      return {
        loading: false,
        data: null,
        error: action.error,
        justFinished: true,
      };

    case AUCTIONS_EDIT_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: null,
  error: null,
  justFinished: false,
};

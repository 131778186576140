import get from "lodash/get";
import findIndex from "lodash/findIndex";
import numeral from "@utils/numeral";
import moment from "moment";
import { QuotumEntity, SeasonEntity, Moment, AuctionStatus } from "@core";
/**
 * 获取auction的状态
 * @param {*} auction
 * @returns {Object}
 */

export class AuctionStatusEntity {
  constructor(props) {
    this.data = props;
  }

  //判断新建auction是否已确认可以发布
  get approved() {
    return !!get(this.data, "approved_at", "");
  }

  //判断sell_type是'assets'的情况
  get assets() {
    const type = get(this.data, "sell_type", "");
    const l = ["Assets Exchange", "assets"];
    return l.includes(type);
  }

  //判断当前是否参加了拍卖的买家
  get bidder() {
    return !!get(this.data, "current_user_involved", "");
  }

  //判断auction是否拍过
  get bidded() {
    return !!get(this.data, "bids_count", "");
  }

  //判断auction是否结束
  get end() {
    const usecase = new Moment(get(this.data, "expire_at", ""));
    return usecase.isExpired;
  }

  //判断是否有last_bid
  get hasLastBid() {
    return !!get(this.data, "last_bid", "");
  }

  //判断最终拍卖是否可见
  get lastBidVisible() {
    return get(this.data, "last_bid.price", 0) > 0;
  }

  //判断完成的auction是否可以显示最终价格
  get showAllPrice() {
    const usecase = new Moment(get(this.data, "showing_all_price_at", ""));
    return usecase.isExpired;
  }

  //判断是auction否为Reserve Price
  get reservePrice() {
    return !!get(this.data, "reserve_price", 0);
  }

  //判断是auction否为 Under Reserve
  get underReserve() {
    // if (!this.bidded) {
    //   return false;
    // }
    // if (get(this.data, "last_bid.price", 0) < 0) {
    //   return false;
    // }
    return (
      get(this.data, "last_bid.price", 0) < get(this.data, "reserve_price", 0)
    );
  }

  //判断拍卖结束后是否成功
  get success() {
    return get(this.data, "success?", false);
  }

  //判断points 是否刚刚消费过
  get consumed() {
    return !!get(this.data, "isConsumed", "");
  }

  //判断当前用户是否为卖家
  seller(currentUserId) {
    const userId = get(this.data, "cognito_id", -1);
    return userId == currentUserId;
  }

  //判断当前用户是否是最高出价的买家
  highestBidder(currentUserId) {
    const userId = get(this.data, "last_bid.cognito_id", -1);
    return userId == currentUserId;
  }

  //前端当前用户是否是与最高价冲突的卖家
  collisionBidder(currentUserId) {
    const userId = get(this.data, "last_bid.collision_cognito_id", -1);
    return userId == currentUserId;
  }

  //判断auction 是否存在final bid
  get hasFinalBid() {
    // return true
    return !!get(this.data, "final_price_expired_at", "");
  }

  //判断auction final expired 是否结束
  get finalBidEnd() {
    if (!this.hasFinalBid) {
      return false;
    }
    const usecase = new Moment(get(this.data, "final_price_expired_at", ""));
    return usecase.isExpired;
  }
  //判断auction是否有fixed price
  get hasFixedPrice() {
    return !!get(this.data, "fixed_price", "");
  }

  //判断auction是否为buy it now 导致拍卖成功
  //成功买家显示buy it now 价格，失败买家显示拍卖价格
  get isSuccessByFixedPriceSold() {
    return get(this.data, "status", "") === AuctionStatus.fixed_price_sold;
  }

  //判断auction是否为final chance accepted 导致拍卖成功
  //显示最后成交的 final offer 价格， 成功买家显示 final offer 价格失败买家显示拍卖价格
  get isSuccessByFinalChanceAccepted() {
    return get(this.data, "status", "") === AuctionStatus.final_chance_accepted;
  }

  //判断auction是否为bid导致的成功
  //成功失败的卖家显示自己最后的 bid
  get isSuccessByAuctionBid() {
    return get(this.data, "status", "") === AuctionStatus.success;
  }

  //判断auction是否是拒绝final bid 导致的失败
  //最高买家显示最后买家的 final offer 价格， 失败买家显示拍卖价格

  get isFailedByFinalChanceRejected() {
    return get(this.data, "status", "") === AuctionStatus.final_chance_rejected;
  }

  //判断auction是否是等待final bid 确认
  get isFinalChanceSet() {
    return get(this.data, "status", "") === AuctionStatus.final_chance_set;
  }

  //判断auction是否是reserve not met 导致的失败
  //显示最后的买家 highest bid
  get isFailedByReserveNotMet() {
    return (
      get(this.data, "status", "") === AuctionStatus.failed &&
      this.bidded &&
      this.reservePrice &&
      this.underReserve
    );
  }

  //判断是否是no one bid 导致的失败
  get isFailedByNoOneBid() {
    return (
      get(this.data, "status", "") === AuctionStatus.failed && !this.bidded
    );
  }

  //判断auction是否是在进行中
  get isPending() {
    return get(this.data, "status", "") === AuctionStatus.pending;
  }

  //判断auction是否已经结束
  get isCompleted() {
    return (
      get(this.data, "status", "") &&
      get(this.data, "status", "") !== AuctionStatus.pending
    );
  }

  //判断是否是winner
  get isWinner() {
    return get(this.data, "current_user_win", false);
  }

  //判断是否交了押金
  get hasDeposited() {
    return get(this.data, "current_user_deposited", false);
  }
}

/**
 * 获取所有auction显示的方法
 * @param {*} auction
 * @returns {Object}
 */

export class AuctionShowEntity {
  static getLogo(fisheries = [], id = "") {
    const index = findIndex(fisheries, { id: id });
    const logoImage = index === -1 ? "" : fisheries[index].logoImage;
    return logoImage;
  }

  constructor(props) {
    this.data = props;
  }

  //获取 id
  get id() {
    return get(this.data, "id", "");
  }

  //显示 title
  get title() {
    return get(this.data, "title", "");
  }

  //显示 custom title
  get customTitle() {
    return get(this.data, "custom_title", "");
  }

  //显示 exprie at
  get expiredAt() {
    // return moment().add(70, "s").format();
    return get(this.data, "expire_at", "");
  }

  //显示 expire at str
  get expiredAtStr() {
    return moment(this.expiredAt).format("ddd, D MMM, h:mma");
    // return Moment.getFormated(this.expiredAt);
  }

  //显示zone
  get zone() {
    const zone = get(this.data, "zone", "");
    if (zone === "N/A") {
      return "";
    }
    return zone;
  }

  //显示 category name
  get categoryName() {
    return get(this.data, "category_name", "");
  }

  //显示state
  get state() {
    return get(this.data, "state", "");
  }

  //显示auction name
  get name() {
    if (this.customTitle) {
      return this.customTitle;
    }
    return this.quotumName;
  }

  //显示quotum name
  get quotumName() {
    return this.quotum.name;
  }

  //显示 price
  get startPrice() {
    return get(this.data, "price", 0);
  }

  //显示 price str
  startPriceStr(weightUnit) {
    const str = weightUnit ? `/${weightUnit}` : "";
    return `${numeral(this.startPrice).format()}${str}`;
  }

  //显示current price
  get currentPrice() {
    return get(this.data, "current_price", "");
  }

  //显示end price
  get endPrice() {
    return get(this.data, "end_price", 0);
  }

  //显示fishing type
  get fishingType() {
    return get(this.data, "fishing_type", "");
  }

  //显示last bid 的 user name
  get lastBidUserName() {
    return get(this.data, "last_bid.user_name");
  }

  //显示last bid 的 price
  get lastBid() {
    return new AuctionBidEntity(get(this.data, "last_bid", {}));
  }

  //显示 status (首字母大写，success->Sold at auction，final chance accepted->Sold via Final Offer)
  get bidStatus() {
    let status = get(this.data, "status", "").split("_").join(" ");
    if (status == "success") {
      return "Sold at auction";
    }
    if(status == "fixed price sold") {
      return "Sold via Buy It Now";
    }
    if (status == "final chance accepted") {
      return "Sold via Final Offer";
    }
    return status.trim().replace(status[0], status[0].toUpperCase());
  }

  //显示current user last bid
  get currentUserLastBid() {
    return new AuctionBidEntity(get(this.data, "current_user_last_bid", {}));
  }

  //显示历史最低和最高价
  get pastPriceRange() {
    return get(this.data, "past_price_range", []);
  }

  //显示quotum
  get quotum() {
    return new QuotumEntity(get(this.data, "quota", {}));
  }

  //显示fishing season
  get season() {
    return new SeasonEntity(get(this.data, "fishing_season") || {});
  }

  //显示step price
  get stepPrice() {
    return get(this.data, "step_price", 0);
  }

  //显示当前用户的max bid
  get currentUserAuctionPrice() {
    return get(this.data, "current_user_auction_price", "");
  }

  //显示当前用户 price str
  currentUserAuctionPriceStr(weightUnit) {
    const str = weightUnit ? `/${weightUnit}` : "";
    return `${numeral(this.currentUserAuctionPrice).format()}${str}`;
  }

  get bidCount() {
    return get(this.data, "bids_count", "");
  }

  get fisheryId() {
    return get(this.data, "fishery_id", "");
  }

  get url() {
    if (!this.fisheryId) {
      return "/";
    }
    return `/auctions/${this.id}`;
  }

  get description() {
    return get(this.data, "description", "");
  }

  get additionalInformation() {
    return get(this.data, "additional_information", {});
  }

  get finalPriceExpiredAt() {
    // return "2021-11-14T18:03:22.388+10:00"
    return get(this.data, "final_price_expired_at", "");
  }

  get isSA() {
    return this.quotum.stateAbbr === "SA";
  }

  //显示reserve price
  get reservePrice() {
    return get(this.data, "reserve_price", 0);
  }

  get unit() {
    return get(this.data, "unit", 0);
  }

  get unitName() {
    return get(this.data, "units_collection_unit", "");
  }

  reservePriceStr(weightUnit) {
    const str = weightUnit ? `/${weightUnit}` : "";
    if (!this.reservePrice) {
      return "";
    }
    return `${numeral(this.reservePrice).format()}${str}`;
  }

  //显示market type， lease/permanent
  get marketType() {
    return get(this.data, "market_type", "");
  }

  //显示是否是permanent
  get isPermanent() {
    return this.marketType === "permanent";
  }

  //是否显示服务条款condition
  get showTermsAndConditions() {
    return get(this.data, "show_terms_and_conditions", false);
  }

  //显示fix price
  get fixedPrice() {
    return get(this.data, "fixed_price", "");
  }

  //显示order id
  get orderId() {
    return get(this.data, "order_id", "");
  }

  //显示接口返回auction status
  get status() {
    return get(this.data, "status", "");
  }

  // 显示 quota_market_id
  get quotaMarketId() {
    return get(this.data, "quota_market_id", "");
  }

  get createdAt() {
    return get(this.data, "created_at", "");
  }

  get createdAtStr() {
    return Moment.getFormated(this.createdAt);
  }
}

/**
 * 获取所有auction bid显示的方法
 * @param {*} auction
 * @returns {Object}
 * {
 *  id: 4
 *  auction_id: 1
 *  cognito_id: "6d98e03f-a9e8-4a75-a72c-9719890558d2"
 *  user_name: "fsdafasd"
 *  alias_username: "F"
 *  price: -1
 *  created_at: "2020-06-24T15:21:12+10:00"
 *  updated_at: "2020-06-24T15:21:12+10:00"
 * }
 */

export class AuctionBidEntity {
  constructor(props) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id");
  }

  get name() {
    return get(this.data, "alias_username", "");
  }

  get price() {
    return get(this.data, "price", 0);
  }

  priceStr(weightUnit) {
    const str = weightUnit ? `/${weightUnit}` : "";
    if (this.price <= 0) {
      return false;
    }
    return `${numeral(this.price).format()}${str}`;
  }

  get createdAt() {
    return get(this.data, "created_at", "");
  }

  get createdAtStr() {
    return Moment.getFormated(this.createdAt);
  }

  isMe(currentUserId) {
    const userId = get(this.data, "cognito_id", -1);
    return userId == currentUserId;
  }
}

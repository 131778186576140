import * as AWS from "aws-sdk";
import { currentUserCredentials } from "../cognitoAuth/session";
import { bucketData } from "./config";
import * as uuid from 'uuid/v4';

const albumName = "avatars";
const albumKey = encodeURIComponent(albumName) + "/";

function addAvatar(file) {
  return new Promise((resolve, reject) => {
    currentUserCredentials().then(credentials => {
      const bucketName = bucketData().bucketName;
      const client = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName },
        credentials: credentials
      });

      const fileName = file.name.split('.')[0];
      const fileExt = file.name.split('.')[1];

      const avatarKey =  albumKey + uuid(fileName) + '.' + fileExt;

      client.upload({
        Key: avatarKey,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
      }, (err, data) => {
        if (err) {
          // console.error(`Upload avatar failed: ${JSON.stringify(err)}`)
          reject(err)
        } else {
          // console.log(`Upload avatar success: ${JSON.stringify(data)}`)
          resolve(data)
        }
      })
    })
  })
}

export {addAvatar};
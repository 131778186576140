export const ORDERS_RECEIVE_FUND = "orders/receive_fund";
export const ORDERS_RECEIVE_FUND_REQUEST = `${ORDERS_RECEIVE_FUND}_request`;
export const ORDERS_RECEIVE_FUND_SUCCESS = `${ORDERS_RECEIVE_FUND}_success`;
export const ORDERS_RECEIVE_FUND_FAILURE = `${ORDERS_RECEIVE_FUND}_failure`;
export const ORDERS_RECEIVE_FUND_RESET = `${ORDERS_RECEIVE_FUND}_reset`;

export const ordersReceiveFundAction = {
  request: (payload) => {
    return {
      type: ORDERS_RECEIVE_FUND_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDERS_RECEIVE_FUND_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDERS_RECEIVE_FUND_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: ORDERS_RECEIVE_FUND_RESET
    }
  },
};

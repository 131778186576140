import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import cx from "lodash/get";

const Price = ({ data, className }) => {
  const bidderPriceStr = get(data, "show.bidder.price.str", " - ");
  const reservePriceStr = get(data, "show.reservePrice.str", " - ");
  const totalWeight = get(data, "show.totalWeight", " - ");
  const list = [
    { label: "Seller’s reserve", value: reservePriceStr },
    { label: "Your previous max bid", value: bidderPriceStr },
    { label: "Quantity", value: totalWeight },
  ];
  return (
    <div className={cx("border border-blue-gray-100 rounded-lg", className)}>
      <ul className="divide-y divide-blue-gray-200">
        {map(list, (item, index) => {
          return (
            <li className="flex items-center justify-between py-4 last:pb-0" key={index}>
              <div className=" text-blue-gray-500"> {item.label} </div>
              <div className=" text-blue-gray-900 font-bold">
                {" "}
                {item.value}{" "}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Price.propTypes = {};

export default Price;

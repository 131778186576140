import { useCallback, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionListUsecase } from "@core";

import {
  involvedAuctionsAction,
  INVOLVED_AUCTIONS
} from "@redux/modules_v2/account/involvedAuctions/action";

//Hooks
import { useCurrentUser } from "@hooks";

/**
 * Fetch lists hooks
 */
export const useAccountInvolvedAuctions = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const fetchList = useCallback((params) => {
    dispatch(involvedAuctionsAction.request(params));
  }, []);

  const accountInvolvedAuctionsStatus = useSelector((state) => {
    const selector = new LoadingSelector(INVOLVED_AUCTIONS, state);
    return Object.assign({}, get(state, "accountInvolvedAuctionsStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = accountInvolvedAuctionsStatus;
  const usecase = new AuctionListUsecase(data, get(currentUser, "user", {}));

  useEffect(() => {
    fetchList();
  }, []);

  return {
    data: usecase.list,
    loading,
    fetchList,
  };
};

import { useCallback, useState } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, FisheryUsecase } from "@core";

import {
 FISHERY_STICK_LIST,
 fisheryStickListAction
} from "@redux/modules_v2/fishery/stickList/action";

/**
 * Hooks
 * @param {*} 
 */
import { useDeepEffect } from "@hooks";

/**
 * Fetch lists hooks
 */
export const useFisheryStickList = () => {
  const dispatch = useDispatch();

  const fetchList = useCallback(() => {
    dispatch(fisheryStickListAction.request());
  }, []);

  const fisheryStickListStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERY_STICK_LIST, state);
    return Object.assign({}, get(state, "fisheryStickListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = fisheryStickListStatus;
  const usecase = new FisheryUsecase(data);

  /**
   * did mount fetch list
   */
  useDeepEffect(() => {
    fetchList();
  }, []);

  return {
    data: usecase.list,
    options: usecase.options,
    loading,
    fetchList,
  };
};

import { takeLatest, call, put } from "redux-saga/effects";
import { stopSubmit } from "redux-form";
import get from "lodash/get";

import errorString from "@utils/errorString";
import { PermanentApiUsecase } from "@core";
import { PERMANENT_NEW_REQUEST, permanentNewAction } from "./action";

export function* watchPermanentNewFetch() {
  yield takeLatest(PERMANENT_NEW_REQUEST, permanentNewFetch);
}

function* permanentNewFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "new"], action.payload);
    yield put(permanentNewAction.success(res.data));
  } catch (err) {
    yield put(permanentNewAction.failure(errorString(err)));
    yield put(stopSubmit("PermannentNewForm", err));
  }
}

import axios from 'axios';
import { takeLatest, takeEvery, call, put, delay } from 'redux-saga/effects';

import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import {
  EXCHANGE_LIST_FETCH_REQUEST,
  EXCHANGE_LIST_FETCH_SUCCESS,
  EXCHANGE_LIST_FETCH_LOADING,
  EXCHANGE_LIST_FETCH_FAILED
} from './listAction';

import { exchangesAPI } from '@config/api';

//fetch exchanges list
export function* watchExchangesFetch() {
  yield takeLatest(EXCHANGE_LIST_FETCH_REQUEST, exchangesFetch);
}

export function* exchangesFetch(action) {
  yield put({ type: EXCHANGE_LIST_FETCH_LOADING });

  const { err, res } = yield call(exchangesFetchApi);
  if (!err) {
    yield put({ type: EXCHANGE_LIST_FETCH_SUCCESS, data: res });
    return;
  }
  yield put({
    type: EXCHANGE_LIST_FETCH_FAILED,
    error: 'Get exchanges list failed.'
  });
}

export function exchangesFetchApi() {
  let api = exchangesAPI();

  return axios
    .get(api)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

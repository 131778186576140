import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import filter from "lodash/filter";

import { QuotumBalanceEntity } from "@core/index";

import numeral from "@utils/numeral";

export class AccountQuotumBalanceUsecase {
  /**
   * 构造函数
   */
  constructor(props = {}) {
    this.data = props;
  }

  /**
   * 获取 list 方法
   */
  get list() {
    if (!isArray(this.data)) {
      return [];
    }
    return map(this.data, (item) => this.getShow(item));
  }

  /**
   * 获取 show 方法
   */
  get show() {
    if (isArray(this.data) || isEmpty(this.data)) {
      return {};
    }
    return this.getShow(this.data);
  }

  /**
   * 获取 show 方法
   */
  getShow(data = {}) {
    const usecase = new QuotumBalanceEntity(data);
    return {
      id: usecase.id,
      quotaMarketId: usecase.quotaMarketId,
      quotaMarketTitle: usecase.quotaMarketTitle,
      quotaMarketUrl: `/leases/${usecase.quotaMarketId}`,
      value: {
        sold: usecase.sold,
        total: usecase.totalConsigned,
        pending: usecase.pending,
        available: usecase.available,
      },
      str: {
        sold: usecase.getAmountStr(usecase.sold),
        total: usecase.getAmountStr(usecase.totalConsigned),
        pending: usecase.getAmountStr(usecase.pending),
        available: usecase.getAmountStr(usecase.available),
      },
      url: `/account/quotum_balances/${usecase.quotaMarketId}`,
    };
  }

  get consignedList() {
    return filter(this.list, (item) => item.value.total > 0);
  }
}

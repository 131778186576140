export const ORDER_SHOW = "orders/show";
export const ORDER_SHOW_REQUEST = `${ORDER_SHOW}_request`;
export const ORDER_SHOW_CHECK = `${ORDER_SHOW}_check`;
export const ORDER_SHOW_SUCCESS = `${ORDER_SHOW}_success`;
export const ORDER_SHOW_FAILURE = `${ORDER_SHOW}_failure`;


export const orderShowAction = {
  request: (payload) => {
    return {
      type: ORDER_SHOW_REQUEST,
      payload,
    }
  },
  check: (payload) => {
    return {
      type: ORDER_SHOW_CHECK,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ORDER_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ORDER_SHOW_FAILURE,
      error,
    }
  },

};

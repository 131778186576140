import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import get from "lodash/get";

/**
 * Core
 */
import {
  QuotaAttrUsecase,
  AllSpeciesOption,
  AllStatesOption,
} from "@core/index";

/**
 * Hooks
 */
import { useCountriesFetchList, useStatesFetchList } from "@hooks/quotaAttr";
import { useSearchForm } from "@hooks/search";

/**
 * import lib components
 */
import { Button } from "@components";
import { Form, Select } from "@components/formik";

/**
 * Searching Form Pc
 */
const SearchFormPc = ({ autoSubmit, url }) => {
  const [countries] = useCountriesFetchList();
  const [states, statesLoading] = useStatesFetchList({
    country_id: get(countries, "[0][id]"),
  });
  const { initialValues, onSubmit } = useSearchForm({ url });
  const quotaAttr = new QuotaAttrUsecase(states);
  const customStyle = {
    input: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided) => {
      return {
        ...provided,
        ":active": {
          ...provided[":active"],
          color: "#FFFFFF",
        },
        ":hover": {
          ...provided[":hover"],
          color: "#FFFFFF",
        },
        ":focus": {
          ...provided[":focus"],
          color: "#FFFFFF",
        },
      };
    },
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#3f83f8",
      primary50: "#3f83f8",
      primary: "#3f83f8",
    },
  });

  const formRef = React.useRef();

  const handleSubmit = () => {
    if (formRef.current && autoSubmit) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      <Form className="relative flex items-center search-form text-grey-800">
        <div className="flex-1 w-1/4">
          <Select
            name="category_id"
            label="SPECIES"
            onChange={handleSubmit}
            placeholder={AllSpeciesOption.label}
            loadOptions={quotaAttr.getCategoryByTerm}
            styles={customStyle}
            theme={customTheme}
            // isMulti
          />
        </div>
        <hr className="hidden sm:block opacity-25" />
        <div className="flex-1 w-1/4 hidden sm:block">
          <Select
            name="state_id"
            label="STATES"
            onChange={handleSubmit}
            placeholder={AllStatesOption.label}
            options={quotaAttr.states}
            styles={customStyle}
            theme={customTheme}
            isLoading={statesLoading}
            // isMulti
          />
        </div>
        {/* <hr className="hidden sm:block opacity-25" />
        <div className="flex-1 w-1/4 hidden sm:block">
          <Select
            name="market_type"
            label="MARKETS"
            onChange={handleSubmit}
            placeholder={AllMarketsOption.label}
            options={MarketOptions}
            styles={customStyle}
            theme={customTheme}
            isMulti
          />
        </div> */}
        <Button
          id="SearchSubmitButton"
          color="fish-primary"
          textColor="white"
          full
          className="flex-shrink-0  hover:bg-fish-primary-700 sm:w-auto"
          type="submit"
        >
          Search
        </Button>
      </Form>
    </Formik>
  );
};

SearchFormPc.propTypes = {
  autoSubmit: PropTypes.bool,
  url: PropTypes.string,
};

SearchFormPc.defaultProps = {
  autoSubmit: false,
  url: "",
};

export default SearchFormPc;

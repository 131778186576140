import { takeLatest, call, put, select } from "redux-saga/effects";
import get from "lodash/get";

import { OrderApiUsecase, OrderUsecase } from "@core";
import errorString from "@utils/errorString";
import {
  ORDER_SHOW_REQUEST,
  ORDER_SHOW_CHECK,
  orderShowAction,
} from "./action";

// order show
export function* watchOrderShowFetchV2() {
  yield takeLatest(ORDER_SHOW_REQUEST, orderShowFetch);
}

export function* orderShowFetch(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(orderShowAction.success(res.data));
  } catch (err) {
    yield put(orderShowAction.failure(err));
  }
}

// order check
export function* watchOrderShowCheck() {
  yield takeLatest(ORDER_SHOW_CHECK, orderCheck);
}

export function* orderCheck(action) {
  try {
    const data = yield call(updateOrderShow, action);
    const isFinished = get(data, "isFinished", false);
    if (isFinished) {
      return;
    }
    const onSuccess = get(action, "payload.onSuccess", "");
    typeof onSuccess === "function" && onSuccess(data);
  } catch (err) {
    const onError = get(action, "payload.onError", "");
    typeof onError === "function" && onError();
    yield put(orderShowAction.failure(errorString(err)));
  }
}

export function* updateOrderShow(action) {
  const id = get(action, "payload.id", "");
  yield orderShowFetch({ payload: { id } });
  const order = yield select((state) =>
    get(state, "orderShowStatusV2.data", {})
  );
  const orderUsecase = new OrderUsecase(order);
  return orderUsecase.single;
}

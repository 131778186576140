import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class InvoiceApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.orders = "orders";
    this.model = "invoice";
  }

  /**
   * Invoice Api instance show  with orderId
   * @param {object} payload { orderId: 1 }
   */
  show(payload) {
    this.setApi(`${API}/${this.orders}/${payload.orderId}/${this.model}`);
    return this.get();
  }

  /**
   * Invoice Api instance create with orderId
   * @param {object} payload { orderId: 1 }
   */
  create(payload) {
    this.setApi(`${API}/${this.orders}/${payload.orderId}/${this.model}`);
    return this.post();
  }
}

import { useEffect, useState, useCallback, useRef } from "react";
import get from "lodash/get";
import compact from "lodash/compact";
import capitalize from "lodash/capitalize";
import numeral from "@utils/numeral";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import union from "lodash/union";
import without from "lodash/without";
import mapKeys from "lodash/mapKeys";
import join from "lodash/join";
import includes from "lodash/includes";
import toString from "lodash/toString";
import size from "lodash/size";

/**
 * import hooks
 */
import { useDeepEffect } from "@hooks";

const defaultValues = {
  state_id: [],
  category_id: [],
};

export const useMarketListfilter = ({ states, categories, cb }) => {
  const [initialValues, setInitalValues] = useState(defaultValues);
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef()

  const onSubmit = useCallback((values) => {
    let params = {};
    mapKeys(values, (value, key) => {
      if (!isEmpty(value)) {
        params[key] = join(value, ",");
      }
    });
    typeof cb === "function" && cb(params);
  }, []);

  const onSelect = useCallback(
    (value) => {
      setInitalValues(value);
      onSubmit(value);
    },
    [initialValues]
  );

  const resetValues = useCallback((key) => {
    let data = { ...initialValues };
    if (!key) {
      data = defaultValues;
    } else {
      data[key] = [];
    }
    setInitalValues(data);
    onSubmit(data);
  }, []);

  const onChange = useCallback(
    (e) => {
      let data = { ...initialValues };

      const value = e.target.value;
      const name = e.target.name;
      const checked = e.target.checked;

      if (checked) {
        data[name] = union(data[name], [value]);
      } else {
        data[name] = without(data[name], value);
      }
      setInitalValues(data);
      onSubmit(data);
    },
    [initialValues]
  );

  const getData = useCallback((states, categories) => {
    const statesData = {
      id: "state_id",
      name: "States",
      options: map(states, (o) => ({
        value: o.id,
        label: o.name,
        checked: false,
      })),
      defaultOpen: true,
    };
    const categoriesData = {
      id: "category_id",
      name: "Categories",
      options: map(categories, (o) => ({
        value: o.id,
        label: o.name,
        checked: false,
      })),
      defaultOpen: false,
    };
    return [statesData, categoriesData];
  }, []);

  const getChecked = useCallback(
    (key, value) => {
      let data = { ...initialValues };
      if (value === "all") {
        return isEmpty(data[key]);
      }
      return includes(data[key], toString(value));
    },
    [initialValues]
  );

  const getQuantity = useCallback(
    (key) => {
      let data = { ...initialValues };
      let quantity = 0;
      if (key) {
        quantity = size(data[key]);
      } else {
        mapKeys(data, (value, _key) => {
          quantity = quantity + size(value);
        });
      }
      return quantity;
    },
    [initialValues]
  );

  const handleSelect = useCallback(
    ({value}) => {
      let data = { ...initialValues };
      if (!getChecked("state_id", value)) {
        data.state_id = [...data.state_id, toString(value)];
        setInitalValues(data);
        onSubmit(data);
      } else {
        handleDeselect(value);
      }
      setIsOpen(true);
    },
    [initialValues]
  );

  const handleDeselect = useCallback(
    (value) => {
      let data = { ...initialValues };
      data.state_id = data.state_id.filter((el) => el != toString(value));
      setInitalValues(data);
      onSubmit(data);
      setIsOpen(true);
    },
    [initialValues]
  );

  useDeepEffect(() => {
    if (!isEmpty(states) && !isEmpty(categories)) {
      const data = getData(states, categories);
      setOptions(data);
    }
  }, [states, categories]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && selectRef.current && !selectRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen])

  return {
    getChecked,
    getQuantity,
    handleDeselect,
    handleSelect,
    initialValues,
    isOpen,
    onChange,
    onSelect,
    options,
    resetValues,
    setIsOpen,
    selectRef,
  };
};

import React from "react";
import { StripeProvider } from "react-stripe-elements";

/**
 * import common components
 */
import { stripePlatformClientId } from "@config/stripe";
import { Modal, useModal } from "@components";

/**
 * import local components
 */

import Checkout from "./_checkout";

const ModalDeposit = (props) => {
  const { modal, toggleModal, handleSubmit } = props;
  const [modalProps] = useModal(
    {
      title: <div className="mb-4">Security Deposit</div>,
      content: (
        <StripeProvider apiKey={stripePlatformClientId()}>
          <Checkout handleSubmit={handleSubmit} />
        </StripeProvider>
      ),
      showClose: true,
      onClosed: () => {
        typeof toggleModal === "function" && toggleModal();
      },
      size: "xl",
      isVertical: true,
    },
    modal
  );
  return <Modal {...modalProps} />;
};

export default ModalDeposit;

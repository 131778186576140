import React, { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * lib tools
 */
import get from "lodash/get";

/**
 * Hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * import local tools
 */

import { Input, formatInt, formatFixedNumber } from "@views/components/formik";
import { Helper, Loading } from "@components";

const FourthStep = ({ values, setAuctionTitle, loading }) => {
  const market = get(values, "market.market", {}) || {};
  const quantity = get(values, "unit", "") || 1;
  const price = get(values, "price", 0) || 0;
  const reservePrice = get(values, "reserve_price", 0) || 0;
  const isPermanent = get(values, "market_type", "") === "permanent";
  const { unitOfPrice, dynaUnitName, kgAmount, auctionTitle, totalPrice } =
    useMarketUnitName(market, quantity);
  useEffect(() => {
    auctionTitle && setAuctionTitle(auctionTitle);
  }, [auctionTitle]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Input
        label={
          <div className="flex items-center justify-between">
            <span>Starting Price</span>
            <span className="text-right">{totalPrice(price)}</span>
          </div>
        }
        name="price"
        placeholder="Enter price"
        className="form-input-lg"
        leftSymbol="$"
        rightSymbol={`per ${unitOfPrice}`}
        parse={formatFixedNumber}
      />
      <Input
        label={
          <div className="flex items-center justify-between">
            <div>
              <span className="mr-2">
                {`Set a hidden reserve price (${
                  isPermanent ? "recommended" : "optional"
                })`}
              </span>
              <Helper
                content={`This is the price the final bid must meet in order to win the auction. Reserve prices are hidden from bidders during the auction. If the final bid does not meet the reserve price, the winning bidder is displayed the reserve price and has the option of accepting the reserve or submitting a last-and-final offer.`}
              />
            </div>
            <div className="text-right">{totalPrice(reservePrice)}</div>
          </div>
        }
        name="reserve_price"
        placeholder="Enter reserve price"
        className="form-input-lg"
        leftSymbol="$"
        rightSymbol={`per ${unitOfPrice}`}
        parse={formatFixedNumber}
      />

      <Input
        label={
          <div className="flex items-center justify-between">
            <div>
              <span className="mr-2">Buy-it-now price (Optional)</span>
            </div>
          </div>
        }
        name="fixed_price"
        placeholder="Enter buy-it-now price"
        className="form-input-lg"
        leftSymbol="$"
        rightSymbol={`per ${unitOfPrice}`}
        parse={formatFixedNumber}
      />

      <Input
        label={
          <div className="flex items-center justify-between">
            <span>Unit</span>
            <span>{kgAmount}</span>
          </div>
        }
        name="unit"
        placeholder="Enter unit"
        className="form-input-lg"
        rightSymbol={dynaUnitName}
        parse={formatInt}
      />
    </>
  );
};

FourthStep.propTypes = {
  setAuctionTitle: PropTypes.func,
  values: PropTypes.object,
  loading: PropTypes.bool,
};

FourthStep.defaultProps = {
  setAuctionTitle: () => {},
  values: {},
  loading: false,
};

export default FourthStep;

import {
    AUCTIONS_CATEGORIES_FETCH_REQUEST,
    AUCTIONS_CATEGORIES_FETCH_SUCCESS,
    AUCTIONS_CATEGORIES_FETCH_FAILED,
  
  } from "./catesAction";
  
  export const auctionsCatesStatus = (state = initStatus, action = {}) => {
    switch (action.type) {
      case AUCTIONS_CATEGORIES_FETCH_REQUEST:
        return {
          loading: true,
          data: {},
          error: null,
        };
        break;
  
      case AUCTIONS_CATEGORIES_FETCH_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null,
        };
        break;
  
      case AUCTIONS_CATEGORIES_FETCH_FAILED:
        return {
          loading: false,
          data: {},
          error: action.error,
        };
        break;
  
      default:
        return state;
    }
  };
  
  const initStatus = {
    loading: false,
    data: {},
    error: null,
  };
import React, { Fragment, useState } from "react";
import { Dialog, Disclosure, Listbox, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
  MinusSmIcon,
  PlusSmIcon,
} from "@heroicons/react/solid";

import cx from "classnames";
import get from "lodash/get";
import map from "lodash/map";

import {
  useCategoriesFetchList,
  useStatesFetchList,
  useCountriesFetchList,
  useMarketListfilter,
} from "@hooks";

const Filter = ({ fetchAuctionList, fetchMarketList }) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [countries] = useCountriesFetchList();
  const [states] = useStatesFetchList({
    country_id: get(countries, "[0][id]"),
  });
  const [categories] = useCategoriesFetchList();
  const {
    getChecked,
    getQuantity,
    handleDeselect,
    handleSelect,
    initialValues,
    isOpen,
    isSelected,
    onChange,
    onSelect,
    options,
    resetValues,
    setIsOpen,
    selectRef,
  } = useMarketListfilter({
    states,
    categories,
    cb: (params) => {
      fetchAuctionList(Object.assign({}, { expired: false }, params));
      fetchMarketList(Object.assign({}, { only_stick: true }, params));
    },
  });
  const stateIds = initialValues.state_id;
  const stateOptions = get(options, "[0].options", []);
  return (
    <div>
      <div className="flex items-center space-x-2 lg:hidden">
        <Listbox open={isOpen}>
          <div ref={selectRef}>
            <div className="relative">
              <button
                className="group inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700 bg-white px-3 py-2 rounded-full shadow-sm"
                onClick={() => setIsOpen(!isOpen)}
              >
                States
                {getQuantity("state_id") ? (
                  <div className="flex-shrink-0 -mr-1 ml-2 h-5 w-5 rounded-full bg-gray-800 text-white text-xs flex items-center justify-center">
                    {getQuantity("state_id")}
                  </div>
                ) : (
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                )}
              </button>
              <Transition
                as={Fragment}
                show={isOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <ul className="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 py-4 space-y-2">
                  {map(stateOptions, (option, index) => {
                    return (
                      <li key={option.value} className="px-4 flex items-center">
                        <input
                          id={`filter-state-id-${index}-select`}
                          name="state_id"
                          defaultValue={option.value}
                          type="checkbox"
                          checked={getChecked("state_id", option.value)}
                          className="h-4 w-4 border-gray-300 rounded text-fish-primary focus:ring-fish-primary"
                          onChange={onChange}
                        />
                        <label
                          htmlFor={`filter-state-id-${index}-select`}
                          className="ml-3 text-sm text-gray-600"
                        >
                          {option.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </Transition>
            </div>
          </div>
        </Listbox>
        <button
          type="button"
          className="group inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700 bg-white px-3 py-2 rounded-full shadow-sm"
          onClick={() => setMobileFiltersOpen(true)}
        >
          More Filters
          {getQuantity() ? (
            <div className="flex-shrink-0 -mr-1 ml-2 h-5 w-5 rounded-full bg-gray-800 text-white text-xs flex items-center justify-center">
              {getQuantity()}
            </div>
          ) : (
            <ChevronDownIcon
              className="flex-shrink-0 -mr-1 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          )}
        </button>
        <button
          type="button"
          className="group inline-flex justify-center text-sm text-gray-500 hover:text-gray-700 bg-white px-3 py-2 rounded-full shadow-sm"
          onClick={() => resetValues()}
        >
          View all
        </button>
      </div>
      {/* Filters */}
      <form className="hidden lg:block">
        <h3 className="sr-only">Categories</h3>
        {options.map((section, index) => (
          <Disclosure
            as="div"
            key={section.id}
            className={cx("border-gray-200 pb-6", {
              "pt-6": index > 0,
              "border-b": options.length > index + 1,
            })}
            defaultOpen={section.defaultOpen}
          >
            {({ open }) => (
              <>
                <h3 className="-my-3 flow-root">
                  <Disclosure.Button className="py-3 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                    <span className="font-medium text-gray-900">
                      {section.name}
                    </span>
                    <span className="ml-6 flex items-center">
                      <ChevronDownIcon
                        className={cx("h-5 w-5 transition-all ease-linear", {
                          "transform rotate-180": open,
                        })}
                      />
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="filter-view-all-pc"
                        type="checkbox"
                        checked={getChecked(section.id, "all")}
                        className="h-4 w-4 border-gray-300 rounded text-fish-primary focus:ring-fish-primary"
                        onChange={() => {
                          resetValues(section.id);
                        }}
                      />
                      <label
                        htmlFor="filter-view-all-pc"
                        className="ml-3 text-sm text-gray-600"
                      >
                        All
                      </label>
                    </div>
                    {section.options.map((option, optionIdx) => (
                      <div key={option.value} className="flex items-center">
                        <input
                          id={`filter-${section.id}-${optionIdx}`}
                          name={section.id}
                          defaultValue={option.value}
                          type="checkbox"
                          checked={getChecked(section.id, option.value)}
                          className="h-4 w-4 border-gray-300 rounded text-fish-primary focus:ring-fish-primary"
                          onChange={onChange}
                        />
                        <label
                          htmlFor={`filter-${section.id}-${optionIdx}`}
                          className="ml-3 text-sm text-gray-600"
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </form>
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                <h3 className="sr-only">Categories</h3>
                {options.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="border-t border-gray-200 px-4 py-6"
                    defaultOpen={section.defaultOpen}
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              <ChevronDownIcon
                                className={cx(
                                  "h-5 w-5 transition-all ease-linear",
                                  {
                                    "transform rotate-180": open,
                                  }
                                )}
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            <div className="flex items-center">
                              <input
                                id="filter-view-all-pc"
                                type="checkbox"
                                checked={getChecked(section.id, "all")}
                                className="h-4 w-4 border-gray-300 rounded text-fish-primary focus:ring-fish-primary"
                                onChange={() => {
                                  resetValues(section.id);
                                }}
                              />
                              <label
                                htmlFor="filter-view-all-pc"
                                className="ml-3 text-sm min-w-0 flex-1 text-gray-500"
                              >
                                All
                              </label>
                            </div>
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={section.id}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  checked={getChecked(section.id, option.value)}
                                  className="h-4 w-4 border-gray-300 rounded text-fish-primary focus:ring-fish-primary"
                                  onChange={onChange}
                                />
                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm min-w-0 flex-1 text-gray-500"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

Filter.propTypes = {};

export default Filter;

import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";

const Price = ({ data }) => {
  const totalWithGST = get(data, "show.totalPrice.str.totalWithGST", "");
  const reservePrice = get(data, "show.reservePrice.value", 0);
  const reservePriceLabel = get(data, "show.reservePrice.label", "");
  const bidCount = get(data, "show.bidCount", "");

  return (
    <div className="">
      {!reservePrice ? (
        <div className="lg:text-center mb-3">
          <div className="inline-block rounded-full bg-fish-primary text-white px-3 py-1 text-xs leading-4 capitalize">
            No reserve
          </div>
        </div>
      ) : null}
      <div className="text-sm font-normal leading-snug uppercase text-blue-gray-500 lg:text-center">
        {get(data, "show.price.label", "")}
      </div>
      <div className="flex lg:block items-center space-x-4 lg:space-x-0 mt-2">
        <div className="text-xl font-bold leading-snug text-blue-gray-900 text-center break-words">
          {get(data, "show.price.str", "")}
        </div>
        {totalWithGST ? (
          <div className="text-sm text-center text-blue-gray-700 mt-1 hidden lg:block">
            {`${totalWithGST} subtotal including GST`}
          </div>
        ) : null}
        {get(data, "show.price.status", "") ? (
          <div className="text-sm text-center text-blue-gray-700 mt-1 whitespace-no-wrap">
            {get(data, "show.price.status", "")}
          </div>
        ) : null}
      </div>
    </div>
  );
};

Price.propTypes = {};

export default Price;

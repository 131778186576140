
export const PRODUCTS_SHOW_FETCH_REQUEST = "products/fetch_show_request";
export const PRODUCTS_SHOW_FETCH_LOADING = "products/fetch_show_loading";
export const PRODUCTS_SHOW_FETCH_SUCCESS = "products/fetch_show_success";
export const PRODUCTS_SHOW_FETCH_FAILED = "products/fetch_show_failed";
export const PRODUCTS_SHOW_FETCH_RECEIVED = "products/fetch_show_received";


export function fetchProductShow(payload) {
  return {
    type: PRODUCTS_SHOW_FETCH_REQUEST,
    payload
  };
}
export const WALLET_TOPUP = "wallets/topup";
export const WALLET_TOPUP_REQUEST = `${WALLET_TOPUP}_request`;
export const WALLET_TOPUP_SUCCESS = `${WALLET_TOPUP}_success`;
export const WALLET_TOPUP_FAILURE = `${WALLET_TOPUP}_failure`;
export const WALLET_TOPUP_RECEIVED = `${WALLET_TOPUP}_received`;



export const walletTopupAction = {
  request: (payload) => {
    return {
      type: WALLET_TOPUP_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: WALLET_TOPUP_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: WALLET_TOPUP_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: WALLET_TOPUP_RECEIVED,
    }
  },
};


export const ACCOUNT_PROFILE_REGISTER = "account/profile_register";
export const ACCOUNT_PROFILE_REGISTER_REQUEST = `${ACCOUNT_PROFILE_REGISTER}_request`;
export const ACCOUNT_PROFILE_REGISTER_SUCCESS = `${ACCOUNT_PROFILE_REGISTER}_success`;
export const ACCOUNT_PROFILE_REGISTER_FAILURE = `${ACCOUNT_PROFILE_REGISTER}_failure`;
export const ACCOUNT_PROFILE_REGISTER_RESET = `${ACCOUNT_PROFILE_REGISTER}_reset`;

export const accountProfileRegisterAction = {
  request: payload => {
    return {
      type: ACCOUNT_PROFILE_REGISTER_REQUEST,
      payload,
    };
  },
  success: data => {
    return {
      type: ACCOUNT_PROFILE_REGISTER_SUCCESS,
      data,
    };
  },
  failure: error => {
    return {
      type: ACCOUNT_PROFILE_REGISTER_FAILURE,
      error,
    };
  },
  reset: () => {
    return {
      type: ACCOUNT_PROFILE_REGISTER_RESET,
    };
  },
};

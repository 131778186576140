import React from "react";

/**
 * import lib tools & components
 */
import PropTypes from "prop-types";
import { Modal, useModal } from "@components";
/**
 * [Component] Modal Wrapper
 *
 * @example
 */
const ModalWrapper = ({
  title,
  subTitle,
  isOpen,
  onClosed,
  children,
  size,
  isShowHeader,
  toggleModal,
  ...res
}) => {
  const [modalProps] = useModal(
    {
      title: isShowHeader && (
        <div className="">
          <div className="text-xl md:text-2xl font-bold">{title}</div>
        </div>
      ),
      content: children,
      showClose: isShowHeader,
      onClosed: () => {
        typeof onClosed === "function" && onClosed();
        typeof toggleModal === "function" && toggleModal();
      },
      size,
      isVertical: true,
      ...res,
    },
    isOpen
  );
  return <Modal {...modalProps} />;
};

ModalWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  isShowHeader: PropTypes.bool,
  toggleModal: PropTypes.func,
};

ModalWrapper.defaultProps = {
  isOpen: false,
  title: "",
  subTitle: "",
  onClosed: () => {},
  size: "sm",
  isShowHeader: true,
  toggleModal: () => {},
};

export default ModalWrapper;

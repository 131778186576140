
export const AUCTIONS_EDIT_REQUEST = "auctions/edit_request";
export const AUCTIONS_EDIT_LOADING = "auctions/edit_loading";
export const AUCTIONS_EDIT_SUCCESS = "auctions/edit_success";
export const AUCTIONS_EDIT_FAILED = "auctions/edit_failed";
export const AUCTIONS_EDIT_RECEIVED = "auctions/edit_received";

export function editAuctionRequest(payload) {
  return {
    type: AUCTIONS_EDIT_REQUEST,
    payload
  };
}

import { takeLatest, call, put } from "redux-saga/effects";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_BID_ALL_LIST_REQUEST, auctionBidAllListAction } from "./action";

//Fetch auction bid list
export function* watchAuctionBidAllListFetch() {
  yield takeLatest(AUCTION_BID_ALL_LIST_REQUEST, auctionBidAllListFetch);
}

function* auctionBidAllListFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "bids"], action.payload);
    yield put(auctionBidAllListAction.success(res.data));
  } catch (err) {
    yield put(auctionBidAllListAction.failure(err));
  }
}
 
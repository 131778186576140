import { HOME_PRODUCT_LIST_TAB_CHANGE } from "./productListAction";

export default function productListState(state = initStatus, action = {}) {
  let homeProductList = Object.assign({}, state.homeProductList);
  switch (action.type) {
    case HOME_PRODUCT_LIST_TAB_CHANGE:
      homeProductList.tabId = action.payload.tabId;
      return Object.assign({}, state, {
        homeProductList
      });
      break;
    default:
      return state;
  }
}

const initStatus = {
  homeProductList: {
    tabId: "tab1"
  }
};

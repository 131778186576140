import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

const Collapse = ({ isOpen, icon, title, onClick, children, className }) => {
  return (
    <div
      className={cx(
        "bg-white rounded-lg shadow overflow-hidden",
        className
      )}
    >
      <div
        className={cx("px-4 md:px-6 py-3 flex items-center cursor-pointer", {
          shadow: isOpen,
        })}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="w-6 h-10 flex items-center justify-center mr-2 text-blue-gray-400">
          {icon}
        </div>
        <div className="font-semibold flex-1 text-blue-gray-800">{title}</div>
        <div>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            className="text-blue-gray-500"
            fill="currentColor"
          >
            <path d="M8.3001 0.299611C8.49267 0.155181 8.73088 0.0850624 8.97099 0.102126C9.2111 0.119191 9.437 0.222292 9.60721 0.392503C9.77742 0.562714 9.88052 0.788612 9.89758 1.02872C9.91465 1.26883 9.84453 1.50704 9.7001 1.69961L5.7001 5.69961C5.51317 5.88284 5.26185 5.98547 5.0001 5.98547C4.73835 5.98547 4.48703 5.88284 4.3001 5.69961L0.300099 1.69961C0.15567 1.50704 0.0855507 1.26883 0.102615 1.02872C0.119679 0.788612 0.222781 0.562714 0.392992 0.392503C0.563204 0.222292 0.7891 0.119191 1.02921 0.102126C1.26932 0.0850624 1.50753 0.155181 1.7001 0.299611L5.0001 3.58961L8.3001 0.28961V0.299611Z" />
          </svg>
        </div>
      </div>
      {isOpen && <div className="px-4 md:px-6 py-5">{children}</div>}
    </div>
  );
};

Collapse.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Collapse;

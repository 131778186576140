import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

/**
 * import local component
 */
import { Link, Button } from "@components";

/**
 * [Page] Quota show
 */
const QuotaUnitsForm = ({ state = {} }) => {
  const history = useHistory();

  return (
    <div className="bg-white shadow rounded-lg p-4 md:p-6">
      <div className="text-xl font-bold leading-relaxed text-blue-gray-900">
        {state.quotaUnitId
          ? "Enquire about this listing"
          : "Create a new listing"}
      </div>
      {!state.quotaUnitId && (
        <>
          <div className="text-sm mt-4 text-blue-gray-500 border-b-1 pb-6">
            It's never been easier to lease-out your seasonal quota or sell your
            permanent quota units.
          </div>
          <div className="flex pt-6 pb-3">
            <div>
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <div className="pl-3">
              <div className="text-base font-bold text-blue-gray-900">
                Get more offers
              </div>
              <p className="text-sm text-blue-gray-500 pt-2">
                Easily reach hundreds of fishing operators, wholesale traders,
                and investors.
              </p>
            </div>
          </div>

          <div className="flex pt-3 pb-3">
            <div>
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <div className="pl-3">
              <div className="text-base font-bold text-blue-gray-900">
                Save on fees
              </div>
              <p className="text-sm text-blue-gray-500 pt-2">
                At a fraction of the cost of standard brokerage.
              </p>
            </div>
          </div>

          <div className="flex pt-3 pb-3">
            <div>
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <div className="pl-3">
              <div className="text-base font-bold text-blue-gray-900">
                Expertise at every step
              </div>
              <p className="text-sm text-blue-gray-500 pt-2">
                From setting the right list price to closing, you’ll be
                supported by local industry experts.
              </p>
            </div>
          </div>
        </>
      )}
      <div className="mt-4">
        {state.quotaUnitId ? (
          <Button
            size="xl"
            color="fish-primary"
            textColor="white"
            className="hover:bg-fish-primary-700"
            full
            type="submit"
            // disabled={enquired}
            onClick={() => {
              history &&
                history.push(`/permanents/${state.quotaUnitId}/enquire`);
            }}
            loading={false}
          >
            Enquire
          </Button>
        ) : (
          <div className="md:grid md:grid-cols-2 md:gap-4">
            <Link to="/permanents/new?type=sell" className="md:col-span-1">
              <Button
                size="xl"
                color="fish-primary"
                textColor="white"
                className="hover:bg-fish-primary-700"
                full
              >
                Sell
              </Button>
            </Link>
            <Link to="/permanents/new?type=buy" className="md:col-span-1">
              <Button
                size="xl"
                color="fish-primary"
                textColor="white"
                className="hover:bg-fish-primary-700"
                full
              >
                Buy
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

QuotaUnitsForm.propTypes = {
  state: PropTypes.object,
};

export default QuotaUnitsForm;

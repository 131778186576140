import { AC_CONSUMER_CREATED, REMOVE_AC_CONSUMER } from "./action";

const initializeStates = {
  cable: undefined
};

export const currentCable = (state = initializeStates, action = {}) => {
  switch (action.type) {
    case AC_CONSUMER_CREATED:
      return {
        cable: action.payload
      };
    case REMOVE_AC_CONSUMER:
      // TODO remove consumer
      // state.cable && state.cable.disconnect();
      return initializeStates;
    default:
      return state;
  }
};

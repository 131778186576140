import React from "react";

/**
 * import lib tools
 */
import { get } from "lodash";

/**
 * import local tools & hooks
 */
import { useOrderShow, useOrderPayment, useWalletBalance } from "@hooks";

/**
 * import local component
 */
import Layout from "@views/layout";
import { Loading } from "@components";

import Header from "./_header";
import Notice from "./_notice";
import PaymentForm from "./_payment_form";

/**
 * [Page] Order payment
 */
const Payment = (props) => {
  const { match, history } = props;
  const id = get(match, "params.id");
  const { balance, balanceStr, loading: loadingBalance } = useWalletBalance();
  const { usecase: usecaseOrder, loading: loadingOrderShow } = useOrderShow(id);
  const {
    submit: submitPay,
    loading: loadingPayment,
    money,
    paymentMethod,
    setPaymentMethod,
    payType,
    setPayType,
  } = useOrderPayment(id, balance, usecaseOrder);
  const order = usecaseOrder.single;

  const renderContent = () => {
    if (loadingOrderShow) {
      return <Loading />;
    }

    return (
      <div className="">
        <div className="mx-4 pt-4 md:mx-0 md:pt-0">
          <Header order={order} history={history} />
        </div>
        <div className="mt-8 mx-5 md:mx-0">
          <Notice order={order} />
        </div>
        <div className="mt-8 mx-5 md:mx-0">
          <PaymentForm
            order={order}
            balanceStr={balanceStr}
            loadingBalance={loadingBalance}
            loadingPayment={loadingPayment}
            submitPay={submitPay}
            money={money}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            payType={payType}
            setPayType={setPayType}
          />
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="pb-5 md:pt-10 md:pb-24 bg-gray-50">
        <div className="container">{renderContent()}</div>
      </div>
    </Layout>
  );
};

export default Payment;

export const PERMANENT_MARKETS = "permanent/markets";
export const PERMANENT_MARKETS_REQUEST = `${PERMANENT_MARKETS}_request`;
export const PERMANENT_MARKETS_SUCCESS = `${PERMANENT_MARKETS}_success`;
export const PERMANENT_MARKETS_FAILURE = `${PERMANENT_MARKETS}_failure`;

export const permanentMarketsAction = {
  request: (payload) => {
    return {
      type: PERMANENT_MARKETS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_MARKETS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_MARKETS_FAILURE,
      error,
    }
  },
};

import { useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  offersApplyConfirmAction,
  OFFERS_APPLY_CONFIRM,
} from "@redux/modules_v2/offers/applyConfirm/action";

import Notice from "@utils/noticeNew";

export const useOfferApplyConfirm = (cb) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback((values = {}) => {
    request(values);
  }, []);

  const request = (payload) => {
    dispatch(offersApplyConfirmAction.request(payload));
  };

  const offersApplyConfirmStatus = useSelector((state) => {
    const selector = new LoadingSelector(OFFERS_APPLY_CONFIRM, state);
    return Object.assign({}, get(state, "offersApplyConfirmStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    return () => {
      dispatch(offersApplyConfirmAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!offersApplyConfirmStatus.loading) {
      if (!isEmpty(offersApplyConfirmStatus.data)) {
        Notice.success("You've notified us of a quota consignment");
        dispatch(offersApplyConfirmAction.reset());
        typeof cb === "function" && cb();
      }
      if (offersApplyConfirmStatus.error) {
        Notice.failure(offersApplyConfirmStatus.error);
      }
    }
  }, [offersApplyConfirmStatus.loading]);

  const loading = offersApplyConfirmStatus.loading;
  const data = offersApplyConfirmStatus.data || {};

  return { onSubmit, loading, data };
};

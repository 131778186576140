import React from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import cx from "classnames";
import { useFormikContext, getIn } from "formik";
import WithLabel from "./with_label";

const Checkbox = ({ className, content, name, onChange, onBlur, onFocus, iconSize, errorMsg }) => {
  const { values, handleChange } = useFormikContext();
  const value = getIn(values, name);
  const iconClassName = () => {
    switch (iconSize) {
      case "sm":
        return "w-3 h-3";
      default:
        return "w-4 h-4";
    }
  };
  const change = (e) => {
    const value = e.target.value;
    if (typeof onChange === "function") {
      onChange(value);
    }
    handleChange(e);
  };
  const id = `${name}_${uniqid()}`;
  return (
    <div className={cx("relative flex items-start cursor-pointer", className)}>
      <div className="flex items-center h-5">
        <input
          id={id}
          name={name}
          type="checkbox"
          className={cx("focus:ring-indigo-500 text-indigo-600 rounded", iconClassName)}
          onChange={change}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          checked={!!value}
        />
        <span></span>
      </div>
      <div className={cx("ml-3 text-sm", {"text-fish-red":errorMsg} )}>
        <label htmlFor={id}>{content}</label>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  iconSize: PropTypes.oneOf(["sm", "md"]),
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: "",
  content: "",
  name: "",
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  iconSize: "md",
  errorMsg: null,
  required: false,
};

export default WithLabel("checkbox")(Checkbox);

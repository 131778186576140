export const STATES_LIST = "states/list";
export const STATES_LIST_REQUEST = `${STATES_LIST}_request`;
export const STATES_LIST_SUCCESS = `${STATES_LIST}_success`;
export const STATES_LIST_FAILURE = `${STATES_LIST}_failure`;

export const statesListAction = {
  request: (payload) => {
    return {
      type: STATES_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: STATES_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: STATES_LIST_FAILURE,
      error,
    }
  },
};

import React from "react";
import get from "lodash/get";

/**
 * import local tools & config & hooks
 */
import { useOrderPurchased } from "@hooks";

/**
 * import local component
 */
import Table from "./_table";

const Purchase = () => {
  const {
    usecase: usecaseOrderPurchased,
    loading: loadingOrderPurchased,
    data: pagePurchasedData,
    fetchOrderPurchased,
  } = useOrderPurchased();

  return (
    <div>
      <Table
        data={get(usecaseOrderPurchased, "list", [])}
        pageData={pagePurchasedData}
        loading={loadingOrderPurchased}
        fetchData={fetchOrderPurchased}
        emptyTitle="You have no purchase orders yet"
      />
    </div>
  );
};

Purchase.propTypes = {};

export default Purchase;

import { createSelector } from "reselect";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import {
  AUCTIONS_FETCH_LOADING,
  AUCTIONS_FETCH_SUCCESS,
  AUCTIONS_FETCH_FAILED,
  AUCTIONS_OWN_FETCH_LOADING,
  AUCTIONS_OWN_FETCH_SUCCESS,
  AUCTIONS_OWN_FETCH_FAILED,
  AUCTIONS_INVOLVED_FETCH_LOADING,
  AUCTIONS_INVOLVED_FETCH_SUCCESS,
  AUCTIONS_INVOLVED_FETCH_FAILED,
  AUCTIONS_FETCH_UPDATED
} from "./listAction";

export const auctionsListStatus = (state = initStatus, action = {}) => {
  const updateSelector = createSelector(
    state => state.data,
    (_, payload) => payload,
    (data, { auction_id, result }) => {
      const { entries, ...rest } = data;
      let updateData = cloneDeep(entries);
      updateData = map(updateData || [], o => {
        let lastBid = get(o, "last_bid", {});
        if (o.id === auction_id) {
          lastBid.price = get(result, "humanized_money", 0);
          return Object.assign({}, o, lastBid, { isConsumed: true });
        }
        return Object.assign({}, o, { isConsumed: false });
      });
      return { entries: updateData, ...rest };
    }
  );
  switch (action.type) {
    case AUCTIONS_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };

    case AUCTIONS_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };

    case AUCTIONS_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
    case AUCTIONS_FETCH_UPDATED:
      return {
        loading: false,
        data: updateSelector(state, action.data),
        error: null
      };
    default:
      return state;
  }
};

export const auctionsListOwnStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTIONS_OWN_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;

    case AUCTIONS_OWN_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case AUCTIONS_OWN_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;

    default:
      return state;
  }
};

export const auctionsListInvolvedStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTIONS_INVOLVED_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;

    case AUCTIONS_INVOLVED_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case AUCTIONS_INVOLVED_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: [],
  error: null
};

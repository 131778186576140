import {takeEvery, call, put} from 'redux-saga/effects';
import {
  REFRESH_COGNITO_USER_REQUEST,
  refreshCognitoUserFailed,
  refreshCognitoUserLoading,
  refreshCognitoUserSuccess
} from "./action";
import axios from "axios";
import {refreshCognitoUserAPI} from "@config/api";

function* watchRefreshCognitoUserRequest() {
  yield takeEvery(REFRESH_COGNITO_USER_REQUEST, refreshCognitoUser)
}

function* refreshCognitoUser(action) {
  yield put(refreshCognitoUserLoading())
  let {res, err} = yield call(refreshCognitoUserApi)
  if (err) {
    return yield put(refreshCognitoUserFailed("FAILED"))
  }
  yield put(refreshCognitoUserSuccess("SUCCESS"))
}

function refreshCognitoUserApi() {
  return axios
    .put(refreshCognitoUserAPI())
    .then(res => ({res}))
    .catch(err => ({err}))
}

export {watchRefreshCognitoUserRequest}
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const ConsignBalance = ({ data }) => {
  return (
    <ul>
      <li className="flex items-center justify-between text-xxs py-2">
        <div className="w-0 flex-1">
          <span className="text-blue-gray-500 capitalize">
            Total quota consigned
          </span>
        </div>
        <div className="ml-4 flex-shrink-0">
          <span className="font-medium text-blue-gray-700">{get(data, "str.total", "")}</span>
        </div>
      </li>
      <li className="flex items-center justify-between text-xxs py-2">
        <div className="w-0 flex-1">
          <span className="text-blue-gray-500 capitalize">Listed</span>
        </div>
        <div className="ml-4 flex-shrink-0">
          <span className="font-medium text-blue-gray-700">{get(data, "str.pending", "")}</span>
        </div>
      </li>
      <li className="flex items-center justify-between text-xxs py-2">
        <div className="w-0 flex-1">
          <span className="text-blue-gray-500 capitalize">Available to list</span>
        </div>
        <div className="ml-4 flex-shrink-0">
          <span className="font-medium text-blue-gray-700">{get(data, "str.available", "")}</span>
        </div>
      </li>
    </ul>
  );
};

ConsignBalance.propTypes = {};

export default ConsignBalance;

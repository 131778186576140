
export const PRODUCTS_PRICES_FETCH_REQUEST = "products/fetch_prices_request";
export const PRODUCTS_PRICES_FETCH_LOADING = "products/fetch_prices_loading";
export const PRODUCTS_PRICES_FETCH_SUCCESS = "products/fetch_prices_success";
export const PRODUCTS_PRICES_FETCH_FAILED = "products/fetch_prices_failed";


export function fetchProductPrices(payload) {
  return {
    type: PRODUCTS_PRICES_FETCH_REQUEST,
    payload
  };
}
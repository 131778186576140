import { takeLatest, call, put } from "redux-saga/effects";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_LIST_REQUEST, permanentListAction } from "./action";

// fetch permanent list
export function* watchpermanentListFetch() {
  yield takeLatest(PERMANENT_LIST_REQUEST, permanentListFetch);
}

function* permanentListFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(permanentListAction.success(res.data));
  } catch (err) {
    yield put(permanentListAction.failure(err));
  }
}

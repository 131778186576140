
export const PRICEABLES_PRODUCTS_FETCH_REQUEST = "priceables/fetch_products_request";
export const PRICEABLES_PRODUCTS_FETCH_LOADING = "priceables/fetch_products_loading";
export const PRICEABLES_PRODUCTS_FETCH_SUCCESS = "priceables/fetch_products_success";
export const PRICEABLES_PRODUCTS_FETCH_FAILED = "priceables/fetch_products_failed";


export function fetchPriceablesProductsList() {
  return {
    type: PRICEABLES_PRODUCTS_FETCH_REQUEST,
  };
}

import { takeLatest, call, put } from "redux-saga/effects";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_STATISTIC_REQUEST, permanentStatisticAction } from "./action";

// fetch permanent statistic
export function* watchPermanentStatisticFetch() {
  yield takeLatest(PERMANENT_STATISTIC_REQUEST, permanentStatisticFetch);
}

function* permanentStatisticFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "statistic"], action.payload);
    yield put(permanentStatisticAction.success(res.data));
  } catch (err) {
    yield put(permanentStatisticAction.failure(err));
  }
}

import {
  CHARTS_LAST_SOLD_LOADING,
  CHARTS_LAST_SOLD_SUCCESS,
  CHARTS_LAST_SOLD_FAILED,
} from "./lastSoldAction";

export const chartsLastSoldStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case CHARTS_LAST_SOLD_LOADING:
      return {
        loading: true,
        data: [],
        error: null,
      };
      break;
    case CHARTS_LAST_SOLD_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case CHARTS_LAST_SOLD_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: [],
  error: null,
};

import {
  UPDATE_PROFILE_EVENT_FAILED,
  UPDATE_PROFILE_EVENT_LOADING,
  UPDATE_PROFILE_EVENT_SUCCESS
} from "./action";

function updateProfileEvent(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_PROFILE_EVENT_LOADING:
      return { loading: true, data: null, error: null }
    case UPDATE_PROFILE_EVENT_SUCCESS:
      return { loading: false, data: action.data, error: null }
    case UPDATE_PROFILE_EVENT_FAILED:
      return { loading: false, data: null, error: action.error }
    default:
      return state
  }
}

export { updateProfileEvent }
import React, { useState, useEffect } from "react";

/**
 * import lib tools
 */
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { submit } from "redux-form";
import { currentUserAttributes } from "@utils/cognitoAuth";
import { get, isEmpty, flow } from "lodash";
import Notice from "@utils/noticeNew";

/**
 * action
 */
import { changeEmailRequest } from "@redux/modules/changeEmail/action";
import { refreshCurrentUserReset } from "@redux/modules/auth/action";

/**
 * local component
 */
import { Button } from "@components";
import EmailForm from "./form";

const Email = (props) => {
  const { error } = props;

  const [currentAttributes, setCurrentAttributes] = useState({});
  const currentUserRefreshStatus = useSelector((state) =>
    get(state, "currentUserRefreshStatus", {})
  );

  useEffect(() => {
    props.dispatch(refreshCurrentUserReset());
    return () => {
      props.dispatch(refreshCurrentUserReset());
    };
  }, []);

  useEffect(() => {
    currentUserAttributes().then((attrs) => {
      setCurrentAttributes(attrs);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      Notice.failure(error.message);
      return;
    }
  }, [error]);

  useEffect(() => {
    if (!currentUserRefreshStatus.loading) {
      if (currentUserRefreshStatus.error) {
        Notice.failure("Change email failed");
        return;
      }
      if (currentUserRefreshStatus.data && getUserEmail()) {
        Notice.success("Confirmation code sent to your email");
        props.history.push(getVerifyEmailURI());
      }
    }
  }, [
    currentUserRefreshStatus.loading,
    currentUserRefreshStatus.data,
    currentUserRefreshStatus.error,
  ]);

  const formInitializeValues = () => ({
    email_current: getUserEmail(),
    email: "",
  });

  const getUserEmail = () => {
    return get(currentAttributes, "email");
  };

  const getVerifyEmailURI = () => {
    return `/account/email/verify?old_email=${encodeURIComponent(
      getUserEmail()
    )}`;
  };

  /**
   * callback for quote form submit
   */

  const handleSubmit = (values) => {
    changeEmailRequest(values);
  };

  const changeEmailRequest = ({ email }) => {
    props.changeEmailRequest({ email });
  };

  /**
   * remote submit order form
   */

  const onSubmit = () => {
    props.dispatch(submit("EmailForm"));
  };

  const conetentful_text = get(props.staticData, "change_email") || {};
  return (
    <div className="form gs-form pb-4-tw">
      <h3>{conetentful_text.change_email}</h3>
      <EmailForm
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={formInitializeValues()}
      />
      <Button
        className="capitalize"
        size="lg"
        color="fish-primary"
        full
        onClick={onSubmit}
      >
        {conetentful_text.submit}
      </Button>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: get(state, "changeEmailStatus.loading"),
    data: get(state, "changeEmailStatus.data"),
    error: get(state, "changeEmailStatus.error"),
    staticData: get(state, "globalStaticStatus.data") || {},
    currentUserRefreshStatus: state.currentUserRefreshStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    {},
    bindActionCreators(
      {
        changeEmailRequest,
      },
      dispatch
    ),
    {
      dispatch,
    }
  );
}

const ConnectedEmail = flow(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Email);

export default ConnectedEmail;

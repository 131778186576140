import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

/**
 * import hooks
 */
import { useAuctionCreateFinalBid, useModal } from "@hooks";

/**
 * import local component
 */
import Notice from "@utils/noticeNew";
import { ModalWrapper } from "@views/common";
import { Button } from "@components";

const FinalChanceCard = ({ data, fetchAuctionShow, fetchBidList }) => {
  const id = get(data, "show.id", "");
  const isFinalChanceSet = get(data, "status.isFinalChanceSet", false);
  const hasFinalBid = get(data, "status.hasFinalBid", false);
  const finalBidUrl = get(data, "show.finalBidUrl", "");
  const reservePrice = get(data, "show.reservePrice.value", 0);
  const reservePriceStr = get(data, "show.reservePrice.str", "");

  const { isOpen, toggleModal } = useModal();
  //Auction final bid create
  const { handleSubmit, loading } = useAuctionCreateFinalBid({
    auctionId: id,
    success: () => {
      fetchAuctionShow(id);
      fetchBidList(id);
    },
  });

  const renderOperation = () => {
    if (isFinalChanceSet) {
      return (
        <div className="mt-6 lg:px-10 text-sm lg:text-center">
          <p>
            You have submitted a final offer and are waiting for confirmation
          </p>
        </div>
      );
    }
    return (
      <>
        <div className="mt-6 lg:mt-10 text-sm space-y-6 lg:text-center">
          <p>Your winning bid did not meet the reserve price. </p>
          <p>
            As the highest bidder, you now have the{" "}
            <strong>exclusive option</strong> to either accept the sellers
            reserve instantly, or submitting a one-time Final Offer for the
            seller to consider.
          </p>
        </div>
        <div className="space-y-2 lg:space-y-0 flex flex-col mt-6 lg:text-center">
          <Button
            size="xl"
            color="gray-900"
            textColor="white"
            className="bg-gray-900 flex-1 justify-center whitespace-no-wrap px-0 text-sm"
            href={finalBidUrl}
          >
            View my Final Offer options
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {renderOperation()}
      <ModalWrapper
        title="Accept reserve price"
        isOpen={isOpen}
        toggleModal={() => {
          typeof toggleModal === "function" && toggleModal(false);
        }}
        size="lg"
        contentAlign="left"
      >
        <div className="">
          <div className="py-4 text-sm text-blue-gray-700">
            You can accept the seller's reserve price and instantly win this
            listing.
          </div>
          <div className="grid grid-cols-2 gap-y-3 lg:gap-6 mb-6 lg:mb-10">
            <div className="col-span-1">
              <div className="text-sm font-normal leading-normal text-blue-gray-500">
                Seller’s reserve price
              </div>
              <div className="text-sm leading-normal font-medium text-blue-gray-800 lg:mt-1">
                {reservePriceStr}
              </div>
            </div>
          </div>

          <Button
            size="xl"
            color="gray-900"
            textColor="white"
            className="bg-gray-900"
            full
            type="submit"
            onClick={() => {
              handleSubmit({ price: reservePrice });
            }}
            loading={loading}
            disabled={loading}
          >
            Accept reserve price
          </Button>
        </div>
      </ModalWrapper>
    </>
  );
};

FinalChanceCard.propTypes = {};

export default FinalChanceCard;

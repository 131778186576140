import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class WalletApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "wallets";
  }

  /**
   * Wallet Api instance entries
   */
  entries(payload) {
    this.setApi(`${API}/${this.model}/entries`);
    return this.get(payload);
  }

  /**
   * Wallet Api instance balance
   * @param {object} payload {}
   */
  balance(payload) {
    this.setApi(`${API}/${this.model}/balance`);
    return this.get();
  }

  /**
   * Wallet Api instance topup
   * @param {object} payload {payMethod: "stripe|offline", postData: {amount: 100, fees: 1.87}}
   */
  topup(payload) {
    this.setApi(`${API}/${this.model}/${payload.payMethod}`);
    return this.post(omit(payload, ["payMethod"]));
  }
}

import {takeEvery, all, call, put} from 'redux-saga/effects';
import {updateCurrentUserEmail} from '@utils/cognitoAuth';

import {
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_LOADING,
  CHANGE_EMAIL_REQUEST
} from './action'
import { refreshCurrentUser } from "../auth/action";

function* watchChangeEmailRequest() {
  yield takeEvery(CHANGE_EMAIL_REQUEST, changeEmailRequest)
}

function* changeEmailRequest(action) {
  yield put({type: CHANGE_EMAIL_LOADING})
  let {res, err} = yield call(changeEmailCall, action.payload)

  if (err) {
    yield put({type: CHANGE_EMAIL_FAILED, error: err})
    return
  }
  yield all([put({type: CHANGE_EMAIL_SUCCESS, data: res}), put(refreshCurrentUser())])
}

function changeEmailCall({email}) {
  return updateCurrentUserEmail(email)
    .then(res => ({res}))
    .catch(err => ({err}))
}

export { watchChangeEmailRequest }
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import rootSaga from '@redux/sagas';
import rootReducer from '@redux/reducers';

/**
 * setting Middlewares
 * @type {Array}
 */
let middlewares = [];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

// if (module.hot) {
//   module.hot.accept("./reducers", () => {
//     const nextCombineReducers = require("./reducers").default;
//     store.replaceReducer(nextCombineReducers);
//   });
// }

export default store;

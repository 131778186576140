import React from "react";
import get from "lodash/get";

import PropTypes from "prop-types";

import { Button } from "@components";

const OrderCard = ({ data }) => {
  const orderUrl = get(data, "show.orderUrl", "");

  if (!orderUrl) {
    return (
      <div className="mt-6 lg:mt-10 lg:text-center text-sm">
        <p>
          Your order is being generated. Please refresh this page in a few
          moments to view your order.
        </p>
      </div>
    );
  }
  return (
    <div className="mt-6 lg:mt-10">
      <Button
        href={orderUrl}
        size="lg"
        color="gray-900"
        textColor="white"
        className="hover:bg-gray-700"
        full
      >
        View Order
      </Button>
    </div>
  );
};

OrderCard.propTypes = {};

export default OrderCard;

import { get, map, isArray } from "lodash";

import { PermanentUsecase } from "./show";

export class PermanentListUsecase {
  /**
   * 构造函数
   * @param {Object} props
   */
  constructor(props) {
    this.data = props;
  }

  get list() {
    if (isArray(this.data)) {
      return parseList(this.data);
    }
    return [];
  }

  get listByGroup() {
    return {
      buy: parseList(get(this.data, "buy", [])),
      sell: parseList(get(this.data, "sell", [])),
    };
  }
}

const parseList = (list) => {
  return map(list, (item) => {
    const usecase = new PermanentUsecase(item);
    return {
      price: usecase.price("price_with_symbol"),
      title: usecase.title,
      id: usecase.id,
      expiredAt: usecase.expiredAt,
      markets: usecase.markets,
      status: usecase.status,
      isUnderContract: usecase.isUnderContract,
      isUnderOffer: usecase.isUnderOffer,
    };
  });
};

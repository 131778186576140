import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import every from "lodash/every";
import _values from "lodash/values";
import PropTypes from "prop-types";

import { useDeepEffect } from "@hooks";

const Transition = ({ values, children }) => {
  const [isOpen, setOpen] = useState(false);

  useDeepEffect(() => {
    const isTrue = every(_values(values));
    setOpen(isTrue);
  }, [values]);

  return (
    <CSSTransition
      in={isOpen}
      classNames="tw-dropdown"
      timeout={300}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

Transition.propTypes = {
  values: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Transition;

import { takeLatest, call, put } from "redux-saga/effects";

import { OfferApiUsecase } from "@core";
import { OFFER_LIST_REQUEST, offerListAction } from "./action";

// fetch offers list with market(lease)
export function* watchMarketOfferListFetch() {
  yield takeLatest(OFFER_LIST_REQUEST, offerListFetch);
}

function* offerListFetch(action) {
  try {
    const usecase = new OfferApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(offerListAction.success(res.data));
  } catch (err) {
    yield put(offerListAction.failure(err));
  }
}

import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import startsWith from "lodash/startsWith";
import PropTypes from "prop-types";

/**
 * [Component] Link
 * @param props { children, to, className, activeClassName, isActive, ...others}
 *
 * @example
 *
 * <Link to="/">Text</Link>
 * <Link to="/" className="text-blue-500">Text</Link>
 * <Link to="/" className="text-blue-500" activeClassName="text-indigo-900">Text</Link>
 * <Link to="/" className="text-blue-500" activeClassName="text-indigo-900" isActive={true|false}>Text</Link>
 */
const MyLink = ({
  children,
  to,
  className,
  isActive,
  activeClassName,
  ...rest
}) => {
  const parseActive = () => {
    return isActive;
  };

  const linkClass = cx("", className, {
    [`${activeClassName}`]: parseActive(),
  });

  if (!to || to === "#") {
    return <div className={linkClass}>{children}</div>;
  }

  if (startsWith(to, "http") || startsWith(to, "mailto")) {
    return (
      <a href={to} className={linkClass} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={linkClass}>
      {children}
    </Link>
  );
};

MyLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children:PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
]),
  isActive: PropTypes.bool,
};

MyLink.defaultProps = {
  to: "",
  className: "",
  activeClassName: "active",
  isActive: false,
};

export default MyLink;

import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_FOLLOW_LIST_REQUEST, fisheryFollowListAction } from "./action";

//Fetch fishery follow list
export function* watchfisheryFollowListFetch() {
  yield takeLatest(FISHERY_FOLLOW_LIST_REQUEST, fisheryFollowListFetch);
}

function* fisheryFollowListFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "followingList"], action.payload);
    yield put(fisheryFollowListAction.success(res.data));
  } catch (err) {
    yield put(fisheryFollowListAction.failure(err));
  }
}
 
import React from 'react';
import PropTypes from 'prop-types';

const FirstStep = props => {
  return (
    <div>
      <h2 className="mt-20 mb-12 text-2xl md:text-3xl text-center text-fish-dark font-bold leading-normal">One of our friendly team members will contact you shortly</h2>
    </div>
  );
};

FirstStep.propTypes = {
  
};

export default FirstStep;
import { takeLatest, call, put } from "redux-saga/effects";

import { QuotaAttrApiUsecase } from "@core";
import { COUNTRIES_LIST_REQUEST, countriesListAction } from "./action";

// fetch quota list
export function* watchcountriesListFetch() {
  yield takeLatest(COUNTRIES_LIST_REQUEST, countriesListFetch);
}

function* countriesListFetch(action) {
  try {
    const usecase = new QuotaAttrApiUsecase();
    const res = yield call([usecase, "countries"], action.payload);
    yield put(countriesListAction.success(res.data));
  } catch (err) {
    yield put(countriesListAction.failure(err));
  }
}

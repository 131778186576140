import React, { useState } from "react";
/**
 * import lib tools
 */
import { get } from "lodash";
import { submit } from "redux-form";
import { useDispatch } from "react-redux";

/**
 * import lib tools & hooks & components
 */
import { useOrderShow, useLicenceUpdate } from "@hooks";
import { orderLinkMap } from "@config/enum";

import Layout from "@views/layout";
import {
  Button,
  Loading,
  Link,
  Modal,
  ModalButton,
  useModal,
  Mobile,
  DesktopAndTablet,
} from "@components";

import Header from "./_header";
import Items from "./_items";
import Licence from "./_licence";
import Links from "./_links";
import Notice from "./_notice";
import Process from "./_process";
import Form from "./_licence_form";
import Actions from "./_action";
import InvoiceLink from "./_invoice_link";

/**
 * [Page] Order Show
 */
const Show = (props) => {
  const { match, history } = props;
  const [isPaymentModal, setPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const id = get(match, "params.id");

  const {
    usecase: usecaseOrder,
    loading: loadingOrderShow,
    fetchOrderShow,
    checkOrderShow,
  } = useOrderShow(id);
  const order = usecaseOrder.single;
  const orderLink = orderLinkMap[order.orderType] || "/";
  const initialValues = {
    licence_number: order.licenceNumber,
  };

  const {
    submit: handleSubmit,
    loadingUpdateLicence: loading,
  } = useLicenceUpdate(order, () => {
    toggleModal();
    isPaymentModal && history && history.push(order.paymentUrl);
  });

  const [modalProps, toggleModal] = useModal({
    content: (
      <div className="text-left">
        <div className="mb-5">
          <h3 className="text-3xl font-semibold">
            {isPaymentModal
              ? "Confirmation"
              : order.licenceNumber
              ? "Edit Your Fishing Licence"
              : "Add Your Fishing Licence"}
          </h3>
        </div>
        <div className="">
          <div>
            {isPaymentModal
              ? "Please confirm your nominated license number again for the quota to be transferred to before making the payment. This will be deemed as the final licence number for your quota transfer."
              : order.licenceNumber
              ? "Edit the fishing licence number you wish to have your quota purchases transferred to."
              : "Add the fishing licence number you wish to have your quota purchases transferred to."}
          </div>
          <div className="py-6">
            <hr />
          </div>
          <Form
            onSubmit={handleSubmit}
            enableReinitialize={true}
            loading={loading}
            initialValues={initialValues}
          />
        </div>
      </div>
    ),
    buttonAlign: "right",
    showClose: true,
    size: "xl",
    buttons: [
      <ModalButton
        label={loading ? "Submiting ..." : "Submit"}
        key={`modal-button-2`}
        isStylePrimary
        disabled={loading}
        loading={loading}
        onClick={() => {
          dispatch(submit("LicenceNumberForm"));
        }}
      />,
    ],
  });
  // render main content with loading
  const renderContent = () => {
    if (loadingOrderShow) {
      return <Loading />;
    }

    return (
      <div className="">
        <Link to={orderLink}>
          <div className="md:hidden px-4 pt-6 cursor-pointer flex items-center li-hover">
            <svg
              className="text-blue-gray-700-hover"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0251 11.4752C11.1335 11.6196 11.186 11.7983 11.1732 11.9784C11.1605 12.1584 11.0831 12.3279 10.9555 12.4555C10.8278 12.5832 10.6584 12.6605 10.4783 12.6733C10.2982 12.6861 10.1196 12.6335 9.97514 12.5252L6.97514 9.5252C6.83771 9.385 6.76074 9.19651 6.76074 9.0002C6.76074 8.80388 6.83771 8.61539 6.97514 8.4752L9.97514 5.4752C10.1196 5.36687 10.2982 5.31429 10.4783 5.32708C10.6584 5.33988 10.8278 5.41721 10.9555 5.54487C11.0831 5.67252 11.1605 5.84195 11.1732 6.02203C11.186 6.20211 11.1335 6.38077 11.0251 6.5252L8.56514 9.0002L11.0401 11.4752H11.0251Z"
                fill="#64748B"
              />
            </svg>
            <div className="text-xs font-medium text-blue-gray-500 text-blue-gray-700-hover">
              Back
            </div>
          </div>
        </Link>
        <div className="hidden md:flex items-center">
          <Link to={orderLink}>
            <div className="text-xs font-normal leading-normal text-blue-gray-500 hover:text-blue-gray-800">
              Orders
            </div>
          </Link>
          <svg
            className="mx-2"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.87435 3.62565C3.81417 3.54541 3.78495 3.44616 3.79206 3.34611C3.79917 3.24607 3.84213 3.15195 3.91305 3.08102C3.98398 3.0101 4.0781 2.96714 4.17815 2.96003C4.27819 2.95292 4.37744 2.98214 4.45768 3.04232L6.12435 4.70898C6.20069 4.78687 6.24346 4.89159 6.24346 5.00065C6.24346 5.10971 6.20069 5.21443 6.12435 5.29232L4.45768 6.95898C4.37744 7.01916 4.27819 7.04838 4.17815 7.04127C4.0781 7.03416 3.98398 6.9912 3.91305 6.92028C3.84213 6.84936 3.79917 6.75523 3.79206 6.65519C3.78495 6.55514 3.81417 6.45589 3.87435 6.37565L5.24518 5.00065L3.87018 3.62565H3.87435Z"
              fill="#6B7280"
            />
          </svg>
          <div className="cursor-pointer text-xs font-normal leading-normal text-blue-gray-500">
            Order #{order.serialNumber}
          </div>
        </div>
        <div className="mx-4 md:mx-0">
          <Header order={order} history={history} />
        </div>
        <div className="mt-6 mx-5 md:mx-0">
          <Notice
            order={order}
            history={history}
            toggleModal={() => {
              setPaymentModal(true);
              toggleModal();
            }}
            checkOrderShow={checkOrderShow}
          />
        </div>
        <div className="mt-2 md:flex">
          <div className="flex-1">
            <Actions
              id={id}
              order={order}
              history={history}
              fetchOrderShow={fetchOrderShow}
            />
            {order.isIn ? (
              <div className="mt-4 md:mt-6 mx-5 md:mx-0">
                <Licence
                  order={order}
                  history={history}
                  toggleModal={() => {
                    setPaymentModal(false);
                    toggleModal();
                  }}
                />
              </div>
            ) : null}
            <Mobile>
              <div className="mt-4 md:mt-6 mx-5 md:mx-0">
                <Process order={order} />
              </div>
            </Mobile>
            <div className="mt-4 md:mt-6 mx-5 md:mx-0">
              <Items order={order} history={history} />
            </div>
            <Mobile>
              <div className="mt-4 md:mt-6 mx-5 md:mx-0">
                <Links order={order} />
              </div>
              <div className="mt-4 md:mt-6 mx-5 md:mx-0">
                <InvoiceLink order={order} history={history} />
              </div>
            </Mobile>
          </div>
          <DesktopAndTablet>
            <div className="page-sub-sm-wrapper pl-12">
              {/*<div className="mt-6">
                <Links order={order} />
              </div>*/}
              <div className="mt-6">
                <Process order={order} />
              </div>
              <div className="mt-4 md:mt-6">
                <InvoiceLink order={order} history={history} />
              </div>
            </div>
          </DesktopAndTablet>
        </div>
        <Modal {...modalProps} />
      </div>
    );
  };

  return (
    <Layout>
      <div className="pb-5 md:pt-10 md:pb-24 bg-gray-50">
        <div className="container">{renderContent()}</div>
      </div>
    </Layout>
  );
};

export default Show;

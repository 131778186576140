import { takeLatest, call, put } from "redux-saga/effects";

import { QuotaAttrApiUsecase } from "@core/index";
import { STATES_LIST_REQUEST, statesListAction } from "./action";

// fetch quota list
export function* watchstatesListFetch() {
  yield takeLatest(STATES_LIST_REQUEST, statesListFetch);
}

function* statesListFetch(action) {
  try {
    const usecase = new QuotaAttrApiUsecase();
    const res = yield call([usecase, "states"], action.payload);
    yield put(statesListAction.success(res.data));
  } catch (err) {
    yield put(statesListAction.failure(err));
  }
}

import React, { useState } from "react";
import cx from "classnames";
import map from "lodash/map";
import PropTypes from "prop-types";

/**
 * Components
 */
import { Button, Loading } from "@components";

const FollowList = ({ data, listLoading, onSubmit, followLoading }) => {
  const [currentFollowId, setCurrenFollowId] = useState(null);
  // const list = [
  //   { title: "Tasmania Lobster", id:"1", following: true },
  //   { title: "Tasmania Lobster", id:"2", following: true },
  //   { title: "Tasmania Lobster", id:"3", following: false },
  //   { title: "Tasmania Lobster", id:"4", following: true },
  //   { title: "Tasmania Lobster", id:"5", following: false },
  //   { title: "Tasmania Lobster", following: true },
  //   { title: "Tasmania Lobster", following: true },
  // ];

  if (listLoading) {
    return <Loading />;
  }
  return (
    <div className="mt-6">
      <ul>
        {map(data, (item, index) => {
          return (
            <li
              key={index}
              className="flex justify-between items-center py-4 border-b-1 border-blue-gray-200"
            >
              <div className="flex items-center">
                <img src={item.logoImage} className="w-10 mr-4" />
                <div className="text-sm font-medium leading-tight text-blue-gray-800">
                  {item.title}
                </div>
              </div>
              <div className="w-20">
                <Button
                  className={cx("py-2 leading-normal")}
                  type="submit"
                  full={true}
                  size="xs"
                  textColor={item.following ? "white" : "fish-primary"}
                  color={item.following ? "fish-primary" : "blue-100"}
                  hoverColor={item.following ? "fish-primary" : "blue-200"}
                  activeColor={item.following ? "fish-primary-700" : "blue-300"}
                  disabled={followLoading}
                  loading={followLoading && currentFollowId === item.id}
                  onClick={() => {
                    if (item.id) {
                      onSubmit({ id: item.id, following: item.following });
                      setCurrenFollowId(item.id);
                    }
                  }}
                >
                  {item.following ? "Following" : "Follow"}
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FollowList.propTypes = {
  data: PropTypes.array,
  listLoading: PropTypes.bool,
  followLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default FollowList;

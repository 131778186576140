import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import { AccountApiUsecase } from "@core";
import errorString from "@utils/errorString";

import { PERMANENT_UPDATE_REQUEST, permanentUpdateAction } from "./action";

// update permanent
export function* watchAccountPermanentUpdate() {
  yield takeLatest(PERMANENT_UPDATE_REQUEST, permanentUpdatePatch);
}

function* permanentUpdatePatch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "permanentUpdate"], action.payload);
    yield put(permanentUpdateAction.success(res.data));
  } catch (err) {
    yield put(permanentUpdateAction.failure(errorString(err)));
  }
}
  
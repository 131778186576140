import {takeEvery, call, put} from 'redux-saga/effects';
import {resendCurrentUserEmailVerificationCode} from '@utils/cognitoAuth';

import {
  RESEND_EAMIL_CONFIRMATION_CODE_FAILED,
  RESEND_EAMIL_CONFIRMATION_CODE_SUCCESS,
  RESEND_EAMIL_CONFIRMATION_CODE_LOADING,
  RESEND_EAMIL_CONFIRMATION_CODE_REQUEST
} from './action'

function* watchResendEmailConfirmationCodeRequest() {
  yield takeEvery(RESEND_EAMIL_CONFIRMATION_CODE_REQUEST, resendEmailConfirmationCodeRequest)
}

function* resendEmailConfirmationCodeRequest(action) {
  yield put({type: RESEND_EAMIL_CONFIRMATION_CODE_LOADING})
  let {res, err} = yield call(resendEmailConfirmationCodeCall)

  if (err) {
    yield put({type: RESEND_EAMIL_CONFIRMATION_CODE_FAILED, error: err})
    return
  }
  yield put({type: RESEND_EAMIL_CONFIRMATION_CODE_SUCCESS, data: "SUCCESS"})
}

function resendEmailConfirmationCodeCall() {
  return resendCurrentUserEmailVerificationCode()
    .then(res => ({res}))
    .catch(err => ({err}))
}

export { watchResendEmailConfirmationCodeRequest }
import {
  DASHBOARD_ORDER_STATUS_CHANGE,
  DASHBOARD_ORDER_KIND_CHANGE,
} from "./dashboardAction";

export default function dashboardState(state = initStatus, action = {}) {

  switch (action.type) {
    case DASHBOARD_ORDER_STATUS_CHANGE:
      return Object.assign({}, state, {
        orderStatus: action.payload.status,
        orderKind: "all",
      });
      break;

    case DASHBOARD_ORDER_KIND_CHANGE:
      return Object.assign({}, state, {
        orderKind: action.payload.orderKind,
      });
      break;



    default:
      return state;
  }
}

const initStatus = {
  orderStatus: "not_done", // not_done|pending|completed
  orderKind: "all", // all|purchased|sold|outdated
};

export const INVOLVED_AUCTIONS = "involved_auctions";
export const INVOLVED_AUCTIONS_REQUEST = `${INVOLVED_AUCTIONS}_request`;
export const INVOLVED_AUCTIONS_SUCCESS = `${INVOLVED_AUCTIONS}_success`;
export const INVOLVED_AUCTIONS_FAILURE = `${INVOLVED_AUCTIONS}_failure`;
export const INVOLVED_AUCTIONS_UPDATE = `${INVOLVED_AUCTIONS}_update`;

export const involvedAuctionsAction = {
  request: (payload) => {
    return {
      type: INVOLVED_AUCTIONS_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: INVOLVED_AUCTIONS_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: INVOLVED_AUCTIONS_FAILURE,
      error,
    };
  },
  update: (payload) => {
    return {
      type: INVOLVED_AUCTIONS_UPDATE,
      payload,
    };
  },
};

const REFRESH_COGNITO_USER_REQUEST = "refresh_cognito_user/request"
const REFRESH_COGNITO_USER_LOADING = "refresh_cognito_user/loading"
const REFRESH_COGNITO_USER_SUCCESS = "refresh_cognito_user/success"
const REFRESH_COGNITO_USER_FAILED = "refresh_cognito_user/failed"

function refreshCognitoUserRequest() {
  return {
    type: REFRESH_COGNITO_USER_REQUEST
  }
}

function refreshCognitoUserLoading() {
  return {
    type: REFRESH_COGNITO_USER_LOADING
  }
}

function refreshCognitoUserSuccess(data) {
  return {
    type: REFRESH_COGNITO_USER_SUCCESS, data
  }
}

function refreshCognitoUserFailed(error) {
  return {
    type: REFRESH_COGNITO_USER_FAILED, error
  }
}

export {
  refreshCognitoUserFailed,
  refreshCognitoUserLoading,
  refreshCognitoUserRequest,
  refreshCognitoUserSuccess,

  REFRESH_COGNITO_USER_FAILED,
  REFRESH_COGNITO_USER_SUCCESS,
  REFRESH_COGNITO_USER_LOADING,
  REFRESH_COGNITO_USER_REQUEST
}
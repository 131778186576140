import React, { useState } from "react";
import { useFormikContext, getIn } from "formik";
import PropTypes from "prop-types";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import cx from "classnames";
import debounce from "lodash/debounce";

import WithLabel from "./with_label";

/**
 * Form Select
 *
 * @example
 *
  <Select
    type="select"
    className={className}
    options={
      [{value: "value", label: "Label"}],
      ...
    }
    {... other react input props}
  />
 *
 * @refs https://react-select.com/home
 */

const SelectComp = ({
  className,
  error,
  id,
  isMulti,
  loadOptions,
  name,
  options,
  onChange,
  ...rest
}) => {
  const [asyncOptions, setAsyncOptions] = useState([]);

  const classNameWrapper = cx("react-select-container", className, {
    invalid: error,
  });

  const { values, setFieldValue } = useFormikContext();

  //select on change 的 callback
  const onChangeValue = (values) => {
    setFieldValue(name, postValue(values));
    onChange && onChange(values);
  };

  //获取react-select对应的值
  const getValue = (values = [], options = []) => {
    if (isMulti && values instanceof Array) {
      return options.filter(({ value }) => values.includes(value));
    }
    return options.filter(({ value }) => value == values);
  };

  //提交form需要的value
  const postValue = (values) => {
    if (!values) {
      return "";
    }
    if (isMulti) {
      return values.map(({ value }) => value);
    }
    return values.value;
  };

  //通过loadOptions判断是否是async
  if (loadOptions) {
    const sleep = 1000;
    const getOptions = (newValue, callback) => {
      // const inputValue = newValue.replace(/\W/g, "");
      // 默认为空数据
      // if (!inputValue) {
      //   return [];
      // }
      loadOptions(newValue).then((results) => {
        setAsyncOptions(results);
        callback(results);
      });
      // Explicitly not returning a Promise.
      return;
    };
    //等待一点时间再触发防止连击
    const debounceGetOptions = debounce(getOptions, sleep);
    return (
      <AsyncSelect
        onChange={onChangeValue}
        {...rest}
        id={id || name}
        name={name}
        value={getValue(getIn(values, name), asyncOptions)}
        className={classNameWrapper}
        classNamePrefix="react-select"
        cacheOptions
        defaultOptions
        isClearable
        loadOptions={debounceGetOptions}
        isMulti={isMulti}
        // closeMenuOnSelect={false}
      />
    );
  }
  return (
    <Select
      onChange={onChangeValue}
      {...rest}
      id={id || name}
      name={name}
      className={classNameWrapper}
      classNamePrefix="react-select"
      value={getValue(getIn(values, name), options)}
      options={options}
      isMulti={isMulti}
      // closeMenuOnSelect={false}
      // menuIsOpen={true}
    />
  );
};

SelectComp.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  loadOptions: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

SelectComp.defaultProps = {
  options: [],
};

export default WithLabel("select")(SelectComp);

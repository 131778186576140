import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { INVOLVED_AUCTIONS_REQUEST, involvedAuctionsAction } from "./action";

// fetch list
export function* watchInvolvedAuctionsFetch() {
  yield takeLatest(INVOLVED_AUCTIONS_REQUEST, involvedAuctionsFetch);
}

function* involvedAuctionsFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "involvedAuctions"], action.payload);
    yield put(involvedAuctionsAction.success(res.data));
  } catch (err) {
    yield put(involvedAuctionsAction.failure(err));
  }
}

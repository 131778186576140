import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class QuotaAttrListApiRepo extends BaseApiRepo {
  constructor() {
    super();
  }

  /**
   * categories list
   */
  getCategories() {
    this.setApi(`${API}/categories`);
    return this.get();
  }

  /**
   * species list by categories id
   * @param {Object} payload {category_id: XXX}
   */
  getSpeciesByCategories(payload = {}) {
    this.setApi(`${API}/categories/${payload.category_id}/species`);
    return this.get();
  }

  /**
   * species list through search
   * @param {Object} payload {term: XXX}
   */
  getSpeciesByTerm(payload) {
    this.setApi(`${API}/species`);
    return this.get(payload);
  }

  /**
   * countries list
   */
  getCountries() {
    this.setApi(`${API}/countries`);
    return this.get();
  }

  /**
   * states list
   * @param {Object} payload {country_id: XXX}
   */
  getStates(payload = {}) {
    this.setApi(`${API}/countries/${payload.country_id}/states`);
    return this.get();
  }

  /**
   * zone list
   * @param {Object} payload {country_id: XXX, state_id: XXX}
   */
  getZones(payload = {}) {
    this.setApi(
      `${API}/countries/${payload.country_id}/states/${payload.state_id}/zones`
    );
    return this.get();
  }

  /**
   * Category list by term
   */
  getCategoryByTerm(payload = {}) {
    this.setApi(`${API}/categories`);
    return this.get(payload);
  }
}

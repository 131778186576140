import { takeLatest, call, put } from "redux-saga/effects";

import { BidApiUsecase } from "@core";
import { BID_UPDATE_REQUEST, bidUpdateAction } from "./action";

// update bid with market(lease)
export function* watchMarketBidUpdatePut() {
  yield takeLatest(BID_UPDATE_REQUEST, bidUpdatePut);
}

function* bidUpdatePut(action) {
  try {
    const usecase = new BidApiUsecase();
    const res = yield call([usecase, "update"], action.payload);
    yield put(bidUpdateAction.success(res.data));
  } catch (err) {
    yield put(bidUpdateAction.failure(err));
  }
}

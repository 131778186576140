import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { QUOTUM_INVENTORIES_REQUEST, quotumInventoriesAction } from "./action";

// fetch quotum inventories list
export function* watchQuotumInventoriesFetch() {
  yield takeLatest(QUOTUM_INVENTORIES_REQUEST, quotumInventoriesFetch);
}

function* quotumInventoriesFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "quotumInventories"], action.payload);
    yield put(quotumInventoriesAction.success(res.data));
  } catch (err) {
    yield put(quotumInventoriesAction.failure(err));
  }
}

import {
  ASKING_PRICES_FETCH_QUOTA_BALANCE_LOADING,
  ASKING_PRICES_FETCH_QUOTA_BALANCE_SUCCESS,
  ASKING_PRICES_FETCH_QUOTA_BALANCE_FAILED,
  ASKING_PRICES_REFRESH_QUOTA_BALANCE,
} from "./quotaBalanceAction";

export const askingPriceQuotaBalanceStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ASKING_PRICES_FETCH_QUOTA_BALANCE_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;

    case ASKING_PRICES_FETCH_QUOTA_BALANCE_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    case ASKING_PRICES_FETCH_QUOTA_BALANCE_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;

    case ASKING_PRICES_REFRESH_QUOTA_BALANCE:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};

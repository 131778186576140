export const OFFERS_APPLY_CONFIRM = "offers/apply_confirm";
export const OFFERS_APPLY_CONFIRM_REQUEST = `${OFFERS_APPLY_CONFIRM}_request`;
export const OFFERS_APPLY_CONFIRM_SUCCESS = `${OFFERS_APPLY_CONFIRM}_success`;
export const OFFERS_APPLY_CONFIRM_FAILURE = `${OFFERS_APPLY_CONFIRM}_failure`;
export const OFFERS_APPLY_CONFIRM_RESET = `${OFFERS_APPLY_CONFIRM}_reset`;

export const offersApplyConfirmAction = {
  request: (payload) => {
    return {
      type: OFFERS_APPLY_CONFIRM_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: OFFERS_APPLY_CONFIRM_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: OFFERS_APPLY_CONFIRM_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: OFFERS_APPLY_CONFIRM_RESET
    }
  },
};


export const CART_OFFER_PRICES_FETCH_REQUEST = "cart/offer_prices_fetch_request";
export const CART_OFFER_PRICES_FETCH_LOADING = "cart/offer_prices_fetch_loading";
export const CART_OFFER_PRICES_FETCH_SUCCESS = "cart/offer_prices_fetch_success";
export const CART_OFFER_PRICES_FETCH_FAILED = "cart/offer_prices_fetch_failed";

export function fetchCartOfferPricesList(payload) {
  return {
    type: CART_OFFER_PRICES_FETCH_REQUEST,
    payload
  };
}
import get from "lodash/get";
import moment from "moment";

/**
 * [ ENTITY ] Quotum Balance entity
 * @data
 * {
 *  balance_cache: 9500
 *  created_at: "2020-04-03T13:56:54.783+11:00"
 *  id: 99
 *  quantity: 9500
 *  updated_at: "2020-04-03T13:56:54.783+11:00"
 * }
 */

export class QuotumInventoryEntity {
  constructor(props = {}) {
    this.data = props;
  }

  get id() {
    return get(this.data, "id", "");
  }

  get quantity() {
    return get(this.data, "quantity", "");
  }

  get createdAt() {
    return get(this.data, "created_at", "");
  }

  get createdAtStr() {
    return moment(this.createdAt).format("DD MMM YYYY HH:mmA");
  }

  get updatedAt() {
    return get(this.data, "updated_at", "");
  }

  get updatedAtStr() {
    return moment(this.updatedAt).format("DD MMM YYYY HH:mmA");
  }

  get balanceCache() {
    return get(this.data, "balance_cache", 0);
  }

  get action() {
    const text = Number(this.quantity) >= 0 ? "Consign" : "Withdraw";
    return `${text} quota`;
  }
}

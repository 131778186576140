
export const BANKS_UPDATE_FOR_ORDER_PUT = "banks/update_for_order_put";
export const BANKS_UPDATE_FOR_ORDER_LOADING = "banks/update_for_order_loading";
export const BANKS_UPDATE_FOR_ORDER_SUCCESS = "banks/update_for_order_success";
export const BANKS_UPDATE_FOR_ORDER_FAILED = "banks/update_for_order_failed";
export const BANKS_UPDATE_FOR_ORDER_RECEIVED = "banks/update_for_order_received";

export function bankUpdateForOrderPut(orderId, payload) {
  return {
    type: BANKS_UPDATE_FOR_ORDER_PUT,
    orderId,
    payload
  };
}

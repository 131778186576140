import { useState, useEffect } from "react";
import omitBy from "lodash/omitBy";
import { useLocation } from "react-router-dom";

//Core Method
import { UrlUtils } from "@core/index";
//Type

//Hooks

//Common Components

//Local Components

/**
 * 获取url中query字符串并返回成params对象
 * @params {string} value 如果传入value值，过滤等于这个值的key
 * @returns 返回params对象
 * @example url = "?state_id=all&species_id=1" useParams("all") /=> {species_id: 1}
 */
export const useParams = (value) => {
  const [params, setParams] = useState({});
  const location = useLocation();

  useEffect(() => {
    setParams(UrlUtils.queryToParams(location.search));
  }, [location.search]);

  if (value) {
    return [omitBy(params, (val) => val === value)];
  }
  return [params];
};

export const INVOICE_MODAL_BANK_STATUS_CHANGE = "invoice/modal_bank_status_change";

/**
 * Invoice 详情页 银行弹框显示状态 切换
 * @param  {Boolean} status true|false
 * @return {Object}
 * {type: INVOICE_MODAL_BANK_STATUS_CHANGE, payload: {isOpen: true}}
 * {type: INVOICE_MODAL_BANK_STATUS_CHANGE, payload: {isOpen: false}}
 */
export function invoiceModalBankStatusChange(status) {
  return {
    type: INVOICE_MODAL_BANK_STATUS_CHANGE,
    payload: {
      isOpen: status
    },
  };
}
import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import compact from "lodash/compact";
import get from "lodash/get";
import cx from "classnames";

import { useResponsive } from "@hooks";

import { Link, TimeCountDown, Button } from "@components";
import Line from "../show/detail/_line";

const Items = ({ data, image }) => {
  const { isMobile } = useResponsive();
  return map(data, (item, index) => {
    const subInfo = compact([
      `Auction #${get(item, "show.id", "")}`,
      "Seasonal Quota",
    ]).join(" · ");
    const reservePriceLabel = get(item, "show.reservePrice.label", "");
    const _image =
      typeof image === "function"
        ? image(get(item, "show.fisheryId", ""))
        : image;
    const bidCount = get(item, "show.bidCount", "");
    const marketType = get(item, "show.marketType", "");
    const marketTypeStr =
      marketType === "permanent" ? "Permanent quota" : "Seasonal quota";
    const end = get(item, "status.end", false);
    const isFinalOffer =
      get(item, "bidderStatus", "") === "pending_wait_for_final_chance_submit";
    const url = isFinalOffer
      ? get(item, "show.finalBidUrl", "")
      : get(item, "show.url", "");
    const renderMsg = (data) => {
      const label = get(data, "show.bidder.price.label", "");
      const message = get(data, "show.bidder.message", "");
      const bgColor = get(data, "show.color.bg", "");
      const price = get(data, "show.bidder.price.str", "");
      const priceVal = get(data, "show.bidder.price.value", "");
      if (!message) {
        return null;
      }
      if (isFinalOffer) {
        return (
          <div
            className={cx(
              "items-center flex justify-between p-2 rounded-lg mt-4",
              bgColor ? bgColor : ""
            )}
          >
            <div className="flex flex-1 items-center">
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                className="mr-2 flex-shrink-0"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.2"
                  cx="6"
                  cy="6.5"
                  r="6"
                  fill="white"
                  fillOpacity="0.56"
                />
                <circle cx="6" cy="6.5" r="3" fill="white" fillOpacity="0.56" />
              </svg>
              <span className="text-sm font-medium text-white hidden md:block">
                Your max bid was lower than the reserve. You now have the
                exclusive Final Offer option.
              </span>
              <span className="text-sm font-medium text-white md:hidden">
                Reserve not met. Make a Final Offer
              </span>
            </div>
            <div className="md:flex justify-end items-center">
              <svg
                className="ml-2"
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.292787 9.70692C0.105316 9.51939 0 9.26508 0 8.99992C0 8.73475 0.105316 8.48045 0.292787 8.29292L3.58579 4.99992L0.292787 1.70692C0.110629 1.51832 0.00983372 1.26571 0.0121121 1.00352C0.0143906 0.741321 0.11956 0.490509 0.304968 0.305101C0.490376 0.119692 0.741189 0.0145233 1.00339 0.0122448C1.26558 0.00996641 1.51818 0.110761 1.70679 0.292919L5.70679 4.29292C5.89426 4.48045 5.99957 4.73475 5.99957 4.99992C5.99957 5.26508 5.89426 5.51939 5.70679 5.70692L1.70679 9.70692C1.51926 9.89439 1.26495 9.99971 0.999786 9.99971C0.734622 9.99971 0.480314 9.89439 0.292787 9.70692Z"
                  fill="white"
                  fillOpacity="0.56"
                />
              </svg>
            </div>
          </div>
        );
      }

      return (
        <div
          className={cx(
            "items-center flex justify-between p-2 rounded-lg mt-4",
            bgColor ? bgColor : ""
          )}
        >
          <div className="flex flex-1 items-center">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              className="mr-2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                opacity="0.2"
                cx="6"
                cy="6.5"
                r="6"
                fill="white"
                fillOpacity="0.56"
              />
              <circle cx="6" cy="6.5" r="3" fill="white" fillOpacity="0.56" />
            </svg>
            <span className="text-sm font-medium text-white">{message}</span>
          </div>
          <div className="md:flex justify-end items-center">
            {priceVal ? (
              <div className="hidden md:block">
                {label && (
                  <span className="text-sm ml-0 md:ml-1 text-white">{`Your ${label}`}</span>
                )}
                <span className="text-sm ml-0 md:ml-1 text-white">{price}</span>
              </div>
            ) : null}
            <svg
              className="ml-2"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292787 9.70692C0.105316 9.51939 0 9.26508 0 8.99992C0 8.73475 0.105316 8.48045 0.292787 8.29292L3.58579 4.99992L0.292787 1.70692C0.110629 1.51832 0.00983372 1.26571 0.0121121 1.00352C0.0143906 0.741321 0.11956 0.490509 0.304968 0.305101C0.490376 0.119692 0.741189 0.0145233 1.00339 0.0122448C1.26558 0.00996641 1.51818 0.110761 1.70679 0.292919L5.70679 4.29292C5.89426 4.48045 5.99957 4.73475 5.99957 4.99992C5.99957 5.26508 5.89426 5.51939 5.70679 5.70692L1.70679 9.70692C1.51926 9.89439 1.26495 9.99971 0.999786 9.99971C0.734622 9.99971 0.480314 9.89439 0.292787 9.70692Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </div>
        </div>
      );
    };

    return (
      <li key={index}>
        <Link
          to={url}
          className="block relative bg-blue-gray-50 rounded-lg overflow-hidden transition duration-300 ease-in-out border-2 border-white hover:border-fish-primary"
        >
          <Line
            vertical={!isMobile}
            data={item}
            className={cx("absolute", {
              "left-0 inset-y-0": !isMobile,
              "top-0 inset-x-0": isMobile,
            })}
          />
          <div className="p-4 pt-5 bg-blue-gray-50 rounded-lg md:rounded-none shadow-sm md:shadow-none border-blue-gray-200 hover:bg-gray-50 cursor-pointer">
            <div
              className={cx("flex", {
                "md:items-center": !get(item, "show.bidder.message", ""),
              })}
            >
              <div className="w-12 h-12 rounded-full md:w-16 md:h-16 mr-4 flex-shrink-0 overflow-hidden">
                {_image ? (
                  <img
                    src={_image}
                    className="w-full h-full"
                    alt={get(item, "show.title", "")}
                  />
                ) : (
                  <div className="w-full h-full rounded-full bg-gray-100"></div>
                )}
              </div>
              <div className="flex-1">
                <div className="md:flex justify-between">
                  <div className="flex-1 md:w-0 md:truncate space-y-2">
                    <div className="flex flex-col-reverse md:flex-row md:items-center md:space-x-4">
                      <div className="text-sm md:text-base leading-tight font-medium md:truncate">
                        {get(item, "show.title", "")}
                      </div>
                    </div>
                    <div className="flex items-center flex-no-wrap">
                      <div
                        className="text-red-500 bg-red-50 px-4 text-xxs rounded-full inline-block mr-3"
                        style={{ paddingTop: "2px", paddingBottom: "2px" }}
                      >
                        {`Auction #${get(item, "show.id", "")}`}
                      </div>
                      <div className="text-blue-gray-500 text-xxs mr-0 md:mr-4">
                        {marketTypeStr}
                      </div>
                      <div className="hidden md:block underline-dot text-fish-primary text-xxs mt-2 md:mt-0">
                        {reservePriceLabel}
                      </div>
                    </div>
                  </div>
                  <div className="md:ml-8 mt-2 md:mt-0">
                    <div className="space-y-2 flex flex-col md:items-end">
                      <div className="leading-tight md:text-right flex items-center md:justify-end text-sm md:text-base">
                        {get(item, "show.price.value", "") ? (
                          <>
                            <span className="hidden md:inline mr-2 md:text-right text-sm text-blue-gray-500 whitespace-no-wrap">
                              Current bid
                            </span>
                            <div className="flex-shrink-0 text-right">
                              <span className="text-base font-medium leading-normal">
                                {get(item, "show.price.str", "")}
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className="ml-2 mt-px pt-px md:hidden text-fish-primary text-xxs">
                          {reservePriceLabel}
                        </div>

                        {/* {bidCount ? (
                          <div className="flex-shrink-0 -mr-1 ml-2 h-5 w-5 rounded-full bg-gray-800 text-white text-xs flex items-center justify-center">
                            {bidCount}
                          </div>
                        ) : null} */}
                      </div>

                      {!end && (
                        <div className="text-sm md:flex justify-end">
                          <TimeCountDown
                            date={get(item, "show.expiredAt", "")}
                            now={() => {
                              return window.moment.now();
                            }}
                            theme="danger"
                            themeClosed="danger"
                            onComplete={() => {
                              window.location.reload();
                            }}
                          />
                        </div>
                      )}
                      {isFinalOffer && (
                        <span className="text-sm text-fish-yellow">
                          Your Final Offer opportunity
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">{renderMsg(item)}</div>
              </div>
            </div>
            <div className="md:hidden">{renderMsg(item)}</div>
          </div>
        </Link>
      </li>
    );
  });
};

Items.propTypes = {};

export default Items;

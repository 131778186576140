import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import { Image } from "@components";

const Empty = ({ data, children, title, content }) => {
  if (isEmpty(data))
    return (
      <div className="flex flex-col items-center py-10">
        <Image className="w-26" source="empty" />
        <div className="w-64 text-center">
          <h4 className="mb-2 mt-4">{title}</h4>
          <p className="text-blue-gray-500 text-sm">{content}</p>
        </div>
      </div>
    );
  return <div>{children}</div>;
};

Empty.propTypes = {
  data: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  title: PropTypes.string,
  content: PropTypes.string,
};

Empty.defaultProps = {
  data: [],
  title: "Nothing yet",
  content: "",
};

export default Empty;

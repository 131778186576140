import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class WithdrawApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "withdraws";
  }

  /**
   * Withdraw Api instance list
   */
  list(payload) {
    this.setApi(`${API}/${this.model}`);
    return this.get();
  }

  /**
   * Withdraw Api instance show
   */
  show(payload) {
    this.setApi(`${API}/${this.model}/${payload.id}`);
    return this.get();
  }

  /**
   * Withdraw Api instance create
   */
  create(payload) {
    this.setApi(`${API}/${this.model}`);
    return this.post(payload);
  }
}

import { useEffect, useState, useCallback } from "react";
import get from "lodash/get";
import map from "lodash/map";
import isArray from "lodash/isArray";

/**
 * import hooks
 */
import { useCurrentUser, useDeepEffect } from "@hooks";

/**
 * import local tools & redux & usecase
 */
import {
  AccountApiUsecase,
  AccountQuotumBalanceUsecase,
  BidOfferUsecase,
  MarketApiUsecase,
  MarketMap,
  MarketUsecase,
  OfferApiUsecase,
} from "@core";

/**
 * [hooks] market seller offers by id
 * @param {Number|String} id url params id (market id)
 * @param {Instance<MarketUsecase>} usecaseMarket
 * @returns { usecase: Array<Offer|Bid>, loading: true|false }
 */
export const useMarketOffers = (id, usecaseMarket) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchOfferList = useCallback(() => {
    setLoading(true);
    try {
      const usecase = new OfferApiUsecase();
      usecase
        .list({ marketId: id })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("err: ", err);
          // throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  }, [id]);

  const usecase = new BidOfferUsecase(data, usecaseMarket);

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    fetchOfferList();
  }, []);

  return { usecase: usecase, loading, fetchOfferList };
};

/**
 * [hooks] market statistics by id
 * @param {String|Number} id market id
 * @param {Instance<MarketUsecase>} usecaseMarket
 * @returns { data: "market statistics data obj", loading: true|false }
 */
export const useMarketStatistics = (id, usecaseMarket) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const generateData = (data) => {
    return map(data, (item) => {
      return {
        price_cents: item.price_cents,
        remain_quantity: item.boxes_count,
        created_at: item.created_at,
        marketable_type: MarketMap.lease,
      };
    });
  };

  const fetchMarketStatistics = useCallback((marketId) => {
    setLoading(true);
    try {
      const usecase = new MarketApiUsecase();
      usecase
        .statistics({ id: marketId })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("err: ", err);
          // throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  }, []);

  const list = generateData(get(data, "latest_transcation", []));

  const usecase = new BidOfferUsecase(list, usecaseMarket);

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    if (id) {
      fetchMarketStatistics(id);
    }
  }, [id]);

  return {
    fetchMarketStatistics,
    transactions: usecase.list,
    data,
    loading,
  };
};

/**
 * [hooks] market balance by id
 * @param {String|Number} id market id
 * @returns { data: "market balance data obj", loading: true|false }
 */
export const useMarketBalance = (id) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const currentUserStatus = useCurrentUser();
  const isAuthenticated = get(currentUserStatus, "isAuthenticated", false);

  const fetchMarketBalance = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }

    setLoading(true);
    try {
      const usecase = new MarketApiUsecase();
      usecase
        .balance({ id })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("err: ", err);
          // throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  }, [isAuthenticated, id]);

  const usecase = new AccountQuotumBalanceUsecase(data);
  /**
   * did mount and watch id change
   */
  useEffect(() => {
    fetchMarketBalance();
  }, []);

  return {
    balance: data,
    loading,
    show: usecase.show,
    fetchMarketBalance,
  };
};

/**
 * [hooks] market balance by id
 * @param {String|Number} id market id
 * @returns { data: "market balance data obj", loading: true|false }
 */
export const useAccountOffers = (params = {}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const currentUserStatus = useCurrentUser();
  const isAuthenticated = get(currentUserStatus, "isAuthenticated", false);

  const fetchOfferList = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }
    setLoading(true);
    try {
      const usecase = new AccountApiUsecase();
      usecase
        .offers(params)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("err: ", err);
          // throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  }, [isAuthenticated, params]);

  const _data = isArray(data)
    ? map(data, (group) => {
        const usecaseMarket = new MarketUsecase(group.quota_market || {});
        const market = usecaseMarket.single;
        const usecaseBidOffer = new BidOfferUsecase(
          group.bid_offer_prices || [],
          market
        );
        return {
          quota_market: market,
          bid_offer_prices: usecaseBidOffer.list,
        };
      })
    : [];

  /**
   * did mount and watch id change
   */
  useDeepEffect(() => {
    fetchOfferList();
  }, []);

  return { data: _data, loading, fetchOfferList };
};

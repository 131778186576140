import React, { useState } from "react";
import Slider from "rc-slider";
import cx from "classnames";

const DefaultSlider = ({ value, min, max, onChange, className, step, ...rest }) => {
  return (
    <Slider
      className={cx("slider-blue", className)}
      onChange={onChange}
      min={min}
      max={max}
      defaultValue={value}
      step={step}
      {...rest}
    />
  );
};

DefaultSlider.propTypes = {};

export default DefaultSlider;

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";

/**
 * import local tools & redux & usecase
 */
import Notice from "@utils/noticeNew";
import { logout, logoutReceived } from "@redux/modules/auth/action";
import { links } from "@config/staticLink";

/**
 * [hooks] user auth logout
 * @returns { data: "", loading: true|false }
 */
export const useUserLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchLagout = () => {
    dispatch(logout());
  };
  const logoutStatus = useSelector((state) => get(state, "logoutStatus"));

  useEffect(() => {
    if (logoutStatus.justFinished) {
      if (!isEmpty(logoutStatus.error)) {
        Notice.failure("Log out failed");
      }
      if (!isEmpty(logoutStatus.data)) {
        Notice.success("You have been successfully logged out!");
        setTimeout(() => {
          window.location.href = links.home
        }, 200);
      }
      dispatch(logoutReceived());
    }
  }, [logoutStatus]);

  return { logout: dispatchLagout, logoutStatus };
};

import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_QUOTA_MARKETS_REQUEST, fisheryQuotaMarketsAction } from "./action";

// fetch fishery quota markets list
export function* watchfisheryQuotaMarketsFetch() {
  yield takeLatest(FISHERY_QUOTA_MARKETS_REQUEST, fisheryQuotaMarketsFetch);
}

function* fisheryQuotaMarketsFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "quotaMarkets"], action.payload);
    yield put(fisheryQuotaMarketsAction.success(res.data));
  } catch (err) {
    yield put(fisheryQuotaMarketsAction.failure(err));
  }
}

import getBpHost from "./getBpHost";
import { quoteType } from "./enum";
import objectToParams from "@utils/objectToParams";

export const getHost = () => {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "staging":
      return "";
      break;
    case "production":
      return "";
      break;
    default:
      return "";
    // return "http://a2fb4cb8.ngrok.io";
  }
};

const host = getHost();
const api = "/api/v1/";

/**
 * cable API
 * @return {String}
 */
export function cableAPI(token = "") {
  const cable_path = `?token=${token}`;
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "staging":
      return `/cable${cable_path}`;
      break;

    case "production":
      return `wss://beachprice.com/cable${cable_path}`;
      break;

    default:
      return `ws://localhost:28030/${cable_path}`;
  }
}

/**
 * orders API
 * @param  {Number} id
 * @return {String}
 * /api/v1/orders.json
 * /api/v1/orders/2.json
 */
export function ordersAPI(id) {
  if (id) {
    return host + api + `orders/${id}`;
  }
  return host + api + `orders`;
}

/**
 * orders grade API
 * @param  {Number} product_grade_id
 * @return {String}
 * /api/v1/product_grades/2/orders.json
 */
export function ordersGradeAPI(product_grade_id) {
  return host + api + `product_grades/${product_grade_id}/orders.json`;
}

/**
 * close order API
 * @param {Number} id
 * @return {String}
 * /api/v1/orders/2/close
 */
export function closeOrderAPI(id) {
  return host + api + `orders/${id}/close`;
}

/**
 * orders payment API
 * @param  {Number} order_id 订单ID
 * @param  {String} kind     支付方式 "poli|offline|wallet"
 * @return {String}
 * /api/v1/orders/2/payments/poli
 * /api/v1/orders/2/payments/offline
 * /api/v1/orders/2/payments/wallet
 */
export function ordersPaymentAPI(order_id, kind) {
  return host + api + `orders/${order_id}/payments/${kind}`;
}

/**
 * orders API
 * @param  {String} kind ""|purchased|sold
 * @param  {Object} params {page:1, state: "pending|closed|completed"} completed可以没有
 * @return {String}
 * /api/v1/orders.json
 * /api/v1/orders/purchased.json
 * /api/v1/orders/sold.json
 */
export function ordersDashboardAPI(kind = "", params = {}) {
  let paramsStr = objectToParams(params);
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }
  let kindStr = kind == "" ? "" : `/${kind}`;
  return host + api + `orders${kindStr}.json${paramsStr}`;
}

/**
 * offer & asking prices API 报价列表
 * @param  {Object} payload { id: product_id, grade_id: grade_id|null, page: 1|2|3 }
 * @return {String}
 * /api/v1/products/2/prices.json
 */
export function pricesAPI(payload) {
  let paramsStr = objectToParams({ page: payload.page });
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }

  if (payload.grade_id) {
    return (
      host + api + `product_grades/${payload.grade_id}/prices.json${paramsStr}`
    );
  }
  return host + api + `products/${payload.id}/prices.json${paramsStr}`;
}

/**
 * offer prices API 买家出价列表
 * @param  {Number} product_grade_id
 * @param  {Number|null} id
 * @return {String}
 * /api/v1/product_grades/2/offer_prices.json
 * /api/v1/product_grades/2/offer_prices/1.json
 */
export function offerPricesAPI(product_grade_id, id) {
  if (id) {
    return (
      host +
      api +
      `product_grades/${product_grade_id}/offer_prices/${id}.json?group_by=price`
    );
  }
  return (
    host +
    api +
    `product_grades/${product_grade_id}/offer_prices.json?group_by=price`
  );
}

/**
 * offer prices API (没有产品)
 * @param  {Number|null} id
 * @return {String}
 * /api/v1/offer_prices.json
 * /api/v1/offer_prices/1.json
 */
export function offerPricesWithoutProductAPI(id) {
  if (id) {
    return host + api + `offer_prices/${id}.json`;
  }
  return host + api + `offer_prices.json`;
}

/**
 * asking prices API 卖家出价列表
 * @param  {Number} product_grade_id
 * @param  {Number|null} id
 * @return {String}
 * /api/v1/product_grades/2/asking_prices.json
 * /api/v1/product_grades/2/asking_prices/1.json
 */
export function askingPricesAPI(product_grade_id, id, params = {}) {
  let paramsStr = params ? objectToParams(params) : "";
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }
  if (id) {
    return (
      host + api + `product_grades/${product_grade_id}/asking_prices/${id}.json`
    );
  }
  return (
    host +
    api +
    `product_grades/${product_grade_id}/asking_prices.json${paramsStr}`
  );
}

/**
 * asking prices API (没有产品)
 * @param  {Number|null} id
 * @return {String}
 * /api/v1/asking_prices.json
 * /api/v1/asking_prices/1.json
 */
export function askingPricesWithoutProductAPI(id) {
  if (id) {
    return host + api + `asking_prices/${id}.json`;
  }
  return host + api + `asking_prices.json`;
}

/**
 * 报价列表
 * @param {String|Null} cont 报价其他数据相关，其值为 "products|not_done|attended|null"
 * @param {Object} params    其他参数
 *                           { page: 1 } |
 *                           { class: "Auction" } |
 *                           { class: "AskingPrice,OfferPrice", sell_type; "Assets Exchange" }
 * @param {Object} pre       前置路径参数 {productGradeId: 1}|{categoryId: 1}
 * @return {String}
 * priceablesAPI()
 * /api/v1/priceables.json
 *
 * priceablesAPI("product|not_done|attended")
 * /api/v1/priceables/products.json
 * /api/v1/priceables/not_done.json
 * /api/v1/priceables/attended.json
 *
 * priceablesAPI("", {class: "Auction"})
 * /api/v1/priceables.json?class=Auction
 *
 * priceablesAPI("", {class: "AskingPrice"}, {productGradeId: 1})
 * /api/v1/product_grades/1/priceables.json?class=AskingPrice
 * priceablesAPI("", {class: "AskingPrice"}, {categoryId: 1})
 * /api/v1/categories/1/priceables.json?class=AskingPrice
 */
export function priceablesAPI(cont, params = {}, pre = {}) {
  let str = "";
  if (cont) {
    str = `/${cont}`;
  }
  let paramsStr = params ? objectToParams(params) : "";
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }

  let preStr = "";
  if (pre && pre.productGradeId) {
    preStr = `product_grades/${pre.productGradeId}/`;
  }
  if (pre && pre.categoryId) {
    preStr = `categories/${pre.categoryId}/`;
  }
  return host + api + `${preStr}priceables${str}.json${paramsStr}`;
}

/**
 * 报价列表的相关数据
 * @param  {Number} category_id 分类ID
 * @param  {String|Null} cont        获取某一部分的（目标）信息，"header|null"
 * @param  {Object} params      其他参数 { page: 1 }
 * @return {String}
 * /api/v1/categories/1/priceables.json
 * /api/v1/categories/1/priceables/header.json
 */
export function priceablesCategoryAPI(category_id, cont, params) {
  let str = "";
  if (cont) {
    str = `/${cont}`;
  }
  let paramsStr = objectToParams(params);
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }
  return (
    host + api + `categories/${category_id}/priceables${str}.json${paramsStr}`
  );
}

/**
 * 子报价
 */
export function appliesAPI(id) {
  if (id) {
    return host + api + `applies/${id}.json`;
  }
  return host + api + `applies.json`;
}

/**
 * categories API
 * @param  {Number|null} id
 * @param  {Object|null} params {simple: true}
 * @return {String}
 * /api/v1/categories.json
 * /api/v1/categories/1.json
 */
export function categoriesAPI(id, params) {
  let paramsStr = objectToParams(params);
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }
  if (id) {
    return host + api + `categories/${id}.json${paramsStr}`;
  }
  return host + api + `categories.json${paramsStr}`;
}

/**
 * Categories active product grade API
 * @param  {Number} id
 * @return {String}
 * /api/v1/categories/1/product_grades.json
 */
export function categoriesProductGradesAPI(id) {
  if (id) {
    return host + api + `categories/${id}/product_grades.json`;
  }
  return host + api + "product_grades.json";
}

/**
 * sub categories API
 * @param  {Number} pid
 * @param  {Number|null} id
 * @return {String}
 * /api/v1/categories/1/sub_categories.json
 * /api/v1/categories/1/sub_categories/2.json
 */
export function subCategoriesAPI(pid, id) {
  if (id) {
    return host + api + `categories/${pid}/sub_categories/${id}.json`;
  }
  return host + api + `categories/${pid}/sub_categories.json`;
}

/**
 * invoices API
 * @param  {Number} orderItemid
 * @return {String}
 * /api/v1/orders/1/invoice.json
 */
export function invoicesAPI(orderId) {
  return host + api + `orders/${orderId}/invoice.json`;
}

/**
 * channels API
 * @param  {Number} targetId
 * @return {String} kind "AskingPrice"|"OfferPrice"
 * /api/v1/channels/.json?target_id=16&target_type=AskingPrice
 */
export function channelsAPI(targetId, kind) {
  let params = {};
  params.target_type = kind;
  params.target_id = targetId;
  let paramsStr = objectToParams(params);
  if (paramsStr) {
    paramsStr = `?${paramsStr}`;
  }
  return host + api + `channels.json${paramsStr}`;
}

export function channelMessagesAPI(channelId) {
  return host + api + `channels/${channelId}/messages.json`;
}

/**
 * bank create/update
 * @returns {String}
 */
export function banksAPI() {
  return host + api + "bank";
}

export function banksForOrderAPI(orderId) {
  return host + api + `orders/${orderId}/bank`;
}

/**
 * products API
 * @param  {Number|null} id
 * @return {String}
 * /api/v1/products.json
 * /api/v1/products/1.json
 */
export function productsAPI(id) {
  if (id) {
    return host + api + `products/${id}.json`;
  }
  return host + api + `products.json`;
}

/**
 * product new API
 * @param  {Number} product_id
 * @param  {String} type asking_prices | quotes
 * @return {String}
 */
export function productNewAPI(grade_id, type) {
  switch (type) {
    case quoteType.buy:
      return host + api + `product_grades/${grade_id}/offer_prices.json`;
    case quoteType.sell:
      return host + api + `product_grades/${grade_id}/asking_prices.json`;
    default:
      return "";
  }
}

/**
 * product recent trades API
 * @param  {Number} id
 * @return {String}
 * /api/v1/product_grades/1/recent_trades.json
 */
export function productsRecentTradeAPI(id) {
  return host + api + `product_grades/${id}/recent_trades.json`;
}

/**
 * cart pending asking price list API
 * @return {String}
 */
export function pendingAskingPricesAPI() {
  return host + api + "asking_prices/pending.json";
}

/**
 * cart pending offer price list API
 * @return {String}
 */
export function pendingOfferPricesAPI() {
  return host + api + "offer_prices/pending.json";
}

/**
 * cart pending update price API
 * @param  {String} type
 * @param  {Number} price_id
 * @return {String}
 */

export function cartPricesAPI(type, price_id) {
  switch (type) {
    case quoteType.buy:
      return host + api + `offer_prices/${price_id}.json`;
    case quoteType.sell:
      return host + api + `asking_prices/${price_id}.json`;
    default:
      return "";
  }
}

/**
 * charts for LastSoldAPI
 * @param  {Number} id
 * @param  {String} time "day|week|month|year"
 * @return {String}
 * /api/v1/product_grades/1/charts/day.json
 * /api/v1/product_grades/1/charts/week.json
 */
export function chartsLastSoldAPI(id, time = "day") {
  return host + api + `product_grades/${id}/kcharts/${time}.json`;
}

/**
 * Get static info of home page
 * @returns {String}
 */

export function staticAPI() {
  return host + api + "static";
}

/**
 * Get live states for layout
 * @returns {String}
 */
export function liveStatesAPI() {
  return host + api + "live_status.json";
}

/**
 * Get account users profile info
 * @returns {String}
 */
export function userProfileAPI() {
  return host + api + "users/profile.json";
}

/**
 * Get account wallet list info
 * @returns {String}
 */
export function walletEntriesAPI() {
  return host + api + "wallets/entries";
}

/**
 * Get account wallet balance
 * @returns {String}
 */
export function walletBalanceAPI() {
  return host + api + "wallets/balance";
}

/**
 * Topup to wallet
 * @param payMethod {String} stripe|poli ...
 * @returns {String}
 */
export function walletTopupAPI(payMethod) {
  return host + api + `wallets/${payMethod}`;
}

/**
 * Withdrawal from wallet
 * @returns {String}
 */
export function walletWithdrawalAPI() {
  return host + api + `withdraws`;
}

/**
 * Cancel withdrawal
 * @returns {String}
 */
export function walletWithdrawalCancelAPI(id) {
  return host + api + `withdraws/${id}/cancel`;
}

/**
 * Auctions
 * @returns {String}
 */

export function auctionsAPI(auction_id) {
  if (auction_id) {
    return host + api + "auctions/" + auction_id;
  }
  return host + api + "auctions";
}

/**
 * Listings API
 * @returns {String}
 */
export function listingsAPI(id) {
  if (id) {
    return host + api + "listings/" + id;
  }
  return host + api + "listings";
}

/**
 * Leave message for listing
 * @returns {String}
 */
export function listingLeaveMessageAPI(id) {
  return host + api + `listings/${id}/leave_message`;
}

/**
 * Exchanges API
 * @returns {String}
 */
export function exchangesAPI(id) {
  if (id) {
    return host + api + "exchanges/" + id;
  }
  return host + api + "exchanges";
}

/**
 * Bids
 * @returns {String}
 */

export function bidsAPI(auction_id) {
  return host + api + `auctions/${auction_id}/bids`;
}

/**
 * Timestamp
 * Get a stamp for maintenance time
 * @returns {String}
 */

export function timestampAPI() {
  return host + api + "timestamp";
}

/*
 * Refresh cognito user
 */
export function refreshCognitoUserAPI() {
  return host + api + "cognito_user";
}

/****************************** 以下是 BP api ***************************************/

/**
 * product list of bp API
 * @returns {String}
 */

export function bpProductsAPI() {
  return getBpHost() + "/api/v2/products";
}

/**
 * login out of bp API
 * @returns {String}
 */

export function bpLogoutAPI(redirect_url) {
  return getBpHost() + "/register?redirect_url=" + redirect_url;
}

/**
 * bank create/update of bp API
 * @returns {String}
 */

export function bpBanksAPI() {
  return getBpHost() + "/api/v2/bank";
}

/**
 * recharge deposit
 * @returns {String}
 */
export function rechargeDepositAPI() {
  return getHost() + api + "deposits";
}

/**
 * change email event
 * @returns {String}
 */
export function changeEmailEventAPI() {
  return getHost() + api + "email_events";
}

/**
 * change password event
 * @returns {String}
 */
export function changePasswordEventAPI() {
  return getHost() + api + "password_events";
}

/**
 * update profile event
 * @returns {String}
 */
export function updateProfileEventAPI(newRegister) {
  return (
    getHost() +
    api +
    `${newRegister ? "fullfill_profile_events" : "profile_events"}`
  );
}

/**
 * fetch points
 * @returns {String}
 */

export function pointsAPI() {
  return getHost() + api + "points";
}

/**
 * invite
 * @returns {String}
 */

export function inviteCodeAPI() {
  return getHost() + api + "invite_code";
}

/**
 * payment stripe
 * @returns {String}
 */

export function paymentStripesAPI(payment_id) {
  return getHost() + api + `payments/stripe/${payment_id}/complete`;
}

/**
 * payment amount
 * @returns {String}
 */

export function paymentAmountAPI(payment_id) {
  return getHost() + api + `payments/${payment_id}`;
}

/**
 * payment methods
 * @param {}
 */

export function paymentMethodsAPI(id) {
  const url = getHost() + api + "stripe_payment_methods";
  if (id) {
    return url + "/" + id;
  }
  return url;
}

/**
 * Licence number events
 * @returns {String}
 */

export function licenceNumberByOrderIdAPI(order_id) {
  return getHost() + api + `orders/${order_id}/licence_number`;
}

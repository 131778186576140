import React, { memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

//Loading Icon
export const LoadingIcon = ({ className }) => {
  return (
    <svg
      className={cx("animate-spin mx-auto", className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
LoadingIcon.propTypes = {
  className: PropTypes.string,
};
LoadingIcon.defaultProps = {
  className: "",
};

//Loading组件
export const Loading = ({ className, color, spacing, size }) => {
  const wrapperClass = cx("text-center", className, spacing);
  const iconClass = cx(color, {
    "h-16 w-16": size == "lg",
    "h-5 w-5": size == "sm",
    "h-8 w-8": size == "normal" || !size,
  });
  return (
    <div className={wrapperClass}>
      <LoadingIcon className={iconClass} />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  spacing: PropTypes.string,
  color: PropTypes.string,
};

Loading.defaultProps = {
  size: "", // "normal" | "sm" | "lg"
  className: "",
  spacing: "p-6",
  color: "text-fish-primary",
};

//Loading wrapper
//为了处理同时loading几个组件，只显示一个loading状态的情况
//@example <LoadingWrapper loading{a-loading || b-loading || c-loading}><A /><B /><C /></Loading>

export const LoadingWrapper = ({ loading, size, children }) => {
  if (loading) return <Loading size={size} className="py-12" />;
  return <div>{children}</div>;
};

LoadingWrapper.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.string, // "" | "sm" | "lg"
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

LoadingWrapper.defaultProps = {
  loading: false,
  size: "",
};

//with Loading
//为需要laoding功能的组件提供的HOC
//@example WithLoading(MarketList);

export const WithLoading = (WrappedComponent) => {
  let LoadingWrapper = (props) => {
    const { loading, size, ...restProps } = props;
    if (loading) return <Loading size={size} className="py-12" />;
    return <WrappedComponent {...restProps} />;
  };
  LoadingWrapper.propTypes = {
    loading: PropTypes.bool,
    size: PropTypes.string, // "" | "sm" | "lg"
  };
  LoadingWrapper.defaultProps = {
    loading: false,
    size: "",
  };
  return memo(LoadingWrapper);
};

import { AUCTION_FINAL_BID_CREATE_SUCCESS, AUCTION_FINAL_BID_CREATE_FAILURE, AUCTION_FINAL_BID_CREATE_RESET } from "./action";

export const auctionFinalBidCreateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case AUCTION_FINAL_BID_CREATE_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case AUCTION_FINAL_BID_CREATE_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case AUCTION_FINAL_BID_CREATE_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import numeral from "@utils/numeral";
import {
  walletBalanceAction,
  WALLET_BALANCE,
} from "@redux/modules_v2/wallets/balance/action";

/**
 * [hooks] 个人账户的 wallet balance
 * @returns { balance: Number, loading: true|false }
 */
export const useWalletBalance = () => {
  const dispatch = useDispatch();

  const fetchWalletBalance = orderId => {
    dispatch(walletBalanceAction.request({}));
  };

  const walletBalanceStatus = useSelector(state => {
    const selector = new LoadingSelector(WALLET_BALANCE, state);
    return Object.assign({}, get(state, "walletBalanceStatusV2", {}), {
      loading: selector.loading,
    });
  });

  const loading = walletBalanceStatus.loading;
  const balance = get(walletBalanceStatus, "data.balance", 0);

  /**
   * did mount
   */
  useEffect(() => {
    fetchWalletBalance();
  }, []);

  return { balance, balanceStr: numeral(balance).format(), loading };
};

import {takeEvery, call, put} from 'redux-saga/effects'
import {resendConfirmationCode} from '@utils/cognitoAuth'

import {
  RESEND_CODE_FAILED,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_LOADING,
  RESEND_CODE_REQUEST
} from './action'

function* watchResendCodeRequest(action) {
  yield takeEvery(RESEND_CODE_REQUEST, resendCodeRequest)
}

function* resendCodeRequest(action) {
  yield put({type: RESEND_CODE_LOADING})
  let {res, err} = yield call(resendCodeCall, action.payload)

  if (err) {
    yield put({type: RESEND_CODE_FAILED, error: err})
    return
  }
  yield put({type: RESEND_CODE_SUCCESS, data: res})
}

function resendCodeCall({email}) {
  return resendConfirmationCode(email)
    .then(res => ({res}))
    .catch(err => ({err}))
}

export {watchResendCodeRequest}
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

//Common Components
import Layout from "@views/layout";
import { Panel, Breadcrumb } from "@components";

//Quota Units
import QuotaUnitsShow from "./detail";
import QuotaUnitsForm from "./form";

//Hooks
import {
  useFisheryShow,
  useParams,
  useFisheryShowState,
  useFetchPermanentShow,
} from "@hooks";

const PermanentPage = (props) => {
  const [params] = useParams();
  //Permanent id
  const id = get(props, "match.params.id");
  const fisheryId = get(params, "fishery_id", "");
  const quotumId = get(params, "quotum_id", "");
  //Fishery Show Data
  const { data: fisheryShow, loading: fisheryLoading } = useFisheryShow(
    fisheryId
  );
  //Fishery Show Page State
  const { state, setQuotaUnitId, setShowPermanentList } = useFisheryShowState();

  //permanent show
  const [permanentShow, permanentShowLoading] = useFetchPermanentShow({
    permanent_listing_id: state.quotaUnitId,
  });

  //Set quota unit id
  useEffect(() => {
    id && setQuotaUnitId(id);
  }, [id]);

  const name = get(permanentShow, "[markets][0].nameStateAbbr");

  return (
    <Layout>
      <div
        className="gs-page pt-0 bg-gray-50 page-pb"
        style={{ paddingBottom: 140 }}
      >
        <div className="container px-2 lg:px-0">
          <Breadcrumb
            className="mt-4 md:mt-6"
            data={[
              {
                name: get(fisheryShow, "title", ""),
                link: get(fisheryShow, "url", ""),
              },
              {
                name: name ? `${name} For Sale/Wanted` : "Permanent Sales",
                link: `/fisheries/${fisheryId}/quotum/${quotumId}/permanent_listings`,
              },

              {
                name:
                  get(permanentShow, "listingType") === "sell"
                    ? "Quota Unit for Sale"
                    : "Quota Unit Wanted",
              },
            ]}
            loading={fisheryLoading || permanentShowLoading}
          />

          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-4 md:mt-6 md:gap-6">
            <div className="bg-white mt-px rounded-lg shadow md:col-span-8 min-hight">
              <Panel classNameWrapper="shadow-none rounded-0">
                <QuotaUnitsShow
                  permanentShow={permanentShow}
                  setQuotaUnitId={setQuotaUnitId}
                  setShowPermanentList={setShowPermanentList}
                  loading={permanentShowLoading}
                  // title={data.title}
                  type={state.showPermanentList}
                />
              </Panel>
            </div>
            <div className="md:col-span-4" style={{ minWidth: 300 }}>
              <QuotaUnitsForm
                state={state}
                enquired={true}
                // id={data.id}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

PermanentPage.propTypes = {
  props: PropTypes.object,
};

export default PermanentPage;

import React, {useState, useEffect} from "react";
import { Formik } from "formik";
import map from "lodash/map";
import get from "lodash/get";
import indexOf from "lodash/indexOf";
import pull from "lodash/pull";
import concat from "lodash/concat";
import includes from "lodash/includes";
import PropTypes from "prop-types";

//Hooks
import {
  useDeepEffect,
} from "@hooks";

/**
 * Local components
 */
import { Button } from "@components";
import { Form, Input, Select, formatNumber, Radio, Checkbox } from "@components/formik";

// {
//   email: "",
//   picture: "",
//   user_name: "",
//   first_name: "",
//   last_name: "",
//   user_type: [],
//   address: "",
//   state: "",
//   country: "",
//   post_code: "",
//   phone_country_code: "",
//   mobile_number: "",
//   company_name: "",
//   industry: [],
//   abn: "",
//   licence_number: "",
//   need_gst: false,
//   goal: [],
//   hear_us: "",
//   word_of_mouth: "",
//   hear_us_other_way: "",
// }

const ProfileForm = ({
  initialValues,
  onSubmit,
  goal,
  setGoalList,
  schema,
  updateLoading,
  states,
  staticData,
}) => {

  const [hearUsValue, setHearUsValue] = useState();

  const hearOptions = [
    {label:'Google/Bing',value:'google_bing'},
    {label:'Facebook',value:'facebook'},
    {label:'Word of Mouth',value:'word_of_mouth', input:true, name:"word_of_mouth", placeholder:"Who referred you?"},
    {label:'Ad',value:'ad'},
    {label:'Mail',value:'mail'},
    {label:'Other',value:'other', input:true, name:"hear_us_other_way", placeholder:"Tell us more"}
  ];
  const goalOptions = [
    {label:'Sell or lease-out',value:'sell_or_lease_out'},
    {label:'Buy or lease-in',value:'buy_or_lease_in'},
    {label:'Monitoring prices',value:'monitoring_prices'},
    {label:'Other',value:'other'}
  ];

  const getGoalTypes = (value) => {
    if(indexOf(goal,value) == -1) {
      const newGoalType = concat(goal, value)
      setGoalList(newGoalType);
    }else {
      const newGoalType = pull(goal, value)
      setGoalList(newGoalType);
    }
  };

  const change = (e) => {
    setHearUsValue(e);
  };

  useEffect(() => {
    getGoalTypes('');
  }, [goal]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="grid md:grid-cols-2 md:gap-x-4">
          <Input
            name="first_name"
            label="First Name"
            placeholder="First Name"
            classNameWrapper="mb-5"
            className="border-blue-gray-200"
            labelFontWeight="normal"
          />
          <Input
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            classNameWrapper="mb-5"
            className="border-blue-gray-200"
            labelFontWeight="normal"
          />
          <Input
            leftSymbol="+61"
            name="phone_number"
            label="Mobile Number"
            placeholder="Mobile Number"
            classNameWrapper="mb-5"
            className="border-blue-gray-200"
            labelFontWeight="normal"
            leftSymbolClass="border-opacity-50"
            parse={formatNumber}
          />
          <Select
            name="state"
            label={staticData.state}
            options={states}
            classNameWrapper="mb-5"
            className="rounded-md shadow-sm border border-blue-gray-200 inset-no-border"
            labelFontWeight="normal"
            placeholder="Select state"
          />
          <Input
            name="company_name"
            label="Company Name"
            placeholder="Company name"
            classNameWrapper="mb-5"
            className="border-blue-gray-200"
            labelFontWeight="normal"
          />
        </div>
        <div className="grid md:grid-cols-1 md:gap-x-4 gap-y-5">
          <Radio
            name="hear_us"
            classNameWrapper=""
            className="grid grid-cols-1 md:grid-cols-2 space-y-2 md:space-y-0 md:gap-y-3 mt-3"
            radioClassName="fish-primary rounded-md bg-white"
            label="How did you hear about us?"
            onChange={change}
            radioIconClassName="w-5 h-5 transform scale-90"
            options={map(hearOptions, (item = {}) => ({
              label: (
                <div className="block text-sm -ml-2px">
                  <div>
                    {item.label}
                  </div>
                  {item.input && hearUsValue == item.value &&(
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      className="border-blue-gray-200 mt-1 -ml-7"
                      classNameWrapper="mt-1 mb-1"
                    />
                  )}
                </div>
              ),
              value: item.value,
            }))}
          />
          <div className="mt-2">
            <div className="label block text-sm leading-5 text-fish-dark font-medium mb-2">What is your main goal?</div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-1">
              {map(goalOptions,(item) => (
                <div
                  key={item.value}
                  className="checkbox-label-group mb-1 relative"
                >
                  <input
                    name={item.value}
                    id={item.value}
                    defaultValue={item.value}
                    type="checkbox"
                    onChange={(evt) => {
                      const value = evt.target.value || '';
                      getGoalTypes(value);
                    }}
                    className="h-4 w-4 border-gray-300 rounded text-fish-primary focus:ring-fish-primary focus-within:bg-fish-primary"
                  />
                  <label
                    htmlFor={item.value}
                    className="pl-6 -ml-3 text-sm text-blue-gray-700"
                  >
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
            <Select
              name="goal"
              className="hidden"
            />
          </div>
        </div>
        
        <div className="pt-8">
          <Button
            color="gray-800"
            textColor="white"
            className="hover:bg-gray-900 h-42"
            type="submit"
            trailingIcon={
              <svg className="mt-px pt-px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8H2M9.33333 3.33334L14 8L9.33333 3.33334ZM14 8L9.33333 12.6667L14 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            full
            disabled={updateLoading}
            loading={updateLoading}
          >
            Continue
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

ProfileForm.propTypes = {
  categories: PropTypes.array,
  countries: PropTypes.array,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  schema: PropTypes.object,
  states: PropTypes.array,
  staticData: PropTypes.object,
  updateLoading: PropTypes.bool,
};

ProfileForm.defaultProps = {
  categories: [],
  countries: [],
  initialValues: {},
  onSubmit: () => {},
  schema: {},
  states: [],
  staticData: {},
  updateLoading: false,
};

export default ProfileForm;

export const INVOICE_SHOW = "invoices/show";
export const INVOICE_SHOW_REQUEST = `${INVOICE_SHOW}_request`;
export const INVOICE_SHOW_SUCCESS = `${INVOICE_SHOW}_success`;
export const INVOICE_SHOW_FAILURE = `${INVOICE_SHOW}_failure`;


export const invoiceShowAction = {
  request: (payload) => {
    return {
      type: INVOICE_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: INVOICE_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: INVOICE_SHOW_FAILURE,
      error,
    }
  },

};

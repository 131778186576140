import {
  LICENCE_UPDATE_SUCCESS,
  LICENCE_UPDATE_FAILURE,
  LICENCE_UPDATE_RECEIVED
} from "./action"

export const licenceUpdateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LICENCE_UPDATE_SUCCESS:
      return {
        justFinished: true,
        data: action.data,
        error: null,
      }
    case LICENCE_UPDATE_FAILURE:
      return {
        justFinished: true,
        data: null,
        error: action.error,
      }
    case LICENCE_UPDATE_RECEIVED:
      return initStatus
    default:
      return state
  }
}

const initStatus = {
  justFinished: false,
  data: null, 
  error: null,
}
import { takeLatest, call, put } from "redux-saga/effects";

import { QuotaApiUsecase } from "@core";
import { QUOTA_LIST_REQUEST, quotaListAction } from "./action";

// fetch quota list
export function* watchQuotaListFetch() {
  yield takeLatest(QUOTA_LIST_REQUEST, quotaListFetch);
}

function* quotaListFetch(action) {
  try {
    const usecase = new QuotaApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(quotaListAction.success(res.data));
  } catch (err) {
    yield put(quotaListAction.failure(err));
  }
}

export const AUCTION_LIST = "auction/list";
export const AUCTION_LIST_REQUEST = `${AUCTION_LIST}_request`;
export const AUCTION_LIST_SUCCESS = `${AUCTION_LIST}_success`;
export const AUCTION_LIST_FAILURE = `${AUCTION_LIST}_failure`;
export const AUCTION_LIST_UPDATE = `${AUCTION_LIST}_update`;

export const auctionListAction = {
  request: (payload) => {
    return {
      type: AUCTION_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: AUCTION_LIST_SUCCESS,
      data,
    };
  },
  update: (data) => {
    return {
      type: AUCTION_LIST_UPDATE,
      data,
    };
  },
  failure: (error) => {
    return {
      type: AUCTION_LIST_FAILURE,
      error,
    };
  },
};


export const CART_ASKING_PRICES_FETCH_REQUEST = "cart/asking_prices_fetch_request";
export const CART_ASKING_PRICES_FETCH_LOADING = "cart/asking_prices_fetch_loading";
export const CART_ASKING_PRICES_FETCH_SUCCESS = "cart/asking_prices_fetch_success";
export const CART_ASKING_PRICES_FETCH_FAILED = "cart/asking_prices_fetch_failed";

export function fetchCartAskingPricesList(payload) {
  return {
    type: CART_ASKING_PRICES_FETCH_REQUEST,
    payload
  };
}

export const CART_PRICES_UPDATE_REQUEST = "cart/prices_update_request";
export const CART_PRICES_UPDATE_LOADING = "cart/prices_update_loading";
export const CART_PRICES_UPDATE_SUCCESS = "cart/prices_update_success";
export const CART_PRICES_UPDATE_FAILED = "cart/prices_update_failed";

export function cartUpdatePrice(payload) {
  return {
    type: CART_PRICES_UPDATE_REQUEST,
    payload
  };
}

export const CART_PRICES_DELETE_REQUEST = "cart/prices_delete_request";
export const CART_PRICES_DELETE_LOADING = "cart/prices_delete_loading";
export const CART_PRICES_DELETE_SUCCESS = "cart/prices_delete_success";
export const CART_PRICES_DELETE_FAILED = "cart/prices_delete_failed";

export function cartDeletePrice(payload) {
  return {
    type: CART_PRICES_DELETE_REQUEST,
    payload
  };
}

import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { unitOfSaleMap } from "@core";
import {
  renderTextField,
  renderSelectField,
  required,
  formatNumber,
  formatFixedNumber,
  renderRadioField,
} from "@components/reduxform";
import { Button } from "@components";

const AttributesGroup = ({
  addNewMarketsAttribute,
  getUnitNameByQuotumId,
  unitNameCondition,
  fields,
  meta: { error, submitFailed },
  getQuotaOptions,
  type,
  moveMarket,
  edit,
  isLicence,
}) => {
  const options = getQuotaOptions(fields);
  return (
    <div>
      {fields.map((attr, index) => {
        const item = fields.get(index);
        const quotumId = get(item, "quotum_id", "");
        const unit_of_sale = get(item, "unit_of_sale", "");
        const unitName = getUnitNameByQuotumId(quotumId);
        const unitNameCond = unitNameCondition(quotumId, unit_of_sale);
        if (item._destroy) {
          return null;
        }
        return (
          <div
            className="border border-blue-gray-200 shadow rounded-lg p-4 pb-2 mt-4 md:mt-6 relative md:grid md:grid-cols-2 md:col-gap-4"
            key={index}
          >
            <div className="md:col-span-2">
              <Field
                name={`${attr}.quotum_id`}
                label="Listing"
                bsSize="sm"
                validate={required}
                component={renderSelectField}
                options={getQuotaOptions(fields, quotumId)}
                disabled={edit}
              />
            </div>
            <div className="md:col-span-1">
              <div className="">
                <label className="label block text-sm font-medium leading-5 text-blue-gray-700 mb-2">
                  <span>Quantity</span>
                </label>
              </div>
              <div className="flex gs-form-group-wrapper">
                <div className="flex-1">
                  <Field
                    name={`${attr}.quantity`}
                    bsSize="sm"
                    type="text"
                    className="left-field"
                    validate={required}
                    parse={(value) => formatNumber(value)}
                    component={renderTextField}
                  />
                </div>
                <div className="w-24">
                  <Field
                    name={`${attr}.unit_of_sale`}
                    bsSize="sm"
                    validate={required}
                    component={renderSelectField}
                    className="right-field"
                    options={[
                      { label: unitName, value: unitOfSaleMap.unit },
                      { label: "kg", value: unitOfSaleMap.kg },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="md:col-span-1">
              <Field
                name={`${attr}.price`}
                label={type == "sell" ? "Ask Price" : " Bid price"}
                bsSize="sm"
                type="text"
                symbol="$"
                rightSymbol={`per ${pluralize(unitNameCond, 1)}`}
                parse={(value) => formatFixedNumber(value)}
                validate={required}
                component={renderTextField}
              />
            </div>
            {!isLicence && (
              <Field
                name={`${attr}.fish_on`}
                label="Quota Status"
                validate={required}
                options={[
                  {
                    label: "Fish On",
                    value: "on",
                  },
                  { label: "Fish Off", value: "off" },
                ]}
                component={renderRadioField}
                align="left"
              />
            )}
            {index > 0 && !edit ? (
              <div
                className="absolute right-4 top-4 ml-4-tw cursor-pointer inline-block"
                onClick={() => {
                  if (item && item.id) {
                    moveMarket(item.id);
                  } else {
                    fields.remove(index);
                  }
                }}
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : null}
          </div>
        );
      })}
      {submitFailed && error && (
        <span className="invalid-feedback block">{error}</span>
      )}
      {!isEmpty(options) && !edit ? (
        <div className="mt-6">
          <Button
            size="xl"
            color="fish-primary"
            hoverColor="gray-100"
            outline
            full
            disabled={false}
            loading={false}
            onClick={() => addNewMarketsAttribute(fields)}
          >
            <div className="flex items-center">
              <svg
                className="mr-2"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.99992 14.6666C6.23181 14.6666 4.53612 13.9642 3.28587 12.714C2.03563 11.4637 1.33325 9.76803 1.33325 7.99992C1.33325 6.23181 2.03563 4.53612 3.28587 3.28587C4.53612 2.03563 6.23181 1.33325 7.99992 1.33325C9.76803 1.33325 11.4637 2.03563 12.714 3.28587C13.9642 4.53612 14.6666 6.23181 14.6666 7.99992C14.6666 9.76803 13.9642 11.4637 12.714 12.714C11.4637 13.9642 9.76803 14.6666 7.99992 14.6666ZM7.99992 13.3333C9.41441 13.3333 10.771 12.7713 11.7712 11.7712C12.7713 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7713 5.22888 11.7712 4.22868C10.771 3.22849 9.41441 2.66659 7.99992 2.66659C6.58543 2.66659 5.22888 3.22849 4.22868 4.22868C3.22849 5.22888 2.66659 6.58543 2.66659 7.99992C2.66659 9.41441 3.22849 10.771 4.22868 11.7712C5.22888 12.7713 6.58543 13.3333 7.99992 13.3333ZM8.66659 7.33325H9.99992C10.1767 7.33325 10.3463 7.40349 10.4713 7.52851C10.5963 7.65354 10.6666 7.82311 10.6666 7.99992C10.6666 8.17673 10.5963 8.3463 10.4713 8.47132C10.3463 8.59635 10.1767 8.66659 9.99992 8.66659H8.66659V9.99992C8.66659 10.1767 8.59635 10.3463 8.47132 10.4713C8.3463 10.5963 8.17673 10.6666 7.99992 10.6666C7.82311 10.6666 7.65354 10.5963 7.52851 10.4713C7.40349 10.3463 7.33325 10.1767 7.33325 9.99992V8.66659H5.99992C5.82311 8.66659 5.65354 8.59635 5.52851 8.47132C5.40349 8.3463 5.33325 8.17673 5.33325 7.99992C5.33325 7.82311 5.40349 7.65354 5.52851 7.52851C5.65354 7.40349 5.82311 7.33325 5.99992 7.33325H7.33325V5.99992C7.33325 5.82311 7.40349 5.65354 7.52851 5.52851C7.65354 5.40349 7.82311 5.33325 7.99992 5.33325C8.17673 5.33325 8.3463 5.40349 8.47132 5.52851C8.59635 5.65354 8.66659 5.82311 8.66659 5.99992V7.33325Z" />
              </svg>
              <span className="text-sm font-semibold leading-snug">
                Add another listing...
              </span>
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

AttributesGroup.propTypes = {
  addNewMarketsAttribute: PropTypes.func,
  fields: PropTypes.object,
  getQuotaOptions: PropTypes.func,
  getUnitNameByQuotumId: PropTypes.func,
  unitNameCondition: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
  type: PropTypes.string,
  moveMarket: PropTypes.func,
  isLicence: PropTypes.bool,
  edit: PropTypes.bool,
};

export default AttributesGroup;

export const MARKET_BALANCE = "markets/balance";
export const MARKET_BALANCE_REQUEST = `${MARKET_BALANCE}_request`;
export const MARKET_BALANCE_SUCCESS = `${MARKET_BALANCE}_success`;
export const MARKET_BALANCE_FAILURE = `${MARKET_BALANCE}_failure`;

export const marketBalanceAction = {
  request: (payload) => {
    return {
      type: MARKET_BALANCE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: MARKET_BALANCE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: MARKET_BALANCE_FAILURE,
      error,
    }
  },
};

import { FISHERY_FOLLOW_LIST_SUCCESS, FISHERY_FOLLOW_LIST_FAILURE } from "./action";

export const fisheryFollowListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case FISHERY_FOLLOW_LIST_SUCCESS:
      return {
        data: action.data,
        error: null
      };

    case FISHERY_FOLLOW_LIST_FAILURE:
      return {
        data: null,
        error: action.error
      };

    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null
};

import { useState, useEffect } from "react";

import { isAndroid } from "@utils/isPhone";

export const useWindowResize = () => {
  const [clientHeight, setClientHeight] = useState(
    document.documentElement.clientHeight || document.body.clientHeight
  );

  const addListener = () => {
    window && window.addEventListener("resize", resize);
  };
  const removeListener = () => {
    window && window.removeEventListener("resize", resize);
  };

  const resize = () => {
    const clientHeightNew =
      document.documentElement.clientHeight || document.body.clientHeight;
    const dom = document.getElementById("LeaseFormWrapper");

    if (clientHeight > clientHeightNew) {
      // 键盘弹出
      if (dom) {
        dom.style.maxHeight = `${clientHeightNew - 48}px`;
        dom.style.overflowY = "auto";
      }
    } else {
      // 键盘收起
      if (dom) {
        dom.style.maxHeight = null;
        dom.style.overflowY = null;
      }
    }
    setClientHeight(clientHeightNew);
  };

  useEffect(() => {
    if (isAndroid()) {
      addListener();
    }

    return () => {
      if (isAndroid()) {
        removeListener();
      }
    };
  }, []);
};

import {

  PRICEABLES_FETCH_SUCCESS,
  PRICEABLES_FETCH_FAILED,
  PRICEABLES_FETCH_LOADING,
  PRICEABLES_REFRESH,
  PRICEABLES_AUCTIONS_FETCH_LOADING,
  PRICEABLES_AUCTIONS_FETCH_SUCCESS,
  PRICEABLES_AUCTIONS_FETCH_FAILED,
  PRICEABLES_SINGLE_AUCTION_FETCH_SUCCESS,
} from "./listAction";
import get from "lodash/get";
import { quotaKind } from "@config/enum";

export const priceableListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PRICEABLES_FETCH_LOADING:
      return {
        loading: true,
        data: {},
        error: null
      };
      break;

    case PRICEABLES_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case PRICEABLES_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error
      };
      break;

    case PRICEABLES_REFRESH:
      const id = get(action, "payload.id");
      const entries = get(state, "data.entries", []).map(item =>
        Number(item.id) === Number(id) && item.class === get(action, "payload.class") ? action.payload : item
      );
      return {
        loading: false,
        data: Object.assign({}, state.data, { entries }),
        error: null
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null
};

export const priceableAuctionsListStatus = (
  state = { data: [] },
  action = {}
) => {
  switch (action.type) {
    case PRICEABLES_AUCTIONS_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;
    case PRICEABLES_AUCTIONS_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;
    case PRICEABLES_SINGLE_AUCTION_FETCH_SUCCESS:
      const id = get(action, "payload.id");
      const data = get(state, "data", []).map(item =>
        item.id === id ? action.payload : item
      );
      return {
        loading: false,
        data,
        error: null
      };
    case PRICEABLES_AUCTIONS_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;
    default:
      return state;
  }
};

import axios from 'axios';
import { takeLatest, takeEvery, call, put, select, delay } from 'redux-saga/effects';
import get from 'lodash/get';

import {
  POINTS_FETCH_REQUEST,
  POINTS_FETCH_LOADING,
  POINTS_FETCH_SUCCESS,
  POINTS_FETCH_FAILED,
  POINTS_CONSUME_REQUEST,
  POINTS_CONSUME_LOADING,
  POINTS_CONSUME_SUCCESS,
  POINTS_CONSUME_FAILED,
  POINTS_REWARD_FETCH_REQUEST,
  POINTS_REWARD_FETCH_LOADING,
  POINTS_REWARD_FETCH_FAILED,
  POINTS_REWARD_FETCH_SUCCESS
} from './action';

import { AUCTIONS_FETCH_UPDATED } from '../auctions/listAction';
import { pointsAPI } from '@config/api';

//fetch points list
export function* watchPointsFetch() {
  yield takeLatest(POINTS_FETCH_REQUEST, pointsFetch);
}

export function* pointsFetch(action) {
  yield put({ type: POINTS_FETCH_LOADING });
  const { err, res } = yield call(pointsFetchApi, action.payload);
  if (!res) {
    yield put({
      type: POINTS_FETCH_FAILED,
      error: 'Get points failed.'
    });
    return;
  }
  yield put({ type: POINTS_FETCH_SUCCESS, data: res });
}

export function pointsFetchApi(payload) {
  return axios
    .get(pointsAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

//watch points fetch reward list
export function* watchPointsRewardFetch() {
  yield takeLatest(POINTS_REWARD_FETCH_REQUEST, pointsRewardFetch);
}

export function* pointsRewardFetch(action) {
  yield put({ type: POINTS_REWARD_FETCH_LOADING });
  const { err, res } = yield call(pointsRewardFetchApi, action.payload);
  if (!res) {
    yield put({
      type: POINTS_REWARD_FETCH_FAILED,
      error: 'Get points failed.'
    });
    return;
  }
  yield put({ type: POINTS_REWARD_FETCH_SUCCESS, data: res });
}

export function pointsRewardFetchApi(payload) {
  return axios
    .post(`${pointsAPI()}/fetch`, payload)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

// watch points consume request

export function* watchPointsConsumeRequest() {
  yield takeLatest(POINTS_CONSUME_REQUEST, pointsConsumeReq);
}

export function* pointsConsumeReq(action) {
  yield put({ type: POINTS_CONSUME_LOADING });
  const { err, res } = yield call(pointsConsumeApi, action.payload);
  if (!res) {
    yield put({ type: POINTS_CONSUME_FAILED, error: 'Consume points failed' });
    return;
  }
  yield put({ type: POINTS_CONSUME_SUCCESS, data: 'Consume Points Success' });
  yield put({ type: AUCTIONS_FETCH_UPDATED, data: res });
}

export function pointsConsumeApi(payload) {
  return axios
    .post(pointsAPI(), payload)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import { useEffect, useState, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import * as Yup from "yup";

/**
 * import lib tools
 */
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

/**
 * import local tools & redux & usecase
 */
import { registerRequest, registerReset } from "@redux/modules/register/action";

import Notice from "@utils/noticeNew";
import { UrlUtils } from "@core/index";

/**
 * Register hooks
 * @param {*} param
 */
export const useRegister = (cb = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  /**
   * 注册提交后状态
   */
  const registerStatus = useSelector((state) =>
    get(state, "registerStatus", {})
  );

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    email: "",
    password: "",
    activity_code: "",
    broker_code: "",
    invite_code: "",
    agree: true,
  });

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback((values) => {
    dispatch(registerRequest(values));
  }, []);

  /**
   * Schema
   */
  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(5, "Password length should be greater than or equal to 6")
      .required("Required"),
    agree: Yup.string().max(4," ").required(true),
  });

  /**
   * 更新表单数据
   */
  useEffect(() => {
    let params = UrlUtils.queryToParams(location.search);
    setInitalValues(Object.assign({}, initialValues, params));
  }, [location.search]);

  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(registerReset());
    };
  }, []);

  useEffect(() => {
    if (!registerStatus.loading) {
      if (!isEmpty(registerStatus.data)) {
        Notice.success("Registered Successfully!");
        history && history.push(`/dashboard`);
      }

      if (registerStatus.error) {
        onError(registerStatus.error);
      }
    }
  }, [registerStatus.loading]);

  const onError = useCallback(
    (error) => {
      const type = get(error, "type", "");
      switch (type) {
        case "signInErr":
          history && history.push(`/login`);
          break;
        case "signUpErr":
          Notice.failure(get(error, "message", "Registration Failed"));
          break;
        default:
          Notice.failure("Registration Failed");
      }
    },
    []
  );

  return {
    initialValues,
    email: get(registerStatus, "data.user.username", ""),
    loading: registerStatus.loading,
    schema,
    onSubmit,
  };
};

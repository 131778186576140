import axios from "axios";
import {
  takeEvery,
  takeLatest,
  all,
  call,
  put,
  delay,
} from "redux-saga/effects";
import { quotaKind } from "@config/enum";
import get from "lodash/get";

import {
  PRICEABLES_FETCH_REQUEST,
  PRICEABLES_FETCH_LOADING,
  PRICEABLES_FETCH_SUCCESS,
  PRICEABLES_FETCH_FAILED,
  PRICEABLES_AUCTIONS_FETCH_REQUEST,
  PRICEABLES_AUCTIONS_FETCH_LOADING,
  PRICEABLES_AUCTIONS_FETCH_SUCCESS,
  PRICEABLES_AUCTIONS_FETCH_FAILED,
} from "./listAction";
import { setLoadings } from "../loadings/action";

import { priceablesAPI, priceablesCategoryAPI } from "@config/api";

//fetch priceables list
export function* watchPriceablesFetch() {
  yield takeEvery(PRICEABLES_FETCH_REQUEST, priceablesFetch);
}

export function* priceablesFetch(action) {
  if (!get(action, "payload.refetch")) {
    yield put({ type: PRICEABLES_FETCH_LOADING });
  }
  const { err, res } = yield call(
    priceablesFetchApi,
    action.payload,
    action.pre
  );

  if (!res) {
    yield put({
      type: PRICEABLES_FETCH_FAILED,
      error: "Fetch priceables list failed.",
    });
    return;
  }

  yield put({ type: PRICEABLES_FETCH_SUCCESS, data: res });
}

//fetch auction priceables list
export function* watchAuctionsPriceablesFetch() {
  yield takeLatest(PRICEABLES_AUCTIONS_FETCH_REQUEST, auctionsPriceablesFetch);
}

export function* auctionsPriceablesFetch(action) {
  yield put(
    setLoadings({ request: PRICEABLES_AUCTIONS_FETCH_REQUEST, value: true })
  );
  const { err, res } = yield call(
    priceablesFetchApi,
    action.payload,
    action.pre
  );

  // yield call(delay, 5000);
  if (!res) {
    yield all([
      put({
        type: PRICEABLES_AUCTIONS_FETCH_FAILED,
        error: "Fetch priceables list failed.",
      }),
      put(
        setLoadings({
          request: PRICEABLES_AUCTIONS_FETCH_REQUEST,
          value: false,
        })
      ),
    ]);
    return;
  }

  yield all([
    put({
      type: PRICEABLES_AUCTIONS_FETCH_SUCCESS,
      data: get(res, "entries", []),
    }),
    put(
      setLoadings({ request: PRICEABLES_AUCTIONS_FETCH_REQUEST, value: false })
    ),
  ]);
}

export function priceablesFetchApi(payload, pre) {
  let url = priceablesAPI("", payload, pre);
  // let config = [priceablesAPI("", payload)];
  // if (payload) {
  //   config.push({ params: payload });
  // }

  // let config = [];
  // if (category_id) {
  //   config.push(priceablesCategoryAPI(category_id));
  // } else {
  //   config.push(priceablesAPI());
  // }
  // if (params) {
  //   config.push({ params });
  // }

  return axios
    .get(url)
    .then((res) => ({
      res: res.data,
    }))
    .catch((err) => ({
      err,
    }));
}

export const getAuctions = (data = []) =>
  data.filter((item) => item.class === get(quotaKind, "auctionPrice.kind"));

export const adjustTime = ({
  receive_timestamp,
  response_timestamp,
  request_timestamp,
  callback_timestamp
}) => {
  const offset =
    (receive_timestamp -
      request_timestamp +
      (response_timestamp - callback_timestamp)) /
    2;
  window.moment.now = function() {
    return +new Date(getCurrentTimeInMs() + offset);
  };
};

export const getCurrentTimeInMs = () => {
  return window.performance &&
    window.performance.now &&
    window.performance.timing &&
    window.performance.timing.navigationStart
    ? window.performance.now() + window.performance.timing.navigationStart
    : Date.now();
};

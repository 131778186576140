
export const POINTS_FETCH_REQUEST = "points/fetch_request";
export const POINTS_FETCH_LOADING = "points/fetch_loading";
export const POINTS_FETCH_SUCCESS = "points/fetch_success";
export const POINTS_FETCH_FAILED = "points/fetch_failed";
export const POINTS_CONSUME_REQUEST = "points/consume_request";
export const POINTS_CONSUME_LOADING = "points/consume_loading";
export const POINTS_CONSUME_SUCCESS = "points/consume_success";
export const POINTS_CONSUME_FAILED = "points/consume_failed";
export const POINTS_REWARD_FETCH_REQUEST = "points/reward_fetch_request";
export const POINTS_REWARD_FETCH_LOADING = "points/reward_fetch_loading";
export const POINTS_REWARD_FETCH_FAILED = "points/reward_fetch_failed";
export const POINTS_REWARD_FETCH_SUCCESS = "points/reward_fetch_success";

export function fetchPoints(payload) {
  return {
    type: POINTS_FETCH_REQUEST,
    payload
  };
}

export function fetchPointsReward(payload) {
  return {
    type: POINTS_REWARD_FETCH_REQUEST,
    payload
  };
}


export function consumePoints(payload) {
  return {
    type: POINTS_CONSUME_REQUEST,
    payload
  }
}
import React from "react";
import PropTypes from "prop-types";

/**
 * import lib tools
 */
import get from "lodash/get";
import includes from "lodash/includes";

/**
 * import core
 */
import { BidderStatus } from "@core";

/**
 * import local component
 */
import FinalCard from "./_final_chance_card";
import BidCard from "./_bid_card";
import OrderCard from "./_order_card";
import CurrentPrice from "./_current_price";

/**
 * [Page] Auction bid form
 */
const AuctionForm = ({
  state,
  data,
  loading,
  fetchAuctionShow,
  fetchBidList,
}) => {
  const isCompleted = get(data, "status.isCompleted", false);
  const bidderStatus = get(data, "bidderStatus", "");
  const renderContent = () => {
    if (
      includes(
        [
          BidderStatus.failed_by_final_chance_rejected,
          BidderStatus.failed_by_final_chance_expired,
          BidderStatus.failed_by_low_bid,
        ],
        bidderStatus
      ) ||
      (isCompleted && bidderStatus === BidderStatus.not_join) ||
      loading
    ) {
      return null;
    }

    if (
      includes(
        [
          BidderStatus.won_by_fixed_price,
          BidderStatus.won_by_final_chance_accepted,
          BidderStatus.won_by_highest_bid,
        ],
        bidderStatus
      )
    ) {
      return <OrderCard data={data} />;
    }

    if (
      includes(
        [
          BidderStatus.pending_wait_for_final_chance_submit,
          BidderStatus.pending_wait_for_final_chance_confirm,
        ],
        bidderStatus
      )
    ) {
      return (
        <FinalCard
          data={data}
          fetchAuctionShow={fetchAuctionShow}
          fetchBidList={fetchBidList}
        />
      );
    }
    return <BidCard data={data} state={state} />;
  };

  return (
    <div className="lg:mb-6">
      <CurrentPrice data={data} />
      {renderContent()}
    </div>
  );
};

AuctionForm.propTypes = {
  state: PropTypes.object,
  data: PropTypes.object,
  loading: PropTypes.bool,
  fetchAuctionShow: PropTypes.func,
  fetchBidList: PropTypes.func,
};

AuctionForm.defaultProps = {
  state: {},
  data: {},
  loading: false,
  fetchAuctionShow: () => {},
  fetchBidList: () => {},
};
export default AuctionForm;

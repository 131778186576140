
export const PRODUCT_NEW_REQUEST = "product/new_request";
export const PRODUCT_NEW_LOADING = "product/new_loading";
export const PRODUCT_NEW_SUCCESS = "product/new_success";
export const PRODUCT_NEW_FAILED = "product/new_failed";

export function productNewRequest(payload) {
  return {
    type: PRODUCT_NEW_REQUEST,
    payload
  };
}

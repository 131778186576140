import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";

//Common components
import { Button, Notice, Mobile, DesktopAndTablet } from "@components";
import SearchForm from "@views/search/form";
//Local components
import FollowList from "./list";
//Hooks
import {
  useFisheryList,
  useFisheryFollow,
  useParams,
  useFisheryExistence,
} from "@hooks";

/**
 * Components
 */

const Follow = () => {
  const [pristine, setPristine] = useState(true);
  const [{ redirect_to, ...params }] = useParams("all");
  const {
    data,
    hasFollowing,
    loading: listLoading,
    updateList,
  } = useFisheryList(params);
  const { onSubmit, loading: followLoading } = useFisheryFollow(updateList);
  const history = useHistory();
  const isError = pristine ? false : hasFollowing ? false : true;
  const redirectUrl = redirect_to || "/dashboard";
  const onContinue = () => {
    setPristine(false);
    if (hasFollowing) history && history.push(redirectUrl);
  };
  const {
    fetch: fisheryExistFetch,
    loading: fisheryExistFetchLoading,
  } = useFisheryExistence(onContinue);

  const renderDesktop = () => {
    return (
      <div>
        <div
          className="mt-6 mx-auto bg-white rounded-lg overflow-x-hidden"
          style={{ width: 620 }}
        >
          <div className="px-6 pt-10">
            <Notice
              isOpen={isError}
              bgColor="orange-100"
              textColor="orange-700"
              borderColor="orange-600"
              content="Please select at least one species to continue"
            />
            <div className="mb-40">
              <h2 className="mb-6">
                Personalise your experience. <br /> Follow the fisheries you’re
                interested in.
              </h2>
              {/* <SearchForm url="/fishery/follow" autoSubmit /> */}
              <FollowList
                data={data}
                listLoading={listLoading}
                onSubmit={onSubmit}
                followLoading={followLoading}
              />
            </div>
          </div>
        </div>
        <div
          className="fixed bottom-0 pr-6 py-10 bg-white transform -translate-x-1/2"
          style={{ width: 600, left: "50%" }}
        >
          <Button
            size="xl"
            color="fish-primary"
            textColor="white"
            className="hover:bg-fish-primary-700"
            full
            type="submit"
            onClick={fisheryExistFetch}
            loading={fisheryExistFetchLoading}
            disabled={fisheryExistFetchLoading}
          >
            Continue
          </Button>
          {/* </Link> */}
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="min-h-screen h-full bg-gray-50 p-2">
        <div className="h-full bg-white px-4 pt-6 rounded-lg shadow relative">
          <Notice
            isOpen={isError}
            bgColor="orange-100"
            textColor="orange-700"
            borderColor="orange-600"
            content="Please select at least one species to continue"
          />
          <h2
            className="md:pt-6 mb-6"
          >
            Personalise your experience. Follow the fisheries you’re interested
            in.
          </h2>
          <SearchForm />
          <FollowList
            data={data}
            listLoading={listLoading}
            onSubmit={onSubmit}
            followLoading={followLoading}
          />
          <div className="mt-6">
            <Button
              size="xl"
              color="fish-primary"
              textColor="white"
              className="hover:bg-fish-primary-700"
              full
              type="submit"
              onClick={fisheryExistFetch}
              loading={fisheryExistFetchLoading}
              disabled={fisheryExistFetchLoading}
            >
              Continue
            </Button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DesktopAndTablet>{renderDesktop()}</DesktopAndTablet>
      <Mobile>{renderMobile()}</Mobile>
    </div>
  );
};

export default Follow;

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link, LoadingIcon } from "@components";

/**
 * Button
 * @param {Object} props
 * @ref https://tailwindui.com/components/application-ui/elements/buttons
 */

const defaultStyle = {
  base: "transition ease-in-out duration-150 relative",
  text: "font-medium",
};

const Button = (props) => {
  const {
    children,
    className,
    color,
    hoverColor,
    activeColor,
    disabled,
    full,
    leadingIcon,
    loading,
    outline,
    size,
    textColor,
    trailingIcon,
    href,
    type,
    ...restProps
  } = props;

  const Tag = href ? Link : "button";
  const isIconLeft = !!leadingIcon;
  const isIconRight = !!trailingIcon;

  const sizeClass = () => {
    switch (size) {
      case "xxs":
        return "text-xs leading-4 px-2 py-1 rounded";
      case "xs":
        return "text-xs leading-4 px-2.5 py-1.5 rounded";
      case "sm":
        return "text-sm leading-4 px-3-tw py-2-tw rounded-md";
      case "md":
        return "text-sm leading-5 px-4-tw py-2-tw rounded-md";
      case "lg":
        return "leading-6 px-4 py-2 rounded-md";
      case "xl":
        return "leading-6 px-6 py-3-tw rounded-md";
      default:
        return "text-sm leading-5 px-4-tw py-2-tw rounded-md";
    }
  };

  const colorClass = () => {
    const effectColor = `hover:bg-${
      hoverColor ? hoverColor : color
    } active:bg-${activeColor ? activeColor : color}`;
    if (outline) {
      return `text-${
        textColor ? textColor : color
      } border border-${color} ${effectColor}`;
    }
    return `text-${
      textColor ? textColor : "white"
    } bg-${color} ${effectColor} border-transparent`;
  };

  const disabledClass = () => {
    if (disabled || loading) {
      return "opacity-50 cursor-not-allowed pointer-events-none";
    }
  };

  const iconSpacingBySize = () => {
    switch (size) {
      case "xxs":
        return 2;
      case "xs":
        return 2;
      case "sm":
        return 2;
      case "md":
        return 3;
      case "lg":
        return 3;
      case "xl":
        return 3;
      default:
        return 2;
    }
  };

  const loadingIconClassBySize = () => {
    switch (size) {
      case "sm":
        return "w-4 h-4";
      case "xl":
        return "w-6 h-6";
      default:
        return "w-5 h-5";
    }
  };

  const iconRelativeStyleByPostion = () => {
    if (isIconLeft) {
      return `-ml-1 mr-${iconSpacingBySize(size)}`;
    }
    if (isIconRight) {
      return `-mr-1 ml-${iconSpacingBySize(size)}`;
    }
    return "";
  };

  const iconAbsolutStyleByPosition = () => {
    if (isIconLeft) {
      return `absolute left-0 inset-y ml-${iconSpacingBySize(size)}`;
    }
    if (isIconRight) {
      return `absolute right-0 inset-y mr-${iconSpacingBySize(size)}`;
    }
    return "";
  };

  const iconClass = () => {
    if (full) {
      return iconAbsolutStyleByPosition();
    }
    return iconRelativeStyleByPostion();
  };

  const loadingIconClassByColor = () => {
    if (outline) {
      return `text-${textColor ? textColor : color}`;
    }
    return `text-${textColor ? textColor : "white"}`;
  };

  const buttonClass = cx(
    defaultStyle.base,
    defaultStyle.text,
    sizeClass(),
    colorClass(),
    disabledClass(),
    { "block w-full text-center": full, "inline-flex items-center ": !full },
    className
  );

  let buttonProps = href
    ? Object.assign({}, { className: buttonClass }, { to: href })
    : Object.assign({}, { className: buttonClass }, { type, disabled });

  return (
    <Tag {...buttonProps} {...restProps}>
      {loading ? (
        <LoadingIcon
          className={cx(loadingIconClassBySize(), loadingIconClassByColor())}
        />
      ) : (
        <>
          {isIconLeft && <span className={iconClass()}>{leadingIcon}</span>}
          {children}
          {isIconRight && <span className={iconClass()}>{trailingIcon}</span>}
        </>
      )}
    </Tag>
  );
};

Button.propTypes = {
  full: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  activeColor: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  leadingIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg", "xl"]),
  trailingIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  textColor: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  //  hoverColor: "indigo-700",
  className: "", //button 额外的样式
  color: "fish-primary", //button 颜色
  disabled: false, //button 的disabled
  full: false, //button 宽度是否是100%
  leadingIcon: "", //button 左侧图标
  loading: false, //TODO button loading 效果图
  outline: false, //button 样式是否是outline
  size: "md", //button 大小 sx/sm/md/lg/xl
  trailingIcon: "", //button 右侧图标
  type: "button", //button 类型,
  textColor: "", // button 字体颜色
};

export default Button;

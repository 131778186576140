export const AUCTION_NEW = "auction/new";
export const AUCTION_NEW_REQUEST = `${AUCTION_NEW}_request`;
export const AUCTION_NEW_SUCCESS = `${AUCTION_NEW}_success`;
export const AUCTION_NEW_FAILURE = `${AUCTION_NEW}_failure`;
export const AUCTION_NEW_RESET = `${AUCTION_NEW}_reset`;

export const auctionNewAction = {
  request: (payload) => {
    return {
      type: AUCTION_NEW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: AUCTION_NEW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: AUCTION_NEW_FAILURE,
      error,
    }
  },
  reset: () => {
    return {
      type: AUCTION_NEW_RESET
    }
  }
};

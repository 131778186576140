import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { CREATED_AUCTIONS_REQUEST, createdAuctionsAction } from "./action";

// fetch list
export function* watchCreatedAuctionsFetch() {
  yield takeLatest(CREATED_AUCTIONS_REQUEST, createdAuctionsFetch);
}

function* createdAuctionsFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "createdAuctions"], action.payload);
    yield put(createdAuctionsAction.success(res.data));
  } catch (err) {
    yield put(createdAuctionsAction.failure(err));
  }
}

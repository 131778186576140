
export const PRODUCTS_RECENT_TRADE_FETCH_REQUEST = "products/fetch_recent_trade_request";
export const PRODUCTS_RECENT_TRADE_FETCH_LOADING = "products/fetch_recent_trade_loading";
export const PRODUCTS_RECENT_TRADE_FETCH_SUCCESS = "products/fetch_recent_trade_success";
export const PRODUCTS_RECENT_TRADE_FETCH_FAILED = "products/fetch_recent_trade_failed";


export function fetchProductRecentTrade(id) {
  return {
    type: PRODUCTS_RECENT_TRADE_FETCH_REQUEST,
    payload: {
      id
    }
  };
}
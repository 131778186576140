import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { useFormikContext, getIn } from "formik";
import cx from "classnames";

import WithLabel from "./with_label";

const FlatpickrTime = (props) => {
  const { name, className, placeholder, onChange } = props;
  const { values, setFieldValue } = useFormikContext();

  //select on change 的 callback
  const onChangeValue = (values) => {
    setFieldValue(name, values);
    onChange && onChange(values);
  };

  return (
    <Flatpickr
      data-enable-time
      name={name}
      value={getIn(values, name)}
      className={cx("form-input", className)}
      placeholder={placeholder || "Select time"}
      options={{
        time_24hr: true,
        noCalendar: true,
        dateFormat: "H:i",
      }}
      onChange={(datetimes, value, config) => {
        onChangeValue(datetimes[0]);
      }}
    />
  );
};

FlatpickrTime.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

FlatpickrTime.defaultProps = {
  label: "",
  error: "",
  className: "",
  placeholder: "Select time",
  onChange: () => {},
};

export default WithLabel("flatpickr_time")(FlatpickrTime);

import { takeLatest, call, put } from "redux-saga/effects";

import { OrderApiUsecase } from "@core";
import { ORDER_PURCHASED_REQUEST, orderPurchasedAction } from "./action";

// order purchased
export function* watchOrderPurchasedFetch() {
  yield takeLatest(ORDER_PURCHASED_REQUEST, orderPurchasedFetch);
}

function* orderPurchasedFetch(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "purchased"], action.payload);
    yield put(orderPurchasedAction.success(res.data));
  } catch (err) {
    yield put(orderPurchasedAction.failure(err));
  }
}

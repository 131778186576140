import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";
import get from "lodash/get";
import omit from "lodash/omit";

export class ListingApiRepo extends BaseApiRepo {
  constructor() {
    super();
  }

  /**
   * create listing
   * @param {object} payload 
   * {
        quota_market_id: 1,
        price: 10,
        quantity: 10,
        quotum_transfer_apply_attributes: {
          quota_transfer_ids: [1]
        }
      }
   */
  create(payload) {
    this.setApi(`${API}/multi_step_offers`);
    const data = omit(payload, [
      "marketType",
      "market",
      "fisheryId",
      "salesTypes",
    ]);
    return this.post(data);
  }

  /**
   * transfer quota transfer
   * @param {object} payload
   */
  transferQuota(payload) {
    this.setApi(`${API}/quota_transfers`);
    let formData = new FormData();
    let file = get(payload, "file", {});
    let name = get(payload, "file.name", "");
    formData.append("file", file);
    formData.append("name", name);
    return this.post(formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  /**
   * get quota transfers
   */
  getQuotaTransfers(payload) {
    this.setApi(`${API}/quota_transfers`);
    return this.get(payload);
  }

  /**
   * enquiry 
   * @param {object} payload 
   * {
      selling: "Permanent quota units",
      interested: "Public fixed price or auction listing",
      fishery_id: fishery.id,
      email: "geekerzp@gmail.com",
      country: "China",
      first_name: "peng",
      last_name: "zhang",
      phone_country_code: "86",
      phone_number: "15184714328",
      state: "Beijing"
    }
   */
  enquiry(payload) {
    this.setApi(`${API}/sell_request_forms`);
    return this.post(payload);
  }
}

import axios from "axios";
import {takeEvery, call, put} from 'redux-saga/effects';

import {
  CHANGE_PASSWORD_EVENT_REQUEST,
  changePasswordEventLoading,
  changePasswordEventSuccess,
  changPasswordEventFailed
} from "./action";

import {changePasswordEventAPI} from "@config/api";

function* watchChangePasswordEventRequest() {
  yield takeEvery(CHANGE_PASSWORD_EVENT_REQUEST, changePasswordEventRequest)
}

function* changePasswordEventRequest(action) {
  yield put(changePasswordEventLoading())

  try {
    const res = yield call(axios.post, changePasswordEventAPI(), {})
    yield put(changePasswordEventSuccess("SUCCESS"))
  } catch (err) {
    yield put(changPasswordEventFailed(err))
  }
}

export { watchChangePasswordEventRequest }
export const ASKING_PRICES_DELETE = "asking_prices/delete";
export const ASKING_PRICES_DELETE_LOADING = "asking_prices/delete_loading";
export const ASKING_PRICES_DELETE_SUCCESS = "asking_prices/delete_success";
export const ASKING_PRICES_DELETE_FAILED = "asking_prices/delete_failed";
export const ASKING_PRICES_DELETE_RECEIVED = "asking_prices/delete_received";

export function askingPriceDelete(id, payload = {}) {
  return {
    type: ASKING_PRICES_DELETE,
    id,
    payload
  };
}
import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";


import {
  walletWithdrawalAPI,
} from "@config/api";


import {
  WALLET_WITHDRAWAL_REQUEST,
  walletWithdrawalFailed,
  walletWithdrawalSuccess,
  walletWithdrawalLoading,
} from "./withdrawalAction";

export function* watchWalletWithdrawalPost() {
  yield takeLatest(WALLET_WITHDRAWAL_REQUEST, walletWithdrawalPost);
}

export function* walletWithdrawalPost(action) {

  yield put(walletWithdrawalLoading());

  const { res, err } = yield call(walletWithdrawalApi, action.payload);

  if (err) {
    yield put(walletWithdrawalFailed(err));
    return;
  }
  yield put(walletWithdrawalSuccess(res));
}

export function walletWithdrawalApi(payload) {
  return axios
    .post(walletWithdrawalAPI(), { ...payload } )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


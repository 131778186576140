import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import cx from "classnames";

/**
 * import local component
 */
import { TimeCountDown, Helper } from "@components";

const TimeCountdown = ({
  data,
  auctionLastChance,
  state,
  setAuctionLastChance,
  setAuctionWaitRuling,
  countdownRef,
}) => {
  const end = get(data, "status.end", false);
  const isSuccessByFixedPriceSold = get(
    data,
    "status.isSuccessByFixedPriceSold",
    false
  );
  if (isSuccessByFixedPriceSold) {
    return (
      <div className="text-sm text-blue-gray-500 py-2">Auction completed</div>
    );
  }
  if (end) {
    return (
      <div className="text-sm text-blue-gray-500 py-2">{`Auction completed ${get(
        data,
        "show.expiredAtStr",
        ""
      )}`}</div>
    );
  }

  return (
    <div className="py-2">
      <div className="font-medium lg:flex items-center">
        <div className="mr-2">
          <TimeCountDown
            date={get(data, "show.expiredAt", "")}
            now={() => {
              return window.moment.now();
            }}
            theme="gray"
            themeClosed="danger"
            timeClosed={12 * 3600 * 1000}
            onTick={(delta) => {
              if (delta.total > 60 * 1000 || state.auctionLastChance) {
                return;
              }
              setAuctionLastChance(true);
            }}
            onComplete={() => {
              setAuctionWaitRuling(true);
            }}
            ref={countdownRef}
          />
        </div>
        {auctionLastChance && (
          <Helper
            theme="red"
            content="Any bids placed in the last 60 seconds will extend the auction for another 60 seconds."
          >
            <div
              className="text-xxs whitespace-no-wrap text-fish-red uppercase border rounded-full border-fish-red cursor-pointer inline-flex space-x-1 items-center"
              style={{ padding: "0 8px" }}
            >
              <span>Ending Soon</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00016 14.6663C4.31816 14.6663 1.3335 11.6817 1.3335 7.99967C1.3335 4.31767 4.31816 1.33301 8.00016 1.33301C11.6822 1.33301 14.6668 4.31767 14.6668 7.99967C14.6668 11.6817 11.6822 14.6663 8.00016 14.6663ZM7.3335 9.99967V11.333H8.66683V9.99967H7.3335ZM7.3335 4.66634V8.66634H8.66683V4.66634H7.3335Z"
                  fill="url(#paint0_linear_4360_4865)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_4360_4865"
                    x1="14.6847"
                    y1="0.948268"
                    x2="0.912492"
                    y2="14.7496"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#F56D5B" />
                    <stop offset="1" stop-color="#EA239A" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </Helper>
        )}
      </div>
    </div>
  );
};

TimeCountdown.propTypes = {};

export default TimeCountdown;

import { useState, useCallback } from "react";
import { useDeepEffect } from "@hooks";

/**
 * [hooks] Limited List
 */
export const useLimitedList = (list = [], limit) => {
  const length = list.length;
  const initalValue = limit && length - limit > 0 ? list.slice(0, limit) : list;
  const [limitedList, setList] = useState([]);

  const setLimitedList = useCallback(
    (limit) => {
      if (!limit) {
        setList(list);
        return;
      }
      setList(list.slice(0, limit));
    },
    [list]
  );

  useDeepEffect(() => {
    setList(initalValue);
  }, [list]);

  return [limitedList, setLimitedList];
};

import { MARKET_SHOW_SUCCESS, MARKET_SHOW_FAILURE, MARKET_SHOW_RESET } from "./action";

export const marketShowStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case MARKET_SHOW_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case MARKET_SHOW_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case MARKET_SHOW_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

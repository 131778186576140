export const FISHERY_EXISTENCE = "fishery/existence";
export const FISHERY_EXISTENCE_REQUEST = `${FISHERY_EXISTENCE}_request`;
export const FISHERY_EXISTENCE_SUCCESS = `${FISHERY_EXISTENCE}_success`;
export const FISHERY_EXISTENCE_FAILURE = `${FISHERY_EXISTENCE}_failure`;
export const FISHERY_EXISTENCE_RESET = `${FISHERY_EXISTENCE}_reset`;

export const fisheryExistenceAction = {
  request: (payload) => {
    return {
      type: FISHERY_EXISTENCE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_EXISTENCE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_EXISTENCE_FAILURE,
      error,
    }
  },
  reset: () => {
    return { type: FISHERY_EXISTENCE_RESET };
  }
};

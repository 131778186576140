import React from "react";
import classnames from "classnames";
import get from "lodash/get";
import { orderStateMap } from "@config/enum";

const generateOrderStateIndex = (depositPaid, state = "") => {
  switch (state) {
    case orderStateMap.pending_payment:
    case orderStateMap.pending_confirmation:
      if (depositPaid) {
        return 2;
      } else {
        return 1;
      }
      break;

    case orderStateMap.completed:
    case orderStateMap.closed:
      return 3;
      break;

    default:
      return -1;
      break;
  }
};

const generateProcessList = (state = "", list, listClosed) => {
  switch (state) {
    case orderStateMap.closed:
      return listClosed;
      break;

    case orderStateMap.pending_payment:
    case orderStateMap.pending_confirmation:
    case orderStateMap.completed:
      return list;
      break;

    default:
      return [];
      break;
  }
};

/**
 * [Component] Order show page process
 */
const SectionProcess = (props) => {
  const { order } = props;
  
  const current = generateOrderStateIndex(order.depositPaid, order.paidState);
  const list = [
    {
      label: "Order created",
    },
    {
      label: "Waiting for payment",
    },
    {
      label: "Order completed",
    },
  ];
  const listClosed = [
    {
      label: "Order created",
    },
    {
      label: "Waiting for payment",
    },
    {
      label: "Order closed",
    },
  ];

  const currentList = generateProcessList(order.paidState, list, listClosed);

  const renderCircle = (index, current) => {
    if (index === current - 1) {
      return (
        <div className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-fish-primary rounded-full">
          <span
            className="h-2 w-2 bg-fish-primary rounded-full"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Step current</span>
        </div>
      );
    }
    if (index < current - 1) {
      return (
        <div className="relative w-8 h-8 flex items-center justify-center bg-fish-primary rounded-full hover:bg-blue-900">
          <svg
            className="w-5 h-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Step past</span>
        </div>
      );
    }
    return (
      <div className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-gray-300 rounded-full hover:border-blue-gray-400">
        <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
        <span className="sr-only">Step uncoming</span>
      </div>
    );
  };

  return (
    <div className="border-blue-gray-200 border block rounded-lg bg-white">
      <div className="pt-5 px-4 md:px-8 text-lg font-bold leading-tight">
        Current Status
      </div>
      <div className="py-8 px-6 md:px-8">
        <nav>
          <ol className="overflow-hidden">
            {currentList.map((item, index) => {
              const isLast = index === currentList.length - 1;
              const isLinePast = index < current - 1;
              const lineClass = classnames(
                "-ml-px absolute top-4 left-4 h-full",
                {
                  "bg-fish-primary": isLinePast,
                  "bg-gray-300": !isLinePast,
                }
              );
              return (
                <li className="relative pb-10" key={index}>
                  {!isLast ? (
                    <div
                      className={lineClass}
                      style={{ width: 2, marginTop: 2 }}
                      aria-hidden="true"
                    ></div>
                  ) : null}
                  <div className="relative flex items-center group">
                    <span className="h-9 flex items-center">
                      {renderCircle(index, current)}
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase">
                        {index === 2 && current === 2
                          ? "Deposit Paid"
                          : item.label}
                      </span>
                      {item.subLabel && (
                        <span className="text-sm text-blue-gray-500">
                          {item.subLabel}
                        </span>
                      )}
                    </span>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};

SectionProcess.propTypes = {};

export default SectionProcess;

import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class OfferApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.market = "quota_markets";
    this.model = "offers";
  }

  /**
   * Offer Api instance list with market
   * @param {object} payload { marketId: 1, ... }
   */
  list(payload) {
    this.setApi(`${API}/${this.market}/${payload.marketId}/${this.model}`);
    return this.get(omit(payload, "marketId"));
  }

  /**
   * Offer Api instance create with market
   * @param {object} payload { marketId: 1, offer: {price_cents: 100, quantity: 200} }
   */
  create(payload) {
    this.setApi(`${API}/${this.market}/${payload.marketId}/${this.model}`);
    return this.post(omit(payload, "marketId"));
  }

  /**
   * Offer Api instance update with market
   * @param {object} payload { marketId: 1, offerId: 1, offer: {price_cents: 100, quantity: 200} }
   */
  update(payload) {
    this.setApi(
      `${API}/${this.market}/${payload.marketId}/${this.model}/${payload.offerId}`
    );
    return this.put(omit(payload, ["marketId", "offerId"]));
  }

  /**
   * Offer Api instance destroy with market
   * @param {object} payload { marketId: 1, offerId: 1 }
   */
  destroy(payload) {
    this.setApi(
      `${API}/${this.market}/${payload.marketId}/${this.model}/${payload.offerId}`
    );
    return this.delete();
  }

  /**
   * Offer Api instance create order with market
   * @param {object} payload { marketId: 1, quantity: 20, items: [ { quantity: 2, price: 1000 } ] }
   */
  order(payload) {
    this.setApi(
      `${API}/${this.market}/${payload.marketId}/${this.model}/order`
    );
    return this.post(omit(payload, "marketId"));
  }

  /**
   * create apply for an unapprove offer
   */
  apply(payload) {
    this.setApi(
      `${API}/${this.model}/${payload.offerId}/quotum_transfer_apply`
    );
    return this.post(payload);
  }

  /**
   * confirm quotum transfer apply
   * @param {object} payload {offerId}
   */
  applyConfirm(payload) {
    this.setApi(
      `${API}/${this.model}/${payload.offerId}/quotum_transfer_apply/confirm`
    );
    return this.post(payload);
  }
}

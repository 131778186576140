import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";


import {
  walletEntriesAPI,
} from "@config/api";


import {
  WALLET_REQUEST,
  walletFailed,
  walletSuccess,
  walletLoading,
} from "./listAction";

export function* watchWalletRequest() {
  yield takeLatest(WALLET_REQUEST, walletRequest);
}

export function* walletRequest(action) {
  yield put(walletLoading());

  const { res, err } = yield call(walletApi, action.payload);

  if (err) {
    yield put(walletFailed(err));
    return;
  }
  yield put(walletSuccess(res));
}

export function walletApi(payload) {
  return axios
    .get(walletEntriesAPI(), { params: payload } )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


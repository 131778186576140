import React from "react";
import map from "lodash/map";
import findIndex from "lodash/findIndex";

import { Link, Loading } from "@components";
import { List } from "@views/markets/auction/list";

const FeaturedListings = ({
  loading,
  auctionList,
  fisheryList,
  marketLoading,
  marketList,
}) => {
  const getLogoImage = (id) => {
    const index = findIndex(fisheryList, { id: id });
    const logoImage = index === -1 ? "" : fisheryList[index].logoImage;
    return logoImage;
  };

  return (
    <div className="">
      <div className="">
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <List data={auctionList} image={getLogoImage} className="mb-4" />
        )}
        {marketLoading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <ul className="space-y-4">
            {map(marketList, (item, index) => {
              return (
                <Link
                  to={item.url}
                  key={index}
                  className="block relative bg-white shadow-sm rounded-lg overflow-hidden  transition duration-300 ease-in-out border-2 border-white hover:border-fish-primary"
                >
                  <div className="p-4 md:p-6 bg-white rounded-lg md:rounded-none shadow-sm md:shadow-none border-blue-gray-200 hover:bg-gray-50 cursor-pointer">
                    <div className="md:flex justify-between items-center">
                      <div className="flex-1 flex md:items-center">
                        <div className="w-12 h-12 md:w-16 md:h-16 mr-4">
                          {getLogoImage(item.fisheryId) ? (
                            <img
                              src={getLogoImage(item.fisheryId)}
                              className="w-full h-full"
                              alt=""
                            />
                          ) : (
                            <div className="w-full h-full rounded-full bg-gray-100"></div>
                          )}
                        </div>
                        <div className="flex-1 space-y-2">
                          <div className="text-sm md:text-base leading-tight font-medium text-blue-gray-800">
                            {item.title} Quota Lease
                          </div>
                          <div className="flex items-center space-x-4">
                            <div
                              className="text-blue-700 px-4 text-sm rounded-full"
                              style={{
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                background: "#EAFBFE",
                              }}
                            >
                              Instant transfer
                            </div>
                            <div className="text-blue-gray-500 text-sm">
                              Seasonal Quota
                            </div>
                          </div>
                          <div className="md:hidden space-y-2">
                            <div className="flex items-center">
                              <svg
                                className="w-4 h-4 md:w-auto md:h-auto"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.3 1.04602C11.503 1.10986 11.6803 1.2368 11.8062 1.40837C11.9321 1.57995 12 1.78721 12 2.00002V7.00002H16C16.1829 6.99994 16.3623 7.05003 16.5188 7.14484C16.6752 7.23965 16.8026 7.37556 16.8872 7.53776C16.9717 7.69996 17.0102 7.88225 16.9983 8.06478C16.9865 8.24732 16.9248 8.4231 16.82 8.57302L9.81999 18.573C9.69816 18.7476 9.52382 18.8787 9.32233 18.9473C9.12084 19.0159 8.90271 19.0184 8.69968 18.9545C8.49666 18.8906 8.31932 18.7635 8.19348 18.5919C8.06764 18.4202 7.99985 18.2129 7.99999 18V13H3.99999C3.81707 13.0001 3.63763 12.95 3.4812 12.8552C3.32478 12.7604 3.19735 12.6245 3.1128 12.4623C3.02824 12.3001 2.9898 12.1178 3.00164 11.9353C3.01349 11.7527 3.07518 11.5769 3.17999 11.427L10.18 1.42702C10.302 1.2528 10.4764 1.12203 10.6778 1.05369C10.8792 0.985356 11.0972 0.983018 11.3 1.04702V1.04602Z"
                                  fill="#5DC7FA"
                                />
                              </svg>
                              <div className="ml-1 text-sm font-medium text-blue-gray-800 leading-tight">
                                {item.stickLowestSellerPrice}/kg
                              </div>
                            </div>
                            <div className="md:mt-1 text-xs md:text-sm text-blue-gray-500 leading-tight">
                              Transferred to you in minutes
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-8 hidden md:block">
                        <div className="hidden w-12 md:w-12 mr-4"></div>
                        <div className="flex-1 flex flex-row-reverse md:block justify-between items-center">
                          <div className="flex justify-end items-center">
                            <svg
                              className="w-4 h-4 md:w-auto md:h-auto"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.3 1.04602C11.503 1.10986 11.6803 1.2368 11.8062 1.40837C11.9321 1.57995 12 1.78721 12 2.00002V7.00002H16C16.1829 6.99994 16.3623 7.05003 16.5188 7.14484C16.6752 7.23965 16.8026 7.37556 16.8872 7.53776C16.9717 7.69996 17.0102 7.88225 16.9983 8.06478C16.9865 8.24732 16.9248 8.4231 16.82 8.57302L9.81999 18.573C9.69816 18.7476 9.52382 18.8787 9.32233 18.9473C9.12084 19.0159 8.90271 19.0184 8.69968 18.9545C8.49666 18.8906 8.31932 18.7635 8.19348 18.5919C8.06764 18.4202 7.99985 18.2129 7.99999 18V13H3.99999C3.81707 13.0001 3.63763 12.95 3.4812 12.8552C3.32478 12.7604 3.19735 12.6245 3.1128 12.4623C3.02824 12.3001 2.9898 12.1178 3.00164 11.9353C3.01349 11.7527 3.07518 11.5769 3.17999 11.427L10.18 1.42702C10.302 1.2528 10.4764 1.12203 10.6778 1.05369C10.8792 0.985356 11.0972 0.983018 11.3 1.04702V1.04602Z"
                                fill="#5DC7FA"
                              />
                            </svg>
                            <div className="ml-1 text-base md:text-base font-medium text-blue-gray-800 leading-tight">
                              {item.stickLowestSellerPrice}/kg
                            </div>
                          </div>
                          <div className="md:mt-1 text-xs md:text-sm text-blue-gray-500 leading-tight">
                            Transferred to you in minutes
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

FeaturedListings.propTypes = {};

FeaturedListings.defaultProps = {};

export default FeaturedListings;

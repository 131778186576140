import React, { memo } from "react";
import { useHistory } from "react-router-dom";

//Hooks
import { useModal } from "@hooks";

//Common Components
import { ModalWrapper } from "@views/common";
import { Button } from "@components";

/**
 * With Confirm Modal
 * Confirm Modal Window 的 HOC 组件
 * @param {Object} content
 * @example WithConfirmModal({url: "/", title: "title", text: "text...."})(Component);
 * 在Component组件中执行 toggleConfirmModal() 来控制显示
 */

export const WithConfirmModal = (
  content = {
    url: "/", //confirm按钮点击后跳转的地址,
    getUrl: () => {},
    title: "title", //modal的标题
    text: "text...", //modal的内容
    callback: () => {}, //回调
    button: "Confirm",
    closedUrl: "",
  }
) => (WrappedComponent) => {
  let ConfirmModalWrapper = (props = {}) => {
    const history = useHistory();
    const { isOpen, toggleModal } = useModal();
    return (
      <div>
        <WrappedComponent toggleConfirmModal={toggleModal} {...props} />
        <ModalWrapper
          isOpen={isOpen}
          toggleModal={toggleModal}
          onClosed={() => {
            if (content.closedUrl) {
              history && history.push(content.closedUrl);
            }
          }}
        >
          <React.Fragment>
            <div className="md:px-10">
              <h2 className="mb-2">
                {content.title}
              </h2>
              <div className="text-center text-base leading-6 font-medium mt-10 mb-12">
                {content.text}
              </div>
              <div>
                <Button
                  size="xl"
                  color="fish-primary"
                  textColor="white"
                  className="hover:bg-fish-primary-700"
                  full
                  onClick={() => {
                    toggleModal();
                    content.callback && content.callback();
                    if (content.getUrl) {
                      history && history.push(content.getUrl(props));
                      return;
                    }
                    if (content.url) {
                      history && history.push(content.url);
                    }
                  }}
                >
                  {content.button}
                </Button>
              </div>
            </div>
          </React.Fragment>
        </ModalWrapper>
      </div>
    );
  };
  return memo(ConfirmModalWrapper);
};

export default WithConfirmModal;

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  ORDERS_DASHBOARD_FETCH_REQUEST,
  ORDERS_DASHBOARD_FETCH_LOADING,
  ORDERS_DASHBOARD_FETCH_SUCCESS,
  ORDERS_DASHBOARD_FETCH_FAILED,
} from "./dashboardAction";

import {
  priceablesAPI,
  ordersDashboardAPI,
} from "@config/api";


//fetch orders list for dashboard
export function* watchOrdersDashboardFetch() {
  yield takeEvery(ORDERS_DASHBOARD_FETCH_REQUEST, ordersDashboardFetch);
}

export function* ordersDashboardFetch(action) {
  yield put({ type: ORDERS_DASHBOARD_FETCH_LOADING });
  const { err, res } = yield call(ordersDashboardFetchApi, action.payload);

  if (!res) {
    yield put({
      type: ORDERS_DASHBOARD_FETCH_FAILED,
      error: "Fetch orders list for dashboard failed."
    });
    return;
  }
  yield put({ type: ORDERS_DASHBOARD_FETCH_SUCCESS, data: res });
}

export function ordersDashboardFetchApi(payload) {
  let url;
  if (get(payload, "params.state") == "not_done") {
    delete payload.params.state;
    url = priceablesAPI("not_done", payload.params);
  } else {
    let kind = payload.kind == 'all' ? "" : payload.kind;
    url = ordersDashboardAPI(kind, payload.params);
  }

  return axios
    .get(url)
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

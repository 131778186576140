import getBpHost from "./getBpHost";
import isEmpty from "lodash/isEmpty";

/**
 * 当前人员所视为自己的身份
 *
 * buy: 买家
 * 1、着重所看列表为报价列表，购买报价的产品
 * 2、向购买需求列表提供数据
 *
 * sell: 卖家
 * 1、着重所看列表为购买需求列表，卖出自己的产品
 * 2、向报价列表提供数据
 *
 * @type {Object}
 */
export const quoteType = {
  sell: "sell",
  buy: "buy",
};

/**
 * 报价详情页的当前类型的map
 *
 * AskingPrice 卖家报价
 * 在这种情况下，用户只能作为买家身份，做“购买”操作
 *
 * OfferPrice 买家报价
 * 在这种情况下，用户只能作为卖家身份，做“卖出”操作
 *
 * @type {Object}
 */
export const quotaKind = {
  askingPrice: {
    kind: "AskingPrice",
    role: "buy",
  },
  offerPrice: {
    kind: "OfferPrice",
    role: "sell",
  },
  auctionPrice: {
    kind: "Auction",
    role: "auction",
  },
};

/**
 * 用于 product show 页面
 * price table 多少数据以上显示 view all
 * trade table 多少数据以上显示 view all
 * @type {Number}
 */
export const productShowPriceSmallCount = 5;
export const productShowTradeSmallCount = 10;

export const abbre = {
  Victoria: "VIC",
  "South Australia": "SA",
  "New South Wales": "NSW",
  "Western Australia": "WA",
  Tasmania: "TAS",
  "Australian Capital Territory": "ACT",
  AFMA: "AFMA",
  "Northern Territory": "NT",
  Queensland: "QLD",
};

/**
 *
 * @param {String} location abbre
 */
export const locationAbbre = (location) => {
  return abbre[location] || "";
};

/**
 * @param {String} sell_type lease | assets
 * @return  Lease Price Exchange | Assets Exchange
 */

export const sellType = {
  lease: "Lease Price Exchange",
  assets: "Assets Exchange",
};

export const sellTypeDescription = {
  "lease-unencumbered": "Seasonal Quotas - Lease my quota",
  "lease-encumbered": "Seasonal Quotas - BeachPrice",
  assets: "Permanent Quotas - Assets",
};

/**
 * @param {String} link
 * @return links of bp
 */

export const getBpLinks = (key, redirect) => {
  let url = "";
  let query = [];
  if (redirect) {
    query.push(`redirect_url=${redirect}`);
  }
  switch (key) {
    case "login":
      url = `${getBpHost()}/login`;
      break;
    case "account/new":
      url = `${getBpHost()}/account/edit`;
      query.push("continue=2");
      break;
    case "account/edit":
      url = `${getBpHost()}/account/edit`;
      query.push("continue=2");
      break;
    case "account/email":
      url = `${getBpHost()}/account/email/edit`;
      break;
    case "account/password":
      url = `${getBpHost()}/account/password/edit`;
      break;
    case "account/bank":
      url = `${getBpHost()}/account/bank/edit`;
      break;
    default:
      return "";
  }
  !isEmpty(query) &&
    query.map((param) => {
      url += (url.indexOf("?") === -1 ? "?" : "&") + param;
    });
  return url;
};

/**
 * @return cart tab item list
 */
export const cartTabItemList = [
  { tabId: "tab1", label: "Open Buy Orders" },
  { tabId: "tab2", label: "Open Sell Orders" },
];

/**
 * @return cart update or delete response conflict
 */
export const cartResponse = {
  conflict: "conflict",
};

export const priceHidden = {
  login: "Price hidden",
  unlogin: "Login to view price",
};

// export const priceHidden = "Price Hidden";
export const finishedDays = 14;

/**
 * 特殊分类 (已废弃)
 * 1.详情页不显示fisherman
 * 2.分类页不同于其他
 * 3.不能创建lease offer
 * 4.dashboard页面，报价不显示倒计时
 * 4.1.dashboard页面，完成订单里，invoice按钮文字只显示“Invoice”
 * 5.invoice页面 不显示bank信息
 */
export const specialCategory = ["Coral Trout", "Lobster", "Abalone"];

/**
 * auction下的特殊分类
 */
export const specialCategoryForAuction = ["Coral Trout", "Spanner Crab"];

/**
 * 特殊分类下的 price 列表，默认条数
 * @type {Number}
 */
export const specialCategoryPriceCount = 3;

/**
 * 订单状态
 */
export const orderStateMap = {
  pending_payment: "pending_payment",
  pending_confirmation: "pending_confirmation",
  completed: "completed",
  closed: "closed",
};

export const orderClassMap = {
  Auction: "Auction",
  BidOfferPrice: "Exchange",
  P2pOffer: "P2P"
};

export const orderLinkMap = {
  sell: "/orders?type=sales",
  buy: "/orders?type=purchase",
};

export const withdrawStatus = {
  processing: "processing",
  completed: "completed",
  canceled: "canceled",
};

/**
 * order Gst Percent
 * @type {Number}
 */
export const orderGstPercent = 0.1;

/**
 * 订单 deposit 的限额
 * 超过此数，显示 deposit
 * 否则不显示
 */
export const orderDepositLimit = 1000;

/**
 * stripe 付款手续费
 * 0.3 固定费用0.3澳币
 * 1.87% (with gst)
 * stripe 最小支付金额 0.5
 * @type {Number}
 */
export const stripeFixedFee = 0.3;
export const stripeProcessFeeWithGstPercent = 0.0175;
export const stripePayMin = 0.5;

/**
 * 付款方式
 */
export const paymentMethodMap = {
  poli: "poli",
  stripe: "stripe",
  wallet: "wallet",
  offline: "offline",
};

export const paymentValueMap = {
  poli: "Payment::Poli",
  stripe: "Payment::Stripe",
  wallet: "Payment::Wallet",
  offline: "Payment::Offline",
};

/**
 * 付款结果页面
 * @type {Object}
 */
export const paymentUrl = {
  successful: "/payment/successful",
  failed: "/payment/failed",
  cancelled: "/payment/cancelled",
};

/**
 * 积分查询事件
 * @type {Object}
 * */
export const pointsEventNames = {
  signup: "signup",
  firstOrder: "first_order_created",
  secondOrder: "second_order_created",
  joinBid: "bid",
  inviteFriend: "invite_friend",
  createAuction: "create_auction",
  all: "all",
};

/**
 * 倒计时警告时间
 *
 */

export const timeClosed = 24 * 3600 * 1000;

export const listingStatusMap = {
  nothing: "",
  sold: "Sold",
  leased: "Leased",
  under_offer: "Under Offer",
  under_contract: "Under Contract",
};

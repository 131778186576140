import React from "react";
import PropTypes from "prop-types";

import { Input, Select } from "@components/formik";

const FirstStep = ({ states }) => {
  return (
    <div>
      <h2 className="mt-20 mb-12 text-2xl md:text-3xl text-center text-fish-dark font-bold leading-snug">
        About you
      </h2>
      <div className="md:grid gap-4 grid-cols-2">
        <div className="col-span-1">
          <Input label="First name" name="first_name" />
        </div>
        <div className="col-span-1">
          <Input label="Last name" name="last_name" />
        </div>
        <div className="col-span-2">
          <Input label="Email address" name="email" />
        </div>
        <div className="col-span-2">
          <Input label="Mobile" name="phone_number" />
        </div>
        <div className="col-span-2">
          <Select label="State" name="state" options={states} className="form-select-lg"/>
        </div>
      </div>
    </div>
  );
};

FirstStep.propTypes = {
  states: PropTypes.array,
};

export default FirstStep;

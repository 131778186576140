import React, { useContext } from "react";
import get from "lodash/get";
import compact from "lodash/compact";
import capitalize from "lodash/capitalize";

//Hooks
import { useMarketTrade, useModal, useResponsive } from "@hooks";
import {
  useMarketOffers,
  useMarketStatistics,
  useMarketBalance,
  useAccountOffers,
} from "../hook";

//Local component
import { Loading } from "@components";
import QuotaOffers from "./_offers";
import Transactions from "./_transactions";
import FishNetClientNumber from "./_fishnetClientNumber";
import Form from "./_form";
import Listings from "./myListings";
import { TradeModal } from "@views/common";

//Context
import { CurrentUserContext } from "@contexts";

const QuotaMarket = ({ data, image, id, fisheryId, loading }) => {
  const usecaseMarket = get(data, "market", {});
  const { isMobile } = useResponsive();
  const currentUserStatus = useContext(CurrentUserContext);
  const {
    usecase: offers,
    loading: offersLoading,
    fetchOfferList,
  } = useMarketOffers(id, usecaseMarket, currentUserStatus);
  const {
    transactions,
    data: statistics,
    loading: statisticsLoading,
    fetchMarketStatistics,
  } = useMarketStatistics(id, usecaseMarket);

  const {
    offerOrderStatus,
    trade,
    setAmount: setTradeAmount,
    submit: submitTrade,
    parseTradeFormData,
  } = useMarketTrade(id, "buyer", currentUserStatus, offers, {
    single: usecaseMarket,
  });

  const {
    show,
    loading: loadingBalance,
    fetchMarketBalance,
  } = useMarketBalance(id, usecaseMarket);

  const offersProps = useAccountOffers({ quota_market_id: id });
  const { isOpen, toggleModal } = useModal();
  const renderForm = () => {
    return (
      <Form
        statistics={statistics}
        usecaseMarket={usecaseMarket}
        onAmountChange={(val) => {
          setTradeAmount(val);
        }}
        data={trade}
        offers={offers.list}
        onSubmit={() => {
          toggleModal();
        }}
      />
    );
  };
  const title = compact([
    get(usecaseMarket, "location", ""),
    get(usecaseMarket, "specName", ""),
    get(usecaseMarket, "season.years", ""),
    capitalize(get(usecaseMarket, "marketType", "")),
  ]).join(" ");

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className="bg-white rounded-lg shadow mt-4 md:mt-10">
        <div className="bg-white px-3 py-2 border-b border-blue-gray-200 md:px-4 md:px-6 flex items-center rounded-t-lg">
          <div className="w-8 h-8 md:w-12 md:h-12 rounded-full flex-shrink-0 relative mr-5">
            <img className="h-full w-full" src={image} alt={title} />
            <svg
              className="absolute bottom-1 right-0"
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3 1.04583C11.503 1.10968 11.6803 1.23661 11.8062 1.40819C11.9321 1.57976 12 1.78703 12 1.99983V6.99983H16C16.1829 6.99975 16.3623 7.04984 16.5188 7.14466C16.6752 7.23947 16.8026 7.37537 16.8872 7.53758C16.9717 7.69978 17.0102 7.88207 16.9983 8.0646C16.9865 8.24713 16.9248 8.42292 16.82 8.57283L9.81999 18.5728C9.69816 18.7474 9.52382 18.8785 9.32233 18.9471C9.12084 19.0157 8.90271 19.0182 8.69968 18.9543C8.49666 18.8904 8.31932 18.7634 8.19348 18.5917C8.06764 18.42 7.99985 18.2127 7.99999 17.9998V12.9998H3.99999C3.81707 12.9999 3.63763 12.9498 3.4812 12.855C3.32478 12.7602 3.19735 12.6243 3.1128 12.4621C3.02824 12.2999 2.9898 12.1176 3.00164 11.9351C3.01349 11.7525 3.07518 11.5767 3.17999 11.4268L10.18 1.42683C10.302 1.25262 10.4764 1.12185 10.6778 1.05351C10.8792 0.985173 11.0972 0.982835 11.3 1.04683V1.04583Z"
                fill="#8434D6"
              />
            </svg>
          </div>
          <div className="flex-1 md:flex items-center">
            <div className="text-sm md:text-base leading-6 font-bold text-blue-gray-900 mr-4 capitalize">
              Instant Lease
            </div>
            <div className="text-xxs md:text-base text-blue-gray-500 font-medium">
              {title}
            </div>
          </div>
        </div>
        <div className="md:grid md:grid-cols-8 md:bg-gray-50 md:rounded-b-lg">
          <div className="col-span-5 bg-white md:rounded-bl-lg pb-6">
            {isMobile && <div className="px-4 pt-6">{renderForm()}</div>}
            <div className="px-4 md:px-6">
              <QuotaOffers
                data={offers.list}
                offers={offers}
                loading={offersLoading}
                fisheryId={fisheryId}
                marketId={id}
              />
            </div>
            <div className="px-4 md:px-6">
              <Transactions
                statistics={statistics}
                transactions={transactions}
                loading={statisticsLoading}
              />
            </div>
          </div>
          <div className="col-span-3">
            <div className="px-4 py-6 md:px-8 md:py-8 space-y-6">
              {!isMobile && renderForm()}
              <Listings
                balance={show}
                offers={offersProps.data}
                loading={offersProps.loading || loadingBalance}
                fisheryId={fisheryId}
                marketId={id}
                fetchOfferList={() => {
                  typeof fetchOfferList === "function" && fetchOfferList();
                  typeof offersProps.fetchOfferList === "function" &&
                    offersProps.fetchOfferList();
                }}
                fetchMarketBalance={fetchMarketBalance}
                usecaseMarket={usecaseMarket}
              />
              <FishNetClientNumber />
            </div>
          </div>
        </div>
      </div>
      <TradeModal
        isOpen={isOpen}
        title={usecaseMarket.title}
        subTitle={usecaseMarket.seasonStr}
        data={trade}
        toggle={toggleModal}
        loading={offerOrderStatus.loading}
        onSubmit={() => {
          submitTrade();
        }}
      />
    </>
  );
};

QuotaMarket.propTypes = {};

export default QuotaMarket;

import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class AccountApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "account";
  }

  /**
   * Account Api instance bids
   */
  bids(payload) {
    this.setApi(`${API}/${this.model}/bids`);
    return this.get(payload);
  }

  /**
   * Account Api instance offers
   */
  offers(payload) {
    this.setApi(`${API}/${this.model}/offers`);
    return this.get(payload);
  }

  /**
   * Account Api instance recend
   */
  recend(payload) {
    this.setApi(`${API}/orders/dashboard`);
    return this.get(payload);
  }

  /**
   * Account Api instance open orders
   */
  openOrders(payload) {
    this.setApi(`${API}/${this.model}/open_orders`);
    return this.get(payload);
  }

  /**
   * Account profile update event
   * @param {Object} payload {newRegister: true}
   */
  profileEvent(payload = {}) {
    this.setApi(
      `${API}/${
        payload.newRegister ? "fullfill_profile_events" : "profile_events"
      }`
    );
    return this.post({});
  }

  profileRegister(payload = {}) {
    this.setApi(`${API}/register_question`);
    return this.post(payload);
  }

  /**
   * Account permanent listing
   */
  permanentListing() {
    this.setApi(`${API}/${this.model}/permanent_listings`);
    return this.get();
  }

  /**
   * permanent show
   * @param {object} payload {id: 1}
   */
  permanentShow(payload) {
    this.setApi(`${API}/${this.model}/permanent_listings/${payload.id}`);
    return this.get();
  }

  /**
   * permanent update
   * @param {object} payload {id: 1}
   */
  permanentUpdate(payload) {
    this.setApi(`${API}/${this.model}/permanent_listings/${payload.id}`);
    return this.patch(omit(payload, "id"));
  }

  /**
   * permanent delete
   * @param {object} payload {id: 1}
   */
  permanentDestroy(payload) {
    this.setApi(`${API}/${this.model}/permanent_listings/${payload.id}`);
    return this.delete();
  }

  /**
   * Banks show
   */
  bankShow() {
    this.setApi(`${API}/bank`);
    return this.get();
  }
  /**
   * Banks edit
   * @param {Object} payload
   */
  bankEdit(payload) {
    this.setApi(`${API}/bank`);
    return this.put(payload);
  }

  /**
   * Statements listings
   */
  statementListing(payload) {
    this.setApi(`${API}/statements`);
    return this.get(payload);
  }

  /**
   * Quotum balances
   */
  quotumBalances() {
    this.setApi(`${API}/${this.model}/quotum_balances`);
    return this.get()
  }

  /**
   * Quotum inventories
   */
  quotumInventories(payload) {
    this.setApi(`${API}/${this.model}/quotum_inventories`);
    return this.get(payload) 
  }

  /**
   * Created auctions 
   */
  createdAuctions() {
    this.setApi(`${API}/${this.model}/auctions`);
    return this.get() 
  }

  /**
   * Involved auctions
   */
  involvedAuctions() {
    this.setApi(`${API}/${this.model}/involved_auctions`);
    return this.get() 
  }

  /**
   * notifications list
   * GET /api/v1/notifications
   */
  notificationList(payload) {
    this.setApi(`${API}/notifications`);
    return this.get(payload);
  }
}

import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import flatten from "lodash/flatten";
import cx from "classnames";
import PropTypes from "prop-types";
import { Dropdown } from "@components";

const Offers = ({ data, toggleModal, onDelete }) => {
  const _data = flatten(
    map(data, (item) =>
      map(get(item, "bid_offer_prices", []), (price) => price)
    )
  );

  const renderOperation = (item) => {
    const data = {
      id: item.id,
      price: item.price,
      quantity: item.quantity,
      bidOfferType: item.bidOfferType,
      marketId: item.marketId,
      quantityUnits: get(item, "market.isSaleByUnit", false)
        ? item.unitsStr
        : item.amountStr,
      title: get(item, "market.title", ""),
      unitName: get(item, "market.unitNameCondition", ""),
    };
    return (
      <div className="text-left">
        <div
          className="text-blue-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-blue-gray-900"
          onClick={(evt) => {
            evt.stopPropagation();
            toggleModal && toggleModal(data, "ask");
          }}
        >
          Edit
        </div>
        <div
          className="text-blue-gray-700 px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-blue-gray-900 hidden"
          onClick={() => {
            onDelete(data);
          }}
        >
          Delete
        </div>
      </div>
    );
  };

  const renderStatus = (item) => {
    const approved = get(item, "entity.approved", false);
    const status = get(item, "entity.quotum_transfer_apply.status", "") || "";
    const amountStr = get(item, "amountStr", "");
    const apply = get(item, "entity.quotum_transfer_apply", {}) || {};
    if (!approved) {
      if (status === "pending" || !status) {
        return (
          <button
            className="text-red-500 underline"
            onClick={(evt) => {
              evt.stopPropagation();
              const data = {
                offerId: item.id,
                quotum_transfer_apply: apply,
                amountStr: amountStr,
              };
              toggleModal && toggleModal(data, "apply");
            }}
          >
            Confirm quota transfer
          </button>
        );
      } else {
        return <span>Pending review</span>;
      }
    }

    return <span>Active</span>;
  };

  return (
    <ul className="">
      {map(_data, (item, index) => {
        const status =
          get(item, "entity.quotum_transfer_apply.status", "") || "";
        const approved = get(item, "entity.approved", false);
        return (
          <li
            className="py-2 flex items-center justify-between text-sm"
            key={index}
          >
            <div className="w-0 flex-1 flex items-center">
              <div
                className={cx("flex-1 w-0 truncate text-xxs", {
                  "font-medium":
                    !approved && (status === "pending" || !status),
                  "text-blue-gray-700": approved || (status && status !== "pending"),
                })}
              >
                {`${get(item, "priceWithCondition", "")} · ${get(
                  item,
                  "amountStr",
                  ""
                )} · `}
                {renderStatus(item)}
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <Dropdown
                button={
                  <svg
                    className="cursor-pointer"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33329 7.99998H3.33996H3.33329ZM7.99996 7.99998H8.00663H7.99996ZM12.6666 7.99998H12.6733H12.6666ZM3.99996 7.99998C3.99996 8.17679 3.92972 8.34636 3.8047 8.47138C3.67967 8.59641 3.5101 8.66665 3.33329 8.66665C3.15648 8.66665 2.98691 8.59641 2.86189 8.47138C2.73686 8.34636 2.66663 8.17679 2.66663 7.99998C2.66663 7.82317 2.73686 7.6536 2.86189 7.52858C2.98691 7.40355 3.15648 7.33331 3.33329 7.33331C3.5101 7.33331 3.67967 7.40355 3.8047 7.52858C3.92972 7.6536 3.99996 7.82317 3.99996 7.99998V7.99998ZM8.66663 7.99998C8.66663 8.17679 8.59639 8.34636 8.47136 8.47138C8.34634 8.59641 8.17677 8.66665 7.99996 8.66665C7.82315 8.66665 7.65358 8.59641 7.52855 8.47138C7.40353 8.34636 7.33329 8.17679 7.33329 7.99998C7.33329 7.82317 7.40353 7.6536 7.52855 7.52858C7.65358 7.40355 7.82315 7.33331 7.99996 7.33331C8.17677 7.33331 8.34634 7.40355 8.47136 7.52858C8.59639 7.6536 8.66663 7.82317 8.66663 7.99998V7.99998ZM13.3333 7.99998C13.3333 8.17679 13.2631 8.34636 13.138 8.47138C13.013 8.59641 12.8434 8.66665 12.6666 8.66665C12.4898 8.66665 12.3202 8.59641 12.1952 8.47138C12.0702 8.34636 12 8.17679 12 7.99998C12 7.82317 12.0702 7.6536 12.1952 7.52858C12.3202 7.40355 12.4898 7.33331 12.6666 7.33331C12.8434 7.33331 13.013 7.40355 13.138 7.52858C13.2631 7.6536 13.3333 7.82317 13.3333 7.99998V7.99998Z"
                      stroke="#677489"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                position="right"
                menuClassName="py-1"
              >
                {renderOperation(item)}
              </Dropdown>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

Offers.propTypes = {};

export default Offers;

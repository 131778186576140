export const ACCOUNT_BIDS = "account/bids";
export const ACCOUNT_BIDS_REQUEST = `${ACCOUNT_BIDS}_request`;
export const ACCOUNT_BIDS_SUCCESS = `${ACCOUNT_BIDS}_success`;
export const ACCOUNT_BIDS_FAILURE = `${ACCOUNT_BIDS}_failure`;

export const accountBidsAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_BIDS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ACCOUNT_BIDS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ACCOUNT_BIDS_FAILURE,
      error,
    }
  },
};

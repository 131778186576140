export const AUCTION_FINAL_CHANCES_LIST = "auction/final_chances_list";
export const AUCTION_FINAL_CHANCES_LIST_REQUEST = `${AUCTION_FINAL_CHANCES_LIST}_request`;
export const AUCTION_FINAL_CHANCES_LIST_SUCCESS = `${AUCTION_FINAL_CHANCES_LIST}_success`;
export const AUCTION_FINAL_CHANCES_LIST_FAILURE = `${AUCTION_FINAL_CHANCES_LIST}_failure`;
export const AUCTION_FINAL_CHANCES_LIST_UPDATE = `${AUCTION_FINAL_CHANCES_LIST}_update`;

export const auctionFinalChancesListAction = {
  request: (payload) => {
    return {
      type: AUCTION_FINAL_CHANCES_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: AUCTION_FINAL_CHANCES_LIST_SUCCESS,
      data,
    };
  },
  update: (data) => {
    return {
      type: AUCTION_FINAL_CHANCES_LIST_UPDATE,
      data,
    };
  },
  failure: (error) => {
    return {
      type: AUCTION_FINAL_CHANCES_LIST_FAILURE,
      error,
    };
  },
};

import { OfferApiRepo } from "@core";

export class OfferApiUsecase {
  constructor() {
    this.repo = new OfferApiRepo();
  }

  list(payload) {
    return this.repo.list(payload);
  }

  // show(payload) {
  //   return this.repo.show(payload);
  // }

  create(payload) {
    return this.repo.create(payload);
  }

  update(payload) {
    return this.repo.update(payload);
  }

  destroy(payload) {
    return this.repo.destroy(payload);
  }

  order(payload) {
    return this.repo.order(payload);
  }

  apply(payload) {
    return this.repo.apply(payload);
  }
  applyConfirm(payload) {
    return this.repo.applyConfirm(payload);
  }
}

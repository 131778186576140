import { useCallback, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AccountQuotumInventoryUsecase } from "@core";

import {
  quotumInventoriesAction,
  QUOTUM_INVENTORIES,
} from "@redux/modules_v2/account/quotumInventories/action";

/**
 * Fetch lists hooks
 */
export const useAccountQuotumInventories = (params) => {
  const dispatch = useDispatch();

  const fetchList = useCallback((params) => {
    dispatch(quotumInventoriesAction.request(params));
  }, []);

  const accountQuotumInventoriesStatus = useSelector((state) => {
    const selector = new LoadingSelector(QUOTUM_INVENTORIES, state);
    return Object.assign({}, get(state, "accountQuotumInventoriesStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = accountQuotumInventoriesStatus;
  const usecase = new AccountQuotumInventoryUsecase(data);

  useEffect(() => {
    fetchList(params);
  }, []);

  return {
    data: usecase.list,
    loading,
    fetchList,
  };
};

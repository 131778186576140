import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  PRODUCTS_FETCH_REQUEST,
  PRODUCTS_FETCH_LOADING,
  PRODUCTS_FETCH_SUCCESS,
  PRODUCTS_FETCH_FAILED,
} from "./listAction";

import {
  productsAPI,
} from "@config/api";


//fetch products list
export function* watchProductsFetch() {
  yield takeEvery(PRODUCTS_FETCH_REQUEST, productsFetch);
}

export function* productsFetch(action) {
  yield put({ type: PRODUCTS_FETCH_LOADING });
  const { err, res } = yield call(productsFetchApi);

  if (!res) {
    yield put({
      type: PRODUCTS_FETCH_FAILED,
      error: "Fetch products list failed."
    });
    return;
  }
  yield put({ type: PRODUCTS_FETCH_SUCCESS, data: res });
}

export function productsFetchApi() {
  return axios
    .get(productsAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";


import {
  productsRecentTradeAPI,
} from "@config/api";

import {
  PRODUCTS_RECENT_TRADE_FETCH_REQUEST,
  PRODUCTS_RECENT_TRADE_FETCH_LOADING,
  PRODUCTS_RECENT_TRADE_FETCH_SUCCESS,
  PRODUCTS_RECENT_TRADE_FETCH_FAILED,
} from "./recentTradeAction";


//fetch product recent trade
export function* watchProductRecentTradeFetch() {
  yield takeEvery(PRODUCTS_RECENT_TRADE_FETCH_REQUEST, productRecentTradeFetch);
}

export function* productRecentTradeFetch(action) {
  yield put({ type: PRODUCTS_RECENT_TRADE_FETCH_LOADING });

  const { res, err } = yield call(productRecentTradeFetchApi, action.payload);
  if (!res) {
    yield put({
      type: PRODUCTS_RECENT_TRADE_FETCH_FAILED,
      error: "Get product recent trade failed."
    });
    return;
  }
  yield put({ type: PRODUCTS_RECENT_TRADE_FETCH_SUCCESS, data: res });
}

function productRecentTradeFetchApi(payload) {
  return axios
    .get(productsRecentTradeAPI(get(payload,"id","")))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

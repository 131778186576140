export const LISTING_INDEX_LIST_TAB_CHANGE = "list_index/list_tab_change";

/**
 * LISTING列表页 Tab 切换
 * @param  {String} tabId
 * @return {Object}
 * {type: LISTING_INDEX_LIST_TAB_CHANGE, payload: { tabId: "South Australia|Victoria|''" }}
 */
export function listingIndexListTabChange(tabId) {
  return {
    type: LISTING_INDEX_LIST_TAB_CHANGE,
    payload: {
      tabId,
    },
  };
}
import { useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import Notice from "@utils/noticeNew";
import {
  permanentDeleteAction,
  PERMANENT_DELETE,
} from "@redux/modules_v2/account/permanentDelete/action";

/**
 * [hooks] permanent delete
 *
 * @param {Function} success 成功后的回调函数
 *
 * @example
 * 用法1:
 * const { deletePermanent } = usePermanentDelete(() => { some success code })
 * onClick={() => {
 *   deletePermanent(2)
 * }}
 *
 * 用法2:
 * const { deleteByData } = usePermanentDelete(() => { some success code })
 * onClick={() => {
 *   const data = { id: 2 }
 *   deleteByData(data)
 * }}
 */
export const useAccountPermanentDelete = (success) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * 删除 permanent
   */
  const deletePermanent = (id) => {
    const deleteData = {
      id,
    };

    dispatch(permanentDeleteAction.request(deleteData));
  };

  /**
   * 根据某数据，删除报价，需判断
   */
  const deleteByData = (data) => {
    if (data.id) {
      deletePermanent(data.id);
    }
  };

  /**
   * 监听删除
   */
  const permanentDeleteStatus = useSelector((state) => {
    const selector = new LoadingSelector(PERMANENT_DELETE, state);
    return Object.assign({}, get(state, "accountPermanentDeleteStatus", {}), {
      loading: selector.loading,
    });
  });


  useEffect(() => {
    if (permanentDeleteStatus.justFinished) {
      if (!permanentDeleteStatus.loading && !permanentDeleteStatus.error) {
        Notice.success("You have successfully delete a quota unit!");
        success && success();
      }
      if (!permanentDeleteStatus.loading && permanentDeleteStatus.error) {
        Notice.failure(permanentDeleteStatus.error || "Delete Failed");
      }
      dispatch(permanentDeleteAction.received());
    }
  }, [permanentDeleteStatus.justFinished]);

  return {
    deletePermanent,
    deleteByData,
    loading: permanentDeleteStatus.loading,
  };
};

import {
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_LOADING,
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_REQUEST
} from './action'

function changeEmailStatus(state = {}, action = {}) {
  switch (action.type) {
    case CHANGE_EMAIL_LOADING:
      return { loading: true, data: null, error: null }
    case CHANGE_EMAIL_SUCCESS:
      return { loading: false, data: action.data, error: null }
    case CHANGE_EMAIL_FAILED:
      return { loading: false, data: null, error: action.error }
    default:
      return state
  }
}

export { changeEmailStatus }
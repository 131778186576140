import React from "react";

import { Alert, TimeCountDown } from "@components";

/**
 * [Component] payment page notice component
 */
const Notice = (props) => {
  const { order } = props;

  const renderTimeCountDown = () => {
    return (
      <TimeCountDown
        date={order.expiredAtOrigin}
        theme="danger"
        themeClosed="danger"
        onComplete={() => {
          window && window.location.reload();
        }}
        inline={true}
      />
    );
  };

  if (order.isClosed || (order.isExpired && !order.isFinished)) {
    return (
      <Alert
        theme="danger"
        content="This order has been cancelled as you have not completed the payment with the time specified."
      />
    );
  }

  if (order.isCompleted) {
    return null;
  }

  if (order.useHoldingFee) {
    return (
      <Alert
        theme="danger"
        content={
          <div className="">
            <span>Make full payment in</span> {renderTimeCountDown()}{" "}
            <span>to complete your order.</span>
          </div>
        }
      />
    );
  }

  if (order.depositPaid) {
    return (
      <Alert
        theme="danger"
        content={
          <div className="">
            <span>Pay outstanding balance within</span> {renderTimeCountDown()}
          </div>
        }
      />
    );
  }

  return (
    <Alert
      theme="danger"
      content={
        <div className="">
          Your order is not yet complete.
        </div>
      }
    />
  );
};

export default Notice;

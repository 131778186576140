const UPDATE_PROFILE_EVENT_REQUEST = "update_profile_event/request"
const UPDATE_PROFILE_EVENT_LOADING = "update_profile_event/loading"
const UPDATE_PROFILE_EVENT_SUCCESS = "update_profile_event/success"
const UPDATE_PROFILE_EVENT_FAILED = "update_profile_event/failed"

function updateProfileEventRequest(payload) {
  return {
    type: UPDATE_PROFILE_EVENT_REQUEST,
    payload
  }
}

function updateProfileEventLoading() {
  return {
    type: UPDATE_PROFILE_EVENT_LOADING
  }
}

function updateProfileEventSuccess(data) {
  return {
    type: UPDATE_PROFILE_EVENT_SUCCESS,
    data
  }
}

function updateProfileEventFailed(error) {
  return {
    type: UPDATE_PROFILE_EVENT_FAILED,
    error
  }
}

export {
  UPDATE_PROFILE_EVENT_REQUEST,
  UPDATE_PROFILE_EVENT_FAILED,
  UPDATE_PROFILE_EVENT_LOADING,
  UPDATE_PROFILE_EVENT_SUCCESS,

  updateProfileEventRequest,
  updateProfileEventLoading,
  updateProfileEventSuccess,
  updateProfileEventFailed
}
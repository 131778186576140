import { takeLatest, call, put } from "redux-saga/effects";

import { OfferApiUsecase } from "@core";
import { OFFER_CREATE_REQUEST, offerCreateAction } from "./action";

// create offer with market(lease)
export function* watchMarketOfferCreatePost() {
  yield takeLatest(OFFER_CREATE_REQUEST, offerCreatePost);
}

function* offerCreatePost(action) {
  try {
    const usecase = new OfferApiUsecase();
    const res = yield call([usecase, "create"], action.payload);
    yield put(offerCreateAction.success(res.data));
  } catch (err) {
    yield put(offerCreateAction.failure(err));
  }
}

import {
  WALLET_WITHDRAWAL_LOADING,
  WALLET_WITHDRAWAL_SUCCESS,
  WALLET_WITHDRAWAL_FAILED,
  WALLET_WITHDRAWAL_RECEIVED,
} from "./withdrawalAction";

export function walletWithdrawalStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_WITHDRAWAL_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_WITHDRAWAL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      });

    case WALLET_WITHDRAWAL_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: {},
        error: action.error,
        justFinished: true,
      });

    case WALLET_WITHDRAWAL_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: {},
  error: null,
  justFinished: false,
};

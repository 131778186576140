// Initialize FullStory Config

// process.env.RAILS_ENV == "production" ? "FZV24" : "MP5K7";

const CONFIG = {
  "production": {
    "org_code": "FZV24",
  },
  "staging": {
    "org_code": "MP5K7",
  },
  "development": {
    "org_code": "",
  },
};

export function fullStoryConfig() {
  return CONFIG[process.env.REACT_APP_BUILD_ENV] || {};
}

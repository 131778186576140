import {
  get,
  isEmpty,
  find,
  map,
  sumBy,
  concat,
  first,
} from "lodash";
import { Moment, orderNewTime, CaculatedFee } from "@core";
import {
  orderStateMap,
  paymentValueMap,
} from "@config/enum";
import numeral from "@utils/numeral";
import { OrderNewEntity } from "./orderNewEntity";
import { OrderOldEntity } from "./orderOldEntity";
import { PermanentMarketableEntity } from "./permanentMarketableEntity";
import { PaymentEntity } from "./paymentEntity";
/**
 * [Entity] Order
 * data is obj
  {
    "id": 185,
    "boxes_count": 4,
    "created_at": "2020-03-24T18:08:51.343+11:00",
    "updated_at": "2020-03-24T18:08:51.343+11:00",
    "class": "BidOfferPrice",
    "origin_id": null,
    "sell_type": null,
    "title": null,
    "fishing_season": null,
    "serial_number": "202003248238",
    "point_consumed": 0,
    "unit": null,
    "unit_name": null,
    "unit_weight": null,
    "state": null,
    "paid_state": "pending_payment",
    "pay_off?": true|false,
    "close_reason": null,
    "expired_at": "2020-03-24T18:38:51.364+11:00",
    "url": null,
    "price": {
        "humanized_money": 12.5,
        "humanized_money_with_symbol": "$12.50"
    },
    "invoice": "not_received",
    "marketable_type": "QuotaMarket",
    "buyer": {
        "id": 38,
        "email": "vickie+48@gfresh.io",
        "picture": null,
        "name": "vk wa",
        "company_name": "Gfresh",
        "is_deposit": false,
        "remember_account": true,
        "is_me": true
    },
    "seller": {
        "id": 34,
        "email": "vickie+12@gfresh.io",
        "picture": null,
        "name": "vi wa",
        "company_name": "Gfresh",
        "is_deposit": false,
        "remember_account": false,
        "is_me": false
    },
    "order_type": "buy",
    "sub_total_price": {
        "humanized_money": 15423,
        "humanized_money_with_symbol": "$15,423.00"
    },
    "sub_total_price_with_gst": {
        "humanized_money": 16965.3,
        "humanized_money_with_symbol": "$16,965.30"
    },
    "total_including_gst_price": {
        "humanized_money": 16965.3,
        "humanized_money_with_symbol": "$16,965.30"
    },
    "total_price": {
        "humanized_money": 16965.3,
        "humanized_money_with_symbol": "$16,965.30"
    },
    "real_price": {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
    },
    "gst": {
        "humanized_money": 1542.3,
        "humanized_money_with_symbol": "$1,542.30"
    },
    "transfer_fee": {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
    },
    "fees": {
        "title": "$0.1/kg",
        "value": {
            "humanized_money": 0,
            "humanized_money_with_symbol": "$0.00"
        },
        "value_with_gst": {
            "humanized_money": 0,
            "humanized_money_with_symbol": "$0.00"
        },
    },
    "how_much_paid": {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
    },
    "how_much_payment_left": {
        "humanized_money": 5123.52,
        "humanized_money_with_symbol": "$5,123.52"
    },
    "net_pay": {
        "humanized_money": 5123.52,
        "humanized_money_with_symbol": "$5,123.52"
    },
    "net_pay_lines": {
      "card_processing_fee_cents": 188,
      "quota_price_cents": "111111.00",
      "services_fee_with_gst_cents": 0
    }
  }


  旧订单数据
  {
    "id": 187,
    "boxes_count": 2.0,
    "created_at": "2020-03-24T23:01:19.844+11:00",
    "updated_at": "2020-03-24T23:03:10.575+11:00",
    "class": "AskingPrice"|"OfferPrice"|"Auction",
    "origin_id": 22,
    "sell_type": "Lease Price Exchange"|"Assets Exchange"|"lease"|"assets",
    "title": null,
    "fishing_season": null,
    "serial_number": "202003249974",
    "point_consumed": 0,
    "unit": "1000.0 kg",
    "unit_name": "Unit",
    "unit_weight": 1.0,
    "state": "Queensland",
    "paid_state": "completed",
    "close_reason": null,
    "expired_at": "2020-03-24T23:03:20.087+11:00",
    "payment":
    {
      "paid_amount":
      {
        "humanized_money": 2.69,
        "humanized_money_with_symbol": "$2.69"
      },
      "transaction_amount":
      {
        "humanized_money": 2.64,
        "humanized_money_with_symbol": "$2.64"
      },
      "transaction_fees":
      {
        "humanized_money": 0.05,
        "humanized_money_with_symbol": "$0.05"
      },
      "transaction_original_fees":
      {
        "humanized_money": 0.05,
        "humanized_money_with_symbol": "$0.05"
      },
      "transaction_gst_fees":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "payment_method_type": "Payment::Stripe"
    },
    "completed_payment":
    {
      "paid_amount":
      {
        "humanized_money": 2.64,
        "humanized_money_with_symbol": "$2.64"
      },
      "transaction_amount":
      {
        "humanized_money": 2.64,
        "humanized_money_with_symbol": "$2.64"
      },
      "transaction_fees":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "transaction_original_fees":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "transaction_gst_fees":
      {
        "humanized_money": 0,
        "humanized_money_with_symbol": "$0.00"
      },
      "payment_method_type": "Payment::Wallet"
    },
    "url": "/i/-/AskingPrice/22",
    "price":
    {
      "humanized_money": 1.2,
      "humanized_money_with_symbol": "$1.20"
    },
    "invoice": "received",
    "product_grade":
    {
      "id": 4,
      "name": "N/A",
      "product_type": "Coral Trout",
      "percent": "-55.56%",
      "is_exchange": true,
      "unit_weight": 1.0,
      "unit_name": "Unit",
      "product":
      {
        "id": 3,
        "state": "Queensland",
        "unit_name": "Unit",
        "unit_suffix": "kg",
        "fishing_season": null,
        "show_unit": true,
        "sell_type": "Lease Price Exchange",
        "quota_name": "QLD Coral Trout",
        "category":
        {
          "id": 5,
          "name": "Coral Trout"
        }
      }
    },
    "marketable_type": null,
    "buyer":
    {
      "id": 1,
      "email": "1069888988@qq.com",
      "picture": "https://gfresh-dev.s3.amazonaws.com/avatars/a507e01e-2ad5-40f5-b076-e3e704cbb80b.jpg",
      "name": "Lucien Wang",
      "company_name": "Gfresh",
      "is_deposit": false,
      "remember_account": true,
      "is_me": true
    },
    "seller":
    {
      "id": 34,
      "email": "vickie+12@gfresh.io",
      "picture": null,
      "name": "vi wa",
      "company_name": "Gfresh",
      "is_deposit": false,
      "remember_account": false,
      "is_me": false
    },
    "order_type": "buy",
    "sub_total_price":
    {
      "humanized_money": 2.4,
      "humanized_money_with_symbol": "$2.40"
    },
    "sub_total_price_with_gst":
    {
      "humanized_money": 2.64,
      "humanized_money_with_symbol": "$2.64"
    },
    "total_including_gst_price":
    {
      "humanized_money": 2.69,
      "humanized_money_with_symbol": "$2.69"
    },
    "total_price":
    {
      "humanized_money": 2.64,
      "humanized_money_with_symbol": "$2.64"
    },
    "real_price":
    {
      "humanized_money": 0,
      "humanized_money_with_symbol": "$0.00"
    },
    "gst":
    {
      "humanized_money": 0.24,
      "humanized_money_with_symbol": "$0.24"
    },
    "transfer_fee":
    {
      "humanized_money": 0,
      "humanized_money_with_symbol": "$0.00"
    },
    "fees":
    {
      "title": "Total service fee (Incl. GST)",
      "value": 0,
      "value_with_gst": 0.0
    }
  }
 */
export class OrderEntity {
  constructor(data, currentUserId) {
    this.entity = data;
    this.currentUserId = currentUserId;
    this.order = generateOrder(data);
  }

  /**
   * 获取钱包标题
   * @param {String} sheetTitle
   * @param {String} type "debit" ｜ "credit"
   */
  walletTitle(sheetTitle, type = "") {
    // 如果 type 是 debit，则是由订单进账
    // 否则 为空
    if (type == "debit") {
      return "Quota Sold";
    }
    return "";
  }

  showFees(sheetTitle = "") {
    return false;
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get serialNumber() {
    return get(this.entity, "serial_number", "");
  }

  get licenceNumber() {
    return get(this.entity, "licence_number", "");
  }

  get orderId() {
    return get(this.entity, "id", "");
  }

  get orderSerialNumber() {
    return get(this.entity, "serial_number", "");
  }

  get originClass() {
    return get(this.entity, "class", ""); // "Auction" | "AskingPrice" ｜ "OfferPrice" | "BidOfferPrice" | "P2pOffer"
  }

  get isAuctionOrder() {
    return this.originClass === "Auction";
  }

  get isP2POrder() {
    return this.originClass === "P2pOffer"
  }
  
  get items() {
    return get(this.entity, "items", []);
  }
  
  get itemsFormated() {
    return this.order.itemsFormated || [];
  }

  get boxesCount() {
    return get(this.entity, "boxes_count", 0);
  }

  get units() {
    return this.order.units;
  }

  get unitsStr() {
    return this.order.unitsStr;
  }

  get amount() {
    return this.order.amount;
  }

  get amountStr() {
    return this.order.amountStr;
  }

  get isLease() {
    return this.order.isLease;
  }

  get isPermanent() {
    return this.order.isPermanent;
  }

  get price() {
    return get(this.entity, "price.humanized_money", 0);
  }

  get priceStr() {
    return get(this.entity, "price.humanized_money_with_symbol", "");
  }

  get priceStrWithKg() {
    return get(this.entity, "price", "");
  }

  get subTotal() {
    return get(this.entity, "sub_total_price.humanized_money", 0);
  }

  get subTotalStr() {
    return get(this.entity, "sub_total_price.humanized_money_with_symbol", "");
  }

  get subTotalWithGst() {
    return get(this.entity, "sub_total_price_with_gst.humanized_money", 0);
  }

  get subTotalWithGstStr() {
    return get(
      this.entity,
      "sub_total_price_with_gst.humanized_money_with_symbol",
      ""
    );
  }

  get gst() {
    return get(this.entity, "gst.humanized_money", 0);
  }

  get gstStr() {
    return get(this.entity, "gst.humanized_money_with_symbol", 0);
  }

  get totalPay() {
    // return get(this.entity, "total_including_gst_price.humanized_money", 0);
    // return get(this.entity, "total_price.humanized_money", 0);
    return get(this.entity, "net_pay.humanized_money", 0);
  }

  get totalPayStr() {
    // return get(this.entity, "total_including_gst_price.humanized_money_with_symbol", "");
    // return get(this.entity, "total_price.humanized_money_with_symbol", "");
    if(this.isSeller) {
      return get(this.entity, "seller_price.humanized_money_with_symbol", "");
    }
    if(this.isBuyer) {
      return get(this.entity, "net_pay.humanized_money_with_symbol", "");
    }
    return "";
  }

  get serviceFeeTitle() {
    return get(this.entity, "fees.title", "");
  }

  get serviceFee() {
    return get(this.entity, "fees.value.humanized_money", 0);
  }

  get serviceFeeStr() {
    return numeral(this.serviceFee).format();
  }
 
  get serviceFeeAbsStr() {
    return numeral(Math.abs(this.serviceFee)).format();
  }

  get serviceFeeGst() {
    return numeral(get(this.entity, "fees.value.humanized_money", 0))
      .multiply(0.1)
      .value();
  }

  get serviceFeeGstStr() {
    return numeral(this.serviceFeeGst).format();
  }

  get serviceFeeGstAbsStr() {
    return numeral(Math.abs(this.serviceFeeGst)).format(); 
  }

  get serviceFeeWithGst() {
    return get(this.entity, "fees.value_with_gst.humanized_money", 0);
  }

  get serviceFeeWithGstStr() {
    return numeral(this.serviceFeeWithGst).format();
  }

  get serviceFeeWithGstAbs() {
    return numeral(Math.abs(this.serviceFeeWithGst)).value();
  }

  get serviceFeeWithGstAbsStr() {
    return numeral(Math.abs(this.serviceFeeWithGst)).format();
  }

  get orderType() {
    return get(this.entity, "order_type", ""); // "buy"|"sell"
  }

  get isIn() {
    return this.orderType === "buy";
  }

  get isOut() {
    return this.orderType === "sell";
  }

  get buyer() {
    return get(this.entity, "buyer") || {};
  }

  get seller() {
    return get(this.entity, "seller") || {};
  }

  get isBuyer() {
    const userId = get(this.buyer, "cognito_id", -1);
    if(!this.currentUserId) {
      return false
    }
    return userId === this.currentUserId;
  }
 
  get isSeller() {
    const userId = get(this.seller, "cognito_id", -1);
    if(!this.currentUserId) {
      return false
    }
    return userId === this.currentUserId;
  }

  get invoiceState() {
    return get(this.entity, "invoice", ""); // not_received|received|not_sent|sent
  }

  get paidState() {
    return get(this.entity, "paid_state", ""); // pending_payment|pending_confirmation|completed|closed
  }

  get payOff() {
    return get(this.entity, "pay_off?", false);
  }

  get howMuchPaid() {
    return get(this.entity, "how_much_paid.humanized_money");
  }

  get howMuchPaidStr() {
    return get(this.entity, "how_much_paid.humanized_money_with_symbol", "");
  }

  get depositPaid() {
    return this.howMuchPaid > 0;
  }

  get howMuchLeft() {
    return get(this.entity, "how_much_payment_left.humanized_money");
  }

  get howMuchLeftStr() {
    return get(
      this.entity,
      "how_much_payment_left.humanized_money_with_symbol",
      ""
    );
  }

  get defaultExpirationDur() {
    return get(this.entity, "default_expiration_dur", 0); // 秒
  }

  get depositExpirationDur() {
    return get(this.entity, "deposit_expiration_dur", 0); // 秒
  }

  get depositPercentage() {
    return Number(get(this.entity, "deposit_percentage")) || 0;
  }

  get depositPercentageStr() {
    return numeral(this.depositPercentage).format("0.00%");
  }

  get useHoldingFee() {
    return (
      this.completedHoldFeePayments.length > 0 ||
      this.refundedHoldFeePayments.length > 0
    );
  }

  get paidHoldFee() {
    return this.completedHoldFeePayments.length > 0;
  }

  get holdingFeePaymentMethod() {
    const payComplete = first(this.completedHoldFeePayments);
    const payRefund = first(this.refundedHoldFeePayments);
    if (payComplete) {
      return payComplete.payment_method_type;
    }
    if (payRefund) {
      return payRefund.payment_method_type;
    }
    return "";
  }

  get completedHoldFeePayments() {
    return get(this.entity, "completed_hold_fee_payments", []);
  }

  get refundedHoldFeePayments() {
    return get(this.entity, "refunded_hold_fee_payments", []);
  }

  get holdingExpirationDur() {
    return get(this.entity, "holding_expiration_dur", 0); // 秒
  }

  get holdingFeePercentage() {
    return Number(get(this.entity, "holding_fee_percentage")) || 0;
  }

  get holdingFeePercentageStr() {
    return numeral(this.holdingFeePercentage).format("0.00%");
  }

  get holdingFee() {
    // 这里是用于计算出来的
    // const baseTotal = numeral(this.subTotalWithGst)
    //   .add(this.serviceFeeWithGst)
    //   .value();
    // const result = numberCeil(
    //   numeral(baseTotal).multiply(this.holdingFeePercentage).value(),
    //   2
    // );
    // return numeral(result).value();

    // 这里是取后端的值
    if (this.completedHoldFeePayments.length > 0) {
      return numeral(
        get(first(this.completedHoldFeePayments), "transaction_amount", 0)
      ).value();
    }
    if (this.refundedHoldFeePayments.length > 0) {
      return numeral(
        get(first(this.refundedHoldFeePayments), "transaction_amount", 0)
      ).value();
    }
    return 0;
  }

  get holdingFeeStr() {
    return numeral(this.holdingFee).format();
  }

  get holdingCardProceessingFee() {
    const payments = concat(
      [],
      this.completedHoldFeePayments,
      this.refundedHoldFeePayments
    );

    const isStripe = find(payments, (o) => {
      return paymentValueMap.stripe === o.payment_method_type;
    });

    if (isStripe) {
      return CaculatedFee.stripe(this.holdingFee);
    } 
    return 0;
  }

  get holdingCardProceessingFeeStr() {
    return numeral(this.holdingCardProceessingFee).format();
  }

  get holdingWithProcessingFee() {
    return this.holdingFee + this.holdingCardProceessingFee;
  }

  get holdingWithProcessingFeeStr() {
    return numeral(this.holdingWithProcessingFee).format();
  }

  get closeReason() {
    return get(this.entity, "close_reason", "");
  }

  get createAt() {
    return new Moment(get(this.entity, "created_at"));
  }

  get createAtStr() {
    return this.createAt.format || "";
  }

  get expiredAtOrigin() {
    return get(this.entity, "expired_at", "");
  }

  get expiredAt() {
    return new Moment(get(this.entity, "expired_at"));
  }

  get expiredAtStr() {
    return this.expiredAt.format || "";
  }

  get isExpired() {
    return this.expiredAt.isExpired;
  }

  get isFinished() {
    return this.isCompleted || this.isClosed;
  }

  get isCompleted() {
    return this.paidState === orderStateMap.completed;
  }

  get isClosed() {
    return this.paidState === orderStateMap.closed;
  }

  get isNew() {
    const time = new Moment(this.createAt.dateTime.add(orderNewTime, "h"));
    return !time.isExpired;
  }

  get pendingPayInfo() {
    if (!isEmpty(get(this.entity, "payments"))) {
      const payment = find(get(this.entity, "payments"), (item) => {
        return item[2] === "pending" && item[1] === paymentValueMap.stripe;
      });
      if (payment) {
        return {
          id: payment[0],
          method_type: payment[1],
          state: payment[2],
        };
      }
    }
    return null;
  }

  get title() {
    return this.order.title;
  }

  get titleUrl() {
    return this.order.titleUrl;
  }

  get url() {
    return `/orders/${this.id}`;
  }

  get paymentUrl() {
    return `/orders/${this.id}/payment`;
  }

  get paymentConfirmUrl() {
    const pay = this.pendingPayInfo;
    if (pay && pay.id && pay.method_type === paymentValueMap.stripe) {
      return `/payments/stripes/${pay.id}/complete?created_by=order`;
    }
    return "";
  }

  get invoiceUrl() {
    return `/dashboard/invoice/${this.id}`;
  }

  get invoiceFeeUrl() {
    return `/dashboard/fee_invoice/${this.id}`;
  }

  get completedNotWaletPayments() {
    const payments = get(this.entity, "completed_not_wallet_payments") || [];
    return map(payments, (payment) => {
      return new PaymentEntity(payment);
    });
  }

  get cardProcessFeeSum() {
    return sumBy(this.completedNotWaletPayments, (o) => o.transactionFees);
  }

  get cardProcessFeeSumStr() {
    return numeral(this.cardProcessFeeSum).format();
  }

  /**
   * [GETTER] 获取permanent marketable的usecase
   * @return usecase
   */
  get permanentMarkets() {
    if (!this.isPermanent) {
      return {};
    }
    const marketable = get(this.entity, "marketable", {});
    return new PermanentMarketableEntity(marketable);
  }
}

const generateOrder = (data) => {
  const order = data || {};
  switch (order.class) {
    case "AskingPrice":
    case "OfferPrice":
    case "Auction":
      return new OrderOldEntity(order);
      break;

    case "BidOfferPrice":
    default:
      return new OrderNewEntity(order);
      break;
  }
};

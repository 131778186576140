import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import capitalize from "lodash/capitalize";

import {
  cartPricesAPI,
} from "@config/api";

import {
  ASKING_PRICES_SHOW_FETCH_REQUEST,
  ASKING_PRICES_SHOW_FETCH_LOADING,
  ASKING_PRICES_SHOW_FETCH_SUCCESS,
  ASKING_PRICES_SHOW_FETCH_FAILED
} from "./showAction";


//fetch asking price show
export function* watchAskingPriceShowFetch() {
  yield takeEvery(ASKING_PRICES_SHOW_FETCH_REQUEST, askingPriceShowFetch);
}

export function* askingPriceShowFetch(action) {
  yield put({ type: ASKING_PRICES_SHOW_FETCH_LOADING });

  const { res, err } = yield call(askingPriceShowFetchApi, action.payload);
  if (!res) {
    yield put({
      type: ASKING_PRICES_SHOW_FETCH_FAILED,
      error: "Get asking price data failed."
    });
    return;
  }
  yield put({ type: ASKING_PRICES_SHOW_FETCH_SUCCESS, data: res });
}

function askingPriceShowFetchApi(payload) {
  return axios
    .get(cartPricesAPI('sell', payload.id))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}
import slugify from "slugify";

export default function urlGenerator(url = "", opts = {}) {
  const options = Object.assign({}, {
    replacement: '-',    // replace spaces with replacement
    remove: /[*+~.()'"!:@]/g,        // regex to remove characters
    lower: false          // result in lower case
  }, opts);
  return slugify(url, options);
}


import {
  ACCOUNT_PROFILE_REGISTER_SUCCESS,
  ACCOUNT_PROFILE_REGISTER_FAILURE, 
  ACCOUNT_PROFILE_REGISTER_RESET,
} from "./action";

export const accountProfileRegisterStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ACCOUNT_PROFILE_REGISTER_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case ACCOUNT_PROFILE_REGISTER_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case ACCOUNT_PROFILE_REGISTER_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

import { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import mapKeys from "lodash/mapKeys";
import toNumber from "lodash/toNumber";
import lowerCase from "lodash/lowerCase";
import Notice from "@utils/noticeNew";

//Core Method
import { LoadingSelector, AuctionSellType } from "@core";
import numeral from "@utils/numeral";
import {
  listingOfferNewAction,
  LISTING_OFFER_NEW,
} from "@redux/modules_v2/listing/new/action";
import {
  auctionNewAction,
  AUCTION_NEW,
} from "@redux/modules_v2/auctions/new/action";
import { p2pNewAction, P2P_NEW } from "@redux/modules_v2/p2p/new/action";

//Hooks
import { useParams, useDeepEffect, useQuotamarketOptions } from "@hooks";

export const useNewSell = ({ toggleConfirmModal }) => {
  /**
   * [Callback] 获取now
   */
  const getNow = useCallback(() => {
    var d = new Date();
    d.setSeconds(0, 0);
    return d;
  }, []);
  /**
   * initial values
   */
  const defaultInitialValues = {
    //share
    fishery_id: "", //fishery id
    market_type: "", //lease | permanent
    price: "", //price
    quantity: "", //quantity
    sell_type: "", //auction | exchange
    quota_market_id: "", //quota market id
    title:"",
    //exchange
    quotum_transfer_apply_attributes: {
      transfer_entity_name: "",
      quota_transfer_ids: [],
    },
    confirm_quotum_transfer: false,
    //auction
    reserve_price: "",
    fixed_price: "",
    agree_reserve_price: false,
    description: "",
    expire_at: getNow(),
    unit: "",
    fishing_type: "Unencumbered",
    gov_fee: 0,
    show_company_info: false,
    price_displaying: "visible",
    agree: false,
    additional_information: {},
    //tools only
    market: {}, //quota market usecase
    sales_types: "", //fishery sales types exchange | auction | both
  };

  /**
   * Schema
   */
  const FormSchema = ({
    highestBuyerBids,
    sellType,
    showTermsAndConditions,
    step,
  }) => {
    let obj = {};
    switch (step) {
      case 1:
        obj = {
          market_type: Yup.string().required("This field is required"),
          quota_market_id: Yup.string().required("This field is required"),
          sell_type: Yup.string().required("This field is required"),
        };
        break;
      case 2:
        if (sellType === "exchange") {
          obj = {
            price: Yup.number()
              .moreThan(highestBuyerBids, (obj) => {
                const value = get(obj, "more", 0) || 0;
                return `At this price, you can lease out instantly from a buyer at ${numeral(
                  value
                ).format()}`;
              })
              .required("This field is required"),
            quantity: Yup.string().required("This field is required"),
          };
          break;
        }
        if (sellType === "auction") {
          obj = {
            price: Yup.number().required("This field is required"),
            reserve_price: Yup.number().when("price", {
              is: (val) => !!val,
              then: Yup.number().moreThan(
                Yup.ref("price"),
                "Reserve price must be more than starting price"
              ),
            }),
            unit: Yup.string().required("This field is required"),
          };
          break;
        }
        obj = {
          price: Yup.number()
            .moreThan(highestBuyerBids, (obj) => {
              const value = get(obj, "more", 0) || 0;
              return `At this price, you can lease out instantly from a buyer at ${numeral(
                value
              ).format()}`;
            })
            .required("This field is required"),
          quantity: Yup.string().required("This field is required"),
          description: Yup.string().required("This field is required"),
        };
        break;
      case 3:
        if (sellType === "auction") {
          obj = showTermsAndConditions
            ? {
                agree: Yup.boolean().oneOf(
                  [true],
                  "This field must be checked"
                ),
                expire_at_date: Yup.string().required("This field is required"),
                expire_at_time: Yup.string().required("This field is required"),
              }
            : {
                expire_at_date: Yup.string().required("This field is required"),
                expire_at_time: Yup.string().required("This field is required"),
              };
        }
        obj = {};
        break;
      default:
        obj = {};
    }
    return Yup.object().shape(obj);
  };

  const [params] = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const formikRef = useRef();
  const [step, setStep] = useState(1);
  const [quotaMarketId, setQuotaMarketId] = useState("");
  const [auctionTitle, setAuctionTitle] = useState("");
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [highestBuyerBids, setHighestBuyerBids] = useState(0);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [sellType, setSellType] = useState("");
  const [schema, setSchema] = useState(() =>
    FormSchema({ sellType, highestBuyerBids, step })
  );
  const values = get(formikRef, "current.values", {});
  const {
    getCurrentMarket,
    options,
    loading: quotaMarketLoading,
  } = useQuotamarketOptions({
    fisheryId: toNumber(get(params, "fishery_id", "")),
    marketType: get(params, "market_type", ""),
  });

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback(
    (values, actions) => {
      const sellType = get(values, "sell_type", "");
      const maxStep = sellType === "exchange" ? 2 : 3;
      if (step < maxStep) {
        setStep(step + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
        return;
      }
      setQuotaMarketId(get(values, "quota_market_id"), "");
      if (step === maxStep) {
        if (sellType === "exchange") {
          createExchangeOffer(values, actions);
          return;
        }
        if (sellType === "p2p") {
          createP2p(values);
          return;
        }
        createAuction(values);
        return;
      }

      if (sellType === "exchange") {
        const url = quotaMarketId
          ? `/account/quotum_balances/${quotaMarketId}`
          : "/listings";
        history && history.push(url);
      }
    },
    [step, quotaMarketId]
  );

  /**
   * [Callback] create offer request
   */
  const createExchangeOffer = useCallback((values, actions) => {
    let data = pick(values, [
      "fishery_id",
      "market_type",
      "price",
      "quantity",
      "quota_market_id",
      "quotum_transfer_apply_attributes",
      "sell_type",
    ]);
    dispatch(listingOfferNewAction.request(data));
  }, []);

  /**
   * [Callback] create offer request
   */
  const createAuction = useCallback((values) => {
    const reservePrice = get(values, "reserve_price", "");
    const fixedPrice = get(values, "fixed_price", "");
    const marketType = get(values, "market_type", "");
    const title = get(values, "title", "");
    const values2 = Object.assign({}, values);
    const hour = values2.expire_at_time.getHours();
    const min = values2.expire_at_time.getMinutes();
    const expire_at = values2.expire_at_date;
    expire_at.setHours(hour, min);
    values.expire_at = expire_at;
    delete values.expire_at_time;
    delete values.expire_at_date;
    let data = pick(values, [
      "description",
      "expire_at",
      "fishery_id",
      "fishing_type",
      "gov_fee",
      "price",
      "price_displaying",
      "quota_market_id",
      "show_company_info",
      "unit",
      "additional_information",
    ]);
    if (reservePrice) {
      data = Object.assign({}, data, {
        reserve_price: reservePrice,
      });
    }
    if (fixedPrice) {
      data = Object.assign({}, data, {
        fixed_price: fixedPrice,
      });
    }
    if(title) {
      data = Object.assign({}, data, {
        title
      })
    }
    dispatch(
      auctionNewAction.request({
        auction: Object.assign({}, data, {
          sell_type: AuctionSellType[marketType],
        }),
      })
    );
  }, []);

  /**
   * [Callback] create p2p
   */
  const createP2p = useCallback((values) => {
    const fishing_type = lowerCase(get(values, "fishing_type"));
    const title = get(values, "title", "");
    let data = pick(values, [
      "quota_market_id",
      "description",
      "quantity",
      "price",
    ]);
    if(title) {
      data = Object.assign({}, data, {
        title
      })
    }
    dispatch(p2pNewAction.request(Object.assign({}, data, { fishing_type })));
  }, []);

  //listing new status
  const listingOfferNewStatus = useSelector((state) => {
    const selector = new LoadingSelector(LISTING_OFFER_NEW, state);
    return Object.assign({}, get(state, "listingOfferNewStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * Auction new status
   */
  const auctionNewStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_NEW, state);
    return Object.assign({}, get(state, "auctionNewStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * P2P new status
   */
  const p2pNewStatus = useSelector((state) => {
    const selector = new LoadingSelector(P2P_NEW, state);
    return Object.assign({}, get(state, "p2pNewStatus", {}), {
      loading: selector.loading,
    });
  });

  //Set sell step
  useEffect(() => {
    if (get(values, "sell_type")) {
      setSellType(get(values, "sell_type"));
    }
  }, [get(values, "sell_type")]);

  //Set highest buyer bids
  useDeepEffect(() => {
    if (!isEmpty(get(values, "market"))) {
      const highestBuyerBids =
        get(values, "market.highestBuyerBidValue", 0) || 0;
      const showTermsAndConditions = get(
        values,
        "market.market.showTermsAndConditions",
        false
      );
      setHighestBuyerBids(highestBuyerBids);
      setShowTermsAndConditions(showTermsAndConditions);
    }
  }, [get(values, "market")]);

  //Set schema
  useEffect(() => {
    setSchema(
      FormSchema({
        sellType,
        highestBuyerBids,
        step,
        showTermsAndConditions,
      })
    );
  }, [sellType, highestBuyerBids, step, showTermsAndConditions]);

  /**
   * param 默认值对initial value 初始化
   */
  useDeepEffect(() => {
    const step = toNumber(get(params, "step", ""));
    const fishery_id = toNumber(get(params, "fishery_id", ""));
    const market_type = get(params, "market_type", "");
    const sell_type = get(params, "sell_type", "");
    const quota_market_id = toNumber(get(params, "quota_market_id", ""));
    const market = getCurrentMarket(quota_market_id);
    const highestBuyerBids = get(market, "highestBuyerBidValue", 0) || 0;
    step && setStep(step);
    setHighestBuyerBids(highestBuyerBids);
    let values = {};
    mapKeys(
      { market_type, fishery_id, sell_type, quota_market_id, market },
      (value, key) => {
        if (value) values = Object.assign({}, values, { [key]: value });
      }
    );
    if (!isEmpty(values))
      setInitialValues(Object.assign({}, initialValues, values));
  }, [params, initialValues, options]);

  /**
   * 创建listing 后的effect
   */
  useEffect(() => {
    return () => {
      dispatch(listingOfferNewAction.reset());
      dispatch(auctionNewAction.reset());
      dispatch(p2pNewAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!listingOfferNewStatus.loading) {
      if (listingOfferNewStatus.data) {
        // Notice.success("Your listing was created");
        // setTimeout(() => {
        //   const url = quotaMarketId
        //     ? `/account/quotum_balances/${quotaMarketId}`
        //     : "/listings";
        //   history && history.push(url);
        // }, 600);
        setStep(step + 1);
      }
      if (listingOfferNewStatus.error) {
        Notice.failure(listingOfferNewStatus.error);
      }
    }
  }, [listingOfferNewStatus.loading]);

  useEffect(() => {
    if (!auctionNewStatus.loading) {
      if (auctionNewStatus.data) {
        typeof toggleConfirmModal === "function" && toggleConfirmModal();
      }
      if (auctionNewStatus.error) {
        Notice.failure(auctionNewStatus.error);
      }
    }
  }, [auctionNewStatus.loading]);

  useEffect(() => {
    if (!p2pNewStatus.loading) {
      if (p2pNewStatus.data) {
        typeof toggleConfirmModal === "function" && toggleConfirmModal();
      }
      if (p2pNewStatus.error) {
        Notice.failure(p2pNewStatus.error);
      }
    }
  }, [p2pNewStatus.loading]);

  return {
    auctionNewStatus,
    auctionTitle,
    formikRef,
    initialValues,
    listingOfferNewStatus,
    loading:
      listingOfferNewStatus.loading ||
      auctionNewStatus.loading ||
      p2pNewStatus.loading,
    onSubmit,
    quotaMarketLoading,
    schema,
    sellType,
    setAuctionTitle,
    setStep,
    step,
  };
};

import {currentUser} from "./session"
import {userPool} from "./config"

/**
 * Sign out the current user
 */
function signOut() {
  if (!userPool) { return Promise.reject('No userPool') }

  const user = currentUser()
  if (!user) { return Promise.resolve() }

  return new Promise((resolve, reject) => {
    user.signOut()
    resolve()
  })
}

export {signOut}

import React from "react";
import PropTypes from "prop-types";
import { Image, Link } from "@components";
import { links } from "@config/staticLink";

const Header = (props) => {
  return (
    <div className="px-6 md:px-8">
      <nav className="relative flex items-center justify-between h-16 sm:h-20 md:justify-center z-20">
        <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
          <Link to={links.home} className="flex items-center">
            <Image source="logo"  className="h-4 w-16 md:h-8 w-32" />
          </Link>
        </div>
      </nav>
    </div>
  );
};

Header.propTypes = {};

export default Header;

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import get from "lodash/get";
import {
  CATEGORIES_PRODUCT_GRADES_FETCH_REQUEST,
  CATEGORIES_PRODUCT_GRADES_FETCH_LOADING,
  CATEGORIES_PRODUCT_GRADES_FETCH_SUCCESS,
  CATEGORIES_PRODUCT_GRADES_FETCH_FAILED
} from "./gradeAction";

import { categoriesProductGradesAPI } from "@config/api";

//fetch category product grades
export function* watchCategoryProductGradesFetch() {
  yield takeEvery(
    CATEGORIES_PRODUCT_GRADES_FETCH_REQUEST,
    categoryProductGradesFetch
  );
}

export function* categoryProductGradesFetch(action) {
  yield put({ type: CATEGORIES_PRODUCT_GRADES_FETCH_LOADING });

  const { err, res } = yield call(categoryProductGradesApi, action.payload);
  if (!res) {
    yield put({
      type: CATEGORIES_PRODUCT_GRADES_FETCH_FAILED,
      error: "Get category product grades data failed."
    });
    return;
  }
  yield put({ type: CATEGORIES_PRODUCT_GRADES_FETCH_SUCCESS, data: res });
}

export function categoryProductGradesApi(payload) {
  return axios
    .get(categoriesProductGradesAPI(get(payload, "id", "")))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

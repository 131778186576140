import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

/**
 * Local components
 */
import { Button } from "@components";
import { Form, Select, Input, formatNumber } from "@components/formik";
import Header from "@views/account/setting/_header";

// {
//   email: "",
//   picture: "",
//   user_name: "",
//   first_name: "",
//   last_name: "",
//   user_type: [],
//   address: "",
//   state: "",
//   country: "",
//   post_code: "",
//   phone_country_code: "",
//   mobile_number: "",
//   company_name: "",
//   industry: [],
//   abn: "",
//   licence_number: "",
//   need_gst: false
// }

const ProfileForm = ({
  countries,
  initialValues,
  onSubmit,
  schema,
  states,
  staticData,
  updateLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <Header
          title="Contact Details"
          sub="Your contact information. It won't be shared publicly."
          className="mb-6"
        />
        <div className="md:grid md:grid-cols-6 md:gap-4">
          <div className="md:col-span-3">
            <Input
              name="first_name"
              label="First Name"
              placeholder="First Name"
            />
          </div>
          <div className="mt-4-tw md:mt-0 md:col-span-3">
            <Input name="last_name" label="Last Name" placeholder="Last Name" />
          </div>
          <div className="mt-4-tw md:mt-0 md:col-span-6">
            <Input name="address" label="Street" placeholder="Address" />
          </div>
          <div className="mt-4-tw md:mt-0 md:col-span-2">
            <Select name="state" label={staticData.state} options={states} />
          </div>
          <div className="mt-4-tw md:mt-0 md:col-span-2">
            <Select
              name="country"
              label={staticData.country}
              options={countries}
            />
          </div>
          <div className="mt-4-tw md:mt-0 md:col-span-2">
            <Input
              name="post_code"
              label={staticData.post_code}
              placeholder="Post Code"
            />
          </div>
          <div className="mt-4-tw md:mt-0 md:col-span-6">
            <Input
              leftSymbol="+61"
              name="phone_number"
              label={staticData.mobile_number}
              parse={formatNumber}
              placeholder="Mobile Number"
            />
          </div>
        </div>
        <Header
          title="Company Information"
          sub="For generating tax invoices. It won't be shared publicly."
          className="mt-10 mb-6"
        />
        <Input
          name="company_name"
          label={staticData.company_name}
          placeholder="Company name"
        />
        <Input
          name="abn"
          label="ABN"
          placeholder="Australian Business Number"
          parse={formatNumber}
          classNameWrapper="mt-4-tw"
        />
        <Input
          classNameWrapper="mt-4-tw hidden"
          name="licence_number"
          label={staticData.licence_number}
          placeholder="Licence Number"
        />
        <div className="py-8">
          <Button
            size="xl"
            color="fish-primary"
            textColor="white"
            className="hover:bg-fish-primary-700"
            type="submit"
            disabled={updateLoading}
            loading={updateLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

ProfileForm.propTypes = {
  categories: PropTypes.array,
  countries: PropTypes.array,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  schema: PropTypes.object,
  states: PropTypes.array,
  staticData: PropTypes.object,
  updateLoading: PropTypes.bool,
};

ProfileForm.defaultProps = {
  categories: [],
  countries: [],
  initialValues: {},
  onSubmit: () => {},
  schema: {},
  states: [],
  staticData: {},
  updateLoading: false,
};

export default ProfileForm;

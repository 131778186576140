import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_BANKS_UPDATE_REQUEST, accountBankUpdateAction } from "./action";

// update bank info
export function* watchAccountBankUpdate() {
  yield takeLatest(ACCOUNT_BANKS_UPDATE_REQUEST, bankUpdate);
}

function* bankUpdate(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "bankEdit"], action.payload);
    yield put(accountBankUpdateAction.success(res.data));
  } catch (err) {
    yield put(accountBankUpdateAction.failure(err));
  }
}

import React from "react";

/**
 * import local utils & config & hooks
 */
import { useWalletEntries, useWalletBalance, useUserProfile } from "@hooks";

/**
 * import local component
 */
import { Breadcrumb } from "@components";
import Layout from "@views/layout";
import Balance from "./_balance";
import Entries from "./_entries";
import get from "lodash/get";
import Sidebar from "@views/account/sidebar";

/**
 * [Page] Account Wallet
 */
const Wallet = (props) => {
  const { balanceStr, loading: loadingBalance } = useWalletBalance();
  const {
    data,
    usecase: usecaseWalletEntries,
    loading: loadingEntries,
    fetchWalletEntries,
    setFilter,
    setPage,
  } = useWalletEntries();

  const wallets = usecaseWalletEntries.list;

  const { data: profile, loading: loadingProfile } = useUserProfile();
  const isDeposit = get(profile, "user.is_deposit", false);

  return (
    <Layout name="account">
     <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar className="hidden" current="wallet" />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 mt-6 lg:mt-0 mb-8 lg:mb-16">
            <Breadcrumb className="px-6 md:px-0 mb-6 md:mb-8" back />
            <h2 className="px-6 md:px-0">
              My Wallet
            </h2>
            <div className="mt-8 md:mt-10 px-2 md:px-0">
              <div className="rounded-lg">
                <Balance
                  profile={profile}
                  data={{
                    balance: balanceStr,
                    loading: loadingBalance,
                  }}
                />
              </div>
              {isDeposit && (
                <div className="mt-4 md:mt-6 bg-white rounded-lg shadow">
                  <div className="py-6 px-4 md:px-6 flex justify-between items-center">
                    <div className="text-base md:text-lg font-medium leading-tight">
                      Auction Deposit
                    </div>
                    <div className="text-base md:text-xl font-bold leading-tight text-blue-gray-900">
                      $250.00
                    </div>
                  </div>
                </div>
              )}
              <div className="bg-white rounded-lg shadow mt-4 md:mt-6">
                <Entries
                  data={data}
                  list={wallets}
                  loading={loadingEntries}
                  fetchWalletEntries={fetchWalletEntries}
                  setFilter={setFilter}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wallet;

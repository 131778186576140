import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import {
  closeOrderFailed,
  closeOrderLoading,
  closeOrderSuccess,
  ORDERS_CLOSE_REQUEST
} from "./closeAction";
import {closeOrderAPI} from "@config/api";

function* watchCloseOrder() {
  yield takeEvery(ORDERS_CLOSE_REQUEST, closeOrder);
}

function* closeOrder(action) {
  yield put(closeOrderLoading());

  try {
    const res  = yield call(axios.post, closeOrderAPI(action.payload.id), {});
    yield put(closeOrderSuccess(res));
  } catch (err) {
    yield put(closeOrderFailed(err));
  }
}

export { watchCloseOrder }
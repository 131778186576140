import axios from 'axios';
import { takeLatest, takeEvery, call, put, delay } from 'redux-saga/effects';

import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import {
  LISTING_LIST_FETCH_REQUEST,
  LISTING_LIST_FETCH_SUCCESS,
  LISTING_LIST_FETCH_LOADING,
  LISTING_LIST_FETCH_FAILED
} from './listAction';

import { listingsAPI } from '@config/api';

//fetch listings list
export function* watchListingsFetch() {
  yield takeLatest(LISTING_LIST_FETCH_REQUEST, listingsFetch);
}

export function* listingsFetch(action) {
  yield put({ type: LISTING_LIST_FETCH_LOADING });

  const { err, res } = yield call(listingsFetchApi, action.payload);
  if (!err) {
    yield put({ type: LISTING_LIST_FETCH_SUCCESS, data: res });
    return;
  }
  yield put({
    type: LISTING_LIST_FETCH_FAILED,
    error: 'Get listings list failed.'
  });
}

export function listingsFetchApi(payload = {}) {
  let api = listingsAPI();

  return axios
    .get(api, {params: payload})
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import React from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";

/**
 * import lib tools
 */
import classnames from "classnames";
import { Loading } from "@components";

const WaitingRule = ({ auctionWaitRuling }) => {
  return (
    <Transition in={auctionWaitRuling} timeout={400} unmountOnExit={true}>
      {(state) => {
        return (
          <div
            className={classnames(
              "modal-backdrop flex flex-col justify-center items-center fade",
              {
                show: state === "entered" || state === "entering",
              }
            )}
            tabIndex="-1"
          >
            <Loading color="text-white" size="lg" />
            <div className="text-lg text-white font-semibold text-center mt-3 px-3">
              One moment. We are synchronising any bids in the final few
              seconds. This will depend on your network speed.
            </div>
          </div>
        );
      }}
    </Transition>
  );
};

WaitingRule.propTypes = {
  auctionWaitRuling: PropTypes.bool,
};

WaitingRule.defaultProps = {
  auctionWaitRuling: false,
};

export default React.memo(WaitingRule, (prevProps, nextProps) => {
  return prevProps.auctionWaitRuling === nextProps.auctionWaitRuling;
});

export const INVOICE_CREATE = "invoices/create";
export const INVOICE_CREATE_REQUEST = `${INVOICE_CREATE}_request`;
export const INVOICE_CREATE_SUCCESS = `${INVOICE_CREATE}_success`;
export const INVOICE_CREATE_FAILURE = `${INVOICE_CREATE}_failure`;
export const INVOICE_CREATE_RECEIVED = `${INVOICE_CREATE}_received`;

export const invoiceCreateAction = {
  request: payload => {
    return {
      type: INVOICE_CREATE_REQUEST,
      payload,
    };
  },
  success: data => {
    return {
      type: INVOICE_CREATE_SUCCESS,
      data,
    };
  },
  failure: error => {
    return {
      type: INVOICE_CREATE_FAILURE,
      error,
    };
  },
  received: () => {
    return {
      type: INVOICE_CREATE_RECEIVED,
    };
  },
};

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Form as FormCmp } from 'formik'

export const Form = ({
  children,
  className,
  ...rest
}) => (
  <FormCmp
    {...rest}
    className={cx('react-formik-ui form-wrapper', className)}
  >
    {children}
  </FormCmp>
)

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  /** Adds a custom class to the form element */
  className: PropTypes.string,
}

Form.defaultProps = {
  className: null,
}

export default memo(Form)

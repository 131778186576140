import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

//Hooks
import { useAccountQuotumBalances, useAccountCreatedAuctions, useParams } from "@hooks";

//Local components
import { Breadcrumb } from "@components";
import Layout from "@views/layout";
import Sidebar from "@views/account/sidebar";
import Auctions from "./auctions";
import Exchange from "./exchange";

const Listings = () => {
  const exchangeProps = useAccountQuotumBalances();
  const auctionsProps = useAccountCreatedAuctions();
  const [params] = useParams("");
  const type = get(params, "type", "");
  return (
    <Layout name="account">
      <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar
          className="hidden"
          current="listings"
        />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 px-4 lg:px-0 mt-6 lg:mt-0 mb-8 lg:mb-16">
            <Breadcrumb className="mb-6 md:mb-8" back />
            <div className="text-2xl lg:hidden font-bold leading-tight text-blue-gray-900 mb-6">
              My Listings
            </div>
            {type !== "exchange" && (
              <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow mb-4 lg:mb-10">
                <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div className="ml-4 mt-2">
                      <h3>
                        My Auction Listings
                      </h3>
                    </div>
                  </div>
                </div>
                <Auctions
                  emptyTitle="You have no auction listings yet"
                  emptyContent=""
                  {...auctionsProps}
                />
              </div>
            )}
            {type !== 'auction' && (
              <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow">
                <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div className="ml-4 mt-2">
                      <h3>
                        My Exchange Listings
                      </h3>
                    </div>
                  </div>
                </div>
                <Exchange
                  emptyTitle="You have no exchange listings yet"
                  emptyContent=""
                  {...exchangeProps}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Listings.propTypes = {
  props: PropTypes.object,
};

export default Listings;

import { takeLatest, call, put } from "redux-saga/effects";

import { InvoiceApiUsecase } from "@core";
import { INVOICE_SHOW_REQUEST, invoiceShowAction } from "./action";

// invoice show
export function* watchInvoiceShowFetchV2() {
  yield takeLatest(INVOICE_SHOW_REQUEST, invoiceShowFetch);
}

function* invoiceShowFetch(action) {
  try {
    const usecase = new InvoiceApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(invoiceShowAction.success(res.data));
  } catch (err) {
    yield put(invoiceShowAction.failure(err));
  }
}

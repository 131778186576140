import React, {
  createContext,
  useContext,
} from "react";
import PropTypes from "prop-types";


/**
 * Example
 * const defaultTab = "tab1";
 * const { activeTab, setActiveTab } = useState(defaultTab);
 * return (<>
 *           <ul>
 *             <li><button onClick={()=>{setActiveTab("tab1")}}>Tab1</button></li>
 *             <li><button onClick={()=>{setActiveTab("tab2")}}>Tab2</button></li>
 *          </ul>
 *          <TabContent activeTab={activeTab}>
 *            <TabPanel tabId="tab1">aaa</TabPanel>
 *            <TabPanel tabId="tab1">bbb</TabPanel>
 *           </TabContent>
 *          </>)
 */
const TabsState = createContext();

export const TabContent = ({ activeTab, className, children }) => {
  const state = activeTab || "";
  return (
    <div className={className}>
      <TabsState.Provider value={state}>{children}</TabsState.Provider>
    </div>
  );
};

TabContent.propTypes = {
  activeTab: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

TabContent.defaultProps = {
  activeTab: "",
  className: "",
};


export const usePanelState = (tabId) => {
  const activeTab = useContext(TabsState);
  const isActive = tabId === activeTab;
  return isActive;
};

export const TabPanel = ({ tabId, children }) => {
  const isActive = usePanelState(tabId);
  return isActive ? children : null;
};

export const STATMENT_LIST = "statements";
export const STATMENT_LIST_REQUEST = `${STATMENT_LIST}_request`;
export const STATMENT_LIST_SUCCESS = `${STATMENT_LIST}_success`;
export const STATMENT_LIST_FAILURE = `${STATMENT_LIST}_failure`;
export const STATMENT_LIST_UPDATE = `${STATMENT_LIST}_update`;

export const statementListAction = {
  request: (payload) => {
    return {
      type: STATMENT_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: STATMENT_LIST_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: STATMENT_LIST_FAILURE,
      error,
    };
  },
  update: (payload) => {
    return {
      type: STATMENT_LIST_UPDATE,
      payload,
    };
  },
};

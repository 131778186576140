import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  CHARTS_LAST_SOLD_REQUEST,
  CHARTS_LAST_SOLD_LOADING,
  CHARTS_LAST_SOLD_SUCCESS,
  CHARTS_LAST_SOLD_FAILED,
} from "./lastSoldAction";

import {
  chartsLastSoldAPI,
} from "@config/api";


//fetch charts for last sold price
export function* watchChartsLastSoldFetch() {
  yield takeEvery(CHARTS_LAST_SOLD_REQUEST, chartsLastSoldFetch);
}

export function* chartsLastSoldFetch(action) {
  yield put({ type: CHARTS_LAST_SOLD_LOADING });
  const { err, res } = yield call(chartsLastSoldApi, action.id, action.time);

  if (!res) {
    yield put({
      type: CHARTS_LAST_SOLD_FAILED,
      error: "Fetch charts for last sold price failed."
    });
    return;
  }
  yield put({ type: CHARTS_LAST_SOLD_SUCCESS, data: res });
}

export function chartsLastSoldApi(id, time) {
  return axios
    .get(chartsLastSoldAPI(id, time))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

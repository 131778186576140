import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { OrderApiUsecase } from "@core";
import { ORDERS_QUOTA_TRANSFERS_NEW_REQUEST, ordersQuotaTransfersNewAction } from "./action";

export function* watchOrdersQuotaTransfersNew() {
  yield takeLatest(ORDERS_QUOTA_TRANSFERS_NEW_REQUEST, ordersQuotaTransfersNew);
}

function* ordersQuotaTransfersNew(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "quotaTransfers"], action.payload);
    const data = get(res, "data", {});
    yield put(ordersQuotaTransfersNewAction.success(data));
  } catch (err) {
    yield put(ordersQuotaTransfersNewAction.failure(errorString(err)));
  }
}

import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_FOLLOWING_QUOTA_MARKETS_REQUEST, fisheryFollowingQuotaMarketsAction } from "./action";

// fetch following fishery quota markets list
export function* watchFisheryFollowingQuotaMarketsFetch() {
  yield takeLatest(FISHERY_FOLLOWING_QUOTA_MARKETS_REQUEST, fisheryFollowingQuotaMarketsFetch);
}

function* fisheryFollowingQuotaMarketsFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "followingQuotaMarkets"], action.payload);
    yield put(fisheryFollowingQuotaMarketsAction.success(res.data));
  } catch (err) {
    yield put(fisheryFollowingQuotaMarketsAction.failure(err));
  }
}

import { takeLatest, call, put } from "redux-saga/effects";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_MARKETS_REQUEST, permanentMarketsAction } from "./action";

// fetch permanent list
export function* watchPermanentMarketsFetch() {
  yield takeLatest(PERMANENT_MARKETS_REQUEST, permanentMarketsFetch);
}

function* permanentMarketsFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "markets"], action.payload);
    yield put(permanentMarketsAction.success(res.data));
  } catch (err) {
    yield put(permanentMarketsAction.failure(err));
  }
}

import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setWsConnectingStatus,
  addWsConnectingStatus,
  delWsConnectingStatus
} from "../../redux/modules/wsConnecting/action";
import { timestampFetch } from "../../redux/modules/timeStamp/action";
import { createACConsumer } from "../../redux/modules/actioncable/action";

const createSubscription = (props, channelStr) => {
  const {
    cable,
    channel,
    onReceived,
    onInitialized,
    onConnected,
    onDisconnected,
    onRejected
  } = props;

  return cable.subscriptions.create(channel, {
    received: function(data) {
      onReceived && onReceived(data);
    },
    initialized: function() {
      onInitialized && onInitialized();
    },
    connected: function() {
      props.timestampFetch();
      props.setWsConnectingStatus(channelStr, true);
      onConnected && onConnected();
    },
    disconnected: function() {
      props.setWsConnectingStatus(channelStr, false);
      onDisconnected && onDisconnected();
    },
    rejected: function() {
      onRejected && onRejected();
    }
  });
};

const removeSubscription = (cable, subscription) => {
  subscription.unsubscribe && subscription.unsubscribe();
  cable.subscriptions.remove(subscription);
};

const ActionCableWrapper = forwardRef((props, ref) => {
  const {
    cable,
    getSubscription,
    setSubscription,
    currentUserStatus,
    channel,
    dispatch
  } = props;

  const {
    isLoading: userStatusLoading,
    user: { token: userToken }
  } = currentUserStatus;

  let subscription = getSubscription();
  const channelStr = Object.keys(channel)
    .map(k => channel[k])
    .join("_");

  useEffect(() => {
    if (userStatusLoading) {
      return;
    }

    if (!cable && userToken) {
      props.createACConsumer(userToken || "");
      return;
    }

    if (cable && !subscription) {
      subscription = createSubscription(props, channelStr);
      setSubscription(subscription);
    }

    return () => {
      if (cable && subscription) {
        removeSubscription(cable, subscription);
      }
    };
  }, [cable, userStatusLoading, channelStr]);

  // We need to wrap component in `forwardRef` in order to gain
  // access to the ref object that is assigned using the `ref` prop.
  // This ref is passed as the second parameter to the function component.
  if (ref && Object.keys(ref).length > 0) {
    useImperativeHandle(ref, () => ({
      send: data => {
        subscription && subscription.send(data);
      },
      perform: (action, data) => {
        subscription && subscription.perform(action, data);
      }
    }));
  }

  //Add connecting status for the channel when the component did mount, remove it when unmount;
  useEffect(() => {
    props.addWsConnectingStatus(channelStr);
    return () => {
      props.delWsConnectingStatus(channelStr);
    };
  }, [channelStr]);

  return props.children || null;
});

const mapStateToProps = ({ currentCable: { cable }, currentUserStatus }) => ({
  cable,
  currentUserStatus
});

const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    bindActionCreators(
      {
        setWsConnectingStatus,
        addWsConnectingStatus,
        delWsConnectingStatus,
        timestampFetch,
        createACConsumer
      },
      dispatch
    ),
    {
      dispatch
    }
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(ActionCableWrapper);

import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { AuctionsApiUsecase } from "@core";
import { AUCTION_INSTANT_BUY_REQUEST, auctionInstantBuyAction } from "./action";

// Auction instant buy
export function* watchAuctionInstantBuy() {
  yield takeLatest(AUCTION_INSTANT_BUY_REQUEST, auctionInstantBuy);
}

function* auctionInstantBuy(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "createInstantBuy"], action.payload);
    const data = get(res, "data", {});
    yield put(auctionInstantBuyAction.success(data));
  } catch (err) {
    yield put(auctionInstantBuyAction.failure(errorString(err)));
  }
}

import React from "react";

/**
 * import lib tools & components
 */
import { useResponsive } from "@hooks";
import classnames from "classnames";
import { Action } from "@components";

/**
 * [Component] Order show page licence
 *
 * @example
 *
  <Licence
    order={usecaseOrder.single} // 一个 order entity 实例
    history={history}
  >
 *
 */
const Licence = (props) => {
  const { order, toggleModal } = props;

  return (
    <React.Fragment>
      {order.isCompleted && (
        <Action
          className="mb-4 md:mb-6"
          theme="green"
          subTitle="You've successfully paid for your quota and nominated your fishing license. The seller will transfer your quota to your nominated fishing license shortly. You will receive an email/text notification once the quota transfer is complete"
        />
      )}
      <div className="flex justify-between bg-white border-1 border-blue-gray-200 rounded-lg px-4 py-5 md:px-8 items-center shadow-sm">
        <h4>Lessee fishing licence number</h4>
        <div className="flex">
          {order.licenceNumber && (
            <p className="text-base font-semibold">{order.licenceNumber}</p>
          )}
          {order.isCompleted && (
            <button
              className="font-medium cursor-pointer text-fish-primary hover:text-fish-primary-700 ml-4"
              onClick={() => {
                toggleModal();
              }}
            >
              {order.licenceNumber ? "Edit" : "Add your fishing license number"}
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Licence;

import { WALLET_BALANCE_LOADING, WALLET_BALANCE_SUCCESS, WALLET_BALANCE_FAILED } from "./balanceAction";

export function walletBalanceStatus(state = initStatus, action = {}) {
  switch (action.type) {
    case WALLET_BALANCE_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });

    case WALLET_BALANCE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        error: null,
      });

    case WALLET_BALANCE_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: {},
        error: action.error,
      });

    default:
      return state;
  }
}

const initStatus = {
  loading: false,
  data: {},
  error: null,
};


import { AuctionBidEntity, UserEntity } from "@core";
import { isArray } from "lodash";

/**
 * Auction bid show usecase
 * @param {Object} props bid
 * @param {Object} user currentUserStatus
 */
export class AuctionBidShowUsecase {
  constructor(props, user) {
    this.data = props;
    this.userData = user;
  }

  get show() {
    if (isArray(this.data)) {
      return {};
    }
    const usecase = new AuctionBidEntity(this.data);
    const user = this.user;
    return {
      id: usecase.id,
      name: usecase.name,
      priceStr: usecase.priceStr(),
      createdAtStr: usecase.createdAtStr,
      isMe: usecase.isMe(user.currentUserId),
    };
  }

  get user() {
    return new UserEntity(this.userData);
  }
}

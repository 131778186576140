import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import {
  LIVE_STATES_FETCH_REQUEST,
  LIVE_STATES_FETCH_LOADING,
  LIVE_STATES_FETCH_SUCCESS,
  LIVE_STATES_FETCH_FAILED,

} from "./liveStatesAction";

import {
  liveStatesAPI,
} from "@config/api";


//fetch live states data
export function* watchLiveStatesFetch() {
  yield takeEvery(LIVE_STATES_FETCH_REQUEST, liveStatesFetch);
}

export function* liveStatesFetch(action) {
  yield put({ type: LIVE_STATES_FETCH_LOADING });
  const { err, res } = yield call(liveStatesFetchApi, action.payload);
  if (err && !res) {
    yield put({
      type: LIVE_STATES_FETCH_FAILED,
      error: "Get live states data failed."
    });
    return;
  }
  yield put({ type: LIVE_STATES_FETCH_SUCCESS, data: res });
}

export function liveStatesFetchApi(payload) {
  return axios
    .get(liveStatesAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

export const PERMANENT_BID_LIST = "permanent/bid_list";
export const PERMANENT_BID_LIST_REQUEST = `${PERMANENT_BID_LIST}_request`;
export const PERMANENT_BID_LIST_SUCCESS = `${PERMANENT_BID_LIST}_success`;
export const PERMANENT_BID_LIST_FAILURE = `${PERMANENT_BID_LIST}_failure`;

export const permanentBidListAction = {
  request: (payload) => {
    return {
      type: PERMANENT_BID_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_BID_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_BID_LIST_FAILURE,
      error,
    }
  },
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

//Common Components
import Layout from "@views/layout";
import { Loading, Breadcrumb, SEO } from "@components";

//Local Components
import AuctionShow from "./detail";
import AuctionForm from "./form";
import Line from "./detail/_line";
import Alert from "../alert";

//Hooks
import {
  useAuctionBidList,
  useAuctionBidAllList,
  useCurrentUser,
  useFetchAuctionFinalBidShow,
  useFetchAuctionShow,
  useFisheryShow,
  useFisheryShowState,
} from "@hooks";

const AuctionPage = (props) => {
  //Auction id
  const id = get(props, "match.params.id");

  //Current user status
  const currentUserStatus = useCurrentUser();

  //Fishery Show Page State
  const { state, setAuctionId, setAuctionLastChance, setAuctionWaitRuling } =
    useFisheryShowState();

  //Auction final bid show
  const {
    price: finalBidPrice,
    quantity: finalBidQuantity,
    hasAccepted: hasFinalAccepted,
    hasBidded: hasFinalBid,
    loading: finalBidLoading,
  } = useFetchAuctionFinalBidShow(id);

  //auction show
  const {
    usecase: auctionShow,
    loading: auctionShowLoading,
    onAuctionReceived,
    fetchAuctionShow,
  } = useFetchAuctionShow(state.auctionId, finalBidPrice);

  //auction bid list
  const {
    data: bidList,
    loading: bidListloading,
    fetchBidList,
  } = useAuctionBidList(state.auctionId);

  //auction bid all list
  const {
    data: bidAllList,
    loading: bidAllListloading,
    fetchBidAllList,
  } = useAuctionBidAllList(state.auctionId);

  //Fishery Show Data
  const { data: fisheryShow, loading: fisheryLoading } = useFisheryShow(
    get(auctionShow, "show.fisheryId")
  );

  //Set auction id
  useEffect(() => {
    id && setAuctionId(id);
  }, [id]);

  const logo = get(fisheryShow, "logoImage", "");

  return (
    // Auction page: "Auction of [Auction.name] - BeachPrice"
    <Layout>
      <SEO
        title={`Auction #${id} ${get(
          auctionShow,
          "show.title",
          ""
        )} - BeachPrice`}
      />
      <div className="gs-page pt-0 bg-gray-50 page-pb lg:pb-24">
        <div className="container lg:px-8 xl:px-0">
          <Breadcrumb
            className="my-6 lg:my-8 px-2 lg:px-0"
            data={[
              {
                name: get(fisheryShow, "title", ""),
                link: get(fisheryShow, "url", ""),
              },
              { name: get(auctionShow, "show.title", "") },
            ]}
            loading={fisheryLoading || auctionShowLoading}
          />
          {auctionShowLoading ? (
            <Loading />
          ) : (
            <>
              <Alert data={auctionShow} />
              <div className="pt-4">
                <div className="lg:grid lg:grid-cols-8 lg:bg-gray-50 rounded-lg lg:shadow relative">
                  <Line
                    data={auctionShow}
                    className="hidden lg:block absolute top-0 inset-x-0"
                  />
                  {!fisheryLoading ? (
                    <div
                      className="absolute z-0 inline-flex rounded-full left-4 md:left-8 top-0 -mt-4"
                      style={{
                        background:
                          "radial-gradient(87.6% 164.7% at 31.84% 55.53%, #222496 0%, #413FAF 100%)",
                      }}
                    >
                      <div
                        className="relative inline-flex items-center px-4 my-2 text-sm font-medium text-white uppercase"
                      >
                        {get(fisheryShow, "stateAbbr", "")}
                      </div>
                      <div
                        className="-ml-px relative inline-flex items-center px-4 my-2 border-l text-sm font-medium text-white capitalize"
                        style={{ borderColor: "rgba(255, 255, 255, 0.16)" }}
                      >
                        {get(fisheryShow, "category", "")}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-span-5 bg-white pb-6 lg:rounded-l-lg pt-2 md:t-4">
                    <AuctionShow
                      auctionId={state.auctionId}
                      auctionLastChance={state.auctionLastChance}
                      auctionWaitRuling={state.auctionWaitRuling}
                      bidList={bidList}
                      bidAllList={bidAllList}
                      bidListloading={bidListloading}
                      bidAllListloading={bidAllListloading}
                      currentUserStatus={currentUserStatus}
                      data={auctionShow}
                      fetchAuctionShow={fetchAuctionShow}
                      fetchBidList={fetchBidList}
                      fetchBidAllList={fetchBidAllList}
                      finalBidLoading={finalBidLoading}
                      finalBidPrice={finalBidPrice}
                      fisheryId={id}
                      hasFinalAccepted={hasFinalAccepted}
                      hasFinalBid={hasFinalBid}
                      loading={fisheryLoading}
                      logo={logo}
                      onAuctionReceived={onAuctionReceived}
                      setAuctionLastChance={setAuctionLastChance}
                      setAuctionWaitRuling={setAuctionWaitRuling}
                      state={state}
                    />
                  </div>
                  <div className="col-span-3">
                    <div className="lg:p-10 hidden lg:block">
                      <AuctionForm
                        state={state}
                        data={auctionShow}
                        loading={auctionShowLoading}
                        fetchAuctionShow={fetchAuctionShow}
                        fetchBidList={fetchBidList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

AuctionPage.propTypes = {
  props: PropTypes.object,
};

export default AuctionPage;

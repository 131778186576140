import React from "react";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";
import pluralize from "pluralize";
import cx from "classnames";

/**
 * import local component
 */
import { Loading, Mobile, DesktopAndTablet } from "@components";
/**
 * [Page] QuotaUnits show
 */
const QuotaUnitsShow = ({
  setShowPermanentList,
  permanentShow,
  setQuotaUnitId,
  loading,
}) => {
  const renderStatus = (item = {}) => {
    if (!item.status) {
      return null;
    }
    return (
      <span
        className={cx(
          "inline-flex items-center px-4 py-1 rounded-full text-sm font-medium leading-5",
          {
            " bg-green-400 text-white":
              item.isUnderContract || item.isUnderOffer,
          },
          {
            " bg-gray-400 text-white":
              !item.isUnderContract && !item.isUnderOffer,
          }
        )}
      >
        {item.status}
      </span>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="px-4 py-6 md:px-8 md:pt-8 md:pb-16">
      <div className="mt-6 md:mt-0 flex justify-between items-center">
        <div className="text-xl md:text-2xl font-bold leading-tight text-blue-gray-900 mr-2">
          {permanentShow.listingType === "sell"
            ? "Quota Unit for Sale"
            : "Quota Unit Wanted"}
        </div>
        {renderStatus(permanentShow)}
      </div>
      <div className="mt-4 md:mt-6">
        <div className="mt-4 rounded-lg border border-blue-gray-200">
          {!isEmpty(permanentShow.markets) &&
            map(permanentShow.markets, (item, index) => {
              return (
                <div className="li-hover" key={index}>
                  <DesktopAndTablet>
                    <div className="px-6">
                      <div className="flex justify-between items-center py-6 border-b-1 border-blue-gray-200 last-border-0">
                        <div className="text-base font-medium text-blue-gray-900">
                          {item.name}
                          <span className="">
                            {` · ${item.fishOn ? " Fish On" : "Fish Off"} · ${
                              item.amount
                            } available`}
                          </span>
                        </div>
                        <div className="text-base font-bold leading-normal text-green-500">
                          {item.price} per {pluralize(item.unitName, 1)}
                        </div>
                      </div>
                    </div>
                  </DesktopAndTablet>
                  <Mobile>
                    <div className="px-4">
                      <div className="py-4 border-b-1 border-blue-gray-200 last-border-0">
                        <div className="text-base font-medium leading-normal text-blue-gray-900">
                          {item.name}
                        </div>
                        <div className="text-base font-medium leading-normal text-blue-gray-900">
                          {`${item.fishOn ? " Fish On" : "Fish Off"} · ${
                            item.amount
                          }`}
                        </div>
                        <div className="text-base font-bold leading-snug text-green-500 mt-2">
                          {item.price} per {pluralize(item.unitName, 1)}
                        </div>
                      </div>
                    </div>
                  </Mobile>
                </div>
              );
            })}
        </div>
        <div className="mt-6">
          <div className="text-sm font-medium leading-normal text-blue-gray-600">
            Total Price
          </div>
          <h5 className="mt-2 text-base md:text-lg font-bold leading-relaxed text-blue-gray-900">
            {permanentShow.totalPrice}
          </h5>
        </div>
        <div className="mt-6">
          <div className="text-sm font-medium leading-normal text-blue-gray-600">
            Description
          </div>
          <div className="mt-2 text-base font-medium leading-snug text-blue-gray-900">
            {permanentShow.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: permanentShow.description,
                }}
              />
            )}
          </div>
        </div>
        <div className="mt-6">
          <div className="text-sm font-medium leading-normal text-blue-gray-600">
            Expire Date
          </div>
          <div className="mt-2 flex items-center">
            <span className="text-base font-medium leading-normal text-blue-gray-900">
              {permanentShow.expiredAt}
              {permanentShow.isExpired ? null : (
                <span className="text-base font-bold leading-normal text-blue-gray-900">
                  &nbsp;·&nbsp;{permanentShow.expiredDays}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

QuotaUnitsShow.propTypes = {
  permanentShow: PropTypes.object,
  setShowPermanentList: PropTypes.func,
  setQuotaUnitId: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.bool,
};

export default QuotaUnitsShow;

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { OrderUsecase, LoadingSelector } from "@core";
import {
  orderSoldAction,
  ORDER_SOLD,
} from "@redux/modules_v2/orders/sold/action";

/**
 * [hooks] order sold
 * @param params null | object 参数过滤条件
 * @returns { usecase: "a usecase by order data", loading: true|false }
 */
export const useOrderSold = (params) => {
  const dispatch = useDispatch();

  const fetchOrderSold = (page = 1) => {
    dispatch(orderSoldAction.request({ page, ...params }));
  };

  const orderSoldStatus = useSelector(state => {
    const selector = new LoadingSelector(ORDER_SOLD, state);
    return Object.assign({}, get(state, "orderSoldStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = orderSoldStatus.loading;
  const data = orderSoldStatus.data || {};
  const list = get(orderSoldStatus, "data.entries") || [];
  const usecase = new OrderUsecase(list);

  /**
   * did mount
   */
  useEffect(() => {
    fetchOrderSold();
  }, []);

  return { usecase, loading, data, fetchOrderSold };
};

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, AuctionBidListUsecase } from "@core";

import {
  auctionBidListAction,
  AUCTION_BID_LIST,
} from "@redux/modules_v2/auctions/bidList/action";

//Hooks
import { useCurrentUser } from "@hooks";

/**
 * Fetch auction list hooks
 * @param {*} param
 */
export const useAuctionBidList = (id) => {
  const dispatch = useDispatch();

  const fetchBidList = (id) => {
    dispatch(auctionBidListAction.request({ auction_id: id, limit: 1000 }));
  };

  const auctionBidListStatus = useSelector((state) => {
    const selector = new LoadingSelector(AUCTION_BID_LIST, state);
    return Object.assign({}, get(state, "auctionBidListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = auctionBidListStatus;
  const currentUser = useCurrentUser();
  const usecase = new AuctionBidListUsecase(
    get(data, "bids", []),
    get(currentUser, "user", {})
  );

  /**
   * did mount fetch list
   */
  useEffect(() => {
    return () => {
      auctionBidListAction.reset();
    };
  }, []);

  useEffect(() => {
    id && fetchBidList(id);
  }, [id]);

  return { data: usecase.list, loading, fetchBidList };
};

export const FISHERY_SHOW = "fishery/show";
export const FISHERY_SHOW_REQUEST = `${FISHERY_SHOW}_request`;
export const FISHERY_SHOW_SUCCESS = `${FISHERY_SHOW}_success`;
export const FISHERY_SHOW_FAILURE = `${FISHERY_SHOW}_failure`;
export const FISHERY_SHOW_UPDATE = `${FISHERY_SHOW}_update`;

export const fisheryShowAction = {
  request: (payload) => {
    return {
      type: FISHERY_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_SHOW_FAILURE,
      error,
    }
  },
  update: (payload) => {
    return {
      type: FISHERY_SHOW_UPDATE,
      payload,
    }
  },
};


export const paymentMethod = (payment = {}) => {
  if (payment.payment_method_type == "Payment::Stripe") {
    return "Stripe";
  }
  if (payment.payment_method_type == "Payment::Poli") {
    return "Poli"
  }
  if (payment.payment_method_type == "Payment::Wallet") {
    return "Wallet"
  }
  if (payment.payment_method_type == "Payment::Offline") {
    return "EFT"
  }

  return "";
}
import { omit } from "lodash";

import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";


export class TransferApplyApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "quotum_transafer_applies";
  }

  /**
   * Transfer Apply Api instance create with market
   * @param {object} payload { marketId: 1, quantity: 3 }
   */
  create(payload) {
    this.setApi(`${API}/${this.model}`);
    return this.post(payload);
  }


}

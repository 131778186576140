import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { ListingApiUsecase } from "@core";
import { LISTING_OFFER_NEW_REQUEST, listingOfferNewAction } from "./action";

export function* watchListingOfferNew() {
  yield takeLatest(LISTING_OFFER_NEW_REQUEST, listingOfferNew);
}

function* listingOfferNew(action) {
  try {
    const usecase = new ListingApiUsecase();
    const res = yield call([usecase, "create"], action.payload);
    const data = get(res, "data", {});
    yield put(listingOfferNewAction.success(data));
  } catch (err) {
    yield put(listingOfferNewAction.failure(errorString(err)));
  }
}

const RESEND_EAMIL_CONFIRMATION_CODE_REQUEST = 'resend_email_confirmation_code/request'
const RESEND_EAMIL_CONFIRMATION_CODE_LOADING = 'resend_email_confirmation_code/loading'
const RESEND_EAMIL_CONFIRMATION_CODE_SUCCESS = 'resend_email_confirmation_code/success'
const RESEND_EAMIL_CONFIRMATION_CODE_FAILED = 'resend_email_confirmation_code/failed'

function resendEmailConfirmationCodeRequest(payload) {
  return {
    type: RESEND_EAMIL_CONFIRMATION_CODE_REQUEST,
    payload
  }
}

export {
  RESEND_EAMIL_CONFIRMATION_CODE_REQUEST,
  RESEND_EAMIL_CONFIRMATION_CODE_FAILED,
  RESEND_EAMIL_CONFIRMATION_CODE_LOADING,
  RESEND_EAMIL_CONFIRMATION_CODE_SUCCESS,

  resendEmailConfirmationCodeRequest
}
import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";

const CurrencyInputField = ({ value, error, onChange, onBlur, onDragStart, onDrop, onFocus, ...rest }) => {
  const handleOnValueChange = (value) => {
    if (!value) {
      onChange('');
      return;
    }
    onChange(value);
  }

  const renderError = (error) => {
    if (!error) {
      return null;
    }
    return <div className="mt-2 error text-left">{error}</div>;
  };

  return (
    <div className="form-element">
      <CurrencyInput {...rest} value={value} onValueChange={handleOnValueChange} />
      {renderError(error)}
    </div>
  );
};

CurrencyInputField.propTypes = {};

export default CurrencyInputField;

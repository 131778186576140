import { get, map, isArray, groupBy, filter } from "lodash";

import { PermanentEntity, FisheryEntity } from "@core";

export class QuotaUnitUsecase {
  /**
   * 构造函数
   * @param {Array<QuotaUnit> | QuotaUnit} data 原始数据(QuotaUnit)，可能是数组或单例对象
   */
  constructor(data) {
    this.entity = data;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new PermanentEntity(entity);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new PermanentEntity({});
    }
    return new PermanentEntity(this.entity);
  }

  /**
   * [Class Getter] usecase.sellList
   */
  get sellList() {
    if (!isArray(this.entity)) {
      return [];
    }
    const list = filter(this.entity, ["permanent_listing_type", "sell"]);
    return this.listByGroup(list);
  }

  /**
   * [Class Getter] usecase.buyList
   */
  get buyList() {
    if (!isArray(this.entity)) {
      return [];
    }
    const list = filter(this.entity, ["permanent_listing_type", "buy"]);
    return this.listByGroup(list);
  }

  listByGroup(data) {
    const group = groupBy(data, "fishery.id");
    return map(group, (list, fisheryId) => {
      const fishery = get(list, "[0].fishery");
      const obj = {};
      if (fishery) {
        obj.fishery = new FisheryEntity(fishery);
        obj.quotaUnits = map(list, (item) => {
          return new PermanentEntity(item);
        });
      }
      return obj;
    });
  }
}

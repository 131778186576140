import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import Notice from "@utils/noticeNew";

import { links } from "@config/staticLink";

//Core Method
import { LoadingSelector, AccountProfileUsecase, Yup } from "@core";

//Hooks
import { useParams, useCurrentUser } from "@hooks";

//Redux
import {
  listingOfferEnquiryAction,
  LISTING_OFFER_ENQUIRY,
} from "@redux/modules_v2/listing/enquiry/action";

export const useEnquriySell = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [params] = useParams();
  const currentUserStatus = useCurrentUser();
  const isAuthenticated = get(currentUserStatus, "isAuthenticated", false);

  const profileUsecase = new AccountProfileUsecase();

  /**
   * initial values
   */
  const defaultInitialValues = {
    country: "Australia",
    email: "",
    first_name: "",
    fishery_id: "",
    interested: "",
    last_name: "",
    phone_country_code: "61",
    phone_number: "",
    selling: "",
    state: "",
  };

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback(
    (values, actions) => {
      if (step === 0) {
        setStep(step + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
        return;
      }
      if (step === 1) {
        dispatch(listingOfferEnquiryAction.request(values));
        return;
      }
      if (step === 2) {
        const redirectTo = get(params, "redirect_to", "");
        const url = redirectTo ? `${links.fishery}/${redirectTo}` : links.home;
        window.location.href = url;
      }
    },
    [step, params]
  );

  /**
   * Schema
   */
  const schema = [
    Yup.object().shape({
      first_name: Yup.string().required("This field is required"),
      last_name: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Invalid email")
        .required("This field is required"),
      state: Yup.string().required("This field is required"),
      phone_number: Yup.string()
        .phone("AU", true, "Please enter a valid Phone Number")
        .required("This field is required"),
    }),
    Yup.object().shape({
      selling: Yup.string().required("This field is required"),
      fishery_id: Yup.string().required("This field is required"),
      interested: Yup.string().required("This field is required"),
    }),
  ];

  //listing new status
  const listingOfferEnquiryStatus = useSelector((state) => {
    const selector = new LoadingSelector(LISTING_OFFER_ENQUIRY, state);
    return Object.assign({}, get(state, "listingOfferEnquiryStatus", {}), {
      loading: selector.loading,
    });
  });

  //如果已经登录直接跳转到第二步
  useEffect(() => {
    isAuthenticated && setStep(1);
  }, [isAuthenticated]);

  useEffect(() => {
    return () => {
      dispatch(listingOfferEnquiryAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!listingOfferEnquiryStatus.loading) {
      if (listingOfferEnquiryStatus.data) {
        setStep(2);
      }
      if (listingOfferEnquiryStatus.error) {
        Notice.failure(listingOfferEnquiryStatus.error);
      }
    }
  }, [listingOfferEnquiryStatus.loading]);

  return {
    defaultInitialValues,
    loading: listingOfferEnquiryStatus.loading,
    onSubmit,
    schema,
    setStep,
    states: profileUsecase.states,
    step,
  };
};

import React, { memo } from "react";
import PropTypes from "prop-types";
import { useFormikContext, getIn } from "formik";
import cx from "classnames";
import omit from "lodash/omit";
import InfoMsg from "./info_msg";

export const WithLabel = (component = "input") => (WrappedComponent) => {
  const Label = (props) => {
    const { name, label, hint, classNameWrapper, labelClass, labelFontWeight } = props;

    const { errors, touched } = useFormikContext();
    const error = getIn(errors, name);
    const touch = getIn(touched, name);
    const errorMsg = touch && error ? error : null;
    const passableProps = omit(props, [
      "hint",
      "label",
      "classNameWrapper",
      "labelClass",
    ]);
    const FontWeight = () => {
      switch (labelFontWeight) {
        case "normal":
          return "font-normal";
        default:
          return "font-medium";
      }
    };

    return (
      <div className={cx("form-element", classNameWrapper)}>
        <div className={`${component}-wrapper`}>
          {label && (
            <label
              className={cx(
                "label block text-sm leading-5 text-fish-dark",
                FontWeight(),
                labelClass
              )}
            >
              {label}
            </label>
          )}
          <div className={cx({ "mt-2": !!label })}>
            <WrappedComponent errorMsg={errorMsg} {...passableProps} />
          </div>
        </div>
        {!!errorMsg && <InfoMsg errorMsg={errorMsg} />}
        {!!hint && <InfoMsg hintMsg={hint} />}
      </div>
    );
  };

  Label.propTypes = {
    className: PropTypes.string,
    classNameWrapper: PropTypes.string,
    labelClass: PropTypes.string,
    disabled: PropTypes.bool,
    hint: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    labelFontWeight: PropTypes.string,
  };

  Label.defaultProps = {
    className: null,
    classNameWrapper: null,
    labelClass: null,
    disabled: false,
    hint: null,
    id: null,
    label: null,
    onBlur: () => {},
    onFocus: () => {},
    placeholder: null,
    required: false,
    style: null,
    type: null,
    labelFontWeight: "medium",
  };

  return memo(Label);
};

export default WithLabel;

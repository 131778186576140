import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";

const Action = ({ action, className, icon, list, title, subTitle, theme }) => {
  const getStyles = (theme) => {
    switch (theme) {
      case "teal":
        return {
          borderColor: "border-t-4 border-teal-500",
          bgColor: "bg-teal-50",
          textColor: "text-teal-800",
        };
      case "green":
        return {
          borderColor: "border-t-4 border-green-500",
          bgColor: "bg-white",
          textColor: "text-green-800",
        };
      default:
        return {
          borderColor: "",
          bgColor: "bg-white",
          textColor: "",
        };
    }
  };

  const styles = getStyles(theme);

  return (
    <div
      className={cx(
        "shadow lg:rounded-lg",
        className,
        styles.borderColor,
        styles.bgColor
      )}
    >
      <div className="px-4 py-5 lg:px-8 lg:py-6">
        <div
          className={cx({
            "lg:flex lg:items-center lg:justify-between": action,
          })}
        >
          <div className="flex flex-1">
            {icon && <div className="flex-shrink-0">{icon}</div>}
            <div
              className={cx({
                "ml-3": icon,
                // "lg:flex lg:items-center lg:justify-between w-full": !action,
              })}
            >
              <div
                className={cx("text-lg leading-6 font-bold", styles.textColor)}
              >
                {title}
              </div>
              <div
                className={cx("text-base", styles.textColor, {
                  "mt-2 max-w-xl": action,
                })}
              >
                <p>{subTitle}</p>
              </div>
            </div>
          </div>
          {action && (
            <div className="mt-5 lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex lg:items-center">
              {action}
            </div>
          )}
        </div>
      </div>
      {!isEmpty(list) && <ul className="px-4 pb-5 lg:px-8 lg:pb-8">{list}</ul>}
    </div>
  );
};

Action.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
  theme: PropTypes.oneOf(["teal", ""]),
  className: PropTypes.string,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
  ]),
};

Action.defaultProps = {
  icon: "",
  theme: "",
};

export default Action;

const stateList = [
  "Australian Capital Territory",
  "Commonwealth",
  "New South Wales",
  "Northern Territory",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia"
]

function getStateList() {
  return stateList
}

export {
  getStateList
}
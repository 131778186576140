
export const CATEGORIES_PRODUCT_GRADES_FETCH_REQUEST = "categories/product_grades_fetch_request";
export const CATEGORIES_PRODUCT_GRADES_FETCH_LOADING = "categories/product_grades_fetch_loading";
export const CATEGORIES_PRODUCT_GRADES_FETCH_SUCCESS = "categories/product_grades_fetch_success";
export const CATEGORIES_PRODUCT_GRADES_FETCH_FAILED = "categories/product_grades_fetch_failed";

export function fetchCategoryProductGrades(payload) {
  return {
    type: CATEGORIES_PRODUCT_GRADES_FETCH_REQUEST,
    payload
  };
}

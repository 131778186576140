import React, { useState } from "react";
/**
 * import lib tools
 */
import { get } from "lodash";

/**
 * import lib tools & hooks & components
 */
import {
  useMarketBalance,
  useAccountOffers,
  useAccountQuotumInventories,
  useModal,
  useBidOfferEdit,
  useBidOfferDelete,
  useOfferApply,
  useOfferApplyConfirm,
  useParams,
} from "@hooks";

import Layout from "@views/layout";
import Sidebar from "@views/account/sidebar";

import { LoadingWrapper, Link } from "@components";
import { PlaceForm, ModalWrapper } from "@views/common";
import Inventories from "./quotumInventories";
import Offers from "./offers";
import Apply from "./apply";
import Actions from "./actions";

/**
 * [Page] Quotum balance Show
 */
const Show = (props) => {
  const id = get(props, "match.params.id", "");
  const [params] = useParams({});
  const { show, loading, fetchMarketBalance } = useMarketBalance(id);
  const quotumInventoriesProps = useAccountQuotumInventories({
    quota_market_id: id,
  });
  const offersProps = useAccountOffers({ quota_market_id: id });
  const usecaseMarket = get(offersProps, "data[0].quota_market", {});
  const { isOpen, toggleModal } = useModal();
  //set current modal apply || ask
  const [currentModal, setCurrenModal] = useState("");
  const {
    place,
    setPlace,
    placeOrigin,
    setPlaceOrigin,
    submit,
  } = useBidOfferEdit(() => {
    typeof offersProps.fetchOfferList === "function" &&
      offersProps.fetchOfferList();
    typeof fetchMarketBalance === "function" && fetchMarketBalance(id);
    toggleModal();
  });
  const { deleteByData } = useBidOfferDelete(() => {
    typeof offersProps.fetchOfferList === "function" &&
      offersProps.fetchOfferList();
    typeof fetchMarketBalance === "function" && fetchMarketBalance(id);
    toggleModal();
  });
  const { onSubmit: onOfferApplyConfirmSubmit } = useOfferApplyConfirm(() => {
    typeof offersProps.fetchOfferList === "function" &&
      offersProps.fetchOfferList();
  });
  const {
    onSubmit: onOfferApplySubmit,
    loading: offerApplyLoading,
  } = useOfferApply((offerId) => {
    onOfferApplyConfirmSubmit({ offerId });
    toggleModal();
  });
  // render main content with loading
  const renderContent = () => {
    return (
      <div className="">
        <Link to="/listings">
          <div className="md:hidden cursor-pointer flex items-center li-hover">
            <svg
              className="text-blue-gray-700-hover"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0251 11.4752C11.1335 11.6196 11.186 11.7983 11.1732 11.9784C11.1605 12.1584 11.0831 12.3279 10.9555 12.4555C10.8278 12.5832 10.6584 12.6605 10.4783 12.6733C10.2982 12.6861 10.1196 12.6335 9.97514 12.5252L6.97514 9.5252C6.83771 9.385 6.76074 9.19651 6.76074 9.0002C6.76074 8.80388 6.83771 8.61539 6.97514 8.4752L9.97514 5.4752C10.1196 5.36687 10.2982 5.31429 10.4783 5.32708C10.6584 5.33988 10.8278 5.41721 10.9555 5.54487C11.0831 5.67252 11.1605 5.84195 11.1732 6.02203C11.186 6.20211 11.1335 6.38077 11.0251 6.5252L8.56514 9.0002L11.0401 11.4752H11.0251Z"
                fill="#64748B"
              />
            </svg>
            <div className="text-xs font-medium text-blue-gray-500 text-blue-gray-700-hover">
              Back
            </div>
          </div>
        </Link>
        <div className="hidden md:flex items-center">
          <Link to="/listings">
            <div className="text-xs font-normal leading-normal text-blue-gray-500 hover:text-blue-gray-800">
              My listings
            </div>
          </Link>
          <svg
            className="mx-2"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.87435 3.62565C3.81417 3.54541 3.78495 3.44616 3.79206 3.34611C3.79917 3.24607 3.84213 3.15195 3.91305 3.08102C3.98398 3.0101 4.0781 2.96714 4.17815 2.96003C4.27819 2.95292 4.37744 2.98214 4.45768 3.04232L6.12435 4.70898C6.20069 4.78687 6.24346 4.89159 6.24346 5.00065C6.24346 5.10971 6.20069 5.21443 6.12435 5.29232L4.45768 6.95898C4.37744 7.01916 4.27819 7.04838 4.17815 7.04127C4.0781 7.03416 3.98398 6.9912 3.91305 6.92028C3.84213 6.84936 3.79917 6.75523 3.79206 6.65519C3.78495 6.55514 3.81417 6.45589 3.87435 6.37565L5.24518 5.00065L3.87018 3.62565H3.87435Z"
              fill="#6B7280"
            />
          </svg>
          <div className="cursor-pointer text-xs font-normal leading-normal text-blue-gray-500">
            {get(show, "quotaMarketTitle", "")}
          </div>
        </div>
        <div className="relative">
          <div className="mt-6 md:mt-10 mb-8 mx-1">
            <div className="lg:flex items-center space-x-4">
              <h2 className="mb-1 lg:mb-0">
                {get(show, "quotaMarketTitle", "")}
              </h2>

              <Link
                to={get(show, "quotaMarketUrl", "/")}
                className="text-sm font-medium text-blue-gray-500 hover:text-blue-gray-600"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <Actions
            toggleModal={(data, currentModal) => {
              setPlace(data);
              setPlaceOrigin(data);
              setCurrenModal(currentModal);
              toggleModal();
            }}
            params={params}
            {...offersProps}
          />
          <dl className="rounded-lg bg-white shadow sm:grid sm:grid-cols-3">
            <div className="flex flex-col lg:border-b border-blue-gray-100 px-6 pt-8 pb-4 lg:py-8 text-center sm:border-0 sm:border-r space-y-2 lg:space-y-3">
              <dd className="order-1 text-xxs lg:text-sm  text-blue-gray-500 uppercase">
                Total Quota Consigned
              </dd>
              <dt className="order-2 text-2xl lg:text-3xl leading-6 font-bold">
                {get(show, "str.total", "")}
              </dt>
            </div>
            <div className="flex flex-col lg:border-t lg:border-b border-blue-gray-100 py-4 lg:py-8 text-center sm:border-0 sm:border-l sm:border-r space-y-2 lg:space-y-3">
              <dd className="order-1 text-xxs lg:text-sm text-blue-gray-500 uppercase">
                Listed On Market
              </dd>
              <dt className="order-2 text-2xl lg:text-3xl leading-6 font-bold">
                {get(show, "str.pending", "")}
              </dt>
            </div>
            <div className="flex flex-col lg:border-t border-blue-gray-100 pt-4 pb-8 lg:py-8 text-center sm:border-0 sm:border-l space-y-2 lg:space-y-3">
              <dd className="order-1 text-xxs lg:text-sm text-blue-gray-500 uppercase">
                Available To List
              </dd>
              <dt className="order-2 text-2xl lg:text-3xl leading-6 font-bold">
                {get(show, "str.available", "")}
              </dt>
            </div>
          </dl>
        </div>
        <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow mt-4 lg:mt-6">
          <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2">
                <h3>
                  Listed quota
                </h3>
              </div>
            </div>
          </div>
          <Offers
            toggleModal={(data, currentModal) => {
              setPlace(data);
              setPlaceOrigin(data);
              setCurrenModal(currentModal);
              toggleModal();
            }}
            {...offersProps}
          />
        </div>
        <div className="bg-white overflow-hidden border border-blue-gray-200 rounded-lg lg:shadow mt-4 lg:mt-6">
          <div className="bg-white px-4 py-5 lg:border-b border-blue-gray-200 lg:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2">
                <h3>
                  Quota transfer history
                </h3>
              </div>
            </div>
          </div>
          <Inventories {...quotumInventoriesProps} />
        </div>
      </div>
    );
  };

  return (
    <Layout name="account">
      <div className="flex flex-1 bg-gray-50 min-h-screen md:pt-10">
        <Sidebar
          className="hidden"
          current="listings"
        />
        <div className="max-w-960 mx-auto flex flex-1">
          <div className="flex flex-col w-0 flex-1 px-4 lg:px-0 mt-6 lg:mt-0 mb-8 lg:mb-16">
            <LoadingWrapper loading={loading}>{renderContent()}</LoadingWrapper>
          </div>
        </div>
      </div>
      <ModalWrapper
        title={currentModal === "ask" ? "Edit Ask Price" : "Transfer quota"}
        isOpen={isOpen}
        toggleModal={() => {
          setPlace({});
          setPlaceOrigin({});
          toggleModal();
        }}
        size="md"
      >
        <div className="pt-8">
          {currentModal === "ask" ? (
            <PlaceForm
              dataOrigin={placeOrigin}
              data={place}
              setPlaceData={setPlace}
              onSubmit={() => submit()}
              onDelete={() => deleteByData(placeOrigin)}
              usecaseMarket={usecaseMarket}
            />
          ) : (
            <Apply
              data={place}
              onSubmit={onOfferApplySubmit}
              loading={offerApplyLoading}
            />
          )}
        </div>
      </ModalWrapper>
    </Layout>
  );
};

export default Show;

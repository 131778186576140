import React, { Component } from "react";

/**
 * import lib tools
 */
import { get } from "lodash";

import { Link } from "@components";

const Links = (props) => {
  const { order } = props;
  return (
    <div className="bg-white border-1 border-blue-gray-200 rounded-lg px-4 md:px-6">
      <Link
        to={get(order, "order.market.fisheryUrl", "")}
        className="flex items-center justify-between border-b border-blue-gray-200 hover:text-fish-primary py-4 md:py-6"
      >
        <span className="font-bold">Go to the market</span>
        <svg
          className="h-5 w-5 text-blue-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </Link>
      <Link
        to="/orders"
        className="flex items-center justify-between hover:text-fish-primary py-4 md:py-6"
      >
        <span className="font-bold">Go to my order lists</span>
        <svg
          className="h-5 w-5 text-blue-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </Link>
    </div>
  );
};

export default Links;

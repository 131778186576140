import React from "react";
import PropTypes from "prop-types";

/**
 * lib tools
 */
import get from "lodash/get";
import numeral from "@utils/numeral";

/**
 * import core
 */
import { fishnetClientNumber } from "@core";

/**
 * Hooks
 */
import { useMarketUnitName } from "@hooks";

/**
 * Components
 */
import { Button } from "@components";
const MarketType = {
  lease: "Lease seasonal quota",
  permanent: "Sell permanent quota",
};
const SellType = {
  p2p: "Fixed price",
  auction: "Auction",
  exchange: "Consign to BeachPrice",
}


const StepEnd = ({ values }) => {
  const market = get(values, "market.market", {}) || {};
  const title = get(market, "title", "");
  const price = get(values, "price", "") || 0;
  const quantity = get(values, "quantity", "") || 0;
  const marketType = get(values, "market_type", "");
  const sellType = get(values, "sell_type", "")
  const { amountStr, unitOfPrice } = useMarketUnitName(market, quantity);
  return (
    <ul className="py-4 rounded-lg space-y-3">
      <li className="flex items-center justify-between">
        <span className="text-blue-gray-500 text-sm">
          What are you listing?
        </span>
        <span className="text-blue-gray-700 text-sm font-medium">{MarketType[marketType]}</span>
      </li>
      <li className="flex items-center justify-between">
        <span className="text-blue-gray-500 text-sm">
          What are you selling?
        </span>
        <span className="text-blue-gray-700 text-sm font-medium">{title}</span>
      </li>
      <li className="flex items-center justify-between">
        <span className="text-blue-gray-500 text-sm">
          How would you like to sell?
        </span>
        <span className="text-blue-gray-700 text-sm font-medium">
          {SellType[sellType]}
        </span>
      </li>
      <li className="flex items-center justify-between">
        <span className="text-blue-gray-500 text-sm">price</span>
        <span className="text-blue-gray-700 text-sm font-medium">
          {numeral(price).format()}/{unitOfPrice}
        </span>
      </li>
      <li className="flex items-center justify-between">
        <span className="text-blue-gray-500 text-sm">Unit</span>
        <span className="text-blue-gray-700 text-sm font-medium">
          {amountStr}
        </span>
      </li>
    </ul>
  );
};

StepEnd.propTypes = {};

export default StepEnd;

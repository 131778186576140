import React from "react";

/**
 * Hooks
 */
import { useAccountProfileSettingForm } from "@hooks/account/profile";

/**
 * Components
 */
import { Loading } from "@components";
import Form from "./form";

/**
 * [Page] Account profile 个人信息页面
 */
const Profile = () => {
  const { fetchloading, ...formProps } = useAccountProfileSettingForm();
  return <div>{fetchloading ? <Loading /> : <Form {...formProps} />}</div>;
};

export default Profile;

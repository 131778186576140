import { GlobalApiRepo } from "@core";

export class GlobalApiUsecase {

  constructor() {
    this.repo = new GlobalApiRepo();
  }

  static(payload) {
    return this.repo.static(payload);
  }

}
import React from "react";

/**
 * import lib tools
 */
import { get } from "lodash";

import { useGlobalStatic } from "@hooks";
import Layout from "@views/layout";
import Content from "./_content";

const Failed = props => {
  const { data: staticData } = useGlobalStatic();
  const { title, description } = get(staticData, "payment_page.failed", {});

  return (
    <Layout>
      <Content
        title={title}
        description={description}
        type="failed"
        redirect={true}
      />
    </Layout>
  );
};

export default Failed;


export const BANKS_UPDATE_PUT = "banks/update_put";
export const BANKS_UPDATE_LOADING = "banks/update_loading";
export const BANKS_UPDATE_SUCCESS = "banks/update_success";
export const BANKS_UPDATE_FAILED = "banks/update_failed";
export const BANKS_UPDATE_RECEIVED = "banks/update_received";
export const BANKS_UPDATE_RESET = "banks/update_reset";

export function bankUpdatePut(payload) {
  return {
    type: BANKS_UPDATE_PUT,
    payload
  };
}

export function bankUpdateReset() {
  return {
    type: BANKS_UPDATE_RESET
  };
}


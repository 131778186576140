import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * import lib tools
 */
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { submit } from "redux-form";
import get from "lodash/get";
import cx from "classnames";

import { links } from "@config/staticLink";

/**
 * import local hooks & utils & actions
 */
import { useGlobalStatic } from "@hooks";
import Notice from "@utils/noticeNew";
import { initRequest } from "@redux/modules/forgotPassword/initAction";
import { confirmRequest } from "@redux/modules/forgotPassword/confirmAction";

/**
 * common components
 */
import { Button, Image, Link, Mobile, DesktopAndTablet } from "@components";

/**
 * local components
 */
import FormStepOne from "./_init_forgot_password";
import FormStepTwo from "./_confirm_forgot_password";

const ForgotPassword = (props) => {
  const history = useHistory();
  const { data: staticData } = useGlobalStatic(true);

  const [page, setPage] = useState(1);
  const [email, setEmail] = useState(null);
  const [requestCodeDisabled, setRequestCodeDisabled] = useState(false);
  const initForgotPassword = useSelector((state) =>
    get(state, "initForgotPassword", {})
  );
  const confirmForgotPassword = useSelector((state) =>
    get(state, "confirmForgotPassword", {})
  );

  useEffect(() => {
    if (!initForgotPassword.loading && initForgotPassword.error) {
      // init forgot password failed
      return Notice.failure(initForgotPassword.error.message);
    }
    if (!initForgotPassword.loading && initForgotPassword.data) {
      // init forgot password success
      Notice.success(
        get(staticData, "messages.success_send_code_to_email", "")
      );
      return nextPage();
    }
  }, [initForgotPassword.loading]);
  useEffect(() => {
    if (!confirmForgotPassword.loading && confirmForgotPassword.error) {
      // confirm forgot password failed
      return Notice.failure(confirmForgotPassword.error.message);
    }
    if (!confirmForgotPassword.loading && confirmForgotPassword.data) {
      // confirm forgot password success
      Notice.success("Reset password success, please login");
      props.history.push("/login");
    }
  }, [confirmForgotPassword.loading]);

  const nextPage = () => {
    if (page > 1) {
      return;
    }
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  /**
   * callback for quote form submit
   */

  const handleSubmit = (values) => {
    onChangeReuqest(values);
  };

  const onChangeReuqest = (payload) => {
    switch (page) {
      case 1:
        // 第一步点击next按钮的请求，请求成功后执行nextPage跳转到下一页
        initForgotPasswordReqeust(payload);
        break;
      case 2:
        // 第二步点击verify按钮的请求，请求成功后执行nextPage跳转到下一页
        confirmForgotPasswordRequest(payload);
        break;
      default:
        return;
    }
  };

  const initForgotPasswordReqeust = ({ email }) => {
    setEmail(email);
    props.initRequest({ email });
  };

  const confirmForgotPasswordRequest = ({ code, password }) => {
    props.confirmRequest({
      email: email,
      code: code,
      newPassword: password,
    });
  };

  const handleRequestCodeAllow = () => {
    setTimeout(() => {
      setRequestCodeDisabled(false);
    }, 60 * 1000);
  };

  const renderResetCode = () => {
    return (
      <button
        className={cx(
          "-ml-px relative inline-flex items-center px-4 py-2 border border-blue-gray-200 text-sm leading-5 font-medium rounded-r-md text-blue-gray-700 bg-gray-50 hover:text-blue-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-50 active:text-blue-gray-700 transition ease-in-out duration-150",
          {
            "opacity-50 cursor-not-allowed pointer-events-none": requestCodeDisabled,
          }
        )}
        onClick={() => {
          props.initRequest({ email });
          setRequestCodeDisabled(true);
          handleRequestCodeAllow();
        }}
        disabled={requestCodeDisabled}
      >
        {requestCodeDisabled ? "Sent" : "Resend"}
      </button>
    );
  };

  /**
   * remote submit order form
   */

  const onSubmit = () => {
    props.dispatch(submit("ForgotPasswordForm"));
  };

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <div className="hidden md:block absolute top-5 left-8">
        <Link to="/dashboard">
          <Image source="logo" className="h-8 w-32" />
        </Link>
      </div>
      <div className="min-h-screen md:grid grid-cols-1 md:grid-cols-2 px-4 md:px-0 pb-12 md:pb-0">
        <div className="w-full h-full md:bg-white md:flex flex-col justify-center">
          <div className="max-w-sm mx-auto py-12 md:py-0 md:transform md:translate-x-1/5">
            <Mobile>
              <div className="flex justify-center">
                <Link to={links.home}>
                  <Image source="logo" className="h-8 w-32" />
                </Link>
              </div>
            </Mobile>
            <DesktopAndTablet>
              <h2 className="leading-tight text-center md:text-left">
                Welcome back
              </h2>
              <div className="mt-6 md:mt-8 px-6 md:px-0 text-sm md:text-base text-blue-gray-600 text-center md:text-left">
                Log back in to your BeachPrice accout to start trading.
              </div>
            </DesktopAndTablet>
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-center">
          <div className="max-w-sm w-full mx-auto">
            <div className="hidden md:flex">
              <Link
                to={links.home}
                className="flex items-center mb-6"
              >
                <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.21872 8.00001L10.5187 11.3L9.57606 12.2427L5.33339 8.00001L9.57606 3.75734L10.5187 4.70001L7.21872 8.00001Z" fill="#677489"/>
                </svg>
                <span className="text-sm text-blue-gray-500">Back to homepage</span>
              </Link>
            </div>
            <div className="rounded-lg bg-gray-200 grid grid-cols-2" style={{padding:6}}>
              <Link
                to="/register"
                className="py-3 text-center text-blue-gray-600 font-medium text-sm"
              >
                Create account
              </Link>
              <Link
                to="/login"
                className="bg-white rounded-lg shadow-t-xs py-3 text-center text-blue-gray-800 font-medium text-sm"
              >
                Log in
              </Link>
            </div>
            <div className="mt-6 bg-white rounded-lg shadow-t-card">
              <div className="p-6 md:p-10 md:pb-12">
                <h4 className="text-base">
                  {page === 2 ? "Password reset link sent" : "Reset your password"}
                </h4>
                <p className="mt-2-tw text-sm leading-5 text-blue-gray-500">
                  {page === 2 ? (
                    <span>
                      A verification email has been sent to your email address. Please
                      check and verify your email. <strong>{email}</strong>
                    </span>
                  ) : (
                    "Enter your email and we'll send you a link to reset your password."
                  )}
                </p>
                <div className="mt-6">
                  {page === 1 && (
                    <FormStepOne
                      loading={props.initForgotPassword.loading}
                      onSubmit={handleSubmit}
                    />
                  )}
                  {page === 2 && (
                    <FormStepTwo
                      loading={props.confirmForgotPassword.loading}
                      onSubmit={handleSubmit}
                      renderResetCode={renderResetCode}
                    />
                  )}
                  <button
                    onClick={() => {
                      history && history.goBack();
                    }}
                    className="block w-full mt-2 rounded-lg border border-blue-gray-200 py-2 h-42 bg-white hover:bg-gray-100 text-center text-blue-gray-700 font-medium text-sm"
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    initForgotPassword: state.initForgotPassword,
    confirmForgotPassword: state.confirmForgotPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    {},
    bindActionCreators(
      {
        initRequest,
        confirmRequest,
      },
      dispatch
    ),
    {
      dispatch,
    }
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import { takeLatest, call, put } from "redux-saga/effects";

import { OfferApiUsecase } from "@core";
import { OFFER_ORDER_REQUEST, offerOrderAction } from "./action";

// post buyer order with offer & market(lease)
export function* watchMarketOfferOrderPost() {
  yield takeLatest(OFFER_ORDER_REQUEST, offerOrderPost);
}

function* offerOrderPost(action) {
  try {
    const usecase = new OfferApiUsecase();
    const res = yield call([usecase, "order"], action.payload);
    yield put(offerOrderAction.success(res.data));
  } catch (err) {
    yield put(offerOrderAction.failure(err));
  }
}

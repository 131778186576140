import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Collapse = ({
  title,
  content,
  className,
  titleClassName,
  contentClassName,
  open,
}) => {
  const [isOpen, setOpen] = useState(open || false);
  return (
    <div
      className={cx(
        "border-t border-blue-gray-200 first:border-t-0",
        className
      )}
    >
      <div>
        <dt className="text-lg leading-7">
          <button
            className={cx(
              "text-left w-full flex justify-between items-center overflow-hidden",
              titleClassName,
              { rounded: !isOpen, "rounded-t": isOpen }
            )}
            onClick={() => setOpen(!isOpen)}
          >
            <div className="flex-1">{title}</div>
            <span className="ml-6 h-7 flex items-center">
              <svg
                className={cx(
                  "h-6 w-6 transform",
                  { "rotate-0": !isOpen },
                  { "rotate-180": isOpen }
                )}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <dd className={cx("", contentClassName, { hidden: !isOpen })}>
          {content}
        </dd>
      </div>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  open: PropTypes.bool,
};

Collapse.defaultProps = {
  data: { title: "", content: "" },
  className: "",
  open: false,
};

export default Collapse;

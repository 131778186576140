import axios from 'axios';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';

import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import capitalize from 'lodash/capitalize';

import { listingsAPI } from '@config/api';

import {
  LISTING_SHOW_FETCH_REQUEST,
  LISTING_SHOW_FETCH_LOADING,
  LISTING_SHOW_FETCH_SUCCESS,
  LISTING_SHOW_FETCH_FAILED
} from './showAction';

//fetch listing show
export function* watchListingShowFetch() {
  yield takeLatest(LISTING_SHOW_FETCH_REQUEST, listingShowFetch);
}

export function* listingShowFetch(action) {
  yield put({ type: LISTING_SHOW_FETCH_LOADING });

  const { res, err } = yield call(listingShowFetchApi, action.payload);
  if (!err) {
    yield put({ type: LISTING_SHOW_FETCH_SUCCESS, data: res });
    return;
  }
  yield put({
    type: LISTING_SHOW_FETCH_FAILED,
    error: 'Get listing details data failed.'
  });
}

function listingShowFetchApi(payload) {
  return axios
    .get(listingsAPI(payload.id))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

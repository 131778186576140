import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_QUOTA_LIST_REQUEST, fisheryQuotaListAction } from "./action";

//Fetch fishery follow list
export function* watchFisheryQuotaListFetch() {
  yield takeLatest(FISHERY_QUOTA_LIST_REQUEST, fisheryQuotaListFetch);
}

function* fisheryQuotaListFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "quota"], action.payload);
    yield put(fisheryQuotaListAction.success(res.data));
  } catch (err) {
    yield put(fisheryQuotaListAction.failure(err));
  }
}
 
import React from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import cx from "classnames";
import { Loading } from "@components";
import { get, map, find, filter, isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

const SaleList = ({
  permanentList,
  setQuotaUnitId,
  // setShowPermanentList,
  loading,
  // title,
  // setActiveTab,
  name,
  state,
}) => {
  const sellList = permanentList.sell || [];
  const buyList = permanentList.buy || [];
  const emptyTextBuy = "No Wanted to Purchase Listing";
  const emptyTextSell = "No For Sale Listing";
  const history = useHistory();
  if (loading) {
    return <Loading />;
  }

  const renderSale = () => {
    return (
      <div className="mb-6">
        <h2>
          {`${name ? name + " " : ""}For Sale`}
        </h2>
        {isEmpty(sellList) ? (
          <div className="text-center text-blue-gray-800 textcolor-hover font-base mt-6">
            {emptyTextSell}
          </div>
        ) : (
          <div className="bg-white rounded-lg border border-blue-gray-200 overflow-hidden mt-6">
            {map(sellList, (item, index) => renderItem(item, index))}
          </div>
        )}
      </div>
    );
  };

  const renderWanted = () => {
    return (
      <div className="mt-6 md:mt-8 mb-6">
        <h2>
          {`${name ? name + " " : ""}Wanted To Purchase`}
        </h2>
        {isEmpty(buyList) ? (
          <div className="text-center text-blue-gray-800 textcolor-hover font-base mt-6">
            {emptyTextBuy}
          </div>
        ) : (
          <div className="bg-white rounded-lg border border-blue-gray-200 overflow-hidden mt-6">
            {map(buyList, (item, index) => renderItem(item, index))}
          </div>
        )}
      </div>
    );
  };

  const renderItem = (item, index) => {
    const marketSame = find(item.markets, (m) => {
      return get(m, "quotum.id") == state.quotaId;
    });
    const marketOther = filter(item.markets, (m) => {
      return get(m, "quotum.id") != state.quotaId;
    });
    return (
      <div
        key={index}
        className="cursor-pointer hover:bg-gray-50 li-hover"
        onClick={() => {
          setQuotaUnitId(item.id);
          history &&
            history.push(
              `/permanents/${item.id}?fishery_id=${state.fisheryId}&quotum_id=${state.quotaId}`
            );
        }}
      >
        {index == 0 ? null : <hr className="mx-4 md:mx-6" />}
        <div className="md:flex justify-between items-center px-4 md:px-6 py-4">
          <div className="flex-1">
            <div className="mt-4 first:mt-0">
              <div className="mt-2 first:mt-0">
                <div className="md:mr-2 text-lg md:text-base font-medium leading-relaxed text-blue-gray-900 flex items-center">
                  <span className="mr-2">
                    {marketSame && marketSame.nameNoState} ·{" "}
                    {marketSame && marketSame.amount}
                  </span>
                  {renderStatus(item)}
                </div>
              </div>
              {map(marketOther, (market, mindex) => {
                return (
                  <div className="mt-2 first:mt-0" key={mindex}>
                    <div className="text-sm font-medium text-blue-gray-500">
                      With {market.nameNoState} · {market.amount}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {marketSame ? (
            <button
              className="mt-3 md:mt-0 h-10 leading-10 text-sm font-semibold text-center rounded-full border border-fish-primary text-fish-primary buttoncolor-hover"
              style={{
                width: 155,
              }}
            >
              {marketSame.price} per {pluralize(marketSame.unitName, 1)}
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  const renderStatus = (item = {}) => {
    if (!item.status) {
      return null;
    }
    return (
      <span
        className={cx(
          "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5",
          {
            " bg-green-400 text-white":
              item.isUnderContract || item.isUnderOffer,
          },
          {
            " bg-gray-400 text-white":
              !item.isUnderContract && !item.isUnderOffer,
          }
        )}
      >
        {item.status}
      </span>
    );
  };

  return (
    <div className="px-4 md:px-8 pt-6 md:pt-8">
      <div className="pb-6">
        {/* {renderNav()} */}
        {renderSale()}
        {renderWanted()}
      </div>
    </div>
  );
};

SaleList.propTypes = {
  permanentList: PropTypes.object,
  setQuotaUnitId: PropTypes.func,
  setShowPermanentList: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  setActiveTab: PropTypes.func,
  state: PropTypes.object,
  name: PropTypes.string,
};

SaleList.defaultProps = {
  permanentList: {},
  setQuotaUnitId: () => {},
  setShowPermanentList: () => {},
  loading: false,
  state: {},
  name: "",
};

export default SaleList;
import React from "react";
import { StripeProvider } from "react-stripe-elements";
import get from "lodash/get";

/**
 * import common components
 */
import { stripePlatformClientId } from "@config/stripe";
import Layout from "@views/layout";

/**
 * import local components
 */

import Checkout from "./_stripe_check_out";
import { usePaymentAmountHook } from "@hooks";
import { Modal, useModal } from "@components";

// POST /api/v1/orders/order_id/payments/stripe
// 会创建一个 payment, response 中会带有一个 url，这个url是stripe form页面的url，也就是前端的url，为了和poli的接口统一，我直接在接口中返回了，url中的数字就是 payment id
// POST /api/v1/payments/stripe/payment_id/complete { "token": "xxxx" }
// 在 stripe form 页面中，提交form的时候，请求这个接口，进行支付，payment id就是上一个接口拿到的

const PaymentStripe = (props) => {
  const payment_id = get(props, "match.params.payment_id");
  const [payment, loading] = usePaymentAmountHook(payment_id);

  const paidAmount = get(payment, "paid_amount_with_symbol");
  const orderId = get(payment, "order_id", "");
  
  const [modalProps] = useModal(
    {
      content: (
        <div className="container pt-3 payments-method-wrapper text-left">
          <div className="flex justify-between mt-3 mb-6">
            <div className="text-xl font-bold">Pay with credit card</div>
            <svg
              width="130"
              height="30"
              viewBox="0 0 65 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="47.5" cy="7.5" r="7.5" fill="#D82E2B" />
              <circle cx="56.6072" cy="7.5" r="7.5" fill="#EAA140" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.0537 13.5271C53.8449 12.1565 55.0002 9.99671 55.0002 7.56712C55.0002 5.13753 53.8449 2.97776 52.0537 1.60718C50.2626 2.97776 49.1073 5.13753 49.1073 7.56712C49.1073 9.99671 50.2626 12.1565 52.0537 13.5271Z"
                fill="#EC692C"
              />
              <path
                d="M23.2602 3.40849C22.5157 3.13418 21.7306 2.99383 20.9392 3.00021C18.3805 3.00021 16.5724 4.31757 16.5599 6.20269C16.5443 7.59022 17.8456 8.36852 18.8278 8.83422C19.8381 9.30949 20.176 9.60932 20.1728 10.0367C20.1666 10.6843 19.3658 10.9777 18.6244 10.9777C17.6016 10.9777 17.0416 10.831 16.1846 10.4674L15.8655 10.3174L15.5026 12.4896C16.1251 12.7544 17.245 12.9809 18.4023 13C21.1238 13 22.9036 11.6922 22.9224 9.68268C22.9443 8.57585 22.2436 7.73695 20.7609 7.04797C19.8631 6.59822 19.3032 6.29839 19.3032 5.84225C19.3032 5.44035 19.7818 5.00974 20.7828 5.00974C21.4491 4.99379 22.1091 5.12138 22.7222 5.37974L22.963 5.48819L23.3259 3.38936L23.2602 3.40849ZM29.9042 3.17564H27.9054C27.2829 3.17564 26.8168 3.34789 26.5447 3.97945L22.6972 12.8628H25.4186L25.9629 11.4083L29.2817 11.4115C29.363 11.7528 29.6008 12.8628 29.6008 12.8628H32L29.9042 3.17564ZM12.8813 3.0959H15.4714L13.851 12.7895H11.2579L12.8813 3.0959ZM6.29365 8.4355L6.56266 9.78157L9.09638 3.17564H11.8428L7.76383 12.8501H5.02366L2.78397 4.65568C2.74643 4.52171 2.66197 4.40369 2.54311 4.33351C1.7392 3.90609 0.885239 3.58393 0 3.37341L0.0344086 3.16926H4.21036C4.77654 3.19159 5.23011 3.37341 5.38651 3.98902L6.29677 8.43869V8.4355H6.29365ZM26.7042 9.42432L27.7365 6.71305C27.7239 6.74176 27.9492 6.15166 28.0805 5.78803L28.2588 6.62374L28.8563 9.42113L26.7042 9.42432Z"
                fill="#2563EB"
              />
            </svg>
          </div>
          <div className="payments-method-form">
            <div className="pay-amount flex text-lg items-end mb-4">
              <span className="text-sm font-medium flex-1 pr-4-tw leading-tight">
                Total Price:
              </span>
              <span className="text-sm font-medium">{paidAmount}</span>
            </div>
            <StripeProvider apiKey={stripePlatformClientId()}>
              <Checkout
                paymentId={payment_id}
                orderId={orderId}
                loading={loading}
              />
            </StripeProvider>
          </div>
        </div>
      ),
      showClose: true,
      onClosed: () => {
        props.history && props.history.goBack();
      },
      size: "xl",
      isVertical: true,
    },
    true
  );

  if (!payment_id) {
    return null;
  }

  return (
    <Layout>
      <Modal {...modalProps} />;
    </Layout>
  );
};

export default PaymentStripe;

import React from "react";
import { map, get, isEmpty } from "lodash";
import { useRouteMatch } from "react-router-dom";
import { ChartFull } from "@views/common";
import { Loading, Link } from "@components";
import numeral from "@utils/numeral";

import Divider from "./_divider";

const ShowChart = ({list, loading, isLogin}) => {
  let chartData = map(list, function(o) { return {...o.data.last_bid, price_cents:o.data.current_price*100}; }) || [];
  const lastAuctionPrice = numeral(get(list, "[0][data.current_price]",0)).format()

  const match = useRouteMatch();
  const loginUrl = get(match, "url", "")
    ? `/login?redirect_to=${get(match, "url", "")}`
    : "/login";
    
  const renderLogin = () => {
    return (
      <div className="p-4 lg:p-6 rounded-lg bg-gray-50 text-center whitespace-normal">
        <Link to={loginUrl} className="underline hover:text-fish-primary">
          Login to see past auction
        </Link>
      </div>
    );
  };

  return (
    <div className="">
      {loading ? (
          <Loading />
        ) : !isLogin ? (
          renderLogin()
        ) : isEmpty(list) ? (
          <div></div>
        ) : (
          <div>
            <Divider className="mt-8 mb-6" />
            <div className="">
              <div className="flex items-center justify-between mb-4">
                <div className="font-medium">Recent auction prices</div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4 lg:p-6">
                <ChartFull 
                  data={chartData} 
                  yPosition="left" 
                  zoneLineColor="#cbd5e1"
                  isBgGradient={true}
                  showX={false}
                />
                <div className="lg:mt-4 bg-white rounded-lg py-3 flex justify-center">
                  <div className="text-xxs text-blue-gray-500">
                    Last auction price <span className="text-blue-gray-900 font-medium">{lastAuctionPrice}/{get(list, "[0][data.price_unit]", "")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default ShowChart;

import React from "react";
import isEmpty from "lodash/isEmpty";

import { Loading } from "@components";

import Chart from "./_transactionsChart";
import List from "./_transactionsList";

/**
 * import lib tools & hooks & components
 */
import { useModal } from "@hooks";

const Transactions = ({ statistics, transactions, loading }) => {
  const { isOpen, toggleModal } = useModal();
  if (loading) {
    return <Loading />;
  }

  if (isEmpty(transactions)) {
    return null;
  }

  return (
    <div className="">
      <div className="flex items-center justify-between py-4 border-b border-blue-gray-100">
        <div className="text-sm font-medium">Past transactions</div>
        <button
          className="text-blue-gray-500 text-xxs"
          onClick={() => {
            toggleModal();
          }}
        >
          View all past transactions
        </button>
      </div>
      <Chart data={statistics} />
      <List data={transactions} isOpen={isOpen} />
    </div>
  );
};

Transactions.propTypes = {};

export default Transactions;

import { signUp } from "./signUp";
import { signIn } from "./signIn";

/**
 * Sign up and Sign in
 * @param email
 * @param password
 * @param opts
 * @return {Promise<any>}
 */
function signUpAndSignIn(email, password, opts) {
  return new Promise((resolve, reject) => {
    signUp(email, password, opts).then(signUpRes => {
      signIn(email, password).then(signInRes => {
        resolve(signInRes)
      }
      ).catch(signInErr => {
        reject(Object.assign(signInErr, { type: "signInErr" }))
      })
    }).catch(signUpErr => {
      reject(Object.assign(signUpErr, { type: "signUpErr" }))
    })
  })
}

export {signUpAndSignIn}
import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, map, isArray } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { BidOfferUsecase, MarketUsecase, LoadingSelector } from "@core";
import {
  accountOffersAction,
  ACCOUNT_OFFERS,
} from "@redux/modules_v2/account/offers/action";

/**
 * [hooks] account offers
 * @returns { usecase: Array<Offer|Bid>, loading: true|false }
 */
export const useAccountOffers = (params={}) => {
  const dispatch = useDispatch();

  const fetchOfferList = () => {
    dispatch(accountOffersAction.request(params));
  };

  const acccountOffersStatus = useSelector((state) => {
    const selector = new LoadingSelector(ACCOUNT_OFFERS, state);
    return Object.assign({}, get(state, "acccountOffersStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = acccountOffersStatus.loading;
  const data = isArray(acccountOffersStatus.data)
    ? map(acccountOffersStatus.data, (group) => {
        const usecaseMarket = new MarketUsecase(group.quota_market || {});
        const market = usecaseMarket.single;
        const usecaseBidOffer = new BidOfferUsecase(
          group.bid_offer_prices || [],
          market
        );
        return {
          quota_market: market,
          bid_offer_prices: usecaseBidOffer.list,
        };
      })
    : [];

  /**
   * did mount and watch id change
   */
  useEffect(() => {
    fetchOfferList();
  }, []);

  return { data, loading, fetchOfferList };
};

import {
  INITIALIZE_REQUEST,
  INITIALIZE_LOADING,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAILED
} from "./initAction"

function initForgotPassword(state = {}, action = {}) {
  switch (action.type) {
    case INITIALIZE_LOADING:
      return {loading: true, data: null, error: null}
    case INITIALIZE_SUCCESS:
      return {loading: false, data: action.data, error: null}
    case INITIALIZE_FAILED:
      return {loading: false, data: null, error: action.error}
    default:
      return state
  }
}

export {initForgotPassword}
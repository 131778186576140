import pick from "lodash/pick";

export class AccountBankUsecase {
  /**
   * 构造函数
   */
  constructor(props = {}) {
    this.data = props;
  }

  /**
   * 获取bank表单初始数据
   */
  get initialValues() {
    return pick(this.data, ["name", "bsb", "acc", "account_name"]);
  }
}
 
import React, { useReducer } from "react";
import get from "lodash/get";

//Core
import { FisheryEntity } from "@core";

//Common Components
import Layout from "@views/layout";
import { Loading, SEO, TabContent, TabPanel } from "@components";

//Local components
import Header from "./_header";
import Auctions from "./_auctions";
import QuotaMarkets from "@views/markets/exchange/list";

//Hooks
import {
  useFisheryShow,
  useFisheryLeaseMarketList,
  useAuctionList,
  useCurrentUser,
} from "@hooks";

//Context
import { CurrentUserContext } from "@contexts";

//Reducer
import { reducer, initialState } from "./reducer";

const Show = (props) => {
  const slug = get(props, "match.params.slug");
  const id = FisheryEntity.slugToId(slug);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: fishery, loading: fisheryLoading } = useFisheryShow(id);
  const [leaseMarkets, leaseMarketsloading] = useFisheryLeaseMarketList(id);
  const { activeList, loading: auctionLoading } = useAuctionList({
    fishery_id: id,
    expired: false,
  });

  const currentUser = useCurrentUser();
  const logoImage = get(fishery, "logoImage", "");

  if (fisheryLoading) {
    return (
      <Layout>
        <div
          className="gs-page pt-0 bg-gray-50 page-pb"
          style={{ paddingBottom: 140 }}
        >
          <Loading className="py-12" />
        </div>
      </Layout>
    );
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <Layout>
        <SEO
          title={`${get(fishery, "stateAbbr", "")} ${get(
            fishery,
            "category",
            ""
          )} Quota Marketplace - BeachPrice`}
        />
        <div className="gs-page pt-0 bg-gray-50 page-pb md:pb-24">
          <div className="container px-2 lg:px-0">
            <Header data={fishery} state={state} dispatch={dispatch} />
            <TabContent activeTab={state.activeTab}>
              <TabPanel tabId="tab1">
                <Auctions
                  data={activeList}
                  loading={auctionLoading}
                  image={logoImage}
                />
                <QuotaMarkets
                  data={leaseMarkets}
                  loading={leaseMarketsloading}
                  image={logoImage}
                  fisheryId={id}
                />
              </TabPanel>
              <TabPanel tabId="tab2">Sell....</TabPanel>
            </TabContent>
          </div>
        </div>
      </Layout>
    </CurrentUserContext.Provider>
  );
};

export default Show;

export const OFFER_DELETE = "offers/delete_v2";
export const OFFER_DELETE_REQUEST = `${OFFER_DELETE}_request`;
export const OFFER_DELETE_SUCCESS = `${OFFER_DELETE}_success`;
export const OFFER_DELETE_FAILURE = `${OFFER_DELETE}_failure`;
export const OFFER_DELETE_RECEIVED = `${OFFER_DELETE}_received`;

export const offerDeleteAction = {
  request: (payload) => {
    return {
      type: OFFER_DELETE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: OFFER_DELETE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: OFFER_DELETE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: OFFER_DELETE_RECEIVED
    }
  },
};

import get from "lodash/get";
import {
  OFFER_PRICES_FETCH_REQUEST,
  OFFER_PRICES_FETCH_SUCCESS,
  OFFER_PRICES_FETCH_FAILED,

  OFFER_PRICES_ANIMATE_START,
  OFFER_PRICES_ANIMATE_END,

} from "./listAction";
export const offerPriceListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case OFFER_PRICES_FETCH_REQUEST:
      return {
        loading: true,
        data: {},
        error: null,
        showAnimate: false,
      };
      break;

    case OFFER_PRICES_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
        showAnimate: false,
      };
      break;

    case OFFER_PRICES_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
        showAnimate: false,
      };
      break;

    // case OFFER_PRICES_ANIMATE_START:
    //   return {
    //     loading: false,
    //     data: [].concat(state.data.entries),
    //     error: false,
    //     showAnimate: true,
    //   };
    //   break;

    // case OFFER_PRICES_ANIMATE_END:
    //   return {
    //     loading: false,
    //     data: [].concat(state.data.entries),
    //     error: false,
    //     showAnimate: false,
    //   };
    //   break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
  showAnimate: false,
};
import axios from "axios";
import { takeLatest, takeEvery, all, call, put, select, delay } from "redux-saga/effects";
import moment from "moment";
import get from "lodash/get";

import {
  AUCTIONS_SHOW_REQUEST,
  AUCTIONS_SHOW_LOADING,
  AUCTIONS_SHOW_SUCCESS,
  AUCTIONS_SHOW_FAILED,
  AUCTIONS_SHOW_WAIT_RULING,
  AUCTIONS_SHOW_END,
  fetchAuction
} from "./showAction";

import { fetchBids } from "../bids/listAction";
import { auctionsAPI } from "@config/api";

export function* watchAuctionsShowFetch() {
  yield takeEvery(AUCTIONS_SHOW_REQUEST, auctionsShowFetch);
}

export function* auctionsShowFetch(action) {
  yield put({ type: AUCTIONS_SHOW_LOADING });
  const { err, res } = yield call(auctionsShowFetchApi, action.payload);
  if (!res) {
    yield put({
      type: AUCTIONS_SHOW_FAILED,
      error: "Get auction failed."
    });
    return;
  }
  yield put({ type: AUCTIONS_SHOW_SUCCESS, data: res });
  const isEnd = moment().isAfter(get(res, "expire_at"));
  if (isEnd) {
    yield put(fetchBids({ id: res.id, params: { unique: true } }));
  }
}

export function auctionsShowFetchApi(payload) {
  return axios
    .get(auctionsAPI(payload.id))
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

export function* watchAuctionsFinished() {
  yield takeLatest(AUCTIONS_SHOW_WAIT_RULING, auctionsFinished);
}

function* auctionsFinished() {
  yield delay(10 * 1000);
  const getAuctionsShowStatus = state => state.auctionsShowStatus;
  const { data } = yield select(getAuctionsShowStatus);
  if (get(data, "waitRuling")) {
    yield all([
      // put({ type: AUCTIONS_SHOW_END }),
      put(fetchAuction({ id: get(data, "id") })),
      put(fetchBids({ id: get(data, "id"), params: { unique: true } }))
    ]);
  }
}

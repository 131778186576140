export const UPDATE_ATTRIBUTE = "update_attribute";
const UPDATE_ATTRIBUTE_REQUEST = "update_attribute/request";
const UPDATE_ATTRIBUTE_LOADING = "update_attribute/loading";
const UPDATE_ATTRIBUTE_SUCCESS = "update_attribute/success";
const UPDATE_ATTRIBUTE_FAILED = "update_attribute/failed";

function updateAttributeRequest(payload) {
  return {
    type: UPDATE_ATTRIBUTE_REQUEST,
    payload
  };
}

function updateAttributeLoading() {
  return {
    type: UPDATE_ATTRIBUTE_LOADING
  };
}

function updateAttributeSuccess(data) {
  return {
    type: UPDATE_ATTRIBUTE_SUCCESS,
    data
  };
}

function updateAttributeFailed(error) {
  return {
    type: UPDATE_ATTRIBUTE_FAILED,
    error
  };
}

export {
  UPDATE_ATTRIBUTE_FAILED,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE_LOADING,
  UPDATE_ATTRIBUTE_REQUEST,
  updateAttributeFailed,
  updateAttributeLoading,
  updateAttributeRequest,
  updateAttributeSuccess
};

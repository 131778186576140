import { takeLatest, call, put } from "redux-saga/effects";

import { BidApiUsecase } from "@core";
import { BID_ORDER_REQUEST, bidOrderAction } from "./action";

// post buyer order with bid & market(lease)
export function* watchMarketBidOrderPost() {
  yield takeLatest(BID_ORDER_REQUEST, bidOrderPost);
}

function* bidOrderPost(action) {
  try {
    const usecase = new BidApiUsecase();
    const res = yield call([usecase, "order"], action.payload);
    yield put(bidOrderAction.success(res.data));
  } catch (err) {
    yield put(bidOrderAction.failure(err));
  }
}

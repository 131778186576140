import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import capitalize from "lodash/capitalize";
import includes from "lodash/includes";
import { unitOfSaleMap } from "@core";
import numeral from "@utils/numeral";
import { SeasonEntity } from "./seasonEntity";
import { CategoryEntity } from "./categoryEntity";
import { FisheryEntity } from "./fisheryEntity";
import { QuotumEntity } from "./quotumEntity";
import pluralize from "pluralize";
import compact from "lodash/compact";
/**
 * [Entity] Market
 data is obj
  {
    "id": 1,
    "active": true,
    "title": "some words words",
    "sell_type_extend": "Encumbered" | "Unencumbered"
    "unit_of_sale": "kg" | "blocks"
    "fishing_season": {
      "id": 1,
      "kg_per_quota": "308.46",
      "start_at": "2020-01-01T00:00:00+11:00",
      "end_at": "2020-12-31T23:59:59+11:00"
    },
    "quota": {
      "id": 1,
      "unit_name": "pots",
      "category": {
        "id": 4,
        "name": "Abalone"
      },
      "species": {
        "id": 1,
        "name": "This is a abalone spec"
      },
      "country": {
        "id": 1,
        "name": "Australia",
        "abbr": "AU"
      },
      "state": {
        "id": 1,
        "name": "Victoria",
        "abbr": "VIC"
      },
      "zone": {
        "id": 1,
        "name": "West",
        "abbr": "wt"
      }
    },
    "warning": "",
    "warning_title": "",
  }
 */
export class MarketEntity {
  constructor(data) {
    this.entity = data;
  }

  /**
   * 根据 unitOfSale 转化 quantity 数据
   * @param {Number|String} quantity
   */
  generateData(quantity) {
    const result = {
      units: 0,
      amount: 0,
    };
    let units = 0;
    let amount = 0;
    const kgPerUnit = get(this.season, "kgPerUnit");
    if (this.unitOfPrice === unitOfSaleMap.kg) {
      if (this.isSaleByKg) {
        units =
          kgPerUnit && kgPerUnit > 0
            ? numeral(quantity).divide(kgPerUnit).value()
            : numeral(quantity).value();
        amount = numeral(quantity).value();
      }
      if (this.isSaleByUnit) {
        units = numeral(quantity).value();
        amount =
          kgPerUnit && kgPerUnit > 0
            ? numeral(quantity).multiply(kgPerUnit).value()
            : 0;
      }
    } else {
      if (this.isSaleByUnit) {
        units = numeral(quantity).value();
        amount = numeral(quantity).value();
      }
    }

    result.units = units;
    result.amount = amount;
    result.unitName = this.isSaleByKg
      ? pluralize(this.unitNameCondition || "", 1)
      : ` ${pluralize(this.unitName || "", Number(units || 0))}`;
    result.unitsStr = `${numeral(units).format("0,0")}${result.unitName}`;
    result.amountStr = `${numeral(amount).format("0,0.00")}${
      this.isKgPrice
        ? pluralize(this.unitOfPrice || "", 1)
        : ` ${pluralize(this.unitOfPrice || "", Number(amount || 0))}`
    }`;
    result.kgPerUnit = kgPerUnit
      ? `${numeral(kgPerUnit).format("0,0.00")}kg`
      : "";
    return result;
  }

  /**
   * 根据传递的数量显示数据
   */
  quantityShow(quantity) {
    if (this.unitOfSale === unitOfSaleMap.kg) {
      return `${quantity}kg`;
    }
    const kgPerUnit = get(this.season, "kgPerUnit");
    const amount = numeral(quantity).multiply(kgPerUnit).format("0,0.00");
    return `${quantity} ${this.unitName} / ${amount} kg`;
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get normalTitle() {
    return get(this.entity, "title", "");
  }

  get sellTypeExtend() {
    return get(this.entity, "sell_type_extend", "");
  }

  get unitOfSale() {
    return get(this.entity, "unit_of_sale", ""); // kg | blocks(即unit)
  }

  get isSaleByKg() {
    return this.unitOfSale === unitOfSaleMap.kg;
  }

  get isSaleByUnit() {
    return this.unitOfSale === unitOfSaleMap.unit;
  }

  get lowestPrice() {
    return numeral(get(this.entity, "lowest_seller_offer.price_cents", 0))
      .divide(100)
      .value();
  }

  get lowestPriceStr() {
    return numeral(this.lowestPrice).format();
  }

  get lowestPriceStrWithKg() {
    return this.lowestPriceStr + "/kg";
  }

  get highestPrice() {
    return numeral(get(this.entity, "highest_buyer_bids.price_cents", 0))
      .divide(100)
      .value();
  }

  get highestPriceStr() {
    return numeral(this.highestPrice).format();
  }

  get highestPriceStrWithKg() {
    if (this.highestPrice) {
      return this.highestPriceStr + "/kg";
    }
    return "";
  }

  get isActive() {
    return get(this.entity, "active", false);
  }

  get location() {
    const result = [];
    result.push(`${get(this.quota, "state.abbr", "")}`);
    const zone = get(this.quota, "zone.name", "");
    if (zone) {
      result.push(`${zone} Zone`);
    }
    return result.join(" ");
  }

  get title() {
    if (this.normalTitle) {
      return this.normalTitle;
    }
    return `${this.location} ${this.specName}`;
  }

  get titleWithBr() {
    if (this.normalTitle) {
      return this.normalTitle;
    }
    return `${this.location}<br/>${this.specName}`;
  }

  get titleWithStateAbbr() {
    if (this.normalTitle) {
      return this.normalTitle;
    }
    const result = [];
    result.push(`${get(this.quota, "state.abbr", "")}`);
    const zone = get(this.quota, "zone.name", "");
    if (zone) {
      result.push(`${zone} Zone`);
    }
    result.push(this.specName);
    result.push(capitalize(this.marketType));
    return compact(result).join(" ");
  }

  get url() {
    return `/leases/${this.id}`; // => "/leases/2"
  }

  // get urlBuy() {
  //   return `${this.url}/buy`; // => "/au/m/some-string-names/2/buy"
  // }

  // get urlSell() {
  //   return `${this.url}/sell`; // => "/au/m/some-string-names/2/sell"
  // }

  get urlTransferQuota() {
    return `${this.url}/transfer_quota`; // => "/au/m/some-string-names/2/transfer_quota"
  }

  get urlAllocatedQuota() {
    return `${this.url}/allocated_quota`; // => "/au/m/some-string-names/2/allocated_quota"
  }

  get urlCategory() {
    const country = lowerCase(get(this.quota, "country.abbr", ""));
    return this.category.url(country); // => "/au/c/Abalone/2" 2 是 category id
  }

  get urlStateCategory() {
    const country = lowerCase(get(this.quota, "country.abbr", ""));
    const state = lowerCase(get(this.quota, "state.abbr", ""));
    const stateId = lowerCase(get(this.quota, "state.id", ""));
    return this.category.url(country, state, stateId); // => "/au/c/Abalone/tas/6" 6 是state id
  }

  get urlSearchCategory() {
    const country = lowerCase(get(this.quota, "country.abbr", ""));
    const categoryId = lowerCase(get(this.quota, "category.id", ""));

    return `/${country}/result?category_id=${categoryId}`;
  }

  get urlSearchState() {
    const country = lowerCase(get(this.quota, "country.abbr", ""));
    const stateId = lowerCase(get(this.quota, "state.id", ""));

    return `/${country}/result?state_id=${stateId}`;
  }

  // TODO get latest offer price from quota
  get price() {
    return `$48.10`;
  }

  get imageUrl() {
    const imgName = `categories/${lowerCase(
      get(this.quota, "category.name", "")
    )}`;
    return imgName.replace(/\s/g, "_");
  }

  // 以下是 relation
  get quota() {
    return get(this.entity, "quota") || {};
  }

  get quotum() {
    return new QuotumEntity(this.quota);
  }

  //显示quotum name
  get quotumName() {
    return this.quotum.name;
  }

  get quotaId() {
    return get(this.quota, "id", "");
  }

  get unitName() {
    return get(this.quota, "unit_name", "");
  }

  get unitNameCondition() {
    if (this.isSaleByUnit) {
      return pluralize(get(this.quota, "unit_name") || "", 1);
    }
    return "kg";
  }

  get specName() {
    return get(this.quota, "species.name", "");
  }

  get season() {
    return new SeasonEntity(get(this.entity, "fishing_season") || {});
  }

  get seasonStr() {
    return get(this.season, "title", "");
  }

  get category() {
    return new CategoryEntity(get(this.quota, "category") || {});
  }

  get stateId() {
    return get(this.quota, "state.id", "");
  }

  get stateName() {
    return get(this.quota, "state.name", "");
  }

  get stateAbbr() {
    return get(this.quota, "state.abbr", "");
  }

  get isQLD() {
    return this.stateAbbr === "QLD";
  }

  // 以下是 quota_markets

  get lowestSellerOffer() {
    return get(this.entity, "lowest_seller_offer", {});
  }

  get highestBuyerBids() {
    return get(this.entity, "highest_buyer_bids", {});
  }

  get lowestBuyerOrder() {
    return get(this.entity, "lowest_buyer_order", {});
  }

  get highestSellerOrder() {
    return get(this.entity, "highest_seller_order", {});
  }

  getPriceByType(priceType, isValue) {
    const cents = get(this.entity, `${priceType}.price_cents`, 0);
    if (!cents) {
      return 0;
    }
    if (isValue) {
      return numeral(cents).divide(100).value();
    }
    return numeral(cents).divide(100).format();
  }

  get stickLowestSellerPrice () {
    return get(this.entity, "quota_market_stick_lowest_seller_offer_price", 0);
  }

  get trends() {
    return get(this.entity, "trend", []);
  }

  get trending() {
    return {
      value: numeral(get(this.entity, "trending.value", 0)).value(),
      percent: get(this.entity, "trending.percent", 0),
    };
  }

  trendingByType(type) {
    return {
      value: numeral(get(this.entity, `${type}.value`, 0)).value(),
      percent: get(this.entity, `${type}.percent`, 0),
    };
  }

  get ticker() {
    return get(this.entity, "ticker", "") || "";
  }

  get lastTradePriceStr() {
    return get(this.entity, "last_traded_price.price_with_symbol", "");
  }

  //以下是fishery
  get fishery() {
    return new FisheryEntity(get(this.entity, "fishery", {}) || {});
  }

  get fisheryId() {
    return get(this.fishery, "id", "") || get(this.entity, "fishery_id", "");
  }

  get fisheryUrl() {
    const params = { activeTab: "leaseMarket", leaseMarketId: this.id };
    return this.fishery.getUrl(params);
  }

  get description() {
    return get(this.entity, "description", "");
  }

  get warningContent() {
    return get(this.entity, "warning", "");
  }

  get warningTitle() {
    return get(this.entity, "warning_title", "");
  }

  get marketType() {
    return get(this.entity, "market_type", "");
  }

  get isPermanent() {
    return this.marketType === "permanent";
  }

  get unitOfPrice() {
    const price = get(this.entity, "unit_of_price", "") || "kg";
    if (price === unitOfSaleMap.unit) {
      return pluralize(get(this.quota, "unit_name"), 1);
    }
    return pluralize(price, 1);
  }

  get isKgPrice() {
    return this.unitOfPrice === "kg";
  }

  get showTermsAndConditions() {
    return get(this.entity, "show_terms_and_conditions", false);
  }

  get invoiceActive() {
    return get(this.entity, "invoice_active", false);
  }

  get saleTypes() {
    const types = get(this.entity, "fishery_sale_types", []) || [];
    if (includes(types, "exchange") && includes(types, "auction")) {
      return "both";
    }
    if (includes(types, "exchange")) {
      return "exchange";
    }
    if (includes(types, "auction")) {
      return "auction";
    }
    return "";
  }
}

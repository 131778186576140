import { useState, useEffect, useCallback } from "react";
import { submit, formValueSelector, arrayPush, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import sumBy from "lodash/sumBy";
import find from "lodash/find";
import compact from "lodash/compact";

//Core Method
import { LoadingSelector, unitOfSaleMap } from "@core";
import Notice from "@utils/noticeNew";
import numeral from "@utils/numeral";
import {
  permanentNewAction,
  PERMANENT_NEW,
} from "@redux/modules_v2/permanent/new/action";

//Hooks
import { useFisheryList, useDeepEffect, useFisheryQuotaList } from "@hooks";

/**
 * [hooks] create new permanents listing
 */
export const useCreatePermanent = ({ toggleConfirmModal, type }) => {
  const dispatch = useDispatch();
  const selector = formValueSelector("PermannentNewForm");
  const [quotaParams, setQuotaParams] = useState("");
  const { options, data } = useFisheryList({});
  const [quotaList, quotaListLoading] = useFisheryQuotaList(quotaParams);
  const [isLicence, setLicence] = useState(false);
  // Declare your memoized mapState function
  const mapState = useCallback(
    (state) => ({
      title: selector(state, "title"),
      markets_attributes: selector(state, "markets_attributes"),
      fishery_id: selector(state, "fishery_id"),
    }),
    [selector]
  );
  const { markets_attributes, title, fishery_id } = useSelector(mapState);

  //Permanent new status
  const permanentNewStatus = useSelector((state) => {
    const selector = new LoadingSelector(PERMANENT_NEW, state);
    return Object.assign({}, get(state, "permanentNewStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    title: "",
    description: "",
    expiry_days: "7",
    fishery_id: "",
    markets_attributes: [],
  });
  const [totalPrice, setTotalPrice] = useState();
  /**
   * 获取未被表单选择的quota列表
   * @param {array} fields market_attr 列表
   * @param {number|string} currentQuotumId 当前market_attr的id
   */
  const getQuotaOptions = useCallback(
    (fields, currentQuotumId) => {
      let calculatedQuotas = [];

      const fieldsQuotaIds = fields.map((_, idx) =>
        get(fields.get(idx), "quotum_id", "")
      );

      quotaList.forEach((quota) => {
        const index = findIndex(fieldsQuotaIds, (id) => id == quota.id);
        if (index === -1 || currentQuotumId == quota.id) {
          calculatedQuotas.push(quota);
        }
      });

      const options = map(calculatedQuotas, (o) => {
        let label = [
          get(o, "state.name", ""),
          get(o, "zone.name", ""),
          get(o, "species.name", ""),
        ];
        if (!isLicence) {
          label = [...label, "Quota", capitalize(get(o, "unit_name", ""))];
        }
        return {
          label: compact(label).join(" "),
          value: o.id,
        };
      });
      return options;
    },
    [quotaList, markets_attributes, isLicence]
  );

  /**
   * 提交表单
   */
  const startSubmit = () => {
    dispatch(submit("PermannentNewForm"));
  };

  /**
   * 提交表单callback
   */
  const handleSubmit = useCallback(
    (values) => {
      if (isEmpty(values.markets_attributes)) {
        Notice.failure("Markets attributes can't be blank!");
        return;
      }
      const marketsAttributes = map(values.markets_attributes, (item) =>
        Object.assign({}, item, { fish_on: get(item, "fish_on", "") === "on" })
      );
      const data = Object.assign({}, values, {
        expiry_days: Number(values.expiry_days),
        permanent_listing_type: type || "sell",
        markets_attributes: marketsAttributes,
      });
      dispatch(permanentNewAction.request(data));
    },
    [type]
  );

  /**
   * 添加默认initalValues
   * @param {array} fields market_attr 列表
   */
  const addNewMarketsAttribute = useCallback(
    (fields) => {
      const options = getQuotaOptions(fields);
      if (isEmpty(options)) {
        Notice.failure("Not quota available");
        return;
      }
      dispatch(
        arrayPush("PermannentNewForm", "markets_attributes", {
          price: "",
          quantity: "",
          quotum_id: get(options, "[0][value]", ""),
          unit_of_sale: unitOfSaleMap.unit,
          fish_on: "",
        })
      );
    },
    [quotaList]
  );

  /**
   * 从quota options 中根据id获取unit name
   */
  const getUnitNameByQuotumId = useCallback(
    (currentQuotumId) => {
      const index = findIndex(
        quotaList,
        (quota) => currentQuotumId == quota.id
      );
      return get(quotaList, `[${index}].unit_name`, "");
    },
    [quotaList]
  );

  /**
   * 判断 unitOfSale 返回 unit
   */
  const unitNameCondition = useCallback(
    (currentQuotumId, unitOfSale) => {
      const isSaleByUnit = unitOfSale === unitOfSaleMap.unit;
      if (isSaleByUnit) {
        return getUnitNameByQuotumId(currentQuotumId);
      }
      return "kg";
    },
    [quotaList]
  );

  /**
   * 判断 fishery fishery type 是否是 licence
   */
  const getCurrentLicence = useCallback(
    (fisheryId) => {
      const currentFishery = find(data, (o) => fisheryId == o.id);
      const currentFisheryType = get(currentFishery, "fisheryType", "");
      return currentFisheryType === "licence";
    },
    [data]
  );

  /**
   * 初始化增加一个默认market_attribute
   */
  useDeepEffect(() => {
    if (!isEmpty(options) && !initialValues.fishery_id) {
      setInitalValues(
        Object.assign({}, initialValues, {
          fishery_id: get(options, "[0].value", ""),
        })
      );
    }
  }, [options, initialValues]);

  useDeepEffect(() => {
    if (isEmpty(quotaList)) {
      setInitalValues(
        Object.assign({}, initialValues, {
          fishery_id: fishery_id ? fishery_id : initialValues.fishery_id,
          markets_attributes: [],
        })
      );
      return;
    }
    setInitalValues(
      Object.assign({}, initialValues, {
        fishery_id: fishery_id ? fishery_id : initialValues.fishery_id,
        markets_attributes: [
          {
            quotum_id: get(quotaList, "[0].id", ""),
            unitName: get(quotaList, "[0].unit_name", ""),
            unit_of_sale: unitOfSaleMap.unit,
            fish_on: "",
          },
        ],
      })
    );
  }, [quotaList, initialValues, fishery_id]);

  /**
   * 根据market_attribute自动生成title
   */
  useDeepEffect(() => {
    if (!isEmpty(markets_attributes)) {
      const quotas = filter(
        quotaList,
        (quota) =>
          findIndex(markets_attributes, (attr) => attr.quotum_id == quota.id) >
          -1
      );
      const names = map(quotas, (quota) => {
        let label = [
          get(quota, "state.name", ""),
          get(quota, "zone.name", ""),
          get(quota, "species.name", ""),
        ];
        if (!isLicence) {
          label = [...label, "Quota", capitalize(get(quota, "unit_name", ""))];
        }

        return compact(label).join(" ");
      });
      dispatch(change("PermannentNewForm", "title", names.join(" And ")));
    }
  }, [quotaList, markets_attributes, isLicence]);

  /**
   * 根据market_attribute自动计算总价
   */
  useEffect(() => {
    const sumPrice = sumBy(
      markets_attributes,
      (attr) => Number(attr.price) * Number(attr.quantity)
    );
    setTotalPrice(numeral(sumPrice).format());
  }, [markets_attributes]);

  /**
   * 根据fishery改变获取quota list
   */
  useEffect(() => {
    fishery_id && setQuotaParams(fishery_id);
  }, [fishery_id]);

  /**
   * 根据fishery改变获取当前的fisherytype是否为licence
   */
  useEffect(() => {
    setLicence(getCurrentLicence(fishery_id));
  }, [data, fishery_id]);

  /**
   * 创建permanent 后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(permanentNewAction.reset());
    };
  }, []);
  useEffect(() => {
    if (!permanentNewStatus.loading) {
      if (permanentNewStatus.data) {
        // Notice.success("You have successfully posted a new permanent listing.");
        // history && history.goBack();
        typeof toggleConfirmModal === "function" && toggleConfirmModal();
      }
      if (permanentNewStatus.error) {
        Notice.failure(permanentNewStatus.error);
      }
    }
  }, [permanentNewStatus.loading]);

  return {
    addNewMarketsAttribute,
    getQuotaOptions,
    getUnitNameByQuotumId,
    unitNameCondition,
    handleSubmit,
    initialValues,
    setInitalValues,
    startSubmit,
    title,
    totalPrice,
    fisheryOptions: options,
    loading: permanentNewStatus.loading,
    quotaListLoading,
    isLicence,
  };
};

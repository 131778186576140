import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { useResponsive } from "@hooks";

/**
 * [COMPONENT] Panel
 * @param {Object}
 */
const Panel = ({
  classNameWrapper,
  className,
  children,
  sharp,
  hideOnMobile,
  ...props
}) => {
  const { isMobile } = useResponsive();

  if (hideOnMobile && !isMobile) {
    return <div>{children}</div>;
  }
  return (
    <div
      className={cx("gs-panel-v2 bg-white shadow-sm", classNameWrapper, {
        "rounded-lg-tw": !sharp,
      })}
      {...props}
    >
      <div
        className={cx(className, {
          "rounded-lg-tw": !sharp,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Panel.propTypes = {
  classNameWrapper: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  sharp: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
};

Panel.defaultProps = {
  classNameWrapper: "",
  className: "",
};

export default Panel;

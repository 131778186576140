import {
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAILURE,
} from "./action";

export const accountNotificationListStatus = (state = initStatus, action = {}) => {

  switch (action.type) {
    case NOTIFICATION_LIST_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case NOTIFICATION_LIST_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: [],
  error: null,
};
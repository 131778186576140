
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import Airtable from "airtable";
import { config } from "@config/airtable";
import {
    SUBSCRIBE_REQUEST,
    SUBSCRIBE_LOADING,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAILED,
} from "./action";

//initialize airtable
Airtable.configure({
  apiKey: config.api_key
});

const base = Airtable.base(config.base_name);
const table = base(config.table_name);

// create quote
export function* watchsubscribe() {
  yield takeEvery(SUBSCRIBE_REQUEST, subscribe);
}

export function* subscribe(action) {
    yield put({ type: SUBSCRIBE_LOADING });
    const { err, res } = yield call(
      subscribeApi,
      action.payload
    );

    if (!res) {
      yield put({
        type: SUBSCRIBE_FAILED,
        error: "subscribe failed."
      });
      return;
    }
    yield put({ type: SUBSCRIBE_SUCCESS, data: res });
  }


function subscribeApi(payload) {
    return table
      .create(payload)
      .then(() => ({
        res: "success"
      }))
      .catch(err => ({
        err
      }));
  }

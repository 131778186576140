import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

/**
 * import hooks
 */
import { useAuctionWaitRuling, useModal, useLimitedList, useAuctionList,   useAuctionSimilarList, } from "@hooks";

/**
 * import local component
 */
import { ActionCableWrapper, Loading } from "@components";

import Header from "./_header";
import Attrs from "./_attrs";
import MaxBid from "./_max_bid";
import BidHistory from "./_bid_history";
import BidHisotryModal from "./_bid_history_modal";
import WaitingRule from "./_waiting_rule";
import TimeCountdown from "./_time_countdown";
import AuctionForm from "../form";
import Chart from "./_pastAuctionChart";
import Divider from "./_divider";
import SimilarAuctions from "./_similar_auctions";

/**
 * [Page] Market show
 */
const AuctionShow = ({
  auctionId,
  auctionLastChance,
  auctionWaitRuling,
  bidList,
  bidAllList,
  bidListloading,
  bidAllListloading,
  currentUserStatus,
  data,
  fetchAuctionShow,
  fetchBidList,
  fetchBidAllList,
  finalBidLoading,
  finalBidPrice,
  fisheryId,
  hasFinalAccepted,
  hasFinalBid,
  loading,
  logo,
  onAuctionReceived,
  setAuctionLastChance,
  setAuctionWaitRuling,
  state,
}) => {
  const {
    activeList,
    pastList,
    unapprovedList,
    pastAuctionLoading: pastAuctionLoading,
  } = useAuctionList({
    expired: true,
    quota_market_id: get(data, "show.quotaMarketId", ""),
  });

  const [auctionSub, setAuctionSub] = useState(null);
  const currentUserId = get(data, "user.currentUserId", "");
  const isLogin = get(data, "user.login", false);
  const countdownRef = useRef(null);
  const bidHistoryModalProps = useModal();
  const [limitedBidList] = useLimitedList(bidList, 8);
  const [limitedPastAuctionList] = useLimitedList(pastList, 8);
  const { list } = useAuctionSimilarList(auctionId);

  const showTermsAndConditions = get(
    data,
    "show.showTermsAndConditions",
    false
  );
  const bidCountStr = get(data, "show.bidCountStr", "");

  useAuctionWaitRuling({
    id: auctionId,
    auctionWaitRuling,
    setAuctionWaitRuling,
    setAuctionLastChance,
  });

  const renderActionCable = () => {
    if (!auctionId) {
      return null;
    }
    return (
      <ActionCableWrapper
        channel={{
          channel: "AuctionChannel",
          id: auctionId,
          user_id: currentUserId || "guest",
          fishery_id: fisheryId,
        }}
        onReceived={(obj) => onAuctionReceived(obj)}
        getSubscription={() => auctionSub}
        setSubscription={(sub) => setAuctionSub(sub)}
      />
    );
  };

  useEffect(() => {
    if (auctionId) {
      setAuctionSub(null);
    }
  }, [auctionId]);

  useEffect(() => {
    return () => {
      setAuctionLastChance(false);
      setAuctionWaitRuling(false);
    };
  }, []);

  useEffect(() => {
    if (!state.auctionWaitRuling) {
      countdownRef.current && countdownRef.current.handleStartClick();
    }
  }, [state.auctionWaitRuling]);

  if (loading) {
    return (
      <div className="px-4 lg:px-6 py-5">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="p-4 lg:p-10">
        <div className="lg:relative pt-2 lg:pt-0 lg:top-auto z-40 lg:z-auto">
          <Header data={data} logo={logo} auctionId={auctionId}>
            <TimeCountdown
              data={data}
              auctionLastChance={auctionLastChance}
              state={state}
              setAuctionLastChance={setAuctionLastChance}
              setAuctionWaitRuling={setAuctionWaitRuling}
              countdownRef={countdownRef}
            />
          </Header>
        </div>
        <div>
          <MaxBid
            data={data}
            finalBidPrice={finalBidPrice}
            hasFinalAccepted={hasFinalAccepted}
            className="mt-6 lg:mt-8"
          >
            <div className="lg:hidden">
              <AuctionForm
                currentUserStatus={currentUserStatus}
                data={data}
                fetchAuctionShow={fetchAuctionShow}
                fetchBidList={fetchBidList}
                finalBidLoading={finalBidLoading}
                hasFinalBid={hasFinalBid}
                loading={loading}
                setAuctionLastChance={setAuctionLastChance}
                setAuctionWaitRuling={setAuctionWaitRuling}
                state={state}
              />
            </div>
          </MaxBid>
        </div>
        <Divider className="my-6" />
        <div className="">
          <div className="flex items-center justify-between mb-4">
            <div className="font-medium">About this auction</div>
          </div>
          <Attrs data={data} auctionId={auctionId} />
        </div>
        <Divider className="mt-8 mb-6" />
        <div className="">
          <div className="flex items-center justify-between mb-4">
            <div className="font-medium space-x-2">
              <span>Bids history</span>
              {bidCountStr ? (
                <span className="text-blue-gray-500 text-xxs px-2 py-1 bg-blue-gray-100 rounded-2xl">
                  {bidCountStr}
                </span>
              ) : null}
            </div>
            <button
              className="text-sm font-medium text-blue-gray-400 cursor-pointer"
              onClick={() => {
                bidHistoryModalProps.toggleModal();
                fetchBidAllList(auctionId);
              }}
            >
              View all
            </button>
          </div>
          <BidHistory
            data={data}
            list={limitedBidList}
            loading={bidListloading}
            isLogin={isLogin}
          />
        </div>

        {get(data, "show.description", "") && (
          <div className="">
            <Divider className="mt-8 mb-6" />
            <div className="flex items-center justify-between">
              <div className="font-medium">Description</div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: get(data, "show.description", ""),
              }}
              className="pt-4 pb-4 lg:pb-6 text-sm text-blue-gray-700"
            />
          </div>
        )}

        <Chart
          list={limitedPastAuctionList}
          loading={pastAuctionLoading}
          isLogin={isLogin}
        />

        {showTermsAndConditions && (
          <div className="">
            <Divider className="mt-8 mb-6" />
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-blue-gray-900">Terms and Conditions</h3>
            </div>
            <div className="mb-4 lg:mb-6 space-y-2">
              <p>
                If you are the successful Bidder, you will be bound by the
                contract Terms & Conditions. Full contract and Terms &
                Conditions <strong>HERE</strong>. You must read and acknowledge
                your understanding in order to proceed. In addition, you agree
                to the following:
              </p>
              <p>
                1. All Bidders must be the registered BeachPrice Pty Ltd account
                holder (no 3rd party bids).
              </p>
              <p>
                2. All Bidder’s must provide a valid credit card for
                pre-approval of $250 (similar to a car rental pre-approval).
                This is only to discourage insincere bidding. If you are not
                successful, the pre-approval is automatically cancelled. The
                winning Bidder’s pre-approval will be refunded upon signing of
                the contract.
              </p>
              <p>
                3. All Bidders understand that if they are the winner of the
                auction, they are required to make the deposit amount as
                described in the Auction Description Details section to the
                Agent within 72 hours of the end of the auction. Failure to do
                so will result in the forfeit of the deposit, and permanent
                de-platforming from the site.
              </p>
              <p>
                4. The winning Bidder agrees to Pro Fishing Queensland acting as
                the Agent for purposes of contract subsequent to a successful
                auction; including as deposit holder.
              </p>
            </div>
          </div>
        )}
        <SimilarAuctions data={list} logo={logo} />
        {renderActionCable()}
        <WaitingRule auctionWaitRuling={auctionWaitRuling} />
        <BidHisotryModal
          {...bidHistoryModalProps}
          body={
            <BidHistory
              data={data}
              list={bidAllList}
              loading={bidAllListloading}
              isLogin={isLogin}
            />
          }
        />
      </div>
    </div>
  );
};

AuctionShow.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  title: PropTypes.string,
  auctionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAuctionReceived: PropTypes.func,
  bidList: PropTypes.array,
  bidAllList: PropTypes.array,
  auctionLastChance: PropTypes.bool,
  auctionWaitRuling: PropTypes.bool,
  setAuctionLastChance: PropTypes.func,
  setAuctionWaitRuling: PropTypes.func,
  setAuctionId: PropTypes.func,
  state: PropTypes.object,
  fisheryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bidListloading: PropTypes.bool,
  bidAllListloading: PropTypes.bool,
  finalBidPrice: PropTypes.string,
  hasFinalAccepted: PropTypes.bool,
  finalBidLoading: PropTypes.bool,
  finalBidQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AuctionShow;

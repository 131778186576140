import React, { Component } from "react";

/**
 * import lib tools
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { reduxForm, Field } from "redux-form";

import { renderTextField, required } from "@components/reduxform";

class VerifyForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="account-form">
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="flex-1">
              <Field
                id="code"
                label="Verification Code"
                name="code"
                type="text"
                placeholder="Enter the code sent to your email"
                validate={required}
                component={renderTextField}
                align="left"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return Object.assign({}, bindActionCreators({}, dispatch), {
    dispatch,
  });
}

const VerifyFormWithReduxForm = reduxForm({
  form: "VerifyForm",
})(VerifyForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyFormWithReduxForm);

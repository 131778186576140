export const PERMANENT_MARKET_GROUPS = "permanent/market_groups";
export const PERMANENT_MARKET_GROUPS_REQUEST = `${PERMANENT_MARKET_GROUPS}_request`;
export const PERMANENT_MARKET_GROUPS_SUCCESS = `${PERMANENT_MARKET_GROUPS}_success`;
export const PERMANENT_MARKET_GROUPS_FAILURE = `${PERMANENT_MARKET_GROUPS}_failure`;

export const permanentMarketGroupsAction = {
  request: (payload) => {
    return {
      type: PERMANENT_MARKET_GROUPS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: PERMANENT_MARKET_GROUPS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: PERMANENT_MARKET_GROUPS_FAILURE,
      error,
    }
  },
};

import { takeLatest, call, put } from "redux-saga/effects";

import { MarketApiUsecase } from "@core";
import { MARKET_STATISTICS_REQUEST, marketStatisticsAction } from "./action";

// fetch market(lease) statistics
export function* watchMarketStatisticsFetch() {
  yield takeLatest(MARKET_STATISTICS_REQUEST, marketStatisticsFetch);
}

function* marketStatisticsFetch(action) {
  try {
    const usecase = new MarketApiUsecase();
    const res = yield call([usecase, "statistics"], action.payload);
    yield put(marketStatisticsAction.success(res.data));
  } catch (err) {
    yield put(marketStatisticsAction.failure(err));
  }
}

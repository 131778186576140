import { takeEvery, all, call, put } from "redux-saga/effects";
import { updateCurrentUserAttributes } from "@utils/cognitoAuth";

import {
  UPDATE_ATTRIBUTE_REQUEST,
  updateAttributeSuccess,
  updateAttributeLoading,
  updateAttributeFailed,
} from "./action";
import { refreshCurrentUser } from "../auth/action";

function* watchUpdateAttributeRequest() {
  yield takeEvery(UPDATE_ATTRIBUTE_REQUEST, updateAttribute);
}

function* updateAttribute(action) {
  yield put(updateAttributeLoading());
  let { res, err } = yield call(updateAttributeCall, action.payload);
  if (err) {
    yield put(updateAttributeFailed(err));
    return;
  }
  yield all([put(updateAttributeSuccess(res)), put(refreshCurrentUser())]);
}

function updateAttributeCall(attributes) {
  return updateCurrentUserAttributes(attributes)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export { watchUpdateAttributeRequest };

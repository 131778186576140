export const POPULAR_LISTS = "popular/lists";
export const POPULAR_LISTS_REQUEST = `${POPULAR_LISTS}_request`;
export const POPULAR_LISTS_SUCCESS = `${POPULAR_LISTS}_success`;
export const POPULAR_LISTS_FAILURE = `${POPULAR_LISTS}_failure`;

export const popularListAction = {
  request: (payload) => {
    return {
      type: POPULAR_LISTS_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: POPULAR_LISTS_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: POPULAR_LISTS_FAILURE,
      error,
    }
  },
};

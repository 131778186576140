export const ACCOUNT_PERMANENT_SHOW = "account/permanent_show";
export const ACCOUNT_PERMANENT_SHOW_REQUEST = `${ACCOUNT_PERMANENT_SHOW}_request`;
export const ACCOUNT_PERMANENT_SHOW_SUCCESS = `${ACCOUNT_PERMANENT_SHOW}_success`;
export const ACCOUNT_PERMANENT_SHOW_FAILURE = `${ACCOUNT_PERMANENT_SHOW}_failure`;

export const accountPermanentShowAction = {
  request: (payload) => {
    return {
      type: ACCOUNT_PERMANENT_SHOW_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: ACCOUNT_PERMANENT_SHOW_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: ACCOUNT_PERMANENT_SHOW_FAILURE,
      error,
    }
  },
};


export const CHANNEL_MESSAGES_CREATE_POST = "channel_messages/create_post";
export const CHANNEL_MESSAGES_CREATE_LOADING = "channel_messages/create_loading";
export const CHANNEL_MESSAGES_CREATE_SUCCESS = "channel_messages/create_success";
export const CHANNEL_MESSAGES_CREATE_FAILED = "channel_messages/create_failed";
export const CHANNEL_MESSAGES_CREATE_RECEIVED = "channel_messages/create_received";
/**
 * Channel Create
 * @param  {Number} channelId
 * @param  {Object} payload
 * @return {Object}
 *
 * {
 *   type: "channel_messages/create_post",
 *   channelId: 16,
 *   payload: { ... }
 * }
 */
export function channelMessageCreatePost(channelId, payload = {}) {
  return {
    type: CHANNEL_MESSAGES_CREATE_POST,
    channelId,
    payload,
  };
}

import React from "react";
import { Formik } from "formik";

import { useAccountPasswordForm } from "@hooks/account/settings";

import { Button } from "@components";
import { Form, Input } from "@components/formik";
import Header from "@views/account/setting/_header";

const ResetPasswordForm = () => {
  const { initialValues, onSubmit, schema, loading } = useAccountPasswordForm();
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Form>
        <Header
          title="Reset password"
          sub="You can reset your password here."
          className="mb-6"
        />
        <Input
          classNameWrapper="mt-4"
          name="password_current"
          label="Your current password"
          type="password"
        />
        <Input
          classNameWrapper="mt-4"
          name="password"
          label="New password"
          type="password"
        />
        <Input
          classNameWrapper="mt-4"
          name="password_confirm"
          label="Confirm new password (type it again)"
          type="password"
        />
        <div className="py-8">
          <Button
           size="xl"
           color="fish-primary"
           textColor="white"
           className="hover:bg-fish-primary-700"
           type="submit"
            disabled={loading}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;

import { useState, useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { WalletUsecase, LoadingSelector } from "@core";
import {
  walletEntriesAction,
  WALLET_ENTRIES,
} from "@redux/modules_v2/wallets/entries/action";

/**
 * [hooks] 个人账户的 wallet entries 详细记录
 * @returns { entries: {}, loading: true|false }
 */
export const useWalletEntries = () => {
  const dispatch = useDispatch();
  const [currentFilter, setFilter] = useState("all");
  const [currentPage, setPage] = useState(1);

  const fetchWalletEntries = (filter = "", page = 0) => {
    const payload = {};
    const paramFilter = !!filter ? filter : currentFilter;
    if (paramFilter && paramFilter != "all") {
      payload.filter = paramFilter;
    }
    const paramPage = !!page ? page : currentPage;
    payload.page = paramPage;

    dispatch(walletEntriesAction.request(payload));
  };

  const walletEntriesStatus = useSelector(state => {
    const selector = new LoadingSelector(WALLET_ENTRIES, state);
    return Object.assign({}, get(state, "walletEntriesStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = walletEntriesStatus.loading;
  const data = walletEntriesStatus.data || {};
  const entries = data.entries || [];
  const usecase = new WalletUsecase(entries);

  /**
   * did mount
   */
  useEffect(() => {
    fetchWalletEntries();
  }, []);

  return { data, usecase, loading, fetchWalletEntries, setFilter, setPage };
};

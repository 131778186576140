import { takeLatest, call, put } from "redux-saga/effects";

import { WalletApiUsecase } from "@core";
import { WALLET_TOPUP_REQUEST, walletTopupAction } from "./action";

// wallet topup post
export function* watchWalletTopupPostV2() {
  yield takeLatest(WALLET_TOPUP_REQUEST, walletTopupPost);
}

function* walletTopupPost(action) {
  try {
    const usecase = new WalletApiUsecase();
    const res = yield call([usecase, "topup"], action.payload);
    yield put(walletTopupAction.success(res.data));
  } catch (err) {
    yield put(walletTopupAction.failure(err));
  }
}

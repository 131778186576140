import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import {
  CART_OFFER_PRICES_FETCH_REQUEST,
  CART_OFFER_PRICES_FETCH_SUCCESS,
  CART_OFFER_PRICES_FETCH_LOADING,
  CART_OFFER_PRICES_FETCH_FAILED
} from "./offerPricesAuction";

import { pendingOfferPricesAPI } from "@config/api";

import format from "./_format";

export function* watchCartOfferPricesFetch() {
  yield takeEvery(CART_OFFER_PRICES_FETCH_REQUEST, cartOfferPricesFetch);
}

export function* cartOfferPricesFetch(action) {
  yield put({ type: CART_OFFER_PRICES_FETCH_LOADING });
  const { err, res } = yield call(cartOfferPricesFetchApi, action.payload);
  if (!res) {
    yield put({
      type: CART_OFFER_PRICES_FETCH_FAILED,
      error: "Get offer prices list failed."
    });
    return;
  }
  yield put({ type: CART_OFFER_PRICES_FETCH_SUCCESS, data: format(res) });
}

export function cartOfferPricesFetchApi(payload) {
  return axios
    .get(pendingOfferPricesAPI())
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

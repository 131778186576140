export const FISHERY_STICK_LIST = "fishery/stick_list";
export const FISHERY_STICK_LIST_REQUEST = `${FISHERY_STICK_LIST}_request`;
export const FISHERY_STICK_LIST_SUCCESS = `${FISHERY_STICK_LIST}_success`;
export const FISHERY_STICK_LIST_FAILURE = `${FISHERY_STICK_LIST}_failure`;

export const fisheryStickListAction = {
  request: (payload) => {
    return {
      type: FISHERY_STICK_LIST_REQUEST,
      payload,
    };
  },
  success: (data) => {
    return {
      type: FISHERY_STICK_LIST_SUCCESS,
      data,
    };
  },
  failure: (error) => {
    return {
      type: FISHERY_STICK_LIST_FAILURE,
      error,
    };
  },
};

import { takeLatest, call, put } from "redux-saga/effects";

import { MarketApiUsecase } from "@core";
import { MARKET_BALANCE_REQUEST, marketBalanceAction } from "./action";

// fetch market(lease) balance
export function* watchMarketBalanceFetch() {
  yield takeLatest(MARKET_BALANCE_REQUEST, marketBalanceFetch);
}

function* marketBalanceFetch(action) {
  try {
    const usecase = new MarketApiUsecase();
    const res = yield call([usecase, "balance"], action.payload);
    yield put(marketBalanceAction.success(res.data));
  } catch (err) {
    yield put(marketBalanceAction.failure(err));
  }
}

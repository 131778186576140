import {
  CATEGORIES_PRODUCT_GRADES_FETCH_LOADING,
  CATEGORIES_PRODUCT_GRADES_FETCH_SUCCESS,
  CATEGORIES_PRODUCT_GRADES_FETCH_FAILED
} from "./gradeAction";

export const categoryProductGradesStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case CATEGORIES_PRODUCT_GRADES_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
    case CATEGORIES_PRODUCT_GRADES_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
    case CATEGORIES_PRODUCT_GRADES_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
    default:
      return state;
  }
};

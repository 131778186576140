import get from "lodash/get";
import keys from "lodash/keys";

import { sellType } from "@config/enum";
import toggleUnitTextV2 from "@utils/toggleUnitTextV2";
import { BidOfferEntity, generateMarket } from "./bidOfferEntity";

/**
 * 旧版订单
 */
export class OrderOldEntity {
  constructor(data) {
    this.entity = data;
    this.market = generateMarket(data);
  }

  get originId() {
    return get(this.entity, "origin_id");
  }

  get boxesCount() {
    return get(this.entity, "boxes_count", 0) || 0;
  }

  get items() {
    return get(this.entity, "items", []);
  }

  get itemsFormated() {
    const list = [].concat(this.items).map((item) => {
      const obj = {
        price_cents: get(item, "price_cents.humanized_money", 0) * 100,
        remain_quantity: get(item, "units", 0),
        marketable_type: get(this.entity, "marketable_type"),
        item_total: get(item, "item_total.humanized_money", 0),
      };
      return new BidOfferEntity(obj, this.market);
    });
    return list;

    // const list = [];

    // forEach(this.items, (item) => {
    //   list.push({
    //     unitsStr: `${item.units}`,
    //     amountStr: `${item.amount}`,
    //     priceWithCondition: get(
    //       item,
    //       "price_cents.humanized_money_with_symbol",
    //       0
    //     ),
    //     totalStr: get(item, "item_total.humanized_money_with_symbol", 0),
    //   });
    // });

    // return list;
  }

  get units() {
    if (this.originClass === "Auction") {
      return this.boxesCount;
    }
    return this.isSaleByUnit
      ? this.boxesCount
      : numeral(this.boxesCount).divide(this.unitWeight).value();
  }
  get unitsStr() {
    return `${this.units} ${toggleUnitTextV2(this.units, "units")}`;
  }

  get amount() {
    return numeral(this.totalWeight).value();
  }
  get amountStr() {
    return `${numeral(this.amount).format("0,0.00")}kg`;
  }

  get sellType() {
    return get(this.entity, "sell_type", ""); // "Lease Price Exchange" | "Assets Exchange" | "lease" | "assets"
  }

  get originClass() {
    return get(this.entity, "class", ""); // "Auction" | "AskingPrice" ｜ "OfferPrice"
  }

  get isLease() {
    return (
      this.sellType === sellType.lease ||
      (this.originClass === "Auction" && this.sellType === "lease")
    );
  }

  get isPermanent() {
    return (
      this.sellType === sellType.assets ||
      (this.originClass === "Auction" && this.sellType === "assets")
    );
  }

  get title() {
    if (
      this.originClass === "AskingPrice" ||
      this.originClass === "OfferPrice"
    ) {
      const grade = get(this.entity, "product_grade") || {};
      const product = grade.product || {};
      const isEmptyGrade = grade.name === "N/A" || grade.name === "";
      const name = `${product.state || ""} ${grade.name || ""} ${
        grade.product_type || ""
      }`;
      return isEmptyGrade ? product.quota_name : name;
    }
    if (this.originClass === "Auction") {
      return (
        get(this.market, "titleWithStateAbbr", "") ||
        get(this.entity, "title", "")
      );
    }
    return "";
  }

  get titleUrl() {
    if (this.originClass === "Auction") {
      return this.auctionUrl;
    }
    return "";
  }

  get auctionUrl() {
    if (!this.fisheryId || !this.originId) {
      return "/";
    }
    return `/auctions/${this.originId}`;
  }

  get fisheryId() {
    return get(this.market, "fishery.id");
  }

  /**
   * 是不是以 unit 整体交易， 返回 true|false
   */
  get isSaleByUnit() {
    return this.unitName != "kg";
  }

  get unitWeight() {
    return get(this.entity, "unit_weight", 1) || 1;
  }

  get totalWeight() {
    return get(this.entity, "total_weight", 1) || 1;
  }

  get stateStep() {
    return get(this.entity, "state_step", {}) || {};
  }

  get paidState() {
    return get(this.entity, "paid_state", "");
  }

  get status() {
    return {
      pending: this.paidState === "pending",
      buyerPaid: this.paidState === "buyer_paid",
      fundsReceived: this.paidState === "fund_received",
      completed: this.paidState === "completed",
    };
  }
}

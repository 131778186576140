import {
  PROFILE_FETCH_REQUEST,
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_FAILED,
  PROFILE_FETCH_LOADING,
} from "./action";

export const profileStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PROFILE_FETCH_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;
    case PROFILE_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case PROFILE_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};

import { takeLatest, call, put } from "redux-saga/effects";
import { stopSubmit } from "redux-form";
// import get from "lodash/get";

import errorString from "@utils/errorString";
import { AuctionsApiUsecase } from "@core";
import { AUCTION_NEW_REQUEST, auctionNewAction } from "./action";

export function* watchAuctionNewRequest() {
  yield takeLatest(AUCTION_NEW_REQUEST, auctionNewFetch);
}

function* auctionNewFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "new"], action.payload);
    yield put(auctionNewAction.success(res.data));
  } catch (err) {
    // const errors = get(err, "response.data.errors", {});
    yield put(auctionNewAction.failure(errorString(err)));
    yield put(
      stopSubmit("AuctionNewForm", err)
    );
  }
}

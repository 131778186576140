import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { Form, Input } from "@components/formik";
import { Button } from "@components";

const InitForgotForm = ({ onSubmit, loading }) => {
  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Form>
        <Input name="email" type="email"  placeholder="Email address" label="Email address" className="border-blue-gray-200" labelFontWeight="normal" required />
        <div className="mt-6">
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            trailingIcon={
              <svg className="mt-px pt-px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8H2M9.33333 3.33334L14 8L9.33333 3.33334ZM14 8L9.33333 12.6667L14 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            full
            color="gray-800"
            className="hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline h-42"
          >
            Send password reset email
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

InitForgotForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

InitForgotForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
};

export default InitForgotForm;

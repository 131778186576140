import React, { useCallback } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { ModalWrapper } from "@views/common";
import { Form, Input } from "@views/components/formik";
import Header from "./_header";
import Email from "./email";

import { useModal } from "@hooks";

const MailForm = ({ email }) => {
  const { isOpen, toggleModal } = useModal();
  const onClickInput = useCallback(() => {
    if (isOpen) {
      return;
    }
    toggleModal();
  }, [isOpen]);
  return (
    <div>
      <Formik>
        <Form>
          <Header
            title="Email"
            sub="Where we will primarily contact you. It won't be shared publicly."
            className="mb-6"
          />
          <div onClick={onClickInput}>
            <Input name="email" value={email} label="Email Address" readOnly />
          </div>
        </Form>
      </Formik>
      <ModalWrapper isOpen={isOpen} toggleModal={toggleModal}>
        <Email />
      </ModalWrapper>
    </div>
  );
};

MailForm.propTypes = {
  email: PropTypes.string,
};

MailForm.defaultProps = {
  email: "",
};

export default MailForm;

export const WALLET_ENTRIES = "wallets/entries";
export const WALLET_ENTRIES_REQUEST = `${WALLET_ENTRIES}_request`;
export const WALLET_ENTRIES_SUCCESS = `${WALLET_ENTRIES}_success`;
export const WALLET_ENTRIES_FAILURE = `${WALLET_ENTRIES}_failure`;


export const walletEntriesAction = {
  request: (payload) => {
    return {
      type: WALLET_ENTRIES_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: WALLET_ENTRIES_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: WALLET_ENTRIES_FAILURE,
      error,
    }
  },

};


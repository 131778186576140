import { get, map, isArray, filter, sortBy } from "lodash";
import numeral from "@utils/numeral";
import {
  MarketEntity,
  BreadcrumbEntity,
  PriceType,
  TrendingType,
  QuotaAttrBaseEntity,
  Moment,
} from "@core";

export class MarketUsecase {
  /**
   * 构造函数
   * @param {Array<Market> | Market} data 原始数据(Market)，可能是数组或单例对象
   */
  constructor(data) {
    this.entity = data;
  }

  /**
   * [Class Getter] usecase.list
   */
  get list() {
    if (!isArray(this.entity)) {
      return [];
    }
    return map(this.entity, (entity) => {
      return new MarketEntity(entity);
    });
  }

  /**
   * [Class Getter] usecase.single
   */
  get single() {
    if (isArray(this.entity)) {
      return new MarketEntity({});
    }
    return new MarketEntity(this.entity);
  }

  /**
   * [Class Getter] usecase.breadcrumb
   */
  get breadcrumb() {
    const quota = isArray(this.entity)
      ? get(this.entity, "[0].quota", "")
      : get(this.entity, "quota", "");
    return new BreadcrumbEntity(quota);
  }

  /**
   * [Class Getter] usecase.getAttr
   */
  get getAttr() {
    const quota = isArray(this.entity)
      ? get(this.entity, "[0].quota", "")
      : get(this.entity, "quota", "");
    return QuotaAttrBaseEntity.getAttr(quota);
  }

  /**
   * [Class Function] usecase.nav
   */
  nav(param) {
    return this.breadcrumb.nav(param);
  }

  /**
   * [Class Function] usecase.title
   */
  title({ category_id, state_id } = {}) {
    if (state_id) {
      return this.getAttr("state", "name");
    }
    if (category_id) {
      return this.getAttr("category", "name");
    }
  }

  /**
   * [Class Function] usecase.quotaMarkets
   * @params {String} lowest_seller_offer | highest_buyer_bids | lowest_buyer_order | highest_seller_order
   * @params {Boolean} isCompact 如果是true的情况过过滤掉报价为0的对象
   * @return {Array}
   * [{
      imageUrl: "",
      isNew: false,
      price: "",
      priceType: "",
      state: "",
      title: "",
      subTitle: "",
      trending: {
        priceWithSymbol: "",
        rate: "",
        value: 0,
      },
      unit: "",
      url: "",
    }]
   */

  quotaMarkets(priceType, isCompact) {
    if (!isArray(this.entity)) {
      return [];
    }
    return generateList(this.entity, priceType, isCompact);
  }

  /**
   * 查询相似的 lease market
   * @param {Object} query 相似的条件（或），暂时为 { id, categoryId, stateId } id 为不包括自己的意思
   * @param {String} priceType 读取价格类型 "lowest_seller_offer"|"highest_buyer_bids"
   */
  similar(query, priceType = "lowest_seller_offer") {
    if (!isArray(this.entity)) {
      return [];
    }
    const list = filter(this.entity, (item) => {
      return (
        (get(item, "quota.category.id") == query.categoryId ||
          get(item, "quota.state.id") == query.stateId) &&
        get(item, "id") != query.id
      );
    });
    return generateList(list, priceType, false);
  }

  //get markets
  getFisheryMarket(data = []) {
    return map(data, (entity) => {
      const market = new MarketEntity(entity);
      return {
        id: market.id,
        title: market.titleWithStateAbbr,
        tradePrice: market.lastTradePriceStr,
        lowestAskPrice: market.getPriceByType("lowest_seller_offer") || "Lease",
        highestBuyerBidValue:
          market.getPriceByType("highest_buyer_bids", true) || 0,
        trending: market.trending,
        trends: market.trends,
        url: `/leases/${market.id}`,
        season: market.seasonStr,
        market,
        marketType: market.marketType,
        unitOfPrice: market.unitOfPrice,
        fisheryId: market.fishery.id,
      };
    });
  }
  //all fishery markets
  get fisheryMarket() {
    const data = this.entity;
    if (!isArray(data)) {
      return [];
    }
    return this.getFisheryMarket(data);
  }

  //fishery 详情页中的leasemarkets
  get fisheryLeaseMarket() {
    const data = filter(this.entity, (o = {}) => o.market_type === "lease");
    if (!isArray(data)) {
      return [];
    }
    return this.getFisheryMarket(data);
  }
}

const generateList = (list, priceType, isCompact) => {
  //如果isCompact = true 过滤price 为null的对象;
  let res = isCompact ? filter(list, (entity) => !!entity[priceType]) : list;

  //获取market list 需要的对象参数
  res = map(res, (entity) => {
    const market = new MarketEntity(entity);
    return {
      imageUrl: market.imageUrl,
      price: market.getPriceByType(priceType),
      priceType: get(PriceType, priceType, ""),
      state: market.stateAbbr,
      title: market.ticker,
      subTitle: `${market.titleWithStateAbbr}`,
      trending: market.trendingByType(get(TrendingType, priceType, "")),
      unit: "kg",
      updatedAt: get(entity, `${priceType}.updated_at`, ""),
      url: market.url,
      marketType: market.marketType,
      unitOfPrice: market.unitOfPrice,
    };
  });

  //按照最新的bid来排序
  return sortBy(res, (o) => {
    const momentUsecase = new Moment(get(o, "updatedAt", ""));
    return numeral(`-${momentUsecase.formatTimestamp}`).value();
  });
};

import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";
import { OrderApiUsecase } from "@core";
import { ORDERS_PAYMENT_PROOFS_NEW_REQUEST, ordersPaymentProofsNewAction } from "./action";

export function* watchOrdersPaymentProofsNew() {
  yield takeLatest(ORDERS_PAYMENT_PROOFS_NEW_REQUEST, ordersPaymentProofsNew);
}

function* ordersPaymentProofsNew(action) {
  try {
    const usecase = new OrderApiUsecase();
    const res = yield call([usecase, "paymentsProofs"], action.payload);
    const data = get(res, "data", {});
    yield put(ordersPaymentProofsNewAction.success(data));
  } catch (err) {
    yield put(ordersPaymentProofsNewAction.failure(errorString(err)));
  }
}

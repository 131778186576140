import axios from "axios";
import { takeLatest, takeEvery,all, call, put, select } from "redux-saga/effects";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import {
  BIDS_FETCH_REQUEST,
  BIDS_FETCH_LOADING,
  BIDS_FETCH_SUCCESS,
  BIDS_FETCH_FAILED,
  BIDS_UPDATE_REQUEST,
  BIDS_ALL_FETCH_SUCCESS
} from "./listAction";

import { CURRENT_BID_FETCH_SUCCESS } from "./currentAction";

import { bidsAPI } from "@config/api";

//fetch bids list
export function* watchBidListFetch() {
  yield takeEvery(BIDS_FETCH_REQUEST, bidsListFetch);
}

export function* bidsListFetch(action) {
  //success
  if (get(action, "payload.params.limit")) {
    const { err, res } = yield call(bidsListFetchApi, action.payload);
    yield putAllBidsList(res);
  } else {
    yield put({ type: BIDS_FETCH_LOADING });
    const { err, res } = yield call(bidsListFetchApi, action.payload);
    if (!res) {
      yield put({
        type: BIDS_FETCH_FAILED,
        error: "Get bids list failed."
      });
      return;
    }
    yield putBidsList(res);
  }
}

//update bids list
export function* watchBidListUpdate() {
  yield takeEvery(BIDS_UPDATE_REQUEST, bidsListUpdate);
}

export function* bidsListUpdate(action) {
  yield putBidsList(action.payload);
}

function* putBidsList(res) {
  yield all([
    put({
      type: BIDS_FETCH_SUCCESS,
      data: res.bids
    }),
    put({
      type: CURRENT_BID_FETCH_SUCCESS,
      data: isEmpty(res.current_user_set_price)
        ? {}
        : Object.assign(
            {},
            res.current_user_set_price,
          )
    })
  ]);
}

function* putAllBidsList(res) {
  yield put({
    type: BIDS_ALL_FETCH_SUCCESS,
    data: res.bids
  });
}

export function bidsListFetchApi(payload) {
  return axios
    .get(bidsAPI(payload.id), { params: payload.params })
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}


export const ORDERS_DASHBOARD_FETCH_REQUEST = "orders/fetch_dashboard_request";
export const ORDERS_DASHBOARD_FETCH_LOADING = "orders/fetch_dashboard_loading";
export const ORDERS_DASHBOARD_FETCH_SUCCESS = "orders/fetch_dashboard_success";
export const ORDERS_DASHBOARD_FETCH_FAILED = "orders/fetch_dashboard_failed";


export function fetchOrderDashboardList(kind, params={}) {
  return {
    type: ORDERS_DASHBOARD_FETCH_REQUEST,
    payload: {
      kind,
      params,
    },
  };
}

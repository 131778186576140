
export const CHARTS_LAST_SOLD_REQUEST = "charts/last_sold_request";
export const CHARTS_LAST_SOLD_LOADING = "charts/last_sold_loading";
export const CHARTS_LAST_SOLD_SUCCESS = "charts/last_sold_success";
export const CHARTS_LAST_SOLD_FAILED = "charts/last_sold_failed";

/**
 * fetch charts for LastSoldPrice
 * @param  {Number} productGradeId
 * @param  {String} time           "day|week|month|year"
 * @return {Object}
 * {type: charts/last_sold_request, id: xxx, time: "day"}
 */
export function fetchChartsLastSold(productGradeId, time='day') {
  return {
    type: CHARTS_LAST_SOLD_REQUEST,
    id: productGradeId,
    time,
  };
}

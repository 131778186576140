import { takeLatest, call, put } from "redux-saga/effects";
import errorString from "@utils/errorString";
import { OfferApiUsecase } from "@core";
import { OFFERS_APPLY_CONFIRM_REQUEST, offersApplyConfirmAction } from "./action";

export function* watchOffersApplyConfirm() {
  yield takeLatest(OFFERS_APPLY_CONFIRM_REQUEST, offersApplyConfirm);
}

function* offersApplyConfirm(action) {
  try {
    const usecase = new OfferApiUsecase();
    yield call([usecase, "applyConfirm"], action.payload);
    yield put(offersApplyConfirmAction.success("Success!"));
  } catch (err) {
    yield put(offersApplyConfirmAction.failure(errorString(err)));
  }
}

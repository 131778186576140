export const CATEGORIES_LIST = "categories/list";
export const CATEGORIES_LIST_REQUEST = `${CATEGORIES_LIST}_request`;
export const CATEGORIES_LIST_SUCCESS = `${CATEGORIES_LIST}_success`;
export const CATEGORIES_LIST_FAILURE = `${CATEGORIES_LIST}_failure`;

export const categoriesListAction = {
  request: (payload) => {
    return {
      type: CATEGORIES_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: CATEGORIES_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: CATEGORIES_LIST_FAILURE,
      error,
    }
  },
};

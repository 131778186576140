import {
  BANKS_SHOW_FETCH_LOADING,
  BANKS_SHOW_FETCH_SUCCESS,
  BANKS_SHOW_FETCH_FAILED,
  BANKS_SHOW_FETCH_RECEIVED
} from "./showAction";

export const bankShowStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case BANKS_SHOW_FETCH_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
        justFinished: false,
      };
      break;
    case BANKS_SHOW_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
        justFinished: true,
      };
      break;
    case BANKS_SHOW_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
        justFinished: true,
      };
      break;

    case BANKS_SHOW_FETCH_RECEIVED:
      return Object.assign({}, state, { justFinished: false });

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
  justFinished: false,
};

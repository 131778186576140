import React, { useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

//Common Components
import Layout from "@views/layout";
import { Panel, Breadcrumb } from "@components";

//Quota Units
import SaleList from "./sale_list";
import QuotaUnitsForm from "./form";

//Hooks
import {
  useFisheryShow,
  useFisheryShowState,
  useFetchPermanentList,
} from "@hooks";

const PermanentListPage = (props) => {
  //Permanent id
  const fisheryId = get(props, "match.params.fishery_id");
  const quotumId = get(props, "match.params.quotum_id");

  //Fishery Show Data
  const { data: fisheryShow, loading: fisheryLoading } = useFisheryShow(
    fisheryId
  );

  //Fishery Show Page State
  const {
    state,
    setActiveTab,
    setFisheryId,
    setQuotaId,
    setQuotaUnitId,
    setShowPermanentList,
  } = useFisheryShowState();

  //permanent listing
  const [permanentList, permanentListLoading] = useFetchPermanentList({
    fishery_id: fisheryId,
    quotum_id: quotumId,
  });

  //Set fishery id
  useEffect(() => {
    fisheryId && setFisheryId(fisheryId);
  }, [fisheryId]);

  //Set quotum id
  useEffect(() => {
    quotumId && setQuotaId(quotumId);
  }, [quotumId]);

  //permanent listing name
  const name =
    get(permanentList, "sell[0][markets][0].nameStateAbbr") ||
    get(permanentList, "buy[0][markets][0].nameStateAbbr");

  return (
    <Layout>
      <div
        className="gs-page pt-0 bg-gray-50 page-pb"
        style={{ paddingBottom: 140 }}
      >
        <div className="container px-2 lg:px-0">
          <Breadcrumb
            className="mt-4 md:mt-6"
            data={[
              {
                name: get(fisheryShow, "title", ""),
                link: get(fisheryShow, "url", ""),
              },
              { name: name ? `${name} For Sale/Wanted` : "Permanent Sales" },
            ]}
            loading={fisheryLoading || permanentListLoading}
          />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-4 md:mt-6 md:gap-6">
            <div className="bg-white mt-px rounded-lg shadow md:col-span-8 min-hight">
              <Panel classNameWrapper="shadow-none rounded-0">
                <SaleList
                  setQuotaUnitId={setQuotaUnitId}
                  setShowPermanentList={setShowPermanentList}
                  permanentList={permanentList}
                  loading={permanentListLoading}
                  title={get(fisheryShow, "title", "")}
                  setActiveTab={setActiveTab}
                  state={state}
                  name={name}
                />
              </Panel>
            </div>
            <div className="md:col-span-4" style={{ minWidth: 300 }}>
              <QuotaUnitsForm state={state} id={get(fisheryShow, "id", "")} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

PermanentListPage.propTypes = {
  props: PropTypes.object,
};

export default PermanentListPage;
import {UPLOAD_AVATAR_FAILED, UPLOAD_AVATAR_LOADING, UPLOAD_AVATAR_SUCCESS} from "./action";

function uploadAvatar(state = {}, action = {}) {
  switch (action.type) {
    case UPLOAD_AVATAR_LOADING:
      return { loading: true, data: null, error: null }
    case UPLOAD_AVATAR_SUCCESS:
      return { loading: false, data: action.data, error: null }
    case UPLOAD_AVATAR_FAILED:
      return { loading: false, data: null, error: action.error }
    default:
      return state
  }
}

export { uploadAvatar }
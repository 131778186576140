import { BID_UPDATE_SUCCESS, BID_UPDATE_FAILURE, BID_UPDATE_RECEIVED } from "./action";

export const bidUpdateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case BID_UPDATE_SUCCESS:
      return {
        justFinished: true,
        data: action.data,
        error: null,
      };

    case BID_UPDATE_FAILURE:
      return {
        justFinished: true,
        data: null,
        error: action.error,
      };

    case BID_UPDATE_RECEIVED:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  justFinished: false,
  data: null,
  error: null,
};

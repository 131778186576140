import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { ACCOUNT_PERMANENT_SHOW_REQUEST, accountPermanentShowAction } from "./action";

// fetch permanent show
export function* watchAccountPermanentShow() {
  yield takeLatest(ACCOUNT_PERMANENT_SHOW_REQUEST, accountPermanentShow);
}

function* accountPermanentShow(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "permanentShow"], action.payload);
    yield put(accountPermanentShowAction.success(res.data));
  } catch (err) {
    yield put(accountPermanentShowAction.failure(err));
  }
}

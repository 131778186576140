import React from "react";
import PropTypes from "prop-types";

import { fishnetClientNumber as _fishnetClientNumber } from "@core";

const FishNetClientNumber = (props) => {
  return (
    <div className="bg-white rounded-lg shadow">
      <div className="flex items-center justify-between w-full px-4 md:px-6 py-4 text-xxs capitalize">
        <div className="text-xxs text-blue-gray-500 capitalize mr-5">
          BeachPrice fishnet client number
        </div>
        <div className="text-xxs font-medium">
          {_fishnetClientNumber}
        </div>
      </div>
    </div>
  );
};

FishNetClientNumber.propTypes = {};

export default FishNetClientNumber;

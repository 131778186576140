import axios from 'axios';
import { takeLatest, takeEvery, call, put, delay } from 'redux-saga/effects';

import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import {
  LISTING_CREATE_POST,
  LISTING_CREATE_LOADING,
  LISTING_CREATE_SUCCESS,
  LISTING_CREATE_FAILED,
  LISTING_CREATE_RESET
} from './createAction';

import { listingsAPI } from '@config/api';

//post listing for create
export function* watchListingPost() {
  yield takeLatest(LISTING_CREATE_POST, listingPost);
}

export function* listingPost(action) {
  yield put({ type: LISTING_CREATE_LOADING });
  const { err, res } = yield call(listingPostApi, action.payload);

  if (!err) {
    yield put({ type: LISTING_CREATE_SUCCESS, data: res });
  } else {
    yield put({
      type: LISTING_CREATE_FAILED,
      error: 'Post listing failed.'
    });
  }
  yield call(delay, 3000);
  yield put({ type: LISTING_CREATE_RESET });
}

export function listingPostApi(payload) {
  return axios
    .post(listingsAPI(), { listing: payload })
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  OFFER_PRICES_UPDATE_PATCH,
  OFFER_PRICES_UPDATE_LOADING,
  OFFER_PRICES_UPDATE_SUCCESS,
  OFFER_PRICES_UPDATE_FAILED,
} from "./updateAction";

import {
  offerPricesWithoutProductAPI,
} from "@config/api";


//patch offer price for update
export function* watchOfferPriceUpdate() {
  yield takeLatest(OFFER_PRICES_UPDATE_PATCH, offerPriceUpdate);
}

export function* offerPriceUpdate(action) {
  yield put({ type: OFFER_PRICES_UPDATE_LOADING });
  const { err, res } = yield call(offerPriceUpdateApi, action.id, action.payload);

  if (!res) {
    yield put({
      type: OFFER_PRICES_UPDATE_FAILED,
      error: "Update offer price failed."
    });
    return;
  }
  yield put({ type: OFFER_PRICES_UPDATE_SUCCESS, data: res });
}

export function offerPriceUpdateApi(id, payload) {
  return axios
    .patch(offerPricesWithoutProductAPI(id), { offer_price: payload} )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}
import toString from "lodash/toString";
import get from "lodash/get";
// import toBoolean from "@utils/toBoolean";
import cloneDeep from "lodash/cloneDeep";
import remove from "lodash/remove";
import { currentUserAttributes } from "@utils/cognitoAuth";
import { getCountryList, getStateList } from "@utils/countryState";

import { FormSelectBaseEntity } from "@core/index";

export class AccountProfileUsecase {
  /**
   * 构造函数
   */
  constructor() {}

  /**
   * 获取profile数据
   * @param inputValue
   */
  async getData() {
    try {
      const res = await currentUserAttributes();
      return {
        email: get(res, "email", ""),
        user_name: get(res, "preferred_username", ""),
        first_name: get(res, "given_name", ""),
        last_name: get(res, "family_name", ""),
        // picture: res["picture"],
        user_type: toString(get(res, "custom:user_types", ""))
          ? toString(get(res, "custom:user_types", "")).split(",")
          : [],
        address: get(res, "address", ""),
        state: get(res, "custom:state", ""),
        country: get(res, "custom:country", "Australia"),
        post_code: get(res, "custom:post_code", ""),
        phone_country_code: get(res, "custom:phone_country_code", "61"),
        phone_number: get(res, "custom:phone_number", ""),
        company_name: get(res, "custom:company_name", ""),
        industry: toString(get(res, "custom:industry", ""))
          ? toString(get(res, "custom:industry", "")).split(",")
          : [],
        abn: get(res, "custom:abn", ""),
        licence_number: get(res, "custom:licence_number", ""),
        // need_gst: toBoolean(res["custom:need_gst"])
        goal: toString(get(res, "custom:goal", ""))
        ? toString(get(res, "custom:goal", "")).split(",")
        : [],
        hear_us: toString(get(res, "custom:hear_us", "")),
        word_of_mouth: toString(get(res, "custom:word_of_mouth", "")),
        hear_us_other_way: toString(get(res, "custom:hear_us_other_way", "")),
      };
    } catch (error) {
      return {};
    }
  }

  get countries() {
    const select = new FormSelectBaseEntity();
    const options = select.options(getCountryList());
    return options;
  }

  get states() {
    const select = new FormSelectBaseEntity();
    const options = select.options(getStateList());
    return options;
  }

  /**
   * 更新profile请求的数据
   */

  getEditData(values = {}) {
    let data = cloneDeep(values);
    let userType = remove(data["user_type"], (elem) => {
      return toString(elem) !== "";
    }).join(",");
    let industry = remove(data["industry"], (elem) => {
      return toString(elem) !== "";
    }).join(",");
    return {
      email: values["email"],
      preferred_username: values["user_name"],
      given_name: values["first_name"],
      family_name: values["last_name"],
      picture: values["picture"],
      address: values["address"],
      "custom:user_types": userType,
      "custom:state": values["state"],
      "custom:country": values["country"],
      "custom:post_code": values["post_code"],
      "custom:phone_country_code": values["phone_country_code"],
      "custom:phone_number": values["phone_number"],
      "custom:company_name": values["company_name"],
      "custom:industry": industry,
      "custom:abn": values["abn"],
      "custom:licence_number": values["licence_number"],
      "custom:need_gst": toString(values["need_gst"]),
      "custom:is_filled": toString("true"),
    };
  }

  profileQuestion(values = {}, goal) {
    let register_question = {
      goal: goal,
      hear_us:values["hear_us"],
    }
    if(values["hear_us"] == 'word_of_mouth') {
      register_question.word_of_mouth = values["word_of_mouth"];
    }
    if(values["hear_us"] == 'other') {
      register_question.hear_us_other_way = values["hear_us_other_way"];
    }
    return {register_question}
  }
}

const WALLET_BALANCE_REQUEST = "account_wallet_balance/request";
const WALLET_BALANCE_LOADING = "account_wallet_balance/loading";
const WALLET_BALANCE_SUCCESS = "account_wallet_balance/success";
const WALLET_BALANCE_FAILED = "account_wallet_balance/failed";

function walletBalanceRequest(payload) {
  return {
    type: WALLET_BALANCE_REQUEST,
    payload,
  };
}

function walletBalanceLoading() {
  return {
    type: WALLET_BALANCE_LOADING,
  };
}

function walletBalanceSuccess(data) {
  return {
    type: WALLET_BALANCE_SUCCESS,
    data,
  };
}

function walletBalanceFailed(error) {
  return {
    type: WALLET_BALANCE_FAILED,
    error,
  };
}

export {
  WALLET_BALANCE_REQUEST,
  WALLET_BALANCE_FAILED,
  WALLET_BALANCE_LOADING,
  WALLET_BALANCE_SUCCESS,
  walletBalanceRequest,
  walletBalanceFailed,
  walletBalanceSuccess,
  walletBalanceLoading,
};

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import { links } from "@config/staticLink";

export default function (ComposedComponent) {
  class CheckAuth extends React.Component {
    componentWillMount() {
      this.checkUserAuth(this.props);
    }

    componentWillUpdate(nextProps) {
      if (nextProps.isAuthenticated != this.props.isAuthenticated) {
        this.checkUserAuth(nextProps);
      }
    }

    checkUserAuth = (props) => {
      const pathName = window.location.pathname;
      const search = window.location.search;
      const href = window.location.href;
      const { isAuthenticated, user } = props;
      if (isAuthenticated) {
        return;
      }
      window.location.href = `${links.sign_in}?redirect_to=${href}`;
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: get(state, "currentUserStatus.isAuthenticated", false),
      user: get(state, "currentUserStatus.user", ""),
    };
  }

  return withRouter(connect(mapStateToProps, {})(CheckAuth));
}

import React from "react";

/**
 * import local utils
 */
import numeral from "@utils/numeral";

/**
 * import local components
 */
import { Button } from "@components";
import { ModalWrapper } from "@views/common";

/**
 * [Component] WithdrawalModal
 */
const WithdrawalModal = (props) => {
  const { isOpen, money, toggleStatus, onClosed } = props;

  const toggle = () => {
    toggleStatus(false);
  };

  const renderResult = () => {
    return (
      <div className="">
        <h2 className="mb-8">
          Withdraw is processing
        </h2>
        <div className="text-center mb-6 mx-10">
          Please allow 2 business days for the funds transfer of{" "}
          {numeral(money).format()} to arrive in your nominated bank account.
        </div>
        <div className="flex justify-center mx-6 py-4-tw">
          <Button
            size="xl"
            color="fish-primary"
            textColor="white"
            className="hover:bg-fish-primary-700"
            full
            onClick={() => {
              typeof toggle === "function" && toggle();
              typeof onClosed === "function" && onClosed();
            }}
          >
            <div className="px-5-tw">Confirm</div>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      size="sm"
      isOpen={isOpen}
      toggleModal={toggleStatus}
      onClosed={onClosed}
    >
      {renderResult()}
    </ModalWrapper>
  );
};

export default WithdrawalModal;

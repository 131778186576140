import {
  WALLET_TOPUP_SUCCESS,
  WALLET_TOPUP_FAILURE,
  WALLET_TOPUP_RECEIVED,
} from "./action";

export const walletTopupStatusV2 = (state = initStatus, action = {}) => {
  switch (action.type) {
    case WALLET_TOPUP_SUCCESS:
      return {
        data: action.data,
        error: null,
        justFinished: true,
      };

    case WALLET_TOPUP_FAILURE:
      return {
        data: null,
        error: action.error,
        justFinished: true,
      };

    case WALLET_TOPUP_RECEIVED:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
  justFinished: false,
};

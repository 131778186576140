import { LISTING_QUOTA_TRANSFERS_NEW_SUCCESS, LISTING_QUOTA_TRANSFERS_NEW_FAILURE, LISTING_QUOTA_TRANSFERS_NEW_RESET } from "./action";

export const listingQuotaTransfersNewStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LISTING_QUOTA_TRANSFERS_NEW_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case LISTING_QUOTA_TRANSFERS_NEW_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case LISTING_QUOTA_TRANSFERS_NEW_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

/**
 * Component Pagination
 *
 * @example
 *
 * <Pagination currentPage={1} pageSize={20} entriesTotal={102} />
 * <Pagination currentPage={1} pageSize={20} entriesTotal={102} pageShow={3} itemClickHandler={Function} />
 * <Pagination currentPage={1} pageSize={20} entriesTotal={102} pageTotal={6} />
 * <Pagination currentPage={1} pageSize={20} entriesTotal={102} className="pb-3" listClassName="justify-content-end" theme="danger|success" />
 * pageTotal 总页数可以给，也可以不给。如果给定，必须大于0， 且是正确的。
 * 如果不给定，内部会计算得出。
 * 所以，建议不给定。
 */
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";

const Pagination = ({
  className,
  currentPage,
  entriesTotal,
  pageTotal,
  pageShow,
  itemClickHandler,
  pageSize,
}) => {
  const prevClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    itemClickHandler && itemClickHandler(currentPage - 1);
  };

  const nextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    itemClickHandler && itemClickHandler(currentPage + 1);
  };

  const itemClick = (num) => {
    itemClickHandler && itemClickHandler(num);
  };

  const getBefor = (total) => {
    if (currentPage == 1 || currentPage > total) {
      return [];
    }
    let length = (pageShow - 1) / 2;
    let start = Math.max(currentPage - length, 1);
    let result = [];
    for (var i = start; i < currentPage; i++) {
      result.push(i);
    }
    return result;
  };

  const getAfter = (total) => {
    if (currentPage >= total) {
      return [];
    }
    let length = (pageShow - 1) / 2;
    let end = Math.min(currentPage + length, total);
    let result = [];
    for (var i = currentPage + 1; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  const getPageTotal = () => {
    if (pageTotal && pageTotal > 0) {
      return pageTotal;
    }
    return Math.ceil(entriesTotal / pageSize);
  };

  if (entriesTotal <= 0) {
    return null;
  }

  if (pageShow % 2 == 0) {
    console.warn("Pagination props pageShow is not allowed!");
    return null;
  }

  const total = getPageTotal();
  const before = getBefor(total);
  const after = getAfter(total);
  return (
    <nav
      className={cx(
        "relative z-0 inline-flex shadow-sm -space-x-px",
        className
      )}
      aria-label="Pagination"
    >
      {currentPage > 1 && (
        <button
          onClick={prevClick}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-blue-gray-300 bg-white text-sm font-medium text-blue-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}
      {map(before, (num, index) => {
        return (
          <button
            onClick={() => itemClick(num)}
            key={index}
            className="relative inline-flex items-center px-4 py-2 border border-blue-gray-300 bg-white text-sm font-medium text-blue-gray-700 hover:bg-gray-50"
          >
            {num}
          </button>
        );
      })}
      <button
        className={cx(
          "relative inline-flex items-center px-4 py-2 border border-blue-gray-300 bg-gray-50 text-sm font-medium text-blue-gray-700",
          {
            "rounded-l-md": currentPage === 1,
            "rounded-r-md": currentPage === total,
          }
        )}
      >
        {currentPage}
      </button>
      {after.map((num, index) => {
        return (
          <button
            onClick={() => itemClick(num)}
            key={index}
            className="relative inline-flex items-center px-4 py-2 border border-blue-gray-300 bg-white text-sm font-medium text-blue-gray-700 hover:bg-gray-50"
          >
            {num}
          </button>
        );
      })}
      {currentPage < total && (
        <button
          onClick={nextClick}
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-blue-gray-300 bg-white text-sm font-medium text-blue-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}
    </nav>
  );
};

export default Pagination;

Pagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  entriesTotal: PropTypes.number,
  pageTotal: PropTypes.number,
  pageShow: PropTypes.number,
  itemClickHandler: PropTypes.func,
  pageSize: PropTypes.number,
};
Pagination.defaultProps = {
  className: "",
  currentPage: 1, // 当前页码
  pageSize: 0, // 每页数据个数
  pageTotal: 0, // 总页数
  entriesTotal: 0, // 数据总个数
  pageShow: 5, // 页码显示个数
  itemClickHandler: () => {},
};

import {
    CURRENT_BID_FETCH_REQUEST,
    CURRENT_BID_FETCH_SUCCESS,
    CURRENT_BID_FETCH_FAILED
} from "./currentAction";

export const bidCurrentStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case CURRENT_BID_FETCH_REQUEST:
      return {
        loading: true,
        data: {},
        error: null
      };
      break;

    case CURRENT_BID_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case CURRENT_BID_FETCH_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null
};

import React from "react";
import PropTypes from "prop-types";

/**
 * import lib tools
 */
import { reduxForm, Field, FieldArray } from "redux-form";

/**
 * import local component
 */
import { Button, Loading } from "@components";
import {
  renderTextField,
  renderSelectField,
  required,
} from "@components/reduxform";
import AttributesGroup from "./attributesGroup";

const NewForm = ({
  addNewMarketsAttribute,
  getQuotaOptions,
  getUnitNameByQuotumId,
  unitNameCondition,
  handleSubmit,
  loading,
  submitting,
  totalPrice,
  fisheryOptions,
  type,
  quotaListLoading,
  initialValues,
  moveMarket,
  edit,
  isLicence,
}) => {
  const initialExpired = edit
    ? [{ label: `${initialValues.expiredAt}`, value: "" }]
    : [];

  const daysOptions = [
    { label: "7 days", value: "7" },
    { label: "14 days", value: "14" },
    { label: "30 days", value: "30" },
  ];

  return (
    <div className="mt-6">
      <form onSubmit={handleSubmit}>
        <div className="sell-quota-form">
          <Field
            name="fishery_id"
            label="Fishery"
            validate={required}
            component={renderSelectField}
            options={fisheryOptions}
            bsSize="sm"
            disabled={edit}
          />
          {/* <Select
            className="form-select-lg"
            labelClass={labelClass}
            name="Fishery"
            label="Fishery"
            options={dataList}
            placeholder="Victoria Western Coral Trout Quota Units"
          /> */}
          {quotaListLoading ? (
            <Loading />
          ) : (
            <FieldArray
              addNewMarketsAttribute={addNewMarketsAttribute}
              component={AttributesGroup}
              name="markets_attributes"
              getQuotaOptions={getQuotaOptions}
              getUnitNameByQuotumId={getUnitNameByQuotumId}
              type={type}
              unitNameCondition={unitNameCondition}
              moveMarket={moveMarket}
              edit={edit}
              isLicence={isLicence}
            />
          )}
          {totalPrice && (
            <div className="pt-6 text-right mr-4 sm:mr-0">
              <div className="font-bold text-2xl leading-tight">
                {totalPrice}
              </div>
              <div className="text-base font-medium leading-normal text-blue-gray-600">
                {type == "sell" ? "Total Ask Price" : "Total Bid Price"}
              </div>
            </div>
          )}
          <div className="mt-10">
            <Field
              name="expiry_days"
              label="Expiry Date"
              bsSize="sm"
              validate={edit ? null : required}
              component={renderSelectField}
              options={[...initialExpired, ...daysOptions]}
            />
          </div>
          <div className="mt-4">
            <label className="label block text-sm font-medium leading-5 text-blue-gray-700">
              <span>Other Conditions</span>
            </label>
            <div className="mt-2">
              <Field
                name="description"
                type="textarea"
                validate={required}
                component={renderTextField}
              />
            </div>
          </div>
          {edit ? null : (
            <div className="mt-6">
              <Button
                size="xl"
                color="fish-primary"
                textColor="white"
                className="hover:bg-fish-primary-700"
                type="submit"
                disabled={loading || submitting}
                loading={loading || submitting}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

NewForm.propTypes = {
  addNewMarketsAttribute: PropTypes.func,
  change: PropTypes.func,
  getQuotaOptions: PropTypes.func,
  getUnitNameByQuotumId: PropTypes.func,
  unitNameCondition: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  title: PropTypes.string,
  touch: PropTypes.func,
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fisheryOptions: PropTypes.array,
  quotaListLoading: PropTypes.bool,
  edit: PropTypes.bool,
};

NewForm.defaultProps = {
  edit: false,
};

export default reduxForm({
  form: "PermannentNewForm",
})(NewForm);

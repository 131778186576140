import {
  CONFIRM_REQUEST,
  CONFIRM_LOADING,
  CONFIRM_SUCCESS,
  CONFIRM_FAILED
} from './confirmAction'

function confirmForgotPassword(state = {}, action = {}) {
  switch (action.type) {
    case CONFIRM_LOADING:
      return {loading: true, data: null, error: null}
    case CONFIRM_SUCCESS:
      return {loading: false, data: action.data, error: null}
    case CONFIRM_FAILED:
      return {loading: false, data: null, error: action.error}
    default:
      return state
  }
}

export {confirmForgotPassword}

export const PRODUCTS_FETCH_REQUEST = "products/fetch_list_request";
export const PRODUCTS_FETCH_LOADING = "products/fetch_list_loading";
export const PRODUCTS_FETCH_SUCCESS = "products/fetch_list_success";
export const PRODUCTS_FETCH_FAILED = "products/fetch_list_failed";
export const PRODUCTS_FETCH_RECEIVED = "products/fetch_list_received";


export function fetchProductList() {
  return {
    type: PRODUCTS_FETCH_REQUEST,
  };
}

import { takeLatest, call, put } from "redux-saga/effects";

import { OfferApiUsecase } from "@core";
import { OFFER_DELETE_REQUEST, offerDeleteAction } from "./action";

// destroy offer with market(lease)
export function* watchMarketOfferDelete() {
  yield takeLatest(OFFER_DELETE_REQUEST, offerDelete);
}

function* offerDelete(action) {
  try {
    const usecase = new OfferApiUsecase();
    const res = yield call([usecase, "destroy"], action.payload);
    yield put(offerDeleteAction.success(res.data));
  } catch (err) {
    yield put(offerDeleteAction.failure(err));
  }
}

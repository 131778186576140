import "core-js/stable";
import "regenerator-runtime/runtime";
import "./stylesheets/application.scss";
import includes from "lodash/includes";
import some from "lodash/some";

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import { Provider } from "react-redux";
import jwtDecode from "jwt-decode";
import moment from "moment";
import stringformat from "stringformat";
import "whatwg-fetch";
import { createBrowserHistory } from "history";

import * as Sentry from "@sentry/browser";
import * as FullStory from "@fullstory/browser";

/**
 * import Configs
 */
import { AppRouter } from "./app/config/router";
import store from "./app/config/store";
import trixConfig from "./app/config/trix";
import { fullStoryConfig } from "@config/fullStory";
/**
 * import Utils
 */
import setAuthorizationToken from "./app/utils/setAuthorizationToken";
import setDefaultContentType from "./app/utils/setDefaultContentType";
import ScrollToTop from "./app/utils/scrollToTop";
import viewpointUnit from "./app/utils/viewportUnits";
import numeral from "./app/utils/numeral";

/**
 * import Module actions
 */
import { setCurrentUser } from "./app/redux/modules/auth/action";
import { timestampFetch } from "./app/redux/modules/timeStamp/action";

/*
 * import react google analytic
 */
import ReactGA from "react-ga";

import Cookies from 'js-cookie';

/**
 * import cognito
 */

(async () => {
  try {

    // 需要链接到rails版本的页面
    const pathname = window.location.pathname;
    const changeLinkArray = [
      {react_url: /^\/dashboard$/, rails_url: '/dashboard'},
      {react_url: /^\/listings$/, rails_url: '/listings'},
      {react_url: /^\/orders$/, rails_url: '/orders', search: '?type=purchase'},
      {react_url: /^\/orders$/, rails_url: '/orders', search: '?type=sales'},
      {react_url: /^\/orders\/(\d+)$/, rails_url: '/orders/${1}'},
      {react_url: /^\/bids$/, rails_url: '/bids'},
      {react_url: /^\/setting$/, rails_url: '/profile/settings'},
      {react_url: /^\/auctions\/(\d+)$/, rails_url: '/auctions/${1}'},
      {react_url: /^\/auctions\/(\d+)\/final-bid$/, rails_url: '/auctions/${1}/final_offer/new'},
      {react_url: /^\/auction\/past$/, rails_url: '/past-auctions'},
      {react_url: /^\/sell$/, rails_url: '/listings/new'},
    ]
    changeLinkArray.forEach(item=>{ 
      if(pathname.match(item.react_url)){
        // 当前url带有search的判断（purchase, sales, sell 页面）
        if(item.search && window.location.search != item.search){
          return false;
        }else if(!item.search && window.location.search){
          return false
        }
        // 当前url带有动态数字时 赋值到rails url
        if(item.rails_url.includes("${1}")){
          item.rails_url = item.rails_url.replace("${1}",pathname.match(item.react_url)[1])
        }
        // 当显示react url时，替换到rails url
        if (process.env.NODE_ENV == "production"){
          if(window.location.href.includes("app.")){
            window.location.href = window.location.href.replace("app.", "").replace(pathname.match(item.react_url)[0], item.rails_url);
          }
          if(window.location.href.includes("bpx")){
            window.location.href = window.location.href.replace("bpx", "bpx-api").replace(pathname.match(item.react_url)[0], item.rails_url);
          }
          if(window.location.href.includes("bpx-2")){
            window.location.href = window.location.href.replace("bpx-2", "bpx-api-2").replace(pathname.match(item.react_url)[0], item.rails_url);
          }
          if(window.location.href.includes("bpx-3")){
            window.location.href = window.location.href.replace("bpx-3", "bpx-api-3").replace(pathname.match(item.react_url)[0], item.rails_url);
          }
        }
        else if (process.env.NODE_ENV == "development"){
          if(window.location.href.includes("3000")){
            window.location.href = window.location.href.replace("3000", "5000").replace(pathname.match(item.react_url)[0], item.rails_url);
          }
        }
      }
    });

    window.moment = moment;
    window.numeral = numeral;

    // https://zeph.co/google-analytics-react-router
    if (process.env.REACT_APP_BUILD_ENV == "production") {
      ReactGA.initialize("UA-110542269-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    Sentry.init({
      dsn: "https://042aa8b6cc5c471c94ca2bff4b7c80ba@o584363.ingest.sentry.io/5737195",
      environment: process.env.REACT_APP_BUILD_ENV || "development",
    });
    window.Sentry = Sentry;

    /**
     * init fullstory
     */
    const fullStory = fullStoryConfig();
    fullStory.org_code && FullStory.init({ orgId: fullStory.org_code });

    /**
     * Create history
     */
    const history = createBrowserHistory();

    /**
     * import GA listener
     */
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });

    /**
     * set default axios config
     */
    setDefaultContentType();
    setAuthorizationToken();

    /**
     * config trix
     */
    trixConfig();

    /**
     * setup viewport units
     */
    viewpointUnit();

    const render = () => {
      const root = document.getElementById("root");
      root.setAttribute("notranslate", true);
      return ReactDOM.render(
        <Router history={history}>
          <Provider store={store}>
            <ScrollToTop>
              <AppRouter history={history} />
            </ScrollToTop>
          </Provider>
        </Router>,
        root
      );
    };

    /**
     * Check Login, set Auth token
     * Fetch timestamp
     */
    store.dispatch(timestampFetch());

    /**
     * set Auth token
     * @type {String|null}
     */

    // function sleep(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // }

    const idToken = Cookies.get('id_token');
    if(idToken) {
      let userData = jwtDecode(idToken);
      userData.token = idToken;
      store.dispatch(setCurrentUser(userData));
    }

    document.onvisibilitychange = function () {
      if (!document.hidden) {
        // alert("I am back!");
        // update latest time
        store.dispatch(timestampFetch());
      }
    };

    /**
     * Mount to DOM
     */
    document.addEventListener("DOMContentLoaded", () => {
      stringformat.extendString();
      render();
    });

  } catch (e) {
    console.error(e)
    console.error(e.stack);
  }
})();
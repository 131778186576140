import {
    SUBSCRIBE_LOADING,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAILED,
  } from "./action";
  
  export const subscribeStatus = (state = {}, action = {}) => {
    switch (action.type) {
      case SUBSCRIBE_LOADING:
        return {
          loading: true,
          data: {},
          error: null,
        };
        break;
      case SUBSCRIBE_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null,
        };
        break;
      case SUBSCRIBE_FAILED:
        return {
          loading: false,
          data: {},
          error: action.error,
        };
        break;
      default:
        return state;
    }
  };

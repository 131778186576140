import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  invoiceShowAction,
  INVOICE_SHOW,
} from "@redux/modules_v2/invoices/show/action";

/**
 * [hooks] invoice show by id
 * @returns { data: "a order invoice data", loading: true|false }
 */
export const useInvoiceShow = orderId => {
  const dispatch = useDispatch();

  const fetchInvoiceShow = orderId => {
    dispatch(invoiceShowAction.request({ orderId }));
  };

  const invoiceShowStatus = useSelector(state => {
    const selector = new LoadingSelector(INVOICE_SHOW, state);
    return Object.assign({}, get(state, "invoiceShowStatusV2", {}), {
      loading: selector.loading,
    });
  });

  const loading = invoiceShowStatus.loading;
  const data = invoiceShowStatus.data || {};

  /**
   * did mount and watch order id change
   */
  useEffect(() => {
    if (orderId) {
      fetchInvoiceShow(orderId);
    }
  }, [orderId]);

  return { data, loading };
};

import {poolData, region, userPool} from './config'
import * as jwtDecode from "jwt-decode";
import * as AWS from "aws-sdk";
import Cookies from 'js-cookie';

/**
 * Get current CognitoUser from user pool
 * @return {Request<WorkDocs.GetCurrentUserResponse, AWSError> | CognitoUser}
 */
function currentUser() {
  return userPool.getCurrentUser();
}

/**
 * Get current authenticated user
 * @return {Promise} A promise resolves to current authenticated CognitoUser if success
 */
function currentAuthenticatedUser() {
  if (!userPool) { return Promise.reject('No userPool') }
  const user = userPool.getCurrentUser()
  if (!user) { return Promise.reject('No current user') }

  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) { reject(err) } else { resolve(user) }
    })
  })
}

/**
 * Get current user's session
 * @return {Promise} - A promise resolves to session object if success
 */
function currentUserSession() {
  if (!userPool) { return Promise.reject('No userPool') }

  let cognitoUser = currentUser()
  if (!cognitoUser) { return Promise.reject('No current user') }
  return userSession(cognitoUser)
}

/**
 * Get the corresponding user session
 * @param {Object} user - The CognitoUser object
 * @return {Promise} - A promise resolves to the session
 */
function userSession(user) {
  return new Promise((resolve, reject) => {
    user.getSession((err, result) => {
      if (err) { reject(err) } else { resolve(result) }
    })
  })
}

/**
 * Get current user's session
 * @return {Promise} A promise resolves to session object if success
 */
function currentSession() {
  return currentUserSession()
}

/**
 * Refresh current user's session
 * @return {Promise} A promise resolves to session object if success
 */
function refreshCurrentUserSession() {
  return currentAuthenticatedUser()
    .then(user => refreshUserSession(user))
}

/**
 * Refresh user's session
 * @param {Object} user - The CognitoUser object
 * @return {Promise} A promise resolves to session object if success
 */
function refreshUserSession(user) {
  return userSession(user).then(session => {
    return new Promise((resolve, reject) => {
      let refreshToken = session.getRefreshToken()
      user.refreshSession(refreshToken, (err, session) => {
        if (err) {
          // console.log(`cognito refresh session failed: ${JSON.stringify(err)}`)
          return reject(err)
        }
        // console.log(`cognito refresh session success: ${JSON.stringify(session)}`)
        resolve(session)
      })
    })
  })
}

/**
 * Refresh current user's session
 * @return {Promise} A promise resolves to session object if success
 */
function refreshSession() {
  return refreshCurrentUserSession()
}

/**
 * Get current idToken from session
 * @return {Promise} A promise resolves to idToken string if success
 */
function currentIdToken() {
  return currentSession()
    .then(session => session.getIdToken().getJwtToken())
}

export function currentCookieToken() {
  return new Promise((resolve, reject) => {
    const token = Cookies.get('user_token');
    if (token) {
      resolve(token);
    } else {
      reject('user token not exists');
    }
  })
}

/**
 * Retry 5 times for getting current idToken from session
 * @return {Promise} A promise resolves to idToken string if success
 */

const currentIdTokenRetry = async (n=5) => {
  try {
      return await currentIdToken()
  } catch(err) {
      if (n === 1) throw err;
      return await currentIdTokenRetry( n - 1);
  }
};

/**
 * Get current idToken claims from session
 * @return {Promise} A promise resolves to idToken claims object if success
 */
function currentIdTokenClaims() {
  return currentIdToken()
    .then(token => jwtDecode(token))
}

/**
 * Produce a credentials based on the session
 * @param session - The session used to generate the credentials
 * @returns {CognitoIdentityCredentials}
 */
function sessionToCredentials(session) {
  const idToken = session.getIdToken().getJwtToken();
  const userPoolId = poolData().UserPoolId;
  const identityPoolId = poolData().IdentityPoolId;
  const key = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
  let logins = {};
  logins[key] = idToken;
  return new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
    Logins: logins
  }, {
    region: region
  })
}

/**
 * Get authenticated credentials of current user
 * @returns {Promise}
 */
function currentUserCredentials() {
  return currentUserSession().then(session => {
    return new Promise((resolve, reject) => {
      const credentials = sessionToCredentials(session)
      credentials.get(err => {
        if (err) { reject(err); } else { resolve(credentials); }
      })
    })
  })
}

export {
  currentIdTokenClaims,
  currentUser,
  currentAuthenticatedUser,
  currentUserSession,
  userSession,
  currentSession,
  refreshSession,
  refreshCurrentUserSession,
  refreshUserSession,
  currentIdToken,
  currentIdTokenRetry,
  currentUserCredentials
}

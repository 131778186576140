import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { LoadingWrapper, Empty, Button } from "@components";

const Table = ({ data, loading, emptyTitle, emptyContent }) => {
  const history = useHistory();

  const renderOperation = (item) => {
    const end = get(item, "status.end", false);
    const highestBidder = get(item, "show.highestBidder", false);
    const underReserve = get(item, "status.underReserve", false);
    if (end) {
      if (underReserve) {
        return (
          <Button
            className="rounded-full text-xs font-medium leading-normal"
            size="xxs"
            color="gray-100"
            textColor="gray-600"
          >
            <span className="font-medium text-xxs">Reserve Not Met</span>
          </Button>
        );
      }
      if (highestBidder) {
        return (
          <Button
            className="rounded-full text-xs font-medium leading-normal"
            size="xxs"
            color="green-100"
            textColor="green-600"
          >
            <span className="font-medium text-xxs">Won</span>
          </Button>
        );
      }
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="gray-100"
          textColor="gray-600"
        >
          <span className="font-medium text-xxs">Lost</span>
        </Button>
      );
    }

    if (highestBidder) {
      return (
        <Button
          className="rounded-full text-xs font-medium leading-normal"
          size="xxs"
          color="blue-100"
          textColor="fish-primary"
        >
          <span className="font-medium text-xxs">Leading</span>
        </Button>
      );
    }
    return (
      <Button
        className="rounded-full text-xs font-medium leading-normal"
        size="xxs"
        color="orange-100"
        textColor="orange-500"
      >
        <span className="font-medium text-xxs">Overtaken</span>
      </Button>
    );
  };

  return (
    <LoadingWrapper loading={loading}>
      <Empty data={data} title={emptyTitle} content={emptyContent}>
        <div className="overflow-hidden">
          <table className="mb-2 lg:mb-0 min-w-full lg:divide-y divide-blue-gray-200">
            <thead className="hidden lg:table-header-group bg-gray-50">
              <tr>
                <th className="pl-4 lg:pl-6 pr-2 py-3 w-32 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Listing id
                </th>
                <th className="px-2 py-3 text-left text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  item
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Ends
                </th>
                <th className="px-2 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Latest Bid
                </th>
                <th className="pl-2 pr-4 lg:pr-10 py-3 text-right text-xs text-blue-gray-500 leading-4 font-medium uppercase ">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white lg:divide-y divide-blue-gray-200" x-max="1">
              {map(data, (item, index) => (
                <tr
                  className={cx(
                    "hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer",
                    { "lg:bg-gray-50": index % 2 != 0 }
                  )}
                  key={index}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    if (get(item, "status.approved", false)) {
                      history && history.push(get(item, "show.url", "/"));
                    }
                  }}
                >
                  <td className="px-0 lg:pl-4 lg:pr-2 py-0 lg:py-5">
                    <div
                      className={cx(
                        "border lg:border-none border-blue-gray-200 rounded-lg overflow-hidden mx-2",
                        {
                          "mt-2 lg:mt-0": index > 0,
                        }
                      )}
                    >
                      <div className="px-4 lg:px-0 py-4 lg:py-0">
                        <div className="flex lg:block items-center mb-1">
                          <div className="text-sm leading-5 font-medium text-blue-gray-600 flex-1">
                            #{get(item, "show.id", "")}
                          </div>
                          <div className="lg:hidden">
                            {renderOperation(item)}
                          </div>
                        </div>
                        <div className="lg:hidden text-sm font-medium leading-5 mb-1">
                          {get(item, "show.title", "")}
                        </div>
                        <div className="text-xxs leading-5 text-blue-gray-400 lg:hidden">
                          {get(item, "show.expiredAtStr", "")}
                        </div>
                      </div>
                      <div className="lg:hidden bg-gray-50 flex items-center justify-between py-3 px-4">
                        <span className="text-xxs text-blue-gray-600 uppercase">
                          {get(item, "show.price.str", "")}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="hidden text-left lg:table-cell px-2 py-4 max-w-xxs">
                    <div className="text-sm leading-5 font-medium text-blue-gray-600 truncate mb-1">
                      {get(item, "show.title", "")}
                    </div>
                  </td>
                  <td className="hidden text-right lg:table-cell px-2 py-4 lg:whitespace-no-wrap">
                    <div className="text-sm leading-5 text-blue-gray-600 mb-1">
                      {get(item, "show.expiredAtStr", "")}
                    </div>
                  </td>
                  <td className="hidden text-right lg:table-cell px-2 py-4 lg:whitespace-no-wrap">
                    <div className="text-sm leading-5 text-blue-gray-600 mb-1">
                      {get(item, "show.price.str", "")}
                    </div>
                  </td>
                  <td className="hidden lg:table-cell pl-2 pr-4 lg:pr-6 py-4 lg:whitespace-no-wrap">
                    <div className="flex items-center justify-end space-x-2">
                      {renderOperation(item)}
                      <svg
                        className="w-3 h-3"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.17201 5.00008L0.343018 2.17208L1.75701 0.75708L6 5.00008L1.75701 9.24308L0.343018 7.82808L3.17201 5.00008Z"
                          fill="#E0E0E0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Empty>
    </LoadingWrapper>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptyContent: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  loading: false,
  emptyTitle: "",
  emptyContent: "",
};

export default Table;

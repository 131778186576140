import { get } from "lodash";
import moment from "moment";
import { Moment } from "@core";
import { withdrawStatus } from "@config/enum";

const cancelTime = 10; // 10 分钟

/**
 * [Entity] Withdrawal
 * data is obj
  {
    "id": 17,
    "amount":
    {
      "fractional": "20000.0",
      "currency":
      {
        "id": "usd",
        "alternate_symbols": ["US$"],
        "decimal_mark": ".",
        "disambiguate_symbol": "US$",
        "html_entity": "$",
        "iso_code": "USD",
        "iso_numeric": "840",
        "name": "United States Dollar",
        "priority": 1,
        "smallest_denomination": 1,
        "subunit": "Cent",
        "subunit_to_unit": 100,
        "symbol": "$",
        "symbol_first": true,
        "thousands_separator": ","
      },
      "bank":
      {
        "store":
        {
          "index":
          {},
          "options":
          {},
          "mutex":
          {},
          "in_transaction": false
        },
        "rounding_method": null
      }
    },
    "bank_name": null,
    "bank_bsb": "456789456789",
    "account_name": "LucienWang",
    "account_number": "111222333",
    "state": "processing"
  }
 */
export class WithdrawalEntity {
  constructor(data) {
    this.entity = data;
  }

  /**
   * 获取钱包标题
   * @param {String} sheetTitle
   * @param {String} type "debit" ｜ "credit"
   */
  walletTitle(sheetTitle, type = "") {
    // 如果 type 是 debit, 且 state 是取消，则是由取消提现进账，显示 Cancel Withdrawal
    // 否则 是出账，显示 Withdraw｜Withdrawn
    if (this.state === withdrawStatus.canceled && type === "debit") {
      return "Cancel Withdrawal";
    }
    if (this.isFinished) {
      return "Withdrawn";
    }
    return "Withdraw"; // "Withdraw | Withdrawn | Cancel Withdrawal"
  }

  showFees(sheetTitle = "") {
    return false;
  }

  get id() {
    return get(this.entity, "id", "");
  }

  get bankName() {
    return get(this.entity, "bank_name", "");
  }

  get bankBsb() {
    return get(this.entity, "bank_bsb", "");
  }

  get accountName() {
    return get(this.entity, "account_name", "");
  }

  get accountNumber() {
    return get(this.entity, "account_number", "");
  }

  get state() {
    return get(this.entity, "state", "");
  }

  get createAt() {
    return new Moment(get(this.entity, "created_at"));
  }

  get isProcessing() {
    return this.state === withdrawStatus.processing;
  }
  get isCompleted() {
    return this.state === withdrawStatus.completed;
  }
  get isCanceled() {
    return this.state === withdrawStatus.canceled;
  }
  get isFinished() {
    return this.isCompleted || this.isCanceled;
  }
  get isJust() {
    const dateTime = this.createAt.dateTime;
    return moment().isBefore(dateTime.add(cancelTime, "m"));
  }
}

import {
  QUOTA_INDEX_GRADE_DROPDOWN_CHANGE,
  QUOTA_INDEX_GRADE_CHANGE,
  QUOTA_INDEX_LIST_TAB_CHANGE,
} from "./quotaListAction";

export default function quotaListState(state = initStatus, action = {}) {


  switch (action.type) {
    case QUOTA_INDEX_GRADE_DROPDOWN_CHANGE:
      return Object.assign({}, state, {
        gradeDropdownOpen: action.payload.isOpen,
      });
      break;

    case QUOTA_INDEX_GRADE_CHANGE:
      return Object.assign({}, state, {
        grade: action.grade,
      });
      break;

    case QUOTA_INDEX_LIST_TAB_CHANGE:
      return Object.assign({}, state, {
        listTabId: action.payload.tabId,
      });
      break;

    default:
      return state;
  }
}

const initStatus = {
  gradeDropdownOpen: false,
  grade: null,
  listTabId: "tab1",
};
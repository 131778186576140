import { useState, useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector } from "react-redux";
import get from "lodash/get";

/**
 * Action cable connect hooks
 * @param {*} param
 */
export const useActionCableConnect = () => {
  const wsConnectingState = useSelector((state) =>
    get(state, "wsConnectingState", {})
  );
  return wsConnectingState;
};

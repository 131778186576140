export const FISHERY_QUOTA_LIST = "fishery/quota_list";
export const FISHERY_QUOTA_LIST_REQUEST = `${FISHERY_QUOTA_LIST}_request`;
export const FISHERY_QUOTA_LIST_SUCCESS = `${FISHERY_QUOTA_LIST}_success`;
export const FISHERY_QUOTA_LIST_FAILURE = `${FISHERY_QUOTA_LIST}_failure`;

export const fisheryQuotaListAction = {
  request: (payload) => {
    return {
      type: FISHERY_QUOTA_LIST_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: FISHERY_QUOTA_LIST_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: FISHERY_QUOTA_LIST_FAILURE,
      error,
    }
  },
};

import get from "lodash/get";
import map from "lodash/map";
import isArray from "lodash/isArray";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import numeral from "@utils/numeral";
import pluralize from "pluralize";
import { stripeFixedFee, stripeProcessFeeWithGstPercent } from "@config/enum";
/**
 * [BASE Entity] Price Formatter
 * @example
 * {price: {price: "22.00", price_with_symbol: "$22.00"}}
 * usecase.price("price"); 22.00
 * usecase.price("price_with_symbol"); $22.00
 * usecase.price("value"); 22
 */
export class PriceBaseEntity {
  /**
   * Constructor
   * @param {Object} props of price
   */
  constructor(props = {}) {
    this.data = props || {};
  }

  /**
   * [Method] Get price by type
   * @param {string} key price | price_with_symbol | value
   */
  price(type) {
    switch (type) {
      case "price":
        return get(this.data, "price", 0);
      case "price_with_symbol":
        return get(this.data, "price_with_symbol", "$0.00");
      case "value":
        return numeral(get(this.data, "price", 0)).value();
      default:
        return numeral(get(this.data, "price", 0));
    }
  }
}

/**
 * [BASE Entity] Attribute for Quota
 *
 * @example
 * category: {id: 4, name: "Greenlip Abalone"}
 * country: {id: 2, abbr: "AU", name: "Australia"}
 * species: {id: 1, name: "species_1"}
 * state: {id: 8, abbr: "NWS", name: "New South Wales"}
 * zone: {id: 1, abbr: "Z1", name: "zone 1"}
 *
 * const usecase = new QuotaAttrBaseEntity(quota, "category");
 * const categoryName = usecase.name
 */
export class QuotaAttrBaseEntity {
  /**
   * [STATIC METHOD] Get attr of quota
   * @param {Object} quota
   * @param {String} attr category | country | species | state | zone
   * @param {String} key id | name | abbr
   */
  static getAttr(quota) {
    return (attr, key) => {
      const usecase = new QuotaAttrBaseEntity(quota, attr);
      return usecase[key];
    };
  }

  /**
   * Constructor
   * @param {Object} props quota
   * @param {String} attr category | country | species | state | zone
   */
  constructor(props = {}, attr) {
    this.data = get(props, attr, {});
  }

  /**
   * [Getter] Get Attr of id
   */
  get id() {
    return get(this.data, "id", "");
  }

  /**
   * [Getter] Get Attr of abbr
   */
  get abbr() {
    return get(this.data, "abbr", "");
  }

  /**
   * [Getter] Get Attr of name
   */
  get name() {
    return get(this.data, "name", "");
  }
}

/**
 * 格式化select需要的options格式
 * @param data {array}
 * @param key {object} {label: "XX", value:"XX.xx"}
 * @return {array}
 * @example getFormSelectOptions([{id:2, values:{value: "a"}}], {label: "values.value", value: "id"})
 */
export class FormSelectBaseEntity {
  /**
   * 获取 react select 需要的options对象数组
   * @param data options 数据
   * @param key options key 值
   */
  options(data, key) {
    return sortBy(
      map(data, (item) => ({
        label: key ? get(item, key.label) : item,
        value: key ? get(item, key.value) : item,
      })),
      "label"
    );
  }

  /**
   * 获取 react select 需要默认值的对象数组
   * @param data options 数据
   * @param key options key 值
   * @param values 默认数值通常是id
   */
  defaultValue(data, key, values) {
    const options = this.options(data, key);
    if (isArray(values)) {
      return filter(options, ({ value }) => values.includes(value));
    }
    return filter(options, ({ value }) => value == values);
  }
}

/**
 * 获取market的单位和重量
 * @param data lease, auction show data
 *  sale_type: "units_sale"/"kg_sale"
 *  units_collection
 *  total_weight
 *  weight_unit
 *  如果是 units_sale 就显示 units_colleciton 有多少个 units，total_weight 总重量，
 *  其他的地方，例如单价 使用 weight_unit 作为单位;
 *  如果是 kg_sale 就显示 total_weight 就行，units_collection 不用显示
 */

export class UnitBaseEntity {
  static getUnitName(quantity, unitName) {
    if (unitName === "kg") {
      return "kg";
    }
    return ` ${pluralize(unitName || "", Number(quantity || 1))}`;
  }

  static getAmount(data = {}) {
    const usecase = new UnitBaseEntity(data);
    return usecase.amount;
  }

  constructor(props = {}) {
    this.data = props;
  }

  get saleType() {
    return get(this.data, "sale_type", "");
  }

  get priceType() {
    return get(this.data, "price_type", "");
  }

  get isUnitsSale() {
    return this.saleType === "units_sale";
  }

  get isKgSale() {
    return this.saleType === "kg_sale";
  }

  get isKgPrice() {
    return this.priceType === "kg_price";
  }

  get unitsCollectionUnit() {
    const res = get(this.data, "units_collection_unit", "") || "";
    return pluralize(res, 1);
  }

  get unitsCollectionQuantity() {
    return numeral(get(this.data, "units_collection_quantity", "")).value();
  }

  get unitsCollection() {
    const formatVal = numeral(this.unitsCollectionQuantity).format("0,0.00");
    return `${formatVal}${
      this.unitsCollectionUnit === "kg"
        ? pluralize(this.unitsCollectionUnit || "", 1)
        : ` ${pluralize(
            this.unitsCollectionUnit || "",
            Number(this.unitsCollectionQuantity || 0)
          )}`
    }`;
  }

  get unitWeight() {
    return numeral(get(this.data, "unit_weight", "")).value();
  }

  get weightUnit() {
    return get(this.data, "weight_unit", "") || "";
  }

  get unit() {
    return get(this.data, "unit", 0) || 0;
  }

  get totalWeightVal() {
    if (this.isUnitsSale) {
      return numeral(this.unitsCollectionQuantity)
        .multiply(this.unitWeight)
        .value();
    }
    return this.unitsCollectionQuantity;
  }

  get totalWeight() {
    const formatVal = numeral(this.totalWeightVal).format("0,0.00");
    return `${formatVal}${
      this.isKgPrice
        ? pluralize(this.weightUnit || "", 1)
        : ` ${pluralize(
            this.weightUnit || "",
            Number(this.totalWeightVal || 0)
          )}`
    }`;
  }

  get amount() {
    switch (this.priceType) {
      case "units_price":
        return {
          units: this.unitsCollection,
          totalWeight: this.unitsCollection,
          weightUnit: this.unitsCollectionUnit,
          totalWeightVal: this.unitsCollectionQuantity,
        };
      case "kg_price":
        return {
          units: "",
          totalWeight: this.totalWeight,
          weightUnit: this.weightUnit,
          totalWeightVal: this.totalWeightVal,
        };
      default:
        return {
          units: "",
          totalWeight: "",
          weightUnit: "",
          totalWeightVal: 0,
        };
    }
  }
}

/**
 * 前端价格计算公式
 * @param rates  公式比率
 * @return {String}
 * @example getTotalWithGST()
 */

export class CalculateAmount {
  static getPurchasePayment(amount, rates) {
    const _instance = new CalculateAmount(rates);
    const GST = _instance.getGST(amount);
    const totalWithGST = numeral(amount).add(GST).value();
    return {
      value: {
        amount,
        GST,
        totalWithGST,
      },
      str: {
        amount: numeral(amount).format(),
        GST: numeral(GST).format(),
        totalWithGST: numeral(totalWithGST).format(),
      },
    };
  }

  static getSalesIncome(amount, rates) {
    const _instance = new CalculateAmount(rates);
    const serviceFee = _instance.getServiceFee(amount);
    const subtotal = _instance.getSubtotal(amount);
    const GST = _instance.getGST(subtotal);
    const totalWithGST = _instance.getTotalWithGST(amount);

    return {
      value: {
        amount,
        serviceFee,
        subtotal,
        GST,
        totalWithGST,
      },
      str: {
        amount: numeral(amount).format(),
        serviceFee: numeral(serviceFee).format(),
        subtotal: numeral(subtotal).format(),
        GST: numeral(GST).format(),
        totalWithGST: numeral(totalWithGST).format(),
      },
    };
  }

  constructor(rates = {}) {
    const defaults = {
      gst: 0.1,
      serviceFee: 0.05,
    };
    this.rates = Object.assign({}, defaults, rates);
  }

  getGST(amount) {
    return numeral(amount).multiply(this.rates.gst).value();
  }

  getServiceFee(amount) {
    return numeral(amount).multiply(this.rates.serviceFee).value();
  }

  getSubtotal(amount) {
    const serviceFee = this.getServiceFee(amount);
    return numeral(amount).subtract(serviceFee).value();
  }

  getTotalWithGST(amount) {
    const subtotal = this.getSubtotal(amount);
    const gst = this.getGST(subtotal);
    return numeral(subtotal).add(gst).value();
  }
}

/**
 * 前端手续费计算公式
 * stripe 手续费计算
 * @param {amount} 金额
 * @returns {number} 手续费
 * f_fixed = 0.3
 * f_percent = 0.0175
 * fees = (amount + f_fixed) / (1 - f_percent) - amount
 */
export class CaculatedFee {
  static stripe(amount, rates) {
    const _instance = new CaculatedFee(rates);
    return _instance._stripe(amount);
  }

  constructor(rates = {}) {
    const defaults = {
      fixed: stripeFixedFee,
      percent: stripeProcessFeeWithGstPercent,
    };
    this.rates = Object.assign({}, defaults, rates);
  }

  _stripe(amount) {
    let res;
    try {
      res = numeral(amount)
        .add(this.rates.fixed)
        .divide(1 - this.rates.percent)
        .subtract(amount)
        .format("0.00");
    } catch (err) {
      res = 0;
    } finally {
      return res;
    }
  }
}

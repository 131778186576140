import { takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import errorString from "@utils/errorString";

import { PermanentApiUsecase } from "@core";
import { PERMANENT_ACCEPT_BID_REQUEST, permanentAcceptBidAction } from "./action";

export function* watchPermanentAcceptBidFetch() {
  yield takeLatest(PERMANENT_ACCEPT_BID_REQUEST, permanentAcceptBidFetch);
}

function* permanentAcceptBidFetch(action) {
  try {
    const usecase = new PermanentApiUsecase();
    const res = yield call([usecase, "acceptBid"], action.payload);
    yield put(permanentAcceptBidAction.success(res.data));
  } catch (err) {
    const errors = get(err, "response.data.errors", {});
    yield put(permanentAcceptBidAction.failure(errorString(errors)));
  }
}

import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_RESET,
} from "./action";

const loginStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        loading: true,
        data: null,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    case LOGIN_FAILED:
      return {
        loading: false,
        data: null,
        error: action.error,
      };
    case LOGIN_RESET:
      return {
        loading: false,
        data: null,
        error: null,
      };
    default:
      return state;
  }
};

export { loginStatus };

import { AuctionApiRepo } from "@core";

export class AuctionsApiUsecase {
  constructor() {
    this.repo = new AuctionApiRepo();
  }

  show(payload) {
    return this.repo.show(payload);
  }

  new(payload) {
    return this.repo.new(payload);
  }

  list(payload) {
    return this.repo.list(payload);
  }

  finalChancesList() {
    return this.repo.finalChancesList();
  }

  createBid(payload) {
    return this.repo.createBid(payload);
  }

  bids(payload) {
    return this.repo.bids(payload);
  }

  finalBid(payload) {
    return this.repo.finalBid(payload);
  }

  createFinalBid(payload) {
    return this.repo.createFinalBid(payload);
  }

  createInstantBuy(payload) {
    return this.repo.createInstantBuy(payload);
  }
}

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Notice from "@utils/noticeNew";

/**
 * import local tools & hooks
 */
import { useParams, useCreatePermanent, useModal } from "@hooks";

/**
 * import common component
 */
import Layout from "@views/layout";
import { WithConfirmModal, ModalWrapper } from "@views/common";
import { Breadcrumb, Button, DesktopAndTablet, Mobile } from "@components";

/**
 * import local component
 */
import Form from "./form";
// const getUrl = (props) => {
//   const fisheryId = get(props, "match.params.fishery_id", "");
//   const search = get(props, "history.location.search", "");
//   return `/au/fishery/${fisheryId}${search}`;
// };

/**
 * [Page] Quota show
 */
const SellQuotaUnits = ({ toggleConfirmModal }) => {
  const [params] = useParams();
  const type = get(params, "type", "");
  const {
    addNewMarketsAttribute,
    getQuotaOptions,
    getUnitNameByQuotumId,
    unitNameCondition,
    handleSubmit,
    initialValues,
    title,
    totalPrice,
    fisheryOptions,
    loading,
    quotaListLoading,
    isLicence,
  } = useCreatePermanent({ toggleConfirmModal, type });

  const { isOpen, toggleModal } = useModal();
  // const url = getUrl({ history, match });

  const renderContent = () => {
    return (
      <div className="px-2 md:px-0">
        <Breadcrumb className="mt-4 px-2 md:px-0 md:mt-8" back />
        <h2 className="px-2 md:px-0 mt-6 md:pb-2">
          {type === "sell" ? "Listing for Sale" : "Wanted to buy?"}
        </h2>
        <div className="rounded-lg bg-white mt-10 border-t-6 border-fish-primary p-6 shadow-md">
          <h5 className="text-sm font-semibold text-fish-primary mb-4">
            Announcement
          </h5>
          <ul>
            <li>
              BeachPrice applies fees for listing of quota available for sale or
              lease or for acceptance of a wanted to purchase offer or lease in
              offer. Our partner broker will advise the fees along with terms &
              conditions.
            </li>
          </ul>
        </div>
        <div className="mt-6 md:mt-6 px-4 py-6 md:p-6 rounded-lg border border-blue-gray-100 shadow bg-white">
          <div className="mt-2 text-2xl font-bold leading-tight text-blue-gray-800">
            {type == "sell" ? "What are you selling?" : "what are you buying？"}
          </div>
          <div className="text-base font-medium leading-normal text-blue-gray-500">
            {type == "sell"
              ? "Add all the items that you want to sell"
              : "Add all the items that you want to buy"}
          </div>
          <Form
            addNewMarketsAttribute={addNewMarketsAttribute}
            enableReinitialize={true}
            getQuotaOptions={getQuotaOptions}
            getUnitNameByQuotumId={getUnitNameByQuotumId}
            unitNameCondition={unitNameCondition}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            title={title}
            totalPrice={totalPrice}
            fisheryOptions={fisheryOptions}
            loading={loading}
            type={type}
            quotaListLoading={quotaListLoading}
            isLicence={isLicence}
          />
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="gs-page pt-0 pb-0 bg-gray-50">
        <DesktopAndTablet>
          <div className="mx-auto mb-10" style={{ width: 640 }}>
            {renderContent()}
          </div>
        </DesktopAndTablet>
        <Mobile>
          <div className="mb-6">{renderContent()}</div>
        </Mobile>
      </div>
      <ModalWrapper isOpen={isOpen} toggleModal={toggleModal}>
        <React.Fragment>
          <div className="text-2xl font-medium leading-tight text-black">
            Permanent Quota Units Listing Submitted
          </div>
          <div className="mt-4 md:mt-6 text-lg font-normal leading-relaxed text-blue-gray-600">
            We have received your application for permanent listing. We will
            process your request as quickly as possible!
          </div>
          <div className="mt-6 md:mt-10 pb-2 md:pb-0">
            <Button className="w-full md:w-auto button-blue" type="submit">
              Confirm
            </Button>
          </div>
        </React.Fragment>
      </ModalWrapper>
    </Layout>
  );
};

SellQuotaUnits.propTypes = {
  history: PropTypes.object,
  toggleConfirmModal: PropTypes.func,
  match: PropTypes.object,
};

SellQuotaUnits.defaultProps = {
  toggleConfirmModal: () => {},
};

export default WithConfirmModal({
  title: "Well Done!", //modal的标题
  url: "/",
  text:
    "We have received your Permanent Listing.  Once it has been reviewed, your listing will be published and other Followers of the Fishery will be automatically notified.  Thank you for using BeachPrice.com", //modal的内容
  button: "Confirm",
  callback: () => {
    Notice.success("Submitted successfully!");
  },
})(SellQuotaUnits);

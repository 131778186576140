import { createSelector } from "reselect";
import {
  FISHERY_SHOW_SUCCESS,
  FISHERY_SHOW_FAILURE,
  FISHERY_SHOW_UPDATE,
} from "./action";

export const fisheryShowStatus = (state = initStatus, action = {}) => {
  const updateSelector = createSelector(
    (state) => state.data,
    (_, payload) => payload,
    (data, payload) => {
      return Object.assign({}, data, payload);
    }
  );

  switch (action.type) {
    case FISHERY_SHOW_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case FISHERY_SHOW_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case FISHERY_SHOW_UPDATE:
      return {
        data: updateSelector(state, action.payload),
        error: action.error,
      };

    default:
      return state;
  }
};

const initStatus = {
  data: {},
  error: null,
};

import React, { Component } from "react";

/**
 * import lib tools
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submit } from "redux-form";
import { get, isEmpty } from "lodash";
import Notice from "@utils/noticeNew";

/**
 * action
 */
import { verifyEmailConfirmationCodeRequest } from "@redux/modules/verifyEmail/action";
import { refreshCognitoUserRequest } from "@redux/modules/refreshCognitoUser/action";
import {
  refreshCurrentUser,
  refreshCurrentUserReset,
  logout,
} from "@redux/modules/auth/action";
import { changeEmailEventRequest } from "@redux/modules/changeEmailEvent/action";

/**
 * local components
 */
import { Button } from "@components";
import VerifyForm from "./_verify_form";
import ResetCode from "./_reset_code";

class Verify extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      this.props.codeVerifyStatus.loading &&
      nextProps.codeVerifyStatus.error
    ) {
      // code verify failed
      Notice.failure(get(nextProps, "codeVerifyStatus.error.message"));
      return;
    }

    if (
      get(nextProps, "currentUserRefreshStatus.data") &&
      get(nextProps, "currentUserRefreshStatus.data") !==
        get(this.props, "currentUserRefreshStatus.data")
    ) {
      this.props.refreshCurrentUserReset();
      this.changeEmailEventRequest({ oldEmail: this.getOldEmail() });
      Notice.success("Email changed successfully");
      setTimeout(() => {
        this.props.history.push && this.props.history.push("/setting");
      }, 2 * 1000);
    }
    if (
      !isEmpty(nextProps.logoutStatus.data) &&
      nextProps.logoutStatus.data != this.props.logoutStatus.data
    ) {
      //success
      Notice.success("You have been successfully logged out!");
      this.props.history && this.props.history.push("/");
    }
    if (
      !isEmpty(nextProps.logoutStatus.error) &&
      nextProps.logoutStatus.error != this.props.logoutStatus.error
    ) {
      //error
      Notice.failure("Log out failed");
      return;
    }
  }

  getOldEmail = () => {
    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get("old_email");
  };

  formInitializeValues = () => ({
    code: "",
  });

  /**
   * callback for quote form submit
   */

  handleSubmit = ({ code }) => {
    this.verifyEmailConfirmationCodeRequest({ code });
  };

  resendEmailConfirmationCodeRequest = () => {
    this.props.resendEmailConfirmationCodeRequest();
  };

  verifyEmailConfirmationCodeRequest = (payload) => {
    this.props.verifyEmailConfirmationCodeRequest(payload);
  };

  refreshCognitoUserRequest = () => {
    this.props.refreshCognitoUserRequest();
  };

  changeEmailEventRequest = (payload) => {
    this.props.changeEmailEventRequest(payload);
  };

  /**
   * remote submit order form
   */

  onSubmit = () => {
    this.props.dispatch(submit("VerifyForm"));
  };

  render() {
    return (
      <div className="gs-page account-page">
        <div className="container">
          <div className="panel">
            <div className="panel-body">
              <div className="form gs-form">
                <h3>Verification Code</h3>
                <ResetCode />
                <VerifyForm
                  onSubmit={this.handleSubmit}
                  enableReinitialize={true}
                  initialValues={this.formInitializeValues()}
                />
              </div>
            </div>
            <div className="panel-bottom clearfix flex items-end justify-end">
              <Button
                className="capitalize mr-4"
                color="fish-primary"
                size="lg"
                outline
                onClick={() => {
                  this.props.logout && this.props.logout();
                }}
              >
                Cancel
              </Button>
              <Button
                className="capitalize"
                color="fish-primary"
                size="lg"
                onClick={this.onSubmit}
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    codeVerifyStatus: state.verifyEmailConfirmationCodeStatus,
    currentUserRefreshStatus: state.currentUserRefreshStatus,
    logoutStatus: state.logoutStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    {},
    bindActionCreators(
      {
        verifyEmailConfirmationCodeRequest,
        refreshCognitoUserRequest,
        refreshCurrentUser,
        refreshCurrentUserReset,
        changeEmailEventRequest,
        logout,
      },
      dispatch
    ),
    {
      dispatch,
    }
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Verify);

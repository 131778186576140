import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * [COMPONENT] Modal PopUp 手机端底部向上滑动modal框
 * @param {Object}
 */
const ModalPopUp = ({ open, className, children, toggle }) => {
  return (
    <>
      {open ? (
        <div className="fixed inset-0 bg-black opacity-25 z-10" onClick={toggle} />
      ) : null}
      <div
        className={cx("fixed right-0 bottom-0 left-0 transform translate-x-0 z-20", className, {
          "translate-y-full": !open,
          "translate-y-0 transition ease-out duration-200": open,
        })}
      >
        {children}
      </div>
    </>
  );
};

ModalPopUp.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  toggle: PropTypes.func,
};

ModalPopUp.defaultProps = {
  open: false,
  className: "",
  toggle: () => {},
};

export default ModalPopUp;

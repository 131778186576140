const RECHARGE_DEPOSIT_REQUEST = "recharge_deposit/request"
const RECHARGE_DEPOSIT_LOADING = "recharge_deposit/loading"
const RECHARGE_DEPOSIT_SUCCESS = "recharge_deposit/success"
const RECHARGE_DEPOSIT_FAILED = "recharge_deposit/failed"

function rechargeDepositRequest(payload) {
  return {
    type: RECHARGE_DEPOSIT_REQUEST,
    payload
  }
}

function rechargeDepositLoading() {
  return {
    type: RECHARGE_DEPOSIT_LOADING
  }
}

function rechargeDepositSuccess(data) {
  return {
    type: RECHARGE_DEPOSIT_SUCCESS,
    data
  }
}

function rechargeDepositFailed(error) {
  return {
    type: RECHARGE_DEPOSIT_FAILED,
    error
  }
}

export {
  RECHARGE_DEPOSIT_FAILED,
  RECHARGE_DEPOSIT_SUCCESS,
  RECHARGE_DEPOSIT_LOADING,
  RECHARGE_DEPOSIT_REQUEST,

  rechargeDepositFailed,
  rechargeDepositLoading,
  rechargeDepositRequest,
  rechargeDepositSuccess
}
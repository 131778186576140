import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

/**
 * import hooks
 */
import { useNewSell, useParams } from "@hooks";

/***
 * import local components
 */
import Notice from "@utils/noticeNew";
import Layout from "@views/layout";
import { WithConfirmModal } from "@views/common";
import { Breadcrumb, SEO } from "@components";
import Steps from "./_steps";

/**
 * Import context
 */
import { SellParamsContext } from "@contexts";

/**
 * [Page] Market(lease) transfer quota to BP
 */
const ListingNew = ({ toggleConfirmModal }) => {
  const [params] = useParams();
  const props = useNewSell({
    toggleConfirmModal,
  });

  return (
    <SellParamsContext.Provider value={params}>
      <Layout hideHeader>
        <SEO title="Create your listing - BeachPrice" />
        <div className="gs-page pt-0 bg-gray-50 page-pb pb-16">
          <div className="mx-auto max-w-xl md:px-2">
            <Breadcrumb className="pt-6 md:pt-10 px-2 md:px-0" back />
            <Steps {...props} />
          </div>
        </div> 
      </Layout>
    </SellParamsContext.Provider>
  );
};

ListingNew.propTypes = {
  toggleConfirmModal: PropTypes.func,
};

ListingNew.defaultProps = {
  toggleConfirmModal: () => {},
};

export default WithConfirmModal({
  title: "Successfully submitted!", //modal的标题
  url: "/listings",
  closedUrl: "/listings",
  text: (
    <div>
      Your listing has been successfully submitted. We’re reviewing your listing
      and will let you know once it goes live. It generally takes a few hours
      during normal business hours, at most 1 business day. If you have any
      questions, please contact us at{" "}
      <a
        className="text-fish-primary underline"
        href="mailto:hello@beachprice.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        hello@beachprice.com
      </a>
    </div>
  ), //modal的内容
  button: "Continue",
  callback: () => {
    Notice.success("Submitted successfully!");
  },
})(ListingNew);

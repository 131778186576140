export const WITHDRAW_CREATE = "withdraws/create";
export const WITHDRAW_CREATE_REQUEST = `${WITHDRAW_CREATE}_request`;
export const WITHDRAW_CREATE_SUCCESS = `${WITHDRAW_CREATE}_success`;
export const WITHDRAW_CREATE_FAILURE = `${WITHDRAW_CREATE}_failure`;
export const WITHDRAW_CREATE_RECEIVED = `${WITHDRAW_CREATE}_received`;

export const withdrawCreateAction = {
  request: (payload) => {
    return {
      type: WITHDRAW_CREATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: WITHDRAW_CREATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: WITHDRAW_CREATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: WITHDRAW_CREATE_RECEIVED
    }
  },
};

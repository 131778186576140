import { takeLatest, call, put } from "redux-saga/effects";

import { WithdrawApiUsecase } from "@core";
import { WITHDRAW_LIST_REQUEST, withdrawListAction } from "./action";

// fetch withdraw list
export function* watchWithdrawListFetch() {
  yield takeLatest(WITHDRAW_LIST_REQUEST, withdrawListFetch);
}

function* withdrawListFetch(action) {
  try {
    const usecase = new WithdrawApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(withdrawListAction.success(res.data));
  } catch (err) {
    yield put(withdrawListAction.failure(err));
  }
}

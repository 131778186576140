import React from "react";
import get from "lodash/get";

/**
 * import local tools & config & hooks
 */
import { useOrderSold } from "@hooks";

/**
 * import local component
 */
import Table from "./_table";

const Sales = () => {
  const {
    usecase: usecaseOrderSold,
    loading: loadingOrderSold,
    data: pageSoldData,
    fetchOrderSold,
  } = useOrderSold();

  return (
    <div>
      <Table
        data={get(usecaseOrderSold, "list", [])}
        pageData={pageSoldData}
        loading={loadingOrderSold}
        fetchData={fetchOrderSold}
        emptyTitle="You have no sales orders yet"
      />
    </div>
  );
};

Sales.propTypes = {};

export default Sales;

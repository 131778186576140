import lowerCase from "lodash/lowerCase";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import get from "lodash/get";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import indexOf from "lodash/indexOf";
// import uniqWith from "lodash/uniqWith";

import {
  QuotaAttrApiUsecase,
  FormSelectBaseEntity,
  AllSpeciesOption,
  AllStatesOption,
  UrlUtils,
  SearchType,
} from "@core/index";

export class QuotaAttrUsecase {
  /**
   * 构造函数
   * @param {Object} props
   */
  constructor(props = []) {
    this.data = props;
  }

  /**
   * 获取category搜索select的species options
   * @param inputValue
   */
  async getSpeciesByTerm(inputValue) {
    try {
      const usecase = new QuotaAttrApiUsecase();
      const select = new FormSelectBaseEntity();
      const param = UrlUtils.compactParams({
        term: lowerCase(inputValue),
      });
      const res = await usecase.species(param);
      let options = select.options(res.data, {
        label: "name",
        value: "id",
      });
      return [AllSpeciesOption, ...options];
    } catch (error) {
      return [];
    }
  }

  get states() {
    const select = new FormSelectBaseEntity();
    const options = select.options(this.data, {
      label: "name",
      value: "id",
    });
    return [AllStatesOption, ...options];
  }

  categoriesByKey(key = "id") {
    const select = new FormSelectBaseEntity();
    const options = select.options(this.data, {
      label: "name",
      value: key,
    });
    return options;
  }

  /**
   * 按照 term 来同时搜索 species 和 category
   * @example category response
   * [
   *  {"name":"Spanner Crab","type":"Category","data":{"id":2,"name":"Spanner Crab"}},
   *  {"name":"Licences \u0026 Entitlements","type":"Category","data":{"id":3,"name":"Licences \u0026 Entitlements"}},
   *  {"name":"Coral Trout","type":"Category","data":{"id":6,"name":"Coral Trout"}},
   * ]
   */
  async getCategoryByTerm(inputValue) {
    try {
      const usecase = new QuotaAttrApiUsecase();
      const select = new FormSelectBaseEntity();
      const param = UrlUtils.compactParams({
        term: lowerCase(inputValue),
      });
      const res = await usecase.categoryByTerm(param);
      let options = select.options(res.data, {
        label: "name",
        value: "id",
      });
      return [AllSpeciesOption, ...options];
    } catch (error) {
      return [];
    }
  }

  /**
   * 格式化搜索表单product的id转换成对应的params用于列表接口的筛选
   * @params {Object} values {state_id, product, market_type}
   * @return {Object}
   * @example {product: category_id-23} /=> {category_id: 23}
   */
  formValuesToParams(values = {}) {
    let res = omit(values, ["product"]);
    let product = get(values, "product", "");
    let state_id = get(values, "state_id", "");

    if (product instanceof Array) {
      let o = {};

      if (indexOf(product, "all") > -1) {
        o["product"] = "all";
      } else {
        map(product, (item) => {
          const [key, value] = item.split("-");
          if (o[key]) {
            o[key] = [...o[key], value];
          } else {
            o[key] = [value];
          }
        });
      }

      res = Object.assign({}, res, o);
    }

    if (state_id instanceof Array && indexOf(state_id, "all") > -1) {
      res = Object.assign({}, res, { state_id: "all" });
    }

    if (product === "all" || product === "") {
      res = Object.assign({}, res, { product });
    }

    // if (typeof product === "string") {
    //   const [key, value] = product.split("-");
    //   res = Object.assign({}, res, { [key]: value });
    // }

    return res;
  }

  /**
   * 格式化params的category_id 和 species_id 转换成搜索表单中的product字段
   * @params {Object} {category_id: xx, state_id: xxx}
   * @return {Object}
   * @example {category_id: 23} /=> {product: category_id-23}
   */
  paramsToFormValue(params = {}) {
    if (isEmpty(params)) {
      return {};
    }

    let res = omit(params, ["category_id", "species_id", "keywords"]);
    let product = get(params, "product", []);
    const category_id = get(params, "category_id", "");
    const species_id = get(params, "species_id", "");
    let state_id = get(params, "state_id", "");

    if (category_id) {
      if (category_id.match(",")) {
        const values = category_id.split(",");
        map(values, (val) => {
          product = [...product, `category_id-${val}`];
        });
      } else {
        product = [...product, `category_id-${category_id}`];
      }
    }

    if (species_id) {
      if (species_id.match(",")) {
        const values = species_id.split(",");
        map(values, (val) => {
          product = [...product, `species_id-${val}`];
        });
      } else {
        product = [...product, `species_id-${species_id}`];
      }
    }

    if (state_id && state_id.match(",")) {
      state_id = map(state_id.split(","), id => Number(id));
    }

    return Object.assign({}, res, { product, state_id });
  }
}

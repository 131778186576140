import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";

import {
  fisheryQuotaListAction,
  FISHERY_QUOTA_LIST,
} from "@redux/modules_v2/fishery/quotaList/action";

/**
 * Fetch quota list hooks
 * @param {*} param
 */
export const useFisheryQuotaList = (id) => {
  const dispatch = useDispatch();

  const fetchList = (id) => {
    dispatch(fisheryQuotaListAction.request({ id }));
  };

  const fisheryQuotaListStatus = useSelector((state) => {
    const selector = new LoadingSelector(FISHERY_QUOTA_LIST, state);
    return Object.assign({}, get(state, "fisheryQuotaListStatus", {}), {
      loading: selector.loading,
    });
  });

  const { data, loading } = fisheryQuotaListStatus;
  /**
   * did mount fetch list
   */
  useEffect(() => {
    id && fetchList(id);
  }, [id]);

  return [data, loading];
};

const ORDERS_CLOSE_REQUEST = "orders/close_request";
const ORDERS_CLOSE_LOADING = "orders/close_loading";
const ORDERS_CLOSE_SUCCESS = "orders/close_success";
const ORDERS_CLOSE_FAILED = "orders/close_failed";
const ORDERS_CLOSE_RECEIVED = "orders/close_received"

const closeOrderRequest = payload => (
  {
    type: ORDERS_CLOSE_REQUEST,
    payload
  }
)

const closeOrderLoading = () => (
  {
    type: ORDERS_CLOSE_LOADING
  }
)

const closeOrderSuccess = data => (
  {
    type: ORDERS_CLOSE_SUCCESS,
    data
  }
)

const closeOrderFailed = error => (
  {
    type: ORDERS_CLOSE_FAILED,
    error
  }
)

const closeOrderReceived = () => (
  {
    type: ORDERS_CLOSE_RECEIVED
  }
)

export {
  ORDERS_CLOSE_REQUEST,
  ORDERS_CLOSE_LOADING,
  ORDERS_CLOSE_SUCCESS,
  ORDERS_CLOSE_FAILED,
  ORDERS_CLOSE_RECEIVED,

  closeOrderRequest,
  closeOrderSuccess,
  closeOrderLoading,
  closeOrderFailed,
  closeOrderReceived,

}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Items from "./items";
import ItemsSimilar from "./itemsSimilar";

const List = ({ data, image, className, isSimilar }) => {
  return (
    <ul
      className={cx(className, {
        "space-y-2": isSimilar,
        "space-y-4": !isSimilar,
      })}
      aria-disabled="true"
    >
      {isSimilar ? (
        <ItemsSimilar data={data} image={image} />
      ) : (
        <Items data={data} image={image} />
      )}
    </ul>
  );
};

List.propTypes = {
  isSimilar: false,
};

export default List;

import { takeLatest, call, put } from "redux-saga/effects";

import { AuctionsApiUsecase } from "@core";
import { AUCTION_LIST_REQUEST, auctionListAction } from "./action";

//Fetch auction list
export function* watchAuctionListFetch() {
  yield takeLatest(AUCTION_LIST_REQUEST, auctionListFetch);
}

function* auctionListFetch(action) {
  try {
    const usecase = new AuctionsApiUsecase();
    const res = yield call([usecase, "list"], action.payload);
    yield put(auctionListAction.success(res.data));
  } catch (err) {
    yield put(auctionListAction.failure(err));
  }
}
 
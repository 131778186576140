import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {userPool} from './config'

/**
 * Sign up with email and password
 * @param email
 * @param password
 * @param opts
 * @return {Promise<any>}
 */
function signUp(email, password, opts) {
  let attributeList = []
  let dataEmail = {
    Name: 'email',
    Value: email
  }
  let attributeEmail = new CognitoUserAttribute(dataEmail)

  attributeList.push(attributeEmail)

  const inviteCode = opts["invite_code"];
  if (typeof inviteCode !== "undefined" && inviteCode) {
    let dataInviteCode = {
      Name: 'custom:invite_code',
      Value: inviteCode
    }
    let attributeInviteCode = new CognitoUserAttribute(dataInviteCode)
    attributeList.push(attributeInviteCode)
  }

  const brokerCode = opts["broker_code"];
  if (typeof brokerCode !== "undefined" && brokerCode) {
    let dataBrokerCode = {
      Name: "custom:broker_code",
      Value: brokerCode
    }
    let attributeBrokerCode = new CognitoUserAttribute(dataBrokerCode)
    attributeList.push(attributeBrokerCode)
  }

  const activityCode = opts["activity_code"];
  if (typeof activityCode !== "undefined" && activityCode) {
    let dataActivityCode = {
      Name: 'custom:activity_code',
      Value: activityCode
    }
    let attributeActivityCode = new CognitoUserAttribute(dataActivityCode)
    attributeList.push(attributeActivityCode)
  }

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        // console.log("cognito register user failed:")
        reject(err)
      } else {
        // console.log("cognito register user successful:")
        resolve(result)
      }
    })
  })
}

/**
 * Send the verification code to confirm sign up
 * @param email
 * @param code
 * @return {Promise<any>}
 */
function confirmRegistration(email, code) {
  let userData = {
    Username: email,
    Pool: userPool
  }
  let cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        // console.log("cognito confirm user failed:")
        // console.log(err)
        reject(err)
      } else {
        // console.log("cognito confirm user successful:")
        // console.log(result)
        resolve(result)
      }
    })
  })
}

/**
 * Resend the verification code
 * @param email
 * @return {Promise<any>}
 */
function resendConfirmationCode(email) {
  let userData = {
    Username: email,
    Pool: userPool
  }
  let cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        // console.log("cognito resend confirmation code failed:")
        // console.log(err)
        reject(err)
        return
      }
      // console.log("cognito resend confirmation code success:")
      // console.log(result)
      resolve(result)
    })
  })
}

export {
  signUp,
  confirmRegistration,
  resendConfirmationCode
}


import { takeLatest, call, put } from "redux-saga/effects";

import { AccountApiUsecase } from "@core";
import { NOTIFICATION_LIST_REQUEST, notificationListAction } from "./action";

//Fetch notification list
export function* watchNotificationListFetch() {
  yield takeLatest(NOTIFICATION_LIST_REQUEST, notificationListFetch);
}

function* notificationListFetch(action) {
  try {
    const usecase = new AccountApiUsecase();
    const res = yield call([usecase, "notificationList"], action.payload);
    yield put(notificationListAction.success(res.data));
  } catch (err) {
    yield put(notificationListAction.failure(err));
  }
}
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import moment from "moment";
import get from "lodash/get";
import takeRight from "lodash/takeRight";
import orderBy from "lodash/orderBy";
import minBy from "lodash/minBy";
import take from "lodash/take";
import reverse from "lodash/reverse";
import numeral from "@utils/numeral";

/**
 * format data
 * @param {Array} data: price entries
 * @param {Number} num: quantity for data
 * @return {Object}
 */
const formatData = (data = [], num, borderWidth, pointRadius, isBgGradient, labelShowIndex) => {
  const sortedData = labelShowIndex ? reverse(data): orderBy(data, ["created_at"]);
  const filterData = num ? takeRight(sortedData, num) : sortedData;
  // const labels = filterData.map(item => moment("2019-09-25T16:59:35.679+10:00").format("DD MMM HH:mm a"));
  const labels = filterData.map((item) =>
    moment(item.created_at).format("DD MMM HH:mm a")
  );
  const prices = filterData.map((item) => Number(item.price_cents) / 100);
  
  const getGradient = () =>{
    const canvas = document.getElementById('myChart');
    const ctx = canvas.getContext('2d');
    let gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0,'rgba(183, 128, 255,0.4)');
    gradient.addColorStop(0.5,'rgba(183, 128, 255,0.2)');
    gradient.addColorStop(1,'rgba(183, 128, 255,0)');
    return gradient;
  }

  return {
    labels,
    datasets: [
      {
        label: "",
        fill: true, // 是否显示背景颜色
        lineTension: 0, // 折线弯曲弧度0.5
        backgroundColor: isBgGradient ? getGradient : "rgba(183, 128, 255, .3)", // 背景渐变，auction show -> past auction Chart
        borderWidth: borderWidth,
        borderColor: "rgba(183, 128, 255, 1)",
        // borderCapStyle: 'butt',
        // borderDash: [],
        // borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        // pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: "rgba(183, 128, 255,1)",
        // pointBorderWidth: 1,
        pointHoverRadius: 2,
        // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        // pointHoverBorderColor: 'rgba(220,220,220,1)',
        // pointHoverBorderWidth: 2,
        pointRadius: pointRadius, // 圆点大小，0不显示
        pointHitRadius: 4,
        data: prices,
      },
    ],
  };
};

/**
 * [Component] Chart Line
 */
const ChartFull = (props) => {
  const { data, kind, width, height, borderWidth, pointRadius, yPosition, showX, zoneLineColor, labelShowIndex, isBgGradient } = props;
  const chartKind = kind && kind == "simple" ? false : true;
  const list = useMemo(() => {
    return data;
  }, [data]);

  const minValue = minBy(list, "price_cents");
  const yTickOption =
    minValue && minValue.price_cents < 100 && minValue.price_cents >= 0
      ? {
          min: numeral(minValue.price_cents).divide(100).multiply(0.9).format("0.00"),
        }
      : {};

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: chartKind,
      trigger: "axis",
      mode: "label",
      displayColors: false,
      intersect: false,
      callbacks: {
        // use label callback to return the desired label
        label: function (tooltipItem) {
          return numeral(tooltipItem.yLabel).format();
        },
        footer: function (tooltipItem) {
          return get(tooltipItem, "[0][xLabel]", "");
          // return '18 Sep 20:10 pm'
        },
        // use title
        title: () => {
          return;
        },
        // afterTitle: function(tooltipItem) {
        //   return ('Latest Price: '+ numeral(lastPrice.price / 100).format());
        // },
      },
      xPadding: 12,
      yPadding: 8,
      bodyFontSize: 16,
      bodyFontStyle: "bold",
      bodyAlign: "center",
      footerFontStyle: "normal",
      footerFontColor: "#BAC6CD",
      footerAlign: "center",
      titleFontSize: 16,
      titleAlign: "left",
      titleFontStyle: "normal",
    },
    elements: {
      line: {
        fill: true,
      },
    },
    layout: {
      padding: {
        left: yPosition=="right"?0:-25, // Y轴坐标根据位置修改padding值
        right: yPosition=="right"?-20:2,
        top: 10,
        bottom: 10,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            lineWidth: 0,
            zeroLineWidth: zoneLineColor ? 1 : 0,
            zeroLineColor: zoneLineColor || "#f7fafc", // X轴第一条线显示条件和颜色
          },
          display: chartKind,
          scaleLabel: {
            display: false,
            labelString: "",
          },
          ticks: {
            display: showX, // 控制X轴坐标label是否显示
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              if(labelShowIndex) { // X轴坐标值显示内容
                return index;
              }
              return take(value.split(" "), 2).join(" ");
            },
            fontColor: "#94A3B8",
            align: "end",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "#f7fafc",
            lineWidth: zoneLineColor ? 0 : 1,
            zeroLineColor: zoneLineColor || "#f7fafc", // Y轴第一条线显示颜色
          },
          display: chartKind,
          position: yPosition, // 控制Y轴坐标显示位置（左/右）
          scaleLabel: {
            display: true,
            labelString: "",
          },
          // y轴坐标轴刻度值自定义
          ticks: {
            ...yTickOption,
            maxTicksLimit: 6,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value < 1
                ? numeral(value).format("$0,0.000")+' '
                : numeral(value).format()+' ';
            },
            beginAtZero: true,
            fontColor: "#94A3B8",
          },
        },
      ],
    },
  };
  return (
    <div className="chart-wrapper font-inter">
      <Line
        data={formatData(list, "", borderWidth, pointRadius, labelShowIndex, isBgGradient, labelShowIndex)}
        options={options}
        height={height}
        width={width}
        redraw={false}
        id="myChart"
      />
    </div>
  );
};

ChartFull.propTypes = {
  height: PropTypes.number,
  showX: PropTypes.bool,
  labelShowIndex: PropTypes.bool,
  isBgGradient: PropTypes.bool,
};

ChartFull.defaultProps = {
  height: 180,
  borderWidth: 2,
  pointRadius: 1,
  yPosition: "right",
  showX: true,
  zoneLineColor: "",
  labelShowIndex: false,
  isBgGradient: false,
};

export default ChartFull;

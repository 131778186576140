import {
  PRODUCT_NEW_LOADING,
  PRODUCT_NEW_SUCCESS,
  PRODUCT_NEW_FAILED
} from "../products/newAction";

export default (state = {}, action = {}) => {
  switch (action.type) {
    case PRODUCT_NEW_LOADING:
      return {
        loading: true,
        data: null,
        error: null
      };
    case PRODUCT_NEW_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
    case PRODUCT_NEW_FAILED:
      return {
        loading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

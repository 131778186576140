import { useEffect, useState, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import * as Yup from "yup";

/**
 * import lib tools
 */
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

/**
 * import local tools & redux & usecase
 */
import { loginRequest, loginReset } from "@redux/modules/login/action";
import { UrlUtils } from "@core/index";
import Notice from "@utils/noticeNew";

/**
 * Login hooks
 * @param {*} param
 */
export const useLogin = (cb = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  /**
   * 登录提交后状态
   */
  const loginStatus = useSelector((state) => get(state, "loginStatus", {}));

  /**
   * 初始化表单
   */
  const [initialValues, setInitalValues] = useState({
    email: "",
    password: "",
  });

  const [email, setEmail] = useState("");

  /**
   * [Callback] 提交表单
   */
  const onSubmit = useCallback((values) => {
    setEmail(get(values, "email", ""));
    dispatch(loginRequest(values));
  }, []);

  /**
   * Schema
   */
  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(5, "Password length should be greater than or equal to 6")
      .required("Required"),
  });

  /**
   * [Callback] 登录失败后根据报错信息返回不同的结果
   */
  const onError = useCallback(
    (error) => {
      const code = get(error, "code", "");
      const message = get(error, "message", "");
      switch (code) {
        case "UserNotConfirmedException":
          cb.toggleModal && cb.toggleModal();
          break;
        case "PasswordResetRequiredException":
          Notice.success(
            "As our system is being upgraded, please reset your password to protect your account security."
          );
          history && history.push("/account/password/forgot");
          break;
        default:
          Notice.failure(message);
      }
    },
    [cb, email]
  );

  /**
   * 提交表单后的effect
   */
  useEffect(() => {
    return () => {
      setInitalValues({});
      dispatch(loginReset());
    };
  }, []);

  useEffect(() => {
    if (!loginStatus.loading) {
      if (!isEmpty(loginStatus.data)) {
        // login success
        Notice.success("Logged in successfully!");
        setTimeout(() => {
          const redirectTo =
            UrlUtils.queryAfterKey(location.search, "redirect_to") || "/dashboard" ;
          if (window.location) {
            window.location.href = redirectTo;
          }
        }, 100);
      }
      if (loginStatus.error) {
        onError(loginStatus.error);
      }
    }
  }, [loginStatus.loading]);

  return {
    initialValues,
    email,
    loading: loginStatus.loading,
    schema,
    onSubmit,
  };
};

import { QuotaAttrBaseEntity } from "./baseEntity";
/**
 * [Entity] Bread crumbs
 * @example
 *
 */

export class BreadcrumbEntity {
  /**
   * Constructor
   * @param {Object} quota
   */
  constructor(props = {}) {
    this.quota = props;
    this.getAttr = QuotaAttrBaseEntity.getAttr(props);
  }

  /**
   * [Function] Get price by type
   * @param {Ojbect} param { category_id, category_name, country_name, state_name, state_id }
   * @returns [{url:XXX, name: XXX}, {url:XXX, name: XXX}]}]
   */
  nav({ category_id, category_name, country_name, state_name, state_id } = {}) {
    let res = [];
    if (state_id) {
      res.unshift({
        name: this.getAttr("state", "name"),
        url: `/${country_name}/c/${category_name}/${state_name}/${state_id}}`
      });
    }
    const categoryId = category_id
      ? category_id
      : this.getAttr("category", "id");
    res.unshift({
      name: category_name,
      url: `/${country_name}/c/${category_name}/${categoryId}`
    });
    res.unshift({ name: "Home", url: "/" });
    return res;
  }
}

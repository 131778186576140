const LICENCE_NUMBER_EVENTS_REQUEST = 'licence_number_events/request';
const LICENCE_NUMBER_EVENTS_LOADING = 'licence_number_events/loading';
const LICENCE_NUMBER_EVENTS_SUCCESS = 'licence_number_events/success';
const LICENCE_NUMBER_EVENTS_FAILED = 'licence_number_events/failed';

function licenceNumberEventsRequest(payload) {
  return {
    type: LICENCE_NUMBER_EVENTS_REQUEST,
    payload
  };
}

function licenceNumberEventsLoading() {
  return {
    type: LICENCE_NUMBER_EVENTS_LOADING
  };
}

function licenceNumberEventsSuccess(data) {
  return {
    type: LICENCE_NUMBER_EVENTS_SUCCESS,
    data
  };
}

function licenceNumberEventsFailed(error) {
  return {
    type: LICENCE_NUMBER_EVENTS_FAILED,
    error
  };
}

export {
  licenceNumberEventsRequest,
  licenceNumberEventsLoading,
  licenceNumberEventsSuccess,
  licenceNumberEventsFailed,
  LICENCE_NUMBER_EVENTS_REQUEST,
  LICENCE_NUMBER_EVENTS_LOADING,
  LICENCE_NUMBER_EVENTS_SUCCESS,
  LICENCE_NUMBER_EVENTS_FAILED
};

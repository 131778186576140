

import {
  PRODUCTS_RECENT_TRADE_FETCH_LOADING,
  PRODUCTS_RECENT_TRADE_FETCH_SUCCESS,
  PRODUCTS_RECENT_TRADE_FETCH_FAILED,
} from "./recentTradeAction";


export const productRecentTradeStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case PRODUCTS_RECENT_TRADE_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null,
      };
      break;

    case PRODUCTS_RECENT_TRADE_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;

    case PRODUCTS_RECENT_TRADE_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: [],
  error: null,
};


import {
  CHANNEL_MESSAGES_FETCH_REQUEST,
  CHANNEL_MESSAGES_FETCH_SUCCESS,
  CHANNEL_MESSAGES_FETCH_FAILED,
  CHANNEL_MESSAGES_FETCH_LOADING,
  CHANNEL_MESSAGES_FETCH_RECEIVED,

  CHANNEL_MESSAGES_PUSH,
  CHANNEL_MESSAGES_SLICE,
} from "./messageListAction";


export const channelMessageListStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case CHANNEL_MESSAGES_FETCH_LOADING:
      return {
        justFinished: false,
        loading: true,
        data: [],
        error: null,
      };
      break;
    case CHANNEL_MESSAGES_FETCH_SUCCESS:
      return {
        justFinished: true,
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case CHANNEL_MESSAGES_FETCH_FAILED:
      return {
        justFinished: true,
        loading: false,
        data: [],
        error: action.error,
      };
      break;

    case CHANNEL_MESSAGES_FETCH_RECEIVED:
      return {
        justFinished: false,
        loading: false,
        data: state.data,
        error: null,
      };

    case CHANNEL_MESSAGES_PUSH:
      let list = [];
      list = list.concat(state.data);
      list = list.concat([action.message]);
      return {
        justFinished: false,
        loading: false,
        data: list,
        error: null,
      }

    default:
      return state;
  }
};

const initStatus = {
  justFinished: false,
  loading: false,
  data: [],
  error: null,
};

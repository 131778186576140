import {
  LISTING_CREATE_LOADING,
  LISTING_CREATE_SUCCESS,
  LISTING_CREATE_FAILED,
  LISTING_CREATE_RESET
} from "./createAction";

export const listingCreateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case LISTING_CREATE_LOADING:
      return {
        loading: true,
        data: {},
        error: null,
      };
      break;
    case LISTING_CREATE_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
      };
      break;
    case LISTING_CREATE_FAILED:
      return {
        loading: false,
        data: {},
        error: action.error,
      };
      break;
    case LISTING_CREATE_RESET:
      return initStatus;
      break;
    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: {},
  error: null,
};
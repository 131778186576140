import {
  POINTS_FETCH_LOADING,
  POINTS_FETCH_SUCCESS,
  POINTS_FETCH_FAILED,
  POINTS_CONSUME_LOADING,
  POINTS_CONSUME_SUCCESS,
  POINTS_CONSUME_FAILED,
  POINTS_REWARD_FETCH_LOADING,
  POINTS_REWARD_FETCH_FAILED,
  POINTS_REWARD_FETCH_SUCCESS
} from "./action";

export const pointsStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case POINTS_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;

    case POINTS_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case POINTS_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;

    default:
      return state;
  }
};

export const pointsRewardStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case POINTS_REWARD_FETCH_LOADING:
      return {
        loading: true,
        data: [],
        error: null
      };
      break;

    case POINTS_REWARD_FETCH_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case POINTS_REWARD_FETCH_FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
      break;

    default:
      return state;
  }
};

const initStatus = {
  loading: false,
  data: [],
  error: null
};

export const pointsConsumeStatus = (state = { loading: false,
  data: null,
  error: null}, action = {}) => {
  switch (action.type) {
    case POINTS_CONSUME_LOADING:
      return {
        loading: true,
        data: null,
        error: null
      };

    case POINTS_CONSUME_SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
      break;

    case POINTS_CONSUME_FAILED:
      return {
        loading: false,
        data: null,
        error: action.error
      };
      break;

    default:
      return state;
  }
};

export const BID_UPDATE = "bids/update_v2";
export const BID_UPDATE_REQUEST = `${BID_UPDATE}_request`;
export const BID_UPDATE_SUCCESS = `${BID_UPDATE}_success`;
export const BID_UPDATE_FAILURE = `${BID_UPDATE}_failure`;
export const BID_UPDATE_RECEIVED = `${BID_UPDATE}_received`;

export const bidUpdateAction = {
  request: (payload) => {
    return {
      type: BID_UPDATE_REQUEST,
      payload,
    }
  },
  success: (data) => {
    return {
      type: BID_UPDATE_SUCCESS,
      data,
    }
  },
  failure: (error) => {
    return {
      type: BID_UPDATE_FAILURE,
      error,
    }
  },
  received: () => {
    return {
      type: BID_UPDATE_RECEIVED
    }
  },
};

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const themeMap = {
  primary: {
    bgWrapper: "bg-blue-50 border-blue-500 text-blue-500",
    icon: "text-blue-500",
  },
  success: {
    bgWrapper: "bg-green-100 border-fish-green text-fish-green",
    icon: "text-fish-green",
  },
  danger: {
    bgWrapper: "bg-orange-50 border-fish-red text-fish-red",
    icon: "text-fish-red",
  },
};

/**
 * [Component] Alert v2
 *
 * @example
 *
 * <AlertV2 theme="primary|success|danger" title="" content="" button="button" />
 */
const AlertV2 = (props) => {
  const { title, content, theme, button } = props;
  const color = themeMap[theme];

  const wrapperClass = classnames(
    "border-t-4 rounded-b px-4-tw py-3-tw shadow-md",
    color.bgWrapper
  );
  const iconClass = classnames("fill-current h-6 w-6 mr-4", color.icon);

  return (
    <div className={wrapperClass}>
      <div className="flex md:items-center">
        <div className="py-1">
          <svg
            className={iconClass}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div className="flex-1 md:flex items-center">
          <div className="flex-1">
            {title && <div className="font-bold mb-3-tw md:mb-0">{title}</div>}
            {content && <div className="text-sm">{content}</div>}
          </div>
          {button && <div className="mt-3 md:mt-0 md:ml-5-tw">{button}</div>}
        </div>
      </div>
    </div>
  );
};

AlertV2.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

AlertV2.defaultProps = {
  theme: "primary",
  title: "",
  content: "",
  button: "",
};

export default AlertV2;

import {
    STATIC_DATA_FETCH_REQUEST,
    STATIC_DATA_FETCH_SUCCESS,
    STATIC_DATA_FETCH_FAILED,
  } from "./action";

  export const staticDataStatus = (state = initStatus, action = {}) => {
    switch (action.type) {
      case STATIC_DATA_FETCH_REQUEST:
        return {
          loading: true,
          data: {},
          error: null,
        };
        break;
  
      case STATIC_DATA_FETCH_SUCCESS:
        return {
          loading: false,
          data: action.data,
          error: null,
        };
        break;
  
      case STATIC_DATA_FETCH_FAILED:
        return {
          loading: false,
          data: {},
          error: action.error,
        };
        break;
  
      default:
        return state;
    }
  };
  
  const initStatus = {
    loading: false,
    data: {},
    error: null,
  };

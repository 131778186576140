import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_EXISTENCE_REQUEST, fisheryExistenceAction } from "./action";

//Fetch fishery show
export function* watchfisheryExistenceFetch() {
  yield takeLatest(FISHERY_EXISTENCE_REQUEST, fisheryExistenceFetch);
}

function* fisheryExistenceFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "existence"], action.payload);
    yield put(fisheryExistenceAction.success(res.data));
  } catch (err) {
    yield put(fisheryExistenceAction.failure(err));
  }
}

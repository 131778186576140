import { API } from "@core";
import { BaseApiRepo } from "./baseApiRepo";

export class PopularListsApiRepo extends BaseApiRepo {
  constructor() {
    super();
    this.model = "popular_lists";
  }

  /**
   * list Api
   */
  list() {
    this.setApi(`${API}/${this.model}`);
    return this.get();
  }
}

import { useEffect } from "react";

/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector, QuotaUnitUsecase } from "@core";
import {
  accountPermanentShowAction,
  ACCOUNT_PERMANENT_SHOW,
} from "@redux/modules_v2/account/permanentShow/action";

/**
 * [hooks] permanent show
 *
 */
export const useAccountPermanentShow = (id) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * fetch permanent
   */
  const fetchPermanent = (quotaUnitId) => {
    dispatch(accountPermanentShowAction.request({ id: quotaUnitId }));
  };

  const permanentShowStatus = useSelector((state) => {
    const selector = new LoadingSelector(ACCOUNT_PERMANENT_SHOW, state);
    return Object.assign({}, get(state, "accountPermanentShowStatus", {}), {
      loading: selector.loading,
    });
  });

  /**
   * did mount fetch list
   */
  useEffect(() => {
    fetchPermanent(id);
  }, []);

  return {
    usecase: new QuotaUnitUsecase(permanentShowStatus.data),
    loading: permanentShowStatus.loading,
  };
};

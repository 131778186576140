import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import cx from "classnames";

const Title = ({ step, maxStep }) => {
  const dots = (step) => {
    const num = Array(maxStep)
      .fill(null)
      .map((_, i) => i + 1);

    return (
      <ul className="flex items-center justify-center space-x-4">
        {map(num, (value, index) => (
          <li
            className={cx("rounded-full w-2 h-2", {
              "bg-fish-primary": step >= value,
              "bg-gray-300": step < value,
            })}
            style={{
              boxShadow:
                value === step ? "0 0 0 4px rgba(93, 90, 243, 0.2)" : "",
            }}
            key={index}
          />
        ))}
      </ul>
    );
  };
  return (
    <div className="p-6 md:py-10 flex flex-col items-center space-y-6 md:space-y-8 text-center">
      <div className="text-2xl md:text-3xl font-medium">
        Create your listing
      </div>
      {dots(step)}
    </div>
  );
};

Title.propTypes = {};

export default Title;

import {
  ACCOUNT_PROFILE_UPDATE_SUCCESS,
  ACCOUNT_PROFILE_UPDATE_FAILURE, 
  ACCOUNT_PROFILE_UPDATE_RESET,
} from "./action";

export const accountProfileUpdateStatus = (state = initStatus, action = {}) => {
  switch (action.type) {
    case ACCOUNT_PROFILE_UPDATE_SUCCESS:
      return {
        data: action.data,
        error: null,
      };

    case ACCOUNT_PROFILE_UPDATE_FAILURE:
      return {
        data: null,
        error: action.error,
      };

    case ACCOUNT_PROFILE_UPDATE_RESET:
      return initStatus;

    default:
      return state;
  }
};

const initStatus = {
  data: null,
  error: null,
};

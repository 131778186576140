import { takeLatest, call, put } from "redux-saga/effects";

import { FisheryApiUsecase } from "@core";
import { FISHERY_SHOW_REQUEST, fisheryShowAction } from "./action";

//Fetch fishery show
export function* watchfisheryShowFetch() {
  yield takeLatest(FISHERY_SHOW_REQUEST, fisheryShowFetch);
}

function* fisheryShowFetch(action) {
  try {
    const usecase = new FisheryApiUsecase();
    const res = yield call([usecase, "show"], action.payload);
    yield put(fisheryShowAction.success(res.data));
  } catch (err) {
    yield put(fisheryShowAction.failure(err));
  }
}

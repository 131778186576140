import axios from "axios";
import { takeLatest, takeEvery, call, put, delay } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  LISTING_MESSAGE_POST,
  LISTING_MESSAGE_LOADING,
  LISTING_MESSAGE_SUCCESS,
  LISTING_MESSAGE_FAILED,
  LISTING_MESSAGE_RESET
} from "./messageAction";

import {
  listingLeaveMessageAPI,
} from "@config/api";


// post a message for listing
export function* watchListingMessagePost() {
  yield takeLatest(LISTING_MESSAGE_POST, listingMessagePost);
}

export function* listingMessagePost(action) {
  yield put({ type: LISTING_MESSAGE_LOADING });

  const { err, res } = yield call(listingMessagePostApi, action.id, action.payload);

  if (!err) {
    yield put({ type: LISTING_MESSAGE_SUCCESS, data: 'ok' });
    yield call(delay, 3000);
    yield put({ type: LISTING_MESSAGE_RESET });
    return;
  }
  yield put({
    type: LISTING_MESSAGE_FAILED,
    error: "Leave message for listing failed."
  });
}

export function listingMessagePostApi(id, payload) {
  return axios
    .post(listingLeaveMessageAPI(id), { ...payload} )
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

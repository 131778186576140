export const OFFER_NEW_COLLAPSE_STATUS_TOGGLE =
  "layout/offer_new_collapse_status_toggle";
export const OFFER_NEW_MODAL_STATUS_TOGGLE =
  "layout/offer_new_modal_status_toggle";
export const OFFER_NEW_SET_SELL_TYPE = "layout/offer_new_set_sell_type";
export const OFFER_SET_EDIT = "layout/offer_set_edit";
export const AUCTION_SET_EDIT = "layout/auction_set_edit";

export function layoutOfferNewCollapseStatusToggle(status) {
  return {
    type: OFFER_NEW_COLLAPSE_STATUS_TOGGLE,
    payload: {
      isOpen: status
    }
  };
}

export function layoutOfferNewModalStatusToggle(status) {
  return {
    type: OFFER_NEW_MODAL_STATUS_TOGGLE,
    payload: {
      isOpen: status
    }
  };
}

export function layoutSetOfferSellType(kind) {
  return {
    type: OFFER_NEW_SET_SELL_TYPE,
    payload: {
      kind
    }
  };
}

export function layoutSetOfferEdit(status, data) {
  return {
    type: OFFER_SET_EDIT,
    payload: {
      isEdit: status,
      data
    }
  };
}

export function layoutSetAuctionEdit(status, data) {
  return {
    type: AUCTION_SET_EDIT,
    payload: {
      isEdit: status,
      data
    }
  };
}
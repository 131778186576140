import axios from "axios";
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import get from "lodash/get";
import capitalize from "lodash/capitalize";

import {
  CHANNEL_MESSAGES_CREATE_POST,
  CHANNEL_MESSAGES_CREATE_LOADING,
  CHANNEL_MESSAGES_CREATE_SUCCESS,
  CHANNEL_MESSAGES_CREATE_FAILED,
} from "./messageCreateAction";

import {
  channelMessagesAPI,
} from "@config/api";


//post channel message for create
export function* watchChannelMessagePost() {
  yield takeLatest(CHANNEL_MESSAGES_CREATE_POST, channelMessagePost);
}

export function* channelMessagePost(action) {
  yield put({ type: CHANNEL_MESSAGES_CREATE_LOADING });
  const { err, res } = yield call(channelMessagePostApi, action.channelId, action.payload);

  if (!res) {
    yield put({
      type: CHANNEL_MESSAGES_CREATE_FAILED,
      error: "Post channel message failed."
    });
    return;
  }
  yield put({ type: CHANNEL_MESSAGES_CREATE_SUCCESS, data: res });
}

export function channelMessagePostApi(id, payload) {
  return axios
    .post(channelMessagesAPI(id), {message: {...payload}})
    .then(res => ({
      res: res.data
    }))
    .catch(err => ({
      err
    }));
}

import { useEffect } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";

/**
 * import local tools & redux & usecase
 */
import { OrderUsecase, LoadingSelector } from "@core";
import {
  orderPurchasedAction,
  ORDER_PURCHASED,
} from "@redux/modules_v2/orders/purchased/action";

/**
 * [hooks] order purchased
 * @param params null | object 参数过滤条件
 * @returns { usecase: "a usecase by order data", loading: true|false }
 */
export const useOrderPurchased = params => {
  const dispatch = useDispatch();

  const fetchOrderPurchased = (page = 1) => {
    dispatch(orderPurchasedAction.request({ page, ...params }));
  };

  const orderPurchasedStatus = useSelector(state => {
    const selector = new LoadingSelector(ORDER_PURCHASED, state);
    return Object.assign({}, get(state, "orderPurchasedStatus", {}), {
      loading: selector.loading,
    });
  });

  const loading = orderPurchasedStatus.loading;
  const data = orderPurchasedStatus.data || {};
  const list = get(orderPurchasedStatus, "data.entries") || [];
  const usecase = new OrderUsecase(list);

  /**
   * did mount
   */
  useEffect(() => {
    fetchOrderPurchased();
  }, []);

  return { usecase, loading, data, fetchOrderPurchased };
};

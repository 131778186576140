import { useState, useEffect, useCallback } from "react";
/**
 * import lib tools
 */
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * import local tools & redux & usecase
 */
import { LoadingSelector } from "@core";
import {
  offersApplyAction,
  OFFERS_APPLY,
} from "@redux/modules_v2/offers/apply/action";

import Notice from "@utils/noticeNew";

export const useOfferApply = (cb) => {
  const dispatch = useDispatch();
  const [offerId, setOfferId] = useState("");
  const onSubmit = useCallback((values = {}) => {
    if (!values.offerId) {
      return;
    }
    setOfferId(values.offerId);
    request(values);
  }, []);

  const request = (payload) => {
    dispatch(offersApplyAction.request(payload));
  };

  const offersApplyStatus = useSelector((state) => {
    const selector = new LoadingSelector(OFFERS_APPLY, state);
    return Object.assign({}, get(state, "offersApplyStatus", {}), {
      loading: selector.loading,
    });
  });

  useEffect(() => {
    return () => {
      dispatch(offersApplyAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!offersApplyStatus.loading) {
      if (!isEmpty(offersApplyStatus.data)) {
        dispatch(offersApplyAction.reset());
        if (offerId && typeof cb === "function") {
          cb(offerId);
        }
      }
      if (offersApplyStatus.error) {
        Notice.failure(offersApplyStatus.error);
      }
    }
  }, [offersApplyStatus.loading, offerId]);

  const loading = offersApplyStatus.loading;
  const data = offersApplyStatus.data || {};

  return { onSubmit, loading, data };
};

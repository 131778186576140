import React from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import Divider from "./_divider";
import { List } from "@views/markets/auction/list";

const SimilarAuctions = ({ data, logo }) => {

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div>
      <Divider className="mt-8 mb-6" />
      <div className="flex justify-between items-center mb-4">
        <div className="font-medium">Similar auctions</div>
      </div>
      <List data={data} image={logo} isSimilar/>
    </div>
  );
};

SimilarAuctions.propTypes = {};

export default SimilarAuctions;

export const LISTING_LIST_FETCH_REQUEST = "listing/fetch_list_request";
export const LISTING_LIST_FETCH_LOADING = "listing/fetch_list_loading";
export const LISTING_LIST_FETCH_SUCCESS = "listing/fetch_list_success";
export const LISTING_LIST_FETCH_FAILED = "listing/fetch_list_failed";
export const LISTING_LIST_REFRESH = "listing/refresh_list";



export function fetchListingList(payload) {
  return {
    type: LISTING_LIST_FETCH_REQUEST,
    payload
  };
}

export function refreshListingList(data) {
  return {
    type: LISTING_LIST_REFRESH,
    data,
  };
}

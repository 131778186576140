const WALLET_WITHDRAWAL_CANCEL_REQUEST = "account_wallet_withdrawal_cancel/request";
const WALLET_WITHDRAWAL_CANCEL_LOADING = "account_wallet_withdrawal_cancel/loading";
const WALLET_WITHDRAWAL_CANCEL_SUCCESS = "account_wallet_withdrawal_cancel/success";
const WALLET_WITHDRAWAL_CANCEL_FAILED = "account_wallet_withdrawal_cancel/failed";
const WALLET_WITHDRAWAL_CANCEL_RECEIVED = "account_wallet_withdrawal_cancel/received";

function walletWithdrawalCancelRequest(id) {
  return {
    type: WALLET_WITHDRAWAL_CANCEL_REQUEST,
    payload: {
      id,
    },
  };
}

function walletWithdrawalCancelLoading() {
  return {
    type: WALLET_WITHDRAWAL_CANCEL_LOADING,
  };
}

function walletWithdrawalCancelSuccess(data) {
  return {
    type: WALLET_WITHDRAWAL_CANCEL_SUCCESS,
    data,
  };
}

function walletWithdrawalCancelFailed(error) {
  return {
    type: WALLET_WITHDRAWAL_CANCEL_FAILED,
    error,
  };
}

function walletWithdrawalCancelReceived() {
  return {
    type: WALLET_WITHDRAWAL_CANCEL_RECEIVED,
  };
}

export {
  WALLET_WITHDRAWAL_CANCEL_REQUEST,
  WALLET_WITHDRAWAL_CANCEL_LOADING,
  WALLET_WITHDRAWAL_CANCEL_SUCCESS,
  WALLET_WITHDRAWAL_CANCEL_FAILED,
  WALLET_WITHDRAWAL_CANCEL_RECEIVED,
  walletWithdrawalCancelRequest,
  walletWithdrawalCancelLoading,
  walletWithdrawalCancelSuccess,
  walletWithdrawalCancelFailed,
  walletWithdrawalCancelReceived,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import cx from "classnames";
import { Switch } from '@headlessui/react'


import { Link, Loading } from "@components";

const WatchList = ({ listLoading, data, onSubmit, followLoading }) => {

  const [currentFollowId, setCurrenFollowId] = useState(null);

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6 border-b border-blue-gray-200">
        <h2 className="text-base text-blue-gray-800 leading-normal font-medium">
          My watchlist
        </h2>
        <span className="text-xxs text-blue-gray-400">Notify me of activity</span>
      </div>
      { listLoading ? (
        <div>
          <Loading />
        </div>
      ): (
        <ul className="wacthlist-input watchlist-height py-4 px-6">
          {map(data, (item, index) => {
            return (
              <li
                key={index}
                className="mb-3 md:mb-4"
              >
                <div className="flex justify-between items-center">
                  <p className="flex-1 mr-4 text-sm text-blue-gray-500">{item.title}</p>
                  {
                    followLoading && currentFollowId === item.id ? (
                      <Loading size="sm" spacing="p-0" />
                    ):(
                      <Switch
                        checked={item.following}
                        onChange={() => {
                          if (item.id) {
                            onSubmit({ id: item.id, following: item.following });
                            setCurrenFollowId(item.id);
                          }
                        }}
                        className={cx(
                          item.following ? 'bg-fish-primary' : 'bg-gray-100',
                          'relative inline-flex flex-shrink-0 h-6 switch-w-44 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        )}
                      >
                        <span
                          className={cx(
                            item.following ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        >
                          <span
                            className={cx(
                              item.following ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                          >
                            <svg className="bg-white h-3 w-3 text-blue-gray-400" fill="none" viewBox="0 0 12 12">
                              <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span
                            className={cx(
                              item.following ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                          >
                            <svg className="bg-white h-3 w-3 text-fish-primary" fill="currentColor" viewBox="0 0 12 12">
                              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                            </svg>
                          </span>
                        </span>
                      </Switch>
                    )
                  }
                </div>
              </li>
            );
          })}
        </ul>
      )}
      
      <div className="flex justify-between items-center py-3 px-6 bg-gray-50 text-xxs leading-tight text-blue-gray-500">
        <span className="">Notification type</span>
        <div className="relative">
          <select className="py-2 px-3 pr-8 rounded-lg border border-blue-gray-200 text-blue-gray-500">
            <option value="emailandsms">Email and SMS</option>
          </select>
          <svg className="absolute top-2 right-3" style={{top:10}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 4.5L6 8L2.5 4.5" stroke="#97A3B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

WatchList.propTypes = {
};

WatchList.defaultProps = {};

export default WatchList;

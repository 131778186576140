import React, { useState, useCallback, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import cx from "classnames";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/**
 * Modal window
 * @example
 * const [modalProps, toggleModal] = useModal(options);
 * <Modal {...modalProps} />
 */

/**
 * Modal 默认 options
 */
const defaultOptions = {
  icon: null, //图标
  title: "", //标题
  content: "", //内容
  contentAlign: "center",
  buttonAlign: "center", //按钮对齐
  isVertical: false, //modal body布局,如果为true就是上下结构，否则为左右结构
  size: "lg", //Base on max-width https://tailwindcss.com/docs/max-width/#app
  bodyNoScroll: false, //modal显示时,背景body页面是否可滚动
};

/**
 * Modal buttons options的buttons字段中使用
 * @param {Object} param0
 * @example
 *  <ModalButton
 *    label="Re-send verification code"
 *    key={`modal-button-2`}
 *    isStyleDestructive
 *    onClick={() => toggleModal()}
 *  />
 */
const ModalButton = ({
  onClick,
  label,
  disabled,
  isStylePrimary,
  isStyleCancel,
  isStyleDestructive,
}) => {
  const buttonClass = cx(
    "inline-flex justify-center w-full rounded-md border px-4 py-2 text-base leading-6 font-proxima-medium shadow-sm transition ease-in-out duration-150 md:text-sm md:leading-5",
    {
      "border-blue-gray-300 bg-white text-blue-gray-700 hover:text-blue-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline":
        isStyleCancel,
      "border-transparent bg-fish-primary text-white hover:bg-fish-primary-700 focus:outline-none focus:border-fish-primary-700 focus:shadow-outline-indigo":
        isStylePrimary,
      "border-transparent bg-red-600 text-white hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red":
        isStyleDestructive,
      "opacity-50 cursor-not-allowed pointer-events-none": disabled,
    }
  );
  return (
    <button
      type="button"
      disabled={disabled}
      className={buttonClass}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

ModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isStylePrimary: PropTypes.bool,
  isStyleCancel: PropTypes.bool,
  isStyleDestructive: PropTypes.bool,
  disabled: PropTypes.bool,
};

ModalButton.defaultProps = {
  isStylePrimary: false,
  isStyleCancel: false,
  isStyleDestructive: false,
  disabled: false,
};

/**
 * Modal 组件主体
 * @param {Object}
 */
const Modal = ({ isOpen, hide, options, children }) => {
  const sizeClassName = (size) => {
    switch (size) {
      case "xs":
        return "md:max-w-xs";
      case "md":
        return "md:max-w-sm";
      case "lg":
        return "md:max-w-lg";
      case "xl":
        return "md:max-w-xl";
      default:
        return "md:max-w-lg";
    }
  };

  // modal显示时,body不可滚动样式判断
  if(options.bodyNoScroll){
    if(isOpen) {
      document.body.classList.add('body-not-scroll')
    }else {
      document.body.classList.remove('body-not-scroll')
    }
  }

  // modal头部 absolute固定位置显示
  const renderHead = () => {
    const showClose = get(options, "showClose", false);
    const onClosed = get(options, "onClosed", "");
    if (!showClose) {
      return null;
    }
    return (
      <div className="absolute inset-x-0 top-0 z-10 bg-white px-5">
        <div className="py-3 md:py-4 border-b border-blue-gray-200 flex justify-between items-center">
          <div className="text-lg leading-6 font-medium text-blue-gray-900">
            {options.title}
          </div>
          <button
            className=""
            onClick={() => {
              typeof hide === "function" && hide();
              typeof onClosed === "function" && onClosed();
            }}
            style={{ mixBlendMode: "difference" }}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </div>
      
    );
  }

  const renderBody = () => {
    if (children) {
      return children;
    }
    if (options.isVertical) {
      return (
        <div className="md:flex md:items-start w-full">
          {options.icon && (
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-3 md:mx-0 md:h-10 md:w-10  md:mr-4">
              {options.icon}
            </div>
          )}
          <div
            className={cx(
              "md:mt-0 md:text-left w-full",
              `text-${options.contentAlign}`
            )}
          >
            {/* modal头部absolute显示时 标题显示在head不在body显示 */}
            {!options.absoluteHead && options.title && (
              <div className="text-lg leading-6 font-medium text-blue-gray-900">
                {options.title}
              </div>
            )}
            {options.content && (
              <div className="mt-2">
                <div className="text-sm leading-5">{options.content}</div>
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="tw-modal-body flex flex-col items-center text-center">
        {options.icon && <div className="mb-3 md:mb-5">{options.icon}</div>}
        <div className="w-full">
          {options.title && (
            <div className="text-lg leading-6 font-medium text-blue-gray-900">
              {options.title}
            </div>
          )}
          {options.content && <div className="mt-2">{options.content}</div>}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    const buttons = get(options, "buttons", []);
    const buttonAlign = get(options, "buttonAlign", "");
    return !isEmpty(buttons) ? (
      <div className={cx("mt-5 tw-modal-footer", buttonAlign)}>
        {map(buttons, (button, index) => (
          <React.Fragment key={`button-${index}`}>{button}</React.Fragment>
        ))}
      </div>
    ) : null;
  };

  const renderClose = () => {
    const showClose = get(options, "showClose", false);
    const onClosed = get(options, "onClosed", "");
    if (!showClose) {
      return null;
    }
    return (
      <button
        className="absolute right-4 top-4 z-10"
        onClick={() => {
          typeof hide === "function" && hide();
          typeof onClosed === "function" && onClosed();
        }}
        style={{ mixBlendMode: "difference" }}
      >
        <svg
          className="w-5 h-5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    );
  };
  return (
    <CSSTransition
      in={isOpen}
      classNames="tw-modal"
      className="fixed bottom-0 inset-x-0 px-4 py-6 md:inset-0 md:p-0 md:flex md:items-center md:justify-center z-50"
      timeout={300}
      unmountOnExit
    >
      {/* modal头部是否absolute显示判断 */}
      {options.absoluteHead?(
        <div>
          <div className="tw-modal-overlay fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className={cx(
              "relative tw-modal-card bg-white rounded-lg px-0 pt-5 pb-4 shadow-xl transform transition-all md:w-full md:py-6 overflow-hidden",
              sizeClassName(options.size)
            )}
          >
            {renderHead()}
            <div className="max-h-80-screen md-max-h-90-screen overflow-x-hidden overflow-y-scroll pt-10 px-4 md:px-6">
              {renderBody()}
            </div>
            {renderFooter()}
          </div>
        </div>
      ):(
        <div>
          <div className="tw-modal-overlay fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className={cx(
              "relative tw-modal-card bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all md:w-full md:p-6 max-h-80-screen md-max-h-90-screen overflow-x-hidden overflow-y-auto",
              sizeClassName(options.size)
            )}
          >
            {renderClose()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      )}
      
    </CSSTransition>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  options: PropTypes.shape({
    icon: PropTypes.element,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttonAlign: PropTypes.oneOf(["center", "left", "right"]),
    isVertical: PropTypes.bool,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    showClose: PropTypes.bool,
    onClosed: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

Modal.defaultProps = {
  isOpen: false, //默认状态modal是否打开
  options: defaultOptions,
};

/**
 * Modal 组件 hooks
 * @param {Object} options
 * @param {Boolean} open
 */
const useModal = (options, open) => {
  const [isOpen, toggleOpen] = useState(open || false);

  const toggleModal = useCallback(() => {
    toggleOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    toggleOpen(open);
  }, [open]);

  return [
    {
      isOpen,
      hide: toggleOpen,
      options: Object.assign({}, defaultOptions, options),
    },
    toggleModal,
  ];
};

export { ModalButton, Modal, useModal };
